import {
  addTeamMemberURL,
  resendInvitationURL,
  getMemberURL,
  updateTeamMemberURL,
  removeMemberURL,
  joinTeamURL,
  fetchWorkspaceUrl,
  getUsersByEmailURL,
} from '@src/modules/setting/config/api-utils'
import { baseUrl } from '@src/config/api-utils'
import { mapGetters, mapActions } from 'vuex'
import { authenticationTypes } from '@state/mutation-types'
import { debounce } from 'lodash'
import proxy from '../../lib/http-common'

export const teamMixin = {
  computed: {
    ...mapGetters([
      'getTeam',
      'getWorkspaces',
      'getFetchBlogsStatus',
      'getFetchAccountsStatus',
      'getTumblrAccounts',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getThreadsAccounts',
      'getLinkedinAccounts',
      'getInstagramAccounts',
      'getTiktokAccounts',
      'getPlan',
      'getGmbAccounts',
      'getPinterestProfiles',
      'getTeamSellectAllOptions',
      'getUserEmails',
      'getSettingLoaders',
    ]),
  },
  methods: {
    ...mapActions([
      'setSaveTeamMemberLoader',
      'setRemoveTeamMemberLoader',
      'setTeam',
      'setDefaultTeam',
      'setJoinTeamLoader',
      'setTeamSellectAll',
      'setMembersLimit',
      'setConfirmActionStage',
      'setTeamAccountSelectAll',
      'fetchProfile',
      'setEmails',
    ]),

    /**
     * add team member in workspace
     */
    getUsersByEmails: debounce(function () {
      if (this.getTeam.email.trim().length >= 3) {
        this.postRequest(
          getUsersByEmailURL,
          this.getTeam,
          (response) => {
            this.setEmails(response.data.user)
            console.log(this.getUserEmails)
          },
          (error) => {
            console.debug('Exception in saveTeamMember', error)
          }
        )
      }
    }, 1000),

    saveTeamMember() {
      console.debug('Method:saveTeamMember')

      const teamData = this.getTeam
      // if (!teamData.first_name || (teamData.first_name.trim()).length === 0) {
      //   this.alertMessage('Please add first name.', 'error')
      // } else if (!teamData.last_name || (teamData.last_name.trim()).length === 0) {
      //   this.alertMessage('Please add last name.', 'error')
      // }
      if (!teamData.email || teamData.email.trim().length === 0) {
        this.alertMessage('Please add email.', 'error')
      } else if (!this.validateEmail(teamData.email)) {
        this.alertMessage('Please enter a valid email.', 'error')
      } else {
        this.setSaveTeamMemberLoader(true)
        teamData.workspace_id = this.getWorkspaces.activeWorkspace._id
        this.postRequest(
          addTeamMemberURL,
          teamData,
          async (response) => {
            if (response.data && response.data.status === true) {
              if (teamData.role === 'approver')
                this.alertMessage('Team member added successfully.', 'success')
              else this.alertMessage('Invitation has been Sent.', 'success')

              if (!this.getActiveWorkspace.onboarding_steps?.invite_team?.status) {
                await this.onboardingStepsCompleted('invite_team')
              }
              this.trackUserMaven('team_member_invited')
              this.Plan()
              this.changeDefaultWorkspaceMemberDetail(response.data.members)
              this.changeWorkspaceMemberDetail(
                  this.getWorkspaces.activeWorkspace._id,
                  response.data.members
              )
              // $('#addTeamMember').modal('hide')

              this.$bvModal.hide('addTeamMember')
              //  this.$store.dispatch('trackEvent', { event: 'team_members_added' })
            } else if (response.data.upgrade) {
              this.$bvModal.hide('addTeamMember')
              this.showUpgradeModal()
            } else {
              if (response.data && response.data.message) {
                this.alertMessage(response.data.message, 'error')
              } else {
                this.alertMessage(
                    'Failed to send invitation, please try again.',
                    'error'
                )
              }
            }
            this.setSaveTeamMemberLoader(false)
          },
          (error) => {
            console.debug('Exception in saveTeamMember', error)
            this.setSaveTeamMemberLoader(false)
          }
        )
      }
    },

    /**
     * this method will get member detail, ready payload and open edit modal
     */
    updateTeamMember() {
      this.setSaveTeamMemberLoader(true)
      const data = {
        id: this.getTeam.id,
        permissions: this.getTeam.permissions,
        role: this.getTeam.role,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        membership: this.getTeam.membership,
      }
      this.postRequest(
        updateTeamMemberURL,
        data,
        (response) => {
          if (response.data.status === true) {
            this.alertMessage('Successfully updated', 'success')
            this.changeDefaultWorkspaceMemberDetail(response.data.members)
            this.changeWorkspaceMemberDetail(
              this.getWorkspaces.activeWorkspace._id,
              response.data.members
            )
            // $('#addTeamMember').modal('hide')
            this.$bvModal.hide('addTeamMember')
          } else {
            this.alertMessage(
              'Unable to update permissions. Please try again.',
              'error'
            )
          }
          this.setSaveTeamMemberLoader(false)
        },
        (error) => {
          console.debug('Exception in saveTeamMember', error)
          this.setSaveTeamMemberLoader(false)
        }
      )
    },

    processAccountSelectAll() {
      console.log("Method::processAccountSelectAll ~ ")
      const types = [
        'facebook',
        'twitter',
        'pinterest',
        'linkedin',
        'instagram',
        'gmb',
        'youtube',
        'wordpress',
        'tumblr',
        'medium',
        'shopify',
        'tiktok',
        'threads'
      ]
      let selectAll = true
      const stateObject = this
      types.forEach(function (el) {
        if (stateObject.getTeamSellectAllOptions[el] === false)
          selectAll = false
      })
      this.setTeamAccountSelectAll(selectAll)
    },

    /**
     * update member detail method
     */
    editMember(member_id) {
      console.debug('Method:editMember')
      this.setTeamAccountSelectAll(true)
      this.setDefaultTeam()
      this.postRequest(
        getMemberURL,
        {
          id: member_id,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        },
        (response) => {
          if (response.data.status === true) {
            console.debug(response.data.member)
            let membership = 'team'
            if (response.data.member.membership)
              membership = response.data.member.membership
            this.setTeam({
              id: response.data.member._id,
              first_name: response.data.member.user.firstname,
              last_name: response.data.member.user.lastname,
              email: response.data.member.user.email,
              role: response.data.member.role,
              permissions: response.data.member.permissions,
              membership,
            })
            if (response.data.member.permissions) {
              const types = [
                'facebook',
                'twitter',
                'gmb',
                'instagram',
                'linkedin',
                'medium',
                'pinterest',
                'shopify',
                'tumblr_blogs',
                'tumblr_profiles',
                'wordpress',
                'youtube',
                'tiktok',
                'threads'
              ]
              const selfObject = this
              types.forEach((element, index) => {
                selfObject.memberPermissionsChanges(element, '')
              })
            }
            // $('#addTeamMember').modal('show')
            this.$bvModal.show('addTeamMember')
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        },
        (error) => {
          console.debug('Exception in editMember', error)
        }
      )
    },

    async removeWSTeamMember(payload) {
      console.debug('Method:removeWSTeamMember')
      return proxy
        .post(removeMemberURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            this.alertMessage(response.data.message, 'success')
            this.changeWorkspaceMemberDetail(
              payload.workspace_id,
              response.data.members
            )
          } else {
            this.alertMessage(response.data.message, 'error')
          }
          return response.data
        })
        .catch((error) => {
          console.debug('Exception in fetchWorkspace', error)
          return []
        })
    },

    /**
     * update member detail method
     */
    removeTeamMember(confirmation, member_id) {
      console.debug('Method:removeTeamMember')
      if(this.getSettingLoaders.removeTeamMember === true)
        return

      if (confirmation.status) {
        this.setRemoveTeamMemberLoader(true)
        this.postRequest(
          removeMemberURL,
          {
            member_id,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          (response) => {
            if (response.data.status === true) {
              this.setConfirmActionStage('success')
              this.closeConfirmAction(confirmation.type)
              this.changeDefaultWorkspaceMemberDetail(response.data.members)
              this.changeWorkspaceMemberDetail(
                this.getWorkspaces.activeWorkspace._id,
                response.data.members
              )
              this.alertMessage(response.data.message, 'success')
              this.Plan()
            } else {
              this.alertMessage(response.data.message, 'error')
              this.setConfirmActionStage('fail')
              this.closeConfirmAction(confirmation.type)
            }
            this.setRemoveTeamMemberLoader(false)
          },
          (error) => {
            console.debug('Exception in removeTeamMember', error)
            this.setRemoveTeamMemberLoader(false)
          }
        )
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    },

    /**
     * reset member payload and open add modal method
     */
    addTeamMemberModal() {
      console.debug('Method::addTeamMemberModal')
      this.setTeamAccountSelectAll(true)
      this.setDefaultTeam()
      this.toggleMemberPermission(true)
      // $('#addTeamMember').modal('show')
      this.$bvModal.show('addTeamMember')
    },

    /**
     * resend member joinnig invitation method
     */
    resendInvitation(member) {
      console.debug('Method:resendInvitation')
      this.postRequest(
        resendInvitationURL,
        {
          member_id: member._id,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        },
        (response) => {
          if (response.data.status === true) {
            this.alertMessage(response.data.message, 'success')
            this.changeDefaultWorkspaceMemberDetail(response.data.members)
            this.changeWorkspaceMemberDetail(
              this.getWorkspaces.activeWorkspace._id,
              response.data.members
            )
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        },
        (error) => {
          console.debug('Exception in resendInvitation', error)
        }
      )
    },

    /**
     * joining link (invitation link)
     */
    invitationLink(member) {
      console.debug('Method::invitationLink')
      if (member.reff_enc && member.token) {
        return baseUrl + 'joinTeam/' + member.reff_enc + '/' + member.token
      } else if (
        member.user.invitation_token &&
        member.user.invitation_token.token
      ) {
        return baseUrl + 'team/join?token=' + member.user.invitation_token.token
      }

      return ''
    },

    /**
     * copy joining link success message
     */
    copyInvitationLinkSuccess() {
      this.alertMessage(
        'Invitation link has been copied to clipboard.',
        'success'
      )
    },

    /**
     * copy joining link error message
     */
    copyInvitationLinkError() {
      this.alertMessage(
        'An error ocurred while copying invitation link.',
        'error'
      )
    },

    /**
     * Join Team member , set firstname, lastname, password for new User
     */
    joinTeam(firstname, lastname, password, confirmPassword, reff, token) {
      console.debug('Method:joinTeam')

      if (firstname.trim().length === 0) {
        this.alertMessage('Please enter first name.', 'error')
      } else if (lastname.trim().length === 0) {
        this.alertMessage('Please enter last name.', 'error')
      } else if (password === '') {
        this.alertMessage('Please enter a password.', 'error')
      } else if (confirmPassword === '') {
        this.alertMessage('Please confirm your password.', 'error')
      } else if (password !== confirmPassword) {
        this.alertMessage(
          'password and confirm password is not matched.',
          'error'
        )
      } else {
        this.setJoinTeamLoader(true)

        this.$http
          .post(joinTeamURL, {
            firstname,
            lastname,
            password,
            reff_enc: reff,
            token,
          })
          .then(
            async (response) => {
              if (response.data.status === false) {
                this.alertMessage(response.data.message, 'error')
                this.setJoinTeamLoader(false)
              } else {
                this.$store.commit(
                  authenticationTypes.SET_JWT_TOKEN,
                  response.data.token
                )
                this.$store.commit(
                  authenticationTypes.SET_LOGGED_USER,
                  response.data.logged_user
                )
                this.$store.commit(
                  authenticationTypes.SET_IS_AUTHENTICATED,
                  true
                )
                this.fetchProfile()
                if (response.data.user && response.data.user.onBoarding) {
                  if (response.data.user.migration) {
                    this.fetchOnboardingWorkspaces()
                    this.$router.push({ name: 'welcome' })
                  } else {
                    this.fetchWorkspaces()
                    this.$router.push({ name: 'onboardingWorkspace' })
                  }
                } else {
                  await this.fetchWorkspaces(true)
                  console.log('Join Team: workspace redirect')
                  this.$router.push({ name: 'dashboard', params: {workspace: this.getWorkspaces.activeWorkspace.slug}})
                }
              }
            },
            (response) => {
              console.debug('Exception in joinTeam', response)
              this.setJoinTeamLoader(false)
              this.alertMessage(
                'Uh-oh! An unknown error occurred, support has been notified.',
                'error'
              )
            }
          )
      }
    },

    getBlogItems(type) {
      return this.$store.getters.getBlogs[type].items
    },

    toggleMemberPermission(toggleValue) {
      console.debug('Method:toggleMemberPermission', toggleValue)
      // Wordpress
      if (this.getBlogItems('wordpress').length) {
        this.getTeamSellectAllOptions.wordpress = toggleValue
        this.memberPermissionsChanges('wordpress', 'All')
      }

      // Twitter
      if (this.getTwitterAccounts.items.length) {
        this.getTeamSellectAllOptions.twitter = toggleValue
        this.memberPermissionsChanges('twitter', 'All')
      }

      // Threads
      if (this.getThreadsAccounts.items.length) {
        this.getTeamSellectAllOptions.threads = toggleValue
        this.memberPermissionsChanges('threads', 'All')
      }


      // Facebook
      if (this.getFacebookAccounts.items.length) {
        this.getTeamSellectAllOptions.facebook = toggleValue
        this.memberPermissionsChanges('facebook', 'All')
      }

      // Pinterest
      if (this.getPinterestAccounts.items.length) {
        this.getTeamSellectAllOptions.pinterest = toggleValue
        this.memberPermissionsChanges('pinterest', 'All')
      }

      // LinkedIn
      if (this.getLinkedinAccounts.items.length) {
        this.getTeamSellectAllOptions.linkedin = toggleValue
        this.memberPermissionsChanges('linkedin', 'All')
      }

      // Tumblr
      if (
        this.getTumblrAccounts.items.length ||
        this.getBlogItems('tumblr').length
      ) {
        this.getTeamSellectAllOptions.tumblr = toggleValue
        this.memberPermissionsChanges('tumblr', 'All')
      }

      // Instagram
      if (this.getInstagramAccounts.items.length) {
        this.getTeamSellectAllOptions.instagram = toggleValue
        this.memberPermissionsChanges('instagram', 'All')
      }

      // Gmb
      if (this.getGmbAccounts.items.length) {
        this.getTeamSellectAllOptions.gmb = toggleValue
        this.memberPermissionsChanges('gmb', 'All')
      }

      // Youtube
      if (this.getYoutubeAccounts.items.length) {
        this.getTeamSellectAllOptions.youtube = toggleValue
        this.memberPermissionsChanges('youtube', 'All')
      }

      // Medium
      if (this.getBlogItems('medium').length) {
        this.getTeamSellectAllOptions.medium = toggleValue
        this.memberPermissionsChanges('medium', 'All')
      }

      // Shopify
      if (this.getBlogItems('shopify').length) {
        this.getTeamSellectAllOptions.shopify = toggleValue
        this.memberPermissionsChanges('shopify', 'All')
      }

      // tiktok
      if (this.getTiktokAccounts.items.length) {
        this.getTeamSellectAllOptions.tiktok = toggleValue
        this.memberPermissionsChanges('tiktok', 'All')
      }

    },

    memberPermissionsChanges(type, operation) {
      console.debug('Method::memberPermissionsChanges', type, operation)
      if (operation === 'All') {
        const stateObject = this
        switch (type) {
          case 'topics':
            stateObject.getTeam.permissions.topics = []
            if (stateObject.getTeamSellectAllOptions.topics) {
              $(stateObject.getTopics).each(function (index, el) {
                stateObject.getTeam.permissions.topics.push(el._id)
              })
            }
            break
          case 'favorites':
            stateObject.getTeam.permissions.favorites = []
            if (stateObject.getTeamSellectAllOptions.favorites) {
              $(stateObject.getFolders).each(function (index, el) {
                stateObject.getTeam.permissions.favorites.push(el._id)
              })
            }
            break
          case 'wordpress':
          case 'medium':
          case 'shopify':
            stateObject.getTeam.permissions[type] = []
            if (stateObject.getTeamSellectAllOptions[type]) {
              $(stateObject.$store.getters.getBlogs[type].items).each(function (
                index,
                el
              ) {
                stateObject.getTeam.permissions[type].push(
                  el.platform_identifier
                )
              })
            }
            break
          case 'tumblr':
            stateObject.getTeam.permissions.tumblr_blogs = []
            stateObject.getTeam.permissions.tumblr_profiles = []
            if (stateObject.getTeamSellectAllOptions.tumblr) {
              $(stateObject.$store.getters.getBlogs.tumblr.items).each(
                function (index, el) {
                  stateObject.getTeam.permissions.tumblr_blogs.push(
                    el.platform_identifier
                  )
                }
              )
              $(stateObject.getTumblrAccounts.items).each(function (index, el) {
                stateObject.getTeam.permissions.tumblr_profiles.push(
                  el.platform_identifier
                )
              })
            }
            break
          case 'facebook':
            stateObject.getTeam.permissions.facebook = []
            if (stateObject.getTeamSellectAllOptions.facebook) {
              $(stateObject.getFacebookAccounts.items).each(function (
                index,
                el
              ) {
                stateObject.getTeam.permissions.facebook.push(el.facebook_id)
              })
            }
            break
          case 'twitter':
            stateObject.getTeam.permissions.twitter = []
            if (stateObject.getTeamSellectAllOptions.twitter) {
              $(stateObject.getTwitterAccounts.items).each(function (
                index,
                el
              ) {
                stateObject.getTeam.permissions.twitter.push(el.twitter_id)
              })
            }
            break
          case 'threads':
            stateObject.getTeam.permissions.threads = []
            if (stateObject.getTeamSellectAllOptions.threads) {
              $(stateObject.getThreadsAccounts.items).each(function (
                  index,
                  el
              ) {
                stateObject.getTeam.permissions.threads.push(el.platform_identifier)
              })
            }
            break
          case 'pinterest':
            stateObject.getTeam.permissions.pinterest = []
            if (stateObject.getTeamSellectAllOptions.pinterest) {
              $(stateObject.getPinterestProfiles).each(function (index, el) {
                stateObject.getTeam.permissions.pinterest.push(el.pinterest_id)
              })
            }
            break
          case 'linkedin':
            stateObject.getTeam.permissions.linkedin = []
            if (stateObject.getTeamSellectAllOptions.linkedin) {
              $(stateObject.getLinkedinAccounts.items).each(function (
                index,
                el
              ) {
                stateObject.getTeam.permissions.linkedin.push(el.linkedin_id)
              })
            }
            break
          case 'instagram':
            stateObject.getTeam.permissions.instagram = []
            if (stateObject.getTeamSellectAllOptions.instagram) {
              $(stateObject.getInstagramAccounts.items).each(function (
                index,
                el
              ) {
                stateObject.getTeam.permissions.instagram.push(el.instagram_id)
              })
            }
            break
          case 'gmb':
            stateObject.getTeam.permissions.gmb = []
            if (stateObject.getTeamSellectAllOptions.gmb) {
              $(stateObject.getGmbAccounts.items).each(function (index, el) {
                stateObject.getTeam.permissions.gmb.push(el.name)
              })
            }
            break
          case 'youtube':
            stateObject.getTeam.permissions.youtube = []
            if (stateObject.getTeamSellectAllOptions.youtube) {
              $(stateObject.getYoutubeAccounts.items).each(function (
                index,
                el
              ) {
                stateObject.getTeam.permissions.youtube.push(
                  el.platform_identifier
                )
              })
            }
            break
          case 'tiktok':
            stateObject.getTeam.permissions.tiktok = []
            if (stateObject.getTeamSellectAllOptions.tiktok) {
              $(stateObject.getTiktokAccounts.items).each(function (
                  index,
                  el
              ) {
                stateObject.getTeam.permissions.tiktok.push(
                    el.platform_identifier
                )
              })
            }
            break
        }
      } else {
        switch (type) {
          case 'topics':
            if (
              this.getTeam.permissions.topics.length === this.getTopics.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'favorites':
            if (
              this.getTeam.permissions.favorites.length ===
              this.getFolders.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'wordpress':
          case 'medium':
          case 'shopify':
            this.setTeamSellectAll({
              type,
              status:
                this.getTeam.permissions[type].length ===
                this.$store.getters.getBlogs[type].items.length,
            })
            break
          case 'tumblr':
            if (
              this.getTeam.permissions.tumblr_blogs.length +
                this.getTeam.permissions.tumblr_profiles.length ===
              this.$store.getters.getBlogs.tumblr.items.length +
                this.getTumblrAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'facebook':
            if (
              this.getTeam.permissions.facebook.length ===
              this.getFacebookAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'threads':
            if (
                this.getTeam.permissions.threads.length ===
                this.getThreadsAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'twitter':
            if (
              this.getTeam.permissions.twitter.length ===
              this.getTwitterAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'pinterest':
            if (
              this.getTeam.permissions.pinterest.length ===
              this.getPinterestProfiles.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'linkedin':
            if (
              this.getTeam.permissions.linkedin.length ===
              this.getLinkedinAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'instagram':
            if (
              this.getTeam.permissions.instagram.length ===
              this.getInstagramAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'gmb':
            if (
              this.getTeam.permissions.gmb.length ===
              this.getGmbAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
          case 'youtube':
            if (
              this.getTeam.permissions.youtube.length ===
              this.getYoutubeAccounts.items.length
            ) {
              this.setTeamSellectAll({ type, status: true })
            } else {
              this.setTeamSellectAll({ type, status: false })
            }
            break
        }
      }
    },

    /**
     * Get Team member Tooltip html by ID
     */
    teamMemberTooltipHtmlById(member) {
      if (member && member.role) {
        const memberRoleClass = this.getRoleClass(member.role)
        if (memberRoleClass) {
          const teamMemberHtml = `<div class='team_detail_tooltip'>
                                    <div class='flex items-center space-x-2'>
                                        <div class='img' style='background: url(${this.teamMemberImageById(member.user_id)});'></div>
                                        <div class="flex flex-col">
                                          <div class="flex space-x-2 items-center">
                                            <div class="font-semibold">${member.user.firstname} ${member.user.lastname}</div>
                                            <div class="${memberRoleClass} role">
                                              ${this.getMutatedUserRole(member.role)}
                                            </div>
                                          </div>
                                          <div class="text-sm text-gray-600">${member.user.email}</div>
                                        </div>
                                    </div>
                                  </div>`
          return teamMemberHtml
        }
      }
      return null
    },

    teamMemberTooltipHtmlComposer(user) {
      const memberRoleClass = this.getRoleClass(user.role)
      if (memberRoleClass) {
        return `<div class='team_detail_tooltip'>
                                <div class='t_inner d-flex align-items-center'>
                                    <div class='img' style='background: url(${this.teamMemberImageById(
            user._id
        )});'></div>
                                    <div class='t_content'>
                                        <h3>${user.firstname} ${
            user.lastname
        } <span class='${memberRoleClass} role'>${this.getMutatedUserRole(
            user.role
        )}</span></h3>
                                        <p>${user.email}</p>
                                    </div>
                                </div>
                            </div>`
      }
      return null
    },

    selectedTeamMemberDetailById(id) {
      let member = null
      $(this.getWorkspaces.activeWorkspace.members).each(function (index, el) {
        if (el.user && el.user._id === id) {
          member = el
          return false
        }
      })
      return member
    },
    teamMemberImageById(id) {
      let member = null
      $(this.getWorkspaces.activeWorkspace.members).each(function (index, el) {
        if (el.user && el.user._id === id) {
          member = el.user
          return false
        }
      })
      if (member && member.image) {
        return member.image
      }
      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },

    getTotalActiveMember() {
      let total = 0
      $(this.getWorkspaces.activeWorkspace.members).each(function (index, el) {
        if (el.status === 1 || el.status === 'joined') {
          total = total + 1
        }
      })
      return total
    },
  },
}
