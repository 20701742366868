export const NO_DATA_AVAILABLE =
  'No data found for this topic due to low engagement and articles published.'

export const NO_CHART_DATA = 'No data available'

// post related
export const POST_FAVORITE_ADDED = 'Post added to favorite folder!'
export const POST_FAVORITE_REMOVED = 'Post removed from favorite folder!'
export const POST_ARCHIVED_ADDED = 'Post archived successfully!'
export const POST_ARCHIVED_REMOVED = 'Post restored successfully!'
export const POSTS_REPORT_ERROR = 'Post restored successfully!'

// folders
export const FOLDER_UPDATED = 'Folder name updated successfully!'
export const FOLDER_ADDED = 'A new folder has been created!'
export const FOLDER_FAILED = 'An error occurred while creating a new folder!'

// curated topics
export const FOLLOWED = 'Added to your curated topics!'
export const UNFOLLOWED = 'Removed from your curated topics!'

// topic validation messages

export const TOPIC_VALIDATION = {
  TOPIC_NAME: 'Please enter a topic name',
  TOPIC_KEYWORDS: 'Please add the keywords and domains for your topic',
}
export const TOPIC_SAVED = 'Your topic has been saved!'

export const UNKNOWN_ERROR =
  'Uh-oh! An unknown error occurred, support has been notified.!'

// customize Side bar

export const UPDATE_CUSTOMIZE_SIDEBAR_FAIL =
  'Unable to customize your topics sidebar, please try again.'

// integrations messages start

// platform messages start
export const SAVE_PLATFORMS_SUCCESS =
  'Your account(s) have been successfully added.'
export const SAVE_PLATFORMS_ERROR =
  'Unable to add your account(s). Please try again.'
export const CREATE_BOARD_SUCCESS = 'Board has been successfully created.'
export const CREATE_BOARD_ERROR = 'Unable to create board.'
// platform messages end

// integrations messages end

// Replug Message Start
export const NULL_REPLUG_BRAND = 'Please select a replug brand.'
export const NULL_REPLUG_CAMPAIGN = 'Please select a replug campaign.'
// Replug Message Start

// Hashtags Message Start
export const INVALID_NAME_HASHTAG = 'Please enter name for Hashtags.'
export const NO_HASHTAG = 'Please enter hashtags'
export const INVALID_COUNT =
  'The number of hashtags should be greater than 0 and less than or equal to'
export const HASHTAG_SAVE_SUCCESS = 'Hashtag has been successfully saved.'
// Hashtags Message Start

// UTMs Message Start
export const UTM_INVALID_NAME = 'Please enter a name for UTM'
export const UTM_INVALID_SOURCE = 'Please enter a valid source for UTM'
export const UTM_SAVE_SUCCESS = 'UTM has been successfully saved!'
// UTMs Message End

// publish messages start
export const NULL_BLOG_OPTION_SELECTION_ERROR =
  'Please select blog(s) to continue.'
export const NULL_WORDPORESS_BLOG_SELECTION_ERROR =
  'Please select a WordPress blog/Website.'
export const WORDPORESS_BLOG_CONNECTION_ERROR =
  'Unable to connect to the selected WordPress site, please try again.'
export const BLOG_CONNECTION_ERROR =
  'Unable to connect to the selected blog, please try again.'
export const NULL_WORDPORESS_AUTHOR_SELECTION_ERROR =
  'Please select a WordPress blog/Website author.'
export const NULL_TUMBLR_BLOG_SELECTION_ERROR =
  'Please select a Tumblr blog/Website.'
export const NULL_MEDIUM_BLOG_SELECTION_ERROR =
  'Please select a Medium blog/Website.'
export const NULL_SHOPIFY_BLOG_SELECTION_ERROR = 'Please select a Shopify blog.'
export const NULL_SHOPIFY_AUTHOR_SELECTION_ERROR =
  'Please select a Shopify blog author.'
export const NULL_SOCIAL_ACCOUNT_SELECTION_ERROR =
  'Please select social account(s) to continue.'
export const NULL_BLOG_OPTION_PRIMARY_SELECTION_ERROR =
  'Please add a primary blog to continue.'
export const NULL_GMB_CALL_TO_ACTION =
  'A call to action requires a link for the generated button.'
export const INVALID_GMB_CALL_TO_ACTION_LINK =
  'Please enter a valid link for call to action.'
export const GMB_NULL_TITLE_ERROR = 'Title is required for GMB posting.'
export const GMB_TITLE_LENGTH_ERROR =
  'Title may not be greater than 57 characters.'
export const GMB_NULL_START_DATE_ERROR =
  'Start date is required for GMB posting.'
export const GMB_NULL_END_DATE_ERROR = 'End date is required for GMB posting.'
export const GMB_INVALID_DATES_ERROR =
  'End date should be greater than start date.'
export const INVALID_GMB_REDEEM_LINK = 'Please enter a valid redeem link.'
export const FETCH_GCS_LINK_ERROR =
  'Unable to process operation.Please try again.'
export const UPDATE_CANVA_DESIGN_ERROR =
  'Unable to update Canva design.Please try again.'
export const NULL_WEBFLOW_SITE = 'Please select a Webflow site.'
// publish messages end

// automation messages start
export const NULL_AUTOMATION_NAME_ERROR = 'Please enter a campaign name.'
export const SAVE_AUTOMATION_SUCCESS =
  'Your automation campaign has been saved.'
export const SAVE_AUTOMATION_ERROR =
  'Unable to add automation, please try again.'
export const AUTOMATION_REMOVAL_SUCCESS =
  'Automation campaign successfully removed.'
export const AUTOMATION_RESTART_SUCCESS =
  'Automation campaign successfully restarted.'
export const AUTOMATION_REMOVAL_ERROR =
  'Unable to remove automation.Please try again.'
export const AUTOMATION_RESTART_ERROR =
  'Unable to restart automation.Please try again.'
export const FETCH_AUTOMATION_ERROR =
  'Unable to get automation.Please try again.'
export const AUTOMATION_CHANGE_STATUS_ERROR =
  'Unable to change automation status.Please try again.'
export const AUTOMATION_PREFETCH_DAYS_GREATER_VALIDATION =
  'Please enter prefetch days limit greater than 1.'
export const AUTOMATION_PREFETCH_DAYS_LESS_VALIDATION =
  'Please enter prefetch days limit less than 100.'
export const AUTOMATION_YOUTUBE_API_VALIDATION =
  'Please add your YouTube Data API key in order to use YouTube for content planning.'

// Articles
export const AUTOMATION_NULL_TOPIC_SELCTION_ERROR = 'Please select topic(s).'
export const NULL_POST_AGE_ERROR = 'Please select a post age date.'
export const NULL_ENGAGEMENT_VALUE_ERROR =
  'Please add an engagement value greater than 0.'
export const AUTOMATION_PROCESSING_RESULTS_INFO =
  'Processing results, please wait'
export const AUTOMATION_NO_RESULT_ERROR =
  'The keyword(s) you entered did not return any set of results, try another keyword'

// RSS start

export const NULL_FEED_URL_ERROR = 'Please add a feed url.'
export const INVALID_FEED_URL_ERROR = 'Please enter a valid RSS feed url.'
export const RSS_AUTOMATION_LESS_POST_COUNT_ERROR =
  'Please enter a max post value greater than 0.'
export const RSS_AUTOMATION_GREATER_POST_COUNT_ERROR =
  'Please enter a max post value less than or equal to 10.'

// RSS end

// automation messages end

// composer blog start
export const COMPOSER_BLOG_PRIMARY_ALREADY_EXIST =
  'This blog is already added as primary.'
export const COMPOSER_BLOG_SECONDARY_ALREADY_EXIST =
  'This blog is already added as secondary.'
export const COMPOSER_SEO_TITLE_REQUIRED = 'Please add SEO title.'
export const COMPOSER_SEO_URL_REQUIRED = 'Please add SEO url.'

export const COMPOSER_BLOG_PREVIEW_TITLE_REQUIRED =
  'Please add a Post Name (Title).'

// composer blog end

// composer assistant start

export const COMPOSER_POCKET_ACCOUNT_REQUIRED =
  'Please select a pocket account first.'
export const COMPOSER_SEARCH_STRING_REQUIRED = 'Enter some string to search.'

// composer assistant end

// composer Publication start

export const COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED =
  'Please enter a Campaign Folder name.'
export const COMPOSER_PUBLICATION_FOLDER_COLOR_REQUIRED =
  'Please select a Campaign Folder color.'
export const COMPOSER_PUBLICATION_FOLDER_MOVE_REQUIRED =
  'Please select a Campaign Folder.'
export const COMPOSER_PUBLICATION_FOLDER_CREATED_SUCCESS =
  'Campaign Folder is successfully created.'
export const COMPOSER_PUBLICATION_FOLDER_MOVE_SUCCESS =
  'Campaign Folder successfully moved.'
export const COMPOSER_PUBLICATION_FOLDER_CREATED_FAIL =
  'Unable to move Campaign Folder.'
export const COMPOSER_PUBLICATION_FOLDER_MOVE_FAIL = 'Unable to move plan.'
export const COMPOSER_PUBLICATION_FOLDER_UPDATE_FAIL =
  'Unable to rename Campaign Folder.Please try again.'

// composer Publication end

// notification Message start
export const IN_VALID_CHOICE_NOTIFICATIONS =
  'Please select how often we should send you email notifications'
export const BROWSER_NOTIFICATION_DISABLED =
  'Your browser notifications are disabled, please enable them to receive browser notifications.'
// Notification message end

// Labels
export const LABEL_NAME_MISSING = 'Please enter a label name'
export const LABEL_COLOR_MISSING = 'Please select a label color'
// Labels End

// Tasks
export const NO_TASK = 'Field cannot be empty'
// Tasks end

// sources

export const FILE_BIG = 'File size too big'
export const NO_FILE_SELECTED = 'No file selected'

// analytics

export const SYNC_ERROR =
  'Your data was synced in last 3 hours. You can sync after every 3 hours!'

export const TOTAL_GROUP_POSTS_TOOLTIP =
  'The total number of posts published from your social channels during the selected period.'
export const TOTAL_GROUP_REACTIONS_TOOLTIP =
  'The total number of reactions such as likes your posts received from your social channels during the selected period.'
export const TOTAL_GROUP_REPOSTS_TOOLTIP =
  'The total number of shares your posts received from your social channels during the selected period.'
export const TOTAL_GROUP_COMMENTS_TOOLTIP =
  'The total number of comments your posts received from your social channels during the selected period.'
export const TOTAL_GROUP_ENGAGEMENT_TOOLTIP =
  'The total number of engagement your posts received from your social channels during the selected period. Engagement includes likes, reactions, comments etc.'
export const TOTAL_GROUP_AVERAGE_ENGAGEMENT_TOOLTIP =
  'The average of engagement your posts received from your social channels during the selected period. Engagement includes likes, reactions, comments etc.'
export const ANALYTICS_PAGE_IMPRESSION_TOOLTIP =
  "The total number of times your Page's content was seen during the selected period."
export const ANALYTICS_LINKEDIN_ENGAGEMENT_TOOLTIP =
  'The total number of actions (clicks, shares, comments, and reactions) that people took on your Linkedin Page and its posts during the selected period.'
export const ANALYTICS_LINKEDIN_FAVORITES_TOOLTIP =
  'The total number of likes that people took on your Linkedin Page and its posts during the selected period.'
export const ANALYTICS_LINKEDIN_FOLLOWERS_TOOLTIP =
  'The total number of followers your Linkedin Page.'
export const ANALYTICS_FACEBOOK_FANS_TOOLTIP =
  'The total number of fans (Page likes) on your Facebook page right now.'
export const ANALYTICS_FACEBOOK_ENGAGEMENT_TOOLTIP =
  'The total number of actions (clicks, shares, comments, and reactions) that people took on your Linkedin Page and its posts during the selected period.'
export const ANALYTICS_REACTIONS_TOOLTIP =
  'The total number of reactions on your posts during the selected period.'
export const ANALYTICS_CLICKS_TOOLTIP =
  'The total number of clicks on your posts during the selected period.'
export const ANALYTICS_FEEDBACK_TOOLTIP =
  'The total number of feedback you received on Facebook Page during the selected period.'
export const ANALYTICS_SENTIMENT_TOOLTIP =
  'The total number of postive or negative sentiment received on Facebook Page during the selected period.'
export const ANALYTICS_MESSAGE_COUNT_TOOLTIP =
  'The total number of messages you received on Facebook Page during the selected period.'
export const ANALYTICS_MESSAGE_POSTS_COUNT =
  'The total number of messages you received on Facebook Page during the selected period.'
export const ANALYTICS_PINS_TOOLTIP =
  'The total number of pins published on your Pinterest Board during the selected period.'
export const ANALYTICS_PINTEREST_ENGAGEMENT_TOOLTIP =
  'The total number of actions (clicks and repins) published on your Pins during the selected period.'
export const ANALYTICS_PINTEREST_FOLLOWERS_TOOLTIP =
  'The total number of followers on your Pinterest profile.'
export const ANALYTICS_PINTEREST_FOLLOWING_TOOLTIP =
  'The total number of people you are following on your Pinterest profile.'
export const ANALYTICS_PINTEREST_BOARDS_TOOLTIP =
  'The total number of boards you have created on your Pinterest profile.'
export const ANALYTICS_TWITTER_RETWEETS_TOOLTIP =
  'The total number of retweets on your published tweets during the selected period.'
export const ANALYTICS_TWITTER_POSTS_TOOLTIP =
  'The total number of posts you have published on your X (Twitter) profile during the selected period.'
export const ANALYTICS_TWITTER_FOLLOWERS_TOOLTIP =
  'The total number of people that are following you on X (Twitter).'
export const ANALYTICS_TWITTER_FOLLOWING_TOOLTIP =
  'The total number of people you are following on your X (Twitter).'
export const ANALYTICS_TWITTER_ENGAGEMENT_TOOLTIP =
  'The total number of actions (retweets, favorites and comments) that people took on your Posts and its posts during the selected period.'

// analytics boxes messages

export const OVERVIEW_ANALYTICS_POSTS_PUBLISHED =
  'Number of posts published with their engagements.'
export const OVERVIEW_ANALYTICS_TOP_POSTS =
  'Top performing posts on your social channels during the selected period.'
export const OVERVIEW_ANALYTICS_ROLL_UP =
  'Top performing posts on your social channels during the selected period.'
export const OVERVIEW_ANALYTICS_PERFORMANCE =
  'Account performance based on their engagement.'
export const OVERVIEW_ANALYTICS_INTERACTION =
  'Individual social channel performance based on their engagement.'

// social channel charts messages

export const FACEBOOK_ANALYTICS_PUBLISHED_MESSAGES =
  'Total number of posts published with their engagement during the selected period.'
export const FACEBOOK_ANALYTICS_POST_RATE =
  'Total number of posts published with their type (photos, links and videos) during the selected period.'
export const FACEBOOK_ANALYTICS_IMPRESSIONS =
  'Number of paid, organic and viral impressions during the selected period.'
export const FACEBOOK_ANALYTICS_FANS = 'Overview of Facebook Page fans by age.'
export const FACEBOOK_ANALYTICS_GENDER =
  'Overview of Facebook Page fans by gender.'
export const FACEBOOK_ANALYTICS_VIDEO =
  'Number of paid, organic, and viral impressions along with click-to-play and engagement for video posts during the selected period.'
export const FACEBOOK_ANALYTICS_ENGAGEMENT =
  'The total number of actions (comments, shares and reactions) that people took on your posts during the selected period.'
export const FACEBOOK_ANALYTICS_POST_DENSITY =
  'Your posting habits per day. This data is updated approximately every hour.'
export const FACEBOOK_ANALYTICS_AUDIENCE_GROWTH =
  'See how your Facebook community is growing over time. Earliest data available is from 3 months before your registration on our platform. This data is updated once a day.'
export const FACEBOOK_ANALYTICS_POSTS_CLICKS_HISTORY =
  ' The evolution of your posts over time on selected period.'
export const FACEBOOK_ANALYTICS_BEST_TIME_TO_POST =
  'Discover when your Facebook posts trigger the most engagement. The darker the square, the better the engagement is.'

export const INSTAGRAM_ANALYTICS_PUBLISHED_MESSAGES =
  'Total number of posts published with their engagement during the selected period.'
export const INSTAGRAM_ANALYTICS_POST_RATE =
  'Total number of posts published with their type (photos, links and videos) during the selected period.'
export const INSTAGRAM_ANALYTICS_IMPRESSIONS =
  'Number of paid, organic and viral impressions during the selected period.'
export const INSTAGRAM_ANALYTICS_FANS = 'Overview of Facebook Page fans by age.'
export const INSTAGRAM_ANALYTICS_GENDER =
  'Overview of Facebook Page fans by gender.'
export const INSTAGRAM_ANALYTICS_VIDEO =
  'Number of paid, organic, and viral impressions along with click-to-play and engagement for video posts during the selected period.'
export const INSTAGRAM_ANALYTICS_ENGAGEMENT =
  'The total number of actions (comments, shares and reactions) that people took on your posts during the selected period.'
export const INSTAGRAM_ANALYTICS_POST_DENSITY =
  'Your posting habits per day. This data is updated approximately every hour.'
export const INSTAGRAM_ANALYTICS_AUDIENCE_GROWTH =
  'See how your Instgram community is growing over time. Earliest data available is from 3 months before your registration on our platform. This data is updated once a day.'
export const INSTAGRAM_ANALYTICS_POSTS_CLICKS_HISTORY =
  ' The evolution of your posts over time on selected period.'
export const INSTAGRAM_ANALYTICS_BEST_TIME_TO_POST =
  'Discover when your Facebook posts trigger the most engagement. The darker the square, the better the engagement is.'

export const TWITTER_ANALYTICS_AWARENESS =
  'Number of tweets with your brand name, brand keywords or links to your website.'
export const TWITTER_ANALYTICS_AUDIENCE_GROWTH =
  'Number of fans gained and lost during the selected period.'
export const TWITTER_ANALYTICS_ENGAGEMENT =
  'Number of likes and retweets on your tweets during the selected period.'
export const TWITTER_ANALYTICS_PUBLISHED_MESSAGES =
  'Total number of posts published with their engagement (likes, retweets) during the selected period.'
export const TWITTER_ANALYTICS_TOP_HASHTAGS =
  "Interactions you have received on the hashtags you've used."

export const LINKEDIN_ANALYTICS_AUDIENCE_GROWTH =
  'Number of fans gained and lost during the selected period.'
export const LINKEDIN_ANALYTICS_ENGAGEMENT =
  'Number of likes and comments received on your posts during the selected period.'
export const LINKEDIN_ANALYTICS_PUBLISHED_MESSAGES =
  'Total number of posts published with their engagement during the selected period.'

export const PINTEREST_ANALYTICS_AUDIENCE_GROWTH =
  'Number of fans gained and lost during the selected period.'
export const PINTEREST_ANALYTICS_ENGAGEMENT =
  'Number of repins and comments received on your posts during the selected period.'
export const PINTEREST_ANALYTICS_PUBLISHED_MESSAGES =
  'Total number of posts published with their engagement during the selected period.'

// twitter influencers

export const TWITTER_INFLUENCER_LIST_NAME = 'Name cannot be empty.'
export const TWITTER_INFLUENCER_LIST_DESCRIPTION =
  'Description cannot be empty.'
export const TWITTER_INFLUENCER_FOLDER_SAVE = 'Your new list is created.'
export const TWITTER_INFLUENCER_TWITTER_ACCOUNT_REQUIRED =
  'Please select a X (Twitter) account to continue.'
export const TWITTER_INFLUENCER_TWITTER_EMPTY_TEXTAREA =
  'Message box is empty, please add something to post.'

// Integrations messages
export const SAVE_REWRITING_ACCOUNT_SUCCESS = 'Your account successfully saved.'
export const SAVE_REWRITING_ACCOUNT_ERROR =
  'Invalid credentials provided, please enter a valid credentials.'
export const REMOVE_INTEGRATION_ACCOUNT_ERROR = 'Unable to remove account.'
export const PAUSE_WORKSPACE_POSTING_ERROR =
  'Unable to pause workspace posting.'
export const UNPAUSE_WORKSPACE_POSTING_ERROR =
  'Unable to unpause workspace posting.'
export const FETCH_PAUSE_POSTING_COUNT_ERROR =
  'Unable to fetch on hold posting.'
export const REWRITING_NULL_USERNAME_ERROR = 'Please enter a username.'
export const REWRITING_NULL_EMAIL_ERROR = 'Please enter an email address.'
export const REWRITING_NULL_PASSWORD_ERROR = 'Please enter a password.'
export const REWRITING_NULL_KEY_ERROR = 'Please enter api key.'
export const BLOG_NULL_URL_ERROR = 'Blog URL cannot be empty.'
export const BLOG_INVALID_URL_ERROR = 'Please enter a valid URL.'
export const BLOG_NULL_USERNAME_ERROR = 'Blog Username cannot be empty.'
export const BLOG_NULL_PASSWORD_ERROR = 'Blog Password cannot be empty.'
export const FETCH_BLOG_ERROR =
  'An error occurred while receiving your blog details, please try again.'
export const SAVE_BLOG_SUCCESS = 'Blog/website has been successfully added.'
export const SAVE_BLOG_ERROR = 'Unable to save blog/website. Please try again.'
export const BLOG_NULL_STORE_ERROR = 'Store cannot be empty.'
export const BLOG_NULL_API_KEY_ERROR = 'API key cannot be empty.'
export const BLOG_NULL_API_SECRET_ERROR = 'API secret cannot be empty.'
export const EMPTY_BOARD_NAME_ERROR = 'Please enter board name.'
export const EMPTY_BOARD_PROFILE_ERROR = 'Please select profile.'
export const EMPTY_DEVICES_ERROR = 'You have not connected any device yet.'
export const DEVICE_NOTIFICATION_SETTING_SAVING_ERROR =
  'Unable to update device notification setting.Please try again.'
export const FETCH_QUEUE_SLOTS_ERROR = 'Unable to get queue times.'
export const UPDATE_QUEUE_SLOTS_SUCCESS = 'Queue Time successfully updated.'
export const UPDATE_QUEUE_SLOTS_ERROR = 'Unable to update queue time.'
export const ACCOUNT_NOT_ENOUGH_DATA_ERROR =
  'Your account have not enough data.'
export const SLOTS_EMPTY_DAYS_SELECTION_ERROR =
  'Please select some time day(s).'
export const SLOTS_EMPTY_TIME_SELECTION_ERROR =
  'Please select some time(s) for '
export const SLOTS_INVALID_TIME_SELECTION_ERROR =
  'Please Enter valid time value for '

// Planner messages
export const PLAN_APPROVAL_SUCCESS =
  "The post you've approved has been moved to 'Scheduled' posts."
export const PLAN_REJECT_SUCCESS =
  "The post you've rejected has been moved to 'Rejected' posts."
export const CHANGE_PLAN_STATUS_ERROR =
  'Unable to process operation.Please try again.'
export const RESCHEDULE_PLAN_ERROR =
  'Unable to reschedule content.Please try again.'
export const RESCHEDULE_PUBLISHED_PLAN_ERROR =
  'Your post has been already published.'
export const TIME_PASSED_RESCHEDULE_PLAN_ERROR =
  'You cannot reschedule post to the passed time.'
export const DRAFT_POST_RESCHEDULE_PLAN_ERROR =
  'You cannot reschedule draft post.'
export const CC_POST_RESCHEDULE_PLAN_ERROR =
  'You cannot reschedule content category post.'
export const REMOVE_PLAN_ERROR = 'Unable to remove plan.'
export const REPLACE_PLAN_ERROR = 'Unable to replace plan.'
export const BULK_OPERATION_SUCCESS = 'Operation is performed successfully.'
export const BULK_OPERATION_ERROR =
  'An error occurred while performing action, please try again.'
export const PRIMARY_POST_REMOVAL_DUPLICATION_ERROR =
  'You cannot duplicate this post because primary post has been removed.'
export const PRIMARY_POST_REMOVAL_EDIT_ERROR =
  'You cannot edit this post because primary post has been removed.'
export const SEND_POST_NOTIFICATION_SUCCESS =
  'Push notification/reminder sent to your mobile device.'
export const SEND_POST_NOTIFICATION_ERROR =
  'Failed to send push notification/reminder to your mobile device.'

export const ROLE_CHANGED_MESSAGE = 'Your team member role has been changed.'
