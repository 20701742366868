<template>
  <div
    class="relative m-0 hover:text-black-900 cst-simple-checkbox"
    :class="{ 'opacity-60': disabled }"
  >
    <label class="cursor-pointer m-0 p-0 d-flex justify-between items-center">
      <span v-show="hasLabelSlot" class="flex items-center flex-1 relative">
        <slot name="label"></slot>
      </span>

      <span class="text-xs relative">
        <slot name="count"></slot>
      </span>
      <slot name="error">
        <input
            v-model="model"
            class="opacity-0 absolute h-8 w-8"
            name="name"
            :checked="isChecked"
            :disabled="disabled"
            type="checkbox"
            :value="name"
            @change="$emit('change', $event)"
        />
        <span
            v-if="!disabled"
            class="relative cursor-pointer bg-white rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500"
            :class="{ 'ml-2': hasLabelSlot }"
        >
        <svg
            class="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none"
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-9 -11)" fill="#0088cc" fill-rule="nonzero">
              <path
                  d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
              />
            </g>
          </g>
        </svg>
      </span>
        <div v-else>
          <i class="fas fa-exclamation-triangle mr-0" aria-hidden="error" title="Error indicator"></i>
        </div>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SimpleCheckboxVue',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, Boolean],
      default: () => [],
    },
    name: {
      type: [String, Number],
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
    }
  },
  computed: {
    hasLabelSlot() {
      return !!this.$slots.label
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    checked(val) {
      console.log('watch', this.checked)
      this.isChecked = val
    },
  },
  mounted() {
    this.isChecked = this.checked
  },
}
</script>
