import http from '@common/lib/http-common'
import pick from 'lodash.pick'
import {
  NULL_AUTOMATION_NAME_ERROR,
  NULL_SOCIAL_ACCOUNT_SELECTION_ERROR
} from '@common/constants/messages'
import {
  articleAutomationBulkOperationUrl,
  changeArticleAutomationStatusUrl,
  fetchArticleBlogAutomationsUrl,
  fetchArticleSocialAutomationsUrl,
  removeArticleAutomationUrl,
  saveArticleBlogAutomationUrl,
  saveArticleSocialAutomationUrl
} from '../../config/api-utils'
import { article } from './mutation-types'

const getDefaultArticleAutomation = () => {
  return {
    id: '',
    name: '',
    status: 0,

    article: {
      build_type: 'new',
      topics: [],
      post_age: {
        value: 180,
        text: 'Last 6 months'
      },
      post_date: '',
      engagement_type: 'None',
      engagement_count: '',
      skip_without_image: true
    },
    original_source: true,
    remove_anchors_links: false,
    mention_author: true,
    prefetch_days_limit: 2,
    remove_posts: false,
    review_content: false,
    post_variations: 'post_title',
    next_posting: -1
  }
}
const getDefaultArticleAutomationTabStatus = () => {
  return {
    first: true,
    second: false,
    third: false,
    fourth: false
  }
}

const getDefaultArticleAutomationListing = () => {
  return {
    items: [],
    search: '',
    page: 1,
    limit: 20,
    nextPage: false,
    previousPage: false,
    fetchStatus: false
  }
}
const getDefaultArticleAutomationValidations = () => {
  return {
    validations: {
      name: false,
      social_accounts: false
    },
    messages: {
      name: NULL_AUTOMATION_NAME_ERROR,
      social_accounts: NULL_SOCIAL_ACCOUNT_SELECTION_ERROR
    }
  }
}

export default {
  state: {
    selection: getDefaultArticleAutomation(),
    validate: getDefaultArticleAutomationValidations(),
    tabStatus: getDefaultArticleAutomationTabStatus(),

    listing: {
      select_all: false,
      selected: [],
      blog: getDefaultArticleAutomationListing(),
      social: getDefaultArticleAutomationListing()
    },
    loaders: {
      social: {
        retrieve: false
      },
      blog: {
        retrieve: false
      },
      all: {
        store: false
      }
    }
  },
  actions: {
    async fetchArticleToSocialAutomations (
      { commit, getters, dispatch },
      page = 1
    ) {
      // pick the selected fields

      const payload = pick(getters.getArticleSocialAutomationListing, [
        'search',
        'limit'
      ])
      payload.page = page

      // set the loader
      commit(article.SET_SOCIAL_AUTOMATION_RETRIEVE_LOADER, true)

      // send the async request

      await http
        .post(fetchArticleSocialAutomationsUrl, {
          ...payload,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          platformSelection: getters.getPublishSelection.platforms.selection
        })
        .then((res) => {
          // set the automations list

          const items = res.data.automations.data
            ? res.data.automations.data
            : []
          const nextPage = !!res.data.automations.next_page_url
          const previousPage = !!res.data.automations.prev_page_url
          commit(article.SET_SOCIAL_AUTOMATION_ITEMS, items)
          commit(article.SET_SOCIAL_AUTOMATION_LISTING_PAGINATION, {
            nextPage,
            previousPage,
            page
          })
          return res
        })
        .catch(() => {
          return null
        })

      // remove the loader
      commit(article.SET_SOCIAL_AUTOMATION_RETRIEVE_LOADER, false)
    },
    async fetchArticleToBlogAutomations (
      { commit, getters, dispatch },
      page = 1
    ) {
      // pick the selected fields

      const payload = pick(getters.getArticleBlogAutomationListing, [
        'search',
        'limit'
      ])
      payload.page = page

      // set the loader
      commit(article.SET_BLOG_AUTOMATION_RETRIEVE_LOADER, true)

      // send the async request

      await http
        .post(fetchArticleBlogAutomationsUrl, {
          ...payload,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          platformSelection: getters.getPublishSelection.platforms.selection
        })
        .then((res) => {
          // set the automations list

          // set the items, next page and previous page url.
          const items = res.data.automations.data
            ? res.data.automations.data
            : []
          const nextPage = !!res.data.automations.next_page_url
          const previousPage = !!res.data.automations.prev_page_url
          commit(article.SET_BLOG_AUTOMATION_ITEMS, items)
          commit(article.SET_BLOG_AUTOMATION_LISTING_PAGINATION, {
            nextPage,
            previousPage,
            page
          })
          return res
        })
        .catch(() => {
          return null
        })

      // remove the loader
      commit(article.SET_BLOG_AUTOMATION_RETRIEVE_LOADER, false)
    },

    /**
     * Change the article automation status, a user can activate/deactivate their status.
     * @param commit
     * @param getters
     * @param dispatch
     * @param automation object
     * @param automation._id Id of the existing automation campaign
     * @param automation.status status of the campaign 1 or 0
     * @returns {Promise<void>}
     */
    async changeArticleAutomationStatus (
      { commit, getters, dispatch },
      automation
    ) {
      console.log('article automation status change...')
      const status = automation.status ? 1 : 0
      http
        .post(changeArticleAutomationStatusUrl, {
          status,
          id: automation._id,
          workspace_id: getters.getActiveWorkspace._id
        })
        .then((res) => {
          if (res.data.status) automation.status = status
        })
        .catch(() => {
          return null
        })
    },
    /**
     * Delete an existing article automation campaign
     * @param commit
     * @param getters
     * @param dispatch
     * @param payload Payload object
     * @param payload.is social or blog
     * @param payload.index index element value
     */
    deleteArticleAutomation ({ commit, getters, dispatch }, payload) {
      http
        .post(removeArticleAutomationUrl, {
          automation_id: payload._id,
          workspace_id: getters.getActiveWorkspace._id
        })
        .then((res) => {
          if (res.data.status) {
            // remove the existing item
            payload.is === 'social'
              ? getters.getArticleSocialAutomationListing.items.splice(
                payload.index,
                1
              )
              : getters.getArticleBlogAutomationListing.items.splice(
                payload.index,
                1
              )

            // remove the campaigns from the planner dropdown filter.

            const automationIndex = getters.getAllAutomations.article.findIndex(
              (item) => item._id === payload._id
            )
            getters.getAllAutomations.splice(automationIndex, 1)
          }
        })
        .catch(() => {
          return null
        })
    },
    /**
     *
     * @param commit
     * @param getters
     * @param dispatch
     * @param payload object {state, type} (state includes Active, Inactive, Delete), type can be (Blog or Social) campaign
     */
    bulkOperationArticleAutomation ({ commit, getters, dispatch }, payload) {
      const state = payload.state
      const automation = getters.getArticleAutomationListing
      http
        .post(articleAutomationBulkOperationUrl, {
          operation: payload.state,
          automation_ids: getters.getArticleAutomationListing.selected,
          workspace_id: getters.getActiveWorkspace._id
        })
        .then((res) => {
          if (res.data.status) {
            console.log(
              '[bulk operation for rss] for state',
              state.toLowerCase(),
              automation.selected
            )
            switch (state.toLowerCase()) {
              case 'active':
              case 'inactive':
                // check the status, if active set to 1 or else 0.
                const status = state.toLowerCase() === 'active' ? 1 : 0

                automation.selected.forEach((item) => {
                  // iterate all of the campaigns and change their status.
                  automation[payload.type.toLowerCase()].items.map(
                    (automationItem) => {
                      if (item === automationItem._id) {
                        console.log(
                          '[automationItem status change] for item ',
                          item
                        )
                        automationItem.status = status
                      }
                    }
                  )
                })
                break
              case 'delete':
                // in case of the delete, refetch all of the campaigns again
                payload.type.toLowerCase() === 'blog'
                  ? dispatch('fetchArticleToBlogAutomations')
                  : dispatch('fetchArticleToSocialAutomations')
                /* // NOTE: let say user remove multiple items, what do we need to do in this case, brainstorm it...
              rssListing.selected.forEach(item => {
                getters.getRssAutomationListing.items.splice(rssListing.items.findIndex(rssItem => item === rssItem._id), 1)
              }) */
                break
            }
            commit(article.SET_SELECTED_ARTICLE_AUTOMATIONS, [])
            commit(article.SET_ARTICLE_AUTOMATION_SELECT_ALL_FILTER, false)
          }
        })
        .catch(() => {})
    },

    async storeArticleAutomation (
      { commit, getters, dispatch },
      { type, activate = false }
    ) {
      // get the hashtag, replug and other payload details
      const hashtag = getters.getNewWorkspaceHashtag
      const replug = getters.getPublishSelection.replug
      const payload = getters.getArticleAutomationDetails

      // remove the status and next posting time.

      delete payload.status
      delete payload.next_posting

      // if it is cloned/duplicate of the campaign, remove the posts key
      if (!payload.id) {
        delete payload.remove_posts
      }

      // prepare the uri fo the storage, article to social or article to blog.
      const storeArticleAutomationURI =
        type === 'blog'
          ? saveArticleBlogAutomationUrl
          : saveArticleSocialAutomationUrl
      if (type === 'blog') {
        // for the blog case, we will have the selection and rewriting selection
        payload.blog_selection = await dispatch(
          'blogSelectionForPrimarySecondary',
          'automation'
        )
        payload.rewriting_selection = getters.getRewritingSelection
      }

      // hashtags settings
      payload.hashtag = { status: getters.getPublishSelection.hashtag.status }
      if (payload.hashtag.status) {
        payload.hashtag = {
          ...payload.hashtag,
          id: getters.getPublishSelection.hashtag.selected,
          ...pick(hashtag, ['name', 'items', 'count', 'position'])
        }
        console.log(payload.hashtag)
      }
      // replug settings

      payload.replug = { status: replug.status }

      if (replug.status) {
        payload.replug.brand = replug.brand._id
        payload.replug.campaign = replug.campaign._id
        payload.replug.custom_domain = !!replug.campaign.domainId
      }
      // }

      // article settings and schedule time

      payload.article.topic_selection = getters.getTopicSelection
      payload.article.type = getters.getTopicsType
      payload.article.region = getters.getTopicsRegion
      payload.article.language = getters.getTopicsLanguage
      payload.account_selection = getters.getAccountSelection
      payload.schedule_options = getters.getAutomationScheduleOptions

      payload.workspace_id = getters.getActiveWorkspace._id

      // if the user is storing the campaign, but currently the status was paused and user given the permission to activate it.
      if (activate) payload.activate = true
      commit(article.SET_ARTICLE_AUTOMATION_STORE_LOADER, true)

      const res = await http
        .post(storeArticleAutomationURI, payload)
        .then((res) => {
          if (res.data.status) {
            if (res.data.data) {
              // dealing with the existing campaign, update its data.
              if (payload.id && payload.id.length > 3) {
                const findIndex = getters.getArticleAutomationListing[
                  type
                ].items.findIndex((item) => item._id === payload.id)
                getters.getArticleAutomationListing[type].items.splice(
                  findIndex,
                  1,
                  res.data.data
                )
              } else {
                // add new campaign to the list
                getters.getArticleAutomationListing[type].items.unshift(
                  res.data.data
                )
              }
            }

            // track the event for the gosquared etc.
            dispatch('trackEvent', {
              event: `article_to_${type}_recipe_created`
            })
          } else {
            dispatch('toastNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
          return res
        })
        .catch(() => {
          return null
        })
      commit(article.SET_ARTICLE_AUTOMATION_STORE_LOADER, false)
      return res
    },

    setArticleAutomationListingSelectedAll ({ commit }, items) {
      commit('setArticleAutomationListingSelectedAll', items)
    },

    setDefaultArticleAutomationStates ({ commit }) {
      commit('setDefaultArticleAutomationStates')
    }
  },
  mutations: {
    [article.SET_AUTOMATION_NEXT_POSTING] (state, status) {
      state.selection.next_posting = status
    },
    [article.SET_ARTICLE_AUTOMATION_SELECT_ALL_FILTER] (state, value) {
      state.listing.select_all = value
    },
    [article.SET_SELECTED_ARTICLE_AUTOMATIONS] (state, value) {
      state.listing.selected = value
    },
    [article.SET_SOCIAL_AUTOMATION_ITEMS] (state, value) {
      state.listing.social.items = value
    },
    [article.SET_SOCIAL_AUTOMATION_LISTING_PAGINATION] (
      state,
      { nextPage = false, previousPage = false, page = 1 }
    ) {
      state.listing.social.nextPage = nextPage
      state.listing.social.previousPage = previousPage
      state.listing.social.page = page
    },
    [article.SET_SOCIAL_AUTOMATION_RETRIEVE_LOADER] (state, value) {
      state.loaders.social.retrieve = value
    },

    [article.SET_BLOG_AUTOMATION_ITEMS] (state, value) {
      state.listing.blog.items = value
    },
    [article.SET_BLOG_AUTOMATION_LISTING_PAGINATION] (
      state,
      { nextPage = false, previousPage = false, page = 1 }
    ) {
      state.listing.blog.nextPage = nextPage
      state.listing.blog.previousPage = previousPage
      state.listing.blog.page = page
    },

    [article.SET_BLOG_AUTOMATION_RETRIEVE_LOADER] (state, value) {
      state.loaders.blog.retrieve = value
    },
    [article.SET_ARTICLE_AUTOMATION_STORE_LOADER] (state, value) {
      state.loaders.all.store = value
    },

    [article.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS] (state, value) {
      if (value) {
        Object.keys(state.tabStatus).forEach((item, index) => {
          console.log(item, value === item)
          state.tabStatus[item] = value === item
        })
      }
    },
    [article.SET_ARTICLE_AUTOMATION_VALIDATION_VALUE] (state, { key, value }) {
      state.validate.validations[key] = value
    },
    [article.SET_ARTICLE_AUTOMATION] (state, automation) {
      if (automation) {
        state.selection = Object.assign(
          getDefaultArticleAutomation(),
          pick(automation, [
            'id',
            'name',
            'status',
            'article',
            'original_source',
            'remove_anchors_links',
            'mention_author',
            'prefetch_days_limit',
            'review_content',
            'post_variations'
          ])
        )
        state.selection.next_posting = -1
        state.selection.remove_posts = false
      } else {
        state.selection = getDefaultArticleAutomation()
      }
      state.tabStatus = getDefaultArticleAutomationTabStatus()
    },
    [article.RESET_ARTICLE_AUTOMATION] (state) {
      state.selection = getDefaultArticleAutomation()
      state.tabStatus = getDefaultArticleAutomationTabStatus()
    },
    [article.SET_ARTICLE_AUTOMATION_POST_AGE] (state, value) {
      state.selection.article.post_age = value
    },
    [article.SET_ARTICLE_AUTOMATION_POST_DATE] (state, value) {
      state.selection.article.post_date = value
    },
    [article.SET_ARTICLE_AUTOMATION_ENGAGEMENT_TYPE] (state, value) {
      state.selection.article.engagement_type = value
    },

    setArticleAutomationListingSelectedAll (state, status) {
      state.listing.select_all = status
    },

    setDefaultArticleAutomationStates (state) {
      state.selection = getDefaultArticleAutomation()
      state.tabStatus = getDefaultArticleAutomationTabStatus()
      state.listing = {
        select_all: false,
        selected: [],
        blog: getDefaultArticleAutomationListing(),
        social: getDefaultArticleAutomationListing()
      }
    }
  },
  getters: {
    getArticleAutomationLoaders: (state) => state.loaders,
    getArticleAutomationDetails: (state) => state.selection,
    getArticleAutomationValidations: (state) => state.validate,
    getArticleAutomationTabStatus: (state) => state.tabStatus,
    getArticleAutomationListing: (state) => state.listing,
    getArticleBlogAutomationListing: (state) => state.listing.blog,
    getArticleSocialAutomationListing: (state) => state.listing.social
  }
}
