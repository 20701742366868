<template>
  <div
    v-if="commentType === 'comments'"
    class="flex flex-col bg-white shadow-sm border !border-[#eef1f4] rounded-md"
  >
    <div
      v-b-toggle.accordion-1
      class="flex justify-between items-center p-3 cursor-pointer"
    >
      <div class="flex flex-row items-center space-x-2">
        <div>
          <img
            width="20"
            height="20"
            :src="require('@assets/img/icons/planner/post_comments.svg')"
            alt="post_comment"
          />
        </div>
        <h3 class="text-base font-normal">Comments</h3>
        <div
          v-if="totalComments"
          class="bg-red-500 p-0.5 rounded-full w-5 h-5 flex items-center justify-center text-white"
          >{{ totalComments }}</div
        >
      </div>
      <i class="fas fa-chevron-down"></i>
    </div>
    <b-collapse
      id="accordion-1"
      v-model="expansionComments"
      accordion="my-accordion"
      role="tabpanel"
      visible
    >
      <div class="flex-1 p-3">
        <!-- Comments Display -->
        <template v-if="getSortedComments.length > 0">
          <div
            v-for="(message, index) in getSortedComments"
            :key="`comments_v2_${index}`"
          >
            <!-- internal Comments Display -->
            <div v-if="message.is_internal">
              <CommentCard
                :message="message"
                :plan-id="plan._id"
                @update-comment="updateComment"
                @open-image-light-box="previewMediaImage"
                @update-comment-delete-list="updateCommentDeleteList"
              ></CommentCard>
            </div>
            <!-- external Comments Display -->
            <div v-else class="p-3 mb-2 flex flex-row bg-[#FBFCE1] rounded-md">
              <div class="ml-2 flex-1">
                <div>
                  <span
                    v-tooltip="message.email"
                    class="font-semibold mr-1"
                    >{{ message.name }}</span
                  >
                  <p class="whitespace-pre-wrap text-sm">{{ message.comment }}</p>
                </div>
                <div class="flex justify-between mt-1.5">
                  <div class="text-xs text-gray-700 my-auto">{{
                    moment.parseZone(message.created_at).utc(true).fromNow()
                  }}</div>
                  <v-menu
                    :popper-triggers="['hover']"
                    placement="bottom-end"
                    popper-class="threaded-tweets__info-popover"
                  >
                    <!-- This will be the popover target (for the events and position) -->
                    <img
                      src="@assets/img/composer/help-icon.svg"
                      alt=""
                      class="w-4 h-4"
                    />
                    <!-- This will be the content of the popover -->
                    <template v-slot:popper>
                      <p class="">External Comment Added by Shareable link</p>
                    </template>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="py-2 border rounded-md !border-[#eef1f4] p-2">
          <AtTa
            v-model="comment.text"
            class="mentions-bottom"
            :hover-select="true"
            :members="getActiveWorkspaceMembersName"
          >
            <textarea
              id="comment-box-top"
              ref="commented"
              v-model="comment.text"
              :disabled="loader.comments || loader.file"
              class="rounded-md w-full py-2 border-0 text-sm"
              maxlength="2200"
              placeholder="Add your comment here"
              rows="4"
            ></textarea>
          </AtTa>
          <div class="flex flex-row items-center space-x-2">
            <div v-if="comment.media.length" class="flex flex-wrap space-x-1.5">
              <div
                v-for="(media, key) in comment.media"
                :key="key"
                v-tooltip="media.name"
                class="flex flex-col group relative"
              >
                <img
                  :src="media.link"
                  alt=""
                  class="w-20 h-20 rounded cursor-pointer"
                />
                <i
                  class="cs-cross text-xxs absolute hidden group-hover:block top-0.5 right-0.5 text-center w-4 h-4 bg-red-800 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer rounded-full"
                  @click="
                    () => {
                      comment.media.splice(key, 1)
                    }
                  "
                ></i>
                <i
                  class="absolute hidden group-hover:block w-full bottom-0 cs-eye text-center p-1.5 bg-black-900 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
                  @click="previewMediaImage(media.link)"
                ></i>
              </div>
            </div>
            <div v-if="loader.file">
              <div
                class="w-20 h-20 rounded border flex justify-center items-center"
              >
                <clip-loader
                  :color="'#000000'"
                  :size="'13px'"
                  class="spinner"
                  variant="info"
                ></clip-loader>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between mt-3">
            <!-- Emoji attachments and hashtags-->
            <div class="flex space-x-1.5 justify-end items-center">
              <div class="relative">
                <div
                  @click="
                    emojiPickerStates.comment = !emojiPickerStates.comment
                  "
                >
                  <div
                    v-tooltip.top="'Add an emoji'"
                    class="w-8 h-8 flex items-center justify-center bg-gray-400 rounded-md cursor-pointer"
                  >
                    <i class="fal fa-smile-beam text-blue-700 text-base font-weight-500"> </i>
                  </div>
                </div>
                <CstEmojiPicker
                  v-if="emojiPickerStates.comment"
                  position="top-start"
                  :is-open="emojiPickerStates.comment"
                  @on-select="onEmojiSelect"
                  @handle-click="emojiPickerStates.comment = false"
                >
                </CstEmojiPicker>
              </div>

              <label
                v-tooltip.top="{
                  content: 'Add media file',
                }"
                class="cursor-pointer w-8 h-8 mb-0 flex items-center justify-center bg-gray-400 rounded-md"
                for="comment-file-input"
              >
                <img
                  width="16"
                  height="16"
                  :src="require('@assets/img/icons/planner/add_image.svg')"
                  alt="add_media"
                />
              </label>
              <input
                id="comment-file-input"
                accept="image/*"
                class="d-none"
                type="file"
                @change.prevent="uploadCommentImage(false)"
              />
              <i
                v-tooltip.top="{ content: 'Tag people' }"
                class="far fa-at cursor-pointer flex w-8 h-8 items-center justify-center bg-gray-400 rounded-md text-blue-700 font-1rem"
                @click="() => triggerAt('commented')"
              ></i>
            </div>

            <!-- Add Comment Button -->
            <div class="flex justify-start items-center">
              <button
                :class="{
                  'cursor-not-allowed':
                    loader.comments || loader.file || comment.text === '',
                }"
                :disabled="
                  loader.comments ||
                  loader.file ||
                  comment.text === '' ||
                  processingComment
                "
                class="p-1.5 border-0 bg-primary-variant-1 rounded-md"
                @click="handleComment(false)"
              >
                <clip-loader
                  v-if="processingComment"
                  :color="'#dadada'"
                  :size="'16px'"
                  class="spinner"
                  variant="info"
                ></clip-loader>
                <template v-else>
                  <img
                    width="16"
                    height="16"
                    :src="require('@assets/img/icons/planner/send_message.svg')"
                    alt="send_message"
                  />
                </template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <VueEasyLightbox
      :imgs="imgs"
      :visible="visible"
      @hide="handleHide"
    ></VueEasyLightbox>
  </div>
  <div
    v-else-if="commentType === 'notes'"
    class="mt-3 bg-white shadow-sm border !border-[#eef1f4] rounded-md"
  >
    <div
      v-b-toggle.accordion-2
      class="flex justify-between items-center p-3 cursor-pointer"
    >
      <div class="flex flex-row items-center space-x-2">
        <div>
          <img
            width="20"
            height="20"
            :src="require('@assets/img/icons/planner/post_internal_notes.svg')"
            alt="post_internal_notes"
          />
        </div>
        <h3 class="text-base font-normal flex items-center mr-2"
          >Internal Notes
          <v-menu
            :popper-triggers="['hover']"
            placement="top-left"
            popper-class="threaded-tweets__info-popover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <a href="#" class="ml-2"
              ><img
                width="20"
                height="20"
                src="@assets/img/composer/help-icon.svg"
                alt=""
                class="w-5 h-5"
            /></a>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <p class="max-w-[14rem]"
                >Internal notes are only visible to users set as 'team'. You can
                select between 'team' or 'client' while adding a new team
                member.</p
              >
            </template>
          </v-menu>
        </h3>
        <div
          v-if="totalInternalNotes"
          class="bg-red-500 p-0.5 rounded-full w-5 h-5 flex items-center justify-center text-white"
          >{{ totalInternalNotes }}</div
        >
      </div>
      <i class="fas fa-chevron-down"></i>
    </div>
    <b-collapse
      id="accordion-2"
      v-model="expansionInternalNotes"
      accordion="my-accordion"
      role="tabpanel"
      visible
    >
      <div class="flex-1 p-3">
        <!-- Internal Note Display -->
        <div
          v-for="(message, index) in commentsList"
          :key="`comments_v2_${index}`"
        >
          <div v-if="message.is_note">
            <CommentCard
              :is-note="message.is_note"
              :message="message"
              :plan-id="plan._id"
              @update-comment="updateComment"
              @open-image-light-box="previewMediaImage"
              @update-comment-delete-list="updateCommentDeleteList"
            ></CommentCard>
          </div>
        </div>

        <div class="py-2 border rounded-md !border-[#eef1f4] p-2">
          <AtTa
            v-model="internalNote.text"
            class="mentions-bottom"
            :hover-select="true"
            :members="getWorkspaceClientsName"
          >
            <textarea
              id="internal-note-box-top"
              ref="noted"
              v-model="internalNote.text"
              :disabled="loader.comments || loader.file"
              class="rounded-md w-full p-3 border-0 text-sm"
              maxlength="2200"
              placeholder="Add your comment here"
              rows="4"
            ></textarea>
          </AtTa>
          <div class="flex flex-row items-center space-x-2">
            <div
              v-if="internalNote.media.length"
              class="flex flex-wrap space-x-1.5"
            >
              <div
                v-for="(media, key) in internalNote.media"
                :key="key"
                v-tooltip="media.name"
                class="flex flex-col group relative"
              >
                <img
                  :src="media.link"
                  alt=""
                  class="w-20 h-20 rounded cursor-pointer"
                />
                <i
                  class="cs-cross text-xxs absolute hidden group-hover:block top-0.5 right-0.5 text-center w-4 h-4 bg-red-800 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer rounded-full"
                  @click="
                    () => {
                      internalNote.media.splice(key, 1)
                    }
                  "
                ></i>
                <i
                  class="absolute hidden group-hover:block w-full bottom-0 cs-eye text-center p-1.5 bg-black-900 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
                  @click="previewMediaImage(media.link)"
                ></i>
              </div>
            </div>
            <div v-if="loader.file">
              <div
                class="w-20 h-20 rounded border flex justify-center items-center"
              >
                <clip-loader
                  :color="'#000000'"
                  :size="'13px'"
                  class="spinner"
                  variant="info"
                ></clip-loader>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between mt-3">
            <!-- Emoji attachments and hashtags-->
            <div
              class="composer-comment-toolbar flex space-x-1.5 justify-end items-center"
            >
              <div class="relative">
                <div
                  @click="
                    emojiPickerStates.internalNote =
                      !emojiPickerStates.internalNote
                  "
                >
                  <div
                    v-tooltip.top="'Add an emoji'"
                    class="w-8 h-8 flex items-center justify-center bg-gray-400 rounded-md cursor-pointer"
                  >
                    <i class="fal fa-smile-beam text-blue-700 text-base font-weight-500"> </i>
                  </div>
                </div>
                <CstEmojiPicker
                  v-if="emojiPickerStates.internalNote"
                  position="top-start"
                  :is-open="emojiPickerStates.internalNote"
                  @on-select="(unicode) => onEmojiSelect(unicode, true)"
                  @handle-click="emojiPickerStates.internalNote = false"
                >
                </CstEmojiPicker>
              </div>

              <label
                v-tooltip.top="{
                  content: 'Add media file',
                }"
                class="cursor-pointer w-8 h-8 mb-0 flex items-center justify-center bg-gray-400 rounded-md"
                for="internal-note-file-input"
              >
                <img
                  width="16"
                  height="16"
                  :src="require('@assets/img/icons/planner/add_image.svg')"
                  alt="add_media"
                />
              </label>
              <input
                id="internal-note-file-input"
                accept="image/*"
                class="d-none"
                type="file"
                @change.prevent="uploadCommentImage(true)"
              />
              <i
                v-tooltip.top="{ content: 'Tag people' }"
                class="far fa-at cursor-pointer flex w-8 h-8 items-center justify-center bg-gray-400 rounded-md text-blue-700 font-1rem"
                @click="() => triggerAt('noted')"
              ></i>
            </div>

            <div class="flex justify-start items-center">
              <button
                :class="{
                  'cursor-not-allowed':
                    loader.comments || loader.file || internalNote.text === '',
                }"
                :disabled="
                  loader.comments ||
                  loader.file ||
                  internalNote.text === '' ||
                  processingComment
                "
                class="p-1.5 border-0 bg-primary-variant-1 rounded-md"
                @click="handleComment(true)"
              >
                <clip-loader
                  v-if="processingComment"
                  :color="'#dadada'"
                  :size="'16px'"
                  class="spinner"
                  variant="info"
                ></clip-loader>
                <template v-else>
                  <img
                    width="16"
                    height="16"
                    :src="require('@assets/img/icons/planner/send_message.svg')"
                    alt="send_message"
                  />
                </template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <VueEasyLightbox
      :imgs="imgs"
      :visible="visible"
      @hide="handleHide"
    ></VueEasyLightbox>
  </div>
  <div
    v-else-if="commentType === 'external'"
    class="flex flex-col bg-white shadow-sm border !border-[#eef1f4] rounded-md"
  >
    <div
      v-b-toggle.accordion-1
      class="flex justify-between items-center p-3 cursor-pointer"
    >
      <div class="flex flex-row items-center space-x-2">
        <div>
          <img
            width="20"
            height="20"
            :src="require('@assets/img/icons/planner/post_comments.svg')"
            alt="post_comment"
          />
        </div>
        <h3 class="text-base font-normal">Comment</h3>
      </div>
      <i class="fas fa-chevron-down"></i>
    </div>
    <b-collapse
      id="accordion-1"
      v-model="expansionComments"
      accordion="my-accordion"
      role="tabpanel"
      visible
    >
      <div class="flex-1 p-3">
        <!-- Comments Display -->
        <div class="py-2 border rounded-md !border-[#eef1f4] p-2">
          <CstFloatingLabelInput
            id="full-name-preview"
            v-model="data.name"
            class="mb-2 h-12 focus-within:outline-none"
            type="text"
            label="Full Name *"
          >
          </CstFloatingLabelInput>

          <CstFloatingLabelInput
            id="email-preview"
            v-model="data.email"
            class="mb-2 h-12 focus-within:outline-none"
            type="email"
            label="Email *"
          >
          </CstFloatingLabelInput>
          <div>
            <AtTa
              v-model="comment.text"
              class="mentions-bottom"
              :hover-select="true"
            >
              <textarea
                id="comment-box-external"
                ref="commented"
                v-model="data.comment"
                :disabled="loader.comments"
                class="rounded-md w-full p-2 border text-sm"
                maxlength="2200"
                placeholder="Add your comment here"
                rows="4"
              ></textarea>
            </AtTa>
          </div>
          <div class="flex items-center justify-between mt-3">
            <!-- Emoji attachments and hashtags-->
            <div class="flex space-x-1.5 justify-end items-center">
              <div class="relative">
                <div
                  @click="
                    emojiPickerStates.externalComment =
                      !emojiPickerStates.externalComment
                  "
                >
                  <div
                    v-tooltip.top="'Add an emoji'"
                    class="w-8 h-8 flex items-center justify-center bg-gray-400 rounded-md cursor-pointer"
                  >
                    <i class="fal fa-smile-beam text-blue-700 text-base font-weight-500"> </i>
                  </div>
                </div>
                <CstEmojiPicker
                  v-if="emojiPickerStates.externalComment"
                  position="top-start"
                  :is-open="emojiPickerStates.externalComment"
                  @on-select="onEmojiSelect"
                  @handle-click="emojiPickerStates.externalComment = false"
                >
                </CstEmojiPicker>
              </div>
            </div>

            <!-- Add Comment Button -->
            <div class="flex justify-start items-center">
              <button
                :class="{
                  'cursor-not-allowed':
                    data.name === '' ||
                    data.email === '' ||
                    data.comment === '',
                }"
                :disabled="
                  data.name === '' || data.email === '' || data.comment === ''
                "
                class="p-1.5 border-0 bg-primary-variant-1 rounded-md"
                @click="onSubmit"
              >
                <clip-loader
                  v-if="processingComment"
                  :color="'#dadada'"
                  :size="'16px'"
                  class="spinner"
                  variant="info"
                ></clip-loader>
                <template v-else>
                  <img
                    width="16"
                    height="16"
                    :src="require('@assets/img/icons/planner/send_message.svg')"
                    alt="send_message"
                  />
                </template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {EventBus} from "@common/lib/event-bus";

import CommentCard from '@src/modules/planner_v2/components/CommentCard'
import { computed, defineComponent, inject, reactive, ref, watch, watchEffect } from 'vue'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import VueEasyLightbox from 'vue-easy-lightbox'
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { addComment } from '@common/composables/useCommentsAndNotes'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'CommentsAndNotes',
  components: {
    CommentCard,
    AtTa,
    VueEasyLightbox,
    CstEmojiPicker,
    CstFloatingLabelInput,
  },
  mixins: [Helpers],
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    commentType: {
      type: String,
      default: 'comments',
    },
    commentsAccordion: {
      type: Boolean,
      default: false,
    },
    internalNotesAccordion: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const root = inject('root')
    const { dispatch, getters } = useStore()
    const token = computed(() => props.token)
    const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
    const processingComment = ref(false)
    const visible = ref(false)
    const comment = ref({
      text: '',
      mentioned_user: [],
      media: [],
    })
    const internalNote = ref({
      text: '',
      mentioned_user: [],
      media: [],
    })
    const loader = ref({
      file: false,
      comments: false,
    })
    const commented = ref(null)
    const noted = ref(null)
    const imgs = ref('')
    const expansionComments = ref(props.commentsAccordion)
    const expansionInternalNotes = ref(props.internalNotesAccordion)
    const deletedComments=ref([])
    const data = reactive({
      comment: '',
      type: 'comment',
      plans: [],
      name: '',
      email: '',
      token: '',
    })
    const emojiPickerStates = reactive({
      comment: false,
      internalNote: false,
      externalComment: false,
    })
    /**
     * Fetches active client workspace name for mentioning
     * @type {ComputedRef<*[]>}
     */
    const getWorkspaceClientsName = computed(() => {
      const members = []
      if (props.commentType !== 'external') {
        getters.getActiveWorkspace.members.filter(function (member) {
          if (
            member.user &&
            (!member.membership || member.membership !== 'client')
          )
            members.push(member.user.full_name.replace(/ /g, ''))
        })
      }
      return members
    })
    /**
     * Fetches sorted comment from external comment and actions
     * @type {ComputedRef<*[]>}
     */
    const getSortedComments = computed(() => {
      let comments = []
      // map all external comments and add  key,value isInternal
      if (props.plan.external_comments?.length > 0) {
        comments = props.plan.external_comments?.map((item) => ({
          ...item,
          is_internal: false,
        }))
      }
      // push all internal comments
      if (props.plan.comments?.length > 0) {
        props.plan.comments.forEach((comment) => {
          if (!comment.is_note) comments.push({ ...comment, is_internal: true })
        })
      }
      // push all external actions comments
      if (props.plan.external_actions?.length > 0) {
        props.plan.external_actions.forEach((action) => {
          if (action.comment) comments.push({ ...action, is_internal: false })
        })
      }
      // sort by time created
      comments.sort(function (a, b) {
        const timeA = moment.parseZone(a.created_at).utc(true).format()
        const timeB = moment.parseZone(b.created_at).utc(true).format()
        return timeB.localeCompare(timeA)
      })
      return comments
    })
    /**
     * Returns list of comments exisitng in a plan.
     * @type {ComputedRef<*>}
     */
    const commentsList = computed(() => {
      return props.plan?.comments.filter(item => !deletedComments.value.includes(item._id))
    })

    /**
     * Returns total number of comments in a plan
     * @type {ComputedRef<unknown>}
     */
    const totalComments = computed(() => {
      const length = 0
      // if (props.plan.type === 'Composer Article') return length
      return getSortedComments.value?.length || length
    })

    /**
     * Returns total number of internal notes in a plan
     * @type {ComputedRef<unknown>}
     */
    const totalInternalNotes = computed(() => {
      let length = 0
      if (props.plan.type === 'Composer Article') return length
      if (props.plan.comments) {
        props.plan.comments.forEach((item) => {
          if (item.is_note) length += 1
        })
      }
      return length
    })

    watchEffect(() => {
        expansionComments.value = !!totalComments.value
        expansionInternalNotes.value = !!totalInternalNotes.value
    })

    /**
     * click event for mentioning user profiles
     * @param ref
     */
    const triggerAt = (ref) => {
      let eds = null
      if (ref === 'commented') eds = commented.value
      else eds = noted.value
      eds.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      eds.dispatchEvent(e)
    }

    /**
     * Method for handling image upload for comments/internal notes
     * @param isNote
     * @returns {Promise<void>}
     */
    const uploadCommentImage = async (isNote) => {
      loader.value.file = true
      const res = await dispatch('uploadImageForComment', event)
      loader.value.file = false
      if (res) {
        if (res.data.status) {
          if (isNote) {
            if (internalNote.value.media) {
              internalNote.value.media.push(res.data.media)
            } else {
              internalNote.value.media = [res.data.media]
              // this.$set(internalNote, 'media', [res.data.media])
            }
          } else {
            if (comment.value.media) {
              comment.value.media.push(res.data.media)
            } else {
              comment.value.media = [res.data.media]
              // this.$set(comment, 'media', [res.data.media])
            }
          }
        } else {
          dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    }

    /**
     * Resets the comment to default
     * @param isNote
     */
    const resetComment = (isNote) => {
      console.log('METHOD::resetComment')
      if (isNote) {
        internalNote.value.text = ''
        internalNote.value.mentioned_user = []
        internalNote.value.media = []
      } else {
        comment.value.text = ''
        comment.value.mentioned_user = []
        comment.value.media = []
      }
    }

    /**
     * Handles the processing of comment addition.
     * @param isNote
     * @returns {Promise<void>}
     */
    const handleComment = async (isNote = false) => {
      console.log('METHOD::handleComment ~ isNote -> ', comment)
      processingComment.value = true
      const payload = {
        comment: isNote ? internalNote.value.text : comment.value.text,
        is_note: isNote,
        media: isNote ? internalNote.value.media : comment.value.media,
        mentioned_user: isNote
          ? root.mentionedUserIdsList(internalNote.value.text) // this.internalNote.mentioned_user
          : root.mentionedUserIdsList(comment.value.text), // this.comment.mentioned_user,
        notification: '',
        plan_id: props.plan._id,
        title: null,
        type: 'list_plans',
        workspace_id: getters.getActiveWorkspace._id,
      }

      const commentResponse = await addComment(payload)
      // fallback to add comment to plan in case pusher not bind
      if (
        commentResponse?._id &&
        !props.plan.comments.find((item) => item._id === commentResponse._id)
      ) {
        props.plan.comments.unshift(commentResponse)
        EventBus.$emit('increase-comment-count', {id:props.plan._id ,isNote})

      }
      resetComment(isNote)
      processingComment.value = false
    }

    /**
     * Event handler for updating comments
     * @param comment
     */
    const updateComment = (payload) => {
      const {comment,id}  = payload
      const index = props.plan.comments.findIndex((item) => item._id === id)
      if (index > -1) {
        props.plan.comments[index].comment = comment
      }



    }

    /**
     * Event handler for previewing comments/internal notes images
     * @param image
     */
    const previewMediaImage = (image) => {
      console.log('openImageLightBox', image)
      if (image) {
        imgs.value = image
        visible.value = true
      }
    }

    /**
     * Handler for hiding previewed image
     */
    const handleHide = () => {
      visible.value = false
    }

    const onEmojiSelect = (emojiUnicode, isNote) => {
      // Get the current textarea element
      const textarea = isNote ? noted.value : commented.value
      const cursorPosition = textarea.selectionStart

      // Insert the emoji at the cursor position
      const newText =
        textarea.value.slice(0, cursorPosition) +
        emojiUnicode +
        textarea.value.slice(cursorPosition)

      // Update the textarea value and cursor position
      if (isNote) {
        internalNote.value.text = newText
      } else {
        comment.value.text = newText
      }

      textarea.value = newText
      textarea.selectionStart = cursorPosition + emojiUnicode.length
      textarea.selectionEnd = cursorPosition + emojiUnicode.length
    }

    const onSubmit = async () => {
      // validate
      if (!data.name.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your name',
          type: 'error',
        })
        return
      }

      if (!data.email.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your valid email',
          type: 'error',
        })
        return
      }

      if (data.type === 'comment' && !data.comment.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your comment',
          type: 'error',
        })
        return
      }

      if (data.type === 'comment') {
        await addExternalComment()
      } else {
        await externalAction()
      }
    }

    const externalAction = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.APPROVE_REJECT_PLAN, {
          token: token.value,
          plans: [props.plan?._id],
          action: {
            name: data.name,
            email: data.email,
            comment: data.comment,
            type: data.type,
          },
        })
        dispatch('toastNotification', {
          message: 'Post state updated successfully',
          type: 'success',
        })
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    const addExternalComment = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.ADD_COMMENT, {
          token: token.value,
          plans: [props.plan?._id],
          comment: {
            name: data.name,
            email: data.email,
            comment: data.comment,
          },
        })
        comment.value.text = data.name = data.email = data.comment = ''
        dispatch('toastNotification', {
          message: 'Comment added successfully',
          type: 'success',
        })
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    const updateCommentDeleteList = (id) => {
      props.plan.comments = props.plan.comments.filter((item) => item._id !== id)
      deletedComments.value.push(id)
      EventBus.$emit('decrease-comment-count', {id:props.plan._id ,isNote: isNote()})
    }
    const isNote = () => {
      return props.commentType === 'notes'
    }

    return {
      processingComment,
      visible,
      comment,
      internalNote,
      loader,
      commented,
      noted,
      getWorkspaceClientsName,
      commentsList,
      imgs,
      expansionComments,
      expansionInternalNotes,
      totalComments,
      totalInternalNotes,
      emojiPickerStates,
      moment,
      getSortedComments,
      data,
      triggerAt,
      uploadCommentImage,
      resetComment,
      handleComment,
      previewMediaImage,
      handleHide,
      onEmojiSelect,
      onSubmit,
      deletedComments,
      updateCommentDeleteList,
      updateComment,
    }
  },
})
</script>

<style lang="scss">
.emoji_dropdown .dropdown_header i {
  font-size: 1rem !important;
}
</style>
