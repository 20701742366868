<template>
  <div ref="column-cross-chart-container" class="h-chart">
    <highcharts :options="dataOptions"></highcharts>
  </div>
</template>

<script>
import highcharts from "@ui/Highcharts";
export default {
  name: 'ColumnCrossChart',
  components: {
    highcharts
  },
  props: {
    categories: {},
    series: {
      default: [
        {
          name: 'Comments',
          data: [
            49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
            95.6, 54.4
          ],
          color: '#ffe202'
        },
        {
          name: 'Reposts',
          data: [
            83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
            106.6, 92.3
          ],
          color: '#2fe095'
        },
        {
          name: 'Reactions',
          data: [
            48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
            51.2
          ],
          color: '#e02f5f'
        },
        {
          name: 'Posts Published',
          data: [
            42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
            51.1
          ],
          color: '#26282c',
          type: 'spline'
        }
      ]
    }
  },
  data () {
    return {
      dataOptions: {}
    }
  },
  mounted () {
    this.initializeDataOptions()
    this.$nextTick(function () {
      this.dataOptions.chart.height =
        this.$refs['column-cross-chart-container'].offsetHeight - 32
    })
  },
  methods: {
    initializeDataOptions () {
      this.dataOptions = {
        title: {
          text: ' '
        },

        chart: {
          type: 'column',
          height: this.$refs['column-cross-chart-container'].offsetHeight - 32
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: ''
          }
        },

        xAxis: {
          categories: this.categories,
          crosshair: true
        },

        tooltip: {
          headerFormat:
            '<span style="font-size:11px; margin-bottom: 0.4rem">{point.key}</span><table>',
          pointFormat:
            '<tr class="my-1"><td style="color:{series.color};padding:0">{series.name}:   </td>' +
            '<td style="padding:0"><b>  {point.y}</b></td></tr>',
          footerFormat: '</table>',
          backgroundColor: '#FFFFFF',
          borderColor: '#000000',
          shared: true,
          useHTML: true,
          crosshairs: [true]
        },

        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            borderRadius: 3
          },
          spline: {
            marker: {
              radius: 6,
              symbol: 'circle',
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            },
            shadow: true,
            lineWidth: 4
          }
        },
        series: this.series
      }
    }
  }
}
</script>
