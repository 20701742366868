<template>
  <span class="flex items-center">
    <span class="relative">
      <img
        :src="channelImage(account, account.channel_type || platformIdentifier)"
        alt=""
        class="rounded-full object-cover border"
        data-fallback="0"
        height="30"
        width="30"
        @error="onError($event, account.image)"
      />
      <img
        :src="require('@assets/img/integration/' + getSocialImage)"
        alt=""
        class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
        :class = "{ 'bg-white rounded-full': account?.account_type === 'twitter' }"
      />
    </span>
    <span class="flex flex-col ml-3">
      <span class="flex flex-row">
        <span
          v-tooltip="{ content: tooltipContent() }"
          class="text-sm text-ellipsis font-normal text-black-100 select-none"
        >
          {{
            truncateString(
              'platform_name' in account ? account.platform_name : account.name,
              21,
            )
          }}
        </span>
      </span>

      <span class="block text-xs select-none text-gray-800">
        {{ 'type' in account ? account.type : 'Profile' }}
      </span>
    </span>
  </span>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'

export default defineComponent({
  name: 'SocialAccount',
  mixins: [Helpers],
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    externalLink: {
      type: Boolean,
      default: false,
    },
    platformIdentifier: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const socialAccountNameLength = ref(20)

    const getSocialImage = computed(() => {
      let type = ''
      if(props.externalLink){
        type = props.platformIdentifier
      } else {
        type = props.account?.channel_type
      }
      switch (type) {
        case 'facebook':
          return 'facebook-rounded.svg'
        case 'twitter':
          return 'twitter-x-rounded.svg'
        case 'linkedin':
          return 'linkedin-rounded.svg'
        case 'instagram':
          return 'instagram-rounded.svg'
        case 'pinterest':
          return 'pinterest-rounded.svg'
        case 'youtube':
          return 'youtube-rounded.svg'
        case 'gmb':
          return 'gmb-rounded.svg'
        case 'tumblr':
          return 'tumblr-rounded.svg'
        case 'tiktok':
          return 'tiktok-rounded.svg'
      }
      return ''
    })

    /**
     * Used for v-tooltip, if the length of the string is greater than the length provided in argument,
     * then it will return the full name to be shown inside the tooltip.
     * Otherwise, it will return 'null' which will not show the toopltip
     * @returns {*|null}
     */
    const tooltipContent = () => {
      const str =
        'platform_name' in props.account
          ? props.account.platform_name
          : props.account.name
      return str?.length > socialAccountNameLength.value ? str : null
    }

    return {
      getSocialImage,
      socialAccountNameLength,
      tooltipContent,
      // onError,
    }
  },
})
</script>

<style scoped></style>
