import { authenticationTypes } from '@src/store/mutation-types'
import { mapGetters } from "vuex"
import moment from 'moment-timezone'
export default {
  computed: {
    ...mapGetters([
        'getActiveWorkspace',
        'getWorkspaces'
    ])
  },
  methods: {
    // reset is used to check that whether the state reset is required or not
    async loadRequiredData(reset = true) {
      console.debug('Method::loadRequiredData')

      // Get System Status
      this.fetchAppStatus()

      setInterval(() => {
        this.fetchAppStatus()
      }, 600000) // 600000ms == 10 mints

      // fetching profile using async
      await this.asyncUserProfile(reset)

      // checking account is deleted or not
      if (this.isDeletedAccount() === true) return

      // checking email verified or not
      if (this.isEmailVerified() === false) return

      // checking account expired or not
      if (this.isSubscriptionExpired() === true) return

      // checking subscription cancelled or not
      if (this.isSubscriptionCancelled() === true) return

      // checking subscription deleted or not
      if (this.isSubscriptionDeleted() === true) return

      if (this.isSubscriptionPaused() === true) return

      // checking trial expired or not
      if (this.isTrialExpired() === true) return

      // checking password reset required or not
      if (this.isRequiredPasswordReset() === true) return
      if (this.$route.name === 'analytics_pdf_report') return

      // checking v2 migration is required or not
      if (this.isRequiredMigration() === true) return

      // // checking onBoarding is required or not
      // if ((await this.isRequiredOnBoarding()) === true) return

      if (this.$route.query.redirected_url) {
        window.location.href = this.$route.query.redirected_url
        return false
      }

      const workspaces = await this.fetchWorkspaces()
      // in case of attempting setPassword page without value
      if (
        this.$route.name === 'setPassword' &&
        this.getProfile &&
        !this.getProfile.need_to_reset_password
      ) {
        this.$router.push('/workspaces')
        return
      }

      // setting active workspace and loading workspace data (account etc)
      if(workspaces && workspaces.length > 0) {
        console.log('REACH3')
        await this.processActiveWorkspace(workspaces, reset)
        // fetching plan base on workspace id
        this.initializePlanSection()

        // fetching billing details
        await this.$store.dispatch('fetchBillingDetails')
        await this.$store.dispatch('fetchBillingPaymentFailed')

        return
      }


      // fetching subscription plan
      await this.Plan()

      // no active workspace, in that case we will return the user over here.
      if (this.getPlan.subscription.fastspring_name && this.getPlan.subscription.fastspring_name.includes('no_package')) {
        return this.$router.push('/start_trial').catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error
          }
        })
      }

      // if (!this.getProfile.onBoarding) {
      if (this.getPlan.subscription.fastspring_name && this.getPlan.subscription.fastspring_name.includes('no_package')){
        return this.$router.push('/start_trial').catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error
          }
        })
      }

      console.log('REACH4')
      this.checkWorkspaceExistance()

      // fetching plan base on workspace id
      this.initializePlanSection()
    },

   isUserStateActive(user){
     if(!user?.state) return true
     if (
         user.state &&
         (user.state === 'canceled' ||
             user.state === 'cancelled')
     ) {
       this.$router.push({ name: 'subscription_cancelled' })
       return false
     }

     if (user.state && user.state === 'deleted') {
       this.$router.push({ name: 'subscription_deleted' })
       return false
     }

     if (user.state && user.state === 'paused') {
       this.$router.push({ name: 'subscription_paused' })
       return false
     }

     if (user.trial_finished) {
       this.$router.push({ name: 'trial_expired' })
       return false
     }
     return true
   },

    /**
     * fetching app status
     * @returns {Promise<T>}
     */
    async fetchAppStatus() {
      console.debug('Method::fetchAppStatus')
      // eslint-disable-next-line no-return-await
      return await this.$store.dispatch('fetchAppStatus')
    },

    /**
     * fetching profile using async
     * @returns {Promise<T>}
     */
    async asyncUserProfile(reset = false) {
      console.debug('Method::asyncUserProfile')
      const resp = await this.fetchProfile()
      if (!reset) this.loadSocketBroadcast()
      this.loadUserBroadCasters()
      return resp
    },

    /**
     * checking account is deleted or not. If deleted, we will redirect them.
     * @returns {boolean}
     */
    isDeletedAccount() {
      console.debug('Method::isDeletedAccount')
      if (this.getProfile.state) {
        if (this.getProfile.state === 'disabled') {
          this.$store.commit(authenticationTypes.SET_JWT_TOKEN, null)
          this.$store.commit(authenticationTypes.SET_LOGGED_USER, null)
          this.$router.go('/login')
          return true
        }
      }
      return false
    },

    /**
     * Ensuring that the user have email verified. If not, we will redirect them.
     * @returns {boolean}
     */
    isEmailVerified() {
      console.log('Method::isEmailVerified', this.getProfile.email_verify)
      if (this.getProfile.email_verify === false) {
        if (this.$route.name === 'verify_email') {
          return false
        }
        if (this.$route.name !== 'email_verification')
          this.$router.push({ name: 'email_verification' })
        return false
      }
      if (this.$route.name === 'email_verification' || this.$route.name === 'login')
        this.$router.push({ name: 'onboardingWorkspace' })
      return true
    },

    /**
     * if the workspace doesn't exist it should redirect the user to create-workspace route.
     */
    checkWorkspaceExistance(){
      console.log("Method::checkWorkspaceExistance ~ checking workspace", this.getActiveWorkspace);
      if(this.isEmailVerified()){
        if(this.getActiveWorkspace._id === '' || this.getActiveWorkspace === false){
          this.$router.push({name: 'onboardingWorkspace'})
        }
      }
    },

    /**
     * checking account expired or not. If expired, we will redirect them.
     * @returns {boolean}
     */
    isSubscriptionExpired() {
      console.log('Method::isSubscriptionExpired')
      const getProfileState = this.getProfile.state
      if (getProfileState) {
        if (getProfileState === 'deactivated') {
          if (this.$route.name !== 'subscription_expired')
            this.$router.push({
              name: 'subscription_expired',
              query: { state: 'deleted' },
            })
          return true
        }
      }
      return false
    },

    /**
     * checking subscription cancelled or not. If cancelled, we will redirect them.
     * @returns {boolean}
     */
    isSubscriptionCancelled() {
      console.log('Method::isSubscriptionCancelled')
      const getProfileState = this.getProfile.state
      if (getProfileState) {
        if (getProfileState === 'canceled' || getProfileState === 'cancelled') {
          if (this.$route.name !== 'subscription_cancelled')
            this.$router.push({ name: 'subscription_cancelled' })
          return true
        }
      }
      return false
    },

    isSubscriptionDeleted() {
      console.log('Method::isSubscriptionDeleted')
      const getProfileState = this.getProfile.state
      if (getProfileState) {
        if (getProfileState === 'deleted') {
          if (this.$route.name !== 'subscription_deleted')
            this.$router.push({ name: 'subscription_deleted' })
          return true
        }
      }
      return false
    },

    isSubscriptionPaused() {
      console.log('Method::isSubscriptionPaused')
      const getProfileState = this.getProfile.state
      if (getProfileState) {
        if (getProfileState === 'paused') {
          if (this.$route.name !== 'subscription_paused')
            this.$router.push({ name: 'subscription_paused' })
          return true
        }
      }
      return false
    },

    /**
     * checking trial expired or not. If expired, we will redirect them.
     * @returns {boolean}
     */
    isTrialExpired() {
      console.log('Method::isTrialExpired')
      if (this.getProfile.trial_finished) {
        if (this.$route.name !== 'trial_expired')
          this.$router.push({ name: 'trial_expired' })
        return true
      }
      return false
    },

    /**
     * checking password reset required or not. If required, we will redirect them.
     * @returns {boolean}
     */
    isRequiredPasswordReset() {
      console.log('Method::isRequiredPasswordReset')
      if (
        this.$route.name !== 'setPassword' &&
        this.getProfile &&
        this.getProfile.need_to_reset_password
      ) {
        this.$router.push({ name: 'setPassword' })
        return true
      }
      return false
    },

    /**
     * checking v2 migration required or not. If required, we will redirect them.
     * @returns {boolean}
     */
    async isRequiredMigration() {
      console.log('Method::isRequiredMigration')
      if (this.getProfile.migration) {
        await this.fetchOnboardingWorkspaces()
        this.$router.push({ name: 'welcome' })
        return true
      }
      return false
    },

    /**
     * checking onBoarding required or not. If required, we will redirect them.
     * @returns {boolean}
     */
    async isRequiredOnBoarding() {
      console.debug(
        'Method::isRequiredOnBoarding',
        this.$route.name,
        this.getProfile
      )
      if (this.getProfile.onBoarding) {
        await this.fetchWorkspaces()

        // console.debug('Method::isRequiredOnBoarding Role', this.getUserRole)
        // If Workspace Exist

        if (this.getWorkspaces.items.length > 0) {
          if (
            (this.$route.name === 'socialaccounts' &&
              this.$route.hash.length) ||
            this.$route.name === 'getting_started_video'
          ) {
            await this.fetchWorkspaces()
          } else {
            await this.fetchWorkspaces(true)
          }
          if (this.getUserRole && this.getUserRole === 'Collaborator') {
            this.$router.push({ name: 'getting_started_video' })
          } else {
            if (this.$route.name === 'onboardingWorkspace') {
              this.$router.push({ name: 'socialaccounts' })
            }
            if (
              this.$route.name !== 'onboardingWorkspace' &&
              this.$route.name !== 'teammembers' &&
              this.$route.name !== 'socialaccounts' &&
              this.$route.name !== 'contentcategories' &&
              this.$route.name !== 'discovercontent'
            ) {
              this.$router.push({ name: 'socialaccounts' })
            }
          }
          //
        } else {
          this.$router.push({ name: 'onboardingWorkspace' })
        }

        return true
        // if (this.$route.name === 'socialaccounts' && this.getWorkspaces.items.length > 0) {
        //   return false
        // }
      }
      return false
    },

    /**
     * Check if the user is onboarded and have account migrated or not.
     * @returns {Promise<void>}
     */
    async isUserOnboarded() {
      // user has a migrated state, redirect to the welcome screen
      if (this.getProfile.migration) {
        await this.fetchOnboardingWorkspaces()
        this.$router.push({ name: 'welcome' })
        return
      }
      // a new user
      await this.fetchWorkspaces()
      console.log('OnboardingWorkspaces...')
      // this.$router.push({ name: 'getting_started_video' })
    },

    /**
     * Clearing token states and redirecting to the login page
     */
    isLoggedOutUser() {
      this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
      this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
      this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
      if (this.$route.fullPath && !this.$route.fullPath.includes('login'))
        this.$router.push({
          name: 'login',
          query: { redirected_url: this.$route.fullPath },
        })
    },
  },
  watch: {

  }
}
