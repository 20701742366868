import { confirmation } from '@common/store/mutation-types'
import proxy from '@common/lib/http-common'
import { generateRandomId, getHashtagsFromText } from '@common/lib/helper'
import { checkUniqueVariation } from '@common/lib/evergreen'
import Router from '@src/router'
import { AUTOMATION_CHANGE_STATUS_ERROR } from '@common/constants/messages'
import {
  fetchEvergreenAutomationsUrl,
  processEvergreenCsvFileURL,
  createEvergreenAutomationURL,
  changeEvergreenAutomationStatusUrl,
  removeEvergreenAutomationUrl,
  evergreenAutomationBulkOperationUrl,
  fetchVariationsURL
} from '../../config/api-utils'
import { evergreen } from '../automation-mutation-type'

const defaultEvergreenAutomation = () => {
  return {
    id: '',
    name: '',
    status: 0,
    posts: [],
    active_post: '',
    cycleGapStatus: false,
    cycleGap: 1,
    saveEvergreenContent: false,
    replanType: 'Later',
    tabStatus: 'first',
    content_category_id: '',
    content_category_name:''
  }
}

const defaultEvergreenVariations = () => {
  return {
    addState: 'post',
    postIndex: 0,
    variationIndex: 0,
    fetchLoader: false,
    activeVariationIndex: 0,
    variationURL: '',
    variationPreview: [],
    addLoader: false
  }
}

const defaultEvergreenBulk = () => {
  return {
    csvFileUpload: false,
    fileName: null,
    totalProcess: 0,
    total: 0
  }
}

const defaultEvergreenAutomationListing = () => {
  return {
    items: [],
    search: '',
    page: 1,
    limit: 20,
    nextPage: false,
    previousPage: false,
    fetchStatus: false,
    selected_all: false,
    selected: []
  }
}

export default {
  state: {
    selection: defaultEvergreenAutomation(),
    listing: defaultEvergreenAutomationListing(),
    bulk: defaultEvergreenBulk(),
    variation: defaultEvergreenVariations()
  },

  actions: {
    /**
     * Evergreen Bulk Upload.
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param event
     */
    uploadCsvFileEvergreen ({ commit, getters, state, dispatch }, event) {
      console.debug('Method:uploadCsvFileEvergreen')
      event.preventDefault()

      commit(evergreen.SET_EVERGREEN_BULK_LOADER, false)
      const files = event.target.files || event.dataTransfer.files

      // check file exist
      if (files[0]) {
        // reset filename
        commit(evergreen.SET_EVERGREEN_BULK_FILENAME, null)
        const file = files[0]

        // check file size , if greater than 5MB show error message
        if (file.size > 655360) {
          dispatch('toastNotification', {
            message: 'File size too big.',
            type: 'error'
          })
        } else {
          // reset bulk upload states to show progressbar
          commit(evergreen.SET_EVERGREEN_BULK_FILENAME, file.name)
          commit(evergreen.SET_EVERGREEN_BULK_LOADER, true)
          commit(evergreen.SET_EVERGREEN_BULK_TOTAL_POSTS, 0)
          commit(evergreen.SET_EVERGREEN_BULK_TOTAL_PROCESS_POSTS, 0)

          // prepare request payload
          const formData = new FormData()
          formData.append('inputFile', file)
          formData.append(
            'workspace_id',
            getters.getWorkspaces.activeWorkspace._id
          )

          // sending request
          proxy
            .post(processEvergreenCsvFileURL, formData)
            .then((resp) => {
              if (resp.data.status === true) {
                const posts = getters.getEvergreenSelection.posts
                // assign unique id to each post
                // in edit post save id in state to show which post is currently active or in edit mode
                resp.data.posts.forEach(function (element, index) {
                  element.id = generateRandomId()
                  posts.push([element])
                })
                commit(evergreen.SET_EVERGREEN_POSTS, posts)
              } else {
                dispatch('toastNotification', {
                  message: resp.data.message,
                  type: 'error'
                })
              }

              // reset bulk states after process complete
              commit(evergreen.SET_EVERGREEN_BULK_TOTAL_POSTS, 0)
              commit(evergreen.SET_EVERGREEN_BULK_TOTAL_PROCESS_POSTS, 0)
              commit(evergreen.SET_EVERGREEN_BULK_LOADER, false)
            })
            .catch((response) => {
              commit(evergreen.SET_EVERGREEN_BULK_TOTAL_POSTS, 0)
              commit(evergreen.SET_EVERGREEN_BULK_TOTAL_PROCESS_POSTS, 0)
              commit(evergreen.SET_EVERGREEN_BULK_LOADER, false)
            })
        }
        event.target.value = ''
      } else {
        dispatch('toastNotification', {
          message: 'No file selected.',
          type: 'error'
        })
      }
    },

    /**
     * Evergreen automation save method
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     */
    saveEvergreenAutomation ({ commit, getters, state, dispatch }) {
      // prepare payload
      const evergreenData = getters.getEvergreenSelection
      const schedule = getters.getAutomationScheduleOptions
      const payload = {
        id: evergreenData.id,
        name: evergreenData.name,
        schedule_options: schedule,
        posts: evergreenData.posts,
        cycleGapStatus: evergreenData.cycleGapStatus,
        cycleGap: evergreenData.cycleGap,
        account_selection: getters.getAccountSelection,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
        replanType: evergreenData.replanType,
        content_category_id: evergreenData.content_category_id,
        content_category_name:evergreenData.content_category_name
      }

      commit(evergreen.SET_EVERGREEN_CREATE_LOADER, true) // showing loader
      // sending request
      proxy
        .post(createEvergreenAutomationURL, payload)
        .then((resp) => {
          if (resp.data.status === true) {
            dispatch('toastNotification', {
              message: 'Your campaign has been saved.',
              type: 'success'
            })
            dispatch('trackEvent', { event: 'evergreen_recipe_created' })

            // reset states after success save
            commit(evergreen.SET_EVERGREEN_SELECTION_DEFAULT_STATES)
            commit('setAccountSelection', null)
            commit('setAutomationScheduleOptions', null)

            // redirect to evergreen automation listing page
            Router.push({ name: 'evergreenAutomationListing' })
          } else {
            // show error message in case of fail save
            if (resp.data.message) {
              dispatch('toastNotification', {
                message: resp.data.message,
                type: 'error'
              })
            } else {
              dispatch('toastNotification', {
                message: 'Unable to add evergreen scheduling.Please try again.',
                type: 'error'
              })
            }
          }
          commit(evergreen.SET_EVERGREEN_CREATE_LOADER, false) // hiding loader
        })
        .catch((response) => {
          commit(evergreen.SET_EVERGREEN_CREATE_LOADER, false)
        })
    },

    /**
     * get automation for listing
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     */
    fetchEvergreenAutomations ({ commit, getters, state, dispatch }) {
      const listing = getters.getEvergreenAutomationListing

      // prepare payload pass search, page etc
      const payload = {
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
        search: listing.search,
        page: listing.page,
        limit: listing.limit,
        platformSelection: getters.getPublishSelection.platforms.selection
      }
      commit('setFetchEvergreenAutomationsLoader', true) // showing loader

      // sending request
      proxy
        .post(fetchEvergreenAutomationsUrl, payload)
        .then((response) => {
          // save automations in states
          if (response.data.automations.data) {
            commit(
              evergreen.SET_EVERGREEN_AUTOMATION_LISTING_ITEM,
              response.data.automations.data
            )
          } else {
            commit(evergreen.SET_EVERGREEN_AUTOMATION_LISTING_ITEM, [])
          }

          // process pagination check more pages exists ur not
          let next = false
          let previous = false
          if (response.data.automations.next_page_url) next = true
          if (response.data.automations.prev_page_url) previous = true
          commit(evergreen.SET_EVERGREEN_AUTOMATION_LISTING_PAGINATION, {
            nextPage: next,
            previousPage: previous
          })

          commit('setFetchEvergreenAutomationsLoader', false) // hiding loader
        })
        .catch((response) => {
          commit('setFetchEvergreenAutomationsLoader', false)
        })
    },

    /**
     * refetch method call in case of filters or search
     * set page number to 1 and fetch automations
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     */
    refetchEvergreenAutomation ({ commit, getters, state, dispatch }) {
      commit(evergreen.SET_EVERGREEN_AUTOMATION_LISTING_PAGE, 1)
      dispatch('fetchEvergreenAutomations')
    },

    /**
     * pagination for automation listing
     * set page number and fetch automations
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param direction
     */
    processEvergreenAutomationPagination (
      { commit, getters, state, dispatch },
      direction
    ) {
      if (direction === 'next') {
        commit(
          evergreen.SET_EVERGREEN_AUTOMATION_LISTING_PAGE,
          getters.getEvergreenAutomationListing.page + 1
        )
      } else if (direction === 'previous') {
        commit(
          evergreen.SET_EVERGREEN_AUTOMATION_LISTING_PAGE,
          getters.getEvergreenAutomationListing.page - 1
        )
      }
      dispatch('fetchEvergreenAutomations')
    },

    /**
     * change automation status
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param automation
     */
    changeEvergreenAutomationStatus (
      { commit, getters, state, dispatch },
      automation
    ) {
      // preapre payload
      let status = 0
      if (automation.status) status = 1
      const payload = {
        status,
        id: automation._id,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      }

      commit('setChangeEvergreenAutomationStatusLoader', true) // showing loader

      // sending request
      proxy
        .post(changeEvergreenAutomationStatusUrl, payload)
        .then((response) => {
          if (response.data.status === true) {
            // changing status of request automation
            $(getters.getEvergreenAutomationListing.items).each(function (
              index,
              el
            ) {
              if (el._id === automation) {
                getters.getEvergreenAutomationListing.items[index].status =
                  status
                return false
              }
            })
          } else {
            dispatch('toastNotification', {
              message: AUTOMATION_CHANGE_STATUS_ERROR,
              type: 'error'
            })
          }

          commit('setChangeEvergreenAutomationStatusLoader', false) // hiding loader
        })
        .catch((response) => {
          commit('setChangeEvergreenAutomationStatusLoader', false)
        })
    },

    /**
     * remove loader when evergreen automation plan content process is completed
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param data
     */
    automationEvergreenContentPlanned (
      { commit, getters, state, dispatch },
      data
    ) {
      console.debug('Method: automationEvergreenContentPlanned')

      // check completed automation is showing in listing
      // and changing lock status to remove loader
      /* getters.getEvergreenAutomationListing.items.forEach(item=>{

      }) */
      for (
        let i = 0;
        i < getters.getEvergreenAutomationListing.items.length;
        i++
      ) {
        if (
          getters.getEvergreenAutomationListing.items[i]._id ===
          data.automation._id
        ) {
          getters.getEvergreenAutomationListing.items[i].lock = 0
          getters.getEvergreenAutomationListing.items[i].last_execution =
            data.automation.last_execution
          getters.getEvergreenAutomationListing.items[i].next_refill_time =
            data.automation.next_refill_time
          break
        }
      }
    },

    /**
     * delete evergreen automation method
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param data
     */
    async removeEvergreenAutomation (
      { commit, getters, state, dispatch },
      data
    ) {
      console.debug('Method:removeEvergreenAutomation')

      commit('setRemoveEvergreenAutomationLoader', true) // show loader

      // prepare payload
      const payload = {
        automation_id: data._id,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      }
      // sending request
      return proxy
        .post(removeEvergreenAutomationUrl, payload)
        .then((response) => {
          return response
        })
        .catch((response) => {
          commit(confirmation.SET_STAGE, 'fail')
          // remove this in future when we combine confirmationBoxes
          commit('setConfirmActionStage', 'fail')
          commit('setRemoveEvergreenAutomationLoader', false)
          dispatch('hideConfirmationBox', 'removeEvergreenAutomation')
        })
    },

    /**
     * evergreen automation bulk operations , active - in active and delete multiple automation in single request
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param operation
     */
    async evergreenAutomationBulkOperation (
      { commit, getters, state, dispatch },
      operation
    ) {
      console.debug('Method:evergreenAutomationBulkOperation')

      commit('setEvergreenAutomationBulkOperationLoader', true) // showing loader
      // prepare payload
      const payload = {
        operation,
        automation_ids: getters.getEvergreenAutomationListing.selected,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      }
      // sending request
      return proxy
        .post(evergreenAutomationBulkOperationUrl, payload)
        .then((response) => {
          return response
        })
        .catch((response) => {
          commit(confirmation.SET_STAGE, 'fail')
          // remove this in future when we combine confirmationBoxes
          commit('setConfirmActionStage', 'fail')
          commit('setEvergreenAutomationBulkOperationLoader', false)
          dispatch('hideConfirmationBox', 'evergreenAutomationBulkOperation')
        })
    },

    /**
     * Evergreen fetch variations from url
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     */
    fetchVariationUrl ({ commit, getters, state, dispatch }) {
      commit(evergreen.SET_FETCH_VARIATION_LOADER, true) // showing loader

      proxy
        .post(fetchVariationsURL, {
          url: getters.getEvergreenVariationData.variationURL
        })
        .then((resp) => {
          if (
            resp.data.status === true &&
            resp.data.variations &&
            resp.data.variations.length > 0
          ) {
            const posts = getters.getEvergreenSelection.posts
            const postsPreview = []
            resp.data.variations.forEach(function (value) {
              const post = JSON.parse(
                JSON.stringify(
                  posts[getters.getEvergreenVariationData.postIndex][0]
                )
              )
              const tags = getHashtagsFromText(post.message)
              let message =
                value.text +
                ' ' +
                getters.getEvergreenVariationData.variationURL // replace variation text and append url
              for (const prop in tags) message = message + ' ' + tags[prop] // apply hashtags
              if (checkUniqueVariation(message, postsPreview)) {
                postsPreview.push(message)
              }
            })
            if (postsPreview.length === 0) {
              dispatch('toastNotification', {
                message: 'No variations found.',
                type: 'error'
              })
            } else {
              commit(
                evergreen.SET_EVERGREEN_VARIATION_PREVIEW_POSTS,
                postsPreview
              )
            }
          } else {
            if (resp.data.message) {
              dispatch('toastNotification', {
                message: resp.data.message,
                type: 'error'
              })
            } else {
              dispatch('toastNotification', {
                message:
                  'We are unable to retrieve the variations for your provided URL, please try a different URL.',
                type: 'error'
              })
            }
          }
          commit(evergreen.SET_FETCH_VARIATION_LOADER, false) // hiding loader
        })
        .catch((response) => {
          commit(evergreen.SET_FETCH_VARIATION_LOADER, false)
        })
    },

    addVariationFromPreview ({ commit, getters, state, dispatch }, variations) {
      commit(evergreen.SET_EVERGREEN_VARIATION_FROM_PREVIEW_LOADER, true)
      let totalVariationAdded = 0
      const posts = getters.getEvergreenSelection.posts
      variations.forEach(function (message) {
        const post = JSON.parse(
          JSON.stringify(posts[getters.getEvergreenVariationData.postIndex][0])
        )
        post.message = message
        if (
          checkUniqueVariation(
            message,
            posts[getters.getEvergreenVariationData.postIndex]
          )
        ) {
          post.id = generateRandomId()
          posts[getters.getEvergreenVariationData.postIndex].push(post)
          totalVariationAdded = totalVariationAdded + 1
        }
      })

      commit(evergreen.SET_EVERGREEN_POSTS, JSON.parse(JSON.stringify(posts)))
      if (totalVariationAdded === 0) {
        dispatch('toastNotification', {
          message: 'No new variations found.',
          type: 'error'
        })
      } else {
        dispatch('toastNotification', {
          message: totalVariationAdded + ' variations added to the post.',
          type: 'success'
        })
      }
      commit(evergreen.SET_EVERGREEN_VARIATION_FROM_PREVIEW_LOADER, false)

      $('#autoVariationGenerate').modal('hide')
    }
  },

  mutations: {
    [evergreen.SET_EVERGREEN_POSTS] (state, status) {
      state.selection.posts = status
    },

    [evergreen.SET_EVERGREEN_BULK_LOADER] (state, status) {
      state.bulk.csvFileUpload = status
    },

    [evergreen.SET_EVERGREEN_BULK_FILENAME] (state, fileName) {
      state.bulk.fileName = fileName
    },

    [evergreen.SET_EVERGREEN_BULK_TOTAL_POSTS] (state, total) {
      state.bulk.total = total
    },

    [evergreen.SET_EVERGREEN_BULK_TOTAL_PROCESS_POSTS] (state, total) {
      state.bulk.totalProcess = total
    },

    [evergreen.SET_EVERGREEN_ACTIVE_POST] (state, activePost) {
      state.selection.active_post = activePost
    },

    [evergreen.SET_EVERGREEN_CREATE_LOADER] (state, status) {
      state.selection.saveEvergreenContent = status
    },

    [evergreen.SET_EVERGREEN_AUTOMATION_LISTING_ITEM] (state, items) {
      state.listing.items = items
    },

    [evergreen.SET_EVERGREEN_AUTOMATION_LISTING_PAGINATION] (state, pagination) {
      state.listing.nextPage = pagination.nextPage
      state.listing.previousPage = pagination.previousPage
    },

    [evergreen.SET_EVERGREEN_AUTOMATION_LISTING_PAGE] (state, page) {
      state.listing.page = page
    },

    [evergreen.SET_EVERGREEN_SELECTED_AUTOMATION] (state, automations) {
      state.listing.selected = automations
    },

    [evergreen.SET_EVERGREEN_SELECTION_DEFAULT_STATES] (state) {
      state.selection = defaultEvergreenAutomation()
    },

    [evergreen.SET_EVERGREEN_DEFAULT_STATES] (state) {
      state.selection = defaultEvergreenAutomation()
      state.listing = defaultEvergreenAutomationListing()
      state.bulk = defaultEvergreenBulk()
      state.variation = defaultEvergreenVariations()
    },

    [evergreen.SET_EVERGREEN_ID] (state, id) {
      state.selection.id = id
    },

    [evergreen.SET_EVERGREEN_NAME] (state, name) {
      state.selection.name = name
    },

    [evergreen.SET_EVERGREEN_STATUS] (state, status) {
      state.selection.status = status
    },

    [evergreen.SET_EVERGREEN_CYCLE_GAP_STATUS] (state, cycleGapStatus) {
      state.selection.cycleGapStatus = cycleGapStatus
    },

    [evergreen.SET_EVERGREEN_CYCLE_GAP] (state, cycleGap) {
      state.selection.cycleGap = cycleGap
    },

    [evergreen.SET_EVERGREEN_LISTING_SELECT_ALL] (state, select) {
      state.listing.select_all = select
    },

    [evergreen.SET_EVERGREEN_AUTOMATION_TAB_STATUS] (state, status) {
      state.selection.tabStatus = status
    },

    [evergreen.SET_EVERGREEN_ADD_STATE] (state, value) {
      state.variation.addState = value
    },

    [evergreen.SET_EVERGREEN_POST_INDEX] (state, index) {
      state.variation.postIndex = index
    },

    [evergreen.SET_EVERGREEN_VARIATION_INDEX] (state, index) {
      state.variation.variationIndex = index
    },

    [evergreen.SET_FETCH_VARIATION_LOADER] (state, index) {
      state.variation.fetchLoader = index
    },

    [evergreen.SET_VARIATION_INDEX] (state, index) {
      state.variation.activeVariationIndex = index
    },

    [evergreen.SET_VARIATION_URL] (state, url) {
      state.variation.variationURL = url
    },

    [evergreen.SET_EVERGREEN_VARIATION_PREVIEW_POSTS] (state, posts) {
      state.variation.variationPreview = posts
    },

    [evergreen.SET_EVERGREEN_VARIATION_FROM_PREVIEW_LOADER] (state, loader) {
      state.variation.addLoader = loader
    },

    [evergreen.SET_EVERGREEN_AUTOMATION] (state, post) {
      state.selection.id = post._id
      state.selection.name = post.name
      state.selection.posts = post.posts
      state.selection.status = post.status
      state.selection.cycleGap = post.cycleGap
      state.selection.cycleGapStatus = post.cycleGapStatus
    },
    [evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_VALUE] (
      state,
      contentCategoryId,
      categoryName
    ) {
      console.log("categoryName",contentCategoryId);
      state.selection.content_category_id = contentCategoryId,
      state.selection.content_category_name= categoryName
    },
    [evergreen.SET_EVERGREEN_AUTOMATION_CONTENT_CATEGORY_NAME] (
      state,
      categoryName
    ) {
      state.selection.content_category_name= categoryName
    }
  },

  getters: {
    getEvergreenSelection: (state) => {
      return state.selection
    },

    getEvergreenAutomationListing: (state) => {
      return state.listing
    },

    getEvergreenAutomationBulk: (state) => {
      return state.bulk
    },

    getEvergreenVariationData: (state) => {
      return state.variation
    },
    getContentCatgoryName:(state)=>{
       return state.selection.content_category_name
    }
  }
}
