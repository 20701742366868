<template>
  <div class="h-screen overflow-x-hidden">
    <div class="grid grid-cols-8 mx-auto h-full">
      <!-- Auth form -->
      <div class="col-span-3">
        <div class="auth-form-container auth-form-container--block">
          <form class="auth-form">
            <div class="auth-form__heading auth-form__heading--center">
              <h2>Approver Login</h2>
            </div>
            <CstFloatingLabelInput
              id="email"
              v-model="email"
              class="mb-6"
              type="email"
              label="Email Address"
              show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Email"></i>
              </template>
            </CstFloatingLabelInput>

            <div class="auth-form__button-field">
              <button
                class="btn btn-studio-theme-space btn-size-large"
                type="submit"
                :disabled="loginLoader"
                @click.prevent="sendMagicLink"
              >
                <span>Send Login Email</span>
                <img
                  v-if="loginLoader"
                  style="width: 20px; margin-left: 8px"
                  src="../assets/img/common/gif_loader_white.gif"
                  alt=""
              /></button>
            </div>
          </form>

          <div class="auth-form-container__footer mt-5">
            <p
              >Not an approver?
              <router-link :to="{ name: 'login' }"
                >Use the regular Sign In</router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-5 h-full">
        <NewFeaturesSlider :show-logo="false">
            <template v-slot:slides>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    w-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-16">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >Dashboard</h2
                    >
                    <p class="text-base mb-4"
                      >Step into a content management revolution with our advanced dashboard.
                      With its intuitive design and user-friendly layout, the dashboard empowers you to streamline,
                      curate, and schedule your content creation effortlessly while managing your day-to-day operations.</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/dashboard.png"
                      alt="Dashboard"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-16">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4">Social Media Publishing & Scheduling</h2>
                    <p class="text-base mb-4">
                      Effortlessly turn ideas into captivating social posts and grow your brand’s presence. Craft,
                      schedule, and share compelling content with ease using our visual multi-channel calendar.
                      Plan smarter and connect deeper with ContentStudio.io.
                    </p>
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/planner.png"
                      alt="Social Media Publishing & Scheduling"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-16">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >Advanced Analytics</h2
                    >
                    <p class="text-base mb-4"
                      >Make informed, data-driven decisions with elegance and ease. Dive into actionable insights,
                      understand audience dynamics, and watch engagement soar with ContentStudio's beautifully crafted
                      reports, ready for team collaboration and client presentations.</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/analytics.png"
                      alt="Advanced Analytics"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-16">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >Unified Inbox</h2
                    >
                    <p class="text-base mb-4"
                      >With ContentStudio's streamlined communication hub, stay connected with your audience, build stronger relationships, and maintain complete control over your brand's social conversations.</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/inbox.png"
                      alt="Unified Inbox"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-16">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >AI-Powered Writing Assistant</h2
                    >
                    <p class="text-base mb-4"
                      >It's time to revolutionize content creation by reducing errors, saving time, and boosting creativity with ContentStudio's AI assistant. Start exploring new, endless possibilities in content marketing now!</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/chat.png"
                      alt="AI-Powered Writing Assistant"
                    />
                  </div>
                </div>
              </Slide>
            </template>
        </NewFeaturesSlider>
      </div>
    </div>
  </div>
</template>

<script>
import { loginWithMagicLinkURL } from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import NewFeaturesSlider from '@src/modules/account/components/NewFeaturesSlider.vue'
import { Slide } from '@jambonn/vue-concise-carousel'
import LogoComponent from '@src/modules/account/components/LogoComponent.vue'
export default {
  name: 'MagicSignIn',
  components: {
    NewFeaturesSlider,
    Slide,
    CstFloatingLabelInput,
    LogoComponent
  },
  data() {
    return {
      email: '',
      loginLoader: false,
    }
  },
  methods: {
    async sendMagicLink() {
      console.debug(this.email)
      const parameters = { email: this.email }
      this.loginLoader = true
      const resp = await this.$http
        .post(loginWithMagicLinkURL, parameters)
        .catch((err) => {
          if (err.response.status === 500) {
            this.alertMessage(UNKNOWN_ERROR, 'error')
            return false
          }
        })
        .finally(() => {
          this.loginLoader = false
        })
      // this.loginLoader = false

      if (resp.data.status === false) {
        this.alertMessage(resp.data.message, 'error')
      } else {
        this.alertMessage(
          'We sent you an email. Click the link in the email to log in instantly.',
          'success'
        )
      }
    },
  },
}
</script>

<style scoped>
::v-deep .VueCarousel-pagination {
  justify-content: center;
  margin-bottom: 1rem;
}

.feature-img-container {
  max-width: 70rem !important;
}

.auth-form-container {
  border-radius: 0 !important;
  padding: 4rem 5.4rem !important;
}

</style>
