<template>
  <div>
    <div
      class="w-full bg-white pt-4 pb-4 px-4 flex items-center justify-between cursor-pointer"
      style="border-top: 0.031rem solid rgba(221, 227, 231, 0.5)"
      @click="
        () => {
          state.toggle = !state.toggle
        }
      "
    >
      <div class="flex items-center">
        <span
          class="text-sm font-medium text-black-900 select-none"
          :class="{ '!text-cs-primary': state.toggle }"
        >
          {{ props.category.title }}
          <span class="mx-1">({{ props.category.prompts.length }})</span>
        </span>
      </div>

      <i
        class="fa fa-chevron-down cursor-pointer text-xs"
        :class="{ 'rotate-180 text-cs-primary': state.toggle }"
      ></i>
    </div>
    <transition name="slide-bottom">
      <div v-if="state.toggle" class="px-4 pt-3">
        <template v-for="(item, index) in props.category.prompts" :key="index">
          <div
            class="bg-[#F4F6FA] rounded-lg p-3 mb-3 select-none cursor-pointer hover:scale-[1.02] transition-all"
            @click="props.handleCustomPrompts(item, 'append')"
          >
            <span class="mt-2 text-[#757A8A] font-normal">{{ item }}</span>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { defineProps, onMounted, reactive, watch } from 'vue'
const props = defineProps({
  type: {
    type: String,
    default: 'general',
  },
  handleCustomPrompts: {
    type: Function,
    default: () => {},
  },
  category: {
    type: Object,
    default: () => {},
  },
  searchTerm: {
    type: String,
    default: '',
  },
})
const state = reactive({
  toggle: false,
})
onMounted(() => {
  if (props.searchTerm !== '') {
    state.toggle = true
  }
})
// watch(
//   () => props.searchTerm,
//   (val) => {
//     if(val !== ''){
//       state.toggle = true
//       console.log('searchTerm',val)
//     }
//   }
// )
</script>
