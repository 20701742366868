import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  fetchHashTagsVelocityUrl,
  fetchPostHashTagsUrl,
  processRetweetShareUrl,
  processSocialContentCategoryPostURL,
  processSocialShareUrl,
  processHideFromclient,
  fetchGlobalCategoryChannelsUrl
} from '@src/modules/publish/config/api-utils'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import {
  commonTypes,
  instagramTypes,
  facebookSharingTypes,
  gmb,
  instagramSharingTypes,
  linkedinSharingTypes,
  pinterestSharingTypes,
  publish,
  publishLoaders,
  tumblrSharingTypes,
  twitterSharingTypes,
  blogPosting, youtubeTypes, youtubeSharingTypes, social, sharingTypes, tiktokSharingTypes
} from '@src/modules/publish/store/states/mutation-types'
import { planner } from '@src/modules/planner/store/mutation-types'
import proxy from '@common/lib/http-common'
import { EventBus } from '@common/lib/event-bus'
import { socialChannelsNameArray } from '../../../constants/common-attributes'
import { socialPlatformNames } from '../../../lib/integrations'

import { UNKNOWN_ERROR } from '../../../constants/messages'

const he = require('he')

export const sharingMixin = {
  computed: {
    ...mapGetters(['getCommonSharingDetails', 'getCommonBoxOptions', 'getFacebookSharingDetails', 'getTwitterSharingDetails', 'getThreadedTweetsSharingDetails',
      'getLinkedinSharingDetails', 'getPinterestSharingDetails', 'getTumblrSharingDetails', 'getAccountSelection', 'getCommonBoxOptions',
      'getTasks', 'getComments', 'getBlogPostingDetails', 'getPublishLoaders', 'getTwitterRetweetSelection', 'getInstagramSharingDetails',
      'getPinterestboxRef', 'getFacebookboxRef', 'getInstagramboxRef', 'getTwitterboxRef', 'getLinkedinboxRef', 'getTumblrboxRef', 'getCommonboxRef',
      'getDraftCancelAction', 'getAutomationScheduleOptions', 'getGmbAccounts', 'getGmbSharingDetails', 'getGmbSharingLoaders', 'getGmbboxRef', 'getGmbOptions',
      'getApprovalStages', 'getInstagramSharingLoaders', 'getLinkedinSharingLoaders', 'getYoutubeSharingDetails', 'getYoutubeboxRef', 'getFacebookOptions',
      'getYoutubeOptions', 'getYoutubeSharingLoaders', 'getInstagramPostingOption', 'getSocialSharingMediaDetails', 'getSocialSharingFirstComment',
      'getTwitterOptions', 'getPinterestOptions', 'getCarouselOptions', 'getTiktokSharingDetails'
    ]),

    disableSharingBoxToggleOption () {
      if (this.isGlobalContentCategorySelected) return false
      let platformsCount = 0
      socialChannelsNameArray.forEach(item => {
        platformsCount += this.getAccountSelection[item].length
      })
      return !(platformsCount > 0 || !this.getCommonBoxOptions.status)
    },

    sharingPendingProcessStatus () {
      if (this.getCommonBoxOptions.status) {
        if (this.getPublishLoaders.uploadCommonSharingImage.status || this.getPublishLoaders.upload_common_sharing_video || this.getPublishLoaders.fetchCommonSharingPreview) {
          return true
        }
      } else {
        if (this.getPublishLoaders.uploadFacebookSharingImage.status || this.getPublishLoaders.upload_facebook_sharing_video || this.getPublishLoaders.fetchFacebookSharingPreview) {
          return true
        }

        if (this.getPublishLoaders.uploadTwitterSharingImage.status || this.getPublishLoaders.upload_twitter_sharing_video || this.getPublishLoaders.fetchTwitterSharingPreview) {
          return true
        }

        if (this.getLinkedinSharingLoaders.upload_sharing_image.status || this.getPublishLoaders.upload_linkedin_sharing_video || this.getPublishLoaders.fetchLinkedinSharingPreview) {
          return true
        }

        if (this.getPublishLoaders.uploadPinterestSharingImage || this.getPublishLoaders.upload_pinterest_sharing_video || this.getPublishLoaders.fetchPinterestSharingPreview) {
          return true
        }

        if (this.getPublishLoaders.uploadTumblrSharingImage || this.getPublishLoaders.upload_tumblr_sharing_video || this.getPublishLoaders.fetchTumblrSharingPreview) {
          return true
        }

        if (this.getInstagramSharingLoaders.upload_sharing_image.status || this.getPublishLoaders.upload_instagram_sharing_video || this.getPublishLoaders.fetchInstagramSharingPreview) {
          return true
        }

        if (this.getYoutubeSharingLoaders.upload_sharing_image.status || this.getPublishLoaders.upload_youtube_sharing_video || this.getPublishLoaders.fetchYoutubeSharingPreview) {
          return true
        }

        if (this.getGmbSharingLoaders.upload_sharing_image || this.getGmbSharingLoaders.upload_sharing_video || this.getGmbSharingLoaders.fetch_sharing_preview) {
          return true
        }
      }
      const tweets = this.getThreadedTweetsSharingDetails
      if (tweets && tweets.length > 0) {
        for (const i in tweets) {
          if (tweets[i].uploadThreadedTweetsSharingImage.status || tweets[i].upload_thread_tweets_sharing_video) {
            return true
          }
        }
      }

      return false
    }

  },
  methods: {
    ...mapMutations(['RESET_COMMENTS_LIST', 'RESET_TASK_LIST', 'SET_CSV_PAGE', 'SET_CREATE_TASK_LOADER', 'SET_CREATE_COMMENT_LOADER',
      'setCommonSharingMessage', 'setFacebookSharingMessage', 'setTwitterSharingMessage', 'setPinterestSharingMessage', 'setTumblrSharingMessage']),
    ...mapActions([
      'setCommonSharingDetails', 'setFacebookSharingDetails', 'setTwitterSharingDetails', 'setFetchHashTagsVelocityLoader',
      'setPinterestSharingDetails', 'setTumblrSharingDetails',
      'setPublishingTimeType', 'setSocialSharingTagsSuggestionsItems', 'setSocialSharingPostId', 'setUploadCommonSharingImageLoader',
      'setUploadFacebookSharingImageLoader', 'setUploadTwitterSharingImageLoader',
      'setUploadPinterestSharingImageLoader', 'setUploadTumblrSharingImageLoader', 'setCommonSharingImage', 'setFacebookSharingImage',
      'setTwitterSharingImage', 'setFetchCommonSharingPreviewLoader', 'setFetchFacebookSharingPreviewLoader', 'setFetchTwitterSharingPreviewLoader',
      'setFetchLinkedinSharingPreviewLoader', 'setFetchPinterestSharingPreviewLoader', 'setFetchTumblrSharingPreviewLoader',
      'setCommonSharingMessage', 'setFacebookSharingMessage', 'setTwitterSharingMessage', 'setPinterestSharingMessage',
      'setTumblrSharingMessage', 'setCommonSharingLink', 'setFacebookSharingLink', 'setTwitterSharingLink',
      'setPinterestSharingLink', 'setTumblrSharingLink', 'setFacebookSharingLink', 'setFetchCommonShortLinksLoaders',
      'setFetchFacebookShortLinksLoaders', 'setFetchTwitterShortLinksLoaders', 'setFetchLinkedinShortLinksLoaders',
      'setFetchPinterestShortLinksLoaders', 'setFetchTumblrShortLinksLoaders', 'setPinterestSharingImage',
      'setTumblrSharingImage', 'setPublishingTimeType', 'setProcessSocialShareLoader',
      'setPublishPlanId', 'setCommonFacebookSharingLink', 'setCommonTwitterSharingLink',
      'setCommonLinkedinSharingLink', 'setCommonPinterestSharingLink', 'setCommonTumblrSharingLink', 'setProcessRetweetShareLoader', 'setTwitterAccountSelection',
      'setFetchInstagramSharingPreviewLoader', 'setFetchYoutubeSharingPreviewLoader', 'setFetchInstagramShortLinksLoaders',
      'setCommonInstagramSharingLink',
      'setSharingTagsReplaceStatus', 'setSharingActiveTab',
      'setBlogPostType', 'saveTask', 'saveComment', 'setDraftCancelAction', 'setPublicationStatus'
    ]),

    setFetchSharingPreviewLoaders (type, status) {
      switch (type) {
        case 'All':
          this.setFetchCommonSharingPreviewLoader(status)
          this.setFetchFacebookSharingPreviewLoader(status)
          this.setFetchTwitterSharingPreviewLoader(status)
          this.setFetchLinkedinSharingPreviewLoader(status)
          this.setFetchPinterestSharingPreviewLoader(status)
          this.setFetchTumblrSharingPreviewLoader(status)
          this.setFetchInstagramSharingPreviewLoader(status)
          this.setFetchYoutubeSharingPreviewLoader(status)
          this.$store.commit(gmb.SET_FETCH_SHARING_PREVIEW_LOADER, status)
          break
        case 'Common':
          this.setFetchCommonSharingPreviewLoader(status)
          break
        case 'Facebook':
          this.setFetchFacebookSharingPreviewLoader(status)
          break
        case 'Twitter':
          this.setFetchTwitterSharingPreviewLoader(status)
          break
        case 'Linkedin':
          this.setFetchLinkedinSharingPreviewLoader(status)
          break
        case 'Pinterest':
          this.setFetchPinterestSharingPreviewLoader(status)
          break
        case 'Tumblr':
          this.setFetchTumblrSharingPreviewLoader(status)
          break
        case 'Instagram':
          this.setFetchInstagramSharingPreviewLoader(status)
          break
        case 'Youtube':
          this.setFetchYoutubeSharingPreviewLoader(status)
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_FETCH_SHARING_PREVIEW_LOADER, status)
          break
      }
    },

    setFetchShortLinksLoaders (type, status) {
      switch (type) {
        case 'All':
          this.setFetchCommonShortLinksLoaders(status)
          this.setFetchFacebookShortLinksLoaders(status)
          this.setFetchTwitterShortLinksLoaders(status)
          this.setFetchLinkedinShortLinksLoaders(status)
          this.setFetchPinterestShortLinksLoaders(status)
          this.setFetchTumblrShortLinksLoaders(status)
          this.setFetchInstagramShortLinksLoaders(status)
          this.$store.commit(gmb.SET_FETCH_SHORT_LINKS_LOADER, status)
          break
        case 'Common':
          this.setFetchCommonShortLinksLoaders(status)
          break
        case 'Facebook':
          this.setFetchFacebookShortLinksLoaders(status)
          break
        case 'Twitter':
          this.setFetchTwitterShortLinksLoaders(status)
          break
        case 'Linkedin':
          this.setFetchLinkedinShortLinksLoaders(status)
          break
        case 'Pinterest':
          this.setFetchPinterestShortLinksLoaders(status)
          break
        case 'Tumblr':
          this.setFetchTumblrShortLinksLoaders(status)
          break
        case 'Instagram':
          this.setFetchInstagramShortLinksLoaders(status)
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_FETCH_SHORT_LINKS_LOADER, status)
          break
        case 'Youtube':
          this.setFetchYoutubeShortLinksLoaders(status)
          break
      }
    },

    setDefaultSharingDetails () {
      this.$store.commit(commonTypes.RESET_COMMON_SHARING_DETAILS)
      this.$store.commit(facebookSharingTypes.RESET_FACEBOOK_SHARING_DETAILS)
      this.$store.commit(twitterSharingTypes.RESET_TWITTER_SHARING_DETAILS)
      this.$store.commit(linkedinSharingTypes.SET_DEFAULT_SHARING)
      this.$store.commit(pinterestSharingTypes.RESET_PINTEREST_SHARING_DETAILS)
      this.$store.commit(tumblrSharingTypes.RESET_TUMBLR_SHARING_DETAILS)
      this.$store.commit(instagramSharingTypes.RESET_INSTAGRAM_SHARING_DETAILS)
      this.$store.commit(youtubeSharingTypes.RESET_YOUTUBE_SHARING_DETAILS)
      this.$store.commit(tiktokSharingTypes.RESET_TIKTOK_SHARING_DETAILS)
      this.$store.commit(gmb.SET_SHARING_DETAILS, null)
      this.$store.commit(social.SET_YOUTUBE_OPTIONS, null)
      this.$store.commit(social.SET_PINTEREST_OPTIONS, null)
    },

    setSharingPreview (type, preview) {
      switch (type) {
        case 'All':
          this.setCommonSharingPreview(preview)
          this.setFacebookSharingPreview(preview)
          this.setTwitterSharingPreview(preview)
          this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, preview)
          this.setPinterestSharingPreview(preview)
          this.setTumblrSharingPreview(preview)
          this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, preview)
          this.$store.commit(gmb.SET_SHARING_PREVIEW, preview)
          this.$store.commit(youtubeTypes.SET_SHARING_PREVIEW, preview)
          break
        case 'Common':
          this.setCommonSharingPreview(preview)
          break
        case 'Facebook':
          this.setFacebookSharingPreview(preview)
          break
        case 'Twitter':
          this.setTwitterSharingPreview(preview)
          break
        case 'Linkedin':
          this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, preview)
          break
        case 'Pinterest':
          this.setPinterestSharingPreview(preview)
          break
        case 'Tumblr':
          this.setTumblrSharingPreview(preview)
          break
        case 'Instagram':
          this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, preview)
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_SHARING_PREVIEW, preview)
          break
        case 'Youtube':
          this.$store.commit(youtubeTypes.SET_SHARING_PREVIEW, preview)
          break
      }
    },

    setSharingImage (link) {
      if (link) {
        this.setCommonSharingImage([link])
        this.setFacebookSharingImage([link])
        this.setTwitterSharingImage([link])
        this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [link])
        this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [link])
      } else {
        this.setCommonSharingImage([])
        this.setFacebookSharingImage([])
        this.setTwitterSharingImage([])
        this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [])
        this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [])
      }

      this.setPinterestSharingImage(link)
      this.setTumblrSharingImage(link)
      this.$store.commit(gmb.SET_SHARING_IMAGE, link)
      // this.$store.commit(youtubeTypes.SET_SHARING_IMAGE, link)
    },

    setSharingMessage (type, message, cursor = 0) {
      console.debug('Method::setSharingMessage', type, message)
      let selector = ''
      switch (type) {
        case 'All':
          // this.setCommonSharingMessage(message)
          this.$store.commit('setCommonSharingMessage', message)
          selector = document.getElementById('common_sharing_message')
          // document.getElementById('common_sharing_message').value = message

          // this.setFacebookSharingMessage(message)
          this.$store.commit('setFacebookSharingMessage', message)
          selector = document.getElementById('facebook_sharing_message')
          // document.getElementById('facebook_sharing_message').value = message

          // this.setTwitterSharingMessage(message)
          this.$store.commit('setTwitterSharingMessage', message)
          selector = document.getElementById('twitter_sharing_message')
          // document.getElementById('twitter_sharing_message').value = message

          this.$store.commit(linkedinSharingTypes.SET_SHARING_MESSAGE, message)
          selector = document.getElementById('linkedin_sharing_message')
          // document.getElementById('linkedin_sharing_message').value = message

          // this.setPinterestSharingMessage(message)
          this.$store.commit('setPinterestSharingMessage', message)
          this.$store.commit('setPinterestDescription', message)
          selector = document.getElementById('pinterest_sharing_message')
          // document.getElementById('pinterest_sharing_message').value = message

          // this.setTumblrSharingMessage(message)
          this.$store.commit('setTumblrSharingMessage', message)
          selector = document.getElementById('tumblr_sharing_message')
          // document.getElementById('tumblr_sharing_message').value = message

          this.$store.commit(instagramTypes.SET_SHARING_MESSAGE, message)
          selector = document.getElementById('instagram_sharing_message')
          // document.getElementById('instagram_sharing_message').value = message

          this.$store.commit(gmb.SET_SHARING_MESSAGE, message)
          selector = document.getElementById('gmb_sharing_message')
          // document.getElementById('gmb_sharing_message').value = message

          this.$store.commit(youtubeTypes.SET_SHARING_MESSAGE, message)
          selector = document.getElementById('youtube_sharing_message')
        case 'Common':
          // this.setCommonSharingMessage(message)
          this.$store.commit('setCommonSharingMessage', message)
          this.$store.commit('setPinterestDescription', message)
          EventBus.$emit('update-conversation-textarea', message)
          if (document.getElementById('common_sharing_message')) selector = document.getElementById('common_sharing_message')
          break
        case 'Facebook':
          // this.setFacebookSharingMessage(message)
          this.$store.commit('setFacebookSharingMessage', message)
          if (document.getElementById('facebook_sharing_message')) selector = document.getElementById('facebook_sharing_message')
          break
        case 'Twitter':
          // this.setTwitterSharingMessage(message)
          this.$store.commit('setTwitterSharingMessage', message)
          if (document.getElementById('twitter_sharing_message')) selector = document.getElementById('twitter_sharing_message')
          break
        case 'Linkedin':
          this.$store.commit(linkedinSharingTypes.SET_SHARING_MESSAGE, message)
          if (document.getElementById('linkedin_sharing_message')) selector = document.getElementById('linkedin_sharing_message')
          break
        case 'Pinterest':
          // this.setPinterestSharingMessage(message)
          this.$store.commit('setPinterestSharingMessage', message)
          this.$store.commit('setPinterestDescription', message)
          if (document.getElementById('pinterest_sharing_message')) selector = document.getElementById('pinterest_sharing_message')
          break
        case 'Tumblr':
          // this.setTumblrSharingMessage(message)
          this.$store.commit('setTumblrSharingMessage', message)
          if (document.getElementById('tumblr_sharing_message')) selector = document.getElementById('tumblr_sharing_message')
          break
        case 'Instagram':
          this.$store.commit(instagramTypes.SET_SHARING_MESSAGE, message)
          if (document.getElementById('instagram_sharing_message')) selector = document.getElementById('instagram_sharing_message')
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_SHARING_MESSAGE, message)
          if (document.getElementById('gmb_sharing_message')) selector = document.getElementById('gmb_sharing_message')
          break
        case 'Youtube':
          this.$store.commit(youtubeTypes.SET_SHARING_MESSAGE, message)
          if (document.getElementById('youtube_sharing_message')) selector = document.getElementById('youtube_sharing_message')
          break
      }
      if (selector) selector.value = message
      if (cursor !== 0) {
        selector.selectionStart = cursor
        selector.selectionEnd = cursor
      }
      this.allTextareaFocus()
    },

    // for particular textarea reference call
    textareaFocus (ref) {
      console.debug('Method:textareaFocus')
      setTimeout(() => {
        ref.$el.focus()
      }, 200)
    },

    // for all textarea reference call
    allTextareaFocus () {
      console.debug('Method:allTextareaFocus')
      setTimeout(() => {
        if (this.getPinterestboxRef) {
          this.getPinterestboxRef.$el.focus()
        }
        if (this.getFacebookboxRef) {
          this.getFacebookboxRef.$el.focus()
        }
        if (this.getInstagramboxRef) {
          this.getInstagramboxRef.$el.focus()
        }
        if (this.getTwitterboxRef) {
          this.getTwitterboxRef.$el.focus()
        }
        if (this.getLinkedinboxRef) {
          this.getLinkedinboxRef.$el.focus()
        }
        if (this.getTumblrboxRef) {
          this.getTumblrboxRef.$el.focus()
        }
        if (this.getGmbboxRef) {
          this.getGmbboxRef.$el.focus()
        }
        if (this.getYoutubeboxRef) {
          this.getYoutubeboxRef.$el.focus()
        }
        if (this.getCommonboxRef) {
          this.getCommonboxRef.$el.focus()
        }

        // alert("done")
      }, 200)
    },

    changeSharingBoxOption () {
      console.debug('Method:changeSharingBoxOption', this.getCommonBoxOptions.status)

      const message = this.getCommonSharingDetails.message.trim()

      if (this.getCommonBoxOptions.status) {
        const platforms = socialPlatformNames()

        const common_details = this.getCommonSharingDetails

        if (message) {
          const urls = this.getLinksFromText(message)

          if (this.getFacebookSharingDetails.message.trim() === '') {
            let facebookMessage = message
            if (this.getCommonSharingDetails.facebook_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.facebook_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (facebookMessage.includes(element)) {
                    facebookMessage = facebookMessage.replace(element, '')
                  }
                })
                facebookMessage += ' ' + this.getCommonSharingDetails.facebook_url
              }
            }
            this.setSharingMessage('Facebook', facebookMessage)
            if (common_details.mentions && common_details.mentions.length > 0) this.$store.commit(facebookSharingTypes.SET_MENTIONS, JSON.parse(JSON.stringify(common_details.mentions)))
          }

          if (this.getTwitterSharingDetails.message.trim() === '') {
            let twitterMessage = message
            if (this.getCommonSharingDetails.twitter_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.twitter_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (twitterMessage.includes(element)) {
                    twitterMessage = twitterMessage.replace(element, '')
                  }
                })
                twitterMessage += ' ' + this.getCommonSharingDetails.twitter_url
              }
            }
            this.setSharingMessage('Twitter', twitterMessage)
          }

          if (this.getLinkedinSharingDetails.message.trim() === '') {
            let linkedinMessage = message
            if (this.getCommonSharingDetails.linkedin_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.linkedin_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (linkedinMessage.includes(element)) {
                    linkedinMessage = linkedinMessage.replace(element, '')
                  }
                })
                linkedinMessage += ' ' + this.getCommonSharingDetails.linkedin_url
              }
            }
            this.setSharingMessage('Linkedin', linkedinMessage)
          }

          if (this.getPinterestSharingDetails.message.trim() === '') {
            let pinterestMessage = message
            if (this.getCommonSharingDetails.pinterest_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.pinterest_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (pinterestMessage.includes(element)) {
                    pinterestMessage = pinterestMessage.replace(element, '')
                  }
                })
                pinterestMessage += ' ' + this.getCommonSharingDetails.pinterest_url
              }
            }
            this.setSharingMessage('Pinterest', pinterestMessage)
          }

          if (this.getTumblrSharingDetails.message.trim() === '') {
            let tumblrMessage = message
            if (this.getCommonSharingDetails.tumblr_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.tumblr_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (tumblrMessage.includes(element)) {
                    tumblrMessage = tumblrMessage.replace(element, '')
                  }
                })
                tumblrMessage += ' ' + this.getCommonSharingDetails.tumblr_url
              }
            }
            this.setSharingMessage('Tumblr', tumblrMessage)
          }

          if (this.getInstagramSharingDetails.message.trim() === '') {
            let instagramMessage = message
            if (this.getCommonSharingDetails.instagram_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.instagram_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (instagramMessage.includes(element)) {
                    instagramMessage = instagramMessage.replace(element, '')
                  }
                })
                instagramMessage += ' ' + this.getCommonSharingDetails.instagram_url
              }
            }
            this.setSharingMessage('Instagram', instagramMessage)
          }

          if (this.getGmbSharingDetails.message.trim() === '') {
            let gmbMessage = message
            if (this.getCommonSharingDetails.gmb_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.gmb_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (gmbMessage.includes(element)) {
                    gmbMessage = gmbMessage.replace(element, '')
                  }
                })
                gmbMessage += ' ' + this.getCommonSharingDetails.gmb_url
              }
            }
            this.setSharingMessage('Gmb', gmbMessage)
          }

          if (this.getYoutubeSharingDetails.message.trim() === '') {
            let youtubeMessage = message
            if (this.getCommonSharingDetails.youtube_url && this.getCommonSharingDetails.url !== this.getCommonSharingDetails.youtube_url) {
              if (urls) {
                urls.forEach(function (element) {
                  if (youtubeMessage.includes(element)) {
                    youtubeMessage = youtubeMessage.replace(element, '')
                  }
                })
                youtubeMessage += ' ' + this.getCommonSharingDetails.youtube_url
              }
            }
            this.setSharingMessage('Youtube', youtubeMessage)
          }
        }
        if (common_details.location.facebook && common_details.location.facebook.id) {
          this.setSecificBoxLocation(common_details.location.facebook, 'facebook')
        }
        if (common_details.location.instagram && common_details.location.instagram.id) {
          this.setSecificBoxLocation(common_details.location.instagram, 'instagram')
        }
        if (common_details.location.twitter && common_details.location.twitter.id) {
          this.setSecificBoxLocation(common_details.location.twitter, 'twitter')
        }
        if (common_details.url) {
          // setting each platform link preview if the link is null for that platform
          platforms.forEach((platform) => {
            if(platform === 'tiktok') return
            platform = platform.charAt(0).toUpperCase() + platform.slice(1)
            if (!this['get' + platform + 'SharingDetails'].url && this['get' + platform + 'SharingDetails'].video && !this['get' + platform + 'SharingDetails'].video.link && (!this['get' + platform + 'SharingDetails'].image || this['get' + platform + 'SharingDetails'].image.length === 0)) {
              // setting link in states
              const link = JSON.parse(JSON.stringify(common_details[platform.toLowerCase() + '_url']))
              if (platform === 'Youtube') this.$store.commit(youtubeTypes.SET_SHARING_URL, link)
              if (platform === 'Gmb') this.$store.commit(gmb.SET_SHARING_URL, link)
              if (platform === 'Linkedin') this.$store.commit(linkedinSharingTypes.SET_SHARING_URL, link)
              if (platform === 'Instagram') this.$store.commit(instagramTypes.SET_SHARING_URL, link)
              if (platform !== 'Gmb' && platform !== 'Linkedin' && platform !== 'Instagram' && platform !== 'Youtube') this.$store.commit('set' + platform + 'SharingLink', link)
              const preview = this.parseBoxPreview(platform, common_details)
              if (platform === 'Gmb') this.$store.commit(gmb.SET_SHARING_PREVIEW, preview)
              if (platform === 'Instagram') this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, preview)
              if (platform === 'Linkedin') this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, preview)
              if (platform !== 'Gmb' && platform !== 'Linkedin' && platform !== 'Instagram' && platform !== 'Youtube') this.$store.commit('set' + platform + 'SharingPreview', preview)
            }
          })
        }

        // if common box has video
        else if (this.getCommonSharingDetails.video && this.getCommonSharingDetails.video.link) {
          // setting each platform video if the video in null for that platform
          platforms.forEach((platform) => {
            platform = platform.charAt(0).toUpperCase() + platform.slice(1)
            if (platform === 'Pinterest') { return }
            if (this['get' + platform + 'SharingDetails'].video &&
                !this['get' + platform + 'SharingDetails'].video.link &&
                (!this['get' + platform + 'SharingDetails'].image || this['get' + platform + 'SharingDetails'].image.length === 0)) {
              console.debug('getCommonSharingDetails ->', this.getCommonSharingDetails.video)

              if (!(platform === 'Facebook' || platform === 'Common' || platform === 'Youtube')) {
                const commonData = { ...this.getCommonSharingDetails.video }

                commonData.thumbnails_suggestions = commonData.thumbnails_suggestions.filter((thumbnail) => {
                  if (!thumbnail.includes('contentstudio-media-library-nearline')) {
                    return thumbnail
                  }
                })

                if (commonData.thumbnails_suggestions.length && commonData.thumbnail.includes('contentstudio-media-library-nearline')) {
                  commonData.thumbnail = commonData.thumbnails_suggestions[0]
                }

                this.$store.commit('SET_' + platform.toUpperCase() + '_SHARING_VIDEO', JSON.parse(JSON.stringify(commonData)))
              } else {
                this.$store.commit('SET_' + platform.toUpperCase() + '_SHARING_VIDEO', JSON.parse(JSON.stringify(this.getCommonSharingDetails.video)))
              }
            }
          })
        }
        // if common box has image
        else if (this.getCommonSharingDetails.image && this.getCommonSharingDetails.image.length) {
          // setting each platform image if the image is null for that platform
          platforms.forEach((platform) => {
            platform = platform.charAt(0).toUpperCase() + platform.slice(1)
            if (platform !== 'Youtube') {
              if (this['get' + platform + 'SharingDetails'].video && !this['get' + platform + 'SharingDetails'].video.link && (!this['get' + platform + 'SharingDetails'].image || this['get' + platform + 'SharingDetails'].image.length === 0)) {
                this.$store.commit('SET_' + platform.toUpperCase() + '_SHARING_VIDEO', null)
                const image = JSON.parse(JSON.stringify(this.getCommonSharingDetails.image))
                if (platform === 'Gmb') {
                  this.$store.commit(gmb.SET_SHARING_IMAGE, image[0])
                } else if (platform === 'Linkedin') {
                  this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, image)
                } else if (platform === 'Instagram') {
                  this.$store.commit(instagramTypes.SET_SHARING_IMAGE, image)
                } else {
                  this.$store.commit('set' + platform + 'SharingImage', platform === 'Facebook' || platform === 'Twitter' ? image : image[0])
                }
              }
            }
          })
        }
      }

      if (!this.getCommonBoxOptions.status === false &&
          !this.getPublishSelection.queueStatus && !this.isGlobalContentCategorySelected &&
          !this.getPublishSelection.content_category_id
      ) {
        this.setPublishingTimeType('queued')
      }

      if (this.getCommonBoxOptions.status && this.isGlobalContentCategorySelected) {
        // service call here
        this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, !this.getCommonBoxOptions.status)
        this.fetchGlobalCategoryChannels()
      } else {
        this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, !this.getCommonBoxOptions.status)
      }

      // adjust textarea auto size
      setTimeout(() => {
        this.allTextareaFocus()
      }, 300)
    },

    fetchGlobalCategoryChannels () {
      this.$store.commit(commonTypes.SET_GLOBAL_CATEGORIES_STATUS_LOADER, true)
      this.postRequest(fetchGlobalCategoryChannelsUrl, { 'content_category_id': this.getSelectedGlobalContentCategoryId() }, response => {
        if (response.data.status === true) {
          this.$store.commit(commonTypes.SET_GLOBAL_CATEGORIES_CHANNELS, response.data.channels)
          let availableChannel = false

          const keys = ['facebook', 'gmb', 'instagram', 'linkedin', 'pinterest', 'tumblr', 'twitter']
          keys.forEach(function (element) {
            if (response.data.channels && response.data.channels[element]) availableChannel = true
          })
          if (!availableChannel) {
            this.alertMessage('No social accounts found in this global content category.', 'error')
            this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, !this.getCommonBoxOptions.status)
          }
        } else {
          this.alertMessage(response.data.message, 'error')
          this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, !this.getCommonBoxOptions.status)
        }

        this.$store.commit(commonTypes.SET_GLOBAL_CATEGORIES_STATUS_LOADER, false)
      }, error => {
        console.debug('Exception in changeSharingBoxOption ', error)
        this.alertMessage(UNKNOWN_ERROR, 'error')
        this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, !this.getCommonBoxOptions.status)
        this.$store.commit(commonTypes.SET_GLOBAL_CATEGORIES_STATUS_LOADER, false)
      })
    },

    /**
     * For generating separate box link preview base on common box.
     * @param platform
     * @param common
     * @returns {*}
     */
    parseBoxPreview (platform, common) {
      console.debug('Method::parseBoxPreview', platform, common)
      platform = platform.toLowerCase()
      if (platform === 'twitter') {
        return {
          title: common.twitter_title,
          description: common.twitter_description,
          website: common.twitter_website,
          image: common.twitter_image,
          image_suggestions: common.image_suggestions
        }
      }
      if (platform === 'facebook' || platform === 'linkedin') {
        return {
          title: common.title,
          description: common.description,
          website: common.website,
          image: common.image,
          image_suggestions: common.image_suggestions
        }
      }
      if (platform === 'instagram') return { image: common.image }

      return {
        image: common.image ? common.image[0] : ''
      }
    },

    initializeSocialShare (options, publishSource = '') {
      console.debug('Method:initializeSocialShare', options)
      this.setDefaultSharingDetails()

      this.setPublishingTimeType('queued')
      this.setSocialSharingTagsSuggestionsItems([])
      this.$store.commit(publish.SET_PUBLISH_SOURCE, publishSource)

      // @jalib::reset activities as well
      this.RESET_TASK_LIST()
      this.RESET_COMMENTS_LIST()
      // this.getPublishSelection.labels = []
      // this.getPublishSelection.members = []
      this.getPublishSelection.content_category_id = ''
      this.setDraftCancelAction(false)
      this.processPost(options)
    },

    processPost (options) {
      console.debug('processPost -> ', options)
      if (options._id) this.setSocialSharingPostId(options.post_id)
      if (options.id) this.fetchPostHashTags({ 'id': options.id })
      if (options.image) {
        this.setSharingImage(options.image)
      } else {
        this.setSharingImage('')
      }
      let message = ''
      if (options.title) {
        message += he.decode(options.title)
        if (options.url) {
          message = message.trim() + ' '
          // for solving two links issues(one link in title and one link is the post link)
          const urls = this.getLinksFromText(message)
          if (urls) {
            $(urls).each(function (index, el) {
              message = message.replace(el, '')
            })
          }
        }
      }
      if (options.url) {
        const response = this.fetchUtmBasedLinks(options.url, this.fetchActiveUtm)
        this.setFetchSharingPreviewLoaders('All', true)
        this.setCommonSharingLink(response.common)
        this.setCommonFacebookSharingLink(response.facebook)
        this.setCommonTwitterSharingLink(response.twitter)
        this.setCommonLinkedinSharingLink(response.linkedin)
        this.setCommonPinterestSharingLink(response.pinterest)
        this.setCommonTumblrSharingLink(response.tumblr)
        this.setCommonInstagramSharingLink(response.instagram)
        this.$store.commit(commonTypes.SET_GMB_SHARING_LINK, response.gmb)
        this.setFacebookSharingLink(response.facebook)
        this.setTwitterSharingLink(response.twitter)
        this.$store.commit(linkedinSharingTypes.SET_SHARING_URL, response.linkedin)
        this.setPinterestSharingLink(response.pinterest)
        this.setTumblrSharingLink(response.tumblr)
        this.$store.commit(instagramTypes.SET_SHARING_URL, response.instagram)
        this.$store.commit(gmb.SET_SHARING_URL, response.gmb)

        let commonMessage = message + response.common
        const facebookMessage = message + response.facebook
        let twitterMessage = message + response.twitter
        const linkedinMessage = message + response.linkedin
        const pinterestMessage = message + response.pinterest
        const tumblrMessage = message + response.tumblr
        const instagramMessage = message + response.instagram
        const gmbMessage = message + response.gmb

        if (options) {
          if (options.twitter_handler) {
            commonMessage = commonMessage + ' via ' + options.twitter_handler
            twitterMessage = twitterMessage + ' via ' + options.twitter_handler
          } else if (options.twitter_accounts && options.twitter_accounts.constructor === Array) {
            commonMessage = commonMessage + ' via ' + options.twitter_accounts[0]
            twitterMessage = twitterMessage + ' via ' + options.twitter_accounts[0]
          }
        }

        this.setSharingMessage('Common', commonMessage)
        this.setSharingMessage('Facebook', facebookMessage)
        this.setSharingMessage('Twitter', twitterMessage)
        this.setSharingMessage('Linkedin', linkedinMessage)
        this.setSharingMessage('Pinterest', pinterestMessage)
        this.setSharingMessage('Tumblr', tumblrMessage)
        this.setSharingMessage('Instagram', instagramMessage)
        this.$store.commit(gmb.SET_SHARING_MESSAGE, gmbMessage)

        const urls = [response.common]
        if (response.auto_add_social_channel) {
          urls.push(response.facebook)
          urls.push(response.twitter)
          urls.push(response.linkedin)
          urls.push(response.pinterest)
          urls.push(response.tumblr)
          urls.push(response.instagram)
          urls.push(response.gmb)
        }

        if (this.getWorkspaces.activeWorkspace.shortener && this.checkLongLinkExistence(urls)) {
          this.fetchShortLinks(urls, 'All')
        } else {
          this.parseLinkPreview(urls, 'All')
        }
      } else {
        this.setSharingMessage('Common', message)
        this.setSharingMessage('Facebook', message)
        this.setSharingMessage('Twitter', message)
        this.setSharingMessage('Linkedin', message)
        this.setSharingMessage('Pinterest', message)
        this.setSharingMessage('Tumblr', message)
        this.setSharingMessage('Instagram', message)
        this.$store.commit(gmb.SET_SHARING_MESSAGE, message)
      }

      // let hashtags = []
      // if (options.hashtags_text) {
      //   hashtags.concat(options.hashtags_text)
      // }
      // if (options.twitter_hashtags) {
      //   options.twitter_hashtags.forEach(item => {
      //     hashtags.push(`#${item}`)
      //   })
      // }

      // console.log('processPost hashtags > ', hashtags)
      // if (hashtags.length > 0) {
      //   let stateObject = this
      //   setTimeout(function () {
      //     stateObject.fetchHashTagsVelocity(hashtags)
      //   }, 1000)
      // }
    },

    processRetweetShare () {
      console.debug('Method:twitterRetweetSubmission')

      const accountSelection = this.getAccountSelection

      if (accountSelection.twitter.length === 0) {
        this.alertMessage('Please select account to retweet.', 'error')
        return false
      }

      const payload = {
        account_selection: {
          twitter: accountSelection.twitter
        },
        retweet_selection: this.getTwitterRetweetSelection,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }

      this.setProcessRetweetShareLoader(true)
      this.postRequest(processRetweetShareUrl, payload, response => {
        if (response.data.status === true) {
          this.alertMessage(response.data.message, 'success')
        } else {
          this.alertMessage(response.data.message, 'error')
        }

        this.setProcessRetweetShareLoader(false)
        this.$bvModal.hide('retweet_share_modal')
        // $('#retweet_share_modal').modal('hide')
      }, error => {
        console.debug('Exception in processRetweetShare ', error)``
        this.setProcessRetweetShareLoader(false)
        this.$bvModal.hide('retweet_share_modal')
        // $('#retweet_share_modal').modal('hide')
      })
    },

    fetchHashTagsVelocity (hashtags) {
      console.debug('Method:fetchHashTagsVelocity')
      this.setFetchHashTagsVelocityLoader(true)
      this.postRequest(fetchHashTagsVelocityUrl, { tags: hashtags }, response => {
        if (response.data.status === true) {
          this.setSharingTagsReplaceStatus(true)
          this.setSocialSharingTagsSuggestionsItems(response.data.tags)
        } else {
          const tags = []
          $(hashtags).each(function (index, el) {
            tags.push({
              'tag': el
            })
          })
          this.setSharingTagsReplaceStatus(true)
          this.setSocialSharingTagsSuggestionsItems(tags)
        }
        this.setFetchHashTagsVelocityLoader(false)
      }, error => {
        const tags = []
        $(hashtags).each(function (index, el) {
          tags.push({
            'tag': el
          })
        })
        this.setSharingTagsReplaceStatus(true)
        this.setSocialSharingTagsSuggestionsItems(tags)
        console.debug('Exception in fetchHashTagsVelocity', error)
        this.setFetchHashTagsVelocityLoader(false)
      })
    },
    fetchPostHashTags (payload) {
      console.debug('Method:fetchPostHashTags')
      this.setFetchHashTagsVelocityLoader(true)
      this.setSocialSharingTagsSuggestionsItems([])
      // this.setSharingTagsReplaceStatus(false)
      this.postRequest(fetchPostHashTagsUrl, payload, response => {
        if (response.data.status === true) {
          // this.setSharingTagsReplaceStatus(true)
          this.setSocialSharingTagsSuggestionsItems(response.data.tags)
        }
        this.setFetchHashTagsVelocityLoader(false)
      }, error => {
        console.debug('Exception in fetchPostHashTags', error)
        this.setFetchHashTagsVelocityLoader(false)
      })
    },

    triggerSocialPublicationInterval () {
      console.debug('method : triggerSocialPublicationInterval')

      const stateObject = this
      return window.setInterval(function () {
        console.debug('method : triggerSocialPublicationIntervalCalling')
        stateObject.socialAutoPostSave()
      }, 45000)
    },

    socialAutoPostSave () {
      const stateObject = this
      if (stateObject.getBlogPostingDetails.type === 'Composer Social' && stateObject.getPublishSelection.plan_id &&
          stateObject.getPublishSelection.status === 'draft' && !stateObject.getPublishLoaders.processSocialShare &&
          !stateObject.getPublishLoaders.processSavePublication && !stateObject.getPublishSelection.planApprovalData) {
        // if (!stateObject.checkSocialContentNotExist()) {
        stateObject.processSocialShare(true)
        // }
      }
    },

    checkSocialContentNotExist () {
      if (this.getAccountSelection.facebook.length === 0 && this.getAccountSelection.twitter.length === 0 && this.getAccountSelection.tumblr.length === 0 &&
          this.getAccountSelection.linkedin.length === 0 && this.getAccountSelection.pinterest.length === 0 && this.getAccountSelection.instagram.length === 0 &&
          this.getAccountSelection.gmb.length === 0 && this.getAccountSelection.youtube.length === 0) {
        return true
      }
      return false
    },

    // social share post methods

    processSocialShare (draft = false, autoSaveRequest = false, redirect = false, taskCommentSave = '', approval = false) {
      console.debug('Method:processSocialShare', draft, autoSaveRequest)
      this.$store.commit(composer.SET_PUBLICATION_LAST_UPDATED, '')

      // check validations if the post is not of draft.

      if ((!draft || autoSaveRequest) && !this.socialAccountSelectionValidation('SocialShare')) return false

      // user clicked on save as draft and validation is false.

      if (autoSaveRequest && !this.socialShareDraftValidation()) return false

      // post is not draft and user has selected the other status such as content category etc.
      if (!draft && !this.getPublishSelection.queueStatus && !this.publishTimeOptionsValidation('SocialShare')) return false

      if (!draft && !this.socialShareDetailsValidation('SocialShare')) {
        this.$store.commit(commonTypes.SET_COMMON_BOX_MESSAGE_STATUS, true)
        return false
      }

      // show the loader when the post being saved as draft while adding the task and comments.

      if (taskCommentSave === 'comment') {
        this.SET_CREATE_COMMENT_LOADER(true)
      } else if (taskCommentSave === 'task') {
        this.SET_CREATE_TASK_LOADER(true)
      }

      const payload = this.processSocialSharePayload(draft)
      if (approval) payload.approval = this.getApprovalStages

      // when someone edit pending post and remove needs approval than that data will save in post
      else if (this.getPublishSelection.planApprovalData) payload.approval = this.getPublishSelection.planApprovalData

      // in some cases publishing_time_options status is approved while approval status is pending
      // that occuring some problems so we change plan_status in that cases
      if (approval && (payload.approval && payload.approval.status === 'pending_approval') &&
          (payload.publish_time_options && payload.publish_time_options.plan_status === 'Approved')) {
        payload.publish_time_options.plan_status = 'Under Review'
      }

      if (payload.status !== 'draft' || payload.publish_time_options.plan_status === 'Under Review' || this.getTeamMembership === 'client') {
        payload.publish_time_options.hide_client = false
      }
      this.postRequest(processSocialShareUrl, payload, response => {
        this.processSocialShareResponse(draft, redirect, response, taskCommentSave, approval, autoSaveRequest)
      }, error => {
        this.processSocialShareFailResponse(error)
      })
    },

    socialSharePayload () {
      return {
        account_selection: this.getAccountSelection,
        common_box_status: (this.getCommonBoxOptions.status === undefined) ? true : this.getCommonBoxOptions.status,
        common_sharing_details: this.getCommonSharingDetails,
        facebook_sharing_details: this.getFacebookSharingDetails,
        twitter_sharing_details: this.getTwitterSharingDetails,
        linkedin_sharing_details: this.getLinkedinSharingDetails,
        pinterest_sharing_details: this.getPinterestSharingDetails,
        tumblr_sharing_details: this.getTumblrSharingDetails,
        instagram_sharing_details: this.getInstagramSharingDetails,
        youtube_sharing_details: this.getYoutubeSharingDetails,
        tiktok_sharing_details: this.getTiktokSharingDetails,
        gmb_sharing_details: this.getGmbSharingDetails,
        gmb_options: this.getGmbOptions,
        youtube_options: this.getYoutubeOptions,
        twitter_options: this.getTwitterOptions,
        pinterest_options: this.getPinterestOptions,
        facebook_options: this.getFacebookOptions,
        carousel_options:this.getCarouselOptions,
        labels: this.getPublishSelection.label,
        members: this.getPublishSelection.members,
        publish_time_options: this.getPublishTimeOptions,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        queue: this.getPublishSelection.queueStatus,
        reopen: this.getPublishSelection.reopen,
        csv_post_id: this.getPublishSelection.csvPlanId,
        content_category_id: this.getPublishSelection.content_category_id,
        folderId: this.getPublishSelection.folderId,
        publish_source: (this.getPublishSelection.publish_source) ? this.getPublishSelection.publish_source : null,
        instagram_posting_option: this.getInstagramPostingOption,
        sharing_media_details: this.getSocialSharingMediaDetails,
        has_first_comment: this.getSocialSharingFirstComment.has_first_comment,
        first_comment_message: this.getSocialSharingFirstComment.has_first_comment ? this.getSocialSharingFirstComment.first_comment_message : null,
        first_comment_location: this.getSocialSharingFirstComment.first_comment_location ? this.getSocialSharingFirstComment.first_comment_location : null,
        first_comment_accounts: this.getSocialSharingFirstComment.first_comment_accounts ? this.getSocialSharingFirstComment.first_comment_accounts : [],
        // is_approver: this.getUserRole === 'Approver' ? true :false
      }
    },

    processSocialSharePayload (draft) {
      const payload = this.socialSharePayload()
      if (this.getBlogPostingDetails.type === 'Composer Social') {
        payload.folderId = this.getPublishSelection.folderId
        payload.type = this.getBlogPostingDetails.type
      }

      payload.planId = this.getPublishSelection.plan_id
      payload.status = null
      payload.visible = true
      if(this.getUserRole === 'Approver') {
        if(!payload.status) {
          payload.status = this.getPublishSelection.status;
        }
      } else {
        if (draft) {
          payload.status = 'draft'
          if (this.checkSocialContentNotExist()) payload.visible = false // don't break line
          this.setProcessSavePublicationLoader(true)
        } else {
          if (this.getPublishSelection.status === 'draft') payload.status = 'scheduled' // don't break line
          this.setProcessSocialShareLoader(true)
        }
      }

      // in case of discovery social post draft
      if (payload.status === 'draft') {
        this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, 'Composer Social')
        payload.type = 'Composer Social'
      }

      return payload
    },

    async processSocialShareResponse(draft, redirect, response, taskCommentSave, approval, autoSaveRequest = false) {
      if (response.data.status === true) {


        // highlight the post so that user know it is being shared already.
        if (this.getPublishSelection.publish_source) {
          EventBus.$emit('publish-source', this.getPublishSelection.publish_source)
        }

        if (draft) {
          if (autoSaveRequest && !approval) this.alertMessage('Your post has been saved.', 'success')
          this.socialDraftResponse(response, taskCommentSave, redirect, approval)
        } else {
          if (!this.getActiveWorkspace.onboarding_steps.create_first_post.status) {
            await this.onboardingStepsCompleted('create_first_post')
          }

          this.trackUserMaven('social_post_created')

          this.socialNormalResponse(response)
        }
      } else {
        this.alertMessage(response.data.message, 'error')
      }

      this.$store.commit(commonTypes.SET_COMMON_BOX_MESSAGE_STATUS, false)
      this.setProcessSocialShareLoader(false)
      this.setProcessSavePublicationLoader(false)
      if (taskCommentSave === 'comment') {
        // this.SET_CREATE_COMMENT_LOADER(false)
      } else if (taskCommentSave === 'task') {
        this.SET_CREATE_TASK_LOADER(false)
      }

      // for the feeder view.
      if (this.$route.query.module && this.$route.query.module === 'feeds') {
        this.$router.go(-1)
      }
    },

    socialDraftResponse (response, taskCommentSave, redirect, approval) {
      if (response.data.has_text) this.$store.commit(composer.SET_PUBLICATION_LAST_UPDATED, response.data.last_updated) // don't break line
      this.setPublishPlanId(response.data.id)

      // this is the case when user duplicate a post from composer
      if (this.$router.currentRoute.name === 'composerSocial' && this.$route.params.id !== response.data.id) {
        this.setPublicationStatus('draft')
        this.$router.push({ name: 'composerSocial', params: { 'id': response.data.id } })
      }

      this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
      if (this.$route.name === 'list_plans' || this.$route.name === 'calender_plans' || this.$route.name === 'feed_view') this.$store.dispatch('refetchPlans', this.$route)

      // confirmation box close for discovery post
      if (this.getDraftCancelAction) {
        this.setDraftCancelAction(false)
        this.$bvModal.hide('socialConfirmationDialog')
        this.$bvModal.hide('social_share_modal')
      }

      // discovery task /comment save request after save in case of discovery post
      if (taskCommentSave === 'task') {
        this.saveTask(null)
      } else if (taskCommentSave === 'comment') {
        this.saveComment()
      } else if (taskCommentSave === 'label') {
        // do nothing
      } else {
        if (this.getPublishSelection.csvPlanId) this.refetchCSVPlans()
        this.$bvModal.hide('social_share_modal')
      }

      if (redirect) this.$router.push({ name: 'composerSocial', params: { 'id': response.data.id } })

      if (approval) {
        this.alertMessage(response.data.message, 'success')
        this.$router.push({ name: 'composer' })
      }
    },

    socialNormalResponse (response) {
      this.alertMessage(response.data.message, 'success')
      if (this.$router.currentRoute.name === 'composerSocial') this.$router.push({ name: 'composer' }) // don't break line
      this.setDraftCancelAction(true)
      if (this.getPublishSelection.csvPlanId) this.refetchCSVPlans()
      if (this.$route.name === 'list_plans' || this.$route.name === 'calender_plans' || this.$route.name === 'feed_view') this.$store.dispatch('refetchPlans', this.$route)
      this.$bvModal.hide('socialApprovalModal')
      this.$bvModal.hide('social_share_modal')
    },

    processSocialShareFailResponse (error) {
      console.debug('Exception in processSocialShare ', error)
      this.alertMessage(UNKNOWN_ERROR, 'error')
      this.setProcessSocialShareLoader(false)
      this.setProcessSavePublicationLoader(false)
      this.SET_CREATE_COMMENT_LOADER(false)
      this.SET_CREATE_TASK_LOADER(false)
    },

    sharingBoxPendingProcessStatus (type, threadedTweetIndex = 0) {
      if (type === 'Common' && (this.getPublishLoaders.uploadCommonSharingImage.status || this.getPublishLoaders.upload_common_sharing_video)) {
        return true
      } else if (type === 'Facebook' && (this.getPublishLoaders.uploadFacebookSharingImage.status || this.getPublishLoaders.upload_facebook_sharing_video)) {
        return true
      } else if (type === 'Twitter' && (this.getPublishLoaders.uploadTwitterSharingImage.status || this.getPublishLoaders.upload_twitter_sharing_video)) {
        return true
      } else if (type === 'ThreadedTweets' && (this.getThreadedTweetsSharingDetails[threadedTweetIndex].uploadThreadedTweetsSharingImage.status || this.getThreadedTweetsSharingDetails[threadedTweetIndex].upload_thread_tweets_sharing_video)) {
        return true
      } else if (type === 'Linkedin' && (this.getLinkedinSharingLoaders.upload_sharing_image.status || this.getPublishLoaders.upload_linkedin_sharing_video)) {
        return true
      } else if (type === 'Pinterest' && (this.getPublishLoaders.uploadPinterestSharingImage || this.getPublishLoaders.upload_pinterest_sharing_video)) {
        return true
      } else if (type === 'Tumblr' && (this.getPublishLoaders.uploadTumblrSharingImage || this.getPublishLoaders.upload_tumblr_sharing_video)) {
        return true
      } else if (type === 'Instagram' && (this.getInstagramSharingLoaders.upload_sharing_image.status || this.getPublishLoaders.upload_instagram_sharing_video)) {
        return true
      } else if (type === 'Youtube' && (this.getYoutubeSharingLoaders.upload_sharing_image.status || this.getPublishLoaders.upload_youtube_sharing_video)) {
        return true
      } else if (type === 'Gmb' && (this.getGmbSharingLoaders.upload_sharing_image || this.getGmbSharingLoaders.upload_sharing_video)) {
        return true
      }

      return false
    },

    async processContentCategoryPost () {
      console.debug('Method:processContentCategoryPost')
      if (!this.socialShareDetailsValidation('SocialShare', true)) {
        this.$store.commit(commonTypes.SET_COMMON_BOX_MESSAGE_STATUS, true)
        return false
      }
      const payload = this.processSocialSharePayload()
      payload.status = 'scheduled'
      delete (payload.account_selection)
      const res = await proxy.post(processSocialContentCategoryPostURL, payload).then(async res => {

        if (!this.getActiveWorkspace.onboarding_steps.create_first_post.status) {
          await this.onboardingStepsCompleted('create_first_post')
        }

        return res
      }).catch(err => {
        return null
      })

      // after the response being retrieved...

      if (res && res.data.status) {
        this.$store.dispatch('toastNotification', { message: res.data.message })
        if (this.$router.currentRoute.name === 'composerSocial') {
          this.$router.push({ name: 'composer' })
        } else {
          // highlight the post so that user know it is being shared already.
          if (this.getPublishSelection.publish_source) {
            EventBus.$emit('publish-source', this.getPublishSelection.publish_source)
          }
          // hide the draft cancel action modal
          this.setDraftCancelAction(true)
          this.$bvModal.hide('socialConfirmationDialog')
          this.$bvModal.hide('social_share_modal')
        }
        this.$store.commit(publishLoaders.SET_PROCESS_SOCIAL_SHARE_LOADER, false)
      }
    },

    hideFromClientAction (item, status) {
      this.postRequest(processHideFromclient, { id: item._id, status, workspace_id: this.getWorkspaces.activeWorkspace._id }, response => {
        if (response.data.status === true) {
          item.publish_time_options = response.data.publish_time_options
        } else {
          this.alertMessage('Unable to perform action. Please try again.', 'error')
        }
      })
    },
    setSecificBoxLocation (location, type) {
      switch (type) {
        case 'facebook':
          if (!this.getFacebookSharingDetails.location.id) { this.$store.commit(facebookSharingTypes.SET_LOCATION, location) }
          break
        case 'instagram':
          if (!this.getInstagramSharingDetails.location.id) { this.$store.commit(instagramTypes.SET_LOCATION, location) }
          break
        case 'twitter':
          if (!this.getTwitterSharingDetails.location.id) { this.$store.commit(twitterSharingTypes.SET_LOCATION, location) }
          break
      }
    }

  }

}
