<script setup>
import { computed, watch } from 'vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'

const props = defineProps({
  label: {
    type: String,
    default: 'Engagements',
  },
})

const {
  barChartOptions,
  legendOptions,
  channelEngagementsData,
  isReportView,
  dataZoomOptions,
  analyticsDesignSystem,
  SINGLE_GRAPH_TOOLTIPS,
  axisLabelFormatter,
} = useYoutubeAnalytics(props.label)

const chartTypes = [
  {
    name: 'Likes',
    api_response_key: {
      daily: 'like_daily',
      total: 'like_total',
    },
    color: '#61BAE4',
  },
  {
    name: 'Dislikes',
    api_response_key: {
      daily: 'dislike_daily',
      total: 'dislike_total',
    },
    color: '#F67676',
  },
  {
    name: 'Comments',
    api_response_key: {
      daily: 'comment_daily',
      total: 'comment_total',
    },
    color: '#8AC1A2',
  },

  {
    name: 'Shares',
    api_response_key: {
      daily: 'share_daily',
      total: 'share_total',
    },
    color: '#F2CA6B',
  },
]

const noDataFound = computed(() => {
  return !channelEngagementsData.value?.bucket?.length
})

watch(
  () => channelEngagementsData,
  async () => {
    barChartOptions.value.series = []

    barChartOptions.value.legend = {
      ...legendOptions,
      data: chartTypes?.map((chartType) => chartType.name),
    }
    const xAxisData = channelEngagementsData.value?.bucket

    // bar and line chart options
    chartTypes.forEach((chartType) => {
      const apiKey = chartType?.api_response_key

      const seriesStack = {
        stack: 'total',
      }

      const barSeries = {
        name: chartType.name,
        type: 'bar',
        color: chartType.color,
        data: channelEngagementsData.value[apiKey.daily] || [],
        yAxisIndex: 0,
        barMaxWidth: analyticsDesignSystem?.graphs?.singleBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
      }

      barChartOptions.value.series.push({ ...seriesStack, ...barSeries })
      barChartOptions.value.yAxis = {
        ...barChartOptions.value.yAxis,
        name: '{likes|} {dislikes|} {comments|} {shares|} {b|Engagements}',
        nameGap: 50,
        nameTextStyle: {
          rich: {
            likes: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#61BAE4',
            },
            dislikes: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#F67676',
            },
            comments: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#8AC1A2',
            },
            shares: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#F2CA6B',
            },
            b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
          },
        },
        axisLabel: {
          ...barChartOptions.value.yAxis.axisLabel,
          formatter: axisLabelFormatter,
          rich: analyticsDesignSystem?.graphs?.richStylesBySentiment,
        },
      }
    })

    barChartOptions.value.tooltip.formatter = tooltipFormatter
    barChartOptions.value.xAxis.data = xAxisData
  },
  {
    deep: true,
    immediate: true,
  }
)

const tooltipFormatter = (series) => {
  const { axisValue: axisDate } = series[0]
  const date = new Date(axisDate)
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()
  let totalEngagement = 0
  let html = ''

  series.forEach((series) => {
    const { seriesName, data: value } = series
    totalEngagement += value

    html += `
    <div class="flex items-end justify-between mt-2.5">
      <span class="text-[979CA0] text-sm mr-1.5">${seriesName}: </span>
      <span class="text-[#69788E] text-sm font-bold">${value}</span>
    </div>`
  })

  return `
      <div class="flex flex-col">
        <div class="flex items-center">
          <span class="text-[#000D21] font-weight-300">${month} ${date.getDate()}, ${year}</span>
        </div>
        <div class="flex items-end justify-between mt-2.5">
          <span class="text-[979CA0] text-sm mr-1.5">Engagements:  </span>
          <span class="text-[#69788E] text-sm font-bold">${totalEngagement}</span>
        </div>
          ${html}
      </div>
  `
}
</script>

<template>
  <AnalyticsCardWrapper
    :type="`specific-type-${Math.random()}`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <h2 class="text-base font-weight-500 select-none">
            Daily Engagement Activity
          </h2>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                {{ SINGLE_GRAPH_TOOLTIPS.ENGAGEMENT }}
              </p>
            </template>
          </v-menu>
        </div>
        <div>
          <slot name="header-center" />
        </div>
        <div>
          <slot name="header-right" />
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div v-if="noDataFound" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p>No data found.</p>
      </div>
      <div v-else class="flex-1">
        <MainGraph
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...barChartOptions,
                  ...dataZoomOptions,
                  grid: { ...barChartOptions?.grid, bottom: 70 },
                }
              : barChartOptions
          "
          :is-modal="slotProps.isModal"
        />
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
