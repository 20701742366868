<template>
  <div class="flex">
    <div class="flex-1">
      <div
          ref="chartRef"
          :style="{ height: chartHeight, width: chartWidth }"
      ></div>
    </div>
  </div>
</template>

<script>
// libraries
import {
  computed,
  defineComponent,
  watch,
} from 'vue'

// composable
import useEcharts from "@src/modules/analytics_v3/composables/useEcharts";

export default defineComponent({
  name: 'PulseCompetitorGrowth',
  props: {
    chartHeight: {
      type: [Number, String],
      default: '45px',
    },
    chartWidth: {
      type: [Number, String],
      default: '10rem',
    },
    data: {
      type: Object,
      default: () => {
      },
    },
    competitorIndex: {
      type: Number,
      default: 0,
    },
    chartTypes: {
      type: Array,
      default: () => ['line']
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    platformName: {
      type: String,
      default: ''
    }
  },
  setup(props) {

    /**
     * tooltip for pulse graph
     */
    const tooltipFormatter = (params) => {
      const {data} = params[0]
      const followersCount = parseInt(data[1])
      const date = new Date(data[0])
      const month = date.toLocaleString('default', {month: 'short'})
      const year = date.getFullYear()

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <span class="text-[#000D21] font-weight-300">${month} ${date.getDate()}, ${year}</span>
          </div>
            <div class="flex items-end justify-between mt-2.5">
              <span class="text-[979CA0] text-sm mr-1.5">Followers: </span>
              <span class="text-[#69788E] text-sm font-bold">${followersCount.toLocaleString()}</span>
            </div>
            <p class="text-xs text-[#979CA0] border border-t border-red-100 !border-l-0 !border-r-0 !border-b-0 mt-2 pt-2">Click to have a full view of growth chart.</p>
        </div>
        `
    }

    const chartOptions = computed(() => ({
      tooltip: {
        borderColor: props.data?.color,
        textStyle: {
          color: '#595c5f',
        },
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        formatter: tooltipFormatter,
      },
      grid: {
        top: '10%',
        left: '5%',
        right: '5%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          boundaryGap: '0%',
        },
      ],
      yAxis: [
        {
          min: 'dataMin',
          type: 'value',
          splitLine: true,
          alignTicks: true,
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
            splitNumber: 4,
          },
        },
      ],
      series: {
        name: props.data?.name,
        type: 'line',
        areaStyle: {
          opacity: 0.3,
        },
        lineStyle: {
          width: 2,
        },
        showSymbol: false,
        emphasis: {
          focus: 'series',
        },
        color: props.data?.color,
        animation: true,
        data: props.data ? props.data[props.platformName === 'facebook' ? 'dates_with_followers_count' : 'dates_with_followed_by_count'] : [],
      },
    }))

    // setting up the eChart
    const {setup, chartRef} = useEcharts(
        chartOptions.value,
        props.chartTypes,
    )

    watch(
        () => chartRef.value,
        () => {
          setup(chartOptions.value)
        }
    )

    return {
      chartRef,
      chartOptions,
    }
  },
})
</script>
