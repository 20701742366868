import { shallowRef, ref, computed, defineAsyncComponent } from 'vue'
import ClipLoader from '@src/modules/common/components/spinner/ClipLoader'
import { useRoute } from 'vue-router'
import proxy from '@common/lib/http-common'
import { platformPosting, allPlanHeadAttachments } from '@common/lib/planner'
import { getPlatformIdentifierValue } from '@common/lib/integrations'
import {
  SOCIAL_PLATFORMS_MEDIA_ENDPOINT,
  reschedulePlanURL,
  swapPlanDatesUrl,
} from '@src/modules/planner/config/api-utils'
import { useStore } from '@state/base'
import useSocialGridView from '@src/modules/planner_v2/composables/useSocialGridView'
import publishedIcon from '@assets/img/icons/statuses/published_new.svg'
import failedIcon from '@assets/img/icons/statuses/failed.svg'
import draftIcon from '@assets/img/icons/statuses/draft.svg'
import rejectedIcon from '@assets/img/icons/statuses/rejected.svg'
import underReviewIcon from '@assets/img/icons/statuses/under_review.svg'
import missedReviewIcon from '@assets/img/icons/statuses/missed_review_gray.svg'
import inProgressIcon from '@assets/img/icons/statuses/in_progress_light.svg'
import scheduledIcon from '@assets/img/icons/statuses/scheduled.svg'
import useSocialMediaUtils from '@/src/modules/common/composables/useSocialMediaUtils'
import {
  SUPPORTED_PLATFORMS,
  INSTAGRAM_TAB_NAMES,
} from '@/src/modules/planner_v2/constants/GridView'
import {
  PLAN_STATUS,
  POST_STATE,
} from '@/src/modules/common/constants/composer'
import useTime from '@/src/modules/common/composables/useTime'

const FeedsComponent = shallowRef(
  defineAsyncComponent({
    loader: () =>
      import(
        '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/FeedsComponent.vue'
      ),
    loadingComponent: ClipLoader,
  })
)

const FeedsSidebarComponent = shallowRef(
  defineAsyncComponent({
    loader: () =>
      import(
        '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/FeedsSidebarComponent.vue'
      ),
    loadingComponent: ClipLoader,
  })
)

const {
  selectedPost,
  selectedAccount,
  fetchingMoreItems,
  selectedTab,
  noStatusSelected,
  fetching,
  tabs,
} = useSocialGridView()

const DEFAULT_SELECTED_TAB = {
  name: INSTAGRAM_TAB_NAMES.FEED,
  components: {
    main: FeedsComponent,
    sidebar: FeedsSidebarComponent,
  },
}
const REMOTE_MEDIA_TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  CAROUSEL_ALBUM: 'CAROUSEL_ALBUM',
}

// composables
const {
  isPlanPublished,
  isPlanFailed,
  isPlanDraft,
  isPlanInProcessing,
  isPlanRejected,
  isPlanInReview,
  isPlanMissedReview,
} = useSocialMediaUtils()
const { getWorkspaceTimeZoneTime } = useTime()

// Global Reactive variables to share state
const lastTimeStampOfRemoteMedia = ref(null)
const remoteMedia = ref([])
const movedTilesOnDrag = ref([])
const isLocked = ref(false)
const filteredItems = ref([])
const showRemoteMedia = ref(false)

// Global reactive variables for drag and drop
let prevIndex = null
const movingItemIndex = ref(null)
const futureItemIndex = ref(null)
const feedItems = ref([])
const reelItems = ref([])
const storyItems = ref([])
selectedTab.value = DEFAULT_SELECTED_TAB

// computed global states
const filteredItemsAndRemoteMedia = computed(() => {
  const postedIdsSet = new Set(
    filteredItems.value.flatMap((item) =>
      item.posting.map((post) => post.posted_id)
    )
  )

  const newRemoteMedia = remoteMedia.value?.filter(
    (item) => !postedIdsSet.has(item.id)
  )

  return sortArrayByDate([...filteredItems.value, ...newRemoteMedia])
})

const hasFilteredItems = computed(() => {
  return (
    !!filteredItems.value.filter((i) => {
      return (
        !isPlanPublished(i) ||
        !isPlanPublishedButDeletedFromInstagram(i) ||
        isPlanFailedOnSelectedAccount(i)
      )
    }).length > 0
  )
})
const hasFilteredItemsWithNoRemoteMedia = computed(() => {
  return !!filtereditemsWithNoRemoteMedia.value?.length > 0
})
const hasFilteredAndRemoteItems = computed(
  () => filteredItemsAndRemoteMedia.value.length > 0
)
const filtereditemsWithNoRemoteMedia = computed(() => {
  const currentItems = getCurrentItems();

  const renderableItemsValue = renderableItems.value || [];

  const uniqueIds = new Set();

  // Filter currentItems and add their _id to the uniqueIds set
  const filteredCurrentItems = currentItems.filter(item => {
    if (!isRemoteMedia(item) && !uniqueIds.has(item._id)) {
      uniqueIds.add(item._id);
      return true;
    }
    return false;
  });

  // Filter renderableItemsValue, add their _id to the uniqueIds set if not already present
  const filteredRenderableItems = renderableItemsValue.filter(item => {
    if (
      isPlanPublished(item) &&
      isPlanPublishedButDeletedFromInstagram(item) &&
      !uniqueIds.has(item._id)
    ) {
      uniqueIds.add(item._id);
      return true;
    }
    return false;
  });

  return [...filteredCurrentItems, ...filteredRenderableItems];
});
const renderableItems = computed(() =>
  showRemoteMedia.value
    ? filteredItemsAndRemoteMedia.value?.filter((i) =>
        selectedTab.value.name === INSTAGRAM_TAB_NAMES.STORY
          ? !isRemoteMedia(i)
          : true
      )
    : filteredItems.value
)

// by category
const hasFilteredFeedItems = computed(() => {
  console.log(filteredFeedItems.value?.length > 0)
  return filteredFeedItems.value?.length > 0})
const filteredFeedItems = computed(() => {
  return renderableItems.value?.filter((item) => {
    return (
      showOnGridView(item) &&
      (isRemoteMedia(item)
        ? item.media_product_type === 'FEED' || item?.is_shared_to_feed
        : true) &&
      (isPlanPublished(item)
        ? !isPlanPublishedButDeletedFromInstagram(item)
        : true)
    )
  })
})

const hasFilteredReelItems = computed(() => filteredReelItems.value.length > 0)
const filteredReelItems = computed(() => {
  return renderableItems.value?.filter((item) => {
    return (
      showOnGridView(item) &&
      (isRemoteMedia(item) ? item.media_product_type === 'REELS' : true) &&
      (isPlanPublished(item)
        ? !isPlanPublishedButDeletedFromInstagram(item)
        : true)
    )
  })
})

const hasFilteredStoryItems = computed(
  () => filteredStoryItems.value.length > 0
)
const filteredStoryItems = computed(() => renderableItems.value)

// Methods

// Define a method to get the items based on the selected tab
const getCurrentItems = () => {
  switch (selectedTab.value?.name) {
    case 'Feed':
      return feedItems.value
    case 'Reels':
      return reelItems.value
    case 'Story':
      return storyItems.value
    default:
      return []
  }
}

// Define a method to set the items based on the selected tab
const setCurrentItems = (newValue) => {
  switch (selectedTab.value?.name) {
    case 'Feed':
      feedItems.value = newValue
      break
    case 'Reels':
      reelItems.value = newValue
      break
    case 'Story':
      storyItems.value = newValue
      break
    default:
      break
  }
}

const formatDate = (date, hours = 0) => {
  const newDate = new Date(date)
  newDate.setHours(newDate.getHours() + hours)
  return newDate
}
const isRemoteMedia = (element) => Object.hasOwn(element, 'id')

const hasError = (e) => Object.hasOwn(e, 'error') && e.error === true

const isViaPushNotification = (e) => {
  return (
    Object.hasOwn(e, 'device_notification') && e.device_notification === true
  )
}
const isPushPostProcessed = (e) => {
  return (
    Object.hasOwn(e, 'notification_processed') &&
    e.notification_processed === true
  )
}
const isPushAndDeclined = (e) => {
  return isViaPushNotification(e) && isPushPostProcessed(e) && hasError(e)
}
const isPushAndAccepted = (e) => {
  return isViaPushNotification(e) && isPushPostProcessed(e) && !hasError(e)
}
const isPushAndNoOptionIsSelected = (e) => {
  return isViaPushNotification(e) && !isPushPostProcessed(e)
}

const showOnGridView = (i) => {
  if (isRemoteMedia(i)) return true

  if (i.posting_details === undefined) return true

  return (
    !isPushAndDeclined(i.posting_details) &&
    !isPushAndAccepted(i.posting_details) &&
    !isPushAndNoOptionIsSelected(i.posting_details)
  )
}

const isApproved = (element) => element?.status === PLAN_STATUS.APPROVED
const isPast = (date, otherDate = null) => {
  const formattedDate = formatDate(date)

  if (!otherDate) return formattedDate < new Date()

  const formattedOtherDate = formatDate(otherDate)
  return formattedDate < formattedOtherDate
}
const isRemoteCarousal = (element) => {
  return element?.media_type === REMOTE_MEDIA_TYPES.CAROUSEL_ALBUM
}

const isVideo = (element, isTiktok = false) => {
  return element?.thumbnail_url || element?.common_box_status
    ? element?.common_sharing_details?.video?.thumbnail
    : isTiktok
    ? element?.tiktok_sharing_details?.video?.thumbnail
    : element?.instagram_sharing_details?.video?.thumbnail
}

const mediaType = (item) => {
  // Now we will be returning media type carousel in case of instagram carousel
  if (item?.instagram_post_type === 'carousel') {
    return 'Carousel'
  }

  if (!isVideo(item)) {
    return 'Image'
  }

  return 'Video'
}

const shouldDisplayIcon = (plan) => {
  return !isRemoteMedia(plan)
}

const isPlanCarousel = (plan) => {
  return !isRemoteMedia(plan) && allPlanHeadAttachments(plan).length > 1
}
const isTiktokCarousel = (plan) => {
  return !isRemoteMedia(plan) && plan?.tiktok_options?.post_type === 'carousel'
}
const isPlanFailedOnSelectedAccount = (post, isTiktok = false) => {
  if (!post?.posting) return false

  const postingDetailsForSelectedAccount = platformPosting(
    post,
    isTiktok ? 'tiktok' : 'instagram',
    getPlatformIdentifierValue(
      selectedAccount.value,
      isTiktok ? 'tiktok' : 'instagram'
    )
  )

  return (
    postingDetailsForSelectedAccount &&
    Object.hasOwn(postingDetailsForSelectedAccount, 'error') &&
    postingDetailsForSelectedAccount.error
  )
}

const isRemoteVideo = (element) =>
  element?.media_type === REMOTE_MEDIA_TYPES.VIDEO

const sortArrayByDate = (array) => {
  // Sort array by execution_time or timestamp
  return array.sort((a, b) => {
    const dateA = a.execution_time
      ? formatDate(getWorkspaceTimeZoneTime(a.execution_time.date))
      : new Date(a.timestamp)
    const dateB = b.execution_time
      ? formatDate(getWorkspaceTimeZoneTime(b.execution_time.date))
      : new Date(b.timestamp)

    return dateB - dateA
  })
}

const getPostingDetailsOnSelectedAccount = (plan) =>
  plan?.posting?.find(
    (i) => i.platform_id === selectedAccount.value?.instagram_id
  )

const isPlanPublishedButDeletedFromInstagram = (plan) => {
  if (
    lastTimeStampOfRemoteMedia.value &&
    remoteMedia.value.length > 99 &&
    isPast(
      getWorkspaceTimeZoneTime(plan.execution_time.date),
      lastTimeStampOfRemoteMedia.value
    )
  ) {
    return false
  }

  if (!plan.posting_details) {
    return false
  }

  return (
    remoteMedia.value?.find((i) => i.id === plan.posting_details?.posted_id) ===
    undefined
  )
}

const getThumbnail = (element, isTiktok = false) => {
  let media = null

  if (isRemoteMedia(element)) {
    if (isRemoteVideo(element)) {
      return element?.thumbnail_url
    }

    return element.media_url
  }

  media = element?.common_box_status
    ? element?.common_sharing_details
    : isTiktok
    ? element.tiktok_sharing_details
    : element.instagram_sharing_details

  if (media?.multimedia?.length) {
    return typeof media.multimedia[0] === 'string'
      ? media.multimedia[0]
      : media.multimedia[0]?.thumbnail
  }

  if (!media?.video?.link && media?.image?.length > 0) {
    return media.image[0]
  }

  media = media?.video

  return (
    media?.thumbnail ??
    'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
  )
}

const getOverlayDetails = (element, isTiktok = false) => {
  const details = element.posting_details
  const isPlanFailed = isPlanFailedOnSelectedAccount(element, isTiktok)
  const isPushAndDeclinedPlan = details && isPushAndDeclined(details)
  const isPushAndAcceptedPlan = details && isPushAndAccepted(details)
  const isPushAndNoOptionIsSelectedPlan =
    details && isPushAndNoOptionIsSelected(details)

  switch (true) {
    case isRemoteMedia(element):
      return {
        isRemoteMedia: true,
        show: true,
        element: true,
      }
    case element.post_state === POST_STATE.QUEUED ||
      element.post_state === POST_STATE.PROCESSING:
      return {
        color: 'bg-[#FFFFFFB2] !text-secondary-cs',
        badge: {
          color: {
            bg: '#EBECFF',
            text: '#9299F8',
          },
        },
        show: true,
        element: true,
        isRemoteMedia: false,
        message: 'In Progress',
      }
    case element.post_state === POST_STATE.DRAFT:
      return {
        badge: {
          color: {
            bg: '#F3F3F3',
            text: '#76797C',
          },
        },
        show: false,
        element: true,
        isRemoteMedia: false,
        message: 'Draft',
      }
    case element.post_state === POST_STATE.SCHEDULED:
      return {
        color: 'bg-[#ffc55566]',
        badge: {
          color: {
            bg: '#FFF8EA',
            text: '#F0BB52',
          },
        },
        show: false,
        element: true,
        isRemoteMedia: false,
        message: 'Scheduled',
      }
    case element.post_state === POST_STATE.UNDER_REVIEW:
      return {
        color: 'bg-[#ffc55566]',
        badge: {
          color: {
            bg: '#E5F4FF',
            text: '#5FB6F9',
          },
        },
        show: true,
        element: true,
        isRemoteMedia: false,
        message: 'Pending Approval',
      }
    case element.post_state === POST_STATE.REVIEWED:
      if (element?.approval?.status === 'pending_approval') {
        return {
          badge: {
            color: {
              bg: '#E5F4FF',
              text: '#5FB6F9',
            },
          },
          show: false,
          element: true,
          isRemoteMedia: false,
          message: 'In Review',
        }
      }
      return 'In Review'
    case element.post_state === POST_STATE.MISSED_REVIEW:
      return {
        color: 'bg-[#5A6B8CCC]',
        badge: {
          color: {
            bg: '#EDF3FF',
            text: '#081F4B',
          },
        },
        show: true,
        element: true,
        isRemoteMedia: false,
        message: 'Missed Review',
      }
    case element.post_state === POST_STATE.FAILED || isPlanFailed:
      return {
        color: 'bg-[#FF000033]',
        badge: {
          color: {
            bg: '#FFF1F0',
            text: '#EB554D',
          },
        },
        show: true,
        element: true,
        isRemoteMedia: false,
        message: 'Failed',
      }
    case element.post_state === POST_STATE.REJECTED:
      return {
        color: 'bg-[#EB554D80]',
        badge: {
          color: {
            bg: '#FFECF0',
            text: '#EB516B',
          },
        },
        show: true,
        element: true,
        isRemoteMedia: false,
        message: 'Rejected',
      }
    case element.post_state === POST_STATE.PUBLISHED ||
      isPushAndDeclinedPlan ||
      isPushAndAcceptedPlan ||
      isPushAndNoOptionIsSelectedPlan:
      return {
        color: 'bg-[#ff000046]',
        badge: {
          color: {
            bg:
              isPlanFailed &&
              !(
                isPushAndDeclinedPlan ||
                isPushAndAcceptedPlan ||
                isPushAndNoOptionIsSelectedPlan
              )
                ? '#FFF1F0'
                : '#EAFFF1',
            text:
              isPlanFailed &&
              !(
                isPushAndDeclinedPlan ||
                isPushAndAcceptedPlan ||
                isPushAndNoOptionIsSelectedPlan
              )
                ? '#EB554D'
                : '#5EBC7E',
          },
        },
        show: false,
        element: true,
        isRemoteMedia: false,
        message:
          isPlanFailed &&
          !(
            isPushAndDeclinedPlan ||
            isPushAndAcceptedPlan ||
            isPushAndNoOptionIsSelectedPlan
          )
            ? 'Failed'
            : 'Published',
      }
    default:
      return {
        isRemoteMedia: false,
        show: false,
      }
  }
}

const getUpdatedTimeAfterSwap = (element) => {
  // Get execution_time of moved element
  const movedElement = movedTilesOnDrag.value.find((e) => e.id === element._id)

  if (movedElement) {
    return movedElement.execution_time
  }

  return null
}

const onFinishDrag = async (e) => {
  try {
    movedTilesOnDrag.value.forEach(async (item) => {
      await proxy.post(reschedulePlanURL, item)
    })
  } catch (e) {
    //
  } finally {
    isLocked.value = false
  }
}

/**
 * @description Returns status icon based on plan status
 * @param plan
 * @param isTiktok
 * @returns {{}}
 */
const getStatusIcon = (plan, isTiktok = false) => {
  if (isPlanPublished(plan) && !isPlanFailedOnSelectedAccount(plan, isTiktok))
    return publishedIcon
  if (isPlanFailed(plan) || isPlanFailedOnSelectedAccount(plan, isTiktok))
    return failedIcon
  if (isPlanDraft(plan)) return draftIcon
  if (isPlanRejected(plan)) return rejectedIcon
  if (isPlanInReview(plan)) return underReviewIcon
  if (isPlanMissedReview(plan)) return missedReviewIcon
  if (isPlanInProcessing(plan)) return inProgressIcon

  return scheduledIcon
}

/**
 * @description Returns feed status based on plan status
 * @param plan
 * @param isTiktok
 * @returns {string}
 */
const getFeedStatus = (plan, isTiktok = false) => {
  if (isPlanPublished(plan) && !isPlanFailedOnSelectedAccount(plan, isTiktok))
    return 'Published'
  if (isPlanFailed(plan) || isPlanFailedOnSelectedAccount(plan, isTiktok))
    return 'Failed'
  if (isPlanDraft(plan)) return 'Draft'
  if (isPlanRejected(plan)) return 'Rejected'
  if (isPlanInReview(plan)) return 'In Review'
  if (isPlanMissedReview(plan)) return 'Missed Review'
  if (isPlanInProcessing(plan)) return 'In Progress'

  return 'Scheduled'
}

const reset = () => {
  selectedAccount.value = null
  fetching.value = false
  remoteMedia.value = []
  movedTilesOnDrag.value = []
  filteredItemsAndRemoteMedia.value = []
  isLocked.value = false
  selectedTab.value = DEFAULT_SELECTED_TAB
}

export default function useInstagramGridView() {
  //
  const route = useRoute()
  const store = useStore()

  const isGridView = computed(() => route?.meta?.isGridView)
  const currentItems = computed(() => getCurrentItems())

  /**
   * Functionality to handle drag and drop events
   * @param {Event} event - The drag event.
   * @param {number} index - The index of the grid item.
   */

  const handleDragStart = (event, index) => {
    movingItemIndex.value = index
    if (currentItems.value[index]) {
      currentItems.value[index].dragging = true
    }
  }

  /**
   * Handles the move event for a grid item.
   *
   * @param {Event} event - The move event.
   * @param {number} index - The index of the grid item.
   */
  const handleMove = (event, index) => {
    event.preventDefault()
    // If index is out of bounds, return early
    if (index < 0 || index >= currentItems.value?.length) {
      return
    }

    // If there was a previous item being dragged over and the current index is different from the previous index, reset its dropping property
    if (
      prevIndex !== null &&
      prevIndex !== index &&
      currentItems.value[prevIndex]
    ) {
      currentItems.value[prevIndex].dropping = false
    }

    futureItemIndex.value = index

    if (
      movingItemIndex.value !== futureItemIndex.value &&
      futureItemIndex.value !== null &&
      currentItems.value[index]
    ) {
      currentItems.value[index].dropping = true
    }

    // Update prevIndex to the current index
    prevIndex = index
  }

  /**
   * Handles the drag end event for the Tiktok grid view.
   * This function swaps the execution times of two items in the currentItems array,
   * updates the execution times in the API, and displays a toast notification
   * based on the success or failure of the swap operation.
   *
   * @returns {Promise<void>} A promise that resolves when the swap operation is complete.
   */
  const handleDragEnd = async () => {
    // Reset the dragging and dropping properties of the items
    currentItems.value[movingItemIndex.value].dragging = false
    currentItems.value[futureItemIndex.value].dropping = false

    if (movingItemIndex.value === futureItemIndex.value) {
      return
    }
    const isMovingItemDraggable =
      currentItems.value[movingItemIndex.value]?.isDraggable
    const isFutureItemDraggable =
      currentItems.value[futureItemIndex.value]?.isDraggable

    if (!isMovingItemDraggable || !isFutureItemDraggable) {
      return store.dispatch('toastNotification', {
        message: 'Only scheduled, In review and draft posts can be swapped!',
        type: 'error',
      })
    }

    const movingItem = currentItems.value[movingItemIndex.value]
    const futureItem = currentItems.value[futureItemIndex.value]

    // Swap the execution  times of the future and moving items
    const _items = [...currentItems.value]
    _items[futureItemIndex.value] = {
      ...movingItem,
      execution_time: futureItem.execution_time,
    }
    _items[movingItemIndex.value] = {
      ...futureItem,
      execution_time: movingItem.execution_time,
    }

    // Prepare the payload for the API call - id and execution_time for both items
    const payload = {
      workspace_id: store.getters.getActiveWorkspace._id,
      post_ids: [movingItem._id, futureItem._id],
    }

    // Call the swapPosts function and handle the response
    const status = await swapPosts(payload)
    if (status) {
      // Update the items in the grid view
      setCurrentItems(_items)
      return store.dispatch('toastNotification', {
        message: 'Post Swapped successfully',
        type: 'success',
      })
    } else {
      // set the items back to their original positions
      setCurrentItems(currentItems.value)
      return store.dispatch('toastNotification', {
        message: 'Failed to swap posts',
        type: 'error',
      })
    }
  }

  // Function to swap the dates of two posts
  /**
   * Makes a POST request to the swapPlanDatesUrl with the given payload.
   * @param {Object} payload - The payload to be sent in the POST request.
   * @returns {Promise<string|undefined>} - A promise that resolves to the status string if the response is successful, or undefined otherwise.
   */
  const swapPosts = async (payload) => {
    // Make a POST request to the swapPlanDatesUrl with the payload
    try {
      const response = await proxy.post(swapPlanDatesUrl, payload)
      // If the response is successful, return the status
      if (response?.data?.status) {
        return response.data.status
      }
    } catch (error) {
      console.error('An error occurred in Swap method: ', error)
    }
  }

  const fetchMedia = async (options = {}) => {
    try {
      remoteMedia.value = []

      const payload = {
        platform: SUPPORTED_PLATFORMS.INSTAGRAM.route_suffix,
        workspace_id: store.getters.getActiveWorkspace._id,
        platform_id: selectedAccount.value?.instagram_id,
      }

      const { data } = await proxy.post(
        SOCIAL_PLATFORMS_MEDIA_ENDPOINT,
        payload
      )

      remoteMedia.value =
        data?.data?.map((element) => {
          return {
            ...element,
            data_key: Math.random(),
            isDraggable: false,
          }
        }) ?? []

      const lastTimeStamp =
        remoteMedia.value[remoteMedia.value.length - 1]?.timestamp

      if (lastTimeStamp) {
        lastTimeStampOfRemoteMedia.value = formatDate(lastTimeStamp)
      } else {
        lastTimeStampOfRemoteMedia.value = null
      }
    } catch (e) {
      console.error('FETCH MEDIA:::', e)
    }
  }

  // Return properties and methods
  return {
    tabs,
    selectedTab,
    fetching,
    fetchingMoreItems,
    selectedAccount,
    selectedPost,
    lastTimeStampOfRemoteMedia,
    remoteMedia,
    showRemoteMedia,
    noStatusSelected,

    hasFilteredItems,
    hasFilteredItemsWithNoRemoteMedia,
    hasFilteredAndRemoteItems,
    hasFilteredFeedItems,
    hasFilteredReelItems,
    hasFilteredStoryItems,

    filteredItems,
    filteredFeedItems,
    filteredReelItems,
    filteredStoryItems,
    renderableItems,
    filtereditemsWithNoRemoteMedia,
    filteredItemsAndRemoteMedia,
    movedTilesOnDrag,
    DEFAULT_SELECTED_TAB,
    isLocked,

    // swap properties
    feedItems,
    reelItems,
    storyItems,

    getStatusIcon,
    getFeedStatus,
    getUpdatedTimeAfterSwap,
    sortArrayByDate,
    formatDate,

    fetchMedia,
    getOverlayDetails,
    getThumbnail,
    onFinishDrag,
    mediaType,
    shouldDisplayIcon,
    getPostingDetailsOnSelectedAccount,
    reset,

    isPlanPublished,
    isPlanFailed,
    isPlanDraft,
    isPlanInProcessing,
    isPlanRejected,
    isPlanInReview,
    isPlanMissedReview,
    isRemoteCarousal,
    isPlanPublishedButDeletedFromInstagram,
    isPlanFailedOnSelectedAccount,
    isPast,
    isApproved,
    isVideo,
    isRemoteMedia,
    isGridView,
    isPlanCarousel,
    isTiktokCarousel,
    // push notifications methods
    isViaPushNotification,
    hasError,
    isPushAndAccepted,
    isPushAndDeclined,
    isPushAndNoOptionIsSelected,
    isPushPostProcessed,
    showOnGridView,
    // drag and drop methods
    handleDragStart,
    handleMove,
    handleDragEnd,
  }
}
