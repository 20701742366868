<script>
import { mapGetters } from 'vuex'
import orderBy from 'lodash.orderby'
import Router from '@src/router'
import proxy, { getProxyCancelToken } from '@common/lib/http-common'
import {
  createPlannerFilterURL,
  deleteCommentsUrl,
  deletePlannerFiltersURL,
  fetchPlannerFiltersURL,
  fetchPlansCountURL,
  fetchPlansURL,
  saveCommentsUrl,
  setDefaultPlannerFiltersURL,
} from '@src/modules/planner/config/api-utils'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import { SUPPORTED_PLATFORMS } from '@src/modules/common/constants/composer'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import { INSTAGRAM_TAB_NAMES } from '@/src/modules/planner_v2/constants/GridView'

const {
  fetching,
  selectedPost,
  fetchingMoreItems,
  selectedTab,
  noStatusSelected,
} = useInstagramGridView()

export default {
  data() {
    return {
      blogIntegrations: blogIntegrationsNames,
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getActiveWorkspace',
      'getLabels',
      'getActiveWorkspaceMembersName',
      'getActiveWorkspaceMembersIds',
      'getPlatformsList',
    ]),
    isGridView() {
      return (
        !!this.$route.meta?.isGridView || this.$route.name.includes('grid_view')
      )
    },
    membersList() {
      const members = []
      if (
        this.getWorkspaces &&
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.members
      ) {
        console.log('memberSearch', this.getWorkspaces.activeWorkspace)
        this.getWorkspaces.activeWorkspace.members.forEach(
          (workspaceMember, index) => {
            if (workspaceMember.status === 'joined' && workspaceMember.user) {
              members.push(workspaceMember)
            }
          }
        )
      }

      return members
    },
    /**
     * @Deprecated
     */
    /* connectedSocialAccountsList() {
      const blogAccount = this.blogIntegrations
        .map((integration) => {
          const blogAccountDetails =
            this.$store.getters.getBlogs[integration].items
          if (!isEmpty(blogAccountDetails)) {
            console.log(blogAccountDetails)
            return this.$store.getters.getBlogs[integration].items.map(
              (blog) => {
                return {
                  ...blog,
                  integration: integration,
                  account_type: 'blog',
                }
              }
            )
          }
          return []
        })
        .flat()

      const socialAccounts = socialChannelsArray
        .map((channel) => {
          var channelItem = this.$store.getters[channel.getter]
          if (typeof channelItem === 'object' && !Array.isArray(channelItem)) {
            if ('items' in channelItem) {
              return channelItem.items.map((item) => {
                item.channel_type = channel.name // is not reactive
                item.channel_identifier = channel.key
                return item
              })
            }
          } else if (typeof channelItem && Array.isArray(channelItem)) {
            return channelItem.map((innerChannel) => {
              innerChannel.channel_type = channel.name // is not reactive
              innerChannel.channel_identifier = channel.key
              return innerChannel
            })
          }
        })
        .flat()
      console.log('connectedSocialAccountsList', blogAccount)
      console.log('connectedSocialAccountsList', socialAccounts)

      return [...blogAccount, ...socialAccounts]
    }, */
    connectedSocialAccountsList() {
      return this.getPlatformsList
    },
    orderedLabels() {
      return this.getLabels.length > 0
        ? orderBy(this.getLabels, function (item) {
            return [item.name.toLowerCase()]
          })
        : []
    },
    /**
     * Fetches the default campaign folder name that the user has either set.
     * @returns {string|*}
     */
    getDefaultCampaignName() {
      if (
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.default_campaign_name
      ) {
        return this.getWorkspaces.activeWorkspace.default_campaign_name
      }
      return 'Untitled Campaign Folder'
    },
    /**
     * checks if the current user profile is a client profile in this active workspace
     * @returns {boolean}
     */
    clientProfile() {
      const userProfile = this.getWorkspaces?.activeWorkspace?.members?.find(
        (item) => item.user_id === this.getProfile._id
      )
      return userProfile?.membership === 'client'
    },
  },
  methods: {
    getFilteredSocialAccounts(
      accounts,
      routeName,
      platformToFilter = undefined,
      token = 'grid_view'
    ) {
      if (!platformToFilter) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(SUPPORTED_PLATFORMS)) {
          if (routeName.includes(token) && routeName.includes(value)) {
            platformToFilter = value
            break
          }
        }
      }

      if (platformToFilter) {
        return accounts.filter((account) => {
          return account.channel_type === platformToFilter
        })
      }

      return accounts
    },
    onError(event, imageUrl) {
      const fallbackIndex = event.target.dataset.fallback
      const fallbacks = [
        `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`,
      ]

      if (imageUrl) {
        fallbacks.unshift(this.getResizedImageURL(imageUrl, 1080, 0))
      }

      if (fallbackIndex > fallbacks.length - 1) return
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },

    setGridViewRequest(payload) {
      fetching.value = true
        selectedPost.value = null
        payload.grid_view_post_type =
          selectedTab.value.name !== INSTAGRAM_TAB_NAMES.REEL
            ? selectedTab.value.name.toLowerCase()
            : 'reel'

        const gridViewStatuses = ['scheduled', 'published', 'partial', 'failed', 'rejected', 'under_review', 'missed_review', 'draft', 'in_progress']

        if (noStatusSelected.value) {
          payload.statuses = []
        } else {
          if (payload.statuses) {
            payload.statuses = payload.statuses.filter((status) =>
              gridViewStatuses.includes(status)
            )
          }
        }

        // overring filters for grid view
        payload.sort_column = 'status'
        payload.order = 'descending'
    },

    async fetchPlansCall(payload, loaderCallback = () => {}) {
      if (Router?.currentRoute?.value?.meta?.isGridView) {
        this.setGridViewRequest(payload)
      }

      const currentRoute =
        Router.currentRoute.value.name === 'planner_list_v2'
          ? 'list_plans'
          : Router.currentRoute.value.name === 'planner_feed_v2'
          ? 'feed_view'
          : Router.currentRoute.value.name === 'planner_calendar_v2'
          ? 'calender_plans'
          : Router.currentRoute.value.name

      payload.route_name = currentRoute
      loaderCallback(true)

      const res = await proxy.post(
        fetchPlansURL,
        payload,
        getProxyCancelToken(proxy)
      )

      if (Router?.currentRoute?.value?.meta?.isGridView) {
        fetching.value = false
        fetchingMoreItems.value = false
      }

      loaderCallback(false)

      return res
    },

    // Planner save Filters

    async createPlannerFilter(payload) {
      console.debug('Action:createPlannerFilter', payload)

      return proxy
        .post(createPlannerFilterURL, payload)
        .then((response) => {
          if (response.data.status) {
            this.alertMessage('Filter successfully saved.', 'success')

            return response.data.filter
          }
          this.alertMessage(response.data.message, 'error')
          return false
        })
        .catch((response) => {
          console.debug('Exception in createPlannerFilter', response)

          return false
        })
    },

    async fetchPlannerFilters() {
      console.debug('Action:fetchPlannerFilters')

      return proxy
        .post(fetchPlannerFiltersURL, {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          if (response.data.status === true) {
            return response.data.filters
          }

          this.alertMessage(response.data.message, 'error')
          return false
        })
        .catch((response) => {
          console.debug('Exception in fetchPlannerFilters', response)
          return false
        })
    },

    async removePlannerFilter(payload) {
      console.debug('Action:removePlannerFilter')

      return proxy
        .post(deletePlannerFiltersURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            this.alertMessage('Filter successfully removed.', 'success')

            return true
          }
          this.alertMessage(response.data.message, 'error')
          return false
        })
        .catch((response) => {
          console.debug('Exception in removePlannerFilter', response)
          return false
        })
    },

    async setDefaultPlannerFilter(payload) {
      console.debug('Action:setDefaultPlannerFilter')

      return proxy
        .post(setDefaultPlannerFiltersURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            return response.data.filters
          }

          this.alertMessage(response.data.message, 'error')

          return false
        })
        .catch((response) => {
          console.debug('Exception in removePlannerFilter', response)
          return false
        })
    },

    /**
     * http request for adding a comment.
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    async addComment(payload) {
      return await proxy.post(saveCommentsUrl, payload).then((response) => {
        if (response.data.status) {
          return response.data.comment
        }
      })
    },

    /**
     * http request for deleting a comment.
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    async deleteComment(payload) {
      console.log('METHOD::deleteComment')
      return proxy.post(deleteCommentsUrl, payload).then((response) => {
        console.log('deleting comment ~ response -> ', response)
      })
    },

    /**
     * Uses Regex to pluck out the mentioned users in an array
     * @param comment
     * @returns {*[]}
     */
    mentionedUserIdsList(comment) {
      const regex = /@[A-Za-z0-9]*/g
      // var regex = /@[a-zA-Z0-9_.]+?(?![a-zA-Z0-9_.])/g // lookaround technique http://www.rexegg.com/regex-lookarounds.html
      let mentionedUsersIds = []
      const mentionedUsers = comment.match(regex)
      if (mentionedUsers) {
        // filter the name and after that get the value by index.
        mentionedUsers.filter((name, index) => {
          const mentionedId = this.getActiveWorkspaceMembersName.indexOf(
            name.substr(1)
          )
          mentionedUsersIds.push(this.getActiveWorkspaceMembersIds[mentionedId])
        })
        // push the keys to the array
        mentionedUsersIds = Array.from(new Set(mentionedUsersIds))
      }
      return mentionedUsersIds
    },

    async fetchPlansCount(payload) {
      try {
        const response = await proxy.post(
          fetchPlansCountURL,
          payload,
        )
        return response.data.status ? response.data?.plans_count : false
      } catch (exception) {
        console.log('FetchPlanCounts_Exception: ', exception)
      }
    },
  },
}
</script>
