<template>
  <div class="topics-filters-pane__container__right_section">
    <div class="view_change_btn d-flex align-items-center mb-3">
      <div
        class="topic_date_picker d-inline-block align-middle topics-filters-pane__container__right_section__datepicker flex-grow-1"
      >
        <span class="d-inline-block mr-2 topics-datepicker">
          <DateRangePicker
            class="datepicker_input"
            :opens="date_range_pickers.normal.opens"
            :start-date="getFilterFromDate"
            :end-date="getFilterToDate"
            :max-date="getMaxDate"
            :min-date="date_range_pickers.normal.minDate"
            :locale-data="date_range_pickers.normal.locale"
            :single-date-picker="date_range_pickers.normal.singleDatePicker"
            :time-picker="date_range_pickers.normal.timePicker"
            :time-picker24hour="date_range_pickers.normal.timePicker24Hour"
            :show-week-numbers="date_range_pickers.normal.showWeekNumbers"
            :show-dropdowns="date_range_pickers.normal.showDropdowns"
            :auto-apply="date_range_pickers.normal.autoApply"
            :ranges="date_range_pickers.normal.ranges"
            @update="redirectDateView"
          >
            <div
              slot="input"
              slot-scope="picker"
              class="datepicker_content discovery_white ml-2"
            >
              <i class="icon-Calendar"></i>
              <span
                v-if="getSearchDatePicker(picker.startDate, picker.endDate)"
              >
                {{ getSearchDatePicker(picker.startDate, picker.endDate) }}
              </span>
              <span v-else-if="picker.label">
                {{ picker.label }}
              </span>
              <span v-else> Last 30 days </span>
            </div>
          </DateRangePicker>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  components: {
    DateRangePicker
  },
  props: {
    date: {}
  },
  data () {
    return {
      date_range_pickers: {
        normal: {
          locale: {
            format: 'MMMM DD, YYYY HH:mm' // fomart of the dates displayed
          },

          opens: 'left',
          startDate: '2017-09-19',
          endDate: '2017-10-09',
          minDate: '2023-01-01',
          maxDate: this.getMaxDate,
          dateRange: {
            endDate: moment().utc()
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: true,
          showDropdowns: true,
          autoApply: true,
          showWeekNumbers: true,
          checkOpen: false,
          ranges: {
            // 'All Time': [moment.utc().subtract(5, 'years'), moment.utc()],
            '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
            '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
            'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
            'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
            'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
            'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
            'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
            // 'Last 1 Year': [moment.utc().subtract(360, 'days'), moment.utc()],
            // 'Last 2 Years': [moment.utc().subtract(720, 'days'), moment.utc()]
          }
        }
      }
    }
  },
  computed: {
    getMaxDate () {
      return moment.utc().format('YYYY-MM-DD')
    },
    getFilterFromDate () {
      const fromDate = this.date
        ? moment(this.date.split(' - ')[0]).format('YYYY-MM-DD')
        : moment().utc().subtract(90, 'days').format('YYYY-MM-DD')
      return this.$route.query.from_date || fromDate
    },
    getFilterToDate () {
      const toDate = this.date
        ? moment(this.date.split(' - ')[1]).format('YYYY-MM-DD')
        : moment().utc().format('YYYY-MM-DD')
      return this.$route.query.to_date || toDate
    }
  },
  methods: {
    redirectDateView (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: endDate.format('YYYY-MM-DD')
          }
        })
      }
    }
  }
}
</script>
