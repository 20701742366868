<template>
  <div class="review_publish_block">
    <div class="review_publish_inner">
      <div class="head_text">
        <h3>Review &amp; Publish</h3>
        <p>A complete summary of your blog publishing and promotion.</p>
      </div>
      <div v-if="getBlogSelection.platform_name !== ''" class="channel_list">
        <h3 class="c_heading"
          >Your blog post will be first published on this primary channel.</h3
        >
        <div class="c_list">
          <div class="c_item">
            <div
              class="profile_picture"
              :class="getBlogSelection.platform_name"
            >
              <div class="picture_block">
                <div
                  class="icon"
                  :class="
                    configrations[getBlogSelection.platform_name]
                      .background_classes
                  "
                ></div>
                <img
                  :src="
                    getBlogLogo(
                      getBlogSelection.platform_name,
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].website,
                    )
                  "
                  alt=""
                />
              </div>

              <div class="text_block">
                <p class="text">{{
                  getBlogURL(
                    getBlogSelection.platform_name,
                    getBlogSelection.primaryBlog[getBlogSelection.platform_name]
                      .website,
                  )
                }}</p>
                <p
                  v-if="getBlogSelection.primaryTime.time_type === 'now'"
                  class="sub_text"
                  >Publish Immediately</p
                >
                <p v-else class="sub_text">{{
                  getBlogSelection.primaryTime.postSelectedDate
                }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else
        >No primary blog destination selected. Please complete the previous
        steps.
      </div>
      <div
        v-if="
          getBlogSelection.platform_name !== '' &&
          getSecondaryBlogSelection &&
          getSecondaryBlogSelection.length > 0
        "
        class="channel_list"
      >
        <h3 class="c_heading"
          >After publishing on primary channel, your blog post will be published
          on these secondary channels.</h3
        >
        <div class="c_list">
          <div
            v-for="(blog, index) in getSecondaryBlogSelection"
            :key="index"
            class="c_item"
          >
            <div
                v-if="blog.selection[blogSelectionName(blog.option)]?.website"
              class="profile_picture"
              :class="blogSelectionName(blog.option)"
            >
              <div class="picture_block">
                <div
                  class="icon"
                  :class="
                    configrations[blogSelectionName(blog.option)]
                      .background_classes
                  "
                ></div>
                <img
                  :src="
                    getBlogLogo(
                      blogSelectionName(blog.option),
                      blog.selection[blogSelectionName(blog.option)].website,
                    )
                  "
                  alt=""
                />
              </div>

              <div class="text_block">
                <p class="text">{{
                  getBlogURL(
                    blogSelectionName(blog.option),
                    blog.selection[blogSelectionName(blog.option)].website,
                  )
                }}</p>
                <p v-if="blog.time.time_type === 'now'" class="sub_text"
                  >Publish Immediately after primary</p
                >
                <p v-else class="sub_text">{{ blog.time.postSelectedDate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          getBlogSelection.platform_name !== '' &&
          checkSocialAccount &&
          checkSelectedSocialAccounts
        "
        class="channel_list"
      >
        <h3 class="c_heading"
          >After publishing on primary channel, your blog post will be published
          on these social channels.</h3
        >
        <div class="c_list">
          <div
            v-for="id in getAccountSelection.facebook"
            :key="id"
            class="c_item"
          >
            <div class="profile_picture fb">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-facebook"></i>
                </div>
                <img
                  v-if="facebookImage(getFacebookPlatformByID(id))"
                  :src="facebookImage(getFacebookPlatformByID(id))"
                  alt=""
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                  alt=""
                />
              </div>
              <div class="text_block">
                <p class="text">{{
                  accountTooltipHtml(
                    'facebook',
                    getFacebookPlatformByID(id),
                    true,
                  )
                }}</p>
                <p class="sub_text">Publish after 5 minutes of primary</p>
              </div>
            </div>
          </div>
          <div
            v-for="id in getAccountSelection.twitter"
            :key="id"
            class="c_item"
          >
            <div class="profile_picture tw">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-twitter"></i>
                </div>
                <img
                  v-if="twitterImage(getTwitterPlatformByID(id))"
                  :src="twitterImage(getTwitterPlatformByID(id))"
                  alt=""
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                  alt=""
                />
              </div>
              <div class="text_block">
                <p class="text">{{
                  accountTooltipHtml(
                    'twitter',
                    getTwitterPlatformByID(id),
                    true,
                  )
                }}</p>
                <p class="sub_text">Publish after 5 minutes of primary</p>
              </div>
            </div>
          </div>
          <div
            v-for="id in getAccountSelection.linkedin"
            :key="id"
            class="c_item"
          >
            <div class="profile_picture lin">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-linkedin"></i>
                </div>
                <img
                  v-if="linkedinImage(getLinkedinPlatformByID(id))"
                  :src="linkedinImage(getLinkedinPlatformByID(id))"
                  alt=""
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                  alt=""
                />
              </div>
              <div class="text_block">
                <p class="text">{{
                  accountTooltipHtml(
                    'linkedin',
                    getLinkedinPlatformByID(id),
                    true,
                  )
                }}</p>
                <p class="sub_text">Publish after 5 minutes of primary</p>
              </div>
            </div>
          </div>
          <div
            v-for="id in getAccountSelection.pinterest"
            :key="id"
            class="c_item"
          >
            <div class="profile_picture pin">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-pinterest"></i>
                </div>
                <img
                  v-if="pinterestImage(getPinterestPlatformByID(id))"
                  :src="pinterestImage(getPinterestPlatformByID(id))"
                  alt=""
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                  alt=""
                />
              </div>
              <div class="text_block">
                <p class="text">{{
                  accountTooltipHtml(
                    'pinterest',
                    getPinterestPlatformByID(id),
                    true,
                  )
                }}</p>
                <p class="sub_text">Publish after 5 minutes of primary</p>
              </div>
            </div>
          </div>
          <div
            v-for="id in getAccountSelection.tumblr"
            :key="id"
            class="c_item"
          >
            <div class="profile_picture tum">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-tumblr"></i>
                </div>
                <img
                  v-if="tumblrImage(getTumblrPlatformByName(id, 'Profile'))"
                  :src="tumblrImage(getTumblrPlatformByName(id, 'Profile'))"
                  alt=""
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                  alt=""
                />
              </div>
              <div class="text_block">
                <p class="text">{{
                  accountTooltipHtml(
                    'tumblr',
                    getTumblrPlatformByName(id, 'Profile'),
                    true,
                  )
                }}</p>
                <p class="sub_text">Publish after 5 minutes of primary</p>
              </div>
            </div>
          </div>
          <div
            v-for="id in getAccountSelection.instagram"
            :key="id"
            class="c_item"
          >
            <div class="profile_picture ins">
              <div class="picture_block">
                <div class="icon">
                  <i class="cs-instagram"></i>
                </div>
                <img
                  v-if="instagramImage(getInstagramPlatformByID(id))"
                  :src="instagramImage(getInstagramPlatformByID(id))"
                  alt=""
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                  alt=""
                />
              </div>
              <div class="text_block">
                <p class="text">{{
                  accountTooltipHtml(
                    'instagram',
                    getInstagramPlatformByID(id),
                    true,
                  )
                }}</p>
                <p class="sub_text">Publish after 5 minutes of primary</p>
              </div>
            </div>
          </div>
          <div v-for="id in getAccountSelection.gmb" :key="id" class="c_item">
            <div class="profile_picture google_b">
              <div class="picture_block">
                <div class="icon">
                  <i class="fab fa-google"></i>
                </div>
                <img src="/img/google-business.png" alt="" />
              </div>
              <div class="text_block">
                <p class="text">{{
                  accountTooltipHtml(
                    'gmb',
                    integrations.getPlatformByID(
                      getGmbAccounts.all_items,
                      'name',
                      id,
                    ),
                    true,
                  )
                }}</p>
                <p class="sub_text">Publish after 5 minutes of primary</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="false" class="channel_list table_channel_list">
        <h3 class="c_heading"
          >After publishing on primary channel, your blog post will be published
          on these secondary channels.</h3
        >
        <div class="social_table">
          <div class="table_wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center"> </th>
                  <th class="text-center">Date</th>
                  <th class="text-center" style="width: 130px">Time</th>
                  <th class="text-center" style="width: 270px"
                    >Social Networks</th
                  >
                  <th style="text-align: left; width: 500px" class=""
                    >Post Text</th
                  >
                  <th class="text-center">Post Image</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="dot_circle green"></span>
                  </td>
                  <td class="text-center">
                    <span class="date">02</span>
                    <span class="day">OCT</span>
                  </td>

                  <td class="text-center">
                    <p class="time">09:00 AM</p>
                  </td>

                  <td class="text-center">
                    <div class="circle_boxes_inline">
                      <div class="circle_box">
                        <img
                          src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="blue_bg circle_box d-flex align-items-center justify-content-center"
                      >
                        <p class="text">wp</p>
                      </div>
                      <div class="circle_box">
                        <img
                          src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                          alt=""
                        />
                      </div>

                      <div
                        class="gray_bg circle_box d-flex align-items-center justify-content-center"
                      >
                        <p class="text">+32</p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <p class="p_text"
                      >Last year, I wrote an article: “9 Ways To Optimize The
                      New Feedly” https://goo.gl/Fyu4bu
                      <span class="gray">#Icon #Gizmodo #Firefox</span></p
                    >
                  </td>

                  <td class="text-center">
                    <div class="img">
                      <img
                        src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="dot_circle pink"></span>
                  </td>
                  <td class="text-center">
                    <span class="date">02</span>
                    <span class="day">OCT</span>
                  </td>

                  <td class="text-center">
                    <p class="time">09:00 AM</p>
                  </td>

                  <td class="text-center">
                    <div class="circle_boxes_inline">
                      <div class="circle_box">
                        <img
                          src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="blue_bg circle_box d-flex align-items-center justify-content-center"
                      >
                        <p class="text">wp</p>
                      </div>
                      <div class="circle_box">
                        <img
                          src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                          alt=""
                        />
                      </div>

                      <div
                        class="gray_bg circle_box d-flex align-items-center justify-content-center"
                      >
                        <p class="text">+32</p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <p class="p_text"
                      >Last year, I wrote an article: “9 Ways To Optimize The
                      New Feedly” https://goo.gl/Fyu4bu
                      <span class="gray">#Icon #Gizmodo #Firefox</span></p
                    >
                  </td>

                  <td class="text-center">
                    <div class="img">
                      <img
                        src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="dot_circle orange"></span>
                  </td>
                  <td class="text-center">
                    <span class="date">02</span>
                    <span class="day">OCT</span>
                  </td>

                  <td class="text-center">
                    <p class="time">09:00 AM</p>
                  </td>

                  <td class="text-center">
                    <div class="circle_boxes_inline">
                      <div class="circle_box">
                        <img
                          src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="blue_bg circle_box d-flex align-items-center justify-content-center"
                      >
                        <p class="text">wp</p>
                      </div>
                      <div class="circle_box">
                        <img
                          src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                          alt=""
                        />
                      </div>

                      <div
                        class="gray_bg circle_box d-flex align-items-center justify-content-center"
                      >
                        <p class="text">+32</p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <p class="p_text"
                      >Last year, I wrote an article: “9 Ways To Optimize The
                      New Feedly” https://goo.gl/Fyu4bu
                      <span class="gray">#Icon #Gizmodo #Firefox</span></p
                    >
                  </td>

                  <td class="text-center">
                    <div class="img">
                      <img
                        src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="tab_footer">
      <button
        v-if="
          $router.currentRoute.name !== 'composerBlog' &&
          getPublishSelection.status !== 'published'
        "
        class="mr-3 btn btn-studio-theme-space"
        :disabled="
          getPublishLoaders.processBlogPost ||
          getPublishLoaders.processSavePublication
        "
        data-cy="save-draft"
        @click.prevent="processBlogPost(true, true, false, '', true)"
      >
        <span>Save as draft</span>
        <clip-loader
          v-if="getPublishLoaders.processSavePublication"
          :color="'#ffffff'"
          :size="'16px'"
        ></clip-loader>
      </button>

      <button
        class="btn btn-studio-theme-space"
        :disabled="
          getPublishLoaders.processBlogPost ||
          getPublishLoaders.processSavePublication
        "
        data-cy="publish-blog"
        @click="callValidateProcessBlogPost"
      >
        <span>Great, Publish This!</span>
        <clip-loader
          v-if="getPublishLoaders.processBlogPost"
          :color="'#ffffff'"
          :size="'16px'"
        ></clip-loader>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import integrations, { getPlatformByIdentifier } from '@common/lib/integrations'

import {
  blogIntegrationsConfigrations,
  blogIntegrationsNames,
} from '@src/modules/integration/config/api-utils'
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type'],
emits: ['validate-process-blog-post'],
  data() {
    return {
      integrations,
      configrations: blogIntegrationsConfigrations,
    }
  },
  computed: {
    ...mapGetters([
      'getPublishLoaders',
      'getPublishSelection',
      'getAccountSelection',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getPinterestBoards',
      'getTumblrAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
    ]),

    checkSocialAccount() {
      return (
        this.getFacebookAccounts.items.length ||
        this.getTwitterAccounts.items.length ||
        this.getLinkedinAccounts.items.length ||
        this.getPinterestBoards.length ||
        this.getTumblrAccounts.items.length ||
        this.getInstagramAccounts.items.length ||
        this.getGmbAccounts.items.length
      )
    },
    checkSelectedSocialAccounts() {
      return (
        this.getAccountSelection.facebook.length > 0 ||
        this.getAccountSelection.twitter.length > 0 ||
        this.getAccountSelection.linkedin.length > 0 ||
        this.getAccountSelection.pinterest.length > 0 ||
        this.getAccountSelection.tumblr.length > 0 ||
        this.getAccountSelection.instagram.length > 0 ||
        this.getAccountSelection.gmb.length > 0
      )
    },
  },
  methods: {
    ...mapActions([]),
    callValidateProcessBlogPost() {
      if (this.type === 'discovery') {
        this.$emit('validate-process-blog-post')
      }
      if (this.type === 'composer') {
        this.$emit('validate-process-blog-post')
      }
    },

    getBlogLogo(type, platform) {
      console.debug('Method::getBlogLogo', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_logo : ''
    },

    getBlogURL(type, platform) {
      console.debug('Method::getBlogURL', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_url : ''
    },

    blogSelectionName(options) {
      console.debug('Method::blogSelectionName', options)
      let selection = ''
      blogIntegrationsNames.forEach((integration) => {
        /* eslint-disable no-prototype-builtins */
        if (
          options[integration].hasOwnProperty('selection') &&
          options[integration].selection
        ) {
          selection = integration
          return
        }
        if (
          !options[integration].hasOwnProperty('selection') &&
          options[integration]
        ) {
          selection = integration
        }
      })
      return selection
    },
  },
}
</script>
