<template>
  <div class="media-library-header">
    <div class="media-library-header__left">
      <div class="media-library-header__title"> Media Library </div>

      <div v-if="total" class="media-library-header__count">
        {{ total }} File{{ total > 1 ? 's' : '' }}
      </div>

      <div class="media-library-header__button">
        <button
          class="btn btn-studio-theme-space"
          :disabled="isFetchingLimits"
          @click="$bvModal.show('upload-media-modal')"
        >
          <i class="far fa-cloud-upload mr-2"></i>
          Upload Now
        </button>
      </div>
    </div>

    <div class="media-library-header__right">
      <div class="media-library-header__storage">
        <div>
          <i class="fal fa-cloud"></i>
        </div>
        <div>
          <template v-if="isFetchingLimits">
            <div class="d-flex flex-column">
              <SkeletonBox
                class="mb-1"
                height="8px"
                width="100px"
                radius=".2rem"
              />
              <SkeletonBox width="100px" radius=".2rem" />
            </div>
          </template>
          <template v-else-if="limits !== null">
            <b-progress
              :variant="limits.used >= limits.total ? 'danger' : 'primary'"
              class="mb-1"
              height="8px"
              :value="limits.used"
              :max="limits.total"
            ></b-progress>
            {{ bytesToSize(limits.used) }} of
            {{ bytesToSize(limits.total) }} used
            <i
              v-b-tooltip.hover
              :title="`This storage is subscribed by ${
                getSuperAdminDetails().full_name
              } and being consumed by all team members.`"
              class="fal fa-info-circle"
            ></i>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonBox from '../../../../analytics/views/common/SkeletonBox'

export default {
  name: 'Header',
  components: {
    SkeletonBox
  },
  props: ['total', 'limits', 'isFetchingLimits'],
  data () {
    return {
      searchText: ''
    }
  },
  methods: {
    getSuperAdminDetails () {
      const teamMembers = this.getWorkspaces.activeWorkspace.members
      for (let i = 0; i < teamMembers.length; i++) {
        const member = teamMembers[i]
        if (member.role === 'super_admin') {
          return member.user
        }
      }
      return null
    }
  }
}
</script>
