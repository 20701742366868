<template>
  <div :id="props.containerId"></div>
</template>

<script setup>
import { onMounted, watch } from 'vue'

const props = defineProps({
  containerId: {
    type: String,
    required: true,
  },
  postLink: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    default: '500px',
  },
  dataShowText: {
    type: Boolean,
    default: true,
  },
  dataLazy: {
    type: Boolean,
    default: true,
  },
})

/**
 * This method watches for changes in the `postLink` prop and triggers the creation of the Facebook post.
 */
watch(
  () => props.postLink,
  () => {
    createFacebookPost()
  }
)

/**
 * This method loads the Facebook SDK and creates the Facebook post on component mount.
 */
onMounted(() => {
  // Load the Facebook SDK if it's not already loaded
  if (!document.getElementById('facebook-sdk-script')) {
    const script = document.createElement('script')
    script.id = 'facebook-sdk-script'
    script.async = true
    script.defer = true
    script.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2'
    document.head.appendChild(script)
  }

  createFacebookPost()
})

/**
 * This method creates the Facebook post by removing any existing post, rendering the new post, and re-parsing the Facebook content.
 */
const createFacebookPost = () => {
  // Remove existing Facebook post if it exists
  const fbPostContainer = document.getElementById(props.containerId)
  if (fbPostContainer?.firstChild) {
    fbPostContainer?.firstChild.remove()
  }

  // Render the new Facebook post
  const fbPostDiv = document.createElement('div')
  fbPostDiv.className = 'fb-post'
  fbPostDiv.setAttribute('data-href', props.postLink)
  fbPostDiv.setAttribute('data-width', props.width)
  fbPostDiv.setAttribute('data-show-text', props.dataShowText)
  fbPostDiv.setAttribute('data-lazy', props.dataLazy)
  fbPostContainer?.appendChild(fbPostDiv)

  // Re-parse and render the Facebook content
  if (window.FB) {
    window.FB.XFBML.parse()
  }
}
</script>
