<template>
    <!--Begin::Expanded-Sidebar-->
  <aside
    v-if="expanded"
    class="account-section flex flex-col h-100 min-h-100 bg-cs-light-gray"
  >
    <!--Begin::AccountSelection-Header-->
    <div class="px-3 py-3 h-14">
      <div class="account-section__header flex items-center relative py-2">
        <span class="ml-1 font-medium text-base">
          Accounts
          <span class="text-gray-800 font-normal text-sm"
            >({{ selectedAccountsLength }}/{{ socialAccountsLength }})</span
          >
        </span>
        <span class="absolute -right-6">
          <span
            v-tooltip.right="{
              content: 'Minimize Sidebar',
            }"
            class="
              cursor-pointer
              text-gray-700
              hover:text-gray-900
              bg-cs-light-gray
              color-border
              flex
              items-center
              justify-center
              w-6
              h-6
              rounded-full
              shadow-sm
            "
            @click="expandSideBar"
          >
            <i class="fas fa-angle-left text-lg font-normal"></i>
          </span>
        </span>
      </div>
    </div>
    <!--End::AccountSelection-Header-->

    <hr class="m-0 hr-color" />

    <div class="px-3 py-3">
      <!--Begin::Content-Category-->
      <CstDropdown
        v-if="
          !isApprover ||
          (isApprover &&
            selectedContentCategory &&
            selectedContentCategory.name)
        "
        class="w-100"
        size="small"
      >
        <template v-slot:selected>
          <div class="flex flex-row space-x-2 py-1 items-center">
            <div
              v-if="selectedContentCategory !== null"
              class="w-3 h-3 rounded-full text-gray-800"
              :style="{ backgroundColor: selectedContentCategory.color_code }"
            ></div>
            <div class="text-gray-800"
              >{{
                selectedContentCategory !== null
                  ? selectedContentCategory.name
                  : 'Content Category'
              }}
            </div>
          </div>
        </template>

        <template v-if="!isApprover" v-slot>
          <div
            v-if="!contentCategoryList.length"
            class="text-center font-0-75rem"
          >
            You don't have any content category
          </div>
          <CstDropdownItem v-if="!contentCategoryList.length">
            <div
              class="
                flex flex-col
                space-y-2
                items-center
                justify-center
                bg-indigo-500
                text-white
                rounded-lg
                p-2
              "
              @click="
                $bvModal.hide('composer-modal'),
                  $router.push({ name: 'content_categories' })
              "
            >
              <div
                class="flex flex-row items-center justify-center font-semibold"
              >
                Create Category
              </div>
            </div>
          </CstDropdownItem>
          <CstDropdownItem v-if="selectedContentCategory !== null">
            <div
              class="flex flex-row items-center justify-center font-semibold"
              @click="$emit('onChangeContentCategory', null)"
            >
              Unselect Category
            </div>
          </CstDropdownItem>

          <CstDropdownItem
            v-for="(item, index) in contentCategoryList"
            :key="index"
            :class="{ 'bg-indigo-100': isASelectedContentCategory(item) }"
            @click="onCategorySelect(item)"
          >
            <div
              :class="{ 'font-semibold': isASelectedContentCategory(item) }"
              class="flex flex-row items-center justify-between"
            >
              <div class="flex flex-row items-center space-x-4">
                <div
                  class="w-3 h-3 rounded-full"
                  :style="{ backgroundColor: item.color_code }"
                ></div>
                <div
                  >{{ item.name }}
                  <span v-if="item.category_state === 'global'"
                    >( Global )</span
                  ></div
                >
              </div>
              <div class="flex items-center justify-center space-x-2">
                <div v-if="item.category_state === 'global'">
                  <i class="far fa-crown"></i>
                </div>
                <div v-if="isASelectedContentCategory(item)">
                  <i class="fas fa-check text-green-600"></i>
                </div>
              </div>
            </div>
          </CstDropdownItem>
        </template>
      </CstDropdown>
      <!--End::Content-Category-->

      <!--Begin::Search/Select-All-->
      <div
        v-if="!globalContentCategory && accountsList.length && !isApprover"
        class="flex items-center w-full mt-4 p-px"
      >
        <!--Search-->
        <i class="icon-Search text-primary-variant-1 text-base mr-2"></i>
        <div
          v-if="accountsList.length"
          class="account-section__search w-full mr-2"
        >
          <label class="w-full flex items-center relative">
            <input
              v-model="accountsSearchQuery"
              class="rounded-xl border py-2 pl-1 w-full text-grey-darkest"
              placeholder="Search Account"
              type="text"
            />
            <i
              v-if="accountsSearchQuery.length"
              class="
                fas
                fa-times-circle
                absolute
                cursor-pointer
                opacity-80
                right-3
              "
              @click="accountsSearchQuery = ''"
            ></i>
          </label>
        </div>

        <!--Select All-->
        <div class="account-section__select ml-auto flex items-center">
          <div
            class="
              mr-2
              px-1
              py-0.5
              flex
              items-center
              justify-center
              bg-cs-secondary
              cursor-pointer
              rounded-lg
            "
          >
            <i
              class="fas fa-chevron-down transition text-gray-800 text-xs"
              :class="{ 'fa-rotate-180': isOpen }"
              @click="isOpen = !isOpen"
            ></i>
          </div>
          <CstSimpleCheckbox
            v-if="accountsList.length"
            :value="selectAll"
            @input="onSelectAll($event)"
          >
          </CstSimpleCheckbox>
        </div>
      </div>
      <!--End::Search/Select-All-->
    </div>
    <hr class="m-0 hr-color" />

    <!--Begin::Accounts-List-->
    <div
      v-if="accountsList.length && !globalContentCategory"
      class="flex flex-col w-full cs-hr-scroll overflow-y-scroll scroll-padding"
    >
      <template v-if="!isApprover">
        <div
          v-for="(channel, index) in channelsNameArray"
          :key="index"
          class=""
        >
          <AccountDropdown
            v-if="getChannelAccounts(channel).length"
            :ref="`${channel}_account_dropdown`"
            :select-all="channelsSelectAll"
            :channel="channel"
            :open="isOpen"
            :disabled-select-all="isAllTokensExpired(channel)"
            @selectChannelAccounts="selectChannelAccounts"
          >
            <template v-slot:channel>
              <div class="flex items-center">
                <img
                  :src="
                    require('@assets/img/integration/' + getSocialIcon(channel))
                  "
                  alt=""
                  :class="[channel === 'twitter' ? 'w-3' : 'w-5']"
                />
                <span class="ml-2 text-sm font-medium text-black-300 capitalize">
                  {{ channel === 'twitter' ? 'X (Twitter)' : channel }}
                </span>
                <img
                    v-if="channel==='threads'"
                    src="@src/assets/img/common/new_tag.svg"
                    alt="new-tag"
                    class="ml-2"
                />
              </div>
            </template>
            <template v-slot:accounts>
              <template v-for="(account, ind) in getChannelAccounts(channel)">
                <div
                  v-if="!isApprover || (isApprover && account.checked)"
                  :key="ind"
                >
                  <CstAccountCheckbox
                    :size="'small'"
                    :account="account"
                    :channel-id="account._id"
                    :account-image="channelImage(account, account.channel_type)"
                    :account-name="accountName(account)"
                    :account-type="
                      'type' in account
                        ? account.type
                        : channel === 'instagram'
                        ? 'business'
                        : 'personal'
                    "
                    :account-platform="account.channel_type"
                    :is-checked="account.checked"
                    :disabled="isApprover || disableAccount(account)"
                    :mobile-devices="mobileDevices"
                    :publish-as="publishAs"
                    :is-ig-personal-account="isIGPersonalAccount(account)"
                    :is-twitter-disabled="isTwitterDisabled(account)"
                    :is-approver="isApprover"
                    v-bind="$attrs"
                    v-on="$listeners"
                    @change="onAccountSelect"
                  />
                </div>
              </template>
            </template>
          </AccountDropdown>
        </div>
      </template>
      <template v-else>
        <template v-for="(account, ind) in accountsList" :key="ind">
          <div v-if="isApprover && account.checked">
            <CstAccountCheckbox
              :size="'small'"
              :account="account"
              :channel-id="account._id"
              :account-image="channelImage(account, account.channel_type)"
              :account-name="
                'platform_name' in account
                  ? account.platform_name
                  : account.name
              "
              :account-type="accountName(account)"
              :account-platform="account.channel_type"
              :is-checked="account.checked"
              :disabled="isApprover || disableAccount(account)"
              :mobile-devices="mobileDevices"
              :publish-as="publishAs"
              :is-approver="isApprover"
              v-bind="$attrs"
              v-on="$listeners"
              @change="onAccountSelect"
            />
          </div>
        </template>
      </template>
    </div>
    <!--End::Accounts-List-->

    <!--Begin::No-Accounts-->
    <div
      v-else-if="!accountsList.length"
      class="py-10 flex items-center justify-center w-full"
    >
      <span class="font-medium text-sm"
        >No Account Connected
        <br />
        <span class="text-xs text-gray-800"
          >To Connect Account
          <span
            class="text-cs-primary cursor-pointer"
            @click="onClickSocialAccount"
          >
            Click Here</span
          ></span
        >
      </span>
    </div>
    <!--End::No-Accounts-->

    <!--Begin::Global-ContentCategory-Selected-->
    <div
      v-else-if="globalContentCategory"
      class="py-10 mt-6 px-7 flex items-center justify-center w-full"
    >
      <ul class="list-disc text-sm text-gray-900">
        <li>
          <span
            >This post will be published on all the social accounts that are
            configured in the
            <span class="font-medium text-sm">{{
              selectedContentCategory.name
            }}</span>
            global content category</span
          >
        </li>
        <br />
        <li>
          You can use Spintax format to create different caption for each post.
          <a href="#" data-beacon-article-modal="593">Read more</a>
        </li>
        <br />
        <li>
          If Instagram and Pinterest are included in your channels for this
          post, you MUST add at least one image.
        </li>
      </ul>
    </div>
    <!--End::Global-ContentCategory-Selected-->
  </aside>
  <!--End::Expanded-Sidebar-->

  <!--Begin::Condensed-Sidebar-->
  <aside
    v-else
    class="w-16 flex flex-col h-100 min-h-100 bg-cs-light-gray"
    style="border-right: 1px solid #eff0f1"
  >
    <div class="mt-4 px-3 py-3">
      <div class="flex items-center relative py-2">
        <span class="absolute -right-6">
          <span
            v-tooltip.right="{
              content: 'Maximize Sidebar',
            }"
            class="
              cursor-pointer
              text-gray-700
              hover:text-gray-900
              bg-cs-light-gray
              color-border
              flex
              items-center
              justify-center
              w-6
              h-6
              rounded-full
              shadow-sm
            "
            @click="expandSideBar"
          >
            <i class="fas fa-angle-right text-lg font-normal"></i>
          </span>
        </span>
      </div>
    </div>
    <div ref="mainDiv" class="w-full flex flex-col h-5/6">
      <div
        v-if="selectedAccountsLength"
        ref="thumbnailsDiv"
        class="flex flex-col items-center inline-block"
      >
        <template v-for="(account, key) in collapsedAccountsList" :key="key">
          <div
            v-tooltip.right="{
              content:
                account.channel_type === ALL_CHANNELS.GMB ||
                account.channel_type === ALL_CHANNELS.TIKTOK ||
                account.channel_type === ALL_CHANNELS.YOUTUBE ||
                account.channel_type === ALL_CHANNELS.TUMBLR
                  ? account.platform_name
                  : account.name,
            }"
            class="relative border rounded-full my-2"
          >
            <img
              height="30"
              :src="account.image ? account.image : ''"
              width="30"
              alt=""
              class="rounded-full object-cover"
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
              "
            />
            <img
              :src="
                require('@assets/img/integration/' +
                  getSocialImage(account.channel_type))
              "
              alt=""
              class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
              :class = "{ 'bg-white rounded-full': account?.channel_type === 'twitter' }"
            />
          </div>
        </template>
      </div>
      <div v-else class="h-20 flex flex-col items-center">
        <img
          v-tooltip.right="{
            content: 'No Account Selected',
          }"
          width="30"
          height="30"
          src="@assets/img/icons/statuses/failed.svg"
          alt="failed"
          class="cursor-pointer"
          @click="expandSideBar"
        />
      </div>
    </div>
    <div v-show="check" class="relative my-2 flex flex-col items-center">
      <div
        v-tooltip.right="{
          content: 'Show all accounts',
        }"
        class="
          rounded-full
          flex
          justify-center
          items-center
          h-10
          w-10
          cursor-pointer
        "
        style="background-color: #0067e4"
        @click="expandSideBar"
      >
        <p class="text-white font-bold text-lg">+{{ value - value * 2 }}</p>
      </div>
    </div>
  </aside>
  <!--End::Condensed-Sidebar-->
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstAccountCheckbox from '@ui/CheckBox/CstAccountCheckBox'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
import {
  defaultFirstComment,
  firstCommentChannels,
} from '@src/modules/composer_v2/views/composerInitialState'
import AccountDropdown from '@ui/Dropdown/AccountDropdown'
import {
  socialChannelsNameArray,
  ALL_CHANNELS,
} from '@common/constants/common-attributes'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import { getPlatformName } from '@common/lib/integrations'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import {EventBus} from '@/src/modules/common/lib/event-bus'

const clone = require('rfdc/default')

export default {
  name: 'AccountSelectionAside',
  components: {
    CstSimpleCheckbox,
    CstAccountCheckbox,
    CstDropdown,
    CstDropdownItem,
    AccountDropdown,
  },
  mixins: [ComposerHelper],
  props: {
    accountSelection: {
      type: Object,
      required: true,
      default: () => {},
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    contentCategoryList: {
      type: Array,
      default: () => [],
    },
    selectedContentCategory: {
      type: Object,
      default: () => {},
    },
    globalContentCategory: {
      type: Boolean,
      default: false,
    },
    accountsList: {
      type: Array,
      default: () => [],
    },
    mobileDevices: {
      type: Array,
      default: () => [],
    },
    publishAs: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isApprover: {
      type: Boolean,
    },
    firstComment: {
      type: Object,
      default: () => {
        return defaultFirstComment
      },
    },
    isAllowFirstComment: {
      type: Function,
      default: () => {},
    },
    carouselOptions: {
      type: Object,
      default: () => {},
    },
    youtubeOptions: {
      type: Object,
      default: () => {},
    },
    gmbOptions: {
      type: Object,
      default: () => {},
    },
    socialPostType: {
      type: String,
      default: '',
    },
    isTwitterAllowed: {
      type: Boolean,
      default: false,
    },
    linkedinOptions: {
      type: Object,
      default: () => {},
    }
  },
  setup(){
    const {channelImage} = useComposerHelper()
    return {
      channelImage
    }
  },
  data() {
    return {
      // accountsList: [],
      ALL_CHANNELS,
      accountsSelectedByDefault: false,
      // toggleSearchBar: false,
      accountsSearchQuery: '',
      selectedOptions: [],
      selectAll: false,
      channelsSelectAll: false,
      channelsNameArray: socialChannelsNameArray,
      isOpen: true,
      expanded: true,
      mainHeight: '',
      thumbHeight: '',
      check: false,
      value: '',
      displayableAccounts: null,
      collapsedAccountsList: [],
    }
  },
  computed: {
    /**
     * Calculates the length of the accounts selected
     * @returns {number}
     */
    selectedAccountsLength() {
      let length = 0
      for (const channel in this.accountSelection) {
        length += this.accountSelection[channel].length
      }
      EventBus.$emit('selected-accounts-length', length)
      return length
    },

    /**
     * Filters and returns list of social accounts.
     * @returns {number}
     */
    socialAccountsLength() {
      return this.accountsList.length
    },

    /**
     * returns true if the search bar is active and has a query to search for
     * @returns {boolean|number}
     */
    isSearchActive() {
      return this.toggleSearchBar && this.accountsSearchQuery.length > 0
    },
  },
  watch: {
    selectedContentCategory: {
      handler(value) {
        if (value === null) return
        this.unSelectAll()
        // iterate through the computed accounts list and check the matching values.
        this.accountsList.forEach((channel) => {
          // disable account check
          if (this.disableAccount(channel)) return

          if (
            value[channel.channel_type] &&
            value[channel.channel_type].find(
              (item) =>
                String(item) === String(channel[channel.channel_identifier])
            )
          ) {
            // channel.checked = true
            this.onAccountSelect({ channelId: channel._id, value: true }, true)
          }
        })
      },
      deep: true,
    },
    expanded: {
      handler(value) {
        this.collapsedAccountsList = this.accountsList.filter(
          (account) =>
            account.checked === true && account.validity !== 'invalid'
        )
        this.check = false
        this.$cookie.set('isComposeSidebarExpanded', JSON.stringify(value))
      },
    },
  },
  mounted() {
    const cookieValue = JSON.parse(
      this.$cookie.get('isComposeSidebarExpanded') || null
    )
    // Code checks for null/undefined but not false, so we're explicitly checking null.
    if (cookieValue != null) this.expanded = cookieValue
  },
  /**
   * @description calculates the available height of the sidebar in not expanded form and renders the calculated number of accounts.
   */
  updated() {
    if (!this.expanded && this.selectedAccountsLength > 0) {
      this.mainHeight = this.mainDivHeight();
      this.thumbHeight = this.thumbnailDivHeight();

      // Calculate the height required by each account
      const accountsHeight = this.thumbHeight / this.selectedAccountsLength;

      // Calculate the number of displayable accounts based on available height
      this.displayableAccounts = Math.floor(this.mainHeight / accountsHeight);

      // If the height required by selected accounts is more than the available height, splice the accounts that cannot be rendered
      if (this.mainHeight <= this.thumbHeight) {
        const accountsToRemove = this.selectedAccountsLength - this.displayableAccounts;
        this.value = -1 * accountsToRemove;
        this.collapsedAccountsList.splice(this.value);
        this.check = true;
      }
    } else {
      // Reset the select-all value for all channels
      for (const channel in ALL_CHANNELS) {
        this.toggleChannelSelectAllValue(ALL_CHANNELS[channel]);
      }
    }
  },
  methods: {
    mainDivHeight() {
      if (!this.expanded) {
        this.mainHeight = this.$refs?.mainDiv?.clientHeight
        return this.mainHeight
      }
      return 0
    },
    thumbnailDivHeight() {
      if (!this.expanded) {
        this.thumbHeight = this.$refs?.thumbnailsDiv?.clientHeight
        return this.thumbHeight
      }
      return 0
    },
    expandSideBar() {
      if (this.expanded) {
        setTimeout(() => {
          this.expanded = !this.expanded
        }, 280)
      } else {
        this.expanded = !this.expanded
      }
    },
    accountName(account) {
      return getPlatformName(account)
    },
    /**
     * Captures an Event emitted from 'CstAccountCheckBox.vue'
     * De-structures Args and plucks the matching 'channelId' from 'connectedSocialAccountsList' and Adds/Removes the channel based on 'value'
     * TODO: More room for improvement in this method.
     * @param Args
     * @param forCategory = false
     */
    onAccountSelect(Args, forCategory = false) {
      console.log('Method::onAccountSelect ~ Args -> ', Args)

      const { channelId, value } = Args

      const socialChannel = this.accountsList.find((item) => {
        if (String(item._id) === String(channelId)) {
          item.checked = value
          return true
        }
      })

      // YouTube and GMB preferences.
      if (
        this.socialPostType !== 'edit' &&
        value &&
        (socialChannel?.channel_type === 'youtube' ||
          socialChannel?.channel_type === 'gmb')
      ) {
        this.checkYoutubeGmbPreferences(socialChannel)
      }

      // socialChannel = socialChannel[socialChannel.length-1]
      if (this.disableAccount(socialChannel)) return

      const channelIdentifier = this.checkForYoutubeAndTwitter(socialChannel)

      const existingItemIndex = this.accountSelection[
        socialChannel.channel_type
      ].findIndex(
        (item) =>
          String(item) ===
          String(socialChannel[socialChannel.channel_identifier])
      )

      if (value && existingItemIndex === -1) {
        this.accountSelection[socialChannel.channel_type].push(
          socialChannel[socialChannel.channel_identifier]
        )
        // facebook carousel
        if (
          socialChannel.channel_type === 'facebook' &&
          socialChannel.type === 'Page'
        ) {
          this.carouselOptions.accounts.push(
            socialChannel[socialChannel.channel_identifier]
          )
        }
        if(socialChannel.channel_type === 'linkedin'){
          this.linkedinOptions.accounts.push(
            socialChannel[socialChannel.channel_identifier]
          )
          // this.linkedinOptions.is_carousel = true
        }
        // first comment
        this.handleFirstCommentAccountChange(
          'push',
          clone(socialChannel),
          channelIdentifier
        )
      } else if (value === false) {
        this.accountSelection[socialChannel.channel_type].splice(
          existingItemIndex,
          1
        )
        // facebook carousel
        if (
          socialChannel.channel_type === 'facebook' &&
          socialChannel.type === 'Page'
        ) {
          this.carouselOptions.accounts = this.carouselOptions.accounts.filter(
            (acc) => acc !== socialChannel[socialChannel.channel_identifier]
          )
        }

        if(socialChannel.channel_type === 'linkedin'){
          this.linkedinOptions.accounts = this.linkedinOptions.accounts.filter(
            (acc) => acc !== socialChannel[socialChannel.channel_identifier]
          )
        }
        // first comment
        this.handleFirstCommentAccountChange(
          'splice',
          clone(socialChannel),
          channelIdentifier
        )
      }
      if (socialChannel.channel_type === 'instagram')
        this.$emit('updateInstagramSelection')

      if (this.carouselOptions.accounts.length < 1)
        this.carouselOptions.is_carousel_post = false

      if (this.linkedinOptions.accounts.length < 1 && !this.linkedinOptions?.document_added)
        this.linkedinOptions.is_carousel = false
      this.toggleChannelSelectAllValue(socialChannel.channel_type)
      this.toggleSelectAllValue()
    },
    /**
     * Un-selects existing accounts and adds the accounts from content category lists.
     * @param category
     */
    onCategorySelect(category) {
      console.log(
        'Method::onCategorySelect ~ category, accounts -> ',
        category,
        this.accountSelection
      )
      if (this.selectedContentCategory) {
        if (this.selectedContentCategory._id === category._id) {
          this.$emit('onChangeContentCategory', null)
          return
        }
      }

      this.$emit('onChangeContentCategory', category)
    },
    /**
     * Clears the accounts array and marks the checked flag false in "connectedSocialAccountsList".
     */
    unSelectAll() {
      console.log('Method::unSelectAll ')
      // this.$emit('onChangeAccounts', [])
      for (const channel in this.accountSelection) {
        this.accountSelection[channel] = []
      }
      this.accountsList.forEach((channel) => {
        if ('checked' in channel) {
          channel.checked = false
        }
      })
      // empty first comment accounts selection
      this.firstComment.first_comment_accounts = []
      this.firstComment.has_first_comment = false
    },
    /**
     * @Deprecated
     * Applies search filter on the accounts list.
     * @param channel
     * @returns {boolean}
     */
    searchFilter(channel) {
      const channelName =
        'platform_name' in channel ? channel.platform_name : channel.name
      if (this.accountsSearchQuery) {
        return (
          channelName
            .toLowerCase()
            .search(this.accountsSearchQuery.toLowerCase()) > -1
        )
      } else {
        return true
      }
    },
    /**
     * Fetches accounts for specific channel with applied search filter.
     * @param channel
     * @returns {*[]}
     */
    getChannelAccounts(channel) {
      const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
      }
      return this.accountsList.filter((account) => {
        if (account.channel_type === channel) {
          const accountName =
              'platform_name' in account ? account.platform_name : account.name
          return this.accountsSearchQuery
              ? accountName
              .toLowerCase()
              .search(escapeRegExp(this.accountsSearchQuery.toLowerCase())) > -1
              : true
        }
        return false
      })
    },
    /**
     * Event triggered to select all the accounts.
     * @param val
     */
    onSelectAll(val) {
      this.selectAll = this.channelsSelectAll = val
      this.toggleSelectAllValue()
    },
    selectChannelAccounts(channel, val) {
      this.getChannelAccounts(channel).forEach((account) => {
        if (account.channel_type !== channel || this.disableAccount(account))
          return
        this.onAccountSelect({ channelId: account._id, value: val })
      })
    },
    /**
     *  checks if all accounts are selected. 'select all' is disabled if one of the accounts are unchecked and enabled if all accounts are checked.
     *  checks for Twitter and YouTube accounts separately because only one account selection is allowed per channel
     */
    toggleSelectAllValue() {
      this.selectAll = this.checkAccountChannelsSelected(this.accountsList)
    },
    /**
     * if the user has selected youtube or twitter account, unselect the existing one because only one selection is allowed.
     * @param account
     */
    checkForYoutubeAndTwitter(account) {
      // check if there is any twitter/youtube account in accountSelection
      if (
        account.channel_type === 'twitter'
      ) {
        const channelIdentifier = this.accountSelection[account.channel_type][0]
        if (!channelIdentifier) return
        this.accountsList.find((item) => {
          if (item[item.channel_identifier] === channelIdentifier) {
            item.checked = false
            return true
          }
        })
        this.accountSelection[account.channel_type] = []
        return channelIdentifier
      }

      // if any account exists, uncheck that account from accountsList.
      // empty the accountSelection array
    },
    /**
     * if the user clicks on social account connection button, save the current composer state and navigate to the social accounts connection page.
     */
    onClickSocialAccount() {
      // TO-DO: Save the current state of the composer.
      this.$bvModal.hide('composer-modal')
      this.$router.push({ name: 'social' })
    },
    /**
     * Checks if the category is the selected one
     * @param category
     * @returns {boolean}
     */
    isASelectedContentCategory(category) {
      if (this.selectedContentCategory) {
        if (category._id === this.selectedContentCategory._id) {
          return true
        }
      }
      return false
    },
    /**
     * Handle first comment accounts based on accounts selection.
     * @param action (push or splice)
     * @param account
     * @param firstCommentId
     */
    handleFirstCommentAccountChange(action, account, firstCommentId) {
      if (firstCommentChannels.includes(account.channel_type)) {
        let selectedAccounts = this.firstComment.first_comment_accounts.filter(
          (acc) => acc !== firstCommentId
        )
        switch (action) {
          case 'push':
            if (this.isAllowFirstComment(account)) {
              if (
                !selectedAccounts.includes(account[account.channel_identifier])
              )
                selectedAccounts.push(account[account.channel_identifier])
            }
            break
          case 'splice':
            selectedAccounts = this.firstComment.first_comment_accounts.filter(
              (acc) => acc !== account[account.channel_identifier]
            )
            break
        }
        this.firstComment.first_comment_accounts = [...selectedAccounts]
      }
    },
    /**
     * Emits an event to social modal with social preference settings.
     * @param socialChannel
     */
    checkYoutubeGmbPreferences(socialChannel) {
      console.log(
        'METHOD::setYoutubeGmbPreferences ~ socialChannel ->  ',
        socialChannel
      )
      if (socialChannel?.social_preference_settings) {
        this.$emit(
          'setYoutubeGmbOptions',
          socialChannel.channel_type,
          socialChannel.social_preference_settings
        )
      }
    },

    /**
     * Handles the account dropdown select all checkbox value if all accounts for that channel are selected.
     * @param channel
     */
    toggleChannelSelectAllValue(channel) {
      const refName = `${channel}_account_dropdown`
      if (this.$refs[refName] && this.$refs[refName].length) {
        this.$refs[refName][0].checked = this.checkAccountChannelsSelected(
          this.getChannelAccounts(channel),
          channel.channel_type
        )
      }
    },

    /**
     * Helper util to check account selection
     * Takes a list of accounts arrays and checks if all the channels are selected and returns a boolean
     * @param channelAccounts
     * @param type
     */
    checkAccountChannelsSelected(channelAccounts, type = 'all') {
      return channelAccounts.every((account) => account.checked)
    },
    isAllTokensExpired(channel) {
      return this.getChannelAccounts(channel).every(
        (account) => this.disableAccount(account)
      )
    },
  },
}
</script>

<style lang="scss">
.blue-icon {
  @apply rounded-lg;
  @apply px-1.5;
  @apply py-0.5;
  border: 2px solid #0068e5;

  &:before {
    font-weight: 500;
    color: #0068e5 !important;
  }
}
</style>
