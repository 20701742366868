<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

export default {
  components: {},
  setup(){
    const { getSocialImageRounded, analyticsEnabledTwitterAccounts, getAllPinterestProfiles } = useComposerHelper()
    return {
      analyticsEnabledTwitterAccounts,
      getSocialImageRounded,
      getAllPinterestProfiles
    }
  },
  data() {
    return {
      accounts: [],
      selected_accounts: [],
      individualSelectedAccounts: [],
      network: null,
      all_selected: null,
    }
  },
  computed: {
    ...mapGetters([
      'getReportsServiceCalls',
      'getScheduledReportItem',
      'getFacebookPagesList',
      'getInstagramAccounts',
      'getTiktokAccounts',
      'getYoutubeAccounts',
      'getPinterestBoards',
      'getLinkedinCompanyPages',
    ]),
    getSocialAccounts() {
      if(this.network ==='campaign-and-label'){
        return this.accounts;
      }
      return this.accounts?.filter(account => this.filterAccounts(account));
    },
    isPinterestAccountAnalytics() {
      return this.network === 'pinterest';
    },
    isTwitterAnalytics() {
      return this.network === 'twitter';
    },
    isGroupOrIndividual() {
      return this.network === 'group' || this.network === 'individual';
    },
  },
  watch: {
    getScheduledReportItem(){
      if (this.selected_accounts) {
        this.all_selected =
            this.selected_accounts.length === this.accounts.length &&
            this.selected_accounts.length > 0
      }else if(this.individualSelectedAccounts){
        this.all_selected = this.individualSelectedAccounts?.length === this.getSocialAccounts?.length && this.individualSelectedAccounts.length > 0;
      }
    },
    selected_accounts() {
      if (this.selected_accounts.length) {
        this.all_selected =
            this.selected_accounts.length === this.accounts.length &&
            this.selected_accounts.length > 0
      }
    },
    individualSelectedAccounts() {
      this.all_selected = this.individualSelectedAccounts?.length === this.getSocialAccounts?.length && this.individualSelectedAccounts.length > 0;
    },
  },
  beforeUnmount() {
    EventBus.$off('schedule-report')
    EventBus.$off('edit-schedule-report')
  },
  mounted() {
    EventBus.$on('schedule-report', ({accounts, network, topPosts, labels, campaigns}) => {
      this.getScheduledReportItem.topPosts = topPosts
      this.getScheduledReportItem.labels = labels
      this.getScheduledReportItem.campaigns = campaigns
      this.accounts = this.$route.name === 'my_report_v3'
          ? this.populateAccounts()
          : this.populateAccounts()


      this.SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(accounts);
      this.SET_SCHEDULED_REPORT_PLATFORM_TYPE(network)
      this.SET_SCHEDULED_REPORT_ITEM_TYPE(network === 'group' ? 'group' : 'individual');
      this.network = network;

      if (this.network === 'group' || this.network === 'individual' || this.network === 'campaign-and-label') {
        this.selected_accounts = this.individualSelectedAccounts = accounts.map(account => this.getAccountId(account))
      } else {
        if(this.getAccountId(accounts)) this.individualSelectedAccounts = [this.getAccountId(accounts)]
      }
    });

    EventBus.$on('edit-schedule-report', ({reportData}) => {
      if (Object.hasOwn(reportData, 'platform_type')) {
        this.network = reportData.platform_type;
      } else {
        if (reportData.type === 'group') {
          this.network = 'group';
          this.SET_SCHEDULED_REPORT_PLATFORM_TYPE('group');
        } else if (reportData.type === 'individual') {
          this.network = 'individual';
          this.SET_SCHEDULED_REPORT_PLATFORM_TYPE('individual');
        } else {
          this.network = reportData.type;
          this.SET_SCHEDULED_REPORT_PLATFORM_TYPE = reportData.type;
          this.SET_SCHEDULED_REPORT_ITEM_TYPE('individual')
        }
      }
      this.accounts = this.populateAccounts()
      this.SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(this.network === 'group' || this.network === 'individual' ? this.accounts : this.accounts.filter((account) => account.account_type === this.network))

      if(this.network === 'group'){
        this.selected_accounts = reportData.accounts.map(account => this.getAccountId(account))
      }else{
        this.individualSelectedAccounts = reportData.accounts.map(account => this.getAccountId(account))
      }
    })
  },
  methods: {
    ...mapActions(['scheduleReportsService']),
    ...mapMutations([
      'SET_SCHEDULED_REPORT_ITEM_ACCOUNTS',
      'SET_SCHEDULED_REPORT_ITEM_TYPE',
      'RESET_SCHEDULED_REPORT_ITEM',
       'SET_SCHEDULED_REPORT_PLATFORM_TYPE'
    ]),
    reset() {
      this.RESET_SCHEDULED_REPORT_ITEM()
      this.selected_accounts = []
      this.accounts = []
      this.individualSelectedAccounts = []
      this.all_selected = null
      this.network = null
    },
    populateAccounts() {
      const accountsList = []
      this.getFacebookPagesList?.forEach((item) => {
        item.account_type = 'facebook'
        accountsList.push(item)
      })
      this.analyticsEnabledTwitterAccounts?.forEach((item) => {
        item.account_type = 'twitter'
        accountsList.push(item)
      })
      // pinterest profile
      this.getAllPinterestProfiles()?.forEach((item) => {
        item.account_type = 'pinterest'
        item.board_id = item.profile_id
        accountsList.push(item)
      })
      this.getPinterestBoards?.forEach(item => {
        item.account_type = 'pinterest'
        accountsList.push(item)
      })
      this.getLinkedinCompanyPages?.forEach((item) => {
        item.account_type = 'linkedin'
        accountsList.push(item)
      })
      this.getInstagramAccounts?.items?.forEach((item) => {
        item.account_type = 'instagram'
        accountsList.push(item)
      })
      this.getTiktokAccounts?.items?.forEach((item) => {
        item.account_type = 'tiktok'
        accountsList.push(item)
      })
      this.getYoutubeAccounts?.items?.forEach((item) => {
        item.account_type = 'youtube'
        accountsList.push(item)
      })
      return accountsList
    },
    async scheduleReport() {
      let accountsToSet = [];

      if (this.network === 'group') {
        accountsToSet = this.selected_accounts
            .map(accountId => this.accounts.find(account => accountId === this.getAccountId(account)))
            .filter(Boolean);
      } else {
        accountsToSet = this.individualSelectedAccounts.map(accountId => this.accounts.find(account => accountId === this.getAccountId(account)))
            .filter(Boolean);
      }

      this.SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(accountsToSet);
      const response = await this.scheduleReportsService(this.getScheduledReportItem);
      if(response?.name) this.reset();
    },
    addEmailToList(event) {
      let email = this.getScheduledReportItem.email
      // check if it is a comma, then substring last element which will be a comma
      if (event.key === ',') {
        email = email.substring(0, email.length - 1)
      }

      // validate email address

      if (this.validateEmail(email)) {
        // if it is a valid one push to the list and empty the v-model value
        if (this.getScheduledReportItem.email_list.includes(email)) {
          // not a duplicate email
          this.$store.dispatch('toastNotification', {
            message: 'Email address already added.',
            type: 'error',
          })
        } else {
          this.getScheduledReportItem.email_list.push(email)
          this.getScheduledReportItem.email = null
        }
      }
    },
    removeEmailFromList(index) {
      this.getScheduledReportItem.email_list.splice(index, 1)
    },
    copyEmailMyself() {
      if (this.getScheduledReportItem.copy_email_to_myself) {
        if (
            this.getScheduledReportItem.email_list.indexOf(
                this.getProfile.email
            ) === -1
        ) {
          this.getScheduledReportItem.email_list.unshift(this.getProfile.email)
        }
      } else {
        if (
            this.getScheduledReportItem.email_list.indexOf(
                this.getProfile.email
            ) !== -1
        ) {
          this.getScheduledReportItem.email_list.splice(
              this.getScheduledReportItem.email_list.indexOf(
                  this.getProfile.email
              ),
              1
          )
        }
      }
    },
    changeExportType() {
      if (this.network !== 'group' && this.network !== 'individual') return
      this.network = this.network === 'group' ? 'individual' : 'group'
      this.SET_SCHEDULED_REPORT_ITEM_TYPE(this.network)
      this.SET_SCHEDULED_REPORT_PLATFORM_TYPE(this.network)
      if (this.network === 'group') {
        this.all_selected =
            this.selected_accounts.length === this.getSocialAccounts.length &&
            this.selected_accounts.length > 0
      } else {
        this.all_selected = this.individualSelectedAccounts?.length === this.getSocialAccounts?.length && this.individualSelectedAccounts.length > 0;
      }
    },
    handleSelectAll(value) {
      if (value?.target?.checked) {
        const filteredAccounts = this.accounts.filter(account => this.filterAccounts(account))
        if (this.network === 'group') {
          this.selected_accounts = filteredAccounts.map(account => this.getAccountId(account));
        } else if (this.network === 'individual' || this.network === 'campaign-and-label') {
          this.individualSelectedAccounts = filteredAccounts.map(account => this.getAccountId(account));
        } else {
          this.individualSelectedAccounts = filteredAccounts.filter(account => account.account_type === this.network).map(account => this.getAccountId(account));
        }
      } else {
        if (this.network === 'group' && this.selected_accounts.length) {
          this.selected_accounts = [];
        } else {
          if (this.individualSelectedAccounts.length) {
            this.individualSelectedAccounts = [];
          }
        }
      }
    },
    getAccountId(account) {
      const keyMap = {
        facebook: 'facebook_id',
        instagram: 'instagram_id',
        linkedin: 'linkedin_id',
        twitter: 'twitter_id',
        tiktok: 'platform_identifier',
        youtube: 'platform_identifier',
        pinterest: 'board_id',
      };

      return account[keyMap[account.account_type]] || null;
    },
    getAccountName(account){
      return account?.platform_name || account?.name || account?.username
    },
    getAccountImage(account){
      switch(account.account_type){
        case 'tiktok':
        case 'youtube':
          return account.platform_logo ? account.platform_logo : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
        default:
          return account.image ? account.image : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      }
    },

    /**
     * Checks if the account is a Pinterest account
     * @param {Object} account - The account to check
     * @returns {boolean} - True if the account is a Pinterest account, false otherwise
     */
    isPinterestAccount(account) {
      return account.account_type === 'pinterest';
    },

    /**
     * Checks if the account is a Twitter account
     * @param {Object} account - The account to check
     * @returns {boolean} - True if the account is a Twitter account, false otherwise
     */
    isTwitterAccount(account) {
      return account.account_type === 'twitter';
    },

    /**
     * Filters Twitter accounts based on additional conditions
     * @param {Object} account - The Twitter account to filter
     * @returns {boolean} - True if the account passes the filtering conditions, false otherwise
     */
    filterTwitterAccounts(account) {
      return (!this.isTwitterAccount(account) || (this.isTwitterAccount(account) && this.isTwitterAnalytics));
    },

    /**
     * Filters Pinterest accounts based on additional conditions
     * @param {Object} account - The Pinterest account to filter
     * @returns {boolean} - True if the account passes the filtering conditions, false otherwise
     */
    filterPinterestAccounts(account) {
      return (!this.isPinterestAccount(account) || (this.isPinterestAccount(account) && this.isPinterestAccountAnalytics));
    },

    /**
     * Filter accounts based on the network
     * @param account
     * @return {*|boolean}
     */
    filterAccounts(account = {}) {
      if (this.network === 'campaign-and-label') {
        return true;
      }
      return (this.isGroupOrIndividual || account?.account_type === this.network) && this.filterPinterestAccounts(account) && this.filterTwitterAccounts(account);
    },
  }
}
</script>

<template>
  <div id="scheduleReport" class="modal fade scheduleEmail normal_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Schedule Email</h2>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="reset"
            >&times;</button>
        </div>
        <div class="modal_body basic_form m_t_15">
          <div class="input_field mt-0">
            <label for="">Export Name</label>
            <input
              v-model="getScheduledReportItem.name"
              type="text"
              placeholder="Enter a name for export..."
            />
          </div>
          <div
            v-if="getScheduledReportItem.type && !(network !== 'group' && network !== 'individual')"
            class="input_field mt-3 repeat_option"
          >
            <label for="">Export Type</label>
              <div   class="d-flex align-items-start">
                <div class="radio_input_image">
                  <input
                      id="group_type"
                      v-model="getScheduledReportItem.type"
                      name="exportTypeRadio"
                      value="group"
                      type="radio"
                      :disabled="network !== 'group' && network !== 'individual'"
                      @click="changeExportType"
                  />
                  <label for="group_type" class="radio_left w-[24rem]">
                    <div class="profile_picture d-flex align-items-starkot fb">
                      <div class="text_block">
                        <p class="text"><span class="bold">Group</span></p>
                        <p class="sub_text"
                        >Generate a combined report for all selected
                          accounts.</p
                        >
                      </div>
                    </div>
                  </label>
                </div>
                <div class="radio_input_image">
                  <input
                      id="individual_type"
                      name="exportTypeRadio"
                      value="individual"
                      type="radio"
                      :checked="network !== 'group'"
                      @click="changeExportType"
                  />
                  <label for="individual_type" class="radio_left w-[24rem]">
                    <div class="profile_picture d-flex align-items-start fb">
                      <div class="text_block">
                        <p class="text"><span class="bold">Individual</span></p>
                        <p class="sub_text"
                        >Generate individual reports for each selected
                          account.</p
                        >
                      </div>
                    </div>
                  </label>
                </div>
              </div>
          </div>

          <div class="input_field mt-3">
            <div class="d-flex">
              <label for="">Social Accounts</label>
              <div
                  class="checkbox_input_image checkboxx"
              >
                <input
                  id="select_all_accounts"
                  v-model="all_selected"
                  type="checkbox"
                  @click="handleSelectAll"
                />
                <label for="select_all_accounts" class="checkbox_right">
                </label>
              </div>
            </div>

            <div class="accounts container">
              <ul class="account_ul ks-cboxtags overflow-auto">
                <template v-for="(item, key) in getSocialAccounts" :key="key">
                  <li class="">
                    <input
                      v-if="network === 'group'"
                      :id="item._id"
                      v-model="selected_accounts"
                      type="checkbox"
                      :value="getAccountId(item)"
                    />
                    <input
                        v-else
                        :id="item._id"
                        v-model="individualSelectedAccounts"
                        type="checkbox"
                        :value="getAccountId(item)"
                    />
                    <label
                      :for="item._id"
                      v-tooltip="item.account_type === 'pinterest' ? item.type : ''"
                    >
                    <span class="relative mr-0.5">
                        <img
                        class="account_img"
                        :src="getAccountImage(item)"
                        alt=""
                        @error="
                            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                          "
                        />
                        <img
                          :src="getSocialImageRounded(item.account_type)"
                          alt=""
                          class="absolute -right-0.5 -bottom-0.5 !w-[1.125rem] !h-[1.125rem]"
                          />
                      </span>
                      <p class="account_p">{{ getAccountName(item) }}</p>
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="input_field mt-3 repeat_option">
            <label for="">Interval</label>
            <div class="d-flex align-items-start">
              <div class="radio_input_image">
                <input
                  id="week_interval"
                  v-model="getScheduledReportItem.interval"
                  checked
                  name="repeat_radio"
                  value="weekly"
                  type="radio"
                />
                <label for="week_interval" class="radio_left">
                  <div class="profile_picture d-flex align-items-starkot fb">
                    <div class="text_block">
                      <p class="text"><span class="bold">Weekly</span></p>
                      <p class="sub_text"
                        >Every Monday you'll be sent a report of the previous
                        week.</p
                      >
                    </div>
                  </div>
                </label>
              </div>

              <div class="radio_input_image">
                <input
                  id="month_interval"
                  v-model="getScheduledReportItem.interval"
                  name="repeat_radio"
                  type="radio"
                  value="monthly"
                />
                <label for="month_interval" class="radio_left">
                  <div class="profile_picture d-flex align-items-start fb">
                    <div class="text_block">
                      <p class="text"><span class="bold">Monthly</span></p>
                      <p class="sub_text"
                        >On 2nd of every month you'll be sent a report of the
                        previous month.</p
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="input_field">
            <div class="multi_select">
              <div class="d-flex mb-2 align-items-center">
                <label for="" class="mb-0"
                  >Email To (Separate emails with a comma)</label
                >
                <div class="checkbox_input_image ml-auto">
                  <input
                    id="copy_email_to_myself"
                    v-model="getScheduledReportItem.copy_email_to_myself"
                    type="checkbox"
                    @change="copyEmailMyself"
                  />
                  <label for="copy_email_to_myself" class="checkbox_left">
                    Send a copy to myself
                  </label>
                </div>
              </div>

              <div class="multi_input">
                <input
                  v-model="getScheduledReportItem.email"
                  type="text"
                  placeholder="Enter up to 25 email address..."
                  @keyup.enter="addEmailToList($event)"
                  @keyup.188="addEmailToList($event)"
                />
              </div>
              <div class="multi_tags">
                <ul>
                  <li
                    v-for="(item, key) in getScheduledReportItem.email_list"
                    :key="key"
                    class="green"
                  >
                    <span class="value">{{ item }}</span>
                    <span class="remove" @click="removeEmailFromList(key)"
                      >&times;</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="input_field mt-4">
            <button
              class="btn gradient_btn large_btn btn_loader loader_right"
              :disabled="getReportsServiceCalls.save"
              @click="scheduleReport"
            >
              <span>Schedule</span>
              <clip-loader
                v-if="getReportsServiceCalls.save"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accounts {
  max-height: 150px;
  overflow: auto;
  border: 1px solid #cbceda;
  padding: 0.5rem;
  border-radius: 5px;
}

.account_ul {
  display: flex;
  flex-wrap: wrap;
  cursor: default;
}

.account_img {
  font-size: 13px;
  height: 25px;
  width: 25px;
  border-radius: 2rem;
}

.account_p {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.account_i {
  color: #ffffff;
  border-radius: 0.6rem;
  position: relative;
  right: 0.6rem;
  top: 0.6rem;
  height: 0.8rem;
  width: 0.8rem;
}

.container {
}

ul.ks-cboxtags {
  list-style: none;
}

ul.ks-cboxtags li {
  display: flex;
}

ul.ks-cboxtags li label {
  display: flex;
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  align-items: center;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  margin-right: 0.2rem;
}

ul.ks-cboxtags li label::before {
  display: flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-size: 0.8rem;
  padding: 2px 6px 2px 2px;
  content: '✚';
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='radio']:checked + label::before,
ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
  content: '✔';
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='radio']:checked + label,
ul.ks-cboxtags li input[type='checkbox']:checked + label {
  border: 2px solid #4cdd99;
  color: #3a4557;
  transition: all 0.2s;
}

.select_all label {
  position: absolute;
  right: 2rem;
  top: 16rem;
}

.select_all label span {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 50%;
  transition: all linear 0.3s;
}

.select_all label span :after {
  content: 's';
  position: absolute;
  top: -1px;
  left: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 9px;
  width: 4px;
  transform: rotate(45deg);
  visibility: hidden;
}

.select_all input {
  display: none;
}

.select_all input :checked {
  background: #ccc;
}

.select_all input :after {
  visibility: visible;
}

ul.ks-cboxtags li input[type='radio'],
ul.ks-cboxtags li input[type='checkbox'] {
  opacity: 0;
}

.checkboxx {
  position: absolute;
  right: 2.5rem;
}
</style>
