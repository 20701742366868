<script>
import { mapGetters } from 'vuex'
import FeedViewCard from '@src/modules/planner/components/view/feed-view/FeedViewCard'
import plannerUtilsMixin from '@common/mixins/publish/planner-mixin'
import InfiniteLoading from 'vue-infinite-loading'
import { pusherSocketPublish } from '@common/lib/pusher'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import debounce from 'lodash/debounce'
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  components: {
    FeedViewCard,
    InfiniteLoading,
    VueEasyLightbox,
  },
  mixins: [plannerUtilsMixin],
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      channel: '',
      integrations: blogIntegrationsNames,
      previousTopOffset: '',
      scrollCount: '',
    }
  },
  computed: {
    ...mapGetters([
      'getPlannerLoaders',
      'getActiveWorkspace',
      'getSocialIntegrationsNames',
    ]),
  },
  watch: {
    scroll(val) {
      console.log('WATCH::val -> ', val)
      debounce(() => {
        this.handleTableHeight()
      }, 650)()
    },
  },
  created() {},
  mounted() {
    this.channel = pusherSocketPublish.subscribe(
      `comments_${this.getActiveWorkspace._id}`
    )
    this.bindPusherComments()
    // this.plannerListDatePickers()

    this.$nextTick(() => {
      this.handleTableHeight()
    })
    window.addEventListener('resize', () => {
      this.handleTableHeight()
    })
  },
  beforeUnmount() {
    this.channel.unbind('comment')
    this.channel.unsubscribe()

    window.removeEventListener('resize', () => {
      this.handleTableHeight()
    })
  },
  methods: {
    hasSpecificBlogSelection(item, type) {
      return item.blog_selection[type] && item.blog_selection[type].website
    },
    hasSpecificSocialSelection(item, type) {
      return item.account_selection[type] && item.account_selection[type].length
    },
    hasAccountSelection(item) {
      // If blog post
      if (item.blog_selection) {
        for (let i = 0; i < this.integrations.length; i++) {
          if (this.hasSpecificBlogSelection(item, this.integrations[i])) {
            console.log('has specific blog selection', this.integrations[i])
            return true
          }
        }
      }

      // If social post
      if (!item.blog_selection) {
        for (let i = 0; i < this.getSocialIntegrationsNames.length; i++) {
          if (
            this.hasSpecificSocialSelection(
              item,
              this.getSocialIntegrationsNames[i]
            )
          ) {
            console.log(
              'has specific social selection',
              this.getSocialIntegrationsNames[i]
            )
            return true
          }
        }
      }

      return false
    },
    handleTableHeight() {
      if (this.$el) {
        const offset = this.$el?.getBoundingClientRect()
        this.previousTopOffset = offset
        this.$el.style.height = `calc(100vh - ${
          scroll ? this.previousTopOffset : offset.top
        }px)`
      }
    },
    onInfinitePlannerFeedView($state) {

      console.log('infiniteHandler')
      this.$emit('handle-change', (state) => {
        console.log('infiniteHandler', state)

        if (state) {
          $state.complete()
        } else {
          $state.loaded()
        }
      })
    },
    /**
     * removes single plan based on planId and updates the plan count in sidebar.
     * @param planId
     */
    removePlan(planId) {
      console.log('METHOD::removePlan ~ planId -> ', planId)
      this.plans.splice(
        this.plans.findIndex((x) => x._id === planId),
        1
      )
      this.$emit('update-plans-count')
    },
    changeStatusWithComment(data, status) {
      console.log('METHOD::changeStatusWithComment ~ data -> ', status)
      if(status === 'approve'){
        this.$emit('approve-with-comment', data)
      }

      if(status === 'reject'){
        this.$emit('reject-with-comment', data)
      }
    },
  },
}
</script>

<template>
  <div class="planner_component py-12 overflow-y-auto h-full">
    <div class="planner_feed_view">

      <div v-if="loader" class="p-3">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>

      <template v-if="!loader">

        <template v-if="plans.length === 0">
          <content-available-state :type="'no_results'">
            <h2 slot="headline">No posts found</h2>
            <p slot="text"
              >No posts found for the selected filters and date-range.</p
            >
          </content-available-state>
        </template>
      </template>

      <template v-if="!loader">
        <template v-for="(item, index) in plans">
          <FeedViewCard
            v-if="item.feed_preview && hasAccountSelection(item)"
            :key="index"
            :index="index"
            :item="item"
            @update-plans-count="$emit('update-plans-count')"
            @remove-plan="removePlan"
            @change-status-with-comment="changeStatusWithComment"
          ></FeedViewCard>
        </template>
      </template>

      <InfiniteLoading
        v-if="plans.length !== totalItems && !loader"
        ref="infiniteLoading"
        :distance="plans.length * 40"
        @infinite="onInfinitePlannerFeedView"
      >
        <template v-slot:spinner>
          <span class="pt-3 d-block">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>
        </template>
      </InfiniteLoading>
    </div>
  </div>
</template>
