<template>
  <div>
    <!-- Summary Section -->
    <AnalyticsSummaryBlock
      heading="Facebook Page Performance Summary"
      subtitle="View your key profile performance metrics from the reporting period."
    >
      <template v-if="summary_loader">
        <div class="row">
          <div
            v-for="i in 8"
            :key="i"
            class="col-6 col-sm-3"
            :class="{ 'mb-4': i < 4 }"
          >
            <SkeletonBox :width="'100%'" height="92px" radius="10px" />
          </div>
        </div>
      </template>
      <div v-else class="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-4">
        <template v-for="(value, key, i) in summary_values" :key="key">
          <div
            class="summary-card-wrapper w-full min-w-fit"
            :class="{ 'mb-4': i < 5 }"
          >
            <template v-if="key === 'positive_sentiment'">
              <SummaryCard
                :title="value"
                :total="
                  summary.current['positive_sentiment'] -
                  summary.current['negative_sentiment']
                "
                variant="group"
                :positive="summary.current['positive_sentiment']"
                :negative="summary.current['negative_sentiment']"
              >
              </SummaryCard>
            </template>
            <template v-else-if="key === 'page_positive_feedback'">
              <SummaryCard
                :title="value"
                :total="
                  summary.current['page_positive_feedback'] -
                  summary.current['page_negative_feedback']
                "
                variant="group"
                :positive="summary.current['page_positive_feedback']"
                :negative="summary.current['page_negative_feedback']"
              >
              </SummaryCard>
            </template>
            <template v-else>
              <SummaryCard
                :title="value"
                :total="summary.current[key] ? summary.current[key] : 0"
                :reach="Math.abs(getReach(key, summary)) + '%'"
                :reach-up="getReachUp(key, summary)"
              ></SummaryCard>
            </template>
          </div>
        </template>
      </div>
    </AnalyticsSummaryBlock>

    <!-- Audience Growth Section -->
    <div class="d-flex mb-[1.5rem]">
      <div class="col-9 p-0">
        <section
          class="mt-0 mr-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Facebook Audience Growth </h2>
            <p>See how your audience grew during the reporting period.</p>
          </div>
          <template v-if="audience_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="audience.audience_growth.show_data > 0"
              :categories="audience.audience_growth.buckets"
              :series="getAudienceGrowthSeries(audience)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Audience Growth Rollup</h2>
          <template v-if="audience_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template v-for="(value, key) in audience_rollup_values" :key="key">
              <SummaryCard
                :title="value"
                :total="audience.audience_growth_rollup.current[key]"
                :reach="
                  Math.abs(getReach(key, audience.audience_growth_rollup)) + '%'
                "
                :reach-up="getReachUp(key, audience.audience_growth_rollup)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <!-- Publishing Behavior Section -->
    <div class="d-flex">
      <div class="col-9 p-0">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Facebook Publishing Behavior </h2>
            <p
              >View the different types of posts you published during the
              selected time period.</p
            >
          </div>
          <template v-if="publishing_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <ColumnCrossHairChart
              v-if="publishing_behaviour.publishing_behaviour.show_data > 0"
              :series="getPublishingBehaviourSeries(publishing_behaviour)"
              :categories="publishing_behaviour.publishing_behaviour.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section>
          <h2>Publishing Behavior by Content Type</h2>
          <template v-if="publishing_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <div style="height: 432px; overflow: scroll">
            <SummaryCard
              :title="'Total Posts Published'"
              :total="
                publishing_behaviour.publishing_behaviour_rollup.current[
                  'total'
                ]
              "
              :reach="
                Math.abs(
                  getReach(
                    'total',
                    publishing_behaviour.publishing_behaviour_rollup,
                  ),
                ) + '%'
              "
              :reach-up="
                getReachUp(
                  'total',
                  publishing_behaviour.publishing_behaviour_rollup,
                )
              "
              variant="rollup"
            />
            <div class="flex flex-col flex-wrap justify-between">
              <template
                v-for="(value, key) in Object.keys(
                  publishing_behaviour_rollup_values,
                )
                  .slice(1, 10)
                  .reduce((result, key) => {
                    result[key] = publishing_behaviour_rollup_values[key]
                    return result
                  }, {})"
                :key="key"
              >
                <SummaryCard
                  :title="value"
                  :total="
                    publishing_behaviour.publishing_behaviour_rollup.current[
                      key
                    ]
                  "
                  :reach="
                    Math.abs(
                      getReach(
                        key,
                        publishing_behaviour.publishing_behaviour_rollup,
                      ),
                    ) + '%'
                  "
                  :reach-up="
                    getReachUp(
                      key,
                      publishing_behaviour.publishing_behaviour_rollup,
                    )
                  "
                  variant="mini"
                />
              </template>
            </div>
          </div>
          </template>
        </section>
      </div>
    </div>

    <!-- facebook Top Posts -->
    <section style="margin-top: 3.2rem">
      <h2>Facebook Top Posts</h2>
      <p
        >Review your top posts published during the selected time period, based
        on the post's lifetime performance.</p
      >
      <template v-if="top_post_loader">
        <div class="row">
          <template v-for="i in 3" :key="i">
            <div class="col-4">
              <div class="analytics-post-card">
                <div class="analytics-post-card__header">
                  <div class="analytics-post-card__header-left">
                    <div class="analytics-post-card__header-img">
                      <SkeletonBox
                        height="2.8rem"
                        width="2.8rem"
                        radius="50%"
                      />
                    </div>
                    <div class="analytics-post-card__header-detail">
                      <div
                        class="d-flex"
                        style="flex-direction: column; margin-left: 10px"
                      >
                        <div class="mb-1">
                          <SkeletonBox width="100px" radius=".2rem" />
                        </div>
                        <SkeletonBox radius=".2rem" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="analytics-post-card__header-right"
                    style="filter: grayscale(100%); opacity: 0.6"
                  >
                    <!-- Dynamic Icon bases on props -->
                    <i class="icon-facebook_icon"></i>
                  </div>
                </div>

                <div class="analytics-post-card__content">
                  <div class="mb-1">
                    <SkeletonBox width="100%" radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox width="100%" height="150px" radius=".2rem" />
                  </div>
                </div>

                <ul class="analytics-post-card__content-ele">
                  <li v-for="i in 4" :key="i">
                    <SkeletonBox width="40%" radius=".2rem" />
                    <SkeletonBox width="1.1rem" radius=".2rem" />
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify_center analytics-post-card-row">
          <template v-if="top_posts.length <= 0">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no_post_found.svg"
                alt=""
              />
              <p
                >You do not have any posts published in the selected time
                period.</p
              >
            </div>
          </template>
          <template v-for="i in 3" v-else :key="i">
            <div :class="top_posts.length >= 3 ? 'col-4' : 'col-6'">
              <template v-if="top_posts.length >= i">
                <PostCard :post="top_posts[i - 1]" platform_type="facebook" />
              </template>
              <template v-else>
                <div class="analytics-post-card">
                  <div class="analytics-post-card__header">
                    <div class="analytics-post-card__header-left">
                      <div class="analytics-post-card__header-img">
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          height="2.8rem"
                          width="2.8rem"
                          radius="50%"
                        />
                      </div>
                      <div class="analytics-post-card__header-detail">
                        <div
                          class="d-flex"
                          style="flex-direction: column; margin-left: 10px"
                        >
                          <div class="mb-1"> No More Post </div>
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            radius=".2rem"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="analytics-post-card__header-right"
                      style="filter: grayscale(100%); opacity: 0.6"
                    >
                      <!-- Dynamic Icon bases on props -->
                      <i class="far fa-external-link-square-alt"></i>
                    </div>
                  </div>

                  <div class="analytics-post-card__content">
                    <div class="mb-1">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="100%"
                        radius=".2rem"
                      />
                    </div>
                    <div class="mb-2">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        radius=".2rem"
                      />
                    </div>
                    <div class="mb-2">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="100%"
                        height="260px"
                        radius=".2rem"
                      />
                    </div>
                  </div>

                  <ul class="analytics-post-card__content-ele">
                    <li v-for="i in 6" :key="i">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="40%"
                        radius=".2rem"
                      />
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="1.1rem"
                        radius=".2rem"
                      />
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </section>

    <section content="analytics-users-status">
      <h2>Facebook Active Users</h2>
      <p
        >Review your active users as of the last day of the reporting period.</p
      >

      <div class="grid-flex">
        <div class="cell-12 cell-xl-6">
          <div class="analytics-users-status__block">
            <h3>Active Users by Hours</h3>

            <!-- Chart -->
            <div
              class="h-chart h-chart-section-wrapper mt-4 flex-center-center"
              style="height: 90%"
            >
              <template v-if="active_users_loader">
                <div class="h-chart flex-center-center" style="height: 90%">
                  <SkeletonBox
                    height="250px"
                    width="300px"
                    type="chart"
                    radius="4px"
                  />
                </div>
              </template>
              <template v-else>
                <LineBasicChart
                  v-if="active_users.active_users_hours.highest_value > 0"
                  :categories="active_users.active_users_hours.buckets"
                  :series="getActiveUsersSeries(active_users, 'hours')"
                />
                <div v-else class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="active_users.active_users_hours.highest_value !== 0"
            class="analytics-users-status__block"
          >
            <p
              >Best hour to post is
              <b>{{ active_users.active_users_hours.highest_hour }}:00</b> with
              highest value
              <b>{{ active_users.active_users_hours.highest_value }}</b></p
            >
          </div>
        </div>
        <div class="cell-12 cell-xl-6">
          <div class="analytics-users-status__block">
            <h3>Active Users by Days</h3>

            <!-- Chart -->
            <div
              class="h-chart h-chart-section-wrapper mt-4 flex-center-center"
              style="height: 90%"
            >
              <!-- LOADER -->
              <template v-if="active_users_loader">
                <div class="h-chart flex-center-center" style="height: 90%">
                  <SkeletonBox
                    height="250px"
                    width="300px"
                    type="chart"
                    radius="4px"
                  />
                </div>
              </template>
              <template v-else>
                <LineBasicChart
                  v-if="highest_value > 0"
                  :categories="
                    getActiveUsersWeekDaysSeries(active_users, 'days')
                  "
                  :series="getActiveUsersSeries(active_users, 'days')"
                />
                <div v-else class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="highest_value !== 0" class="analytics-users-status__block">
            <p
              >Best day to post is <b>{{ highest_day }}</b> with highest value
              <b>{{ highest_value }}</b></p
            >
          </div>
        </div>
      </div>
    </section>
    <!--impressions-->
    <div class="d-flex mb-10">
      <div class="col-9 p-0">
        <section
          class="mr-0 mt-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Facebook Impressions</h2>
            <p
              >Review how your content was seen by the Facebook community during
              the reporting period.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="impressions_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="impressions.impressions.show_data > 0"
              :series="getImpressionsSeries(impressions)"
              :categories="impressions.impressions.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Impressions Rollup</h2>
          <template v-if="impressions_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template
              v-for="(value, key) in impressions_rollup_values"
              :key="key"
            >
              <SummaryCard
                :title="value"
                :total="
                  isNaN(impressions.impressions_rollup.current[key])
                    ? impressions.impressions_rollup.current[key]
                    : impressions.impressions_rollup.current[key]
                "
                :reach="
                  Math.abs(getReach(key, impressions.impressions_rollup)) + '%'
                "
                :reach-up="getReachUp(key, impressions.impressions_rollup)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <!--impressions-->
    <div class="d-flex mb-10">
      <div class="col-9 p-0">
        <section
          class="mr-0 mt-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Facebook Reach</h2>
            <p
              >Review how your content has reached the Facebook community during
              the reporting period.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="reach_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="reach.reach.show_data > 0"
              :series="getReachSeries(reach)"
              :categories="reach.reach.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Reach Rollup</h2>
          <template v-if="reach_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template
              v-for="(value, key) in reach_rollup_values"
              :key="key"
            >
              <SummaryCard
                :title="value"
                :total="
                  isNaN(reach.reach_rollup.current[key])
                    ? reach.reach_rollup.current[key]
                    : reach.reach_rollup.current[key]
                "
                :reach="
                  Math.abs(getReach(key, reach.reach_rollup)) + '%'
                "
                :reach-up="getReachUp(key, reach.reach_rollup)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <!--impressions-->
    <div class="d-flex mb-10">
      <div class="col-9 p-0">
        <section
          class="mr-0 mt-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <div class="flex items-center gap-2 mb-2">
              <h2>Facebook Reels Performance By</h2>
              <CstDropdown
                dropdown-placement="bottom"
                container-classes="!max-h-96"
                button-classes="flex !w-36 !h-[30px] !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !px-0"
              >
                <template v-slot:arrow>
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </template>
                <template v-slot:selected>
                  <h2 class="!text-dark-cs-800">
                    {{ selectedReelMetric }}
                  </h2>
                </template>
                <template v-slot>
                  <template
                    v-for="(item, index) in getReelDropdownOptions()"
                    :key="`activity_type_${index}`"
                  >
                  <div class="max-h-14">
                    <CstDropdownItem @click="selectedReelMetric = item">
                      <p class="text-gray-900 text-sm !mb-0 !py-0.5">{{ item }}</p>
                    </CstDropdownItem>
                  </div>
                  </template>
                </template>
              </CstDropdown>
            </div>
            <p
              >Review the performance of reels posted during the selected time period in terms of initial plays,
               watch time, and engagement.</p
            >

          </div>
          <!--    LOADER      -->
          <template v-if="reels_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="reels.reels.show_data > 0"
              :key="selectedReelMetric"
              :series="getReelsSeries(reels, selectedReelMetric)"
              :categories="reels.reels.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Reels Rollup</h2>
          <template v-if="reels_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template
              v-for="(value, key) in reels_rollup_values"
              :key="key"
            >
              <SummaryCard
                :title="value"
                :total="
                  isNaN(reels.reels_rollup.current[key])
                    ? reels.reels_rollup.current[key]
                    : reels.reels_rollup.current[key]
                "
                :reach="
                  Math.abs(getReels(key, reels.reels_rollup)) + '%'
                "
                :reach-up="getReelsUp(key, reels.reels_rollup)"
                :tooltip-custom="getReelsRollupTooltips(value)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <section class="analytics-performance-metrics h-chart-section-wrapper mt-20">
      <h2>Facebook Video Performance</h2>
      <p>View your aggregate video performance during the reporting period.</p>

      <div class="row">
        <div class="col-6">
          <div
            class="analytics-users-status__block"
            :class="{
              'analytics-users-status__block--video-pie': !video_loader,
            }"
          >
            <!--            <div class="analytics-performance-metrics__header">-->
            <!--              <i class="fas fa-eye"></i>-->
            <!--              <h3>View Metrics</h3>-->
            <!--            </div>-->

            <div class="flex-center-center" style="flex-direction: column">
              <template v-if="video_loader">
                <div class="h-chart flex-center-center" style="height: 90%">
                  <SkeletonBox
                    :innerfill="true"
                    :width="'240px'"
                    :height="'240px'"
                    radius="50%"
                  />
                </div>
              </template>
              <template v-else>
                <div
                  v-if="getVideoData(video_analytics, 'engagement')[1][1] <= 0"
                  class="flex-center-center"
                  style="height: 90%"
                >
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
                <PieInnerChart
                  v-else
                  :chart-ref="`video-overall-analytics-pie`"
                  :colors="getVideoColors('engagement')"
                  :data="getVideoData(video_analytics, 'engagement')"
                  :series-name="'Engagement and Views'"
                  :width="270"
                  :height="270"
                  @mounted="getPieRef"
                />
                <div class="d-flex justify_center analytics-pie-legends">
                  <template
                    v-for="(obj, i) in legends['video-overall-analytics-pie']"
                    :key="i"
                  >
                    <div
                      class="analytics-pie-legends__detail-item"
                      @click="legendClick(obj, obj?.name !== 'No Data Found')"
                    >
                      <div
                        class="analytics-pie-legends__detail-label v-metrics"
                      >
                        <span
                          :style="{ borderColor: obj?.color }"
                          class="analytics-pie-legends__detail-label-icon"
                        ></span>
                        {{
                          capitalizeFirstLetter(
                            obj?.name !== null ? obj?.name : '',
                          )
                        }}
                      </div>
                      <div
                        v-if="obj?.name !== 'No Data Found'"
                        class="analytics-pie-legends__detail-value"
                      >
                        {{ obj?.y?.toLocaleString('en-US', {
                          notation: 'compact',
                          compactDisplay: 'short',
                        }) }}
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div
            class="analytics-users-status__block"
            :class="{
              'analytics-users-status__block--video-pie': !video_loader,
            }"
          >
            <!--            <div class="analytics-performance-metrics__header">-->
            <!--              <i class="fas fa-eye"></i>-->
            <!--              <h3>Viewing Breakdown</h3>-->
            <!--            </div>-->
            <!--            {{getVideoData(video_analytics, 'play')[0][1]+getVideoData(video_analytics, 'play')[1][1]+getVideoData(video_analytics, 'organic')[0][1]+getVideoData(video_analytics, 'organic')[1][1]<=0}}-->
            <div
              v-if="
                getVideoData(video_analytics, 'play')[0][1] +
                  getVideoData(video_analytics, 'play')[1][1] +
                  getVideoData(video_analytics, 'organic')[0][1] +
                  getVideoData(video_analytics, 'organic')[1][1] <=
                0
              "
              class="flex-center-center"
              style="height: 90%"
            >
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12">
                <div
                  class="justify-content-center"
                  style="display: flex; align-items: center"
                >
                  <template v-if="video_loader">
                    <div
                      class="h-chart flex-center-center"
                      style="height: 90%; min-height: 200px"
                    >
                      <SkeletonBox
                        :innerfill="true"
                        :width="'140px'"
                        :height="'140px'"
                        radius="50%"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="
                        getVideoData(video_analytics, 'organic')[0][1] +
                          getVideoData(video_analytics, 'organic')[1][1] <=
                        0
                      "
                      class="flex-center-center"
                      style="height: 90%"
                    >
                      <div class="analytics-no-data-found">
                        <img
                          src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                          alt=""
                        />
                        <p> No Data Found!</p>
                      </div>
                    </div>
                    <PieInnerChart
                      v-else
                      :chart-ref="`video-organic-analytics-pie`"
                      :colors="getVideoColors('organic')"
                      :data="getVideoData(video_analytics, 'organic')"
                      :series-name="'Paid and Organic Views'"
                      :width="200"
                      :height="200"
                      @mounted="getPieRef"
                    />
                    <div
                      class="
                        d-flex
                        justify_center
                        analytics-pie-legends
                        video-overall-analytics-pie
                      "
                    >
                      <template
                        v-for="(obj, i) in legends[
                          'video-organic-analytics-pie'
                        ]"
                        :key="i"
                      >
                        <div
                          class="analytics-pie-legends__detail-item"
                          @click="
                            legendClick(obj, obj?.name !== 'No Data Found')
                          "
                        >
                          <div
                            class="
                              analytics-pie-legends__detail-label
                              v-metrics
                            "
                          >
                            <span
                              :style="{ borderColor: obj?.color }"
                              class="analytics-pie-legends__detail-label-icon"
                            >
                            </span>
                            {{
                              capitalizeFirstLetter(
                                obj?.name !== null ? obj?.name : '',
                              )
                            }}
                          </div>
                          <div
                            v-if="obj?.name !== 'No Data Found'"
                            class="analytics-pie-legends__detail-value"
                          >
                            {{ obj?.y?.toLocaleString('en-US', {
                              notation: 'compact',
                              compactDisplay: 'short',
                            }) }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>

              <div class="col-12">
                <div
                  class="justify-content-center"
                  style="display: flex; align-items: center"
                >
                  <template v-if="video_loader">
                    <div
                      class="h-chart flex-center-center"
                      style="height: 90%; min-height: 200px"
                    >
                      <SkeletonBox
                        :innerfill="true"
                        :width="'140px'"
                        :height="'140px'"
                        radius="50%"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="
                        getVideoData(video_analytics, 'play')[0][1] +
                          getVideoData(video_analytics, 'play')[1][1] <=
                        0
                      "
                      class="flex-center-center"
                      style="height: 90%"
                    >
                      <div class="analytics-no-data-found">
                        <img
                          src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                          alt=""
                        />
                        <p> No Data Found!</p>
                      </div>
                    </div>
                    <PieInnerChart
                      v-else
                      :chart-ref="`video-play-analytics-pie`"
                      :colors="getVideoColors('play')"
                      :data="getVideoData(video_analytics, 'play')"
                      :series-name="'Video Plays'"
                      :width="200"
                      :height="200"
                      @mounted="getPieRef"
                    />
                    <div
                      class="
                        d-flex
                        justify_center
                        analytics-pie-legends
                        video-overall-analytics-pie
                      "
                    >
                      <template
                        v-for="(obj, i) in legends['video-play-analytics-pie']"
                        :key="i"
                      >
                        <div
                          class="analytics-pie-legends__detail-item"
                          @click="
                            legendClick(obj, obj?.name !== 'No Data Found')
                          "
                        >
                          <div
                            class="
                              analytics-pie-legends__detail-label
                              v-metrics
                            "
                          >
                            <span
                              :style="{ borderColor: obj?.color }"
                              class="analytics-pie-legends__detail-label-icon"
                            >
                            </span>
                            {{
                              capitalizeFirstLetter(
                                obj?.name !== null ? obj?.name : '',
                              )
                            }}
                          </div>
                          <div
                            v-if="obj?.name !== 'No Data Found'"
                            class="analytics-pie-legends__detail-value"
                          >
                            {{ obj?.y?.toLocaleString('en-US', {
                              notation: 'compact',
                              compactDisplay: 'short',
                            }) }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="d-flex">
      <div class="col-9 p-0">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Facebook Engagement</h2>
            <p
              >See how people are engaging with your posts during the reporting
              period.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="engagement_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="engagement.engagement.show_data > 0"
              :series="getEngagementSeries(engagement)"
              :categories="engagement.engagement.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section>
          <h2>Engagements Rollup</h2>
          <template v-if="engagement_loader">
            <div v-for="i in 5" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template
              v-for="(value, key) in engagement_rollup_values"
              :key="key"
            >
              <SummaryCard
                :title="value"
                :total="
                  isNaN(engagement.engagement_rollup.current[key])
                    ? engagement.engagement_rollup.current[key]
                    : engagement.engagement_rollup.current[key]
                "
                :reach="
                  Math.abs(getReach(key, engagement.engagement_rollup)) + '%'
                "
                :reach-up="getReachUp(key, engagement.engagement_rollup)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import PostCard from '@src/modules/analytics/views/common/PostCard'

// Charts
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'

// SkeletonBox
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { mapActions } from 'vuex'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import facebookMixin from '@src/modules/analytics/components/common/series/facebookMixin'
import debounce from 'lodash.debounce'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'

export default {
  name: 'OverviewTab',
  components: {
    AnalyticsSummaryBlock,
    SummaryCard,
    PostCard,
    SkeletonBox,
    SplineBasicChart,
    LineBasicChart,
    ColumnCrossHairChart,
    PieInnerChart,
    CstDropdown,
    CstDropdownItem,
  },
  mixins: [analyticsUtilsMixin, facebookMixin],
  // eslint-disable-next-line vue/require-prop-types, vue/prop-name-casing
  props: ['account', 'date', 'previous_date', 'getOverviewLoader', 'fan_count'],
  emits: ['fan-count'],
  data() {
    return dataValues().facebook.overview
      },
  computed: {
    getFetchingData() {
      return (
        this.summary_loader ||
        this.audience_loader ||
        this.publishing_loader ||
        this.top_post_loader ||
        this.active_users_loader ||
        this.impressions_loader ||
        this.reach_loader ||
        this.engagement_loader ||
        this.video_loader ||
        this.reels_loader
      )
    },
  },
  watch: {
    account(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    getFetchingData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOverviewLoader(newVal)
      }
    },
    date(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    previous_date(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions(['getAnalyzeService']),
    legendClick(point, flag) {
      if (point?.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef(chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    initialize() {
        this.getSummary();
        this.getAudienceGrowth();
        this.getPublishingBehaviour();
        this.getTopPosts();
        this.getActiveUsers();
        this.getImpressions();
        this.getReachData();
        this.getEngagement();
        this.getVideo();
        this.getReelsAnalytics();
      },
    async getSummary() {
      if (this.summary_loader === true) {
        return
      }
      this.summary_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'summary',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        if ('current' in response.overview) {
          this.summary = response.overview
          this.$emit('fan-count', response?.overview?.current?.fan_count)
        }
      }
      this.summary_loader = false
    },
    async getAudienceGrowth() {
      if (this.audience_loader === true) {
        return
      }
      this.audience_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'audience_growth',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })

        this.audience.audience_growth = response.audience_growth
        if ('fan_count' in response.audience_growth_rollup.current) {
          this.audience.audience_growth_rollup = response.audience_growth_rollup
        }
        else{
          this.audience.audience_growth_rollup = { current: {
            avg_page_fans_by_like: 0,
            avg_page_fans_by_unlike: 0,
            fan_count: 0,
            talking_about_count: 0,
            doc_count:0
          }
          }
        }
      }
      this.audience_loader = false
    },

    async getPublishingBehaviour() {
      if (this.publishing_loader === true) {
        return
      }
      this.publishing_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'publishing_behaviour',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })

        this.publishing_behaviour.publishing_behaviour =
          response.publishing_behaviour
        this.publishing_behaviour.publishing_behaviour_rollup =
          response.publishing_behaviour_rollup
      }
      this.publishing_loader = false
    },

    async getTopPosts() {
      if (this.top_post_loader === true) {
        return
      }
      this.top_post_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'top_posts',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        this.top_posts = response.top_posts
      }
      this.top_post_loader = false
    },
    async getActiveUsers() {
      if (this.active_users_loader === true) {
        return
      }
      this.active_users_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'active_users',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        this.active_users = response.active_users
        this.highest_day = response.active_users.highest_day
        this.highest_value = response.active_users.highest_value
      }
      this.active_users_loader = false
    },
    async getImpressions() {
      if (this.impressions_loader === true) {
        return
      }
      this.impressions_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'impressions',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        if ('impressions_rollup' in response) {
          this.impressions.impressions_rollup = response.impressions_rollup
        }
        this.impressions.impressions = response.impressions
      }
      this.impressions_loader = false
    },
    async getReachData() {
      if (this.reach_loader === true) {
        return
      }
      this.reach_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'reach',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        if ('reach_rollup' in response) {
          this.reach.reach_rollup = response.reach_rollup
        }
        this.reach.reach = response.reach
      }
      this.reach_loader = false
    },
    async getReelsAnalytics() {
      if (this.reels_loader === true) {
        return
      }
      this.reels_loader = true
      try{
        if ('facebook_id' in this.account) {
          const response = await this.getAnalyzeService({
            facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
            date: this.date,
            section: 'reels',
            tab: 'overview',
            type: 'facebook',
            timezone: this.getActiveWorkspace.timezone,
            previous_date: this.previous_date,
            workspace_id: this.getActiveWorkspace._id,
          })
          if ('reels_rollup' in response) {
            this.reels.reels_rollup = response.reels_rollup
          }
          this.reels.reels = response.reels
        }
      }
      catch(e){
        console.log(e)
      }
      finally{
        this.reels_loader = false
      }
    },
    async getEngagement() {
      if (this.engagement_loader === true) {
        return
      }
      this.engagement_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'engagement',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        if (response.engagement.engagement.show_data > 0) {
          this.engagement.engagement = response.engagement.engagement
          if ('engagement_rollup' in response.engagement) {
            this.engagement.engagement_rollup =
              response.engagement.engagement_rollup
          }
        }
        else{
          this.engagement.engagement.show_data = 0
          this.engagement.engagement_rollup = { current: {
            total_engagement: 0,
            count: 0,
            shares: 0,
            comments: 0,
            post_clicks:0,
            reactions: 0
          }
        }
      }
      this.engagement_loader = false
    }
    },
    async getVideo() {
      if (this.video_loader === true) {
        return
      }
      this.video_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'video_analytics',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        this.video_analytics = response.video_insights
      }
      this.video_loader = false
    },
  },

}
</script>
