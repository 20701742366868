<template>
  <b-modal
    id="pull_history"
    modal-class="pull_history"
    class=""
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <h4>Automation History</h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide('pull_history')"
        >&times;
      </button>
    </div>

    <div class="modal_body">
      <!--
            <div class="top_heading_text">
                <p>Following are the history for your automation campaign. You can view the complete activity.</p>
            </div>-->

      <div class="table_box">
        <table class="table-sm">
          <thead>
            <tr>
              <th>Date</th>
              <th style="text-align: center">Message</th>
            </tr>
          </thead>

          <tbody>
            <template v-if="getRSSAutomationPullHistory.length > 0">
              <tr v-for="item in getRSSAutomationPullHistory" :key="item._id">
                <td>
                  {{
                    getWorkspaceTimeZoneTime(
                      item.created_at,
                      'dddd, MMM DD, YYYY, hh:mm A',
                    )
                  }}
                </td>
                <td class="text-center">
                  {{ item.description }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="2" class="text-center">
                  <p class="no_data_padding"
                    >You automation campaign has not history.</p
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getRSSAutomationPullHistory'])
  }
}
</script>

<style lang="less" scoped></style>
