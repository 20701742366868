<script>
// libraries
import {computed, defineComponent, inject, watch} from 'vue'

export default defineComponent({
  name: 'TableData',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    day: {
      type: String,
      default: '',
    },
    addSlotModal: {
      type: Function,
      default: () => {
      },
    },
    slots: {
      type: Array,
      default: () => []
    },
    removeSlotModal: {
      type: Function,
      default: () => {
      }
    },
    editSlotModal: {
      type: Function,
      default: () => {
      }
    }
  },
  setup(props) {
    const root = inject('root')
    const dataDateTime = computed(() => {
      return `${props.day}-${
          props.index > 12 ? props.index % 12 : props.index === 12 ? 0 : props.index
      }-${props.index >= 12 ? 'PM' : 'AM'}`
    })
    const findSlot = computed(() => {
      return props.slots.filter((item) => {
        const itemDateTime = `${item.day?.toString()}-${item.hour}-${item.period}`;
        return dataDateTime.value === itemDateTime;
      });
    });
    const editSlot = (item) => {
      if (item.hour === 0) {
        item.hour = 12
      }
      props.editSlotModal(item)
    }
    const removeSlot = (item) => {
      props.removeSlotModal({
        slotId: item._id,
        weekday: item.day,
        index: item.idx,
        categoryId: item.category._id,
      })
    }
    return {
      dataDateTime,
      root,
      findSlot,
      removeSlot,
      editSlot
    }
  }
})
</script>

<template>
  <td
      class="relative h-[2rem] box-content border border-2 border-[#2a8bc3] align-top lg:px-2 sm:px-1"
      :data-date-time="`${day}-${
      index > 12 ? index % 12 : index === 12 ? 0 : index
    }-${index >= 12 ? 'PM' : 'AM'}`"
  >
    <div class="all-slots relative z-10">
      <template v-for="(slot, slotIndex) in findSlot" :key="`slot_${slotIndex}`">
        <div
            class="group custom-slots w-full rounded-t-lg rounded-b-lg border border-solid border-[#70707045] mt-2 last:mb-[38px]">
          <div
              class="edit-delete-slot lg:flex lg:justify-between lg:flex-row sm:flex-col sm:justify-center sm:align-center lg:px-2 lg:py-2.5 sm:px-1 sm:py-1.5  lg:h-[1.875rem] sm:h-[3.5rem] border border-solid border-top-0 border-left-0 border-right-0 border-bottom-2">
              <span class="text-[#3a4557ba] text-[0.7rem]">   {{
                  root.$filters.addZeroToStart(slot.hour)
                }}:{{ root.$filters.addZeroToStart(slot.minute) }}
          {{ slot.period }}</span>
            <div class="hidden group-hover:block">
                 <span
                     title="Edit Slot"
                     class="edit-slot-button cursor-pointer icon-edit-cs text-xs remove_icon ml-auto text-[#3a4557ba] hover:text-[#ff4500]"
                     @click="() => editSlot(slot)"
                 ></span>
              <span
                  title="Remove Slot"
                  class="remove-slot-button cursor-pointer icon-delete-cs text-xs remove_icon ml-1 text-[#3a4557ba] hover:text-[#ff4500]"
                  @click="() => removeSlot(slot)"
              ></span>
            </div>
          </div>
          <div
              class="w-full px-2 py-2.5 text-center cursor-pointer edit-slot-bar rounded-b-lg"
              :class="[slot.category.color]"
              @click="() => editSlot(slot)">
              <span
                  class="text-white font-medium text-[0.688rem] capitalize overflow-ellipsis"
                  :title="slot.category.color">{{ slot.category.name || '--' }}</span>

          </div>
        </div>
      </template>
    </div>
    <div
        class="group custom-slot-btn cursor-pointer absolute w-full bottom-0 h-full left-1.5"
        @click="
        addSlotModal({
          hour: index > 12 ? index % 12 : index,
          minutes: 0,
          period: index >= 12 ? 'PM' : 'AM',
          day: day,
        })
      "
    >
      <button
          class="h-[2rem] absolute bottom-0 hidden group-hover:block duration-300 bg-[#0068e5] text-white w-11/12 rounded-md border-0"
      >
        Add a slot
      </button>
    </div>
  </td>
</template>
