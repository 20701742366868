<template>
  <div class="articles-content-container">
    <template v-if="!load_required_items">
      <div v-if="posts.length" class="articles-content-container__inner">
        <template v-for="(post, postIndex) in posts" :key="`post_${postIndex}`">
          <div
            v-if="getDiscoveryView === 'grid'"
            :key="'grid_' + post._source.id"
            class="article-grid-view-container"
          >
            <GridViewItem :post="post" :index="postIndex"></GridViewItem>
          </div>
          <div
            v-if="getDiscoveryView === 'list'"
            :key="'grid_' + post._source.id"
            class="article-list-view-container"
          >
            <ListViewItem :post="post" :index="postIndex"></ListViewItem>
          </div>
        </template>
      </div>

      <template v-if="posts.length === 0 && !loaders.retrieve_search">
        <ArticleSearchDidYouMeanText
          :did_you_mean_text="did_you_mean_text"
        ></ArticleSearchDidYouMeanText>
      </template>
      <InfiniteLoading
        ref="infiniteWebArticleSearch"
        :identifier="infiniteId"
        @infinite="webArticlesSearch"
      >
        <span slot="spinner" class="d-block pt-3 pb-4">
          <beat-loader :color="'#436aff'"></beat-loader>
        </span>
        <div slot="no-more" class="not_found_text">
          <div v-if="total_results === 0" class="no_data_found_content">
            <content-available-state :type="'no_results'">
              <h2 slot="headline">No more results available.</h2>
              <p slot="text"
                >Please try a different search string or change search
                filters</p
              >
            </content-available-state>
          </div>
        </div>

        <div slot="no-results">
          <div v-if="!did_you_mean_text" class="no_data_found_content">
            <content-available-state :type="'no_results'">
              <h2 slot="headline">No results found.</h2>
              <p slot="text"
                >Please try a different search string or change search
                filters</p
              >
            </content-available-state>
          </div>
        </div>
      </InfiniteLoading>
    </template>
    <router-view v-if="main_view_loaded"></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import InfiniteLoading from 'vue-infinite-loading'
import { EventBus } from '@common/lib/event-bus'
import { discoverTypes } from '@src/modules/discovery/store/mutation-types.js'
import ArticleSearchDidYouMeanText from '@src/modules/discovery/components/search/components/suggestions/content/ArticleSearchDidYouMeanText.vue'
import GridViewItem from '@src/modules/discovery/components/common/components/GridViewItem.vue'

const ListViewItem = () =>
  import('@src/modules/discovery/components/common/components/ListViewItem.vue')

const getDefaultArticleSearchFilters = () => {
  return {
    sort: 'most_shared',
    from_date: moment.utc().subtract(90, 'day').format('YYYY-MM-DD'),
    to_date: moment.utc().format('YYYY-MM-DD'),
    region: 'world',
    language: 'en',
    page: 1,
    limit: 20,
    search_type: 'curated',
    query: '',
    topic: null,
  }
}
export default {
  components: {
    InfiniteLoading,
    GridViewItem,
    ListViewItem,
    ArticleSearchDidYouMeanText,
  },
  data() {
    return {
      filters: getDefaultArticleSearchFilters(),
      related_topics_suggestions: [],
      posts: [],
      total_results: 0,
      infiniteWebSearch: '',
      infiniteId: +new Date(),
      load_required_items: true,
      main_view_loaded: false,
      did_you_mean_text: '',
      loaders: {
        retrieve_search: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getDiscoverFilters',
      'getDiscoverCuratedTopic',
      'getActiveWorkspace',
      'getWorkspaces',
      'getDiscoveryView',
    ]),
    checkInfluencerType() {
      return 'twitter'
    },
  },
  watch: {
    total_results(value) {
      console.log(`TotalResults: ${value}`)
      this.$store.commit(discoverTypes.SET_DISCOVER_SEARCH_TOTAL_RESULTS, value)
    },
    async '$route.query.tag'(value) {
      this.filters = getDefaultArticleSearchFilters()
      this.$store.commit(discoverTypes.RESET_DISCOVER_CURATED_TOPIC)
      await this.refreshWebArticlesView()
    },
    async '$route.query.sort'(value) {
      await this.refreshWebArticlesView()
    },
    async '$route.query.region'(value) {
      await this.refreshWebArticlesView()
    },
    async '$route.query.language'(value) {
      await this.refreshWebArticlesView()
    },
    async '$route.query.to_date'(value) {
      await this.refreshWebArticlesView()
    },
    async '$route.query.from_date'(value) {
      await this.refreshWebArticlesView()
    },
    '$route.query.q'(value) {
      this.filters = getDefaultArticleSearchFilters()
      this.related_topics_suggestions = []
      this.loaders.retrieve_search = true
      this.refreshWebArticlesView()
    },
    'getActiveWorkspace._id'(value) {
      this.$router.push({ name: 'discover_search' })
    },
  },
  created() {
    this.$store.commit(discoverTypes.SET_DISCOVER_SEARCH_TOTAL_RESULTS, 0)
    const query = this.$route.query
    if (!query.q && !query.tag) {
      this.$router.push({ name: 'discover_search' })
    }
    this.searchQueryOrTag()

    // search depending on the values
  },
  mounted() {
    EventBus.$on('archive-post-item', async (res) => {
      if (res.state === 'archive') {
        const resArchive = await this.$store.dispatch(
          'archivePostItem',
          res.postId
        )
        if (resArchive) {
          this.posts.splice(
            this.posts.findIndex((item) => item._source.id === res.postId),
            1
          )
          this.total_results -= 1
        }
      } else {
        // TODO: add the unarchive case over here.
      }
    })
    EventBus.$on('add-evergreen-post', async (res) => {
      await this.$store.dispatch('addEvergreenPost', {
        title: res.post.title,
        url: res.post.url,
        automation_id: res.automation_id,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      })
    })
    EventBus.$on('favorite-post-item', async (payload) => {
      console.log('EventBus:favorite-post-items ', payload)
      const resFavorite = this.$store.dispatch('favoritePostItem', {
        folder_id: payload.folderId,
        post_id: payload.postId,
      })
      resFavorite.then((res) => {
        if (res.data.status) {
          const post = this.posts.find(
            (item) => item._source.id === payload.postId
          )
          post._source.is_favorite = true
          post._source.is_active = true
          EventBus.$emit('favorite-post-item-preview')
        }
      })
    })

    EventBus.$on('unfavorite-post-item', async (payload) => {
      console.log('EventBus:unfavorite-post-item ', payload)
      const unfavorite = this.$store.dispatch('unfavoritePostItem', {
        id: payload.postId,
      })
      unfavorite.then((res) => {
        if (res.data.status) {
          const post = this.posts.find(
            (item) => item._source.id === payload.postId
          )
          post._source.is_favorite = false
          post._source.is_active = false
        }
      })
    })
  },
  beforeUnmount() {
    EventBus.$off('archive-post-item')
    EventBus.$off('add-evergreen-post')
    EventBus.$off('favorite-post-item')
    EventBus.$off('unfavorite-post-item')
  },
  methods: {
    ...mapActions([
      'fetchInfluencerPreview',
      'fetchInfluencerTopMentioned',
      'fetchInfluencerPosts',
      'fetchInfluencerSharedArticles',
    ]),
    async refreshWebArticlesView() {
      this.$store.commit(discoverTypes.SET_DISCOVER_SEARCH_TOTAL_RESULTS, 0)
      this.load_required_items = true
      this.posts = []
      this.total_results = 0
      this.filters.page = 1
      this.infiniteId += 1
      await this.searchQueryOrTag()
    },
    async searchQueryOrTag() {
      const query = this.$route.query
      if (query.tag) {
        await this.$store.dispatch('fetchCuratedTopicByTag', {
          tag: query.tag,
          tag_id: query.tag_id,
        })
        const payload = Object.assign(this.filters, this.$route.query)
        payload.topic = this.getDiscoverCuratedTopic
        this.filters.topic = this.getDiscoverCuratedTopic
        this.filters.search_type = 'curated'
        this.load_required_items = false
        return true
      }
      if (query.q) {
        this.filters.topic = {}
        this.filters.search_type = 'search'
        this.load_required_items = false
        this.filters = Object.assign(this.filters, this.$route.query)
        this.filters.query = query.q
        EventBus.$emit('reset-related-topics-suggestions')
        return true
      }
      return false
    },
    async webArticlesSearch($state) {
      // reset the values over here.
      this.loaders.retrieve_search = true
      this.did_you_mean_text = ''
      console.log('web articles search', this.filters)
      const payload = Object.assign({}, this.filters)
      if (this.getSearchDatePicker(payload.from_date, payload.to_date)) {
        console.debug(
          'here',
          this.getSearchDatePicker(payload.from_date, payload.to_date)
        )
        EventBus.$emit(
          'set-content-datepicker-label',
          this.getSearchDatePicker(payload.from_date, payload.to_date)
        )
        // this.$refs['contentdatepicker'].label = this.getSearchDatePicker(payload.from_date, payload.to_date)
      }
      console.log(payload)
      let response
      if (this.filters.q) {
        delete payload.q // extra attribute removing it as it is added from the route.
        response = this.$store.dispatch('fetchDiscoverSearchArticles', payload)
      } else {
        response = this.$store.dispatch('fetchDiscoverCuratedArticles', payload)
      }

      await response
        .then((res) => {
          if (res.data.status) {
            this.total_results = res.data.total
            if (res.data.posts.length) {
              if (this.filters.page === 1) {
                this.posts = res.data.posts
              } else {
                this.posts = this.posts.concat(res.data.posts)
              }
              if ($state) $state.loaded()
              this.filters.page += 1
            } else {
              if ($state) $state.complete()
            }
          } else {
            if (
              res.data.hasOwnProperty('relevant_suggestion') &&
              res.data.relevant_suggestion
            ) {
              this.did_you_mean_text = res.data.relevant_suggestion
            }
            if ($state) $state.complete()
          }
          this.loaders.retrieve_search = false
        })
        .catch((err) => {
          this.loaders.retrieve_search = false
        })
        .finally(() => {
          this.main_view_loaded = true
          this.loaders.retrieve_search = false
        })
      console.log(response)

      // for related topics suggestions
      if (this.$route.query.q) {
        // default is page 1, but incremented after the results have been retrieved.
        if (this.filters.page === 2) {
          EventBus.$emit('related-topics-suggestions')
        }
      }
    },

    async getInfluencerPreviewDiscoveryRedirect(screenName) {
      console.debug('Method:getInfluencerPreviewDiscoveryRedirect', screenName)
      if (screenName.charAt(0) === '@') screenName = screenName.substr(1)
      const preview = await this.fetchInfluencerPreview({
        screen_name: screenName,
        type: 'twitter_screen_name',
      })
      if (preview && preview.screen_name) {
        await this.$router.push({
          name: 'twitter_discovery_influencer_preview',
          params: { influencer_id: screenName },
          query: this.$route.query,
        })
      } else {
        window.open('https://twitter.com/' + screenName, '_blank')
      }
      return true
    },
  },
}
</script>

<style lang="less">
.did_you_mean_text {
  cursor: pointer;
}

.did_you_mean_text:hover {
  color: #5762fd;
  text-decoration: underline !important;
}
</style>
