import { mapGetters, mapActions, mapMutations } from 'vuex'
import {} from '@src/modules/setting/config/api-utils'

const browserNotificationMixin = {
  computed: {
    ...mapGetters(['getCurrentNotification']),
  },
  methods: {
    ...mapMutations(['SET_CURRENT_NOTIFICATIONS']),

    /**
     * In case of blocked email browser notifications then this method will open a new tab
     * and redirect to knowledgebase otherwise a pop-up will open to allow or block
     * browser notifications
     *
     */
    enableNotifications() {
      if (Notification.permission === 'denied') {
        window.open(
          'https://docs.contentstudio.io/article/493-enabling-disabling-browser-notifications'
        )
      } else {
        this.requestBrowserPermission()
      }
    },

    /**
     * check whether browser notifications are enabled are disabled in browser
     *
     * if not enabled display notification bar asking displaying message for enabling
     * browser notifications
     * @returns {boolean}
     */
    checkBrowserNotificationEnabled() {
      console.debug('Method:checkBrowserNotificationEnabled')
      if (
        Notification.permission === 'default' ||
        Notification.permission === 'denied'
      ) {
        // this.setBrowserNotificationMessageStatus(true)
        return true
      }
      return true
    },

    /**
     * Action to be performed when clicks on a browser notification
     */
    browserNotificationClickAction() {
      this.onClickNotification(this.getCurrentNotification)
      window.focus()
    },

    /**
     * if permission already granted return otherwise check whether wants to recieve browser notifications or not
     * in notification settings
     * @returns {null}
     */
    checkBrowserNotificationSettings() {
      if (Notification.permission === 'granted') {
        return null
      }
      const workspace = this.getNotifications.workspace
      if (
        workspace.added.browser ||
        workspace.joined.browser ||
        workspace.role_changed.browser
      ) {
        this.checkBrowserNotificationEnabled()
        return null
      }
      const tasks = this.getNotifications.tasks
      if (
        tasks.me_assigned.browser ||
        tasks.mine_assigned_completed.browser ||
        tasks.mine_date_change.browser ||
        tasks.mine_due_tomorrow.browser
      ) {
        this.checkBrowserNotificationEnabled()
        return null
      }

      const discussions = this.getNotifications.discussions
      if (
        discussions.new_comment.browser ||
        discussions.mentioned_in_comment.browser ||
        discussions.comment_react.browser
      ) {
        this.checkBrowserNotificationEnabled()
        return null
      }

      const posts = this.getNotifications.posts
      if (
        posts.mine_approved.browser ||
        posts.mine_publish.browser ||
        posts.new_added.browser ||
        posts.publish_fail.browser ||
        posts.mine_rejected.browser ||
        posts.missed_review.browser
      ) {
        this.checkBrowserNotificationEnabled()
        return null
      }

      const accounts = this.getNotifications.accounts
      if (
        accounts.social_expire.browser ||
        accounts.blog_expire.browser ||
        accounts.integration_expire.browser
      ) {
        this.checkBrowserNotificationEnabled()
        return null
      }
      const automations = this.getNotifications.automations
      if (
        automations.content_planned.browser ||
        automations.no_new_content.browser
      ) {
        this.checkBrowserNotificationEnabled()
        return null
      }
    },

    /**
     * pop-up browser notification
     * @param notification The detail of the notification broadcasted
     */
    displayBrowserNotifications(notification) {
      console.debug('Method: displayBrowserNotifications')
      const description = notification.description.replace(
        /<\/?[^>]+(>|$)/g,
        ''
      )
      {
        const newNotification = new Notification(notification.title, {
          body: description,
        })
        this.SET_CURRENT_NOTIFICATIONS(notification)
        newNotification.onclick = this.browserNotificationClickAction
      }
    },
  },
}

export { browserNotificationMixin }
