<script setup>
import { computed } from 'vue'
import useNumber from "@common/composables/useNumber";
import trendUp from './svgs/trend-up.vue';
import TrendDown from './svgs/trend-down.vue';
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';


const { tooltipHtml } = useComposerHelper()
const { formatNumber } = useNumber()
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  showInfoTooltip: {
    type: Boolean,
    default: true,
  },
})

const trends = {
  UP: 'UP',
  DOWN: 'DOWN',
  NEUTRAL: 'NEUTRAL',
}

const trend = computed(() => {
  const data = props.data

  if (data.difference > 0) {
    return trends.UP
  } else if (data.difference < 0) {
    return trends.DOWN
  } else {
    return trends.NEUTRAL
  }
})

const getStatisticClass = computed(() => {
  if (trend.value === trends.UP) {
    return 'text-[#3D8A61]'
  }

  if (trend.value === trends.DOWN) {
    return 'text-[#e02f2f]'
  }

  return 'text-[#69788E]'
})

const growth = computed(() => {
  const data = props.data
  return data.growth === 'N/A' ? data?.growth : `${Number.isNaN(data?.growth) ? '-' : data?.growth}%`
})

const formattedValue = computed(()=>{
  const data = props.data
  return data?.total === 'N/A' ? data?.total :formatNumber(data?.total)
})

</script>

<template>
  <div class="flex items-center w-full min-w-[20rem] rounded-md p-3 justify-between cst-editor bg-[#F4F6FA80] border-gray-cs-200 color-border">
    <div>
      <span
        v-tooltip="{
          content: tooltipHtml(data?.tooltip),
          theme: 'light',
          allowHTML: true,
        }"
         class="text-dark-cs-100 text-sm max-w-[100px] truncate ">
        {{data?.title}}
      </span>
    </div>
    <div class="flex items-center gap-1">
      <div
        v-tooltip="{
        content: tooltipHtml(data?.total),
        theme: 'light',
        allowHTML: true,
        }"
         class="text-secondary-cs text-base font-semibold">
        {{formattedValue}}
      </div>
      <div class="text-secondary-cs text-base font-semibold">
        |
      </div>
      <div class="text-base" :class="getStatisticClass">
        {{growth}}
      </div>
      <div v-if="trend === trends.UP ">
        <trendUp class="w-2 h-3" />
      </div>
      <div v-if="trend === trends.DOWN ">
        <TrendDown class="w-2 h-3" />
      </div>
    </div>
  </div>
</template>
