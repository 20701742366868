<template>
  <div id="createFolder" class="modal fade add_utm_modal  normal_modal z-[1060]">
    <div class="modal-dialog w-1/3">
      <div class="modal-content">
        <div class="modal_head">
          <h2 v-if="getPublicationFolderEdit.editId">Update Campaign Folder</h2>
          <h2 v-else>Create Campaign Folder</h2>
          <button
            id="close"
            type="button"
            class="modal_head__close"
            data-dismiss="modal"
            @click.prevent="hide"
            >&times;</button
          >
        </div>
        <div class="modal_body">
          <div class="field_group">
            <input
                id="folder_name1"
              ref="myInput"
              v-model="publicationNewFolder.name"
              class="{'input-field-error' : validations.name}"
              type="text"
              placeholder="Campaign Folder name e.g marketing posts"
                autofocus
              @keyup.enter="CallCreatePublicationFolder"
            />
            <label for="folder_name1" class="label-animated"
              >Folder Name</label
            >
            <span v-if="validations.name" class="input-error">
              {{ messages.name }}
            </span>
          </div>

          <div v-if="!isDefaultCampaignEdit" class="field_group">
            <div class="cst_label_box p-2 border rounded-md mt-1">
              <span class="text-sm font-bold text-gray-900 block py-2 ml-2">Choose Color</span>
              <template v-for="(value, key) in fetchColor(null, null)" :key="`key_${value}_${key}`">
                <div class="box_item relative w-10 h-10 float-left p-1 rounded-md">
                  <input
                      :id="key"
                      v-model="publicationNewFolder.color"
                      :value="key"
                      type="radio"
                      class="w-8 h-8 cursor-pointer !opacity-0 absolute"
                      name="color_select"
                  />
                  <label :for="key" :class="value + ' item_inner'" class="rounded-md">
                    <i class="cs-checked check_icon_planner d-flex height align-items-center justify-content-center"></i>
                  </label>
                </div>
              </template>
            </div>
            <span v-if="validations.color" class="input-error">
                {{ messages.color }}
              </span>
          </div>

          <div class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="getPublicationCreateFolderLoader"
              :class="{ disabled: getPublicationCreateFolderLoader }"
              data-cy="save-campaign"
              @click.prevent="CallCreatePublicationFolder"
            >
              <span v-if="getPublicationFolderEdit.editId">Update</span>
              <span v-else>Create</span>
              <clip-loader
                v-if="getPublicationCreateFolderLoader"
                :color="'#ffffff'"
                :size="'12px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED, COMPOSER_PUBLICATION_FOLDER_COLOR_REQUIRED } from '@common/constants/messages'
import {composer} from "@src/modules/composer/store/composer-mutation-type";
import {EventBus} from "@common/lib/event-bus";

export default {
  data () {
    return {
      publicationNewFolder: {
        name: '',
        color:'',
      },
      validations: {
        name: false,
        color: false
      },
      messages: {
        name: COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED,
        color: COMPOSER_PUBLICATION_FOLDER_COLOR_REQUIRED,
      }
    }
  },
  computed: {
    ...mapGetters([
      'getPublicationCreateFolderLoader',
      'getPublicationNewFolder',
      'getPublicationFolderEdit'
    ]),
    isDefaultCampaignEdit () {
      return this.getPublicationFolderEdit?.editId && this.getPublicationFolderEdit?.editId === 'edit_default_folder'
    }
  },
  watch: {
    'publicationNewFolder.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
      }
    },
    'publicationNewFolder.color' (value) {
      if (value && value.length > 0) {
        this.validations.color = false
      }
    },
  },
  mounted () {
    EventBus.$on('createFolder', () => {
      this.publicationNewFolder.name =
        this.getPublicationNewFolder.name.trim() === ''
          ? ''
          : this.getPublicationNewFolder.name
      this.publicationNewFolder.color = this.getPublicationNewFolder.color.trim() === ''
          ? ''
          : this.getPublicationNewFolder.color
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_ID,'')
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_NAME, '')
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_COLOR, '')
      $('#createFolder').modal('show')
    })

    EventBus.$on('setEditFolderName', () => {
      this.publicationNewFolder.name = this.getPublicationFolderEdit.name
      this.publicationNewFolder.color =  this.isDefaultCampaignEdit ? 'color_1' : this.getPublicationFolderEdit.color
    })

  },
  methods: {
    CallCreatePublicationFolder () {
      console.debug('debug', this.publicationNewFolder.name)
      if (this.publicationNewFolder.name && (this.publicationNewFolder.color || this.isDefaultCampaignEdit)) {
        this.getPublicationNewFolder.name = this.publicationNewFolder.name
        this.getPublicationNewFolder.color = this.publicationNewFolder.color

        this.createPublicationFolder(this.getPublicationFolderEdit.editId)
        this.publicationNewFolder.name = ''
        this.publicationNewFolder.color = ''
        // this.$store.commit(composer.SET_PUBLICATIONS_EDIT_ID,'')
        // this.$store.commit(composer.SET_PUBLICATIONS_EDIT_NAME, '')
      } else {
        this.validations.name = !this.publicationNewFolder.name
        this.validations.color = !this.publicationNewFolder.color
      }
    },
    hide () {
      this.validations.name = false
      this.validations.color = false
      $('#createFolder').modal('hide')
    }
  }
}
</script>

<style scoped>

.cst_label_box {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
  input[type='radio']:checked + .item_inner {
    .check_icon_planner {
      display: flex !important;
    }
  }
  .box_item {
    .item_inner {
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: #000;
      .check_icon_planner {
        display: none!important;
        color: #fff;
      }

    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      right: 0;
      top: 8px;
      width: 18px;
      height: 18px;
      border: none;
      border-radius: 100%;
      background: transparent;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      position: absolute;
      right: 0;
      top: 8px;
      width: 18px;
      height: 18px;
      border: none;
      border-radius: 100%;
      background: transparent;
    }
  }

}
</style>
