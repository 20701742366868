<script setup>
import { watch, ref, computed } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useStore } from '@/src/store/base'
import ProfileAvatarCard from '@/src/modules/account/views/onboarding/ProfileAvatarCard.vue'

const props = defineProps({
  platform: {
    type: Object,
    required: true,
  },
})

// declarations
const store = useStore()
const menuItems = ref({ $el: null })
const emit = defineEmits(['onDropdownStateChange'])

// computed properties
const accounts = computed(() => {
  const items = store.getters.getAllPlatforms[props.platform.name]

  return items?.map((item) => ({
    ...item,
    channel_type: props.platform.name,
  })) ?? []
})

// hooks
watch(
  () => menuItems.value.$el,
  (n) => emit('onDropdownStateChange', n !== null)
)
</script>

<template>
  <Menu as="div" class="relative inline-block text-left">
    <div v-if="accounts.length > 0">
      <MenuButton
        class="
          inline-flex
          w-full
          gap-3
          min-w-[10.25rem]
          justify-between
          align-items-center
          rounded-[1.25rem]
          bg-[#F4F6FA]
          px-4
          py-2
          text-[0.675rem]
          xl:text-xs
          font-semibold
          text-[#3C4549]
          hover:bg-gray-50
          focus:outline-none
          button-shadow
        "
      >
        <span> {{ accounts.length }} Accounts Connected </span>
        <img src="@assets/img/onboarding/chevron-down.svg" class="h-2 w-2" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        ref="menuItems"
        class="
          !cursor-default
          absolute
          right-0
          z-10
          mt-2
          w-56
          rounded-md
          bg-white
          shadow-lg
          ring-1
          !ring-[#F2F3F8]
          max-h-[12rem]
          overflow-y-auto
          child
        "
      >
        <div
          v-for="account in accounts"
          :key="account.id"
          :class="[
            'py-1',
            i !== accounts.length - 1 &&
              'border !border-b-[1px] !border-gray-100',
          ]"
        >
          <MenuItem v-slot="{ active }">
            <div
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-2 py-2 text-sm justify-around',
              ]"
            >
              <ProfileAvatarCard :platform="platform" :account="account" />
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<style lang="scss" scoped>
.button-shadow {
  border: 1px solid #f2f3f8 !important;
}
</style>
