<template>
  <div class="min-h-full flex flex-col bg-cs-light-gray">
    <div class="flex items-center px-6 h-14">
      <span class="font-bold text-base">Campaign Folder</span>
      <div v-if="!isOpen" v-tooltip.right="{ content: 'Create New Campaign Folder',distance: 14 }" class="mx-4 cursor-pointer w-7 h-7 bg-cs-primary text-white rounded-lg font-bold flex items-center justify-center" @click="isOpen = !isOpen"><i class="fal fa-plus"></i></div>
    </div>
    <hr class="m-0 hr-color">
    <div class="py-3.5 px-3.5 flex-grow flex-shrink flex-basis-auto overflow-y-auto h-0 mb-8">

        <div v-if="isOpen" class="my-4">
          <input
              v-model="newFolderName"
              type="text"
              placeholder="Campaign Folder Name e.g Marketing Posts"
              class="w-full my-1 mx-0 px-3 py-2.5 text-sm rounded-lg border border-gray-300 shadow-xs font-normal text-gray-800 hover:bg-gray-50 focus:outline-none"
              @input="validations.name = false"
              @keyup.enter="createCampaign"/>
          <span v-if="validations.name" class="input-error">{{
              validations.message
            }}</span>

        <div class="cst_campaign_box p-2 border rounded-md mt-1">
            <span class="text-sm font-bold text-gray-900 block py-2 ml-2">Choose Color</span>
            <template v-for="(value, key) in fetchColor(null, null)" :key="`key_${value}_${key}`">
              <div class="box_item relative w-10 h-10 float-left p-1 rounded-md">
                <input
                    :id="`${value}_${key}`"
                    v-model="newFolderColor"
                    :value="key"
                    type="radio"
                    class="w-8 h-8 cursor-pointer !opacity-0 absolute"
                    name="color_select"
                />
                <label :for="`${value}_${key}`" :class="value + ' item_inner'" class="rounded-md">
                  <i class="cs-checked check_icon_planner d-flex height align-items-center justify-content-center"></i>
                </label>
              </div>
            </template>
          </div>
          <span v-if="validations?.color" class="input-error">{{
              validations?.colorError
            }}</span>
          <div class="flex items-center justify-end">
            <button class="block mt-3 text-sm  focus:outline-none rounded-md text-black  border-0 px-3 py-2 mr-2"  @click="isOpen = !isOpen">Cancel</button>
            <button class="block mt-3 text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2" @click="createCampaign">Create Folder</button>
          </div>

        </div>
        <!-- ***************** campaign radio group boxes **************** -->
        <template v-if="!isOpen">
          <template v-for="(folder,index) in campaigns" :key="index">
            <div class="flex items-center cursor-pointer flex-row w-full rounded-md px-2 py-2  hover:bg-cs-foggray">
              <input
                  :id="folder._id"
                  v-model="planFolder"
                  class=" h-4 w-4 cursor-pointer"
                  :value="folder._id"
                  type="radio"
                  name="campaign_select"
              />
              <label :for="folder._id" class="w-full">
                <span
                  :id="folder._id"
                  class=" inline-block max-w-[75%] ml-2 px-2 py-0.5 rounded-full cst-pill truncate"
                  :class="`${folder.color}`"
                  >
                  {{ folder.name }}
                </span>
                     <!-- <span :id="folder._id" class="block font-medium text-sm text-gray-900 py-2">
                         {{folder.name}}
                     </span> -->
              </label>
            </div>
          </template>
        </template>

    </div>
    <CstButton
        variant="primary"
        class="!mx-8 mb-10"
        @click="$emit('redirect-to', 'preview')"
    >
      <p class="leading-4 font-medium text-light-cs">Continue</p>
    </CstButton>
  </div>
</template>

<script>
import {COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED, COMPOSER_PUBLICATION_FOLDER_COLOR_REQUIRED} from "@common/constants/messages";
import CstButton from '@ui/Button/CstButton.vue'
export default {
  name: "Campaigns",
  components: {
    CstButton
  },
  props:{
    campaigns: {
      type: Array,
      default:()=>[],
      required: false
    },
    folderId:{
      type: String,
      default:'',
      required: false
    },
  },
  emits: ['handle-campaigns-changes', 'redirect-to'],
  data() {
    return {
      isOpen:false,
      newFolderName:'',
      newFolderColor:'',
      planFolder:this.folderId,
      validations: {
        name: false,
        message:COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED,
        color:false,
        colorError: COMPOSER_PUBLICATION_FOLDER_COLOR_REQUIRED,
      },
    }
  },
  watch:{
    planFolder(val) {
      this.$emit('handle-campaigns-changes','set', val)
    },
    newFolderColor() {
      this.validations.color = false
    },
  },
  methods:{
    createCampaign(){

      if (this.newFolderName && this.newFolderColor ) {
        this.$emit('handle-campaigns-changes', 'create', {name: this.newFolderName, color: this.newFolderColor})
        this.newFolderName = ''
        this.newFolderColor = ''

        this.isOpen = false
      } else {
        this.validations.name = !this.newFolderName
        this.validations.color = !this.newFolderColor
      }
    }
  }

}
</script>

<style scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  @apply  text-gray-800
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(58,69,87,.3);
  border-radius: 100%;
  background: #fbfcfc;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  width: 18px;
  height: 18px;
  border: 3px solid #549aee;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:checked + label {
  @apply text-gray-900
}

.cst_campaign_box {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
  input[type='radio']:checked + .item_inner {
    .check_icon_planner {
      display: flex !important;
    }
  }
  .box_item {
    .item_inner {
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: #000;
      .check_icon_planner {
        display: none!important;
        color: #fff;
      }

    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      right: 0;
      top: 8px;
      width: 18px;
      height: 18px;
      border: none;
      border-radius: 100%;
      background: transparent;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      position: absolute;
      right: 0;
      top: 8px;
      width: 18px;
      height: 18px;
      border: none;
      border-radius: 100%;
      background: transparent;
    }
  }

}
</style>
