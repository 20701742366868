<template>
  <div class="article-filters-pane__right_section">
    <!-- if the filter is topic and posts length is 0, do not show the filters.-->
    <!--<template v-if="getFilters.topic && getPosts.length == 0 ">-->
    <!--</template>-->
    <!-- if the content has been searched by a keyword, show all of the filters-->

    <h3
      v-if="getDiscoverSearchTotalResults > 0"
      class="article-filters-pane__right_section__total_results min-w-max"
      >{{ $filters.numberToCommas(getDiscoverSearchTotalResults) }}
      <template v-if="getDiscoverSearchTotalResults === 1"> Result </template>
      <template v-else> Results </template>
    </h3>

      <b-dropdown
        id="dropdown-right"
        variant="studio-theme"
        class="studio-theme-dropdown mr-2 article-filters-pane__right_section__dropdown"
        no-caret
      >
        <template v-slot:button-content>
          <i class="cs-trending-chart studio-theme-icon"></i>
          <span class="capitalize_text">{{ getMostSharedTitle }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>
        <b-dropdown-item
          :active="$route.query.sort === 'most_shared' || !$route.query.sort"
          variant="studio-theme"
          @click.prevent="redirectSortView('most_shared')"
          >Most Shared
        </b-dropdown-item>

        <b-dropdown-item
          :active="$route.query.sort === 'recent'"
          variant="studio-theme"
          @click.prevent="redirectSortView('recent')"
          >Recent
        </b-dropdown-item>
        <!--              <template v-if="featureFlagRelevance">-->
        <!--                <b-dropdown-item-->
        <!--                    :active="$route.query.sort === 'relevance'"-->
        <!--                    variant="studio-theme"-->
        <!--                    @click.prevent="redirectSortView('relevance')"-->
        <!--                >Relevance-->
        <!--                </b-dropdown-item>-->
        <!--              </template>-->
        <b-dropdown-item
          :active="$route.query.sort === 'facebook_shares'"
          variant="studio-theme"
          @click.prevent="redirectSortView('facebook_shares')"
          >Facebook Shares
        </b-dropdown-item>
<!--        <b-dropdown-item-->
<!--          :active="$route.query.sort === 'twitter_shares'"-->
<!--          variant="studio-theme"-->
<!--          @click.prevent="redirectSortView('twitter_shares')"-->
<!--        >-->
<!--          Twitter Shares-->
<!--        </b-dropdown-item>-->
<!--        <b-dropdown-item-->
<!--          :active="$route.query.sort === 'pinterest_shares'"-->
<!--          variant="studio-theme"-->
<!--          @click.prevent="redirectSortView('pinterest_shares')"-->
<!--        >-->
<!--          Pinterest Shares-->
<!--        </b-dropdown-item>-->
<!--        <b-dropdown-item-->
<!--          :active="$route.query.sort === 'reddit_shares'"-->
<!--          variant="studio-theme"-->
<!--          @click.prevent="redirectSortView('reddit_shares')"-->
<!--          >Reddit Shares-->
<!--        </b-dropdown-item>-->
        <!--              <template v-if="featureFlagRelevance">-->
        <b-dropdown-item
          :active="$route.query.sort === 'relevance'"
          variant="studio-theme"
          @click.prevent="redirectSortView('relevance')"
          >Relevance
        </b-dropdown-item>
        <!--              </template>-->
      </b-dropdown>

      <!-- date picker -->
      <DateRangePicker
        ref="contentdatepicker"
        class="datepicker_input mr-2 article-filters-pane__right_section__datepicker px-2 py-[0.45rem] border border-2 border-[#70707029] rounded-lg"
        :opens="date_range_pickers.normal.opens"
        :start-date="getFilterFromDate"
        :end-date="getFilterToDate"
        :max-date="getMaxDate"
        :min-date="date_range_pickers.normal.minDate"
        :locale-data="date_range_pickers.normal.locale"
        :single-date-picker="date_range_pickers.normal.singleDatePicker"
        :time-picker="date_range_pickers.normal.timePicker"
        :time-picker24hour="date_range_pickers.normal.timePicker24Hour"
        :show-week-numbers="date_range_pickers.normal.showWeekNumbers"
        :show-dropdowns="date_range_pickers.normal.showDropdowns"
        :auto-apply="date_range_pickers.normal.autoApply"
        :ranges="date_range_pickers.normal.ranges"
        @update="redirectDateView"
      >
        <div
          slot="input"
          slot-scope="picker"
          class="datepicker_content discovery_white"
          data-cy="date-picker"
        >
          <i class="icon-Calendar"></i>

          <span v-if="getSearchDatePicker(picker.startDate, picker.endDate)">
            {{ getSearchDatePicker(picker.startDate, picker.endDate) }}
          </span>
          <span v-else-if="picker.label">
            {{ picker.label }}
          </span>
          <span v-else> Last 3 months </span>
        </div>
      </DateRangePicker>

      <b-dropdown
        id="dropdown-right"
        variant="studio-theme"
        class="studio-theme-dropdown mr-2 article-filters-pane__right_section__dropdown article-filters-pane__right_section__dropdown__region"
        no-caret
      >
        <template v-slot:button-content>
          <i class="flag_icon studio-theme-icon far fa-globe"></i>
          <span class="capitalize_text">{{ getRegionTitle }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>

        <div class="search-bar-input m-2">
          <div class="search-bar-inner">
            <input
              v-model="search_region"
              type="text"
              placeholder="Search by country name"
            />
            <button class="search_btn">
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>

        <b-dd-divider />

        <template v-if="sortedRegions.length > 0">
          <b-dropdown-item
            v-if="!search_region"
            :active="$route.query.region === 'world' || !$route.query.region"
            variant="studio-theme"
            @click.prevent="
              redirectRegionView({ name: 'Worldwide', code: 'world' })
            "
          >
            <i class="far fa-globe globe_icon worldwide-flag-icon"></i>
            Worldwide
          </b-dropdown-item>

          <b-dropdown-item
            v-for="region in sortedRegions"
            :key="region.code"
            :active="$route.query.region === region.code"
            variant="studio-theme"
            @click.prevent="redirectRegionView(region)"
          >
            <template v-if="region.flag"
              ><span class="region_flag" v-html="region.flag"></span>
            </template>
            <template v-if="!region.flag && region.code === 'world'"
              ><i class="far fa-globe globe_icon"></i
            ></template>
            {{ region.name }}
          </b-dropdown-item>
        </template>
        <template v-else>
          <p class="article-filters-pane__right_section__dropdown__no_matches">
            No matches found
          </p>
        </template>
      </b-dropdown>

      <template v-if="isRegionFiltersAllowed">
        <b-dropdown
          id="dropdown-right"
          variant="studio-theme"
          class="studio-theme-dropdown mr-2 article-filters-pane__right_section__dropdown article-filters-pane__right_section__dropdown__region"
          no-caret
        >
          <template v-slot:button-content>
            <i class="flag_icon studio-theme-icon far cs-flag"></i>
            <span class="capitalize_text">{{ getLanguageTitle }}</span>
            <i class="icon-dropdown-cs"></i>
          </template>

          <div
            class="article-filters-pane__right_section__dropdown_search article-filters-pane__right_section__dropdown_search_language"
          >
            <input
              v-model="search_language"
              type="text"
              placeholder="Search by language"
              class="search-bar-input search-bar-input-discovery with-icon-left"
            />
            <i class="icon-Search"></i>
          </div>
          <b-dd-divider />

          <template v-if="languageList.length > 0">
            <b-dropdown-item
              v-for="language in languageList"
              :key="language.code"
              :active="$route.query.language === language.code"
              variant="studio-theme"
              @click.prevent="redirectLanguageView(language)"
            >
              {{ language.languageName }}
            </b-dropdown-item>
          </template>
          <template v-else>
            <p
              class="article-filters-pane__right_section__dropdown__no_matches"
            >
              No matches found
            </p>
          </template>
        </b-dropdown>
      </template>

    <div class="article-filters-pane__right_section__view_change">
      <span
        v-tooltip.top="'Grid View'"
        class="grid_icon"
        :class="{ active: getDiscoveryView === 'grid' }"
        @click.prevent="changeViewLayout('grid')"
      >
        <i class="icon-groups-cs"></i>
      </span>
      <span
        v-tooltip.top="'List View'"
        class="list_icon"
        :class="{ active: getDiscoveryView === 'list' }"
        @click.prevent="changeViewLayout('list')"
      >
        <i class="icon-Listview_icon"></i>
      </span>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import {mapActions, mapGetters} from 'vuex'
import orderBy from 'lodash.orderby'
import { filterLimitedRegions } from '@common/constants/regions'
import { filterLimitedLanguages } from '@common/constants/languages'
import moment from 'moment'
import { EventBus } from '@common/lib/event-bus'
import proxy from "@common/lib/http-common";
import {discoveryDefaultView} from "@src/modules/publish/config/api-utils";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      languageTitle: '',
      regionTitle: '',
      search_region: '',
      total_results: 0,
      search_language: '',
      date_range_pickers: {
        normal: {
          locale: {
            format: 'MMMM DD, YYYY HH:mm', // fomart of the dates displayed
          },

          opens: 'left',
          startDate: '2023-01-01',
          endDate: '2023-01-01',
          minDate: '2023-01-01',
          maxDate: this.getMaxDate,
          dateRange: {
            endDate: moment().utc(),
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: true,
          showDropdowns: true,
          autoApply: true,
          showWeekNumbers: true,
          checkOpen: false,
          ranges: {
            // 'All Time': [moment.utc().subtract(1.5, 'years'), moment.utc()],
            '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
            '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
            'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
            'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
            'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
            'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
            'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters(['getSearchContentType', 'getDiscoverSearchTotalResults','getDiscoveryView']),
    featureFlagRelevance() {
      const email = this.$store.getters.getProfile.email
      return (
        email.includes('@contentstudio.io') ||
        email.includes('@d4interactive.io')
      )
    },
    getMaxDate() {
      console.log(moment.utc().format('YYYY-MM-DD HH:mm'))
      return moment.utc().format('YYYY-MM-DD HH:mm')
    },
    getFilterFromDate() {
      return (
        this.$route.query.from_date ||
        moment().utc().subtract(90, 'days').format('YYYY-MM-DD')
      )
    },
    getFilterToDate() {
      return this.$route.query.to_date || moment().utc().format('YYYY-MM-DD')
    },
    isRegionFiltersAllowed() {
      return this.$route.query.q && this.$route.query.q.length > 0
    },
    getMostSharedTitle() {
      return this.$route.query.sort
        ? this.$route.query.sort.replace('_', ' ')
        : 'most shared'
    },
    sortedRegions() {
      let filteredRegions = filterLimitedRegions
      if (this.search_region.trim().length > 0) {
        filteredRegions = filterLimitedRegions.filter((item) =>
          item.name.toLowerCase().includes(this.search_region.toLowerCase())
        )
      }
      const regionsList = orderBy(filteredRegions, ['name'], ['asc'])
      return regionsList
    },
    languageList() {
      let languageList = []
      const allLanguageItems = filterLimitedLanguages
      const regionCode = this.$route.query.region || 'world'
      allLanguageItems.forEach((item) => {
        if (item.code === regionCode) {
          languageList = item.languageList
        }
      })
      if (this.search_language.trim().length > 0) {
        languageList = languageList.filter((item) =>
          item.languageName
            .toLowerCase()
            .includes(this.search_language.toLowerCase())
        )
      }
      return orderBy(languageList, ['languageName'], ['asc'])
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getLanguageTitle() {
      console.log('get language title')
      const regionValue = this.$route.query.region || 'world'
      const language = this.$route.query.language || 'en'
      if (regionValue) {
        console.log('find language')
        const languageItem = filterLimitedLanguages.find(
          (item) => item.code === regionValue
        )
        const languageTitle = languageItem.languageList.find(
          (lang) => lang.code === language
        )
        return languageTitle.languageName
      }
    },
    getRegionTitle() {
      const regionValue = this.$route.query.region || 'world'
      return filterLimitedRegions.find((region) => region.code === regionValue)
        .name
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$on('bv::dropdown::show', (bvEvent) => {
        this.closeDatePicker()
      })
    })

    EventBus.$on('set-content-datepicker-label', async (label) => {
      console.log('EventBus:set-content-datepicker-label ', label)
      this.$refs.contentdatepicker.label = label
    })
  },
  beforeUnmount() {
    EventBus.$off('set-content-datepicker-label')
  },
  methods: {
    ...mapActions(['setDiscoveryDefaultView']),
    closeDatePicker() {
      if (this.$refs.contentdatepicker) {
        this.$refs.contentdatepicker.clickAway()
      }
    },
    /**
     * change view to grid or list
     * @param view
     * @returns {Promise<void>}
     */
    async changeViewLayout(view) {
      if (this.getDiscoveryView === view) {
        return
      }
      try {
        await this.setDiscoveryDefaultView(view)
        await this.$router.replace({query: {...this.$route.query, view}})
      }catch(e){
        console.log(e)
      }
    },
    redirectSortView(sort) {
      this.$router.replace({ query: { ...this.$route.query, sort } })
    },
    redirectLanguageView(language) {
      this.languagetitle = language.languageName
      this.trackUserMaven('discover_filters_language', {
        language: language.code,
      })
      this.$router.replace({
        query: { ...this.$route.query, language: language.code },
      })
    },
    redirectRegionView(region) {
      this.search_language = ''
      this.regionTitle = region.name
      const queryValues = this.$route.query
      delete queryValues.language
      this.$router.replace({ query: { ...queryValues, region: region.code } })
    },
    redirectDateView(values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate)
        const endDate = moment(values.endDate)
        this.trackUserMaven('discover_filters_date', {
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
        })
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: endDate.format('YYYY-MM-DD'),
          },
        })
      }
    },
  },
}
</script>

<style lang="less">
.no_matches_found {
  font-size: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 0.625rem;
}

.region_flag {
  display: inline-block;
  padding-right: 5px;

  svg {
    height: 15px;
    width: 15px;
  }
}

.globe_icon {
  padding-right: 5px;
}
</style>
