/**
 *  This file is composable conversion of platformMixin (src/modules/common/mixins/integrations/platformMixin.js)
 *  You can move required methods from platformMixin to this file and use it in your components
 */
export default function usePlatform() {
  function isIGPersonalAccount(account) {
    return account?.type === 'personal'
  }

  return {
    isIGPersonalAccount,
  }
}
