<script setup>
import { computed } from 'vue'
import trendUp from './svgs/trend-up.vue';
import TrendDown from './svgs/trend-down.vue';
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';


const { tooltipHtml } = useComposerHelper()

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  showInfoTooltip: {
    type: Boolean,
    default: true,
  },
})

const trends = {
  UP: 'UP',
  DOWN: 'DOWN',
  NEUTRAL: 'NEUTRAL',
}

const toolTips = {
  totalNA:'No Data available for the selected date range. This metric will be measured from the date the account was added to ContentStudio.',
  netChange: ( title = '' ) => `The net increase or decrease in ${title} compared to the previous time period.`,
  percentageChange: ( title = '' ) => `The percentage rate of change in ${title} compared to the previous time period.`,
  netChangeNA : ( title = '' ) => `The net increase or decrease in ${title} compared to the previous time period. <p class="text-xs mt-2 opacity-70">No past data available for comparison to measure the magnitude of this change.</p>`,
  percentageChangeNA : ( title = '' ) => `The percentage rate of change in ${title} compared to the previous time period. <p class="text-xs mt-2 opacity-70">No past data available for comparison to measure the magnitude of this change.</p>`,
}

const trend = computed(() => {
  const data = props.data

  if (data.difference > 0) {
    return trends.UP
  } else if (data.difference < 0) {
    return trends.DOWN
  } else {
    return trends.NEUTRAL
  }
})

const formattedValue = computed(() => {
  const data = props.data
  const trendV = trend.value
  const sign = trendV === trends.UP ? '+' : trendV === trends.DOWN ? '-' : ''
  const result = `${sign}${data.difference ?? '-'}`

  return result.replace('--', '-').replace('++', '+').replace('-%', '-')
})

const growth = computed(() => {
  const data = props.data
  return data.growth === 'N/A' ? data.growth : `${data.growth ?? '-'}%`
})

const getStatisticClassChip = computed(() => {
  if (trend.value === trends.UP) {
    return 'bg-[#EEF9F3] text-[#3D8A61]'
  }

  if (trend.value === trends.DOWN) {
    return 'bg-[#FFECF0] text-[#e02f2f]'
  }

  return 'bg-[#fafafa] text-[#69788E]'
})

const getStatisticClass = computed(() => {
  if (trend.value === trends.UP) {
    return 'text-[#3D8A61]'
  }

  if (trend.value === trends.DOWN) {
    return 'text-[#e02f2f]'
  }

  return 'text-[#69788E]'
})

</script>

<template>
  <div
    class="
      flex
      h-[7.25rem]
      w-full
      flex-col
      gap-2
      rounded-lg
      bg-white
      p-4
      shadow-[0px_3px_10px_0px_rgba(211,217,236,0.30)]
      color-border
      cst-editor
    "
  >
    <div class="flex items-center gap-1">
      <p class="text-sm font-semibold text-[#757A8A]">{{ data.title ?? '-' }}</p>

      <v-menu
        v-if="showInfoTooltip"
        :popper-triggers="['hover']"
        placement="top"
        popper-class="first-comment__info-popover"
        :delay="300"
    >
      <i class="far fa-question-circle p-0 cursor-pointer"></i>
      <template v-slot:popper>
        <p class="text-sm text-gray-900">
            {{data?.tooltip}}
        </p>
      </template>
      </v-menu>
    </div>
    <span
      v-tooltip="{
        content: data?.total === 'N/A' ? tooltipHtml(toolTips?.totalNA) : tooltipHtml(data?.originalValue),
        theme: 'light',
        allowHTML: true,
        }"
        class="text-lg font-semibold text-black w-max">
        {{ data.total ?? '-' }}
    </span>
    <div class="flex items-center justify-between">
      <div>
        <span
          v-tooltip="{
            content: formattedValue === 'N/A' ? tooltipHtml(toolTips.netChangeNA(data?.title)) : tooltipHtml(toolTips.netChange(data?.title)),
            theme: 'light',
            allowHTML: true,
            }"
          class="font-0-87rem font-medium py-0.5 rounded-full"
          :class="getStatisticClass"
        >
          {{ formattedValue }}
        </span>
      </div>

      <div>
        <div
          v-tooltip="{
            content: data.growth === 'N/A' ? tooltipHtml(toolTips.percentageChangeNA(data?.title)) : tooltipHtml(toolTips.percentageChange(data?.title)),
            theme: 'light',
            allowHTML: true,
            }"
          class="font-0-87rem font-medium px-2.5 py-0.5 rounded-full flex items-center gap-1"
          :class="[getStatisticClassChip, data.growth === 'N/A' && '!bg-[#fafafa] !text-[#69788E]']"
        >

          {{ growth }}
          <div v-if="trend === trends.UP && data.growth !== 'N/A'">
            <trendUp class="w-2 h-3" />
          </div>
          <div v-if="trend === trends.DOWN && data.growth !== 'N/A'">
            <TrendDown class="w-2 h-3" />
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
