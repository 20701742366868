<script>
import {
  ACCOUNT_NOT_ENOUGH_DATA_ERROR,
  SLOTS_EMPTY_DAYS_SELECTION_ERROR,
  SLOTS_EMPTY_TIME_SELECTION_ERROR,
  SLOTS_INVALID_TIME_SELECTION_ERROR,
} from '@common/constants/messages'

import { mapGetters } from 'vuex'
import { social } from '../../store/states/mutation-types'
export default {
  components: {},

  data() {
    return {
      update_slots_loader: false,
    }
  },
  computed: {
    ...mapGetters(['getAccountQueueTimes', 'getWorkspaces', 'getSocialLoader']),

    getQueueSlots() {
      return this.getAccountQueueTimes.type === 'prime'
        ? this.getAccountQueueTimes.prime_queue
        : this.getAccountQueueTimes.custom_queue
    },
    showPlansRequeueWarning() {
      return this.getAccountQueueTimes.plans_count
    },
    showQueueSlots() {
      return this.getQueueSlots.length > 0
    },
    isCustomQueue() {
      return this.getAccountQueueTimes.type === 'custom'
    },
    allowQueueReschedule() {
      return (
        this.getProfile._id ===
          this.getAccountQueueTimes.selected_item.user_id ||
        this.hasPermission('can_reschedule_plan')
      )
    },
    fetchSlotsLoaderStatus() {
      return this.getSocialLoader.fetch_slots_loader
    },
    updateSlotsLoaderStatus() {
      return this.update_slots_loader
    },
  },

  methods: {
    hideModal() {
      this.$bvModal.hide('account_queue_slot_modal')
    },
    clearAllAccountQueueSlots() {
      const slots = this.getAccountQueueTimes.custom_queue
      for (let i = 0; i < slots.length; i++) {
        slots[i].status = false
        slots[i].times = []
      }
      this.$store.commit(social.SET_QUEUE_CUSTOM_SLOTS, slots)
    },
    addAccountQueueSlot(slotIndex) {
      const slots = this.getAccountQueueTimes.custom_queue
      slots[slotIndex].times.push('12:00')
      this.$store.commit(social.SET_QUEUE_CUSTOM_SLOTS, slots)
    },
    removeAccountQueueSlot(slotIndex, timeIndex) {
      const slots = this.getAccountQueueTimes.custom_queue
      slots[slotIndex].times.splice(timeIndex, 1)
      this.$store.commit(social.SET_QUEUE_CUSTOM_SLOTS, slots)
    },
    async updateQueueSlots() {
      console.debug('Method:updateQueueSlots')
      const currentObject = this
      const account_queue_times = this.getAccountQueueTimes

      const data = {
        queueType: account_queue_times.type,
        type: account_queue_times.selected_item.type,
        platform: account_queue_times.selected_item.platform,
        workspace_id: account_queue_times.selected_item.workspace_id,
      }

      if (account_queue_times.selected_item.type === 'Facebook') {
        if (
          account_queue_times.queue_type === 'prime' &&
          !account_queue_times.prime_status
        ) {
          this.alertMessage(ACCOUNT_NOT_ENOUGH_DATA_ERROR, 'error')
          return false
        }
      }

      if (account_queue_times.queue_type !== 'prime') {
        if (
          account_queue_times.custom_queue[0].status === false &&
          account_queue_times.custom_queue[1].status === false &&
          account_queue_times.custom_queue[2].status === false &&
          account_queue_times.custom_queue[3].status === false &&
          account_queue_times.custom_queue[4].status === false &&
          account_queue_times.custom_queue[5].status === false &&
          account_queue_times.custom_queue[6].status === false
        ) {
          this.alertMessage(SLOTS_EMPTY_DAYS_SELECTION_ERROR, 'error')
          return false
        }
        if (account_queue_times.custom_queue[0].status === true) {
          if (account_queue_times.custom_queue[0].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'sunday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[0].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'sunday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[1].status === true) {
          if (account_queue_times.custom_queue[1].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'monday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[1].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'monday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[2].status === true) {
          if (account_queue_times.custom_queue[2].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'tuesday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[2].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'tuesday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[3].status === true) {
          if (account_queue_times.custom_queue[3].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'wednesday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[3].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'wednesday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[4].status === true) {
          if (account_queue_times.custom_queue[4].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'thursday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[4].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'thursday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[5].status === true) {
          if (account_queue_times.custom_queue[5].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'friday.',
              'error'
            )
            return false
          }
          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[5].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'friday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[6].status === true) {
          if (account_queue_times.custom_queue[6].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'saturday.',
              'error'
            )
            return false
          }
          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[6].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'saturday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }

        data.slots = account_queue_times.custom_queue
      }
      this.update_slots_loader = true
      const response = await this.$store.dispatch(
        'updateAccountQueueSlots',
        data
      )
      if (response) {
        this.$bvModal.hide('account_queue_slot_modal')
      }
      this.update_slots_loader = false
    },
  },
}
</script>

<template>
  <b-modal
    id="account_queue_slot_modal"
    modal-class="time_queue_modal normal_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h4
        >Queue time<a
          v-tooltip.top="'Learn more about queue slots'"
          href="https://docs.contentstudio.io/article/691-what-are-social-queues"
          target="_blank"
          ><i class="far fa-question-circle ml-1"></i></a
      ></h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="hideModal"
        >&times;</button
      >
    </div>

    <div v-if="fetchSlotsLoaderStatus" class="modal_body">
      <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
    </div>

    <div v-if="!fetchSlotsLoaderStatus" class="modal_body basic_form m_t_15">
      <div class="custom_block">
        <div
          v-if="showPlansRequeueWarning"
          class="warning_box mb-3 d-inline-block"
        >
          <p
            >Note: Posts already in the queue will be re-queue according to the
            new schedule.</p
          >
        </div>

        <!--@waqas::disabled:getAccountQueueTimes.type == 'prime' || -->
        <div
          class="time_table_main_top"
          :class="{ disabled: !allowQueueReschedule }"
        >
          <!--@waqas:::disabled="getAccountQueueTimes.type == 'prime'"-->
          <div
            v-if="showQueueSlots"
            class="time_table d-flex align-items-start"
          >
            <div v-for="(day, dayIndex) in getQueueSlots" class="time_box_main">
              <div class="box_inner">
                <div class="day_box">
                  <input
                    :id="dayIndex"
                    v-model="getQueueSlots[dayIndex].status"
                    type="checkbox"
                  />
                  <label :for="dayIndex" data-cy="day-select">{{
                    day.name
                  }}</label>
                </div>

                <div v-for="(time, timeIndex) in day.times" class="time_list">
                  <div
                    class="time_box"
                    :class="{ disabled: !getQueueSlots[dayIndex].status }"
                  >
                    <input
                      v-model="getQueueSlots[dayIndex].times[timeIndex]"
                      type="text"
                      data-cy="new-time-slot"
                    />
                    <span
                      class="
                        cross_icon_red
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                      @click="removeAccountQueueSlot(dayIndex, timeIndex)"
                    >
                      <i class="cs-cross"></i>
                    </span>
                  </div>
                </div>

                <div
                  class="
                    add_time_box add_icon
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  @click="addAccountQueueSlot(dayIndex)"
                >
                  <i class="cs-plus" data-cy="add-time-slot"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center time_bottom">
            <span
              v-if="isCustomQueue"
              class="clear_all_btn"
              @click="clearAllAccountQueueSlots"
              >Clear all</span
            >
            <span class="timezone_box ml-auto">
              Timezone: {{ getWorkspaces.activeWorkspace.timezone }}
            </span>
          </div>
        </div>
      </div>

      <div class="input_field">
        <button
          v-if="allowQueueReschedule"
          class="btn btn-studio-theme-space"
          :disabled="updateSlotsLoaderStatus"
          data-cy="update-queue"
          @click.prevent="updateQueueSlots"
        >
          <span>Update</span>
          <clip-loader
            v-if="updateSlotsLoaderStatus"
            :color="'#ffffff'"
            :size="'12px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>
