import { mapGetters } from 'vuex'

export const billingMixin = {
  computed: {
    ...mapGetters(['getPlan', 'getActiveWorkspaceAddonsLifetime']),
  },
  methods: {
    shouldLockBasedOnSubAddons(type) {
      const subscription = this.getPlan.subscription
      const defaultAddons = subscription.default_add_ons || {}
      let shouldLock = !defaultAddons[type] || false
      const paidAddons = this.getActiveWorkspaceAddonsLifetime
      if (shouldLock) {
        if (type === 'social_inbox') {
          shouldLock = !paidAddons.social_inbox
          if (shouldLock) {
            shouldLock = !paidAddons.social_inbox_beta
          }
        } else {
          shouldLock = !paidAddons[type]
        }
      }
      return shouldLock
    },
    getLockBasedOnSubAddonsMessage(type) {
      const message =
        'Your subscription plan ' +
        this.getPlan.subscription.display_name +
        ' does not include '
      const modType = type.replace('_', ' ').toLowerCase()

      return message + modType + ' please update your plan.'
    },
    checkIfStarterSubPlan() {
      const subscriptionId = this.getPlan.subscription._id
      return (
        subscriptionId === '62a0310c2d363a60b1e5ff75' || // starter trial
        subscriptionId === '629f4952945c0941d97530a5' || // starter monthly
        subscriptionId === '629f4964945c0941d97530ac'
      ) // starter annual
    },

    triggerGTMEvent(data, subscriptionType) {
      gtag('event', 'conversion', {
        'send_to': 'AW-367072414/E9tXCPm8gYEZEJ6phK8B',
        'value': parseInt(data?.checkout?.prices?.customer?.total || 0),
        'currency': data?.checkout?.prices?.customer?.currency || 'USD',
        'transaction_id': data?.checkout?.id || '',
        subscriptionType
      });

      // const event = {
      //   'event': 'purchase',
      //   'send_to': 'AW-367072414/w3mYCM_m2u0YEJ6phK8B',
      //   'value': parseInt(data.checkout.prices.customer.total || 0),
      //   'currency': data.checkout?.prices?.customer?.currency || 'USD',
      //   'transaction_id': data.checkout?.id || '',
      //   subscription_type
      // }
      // dataLayer.push(event);
    }
  },
}
