<template>
  <div class="setting_profile_component">
    <div class="component_inner">
      <div class="flex">
        <div class="ps_box flex-1">
          <h2 class="box_heading">Basic Details</h2>

          <div class="box_content flex">
            <div class="mt-6 mb-5 mr-5">
              <div
                class="w-44 h-44 overflow-hidden shadow-sm text-base ease-soft-in-out relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200 group bg-gray-400"
              >
                <img
                  class="h-full shadow-soft-sm object-cover"
                  :src="getWorkspaceDetails.logo"
                  alt="display picture"
                  data-fallback="0"
                  @error="onError($event)"
                />

                <div
                  class="w-full h-full absolute bg-black-100 bg-opacity-50 flex justify-center items-center cursor-pointer opacity-0 group-hover:!opacity-100 transition-all duration-200"
                  :class="{
                    '!opacity-100': getSettingLoaders.uploadWorkspaceLogo,
                  }"
                  @click="triggerWorkspaceChangeLogo('workspace_logo_image')"
                >
                  <clip-loader
                    v-if="getSettingLoaders.uploadWorkspaceLogo"
                    class="center_loader"
                    :color="'#ffffff'"
                    :size="'24px'"
                  ></clip-loader>
                  <i v-else class="fas fa-camera text-4xl"></i>
                  <input
                    id="workspace_logo_image"
                    class="hidden"
                    type="file"
                    autocomplete="off"
                    accept="image/*"
                    :disabled="getSettingLoaders.uploadWorkspaceLogo"
                    @change.prevent="uploadWorkspaceLogo"
                  />
                </div>
              </div>

              <div class="w-44 text-xs mt-2 text-gray-800"
                >Recommended size: Less than 2MB</div
              >
            </div>
            <div class="flex-1">
              <div class="grid grid-cols-2 gap-5 mt-6 mb-5 w-full">
                <div>
                  <CstFloatingLabelInput
                    id="workspaceName"
                    v-model="getWorkspaceDetails.name"
                    value="getWorkspaceDetails.name"
                    data-cy="basic_workspace_name"
                    type="text"
                    label="Workspace Name"
                    :show-icon-left="false"
                  />
                </div>

                <div>
                  <div class="field_group !my-0">
                    <!--<label for="">Timezone</label>-->

                    <div
                      class="social_dropdown dropdown default_style_dropdown"
                    >
                      <div
                        data-toggle="dropdown"
                        class="dropdown_header d-flex align-items-center bg-white flex items-center h-14 !rounded-md border text-gray-900 text-sm focus-within:outline-blue w-full !py-2.5 !px-3"
                        data-cy="basic_workspace_timezone_clickable"
                      >
                        <label
                          class="label-animated !left-3 !top-0 block !text-gray-900 !text-xs !py-2 m-0 leading-normal"
                          >Workspace Timezone</label
                        >
                        <div
                          class="relative_top_ten w-full border-0 py-2 bg-transparent leading-normal rounded !text-gray-900"
                          >{{ getWorkspaceDetails.timezone }}</div
                        >
                        <span class="ml-auto">
                          <i class="dropdown_arrow icon_last cs-angle-down"></i>
                        </span>
                      </div>

                      <div
                        class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
                      >
                        <ul class="inner">
                          <div class="search_input with_icon_right">
                            <div class="search_inner w-100">
                              <input
                                id="search_timezone"
                                v-model="search_timezone"
                                type="text"
                                placeholder="Search by name"
                                data-cy="search-timezone"
                              />
                            </div>
                          </div>

                          <template v-if="getFilteredTimezones.length">
                            <template
                              v-for="(timezone, i) in getFilteredTimezones"
                              :key="i"
                            >
                              <li
                                class="list_item_li"
                                data-cy="basic_workspace_timezone"
                                @click.prevent="changeTimezone(timezone.value)"
                              >
                                {{ timezone.name }}
                              </li>
                            </template>
                          </template>

                          <li
                            v-if="!getFilteredTimezones.length"
                            class="no_text_li"
                          >
                            No Results found
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    class="field_group textarea_field !my-0 bg-white rounded-md border text-gray-900 text-sm focus-within:outline-blue block w-full !py-2.5 !px-3 h-full"
                  >
                    <label
                      class="textarea-label bg-white !px-0 !pt-0 !text-gray-900 !text-xs font-weight-500"
                      for="internalNotes"
                      >Workspace Notes</label
                    >
                    <textarea
                      id="internalNotes"
                      v-model="getWorkspaceDetails.note"
                      class="bg-white !px-0 !pb-0"
                      data-cy="workspace_note"
                      name=""
                      cols="30"
                      rows="4"
                      placeholder="Add notes, instructions or SOPs for your team here..."
                    ></textarea>
                  </div>
                </div>

                <div v-if="onBoardingProgressValue !== 100"> </div>
                <!-- to keep the grid layout same -->

                <div>
                  <div
                    class="field_group textarea_field !my-0 bg-white rounded-md border text-gray-900 text-sm focus-within:outline-blue block w-full !py-2.5 !px-3"
                  >
                    <label
                      class="textarea-label bg-white !px-0 !pt-0 !text-gray-900 !text-xs font-weight-500"
                      for="postingMethod"
                      >Instagram Posting Method For Automation</label
                    >
                    <div class="field_group instagram_options !mb-0">
                      <label class="radio_container">
                        <input
                          id="api"
                          v-model="getWorkspaceDetails.instagram_posting_method"
                          type="radio"
                          value="api"
                        />
                        <div for="api">
                          Direct publishing via API
                          <span class="check"></span>
                        </div>
                      </label>

                      <label class="radio_container">
                        <input
                          id="mobile"
                          v-model="getWorkspaceDetails.instagram_posting_method"
                          type="radio"
                          value="mobile"
                        />
                        <div for="mobile">
                          Mobile Notification
                          <span class="check"></span>
                        </div>
                      </label>

                      <CstAlert type="info" class="mt-3">
                        <template
                          v-if="
                            getWorkspaceDetails.instagram_posting_method ===
                            'api'
                          "
                          >For single/multiple images & videos/reel. Has a few
                          other limitations,
                          <a
                            v-close-popover="true"
                            href="#"
                            data-beacon-article-modal="601c49ffac2f834ec5385736"
                          >
                            learn more.</a
                          ></template
                        >
                        <template v-else>For stories and IGTV </template>
                      </CstAlert>
                    </div>
                  </div>
                </div>

                <div
                  v-if="onBoardingProgressValue !== 100"
                  class="field_group textarea_field !my-0 bg-white rounded-md border text-gray-900 text-sm focus-within:outline-blue block w-full !py-2.5 !px-3"
                >
                  <label
                    class="textarea-label bg-white !px-0 !pt-0 !text-gray-900 !text-xs font-weight-500"
                    >Enable Onboarding widget for this workspace?
                  </label>
                  <div class="field_group instagram_options !mb-0">
                    <label class="radio_container">
                      <input
                        id="yes"
                        v-model="getWorkspaceDetails.onboarding"
                        type="radio"
                        :value="false"
                        name="onboardingcheck"
                      />
                      <div for="yes">
                        Yes
                        <span class="check"></span>
                      </div>
                    </label>
                    <label class="radio_container">
                      <input
                        id="no"
                        v-model="getWorkspaceDetails.onboarding"
                        type="radio"
                        :value="true"
                        name="onboardingcheck"
                      />
                      <div for="no">
                        No
                        <span class="check"></span>
                      </div>
                    </label>
                  </div>
                </div>

                <div>
                  <div class="field_group !my-0">
                    <!--<label for="">Timezone</label>-->

                    <div
                      class="social_dropdown dropdown default_style_dropdown"
                    >
                      <div
                        data-toggle="dropdown"
                        class="dropdown_header d-flex align-items-center bg-white flex items-center h-14 !rounded-md border text-gray-900 text-sm focus-within:outline-blue w-full !py-2.5 !px-3"
                        data-cy="basic_workspace_timezone_clickable"
                      >
                        <label
                          class="label-animated !left-3 !top-0 block !text-gray-900 !text-xs !py-2 m-0 leading-normal"
                          >First Day of the Week</label
                        >
                        <div
                          class="relative_top_ten w-full border-0 py-2 bg-transparent leading-normal rounded !text-gray-900"
                          >{{
                            getWorkspaceDetails.first_day
                              ? getWorkspaceDetails.first_day.day
                              : firstDay
                          }}</div
                        >
                        <span class="ml-auto">
                          <i class="dropdown_arrow icon_last cs-angle-down"></i>
                        </span>
                      </div>

                      <div
                        class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
                      >
                        <ul class="inner">
                          <template v-if="getFilteredTimezones.length">
                            <template v-for="(day, key) in days" :key="key">
                              <li
                                class="list_item_li"
                                data-cy="basic_workspace_timezone"
                                @click.prevent="changeFirstDay(day, key)"
                              >
                                <span class="mx-1 my-1">{{ day }}</span>
                              </li>
                            </template>
                          </template>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasPermission('can_save_workspace')">
                <CstButton
                  data-cy="submit_workspace"
                  class="ml-auto block"
                  :disabled="getSettingLoaders.saveWorkspace"
                  :loading="getSettingLoaders.saveWorkspace"
                  @click.prevent="saveWorkspace(false, false, true)"
                >
                  Update
                </CstButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { timezones } from '@src/modules/setting/config/timezone.js'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import CstAlert from '@ui/Alert/CstAlert'
import CstButton from '@ui/Button/CstButton'

export default {
  components: {
    CstFloatingLabelInput,
    CstAlert,
    CstButton,
  },

  data() {
    return {
      timezones,
      search_timezone: '',
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      firstDay: 'Monday',
    }
  },

  computed: {
    ...mapGetters([
      'getWorkspaceDetails',
      'getSettingLoaders',
      'getWorkspaces',
      'getProfile',
    ]),
    getFilteredTimezones() {
      return this.timezones.filter((s) =>
        s.value.toLowerCase().includes(this.search_timezone.toLowerCase())
      )
    },
  },
  watch: {
    'getWorkspaces.activeWorkspace._id'(val) {
      this.processWorkspaceBasicSetting()
    },
  },

  created() {
    if (this.getWorkspaces.activeWorkspace._id) {
      this.processWorkspaceBasicSetting()
    }
  },
  methods: {
    onError(event) {
      const fallbackIndex = event.target.dataset.fallback
      const fallbacks = [
        `https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg`,
      ]
      if (fallbackIndex > fallbacks.length - 1) return
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },

    changeTimezone(timezone) {
      console.debug('Method::changeTimezone', timezone)
      this.getWorkspaceDetails.timezone = timezone
      this.search_timezone = ''
    },

    changeFirstDay(firstDay, index) {
      this.firstDay = firstDay
      if (!this.getWorkspaceDetails.first_day) {
        this.getWorkspaceDetails.first_day = {}
      }
      this.getWorkspaceDetails.first_day.day = firstDay
      this.getWorkspaceDetails.first_day.key = index
    },
  },
}
</script>
