<script>
import { mapGetters, mapActions } from 'vuex'
import SocialChannelsTooltip from '@src/components/common/ChannelsTooltip'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
import {
  swalAttributes,
  getDuplicateCampaignName
} from '@common/constants/common-attributes'
import { rssTypes } from '../../../store/recipes/mutation-types'
import SelectedPlatforms from '../../SelectedPlatforms'
import PlatformFilters from '../../../../publish/components/PlatformFilters'

import {
  publish,
  social
} from '../../../../publish/store/states/mutation-types'
import PullHistoryDialog from '../../PullHistoryDialog'
import ListingFilters from './ListingFilters'

export default {
  components: {
    ConfirmBox,
    SelectedPlatforms,
    ListingFilters,
    PlatformFilters,
    SocialChannelsTooltip,
    PullHistoryDialog
  },

  data () {
    return {
      status: '0'
    }
  },

  created () {
    this.initializeRssAutomationListingSection()
  },
  mounted () {
    document.addEventListener('keyup', this.keyPressListener)
  },
  unmounted () {
    document.removeEventListener('keyup', this.keyPressListener)
  },

  computed: {
    ...mapGetters([
      'getRssAutomationListing',
      'getAutomationLoaders',
      'getWorkspaces',
      'getProfile',
      'getRSSAutomationLoaders',
      'getReplug',
      'getFetchIntegrationsAccountsLoader'
    ]),
    automationStatus: {
      get() {
        return this.getRssAutomationListing.items.map(
            (automation) => automation.status === 1
        );
      },
      set(newValues) {
        this.getRssAutomationListing.items.forEach(
            (automation, index) => {
              automation.status = newValues[index] ? 1 : 0;
            }
        );
      },
    },
  },
  methods: {
    ...mapActions(['fetchRSSAutomationsList']),

    changeStatus(automation, index) {
      automation.status = this.automationStatus[index] ? 1 : 0
      this.$store.dispatch('changeRSSAutomationStatus', {automation, index})
    },

    removeRSSConfirmationModal (automation, index) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this campaign?', {
          title: 'Remove Campaign',
          ...swalAttributes()
        })
        .then((value) => {
          if (value) {
            automation.index = index
            this.$store.dispatch('deleteRSSAutomation', automation)
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })
    },

    keyPressListener (event) {
      console.debug('Method::keyPressListener', event.keyCode)
      if (this.$route.name === 'rssAutomationListing') {
        if (event.keyCode === 27) {
          this.$router.push({
            name: 'automation',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug }
          })
          return false
        }
      }
    },
    changeRssAutomationSelectAllStatus () {
      console.debug('Method:changeRssAutomationSelectAllStatus')
      const automations = this.getRssAutomationListing.items
      let list = []
      if (this.getRssAutomationListing.select_all) {
        list = automations
          .filter((item) => item.lock === 0)
          .map((item) => item._id)
      }
      console.log(list)
      this.$store.commit(rssTypes.SET_SELECTED_RSS_AUTOMATION_ITEMS, list)
    },
    modifyRSSAutomation (automation, clone = false) {
      if (automation.posting_type === 'content_category') {
        this.$store.dispatch(
          'nextAvailableSlot',
          automation.content_category_id
        )
      }
      if (clone) {
        automation.name = getDuplicateCampaignName(automation.name)
        automation.id = ''
      } else {
        automation.id = automation._id
      }

      delete automation._id
      this.$store.commit(rssTypes.SET_RSS_AUTOMATION, automation)
      this.$store.commit(
        social.SET_ACCOUNT_SELECTION,
        automation.account_selection
      )
      this.$store.commit(social.SET_PINTEREST_ACCOUNT_SELECTION,[])
      this.processHashtagSelection(automation.hashtag)
      if (automation.replug) {
        if (!this.getFetchIntegrationsAccountsLoader) {
          this.processReplugSelection(automation.replug)
        } else {
          this.$store.commit(
            publish.SET_PUBLISH_REPLUG_SELECTED_ITEM_VALUE,
            automation.replug
          )
        }
      }
      this.$router.push({ name: 'saveRssAutomation' })
    },
    viewPullHistory (automationId, page = 1) {
      this.$store.commit(rssTypes.SET_RSS_PULL_HISTORY_ITEMS, [])
      this.$bvModal.show('pull_history')
      this.$store.dispatch('pullRSSHistory', { id: automationId, page: 1 })
    }
  },

  watch: {
    'getRssAutomationListing.selected.length' (value) {
      const result = value === this.getRssAutomationListing.items.length
      this.$store.dispatch(
        rssTypes.SET_RSS_AUTOMATION_LISTING_SELECTED_ALL,
        result
      )
    }
  }
}
</script>

<template>
  <div class="max_container_1800 automation_main_component">
    <div class="component_inner">
      <PullHistoryDialog></PullHistoryDialog>

      <!--<confirm-box id="changeRssAutomationStatus"  confirmText="Yes" cancelText="No" :callBackAction="changeRssAutomationStatus">-->

      <!--<template v-slot:header>-->
      <!--<h2>Change automation status</h2>-->
      <!--</template>-->

      <!--<template v-slot:description>-->
      <!--<p>By making this automation inactive, its:</p>-->
      <!--</template>-->

      <!--<template slot="alert_list">-->

      <!--<ul class="alert_list warning">-->
      <!--<li>Posts will be removed.</li>-->
      <!--</ul>-->

      <!--</template>-->

      <!--<template v-slot:footer>-->
      <!--<p class="text-center">This action cannot be undone.</p>-->
      <!--</template>-->

      <!--</confirm-box>-->

      <div class="top_content_listing">
        <div class="d-flex align-items-center">
          <div class="col-md-4 text-left nopad-l">
            <router-link
              class="btn btn-studio-theme-grey-space"
              :to="{
                name: 'automation',
                params: { workspace: getWorkspaces.activeWorkspace.slug },
              }"
            >
              <i
                style="color: #ffffff"
                class="cs-angle-left icon_left arrow_right_active"
              ></i>
              <span>Back</span>
            </router-link>
          </div>

          <h2 class="col-md-4 text-center">View Existing Campaigns</h2>
          <p class="col-md-4 text-right nopad-r"
            >Timezone: {{ getWorkspaces.activeWorkspace.timezone }} (
            <clock
              time-format="hh:mm:ss A"
              :timezone="getWorkspaces.activeWorkspace.timezone"
            ></clock>
            )
          </p>
        </div>

        <img src="../../../../../assets/img/automation/rss_feed.svg" alt="" />
        <h3>RSS Feed to Social Media</h3>
        <p>Share RSS feeds content on your social media.</p>
      </div>

      <div class="automation_listing">
        <div class="page_inner">
          <ListingFilters></ListingFilters>

          <div class="table_box">
            <table class="table-sm">
              <thead>
                <tr>
                  <th class="text-center" style="min-width: 50px; width: 60px">
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label
                          for="select_all"
                          :class="{
                            disabled: getRssAutomationListing.items.length < 1,
                          }"
                        >
                          <input
                            id="select_all"
                            v-model="getRssAutomationListing.select_all"
                            type="checkbox"
                            :disabled="getRssAutomationListing.items.length < 1"
                            @change="changeRssAutomationSelectAllStatus"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </th>
                  <th class="text-center">Status</th>
                  <th>Name</th>
                  <th class="text-center" style="width: 200px"
                    >Platforms Selected</th
                  >
                  <th class="text-center" style="width: 200px"
                    >Next Refill Time
                    <i
                      v-tooltip.top="
                        'Posts for the next cycle are planned and added in the calendar at the execution time of the 2nd last post of the current cycle.'
                      "
                      class="fas fa-info-circle"
                      style="margin-left: 5px"
                    ></i
                  ></th>
                  <th class="text-center">Published Posts</th>
                  <th style="text-align: center; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    getRSSAutomationLoaders.retrieve ||
                    getAutomationLoaders.fetchRssAutomation
                  "
                >
                  <td colspan="7" class="text-center">
                    <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
                  </td>
                </tr>

                <template v-else-if="getRssAutomationListing.items.length">
                  <tr
                    v-for="(automation, index) in getRssAutomationListing.items"
                    :key="index"
                    :class="{ pause_automation: !automation.status }"
                  >
                    <td class="text-center">
                      <div class="field_group">
                        <div class="checkbox_container">
                          <label :for="index">
                            <input
                              :id="index"
                              v-model="getRssAutomationListing.selected"
                              type="checkbox"
                              :value="automation._id"
                              :disabled="automation.lock !== 0"
                            />
                            <span class="check"></span>
                          </label>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <label
                        v-tooltip.top="'Toggle ON/OFF'"
                        class="switch-radio"
                      >
                        <input
                          id="automation_toggle"
                          v-model="automationStatus[index]"
                          type="checkbox"
                          :disabled="automation.lock !== 0"
                          @change="changeStatus(automation, index)"
                        />
                        <div
                          class="slider round"
                          :class="{ disabled: automation.lock !== 0 }"
                        ></div>
                      </label>
                    </td>
                    <td>{{ automation.name }}</td>
                    <!--<selected-platforms
                                            :account_selection="automation.account_selection"></selected-platforms>-->
                    <td>
                      <SocialChannelsTooltip
                        :accounts="automation.account_selection"
                        :channels_size="'28px'"
                      ></SocialChannelsTooltip>
                    </td>

                    <td class="text-center">
                      <template
                        v-if="!automation.lock && automation.status === 1"
                      >
                        <span
                          v-tooltip="
                            automationNextRefill(automation, 'RssAutomation')
                          "
                          class="text-capitalize"
                        >
                          {{
                            automationNextRefill(
                              automation,
                              'RssAutomation',
                              true,
                            )
                          }}
                        </span>
                      </template>
                      <template v-else>
                        <span> - </span>
                      </template>
                    </td>
                    <td v-if="automation.posts" class="text-center">{{
                      automation.posts
                    }}</td>
                    <td v-else class="text-center">0</td>

                    <td class="text-center">
                      <!--with_loader-->
                      <div class="d-flex align-items-center">
                        <clip-loader
                          v-if="automation.lock"
                          class="inline_loader"
                          color="#4165ed"
                          :size="'16px'"
                        ></clip-loader>
                          <i
                            v-tooltip.top="'View Posts'"
                            class="action_icon sm icon-Listview_icon"
                            @click="
                              viewAutomationPosts(
                                automation._id,
                                'rss_automation',
                              )
                            "
                          >
                          </i>
                          <i
                            v-tooltip="'View History'"
                            class="action_icon sm fa fa-history"
                            aria-hidden="true"
                            @click="viewPullHistory(automation._id)"
                          ></i>

                            <i
                              v-if="
                                automation.policy && automation.policy.can_edit
                              "
                              v-tooltip.top="'Edit'"
                              class="action_icon sm icon-edit-cs"
                              @click="modifyRSSAutomation(automation)"
                            >
                            </i>

                            <i
                              v-if="
                                automation.policy && automation.policy.can_edit
                              "
                              v-tooltip.top="'Duplicate'"
                              class="action_icon sm fa fa-files-o"
                              @click="modifyRSSAutomation(automation, true)"
                            >
                            </i>

                            <i
                              v-if="
                                automation.policy &&
                                automation.policy.can_remove
                              "
                              v-tooltip.top="'Remove'"
                              class="action_icon sm icon-delete-cs"
                              @click.prevent="
                                removeRSSConfirmationModal(automation, index)
                              "
                            >
                            </i>
                      </div>
                    </td>
                  </tr>
                </template>

                <tr v-else>
                  <td
                    v-if="getRssAutomationListing.search"
                    colspan="12"
                    class="text-center"
                    >No results found for your search query, please try again.
                  </td>
                  <td v-else colspan="12" class="text-center"
                    >You have not created any automation yet.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="
              getRssAutomationListing.nextPage ||
              getRssAutomationListing.previousPage
            "
            class="field_group text-center"
          >
            <button
              class="btn btn-studio-theme-transparent-grey mr-1"
              :disabled="!getRssAutomationListing.previousPage"
              @click="fetchRSSAutomationsList(getRssAutomationListing.page - 1)"
            >
              <i class="icon_left cs-angle-left"></i>
              <span>Prev</span>
            </button>
            <button
              class="btn btn-studio-theme-space"
              :disabled="!getRssAutomationListing.nextPage"
              @click="fetchRSSAutomationsList(getRssAutomationListing.page + 1)"
            >
              <span>Next</span>
              <i class="icon_right arrow_right_active cs-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
