<script setup>
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import AnalyticsPostsTable from '@src/modules/analytics/views/common/AnalyticsPostsTable.vue'
import PinterestPostModal from '@src/modules/analytics/views/pinterest/components/PinterestPostModal.vue'
import { watch, computed, defineProps, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@state/base'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import { EventBus } from '@common/lib/event-bus'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'

const route = useRoute()
const store = useStore()
const {
  routes,
  selectedAccount,
  dateRange,
  postsLimit,
  isPostDataLoading,
  isReportView,
  topEngagingPosts,
  validPostsTableHeaders,
  nonSortableItems,
  TopLeastEngagementDropdown,

  getHeaderTitles,
  getBodyValues,
  fetchMedia,
  getHeaderTooltips,
  getHeaderApiKey,
} = usePinterestAnalytics()

const props = defineProps({
  rangeStartValue: {
    type: Number,
    default: 0,
  },
  rangeEndValue: {
    type: Number,
    default: 10,
  },
})

const dataKey = ref(TopLeastEngagementDropdown.find((i) => i.label === 'Engagements').api_key ?? 'engagement_rate')

const dropDownValues = computed(() => {
  if (!topEngagingPosts.value[dataKey.value]?.length) return []
  const range = []
  for (let i = 10; i <= 100; i += 10) {
    range.push(i)
  }
  return range
})

const getPosts = computed(() => {
  return topEngagingPosts.value[dataKey.value] || []
})

const handleDropDownClick = async (value) => {
  EventBus.$emit('set-top-posts-limit', value)
  postsLimit.value = value
  await fetchTopPerformingPins()
}

const handleSortClick = async(header) => {
  const apiKey = getHeaderApiKey(header)
  if(apiKey){
    dataKey.value = apiKey
    await fetchTopPerformingPins()
  }
}

const fetchTopPerformingPins = async () =>{
  isPostDataLoading.value = true
    await fetchMedia(routes.TOP_PERFORMING_PINS, {
      limit: postsLimit.value,
      order_by: dataKey.value,
    })
    isPostDataLoading.value = false
}

watch(
  () => [dateRange, route.params.accountId],
  async () => {
    isPostDataLoading.value = true
    selectedAccount.value = store.getters.getPinterestAccounts.items?.find(
      (item) => {
        return item.type.toLowerCase() === 'profile'
          ? item.pinterest_id === route.params.accountId
          : item.board_id === route.params.accountId
      }
    )
    await fetchTopPerformingPins()
  },
  { deep: true, immediate: true }
)

</script>

<template>
  <AnalyticsCardWrapper custom-class="overflow-x-auto">
    <template v-slot:card-header>
      <div class="flex justify-between items-center w-full">
        <div class="mx-1">
          <h2 class="text-secondary-cs text-base font-medium">
            Pinterest Top Pins {{ isReportView ? `(Top ${postsLimit})` : '' }}
          </h2>
          <p class="text-sm text-secondary-cs-700">
            Review your top pins published during the selected time period,
            based on the pin's lifetime performance.</p
          >
        </div>
        <CstDropdown
          v-if="
            !isReportView &&
            !isPostDataLoading &&
            getPosts.length > 0
          "
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          button-classes="flex !w-24 !rounded-none !bg-transparent my-0 !text-[#3A4872] !px-0 border-solid"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </template>
          <template v-slot:selected>
            <p class="text-base font-weight-500 capitalize">
              Top {{ !isReportView && postsLimit }}
            </p>
          </template>
          <template v-slot>
            <CstDropdownItem
              v-for="(value, index) in dropDownValues"
              :key="`activity_type_${index}`"
              @click="handleDropDownClick(value)"
            >
              <p class="text-gray-900 text-sm">{{ value }}</p>
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
    </template>
    <template v-slot:card-body>
      <AnalyticsPostsTable
        :selected-account="selectedAccount"
        :data-list="getPosts"
        :valid-headers-list="validPostsTableHeaders"
        :non-sortable-items="nonSortableItems"
        :mutate-header-titles="getHeaderTitles"
        :mutate-body-values="getBodyValues"
        :mutate-header-tooltips="getHeaderTooltips"
        :is-report-view="isReportView"
        :range-start-value="props.rangeStartValue"
        :range-end-value="props.rangeEndValue"
        :index-to-sort-by="2"
        custom-no-data-message = "No Pins Data Found."
        :is-loading="isPostDataLoading"
        @update-sort="handleSortClick"
      >
        <template
          v-slot:post-modal="{
            selectedAccount: selectedUserAccount,
            selectedPost,
          }"
        >
          <PinterestPostModal
            v-if="selectedPost"
            :selected-account="selectedUserAccount"
            :selected-post="selectedPost"
          />
        </template>
      </AnalyticsPostsTable>
    </template>
  </AnalyticsCardWrapper>
</template>
