<script>
import VideoModal from '@common/components/dialogs/onboarding/VideoModal'
import AiChatBlog from '@src/modules/discovery/components/share-sidebar/AiChatBlog.vue'
import { mapGetters, mapActions } from 'vuex'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import {
  publish,
  blogPosting,
} from '@src/modules/publish/store/states/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import { commonMethods } from '@common/store/common-methods'
import { expand } from 'vue-resource/src/lib/url-template'
import AIChatMain from '@src/modules/AI-tools/AIChatMain.vue'
import {swalAttributes} from "@common/constants/common-attributes";
import LabelOptions from '@src/modules/publish/components/options/LabelOptions.vue'
import MemberOptions from '@src/modules/publish/components/options/MemberOptions.vue'
import proxy from "@common/lib/http-common";
import {fetchAiTitlesUrl} from "@src/config/api-utils";
import {UNKNOWN_ERROR} from "@common/constants/messages";
import AssistantMain from "@src/modules/composer_v2/components/SidebarAssistant/AssistantMain.vue";
import VideoPreview from '../../../discovery/components/common/preview/VideoPreview'
import ImagePreview from '../../../discovery/components/common/preview/ImagePreview'
import ArticlePreview from '../../../discovery/components/common/preview/ArticlePreview'
import PocketPreview from '../../../discovery/components/common/preview/PocketPreview'

import BlogReviewPublish from '../../../publish/components/posting/blog/BlogReviewPublish'
import BlogDistribute from '../../../publish/components/posting/blog/BlogDistribute'

import CreatePublicationFolder from '../CreatePublicationFolder'

import UploadMediaModal from '../../../publish/components/media-library/components/UploadMediaModal'
import BlogSocialCampaign from './BlogSocialCampaign'
import BlogOptimize from './BlogOptimize'

import BlogPostEditor from './BlogPostEditor'
import Helper from '@/src/modules/discovery/components/share-sidebar/Helper.vue'

const Comments = () =>
  import('../../../publish/components/posting/collaboration/Comments')

const BlogArticlePreview = () =>
  import('../../../discovery/components/share-sidebar/BlogArticlePreview')
const HistoryVersion = () =>
  import('../../../discovery/components/share-sidebar/HistoryVersion')

const Seo = () => import('../../../discovery/components/share-sidebar/Seo')
const Tasks = () =>
  import('../../../publish/components/posting/collaboration/Tasks')
const Activities = () =>
  import('../../../publish/components/posting/collaboration/Activities')
const InsertFileModal = () =>
  import('../../../publish/components/widget/file/InsertFileModal')
const PasteFromWord = () =>
  import('../../../publish/components/widget/file/PasteFromWord')

export default {
  components: {
    AssistantMain,
    AIChatMain,
    UploadMediaModal,
    PasteFromWord,
    InsertFileModal,
    BlogArticlePreview,
    HistoryVersion,
    LabelOptions,
    VideoPreview,
    ArticlePreview,
    PocketPreview,
    VideoModal,
    ImagePreview,
    BlogDistribute,
    BlogOptimize,
    BlogReviewPublish,
    BlogSocialCampaign,
    MemberOptions,
    Helper,
    Seo,
    Comments,
    Tasks,
    Activities,
    CreatePublicationFolder,
    BlogPostEditor,
    AiChatBlog,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => (vm.previousRoute = from))
  },

  data() {
    return {
      closeTooltipStatus: false,
      utm_presets: 'create',
      hashtag_select: 'saved',
      schedule_radio: 'post_now_r',
      repeat_post: 'no_repeat',
      blogShareTabStatus: '',
      toggleModalSidebarStatus: false,

      validations: {
        postTitle: false,
      },
      messages: {
        postTitle: 'Please enter post title',
      },
      previousRoute: null,
      step: 0,
      isSidebarExpanded: false,
      aiSuggestedTitles: [],
      aiTitleLoader: false,
      showSuggestedTitleDialog: false
    }
  },

  computed: {
    ...mapGetters([
      'getBlogPostingDetails',
      'getPublishSelection',
      'getPublicationLastUpdated',
      'getPublicationFolders',
      'getWorkspaces',
      'getPublishLoaders',
      'getPlannerLoaders',
      'getBlogAutoSaveInterval',
      'getEditorTypingInterval',
      'getEditorTypingStatus',
      'getComposerActivePlanMember',
      'getSocketId',
      'getProfile',
      'getPlanControlRequestData',
      'getPlanControl',
      'getComments',
      'getCommentsLoader',
      'getTasks',
      'getTaskscCompletedCount',
      'getPublications',
      'getBlogPostingHTML',
    ]),
    checkPostStatus() {
      return (
          this.getPublishSelection.status !== '' ||
          this.getPublishSelection.repost ||
          this.getPublishSelection.reopen
      )
    },
    checkPlanRequestReceived() {
      const status =
          this.getPlanControlRequestData &&
          this.getPlanControlRequestData.receiver_user_id &&
          this.getPlanControlRequestData.receiver_user_id === this.getProfile._id
      if (status) this.$bvModal.show('lockStatusModal')
      return status
    },
    checkRequestAlreadySent() {
      return (
          this.getPlanControlRequestData &&
          this.getPlanControlRequestData.requested_user_id === this.getProfile._id
      )
    },
  },

  watch: {
    'getPublishSelection.folderId'() {
      this.blogPostAutoSave()
    },

    'getBlogPostingDetails.title'(value) {
      if (value && value.length > 0) {
        this.validations.postTitle = false
      }
      this.emitBlogChanges()
    },

    'getBlogPostingDetails.optionalDescription'() {
      this.emitBlogChanges()
    },

    getPlanControl(value) {
      if (value === false) {
        this.closeTooltipStatus = true
        setTimeout(
            function () {
              this.closeTooltipStatus = false
            }.bind(this),
            5000
        )

        // eslint-disable-next-line no-undef
        $('.assistant_main_block .dragable_box').draggable('disable')
      } else {
        this.closeTooltipStatus = false
        // eslint-disable-next-line no-undef
        $('.assistant_main_block .dragable_box').draggable('enable')
      }
    },
  },

  created() {
    if (this.$route.params.type) {
      let type = null
      if (this.$route.params.typeId) {
        if (this.$route.params.type === 'comment') type = 'comment'
        if (this.$route.params.type === 'task') type = 'task'
      } else {
        if (this.$route.params.type === 'comments') type = 'comment'
        if (this.$route.params.type === 'tasks') type = 'task'
      }
      if (type) this.socialShareTab(type, true)
    }
    this.initializeComposerBlogSection()
    this.broadcastPlanEditStatus()

    if (this.getPublications.folder.id && !this.getPublishSelection.folderId) {
      this.setPublicationFolderId(this.getPublications.folder.id)
    }
  },

  mounted() {
    const thiss = this
    setTimeout(function () {
      // eslint-disable-next-line no-undef
      $('.blog_article_preview,.history_version_modal').on(
          'hidden.bs.modal',
          function () {
            thiss.blogShareTabStatus = ''
          }
      )
    }, 1000)
    window.webSockets.on('disconnect', () => {
      console.log('ComposerMain: Broadcasting all events again.')
      this.loadUserBroadCasters()
    })
    commonMethods.toggleAiChat(true)
  },
  updated() {
    commonMethods.toggleAiChat(true)
  },
  beforeUnmount() {
    window.webSockets.removeListener('disconnect')

    // to destroy interval using for saving publication
    if (this.getBlogAutoSaveInterval) {
      window.clearTimeout(this.getBlogAutoSaveInterval)
      this.$store.commit(composer.SET_BLOG_AUTO_SAVE_INTERVAL, null)
    }

    if (this.getEditorTypingInterval) {
      window.clearTimeout(this.getEditorTypingInterval)
      this.$store.commit(composer.SET_EDITOR_TYPING_INTERVAL, null)
    }
    this.$store.commit(composer.SET_EDITOR_TYPING_STATUS, false)
    this.closePlanEmit(this.getPublishSelection.plan_id)

    commonMethods.toggleAiChat(false)
  },
  methods: {
    expand,
    ...mapActions(['setPublicationFolderId']),
    openCreateFolderModal() {
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, false)
      EventBus.$emit('createFolder')
    },
    closeTooltip() {
      this.closeTooltipStatus = false
    },
    toggleModalSidebar() {
      if (this.isSidebarExpanded && this.blogShareTabStatus === 'chat') {
        this.isSidebarExpanded = false
        return
      }
      if (!this.toggleModalSidebarStatus && this.blogShareTabStatus === '') {
        this.blogShareTabStatus = 'assistant'
      }
      this.toggleModalSidebarStatus = !this.toggleModalSidebarStatus
    },

    socialShareTab(status, initialize = false) {
      if (this.blogShareTabStatus === status && this.toggleModalSidebarStatus) {
        this.toggleModalSidebarStatus = false
        this.blogShareTabStatus = ''
        return
      }
      this.toggleModalSidebarStatus = true
      this.blogShareTabStatus = status

      if (!initialize) {
        switch (status) {
          case 'comment':
            this.$router.push({
              name: 'composerBlog',
              params: {id: this.$route.params.id, type: 'comments'},
            })
            break
          case 'task':
            this.$router.push({
              name: 'composerBlog',
              params: {id: this.$route.params.id, type: 'tasks'},
            })
            break
          default:
            this.$router.push({
              name: 'composerBlog',
              params: {id: this.$route.params.id},
            })
        }
      }
      this.checkComposerDragDrop()
    },

    previewBlogCuration(status) {
      console.debug('Method:previewBlogCuration')
      this.blogShareTabStatus = status
      this.toggleModalSidebarStatus = false

      if (this.getBlogPostingHTML.length) {
        this.$store.commit(
            blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS,
            false
        )
      } else {
        this.$store.commit(
            blogPosting.SET_BLOG_POSTING_CONTENT_EXISTENCE_STATUS,
            true
        )
      }
      window.iframely && window.iframely.load()
      // eslint-disable-next-line no-undef
      $('#blog_article_preview').modal('show')
    },

    historyVersionModal(status) {
      console.debug('Method:historyVersionModal')
      this.blogShareTabStatus = status
      this.toggleModalSidebarStatus = false
      if (this.getPublishSelection.plan_id) {
        this.$store.dispatch(
            'planVersionHistory',
            this.getPublishSelection.plan_id
        )
        // eslint-disable-next-line no-undef
        $('#history_version_modal').modal('show')
      }
    },

    changeBlogStage(stage, step) {
      this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, stage)
      this.step = step
    },
    proceedBlogStage() {
      const currentStage = this.getBlogPostingDetails.stage
      switch (currentStage) {
        case 'content':
          this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'settings')
          this.step = 1
          break
        case 'settings':
          this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'distribution')
          this.step = 2
          break
        case 'distribution':
          this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'publish')
          this.step = 3
          break
      }
    },
    validateProcessBlogPost(draft = false) {
      console.debug('Method:validateProcessBlogPost')
      if (this.getBlogPostingDetails.title.trim() === '') {
        this.validations.postTitle = true
        return false
      }
      if (draft) this.processBlogPost(true, true, false, '', true)
      else this.processBlogPost()
    },

    getPlanViewers() {
      const stateObject = this
      const userIds = []
      const usersData = []
      this.getComposerActivePlanMember.forEach(function (element) {
        if (userIds.indexOf(element.user_id) === -1) {
          userIds.push(element.user_id)
          stateObject.getWorkspaces?.activeWorkspace?.members?.forEach((member) => {
              if (member?.user?._id === element?.user_id) {
                usersData.push(member.user)
              }
          })
        }
      })
      return usersData
    },

    checkPlanControl() {
      console.debug('Method:checkPlanControl')
      const socketId = this.getSocketId
      const composerActivePlanMember = this.getComposerActivePlanMember
      if (
          socketId &&
          composerActivePlanMember &&
          composerActivePlanMember.length > 0
      ) {
        const planControl = composerActivePlanMember.find((item) => {
          return item.control === true
        })
        if (planControl) {
          if (planControl.socket_id === socketId) {
            this.$store.commit(composer.SET_PLAN_CONTROL, true)
            return true
          } else if (planControl.user_id === this.getProfile._id) {
            this.$store.commit(composer.SET_PLAN_CONTROL, false)
            return 'otherTab'
          }
          this.$store.commit(composer.SET_PLAN_CONTROL, false)
          return 'otherUser'
        }
        this.$store.commit(composer.SET_PLAN_CONTROL, false)
        return 'none'
      }
      this.$store.commit(composer.SET_PLAN_CONTROL, true)
      return true
    },

    takePlanControl() {
      const data = {
        socket_id: this.getSocketId,
        plan_id: this.getPublishSelection.plan_id,
      }
      this.saveSocketLogs(
          {
            workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
            data,
          },
          'takePlanControl'
      )

      window.webSockets.emit('take_plan_control', data)
      this.$bvModal.hide('lockStatusModal')
    },

    requestPlanControl() {
      this.$store.commit(composer.SET_PLAN_CONTROL_REQUEST_DATA, null)
      let userId = ''
      const planControl = this.getComposerActivePlanMember.find((item) => {
        return item.control === true
      })
      if (planControl) userId = planControl.user_id

      const data = {
        socket_id: this.getSocketId,
        plan_id: this.getPublishSelection.plan_id,
        requested_user_id: this.getProfile._id,
        receiver_user_id: userId,
      }
      this.saveSocketLogs(
          {
            workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
            data,
          },
          'request_plan_control'
      )

      window.webSockets.emit('request_plan_control', data)
    },

    planControlUsername() {
      const planControl = this.getComposerActivePlanMember.find((item) => {
        return item.control === true
      })
      if (planControl) {
        let user = null
        this.getWorkspaces.activeWorkspace.members.forEach((member) => {
          if (member?.user?._id === planControl?.user_id) {
            user = member.user
          }
        })
        if (user && user.firstname) return user
      }
      return 'user'
    },

    getRequestedUsername() {
      const getPlanControlRequestData = this.getPlanControlRequestData
      if (getPlanControlRequestData) {
        let user = null
        this.getWorkspaces.activeWorkspace.members.forEach((member) => {
          if (
              member?.user?._id === getPlanControlRequestData?.requested_user_id
          ) {
            user = member.user
          }
        })
        if (user) return user
      }
      return 'user'
    },

    acceptRequestPlanControl() {
      const data = {
        socket_id: this.getSocketId,
        plan_id: this.getPublishSelection.plan_id,
        requested_socket_id: this.getPlanControlRequestData.socket_id,
      }
      this.saveSocketLogs(
          {
            workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
            data,
          },
          'accept_request_plan_control'
      )
      window.webSockets.emit('accept_request_plan_control', data)
    },

    cancelRequestPlanControl() {
      const data = {
        plan_id: this.getPublishSelection.plan_id,
      }
      this.saveSocketLogs(
          {
            workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
            data,
          },
          'cancel_request_plan_control'
      )
      window.webSockets.emit('cancel_request_plan_control', data)
    },

    checkSelected(folderId) {
      return (this.getPublishSelection.folderId === folderId)
    },
    selectCampaign(folderId) {
      if (
          this.getPublishSelection.folderId &&
          this.getPublishSelection.folderId === folderId
      ) {
        this.setPublicationFolderId(null)
      } else this.setPublicationFolderId(folderId)
    },
    lockTooltipMessage() {
      const control = this.checkPlanControl()
      if (control === 'otherTab') {
        return 'You are editing this story in another tab, window or device.'
      } else if (control === 'none') {
        return 'No one is editing the story.'
      } else if (control === 'otherUser') {
        return (
            '<strong>' +
            this.planControlUsername().firstname +
            ' ' +
            this.planControlUsername().lastname +
            '</strong> is editing the story.'
        )
      }
      this.closeTooltipStatus = false
      return ''
    },
    selectCampaignForPost(folderId) {
      console.debug('Method:selectCampaignForPost', folderId)
      const folderValue =
          this.getPublishSelection.folderId &&
          this.getPublishSelection.folderId === folderId
              ? null
              : folderId
      console.debug('folder value is ', folderValue)
      this.$store.commit(publish.SET_PUBLISH_CAMPAIGN, folderValue)
    },
    toggleSidebarSize() {
      this.isSidebarExpanded = !this.isSidebarExpanded
    },
    async fetchAiTitle(event) {
      this.showSuggestedTitleDialog = true
      this.aiTitleLoader = true
      this.aiSuggestedTitles = []
      const response = await proxy.get(
          `${fetchAiTitlesUrl}?workspace_id=${this.$store.getters.getWorkspaces.activeWorkspace._id}&title=${this.getBlogPostingDetails.title.trim()}`
      )
      if (response.data.status && response.data.suggeted_titles.length) {
        this.aiTitleLoader = false
        this.aiSuggestedTitles = response.data.suggeted_titles.slice(0, 5)
        if (response.data.limits.used) {
          this.$store.getters.getPlan.used_limits.caption_generation_credit = response.data.limits.used
        }
        return
      } else {
        this.aiTitleLoader = false
        this.aiSuggestedTitles = []
        this.showSuggestedTitleDialog = false
        this.$store.dispatch('toastNotification', {
          message: response.data?.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
      this.aiTitleLoader = true
      this.aiSuggestedTitles = []
    },
    handleTitleClickAway() {
      this.showSuggestedTitleDialog = false
    },
    async handleBlogClose(){
      const res = await this.$root.$bvModal.msgBoxConfirm(
          'Make sure, your changes are auto saved or you have manually saved it (draft) before exiting',
          {
            title: 'Close Composer',
            ...swalAttributes(),
            cancelTitle: 'Cancel',
            okTitle: 'Exit Anyway',
          }
      )
      if(!res) return
      this.composerClose(this.previousRoute)
    }
  }
}
</script>

<template>
  <div>
    <b-modal
      id="lockStatusModal"
      no-close-on-backdrop
      modal-class="alertBox lockStatusModal"
      hide-footer
      hide-header
    >
      <button
        v-if="checkPlanRequestReceived"
        type="button"
        class="close"
        data-dismiss="modal"
        @click.prevent="cancelRequestPlanControl"
        >&times;
      </button>
      <button
        v-else
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('lockStatusModal')"
        >&times;</button
      >
      <div class="modal_body">
        <template v-if="checkPlanRequestReceived">
          <div class="col-sm-12 alert_content">
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>

              <div class="profile_picture d-flex align-items-start">
                <div class="picture_block picture_block_background">
                  <div
                    v-if="teamMemberImageById(getRequestedUsername()._id)"
                    class="img"
                    :style="{
                      background:
                        'url(' +
                        teamMemberImageById(getRequestedUsername()._id) +
                        ')',
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </div>
                <div class="text_block">
                  <p class="text"
                    ><strong
                      >{{ getRequestedUsername().firstname }}
                      {{ getRequestedUsername().lastname }}</strong
                    >
                    would like to start editing now. If you're ready to take a
                    break, you can grant access now. If not, cancel this request
                    and they'll get access when you're done.</p
                  >
                </div>
              </div>

              <!---->
              <!--<p>{{getRequestedUsername()}} would like to start editing now. If you're ready to take a break, you can grant access now.-->
              <!--If not, cancel this request and they'll get access when you're done.</p>-->
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click.prevent="cancelRequestPlanControl"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                @click.prevent="acceptRequestPlanControl"
              >
                <span>Accept</span>
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if="checkPlanControl() === 'otherTab'"
            class="col-sm-12 alert_content"
          >
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>
              <p
                >You are editing this story in another tab, window or device.</p
              >
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click="$bvModal.hide('lockStatusModal')"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                @click.prevent="takePlanControl"
              >
                <span>Take Over</span>
              </button>
            </div>
          </div>

          <div
            v-else-if="checkPlanControl() === 'none'"
            class="col-sm-12 alert_content"
          >
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>
              <p
                >No one is editing the story. Take control by pressing the
                button below.</p
              >
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click="$bvModal.hide('lockStatusModal')"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                @click.prevent="takePlanControl"
              >
                <span>Take Over</span>
              </button>
            </div>
          </div>

          <div
            v-else-if="checkPlanControl() === 'otherUser'"
            class="col-sm-12 alert_content"
          >
            <div class="col-sm-12 alert_content">
              <i class="fa fa-lock"></i>
              <div class="profile_picture d-flex align-items-start">
                <div class="picture_block picture_block_background">
                  <div
                    v-if="teamMemberImageById(planControlUsername()._id)"
                    class="img"
                    :style="{
                      background:
                        'url(' +
                        teamMemberImageById(planControlUsername()._id) +
                        ')',
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </div>
                <div class="text_block">
                  <p class="text"
                    ><strong
                      >{{ planControlUsername().firstname }}
                      {{ planControlUsername().lastname }}</strong
                    >
                    is already editing this post. Do you want to take over?</p
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-12 footer_box">
              <button
                class="btn btn_cancel"
                @click="$bvModal.hide('lockStatusModal')"
              >
                <span>No, Close!</span>
              </button>
              <button
                class="btn gradient_btn btn_loader loader_right"
                :disabled="checkRequestAlreadySent"
                @click.prevent="requestPlanControl"
              >
                <span>Request Access</span>
              </button>
              <button
                v-if="checkRequestAlreadySent"
                class="btn force_btn btn_loader loader_right"
                @click.prevent="takePlanControl"
              >
                <span>Force Take Over</span>
              </button>
            </div>
          </div>
        </template>
      </div>
    </b-modal>

    <template v-if="blogShareTabStatus === 'assistant'">
      <VideoPreview></VideoPreview>
      <ImagePreview></ImagePreview>

      <ArticlePreview :section-name="'composerSection'"></ArticlePreview>
      <PocketPreview></PocketPreview>
    </template>

    <VideoModal id="create-blog-post" position="bottom_left">
      <template v-slot:heading>
        <h2>Publish Blog Post</h2>
      </template>
      <template v-slot:description>
      </template>
      <template v-slot:iframe>
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/s4Sn9393eOI"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </VideoModal>

    <BlogArticlePreview></BlogArticlePreview>
    <HistoryVersion></HistoryVersion>
    <UploadMediaModal type="BlogEditorFile" />
    <InsertFileModal type="BlogEditorFile"></InsertFileModal>
    <PasteFromWord></PasteFromWord>
    <CreatePublicationFolder></CreatePublicationFolder>

    <div class="composer_component composer_step_page top_left_design_box">
      <div class="component_inner">
        <template v-if="getPlannerLoaders.edit_plan">
          <div class="c_header d-flex align-items-center">
            <div class="head_left">
              <h2>Blog Post Composer</h2>
            </div>
          </div>
          <div class="c_body basic_form">
            <div class="height page_content">
              <div class="height d-flex align-items-stretch">
                <div class="content">
                  <div
                    class="content_container content_container_drop"
                    style="max-width: 900px"
                  >
                    <beat-loader :color="'#436aff'"></beat-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <!--body-->
          <div class="flex w-full h-screen max-h-screen overflow-hidden">
            <div class="flex flex-1 overflow-x-hidden">
              <div
                id="blog-body"
                ref="blogBody"
                class="c_body basic_form blog_steps_common !w-full"
              >
                <!--    header -->
                <div
                  class="w-100 flex justify-between items-center pl-4 pr-6 h-[60px] bg-[#F4F6FA] gap-4"
                  style="border-bottom: 1px solid #dde3e7"
                >
                  <h4 class="text-xl font-medium text-[#26303E] whitespace-nowrap"
                    >Blog Composer</h4
                  >
                  <!--  stepper-->
                  <ul
                    :class="{ locked_status: !getPlanControl }"
                    class="flex items-center"
                  >
                    <li
                      class="!flex items-center cursor-pointer"
                      data-cy="write"
                      @click.prevent="changeBlogStage('content', 0)"
                    >
                      <div
                        class="w-[1.25rem] h-[1.25rem] rounded-full flex justify-center items-center"
                        :class="
                          step > 0 || getBlogPostingDetails.stage === 'content'
                            ? 'bg-[#2961D2]'
                            : 'bg-[#C2C8CD]'
                        "
                      >
                        <img
                          v-if="getBlogPostingDetails.stage === 'content'"
                          src="@src/assets/img/chat_bot/dash.svg"
                          alt="tick"
                        />
                        <img
                          v-else
                          src="@src/assets/img/chat_bot/tick.svg"
                          alt="dash"
                        />
                      </div>
                      <p
                        class="font-medium ml-[0.438rem] whitespace-nowrap"
                        :class="
                          getBlogPostingDetails.stage === 'content'
                            ? 'text-[#2961D2]'
                            : 'text-[#595C5F]'
                        "
                        >Write</p
                      >
                      <div class="flex items-center justify-center gap-0 mx-4">
                        <img src="@src/assets/img/chat_bot/line.svg" alt="" class="flex-shrink max-w-full w-auto line-img">
                        <img src="@src/assets/img/chat_bot/arrow-head.svg" alt="">
                      </div>
                    </li>
                    <li
                      class="!flex items-center cursor-pointer"
                      data-cy="optimize"
                      @click.prevent="changeBlogStage('settings', 1)"
                    >
                      <div
                        class="w-[1.25rem] h-[1.25rem] rounded-full flex justify-center items-center"
                        :class="
                          step > 1 || getBlogPostingDetails.stage === 'settings'
                            ? 'bg-[#2961D2]'
                            : 'bg-[#C2C8CD]'
                        "
                      >
                        <img
                          v-if="
                            step <= 1 ||
                            getBlogPostingDetails.stage === 'settings'
                          "
                          src="@src/assets/img/chat_bot/dash.svg"
                          alt="tick"
                        />
                        <img
                          v-else
                          src="@src/assets/img/chat_bot/tick.svg"
                          alt="dash"
                        />
                      </div>
                      <p
                        class="font-medium ml-[0.438rem] whitespace-nowrap"
                        :class="
                          getBlogPostingDetails.stage === 'settings'
                            ? 'text-[#2961D2]'
                            : 'text-[#595C5F]'
                        "
                        >Optimize</p
                      >
                      <div class="flex items-center justify-center gap-0 mx-4">
                        <img src="@src/assets/img/chat_bot/line.svg" alt="" class="flex-shrink max-w-full w-auto line-img">
                        <img src="@src/assets/img/chat_bot/arrow-head.svg" alt="">
                      </div>
                    </li>
                    <li
                      class="!flex items-center cursor-pointer"
                      data-cy="distribute"
                      @click.prevent="changeBlogStage('distribution', 2)"
                    >
                      <div
                        class="w-[1.25rem] h-[1.25rem] rounded-full flex justify-center items-center"
                        :class="
                          step > 2 ||
                          getBlogPostingDetails.stage === 'distribution'
                            ? 'bg-[#2961D2]'
                            : 'bg-[#C2C8CD]'
                        "
                      >
                        <img
                          v-if="
                            step <= 2 ||
                            getBlogPostingDetails.stage === 'distribution'
                          "
                          src="@src/assets/img/chat_bot/dash.svg"
                          alt="dash"
                        />
                        <img
                          v-else
                          src="@src/assets/img/chat_bot/tick.svg"
                          alt="dash"
                        />
                      </div>
                      <p
                        class="font-medium ml-[0.438rem] whitespace-nowrap"
                        :class="
                          getBlogPostingDetails.stage === 'distribution'
                            ? 'text-[#2961D2]'
                            : 'text-[#595C5F]'
                        "
                        >Distribute</p
                      >
                      <div class="flex items-center justify-center gap-0 mx-4">
                        <img src="@src/assets/img/chat_bot/line.svg" alt="" class="flex-shrink max-w-full w-auto line-img">
                        <img src="@src/assets/img/chat_bot/arrow-head.svg" alt="">
                      </div>
                    </li>
                    <li
                      class="!flex items-center cursor-pointer"
                      :class="{
                        active: getBlogPostingDetails.stage === 'publish',
                      }"
                      data-cy="review-publish"
                      @click.prevent="changeBlogStage('publish', 3)"
                    >
                      <div
                        class="w-[1.25rem] h-[1.25rem] rounded-full flex justify-center items-center"
                        :class="
                          getBlogPostingDetails.stage === 'publish'
                            ? 'bg-[#2961D2]'
                            : 'bg-[#C2C8CD]'
                        "
                      >
                        <img
                          v-if="
                            step <= 1 ||
                            getBlogPostingDetails.stage === 'publish'
                          "
                          src="@src/assets/img/chat_bot/dash.svg"
                          alt="dash"
                        />
                        <img
                          v-else
                          src="@src/assets/img/chat_bot/tick.svg"
                          alt="dash"
                        />
                      </div>
                      <p
                        class="font-medium ml-[0.438rem] whitespace-nowrap"
                        :class="
                          getBlogPostingDetails.stage === 'publish'
                            ? 'text-[#2961D2]'
                            : 'text-[#595C5F]'
                        "
                        >Review & Publish</p
                      >
                    </li>
                  </ul>
                  <div class="flex">
                    <button
                      class="bg-[#2961D2] border-0 px-4 text-lg text-white capitalize flex items-center whitespace-nowrap"
                      :class="{ 'opacity-50 cursor-not-allowed': getBlogPostingDetails.stage === 'publish' }"
                      style="
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                      "
                      :disabled="getBlogPostingDetails.stage === 'publish'"
                      @click.prevent="proceedBlogStage"
                    >
                      Next
                    </button>
                    <div class="relative group">
                      <button
                        class="bg-[#2961D2] border-0 text-lg text-white capitalize flex items-center px-[10.5px] py-4 group-hover:bg-[#214EA8]"
                        style="
                          border-left: 0.5px solid #ffffff !important;
                          border-top-right-radius: 8px;
                          border-bottom-right-radius: 8px;
                        "
                        :disabled="getBlogPostingDetails.stage === 'publish'"
                      >
                        <img
                          src="@src/assets/img/chat_bot/a-down.svg"
                          alt="icon"
                        />
                      </button>
                      <div
                        v-if="getPublishSelection.status !== 'published'"
                        class="w-[160px] flex-col p-4 pb-0 absolute right-0 rounded bottom-[-3rem] z-10 bg-white hidden group-hover:!flex"
                        style="
                          border: 0.2px solid #eceef5;
                          box-shadow: 0px 3px 20px rgba(0, 23, 83, 0.15);
                          border-radius: 8px 0px 8px 8px;
                        "
                      >
                        <div
                          class="pb-4 cursor-pointer hover:font-bold flex justify-center"
                          :class="{
                            'pointer-events-none':
                              getPublishLoaders.processSavePublication ||
                              getPublishLoaders.processBlogPost,
                          }"
                          @click.prevent="validateProcessBlogPost(true)"
                        >
                          <span class="text-[#3C4549] text-sm"
                            >Save as draft
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <transition name="slide">
                  <div
                    class="height page_content"
                    :class="{ open: toggleModalSidebarStatus }"
                  >
                    <div class="height d-flex align-items-stretch">
                      <div class="content" :class="{ '!overflow-y-auto': getBlogPostingDetails.stage !== 'content' }">
                        <div class="flex items-center mb-4 w-full">
                          <div
                              class="flex rounded-lg w-[50%] bg-white px-3"
                              style="border: 1px solid #dde3e7"
                          >
                            <input
                                id="c_title"
                                v-model="getBlogPostingDetails.title"
                                class="border-none rounded-lg w-100 h-100 pr-3 py-4"
                                type="text"
                                placeholder="Add your title here"
                                name="title"
                                :disabled="!getPlanControl"
                                data-cy="blog-title"
                            />
                            <img
                                v-show="getBlogPostingDetails.title.trim().length < 3"
                                v-tooltip="
                              'Please enter a blog title with at least 3 characters before asking AI for title suggestions.'
                            "
                                src="@src/assets/img/chat_bot/bot.svg"
                                alt="bot"
                            />
                            <img
                                v-show="getBlogPostingDetails.title.trim().length >= 3"
                                v-tooltip="
                              'Ask AI to suggest more suitable titles.'
                            "
                                src="@src/assets/img/chat_bot/bot-active.svg"
                                alt="bot"
                                class="cursor-pointer"
                                @click="fetchAiTitle"
                            />
                          </div>
                          <div v-if="getBlogPostingDetails.title.trim().length >= 3 && showSuggestedTitleDialog" v-click-away="handleTitleClickAway" class="relative">
                            <div class="absolute -top-6 left-1 flex z-1050">
                              <img
                                  src="@src/assets/img/chat_bot/left-edge.svg"
                                  alt="arrow"
                                  class="place-self-start"
                              />
                              <div class="bg-white w-full min-w-[300px] max-w-[300px] px-4 rounded-md shadow" style="border-top:4px solid #2961D2;">
                                <span class="block text-zinc-700 text-sm font-extrabold leading-none tracking-tight py-2">AI Suggested Titles</span>
                                <template v-if="aiTitleLoader">
                                   <clip-loader
                                       class="center_loader my-2"
                                                color="#436aff"
                                                :size="'30px'"></clip-loader>
                                </template>
                                <template v-else>
                                  <template v-for="(title,index) in aiSuggestedTitles" :key="index">
                                    <span
                                        class="block text-sm text-zinc-700 font-normal py-1 cursor-pointer hover:scale-[1.01] hover:text-[#16181b]"
                                        @click="() => {
                                      getBlogPostingDetails.title = title
                                    }">{{title}}</span>
                                  </template>
                                </template>

                              </div>

                            </div>
                          </div>
                        </div>
                        <span
                          v-if="validations.postTitle"
                          class="input-error absolute z-10 !mt-0"
                        >
                          {{ messages.postTitle }}
                        </span>
                        <div
                          class="content_container"
                          :class="{ locked_status: !getPlanControl }"
                        >
                          <BlogPostEditor
                            :class="{
                              hide: getBlogPostingDetails.stage !== 'content',
                            }"
                          />

                          <BlogDistribute
                            :no-model="false"
                            type="composer"
                            :variations-options="true"
                            :class="{
                              hide:
                                getBlogPostingDetails.stage !== 'distribution',
                            }"
                          ></BlogDistribute>
                          <BlogOptimize
                            :class="{
                              hide: getBlogPostingDetails.stage !== 'settings',
                            }"
                            class="with_opt_box_shadow optimize_block"
                          ></BlogOptimize>
                          <BlogSocialCampaign
                            :class="{
                              hide: getBlogPostingDetails.stage !== 'social',
                            }"
                          ></BlogSocialCampaign>
                          <BlogReviewPublish
                            type="composer"
                            :class="{
                              hide: getBlogPostingDetails.stage !== 'publish',
                            }"
                            @validate-process-blog-post="validateProcessBlogPost"
                          ></BlogReviewPublish>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <div class="flex flex-row h-screen relative">
              <!-- modal view -->
              <div
                id="blog-right-section"
                ref="blogRightSection"
                class="h-screen blog-ai-class bg-white"
              >
                <div
                  id="blog-modal-view"
                  ref="blogModalView"
                  class="social_post_side_filter basic_form h-full"
                  :class="{
                    open: toggleModalSidebarStatus,
                    '!min-w-[48.75rem] !w-[48.75rem]':
                      isSidebarExpanded && blogShareTabStatus === 'chat',
                  }"
                >
                  <div
                    class="filter_inner"
                    :class="{
                      '!border-none':
                        toggleModalSidebarStatus &&
                        blogShareTabStatus === 'chat',
                    }"
                  >
                    <div
                      v-if="toggleModalSidebarStatus"
                      v-tooltip="'Collapse'"
                      class="absolute left-[-13px] top-[1rem] rounded-full w-[1.875rem] h-[1.875rem] flex justify-center items-center cursor-pointer bg-white"
                      style="border: 1px solid #eaf0f6; z-index: 20000"
                      @click="toggleModalSidebar"
                    >
                      <i class="cs-angle-right w-[0.688rem] text-[#979CA0]"></i>
                    </div>
                    <Helper v-if="blogShareTabStatus === 'helper'" />
                    <AIChatMain
                      v-if="blogShareTabStatus === 'chat'"
                      :type="'blog'"
                      :plan-id="getPublishSelection.plan_id"
                      :sidebar-status="toggleModalSidebarStatus"
                      :toggle-sidebar-size="toggleSidebarSize"
                      :is-sidebar-expanded="isSidebarExpanded"
                    >
                      <template v-slot:child="slotProps">
                        <AiChatBlog :parent-state="slotProps" />
                      </template>
                    </AIChatMain>
                    <AssistantMain v-if="blogShareTabStatus === 'assistant'" />
                    <Seo v-if="blogShareTabStatus === 'seo'"></Seo>
                    <Comments
                      v-if="blogShareTabStatus === 'comment'"
                      :comment-id="$route.params.typeId"
                    ></Comments>
                    <Tasks
                      v-if="blogShareTabStatus === 'task'"
                      :task-id="$route.params.typeId"
                    ></Tasks>
                    <Activities
                      v-if="blogShareTabStatus === 'activity'"
                    ></Activities>
                  </div>
                </div>
              </div>
              <!-- sidebar -->
              <div class="z-99 c_sidebar !pt-0 overflow-auto overflow-x-hidden no-scrollbar" :style="{ borderLeft: toggleModalSidebarStatus ? '1px solid #DDE3E7' : 'none' }">
                <div
                    v-tooltip="'Close Blog Post'"
                  class="w-100 bg-[#F4F6FA] h-[60px] flex justify-center items-center cursor-pointer"
                  style="border: 1px solid #eceef5"
                  @click.prevent="handleBlogClose"
                >
                  <img
                    src="@src/assets/img/chat_bot/Vector.svg"
                    alt="close"
                    class="w-4 h-4"
                  />
                </div>
                <ul>
                  <li
                      v-tooltip="'AI Writing Assitant'"
                    class="hover:bg-[#E3F2FF] flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'chat' }"
                    @click="socialShareTab('chat')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/ai_writing_assistant.svg"
                      alt="ai-chat-widget"
                      class="w-14 h-14"
                    />
                  </li>
                  <li
                      v-tooltip="'Assistant'"
                    class="hover:bg-[#E3F2FF] relative flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'assistant' }"
                    @click="socialShareTab('assistant')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/Union.svg"
                      alt="ai-chat-widget"
                      class="w-[1.688rem] h-[1.688rem]"
                    />
                  </li>
                  <li
                      v-tooltip="'SEO'"
                    class="hover:bg-[#E3F2FF] relative flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'seo' }"
                    @click="socialShareTab('seo')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/seo.svg"
                      alt="ai-chat-widget"
                      class="w-[1.563rem] h-[1.5rem]"
                    />
                  </li>
                  <li
                      v-tooltip="'Tasks'"
                    class="hover:bg-[#E3F2FF] relative flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'task' }"
                    @click="socialShareTab('task')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/tasks.svg"
                      alt="ai-chat-widget"
                      class="w-5 h-5"
                    />
                  </li>
                  <li
                      v-tooltip="'Comments'"
                    class="hover:bg-[#E3F2FF] relative flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'comment' }"
                    @click="socialShareTab('comment')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/comments-new.svg"
                      alt="ai-chat-widget"
                      class="w-5 h-5"
                    />
                  </li>
                  <li
                      v-tooltip="'Preview'"
                    class="hover:bg-[#E3F2FF] relative flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'preview' }"
                    @click.prevent="previewBlogCuration('preview')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/preview.svg"
                      alt="ai-chat-widget"
                      class="w-5 h-5"
                    />
                  </li>
                  <li
                      v-tooltip="'Activities'"
                    class="hover:bg-[#E3F2FF] relative flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'activity' }"
                    @click.prevent="socialShareTab('activity')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/activities.svg"
                      alt="ai-chat-widget"
                      class="w-5 h-5 m-3"
                    />
                  </li>
                  <li
                      v-tooltip="'Version History'"
                    class="hover:bg-[#E3F2FF] relative flex justify-center items-center w-14 h-14"
                    :class="{ active: blogShareTabStatus === 'history' }"
                    @click.prevent="historyVersionModal('history')"
                  >
                    <img
                      src="@src/assets/img/chat_bot/history.svg"
                      alt="ai-chat-widget"
                      class="w-5 h-5"
                    />
                  </li>
                </ul>
                <ul class="flex flex-col" style="border-top: 1px solid #dde3e7">
                  <li
                      v-tooltip="'Labels'"
                    class="hover:bg-[#E3F2FF] flex justify-center items-center w-14 h-14"
                    :class="{ locked_status: !getPlanControl }"
                  >
                    <div data-toggle="dropdown" class="w-100 h-100 flex items-center justify-center">
                      <img
                        src="@src/assets/img/chat_bot/labels.svg"
                        alt="ai-chat-widget"
                        class="w-5 h-5 ml-1"
                      />
                    </div>
                    <div
                      class="prevent_close_dropdown ml-2 default_style_dropdown label_dropdown profiles_list_header absolute top-0 left-0"
                    >
                      <div class="dropdown-menu dropdown-menu-right">
                        <LabelOptions></LabelOptions>
                      </div>
                    </div>
                  </li>
                  <li
                      v-tooltip="'Members'"
                    class="hover:bg-[#E3F2FF] flex justify-center items-center w-14 h-14"
                    :class="{ locked_status: !getPlanControl }"
                  >
                    <div data-toggle="dropdown" class="w-100 h-100 flex items-center justify-center">
                      <img
                        src="@src/assets/img/chat_bot/members.svg"
                        alt="ai-chat-widget"
                        class="w-6 h-6"
                      />
                    </div>
                    <div
                      class="prevent_close_dropdown ml-2 default_style_dropdown social_dropdown profiles_list_header absolute top-0 left-0"
                    >
                      <div class="dropdown-menu dropdown-menu-right">
                        <MemberOptions></MemberOptions>
                      </div>
                    </div>
                  </li>
                  <li
                    v-if="getBlogPostingDetails.type === 'Composer Article'"
                    v-tooltip="'Campaigns'"
                    class="hover:bg-[#E3F2FF] flex justify-center items-center w-14 h-14"
                    :class="{ locked_status: !getPlanControl }"
                  >
                    <div data-toggle="dropdown" class="w-100 h-100 flex items-center justify-center">
                      <img
                        src="@src/assets/img/chat_bot/compaigns.svg"
                        alt="ai-chat-widget"
                        class="w-5 h-5 ml-[5px]"
                      />
                    </div>
                    <div
                      class="prevent_close_dropdown ml-2 default_style_dropdown social_dropdown profiles_list_header absolute top-0 left-0"
                    >
                      <div
                        style="min-width: 250px"
                        class="dropdown-menu dropdown-menu-right"
                      >
                        <ul class="inner">
                          <template v-if="getPublicationFolders.length">
                            <li
                                v-for="(folder, key) in getPublicationFolders"
                                :key="key"
                                class="list_item_li w-100 h-auto !flex"
                                @click.prevent="selectCampaignForPost(folder._id)"
                            >
                              <div class="radio_input_image !w-100">
                                <input
                                    :id="folder._id"
                                    :checked="checkSelected(folder._id)"
                                    :value="folder._id"
                                    type="radio"
                                />
                                <label :for="folder._id" class="radio_left !w-100">
                                  <span
                                    class="px-2 py-0.5 rounded-full cst-pill"
                                    :class="folder?.color || 'border'"
                                  >
                                    {{ folder.name }}
                                  </span>
                                </label>
                              </div>
                            </li>
                          </template>
                          <li
                            class="add-new-camp pt-2 w-100 h-auto"
                            data-cy="create-campaign"
                            @click.prevent="openCreateFolderModal"
                          >
                            + Add New Campaign
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import '../../../../assets/less/third-party/slick.less';

// Add this to your CSS
.slide-enter-active,
.slide-leave-active {
  transition: all 500ms ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.open {
  width: 100%; /* Set the expanded width */
}
.add-new-camp {
  text-align: center;
  font-size: 0.875rem;
  border-top: 1px solid #cecece;
  padding: 10px 0px 1px 0px;
  color: #484b52;
  cursor: pointer;
}

.content_container {
  .ckeditor-wrapper {
    -webkit-box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.3);
    box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.3);
  }
  .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-source-editing-area > textarea {
    height: calc(100vh - 275px);
    overflow-y: auto;
    border: 1.33333px solid #dde3e7;
    border-left: 0;
    border-right: 0;
  }
  .ckeditor-count {
    border: none;
    border-top: 0;
  }
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border: none;
    border-bottom: 0;
    padding: 8px 12px;
  }
}

@media (min-width: 2048px) {
  .content_container .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-source-editing-area > textarea {
    height: calc(100vh - 260px);
  }
}

@media (max-width: 1600px) {
  .content_container .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-source-editing-area > textarea {
    height: calc(100vh - 270px);
  }
}

/* Default styles */
.line-img {
  display: block;
}

/* Hide the line image below 1200px */
@media (max-width: 1200px) {
  .line-img {
    display: none;
  }
}
</style>
