<template>
  <div>
    <b-dropdown
      id="dropdown-right"
      :disabled="getReportsServiceCalls.export || disabled"
      variant="studio-theme"
      class="studio-theme-dropdown studio-theme-dropdown-blue dropdown-size-small pr-0"
      no-caret
    >
      <template v-if="getReportsServiceCalls.export" v-slot:button-content>
        <div class="opacity-70" style="display: flex; align-items: center">
          <span>Exporting </span>
          <clip-loader
            class="ml-2"
            :color="'#ffffff'"
            :size="'14px'"
          ></clip-loader>
        </div>
      </template>
      <template v-else v-slot:button-content>
        <i class="icon-download-cs studio-theme-icon"></i>
        <span class="capitalize_text">Export</span>
        <i class="icon-dropdown-cs"></i>
      </template>
      <b-dropdown-item @click.prevent="saveReport"
        >Export as PDF</b-dropdown-item
      >
      <b-dropdown-item @click="sendReportByEmail">Send PDF</b-dropdown-item>
      <b-dropdown-item @click="schedulePDF">Schedule PDF</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import textHelper from '@common/lib/text-helper'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'ExportButton',
  props: {
    accounts: {
      default: []
    },
    start_date: {},
    end_date: {},
    type: {},
    disabled: false,
    topPostsLimit: {
      type: [String, Number],
      default: 5
    },
    allAccountsSelected: {
      type: Boolean,
      default: false
    },
    labels:{
      type: Array,
      default: []
    },
    campaigns:{
      type: Array,
      default: []
    },

  },
  computed: {
    ...mapGetters(['getReportsServiceCalls', 'getActiveWorkspace'])
  },
  methods: {
    ...mapActions(['renderReportsService']),
    async saveReport () {
      const res = await this.renderReportsService({
        date: `${this.start_date} - ${this.end_date}`,
        accounts: this.accounts,
        type: this.type,
        action: 'render',
        topPosts: this.topPostsLimit,
        allAccountsSelected: this.allAccountsSelected,
        labels: this.labels,
        campaigns: this.campaigns
      })

      // download the report for the user.

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/pdf' })
      )
      const link = document.createElement('a')
      link.href = url

      // improve the file download name
      const date = textHelper.onlyDateStringIntlTime(
        `${this.start_date} - ${this.end_date}`
      )
      const fileName =
        this.getFileName()+
        ` Report (${textHelper.ucFirst(
          this.getActiveWorkspace.slug
        )}) - ${date}.pdf`
      link.setAttribute('download', fileName)
      link.setAttribute('target', '_blank')
      link.download = fileName
      document.body.appendChild(link)
      link.click()
    },
    schedulePDF () {
      EventBus.$emit('schedule-report', {
            accounts: this.accounts,
            network: this.type,
            topPosts: this.topPostsLimit,
            labels: this.labels,
            campaigns: this.campaigns
          })
      $('#scheduleReport').modal('show')
    },
    sendReportByEmail () {
      $('#sendReportByEmail').modal('show')
      EventBus.$emit(
        'send-report-by-email',
          {
            accounts: this.accounts,
            network: this.type,
            startDate: this.start_date,
            endDate: this.end_date,
            topPosts: this.topPostsLimit,
            allAccountsSelected: this.allAccountsSelected,
            labels: this.labels,
            campaigns: this.campaigns
          }
      )
    },
    getFileName(){
      if(this.type === 'campaign-and-label'){
        return 'Campaign and Label'
      }
      else if( this.type === 'twitter'){
        return 'X (Twitter)'
      }
      return textHelper.ucFirst(this.type)
    }
  }
}
</script>
