import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import EyeIcon from '@src/assets/img/icons/analytic/eye-icon.svg'
import PointSelectIcon from '@src/assets/img/icons/analytic/point-select.svg'
import GraphUpIcon from '@src/assets/img/icons/analytic/graph-up.svg'
import ImageIcon from '@src/assets/img/icons/analytic/image-icon.svg'
import FollowersIcon from '@src/assets/img/icons/analytic/followers-icon.svg'
import LikeIcon from '@src/assets/img/icons/analytic/like-icon.svg'
import DislikeIcon from '@src/assets/img/icons/analytic/dislike-icon.svg'
import ShareIcon from '@src/assets/img/icons/analytic/share-icon.svg'
import CommentIcon from '@src/assets/img/icons/analytic/comment-icon.svg'
import WatchIcon from '@src/assets/img/icons/analytic/watch-time.svg'
/*
  constants
 */

const DEFAULT_TOP_AND_LEAST_POSTS_LIMIT = 5
const DUAL_GRAPHS_TITLE_AND_TOOLTIPS = {
  ENGAGEMENT: {
    daily: {
      title: 'Engagement Activity',
      tooltip:
        'Inspect the day-to-day engagement metrics (likes, dislikes, comments, and shares) received each day during the selected time period, regardless of the post publication date.',
    },
  },
  VIDEO: {
    daily: {
      title: 'Video Views',
      tooltip:
        'Analyze the day-to-day video views your posts receive each day during the selected time period.',
    },
    cumulative: {
      title: 'Video Views over Time',
      tooltip:
        'Track the cumulative count of video views, aggregating daily changes to show a running total, throughout the selected time period.',
    },
  },
  SUBSCRIBER: {
    daily: {
      title: 'Subscribers Change',
      tooltip:
        'Inspect the number of new subscribers gained or lost on each specific day during the selected time period.',
    },
    cumulative: {
      title: 'Subscribers Trend',
      tooltip:
        'Examine the overall growth or decline in your YouTube subscribers over time, aggregating daily changes to show a running total, throughout the selected time period.',
    },
  },
  PERFORMANCE: {
    engagement: {
      title: 'Engagements',
      tooltip:
        'Evaluate the correlation between your daily posting frequency during the selected time period and the resulting engagement metrics, including likes, dislikes, shares, and comments.',
    },
    video_views: {
      title: 'Video Views',
      tooltip:
        'Evaluate the correlation between your daily posting frequency during the selected time period and the resulting video views received.',
    },
  },
}
const SINGLE_GRAPH_TOOLTIPS = {
  ENGAGEMENT:
    'Inspect the day-to-day engagement metrics (likes, dislikes, comments, and shares) received each day during the selected time period, regardless of the post publication date.',
  VIDEO_VIEWS:
    'Analyze day-to-day video views received during the selected time period, regardless of the post publication date.',
  WATCH_TIME:
    'Analyze the total amount of time in minutes, viewers have spent, watching videos on your YouTube channel, each day during the selected time period, regardless of the post publication date.',
  VIDEO_DISCOVERY:
    'Explore how viewers discover your videos across different platforms, including YouTube search, suggested videos, external websites, playlists, and more, during the selected time period.',
  SHARE_SERVICE_TYPE:
    'Track the number of times viewers shared your videos on external platforms and social media networks during the selected time period.',
}
const DUAL_GRAPHS = {
  ENGAGEMENT: 'ENGAGEMENT',
  VIDEO: 'VIDEO',
  SUBSCRIBER: 'SUBSCRIBER',
  PERFORMANCE: 'PERFORMANCE',
}

const topAndLeastDropDownOptions = [
  {
    label: 'Engagement',
    api_key: 'byEngagement',
  },
  {
    label: 'Views',
    api_key: 'byViews',
  },
]

const postModalFields = [
  {
    label: 'Subscribers Change',
    key: 'subscribersGained',
    iconSrc: FollowersIcon,
    tooltip: `The number of new subscribers acquired as a result of viewers watching this video published during the selected time period on your YouTube channel.`,
  },
  {
    label: 'Total Engagements',
    key: 'engagement',
    iconSrc: PointSelectIcon,
    tooltip: `The total number of engagement actions (likes, dislikes, comments, shares) received on this video published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Engagement Rate',
    key: 'engagementRate',
    iconSrc: GraphUpIcon,
    tooltip: `The average number of engagement actions (likes, dislikes, shares, comments) per view on this video published during the selected time period, expressed as a percentage.`,
  },
  {
    label: 'Likes',
    key: 'likes',
    iconSrc: LikeIcon,
    tooltip: `The total number of likes received on this video published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Dislikes',
    key: 'dislikes',
    iconSrc: DislikeIcon,
    tooltip: `The total number of dislikes received on this video published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Shares',
    key: 'shares',
    iconSrc: ShareIcon,
    tooltip: `The total number of times this video, published during the selected time period, has been forwarded to others or shared on different platforms, based on the lifetime performance.`,
  },
  {
    label: 'Comments',
    key: 'comments',
    iconSrc: CommentIcon,
    tooltip: `The total number of comments left by viewers on this video published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Views',
    key: 'views',
    iconSrc: EyeIcon,
    tooltip: `The total number of times this video, published during the selected time period, has been watched based on the lifetime performance.`,
  },
  {
    label: 'Watch Time',
    key: 'averageViewDuration',
    iconSrc: WatchIcon,
    tooltip: `The total amount of time viewers have spent watching this video published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Type',
    key: 'mediaType',
    iconSrc: ImageIcon,
    tooltip: `The type of the post (video or short).`,
  },
]

/*
 posts table data
 */
const validPostsTableHeaders = [
  'thumbnail',
  'engagement',
  'engagementRate',
  'likes',
  'dislikes',
  'comments',
  'shares',
  'views',
  'averageViewDuration',
  'subscribersGained',
]
const nonSortableItems = ['thumbnail', 'description']
// object mapping of header values to their corresponding titles
const headerTitles = {
  thumbnail: 'Posts',
  engagement: 'Engagement',
  engagementRate: 'Engagement Rate',
  likes: 'Likes',
  dislikes: 'Dislikes',
  comments: 'Comments',
  shares: 'Shares',
  views: 'Views',
  averageViewDuration: 'Average View Duration',
  subscribersGained: 'Subscribers Change',
}
// object mapping of header api keys to their corresponding titles
const validPostsTableApiKeys = {
  engagement: 'engagement',
  engagementRate: 'engagement_rate',
  likes: 'like',
  dislikes: 'dislike',
  comments: 'comment',
  shares: 'share',
  views: 'views',
  averageViewDuration: 'average_view_duration',
  subscribersGained: 'subscribers_gained',
}
// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  engagement: (value) => formatNumber(value),
  likes: (value) => formatNumber(value),
  dislikes: (value) => formatNumber(value),
  comments: (value) => formatNumber(value),
  shares: (value) => formatNumber(value),
  views: (value) => formatNumber(value),
  averageViewDuration: (value) => formatNumber(value),
  subscribersGained: (value) => formatNumber(value),
  engagementRate: (value) => `${formatNumber(value)}%`,
  thumbnail: (value) => value,
  description: (value) => value,
}
const headerTooltips = {
  engagement:
    "The total number of engagement actions (likes, dislikes, comments, shares) received on a post published during the selected time period, based on the post's lifetime performance.",
  engagementRate:
    'The average number of engagement actions (likes, dislikes, shares, comments) per view on a post published during the selected time period, expressed as a percentage.',
  likes:
    "The total number of likes received on a post published during the selected time period, based on the post's lifetime performance.",
  dislikes:
    "The total number of dislikes received on the post published during the selected time period, based on the post's lifetime performance.",
  comments:
    "The total number of comments left by viewers on a post published during the selected time period, based on the post's lifetime performance.",
  shares:
    "The total number of times a post, published during the selected time period, has been forwarded to others or shared on different platforms, based on the post's lifetime performance.",
  views:
    "The total number of times a post, published during the selected time period, has been watched, based on the post's lifetime performance.",
  averageViewDuration:
    'The average amount of time in seconds, viewers spent watching a video post published during the selected time period on your YouTube channel.',
  subscribersGained:
    'The number of new subscribers acquired as a result of viewers watching a specific video post published during the selected time period on your YouTube channel.',
}

/*
 routes
 */
const routes = {
  CHANNEL_SUBSCRIBER_TREND: 'overviewSubscriberTrend',
  CHANNEL_SUMMARY: 'overviewSummary',
  CHANNEL_ENGAGEMENT_TREND: 'overviewEngagementTrend',
  CHANNEL_VIEWS_TREND: 'overviewViewsTrend',
  CHANNEL_WATCH_TIME_TREND: 'overviewWatchTimeTrend',
  CHANNEL_FIND_VIDEO: 'overviewFindVideo',
  CHANNEL_VIDEO_SHARING: 'overviewVideoSharing',
  TOP_PERFORMING_POSTS: 'overviewTopPosts',
  SORTED_TOP_POSTS: 'getSortedTopPosts',
  LEAST_PERFORMING_POSTS: 'overviewLeastPosts',
  PERFORMANCE_AND_VIDEO_POSTING_SCHEDULE:
    'overviewPerformanceAndVideoPostingSchedule',
}

/*
  state
 */
const { formatNumber } = useNumber()
const { DEFAULT_DATE_RANGE } = useAnalytics()
const selectedAccount = ref(null)
const cardsData = ref([])
const channelSubscribersData = ref([])
const channelEngagementsData = ref([])
const channelViewsData = ref([])
const channelWatchTimeData = ref([])
const channelFindVideoData = ref([])
const channelVideoSharingData = ref([])
const channelPerformanceAndVideoPostingScheduleData = ref([])

// Initialize allTopPosts with empty arrays for each key in validPostsTableApiKeys to ensure we have a structured response format ready for data population.
const allTopPosts = ref(
  Object.fromEntries(
    Object.values(validPostsTableApiKeys).map((key) => [key, []])
  )
)

const topPosts = ref({
  byViews: [],
  byEngagement: [],
})
const leastPosts = ref({
  byViews: [],
  byEngagement: [],
})
const postTableCurrentOffset = ref(0)
const postsLimit = ref(10)
const isPostDataLoading = ref(false)
const dateRange = ref(DEFAULT_DATE_RANGE)
const cards = ref([
  {
    title: 'Subscribers',
    key: 'subscribers',
    tooltip:
      'The total number of people subscribed to your YouTube channel up to the end of the selected time period.',
  },
  {
    title: 'Videos',
    key: 'videos',
    tooltip:
      'The total number of videos published on your YouTube channel during the selected time period.',
  },
  {
    title: 'Video Views',
    key: 'views',
    tooltip:
      'The total number of times your videos have been watched during the selected time period, regardless of their publication date.',
  },
  {
    title: 'Watch Time',
    key: 'watch_time',
    tooltip:
      'The total amount of time in minutes, viewers have spent watching videos on your YouTube channel during the selected time period, regardless of their publication date.',
  },
  {
    title: 'Average View Duration',
    key: 'avg_view_duration',
    tooltip:
      'The average amount of time in seconds, viewers spent watching videos on your YouTube channel during the selected time period, regardless of their publication date.',
  },
  {
    title: 'Engagements',
    key: 'engagement',
    tooltip:
      'The total number of engagement actions (likes, dislikes, comments, shares) received during the selected time period, on all the posts regardless of their publication date.',
  },
  {
    title: 'Likes',
    key: 'like',
    tooltip:
      'The total number of likes received during the selected time period, on all the posts regardless of their publication date.',
  },
  {
    title: 'Dislikes',
    key: 'dislike',
    tooltip:
      'The total number of dislikes received during the selected time period, on all the posts regardless of their publication date.',
  },
  {
    title: 'Shares',
    key: 'share',
    tooltip:
      'The total number of times posts, regardless of their publication date, have been forwarded to others or shared on different platforms during the selected time period.',
  },
  {
    title: 'Comments',
    key: 'comment',
    tooltip:
      'The total number of comments left by viewers during the selected time period, on all the posts regardless of their publication date.',
  },
])

/*
  computed
 */
const canFetchMedia = computed(() => {
  return !!(selectedAccount.value !== null && dateRange.value.length > 0)
})

const isReconnectRequired = computed(() => {
  const scopes = selectedAccount.value?.access_token?.scope
  if(!scopes || typeof scopes !== 'string') return false;

  return scopes.split(' ').length < 7
})

/*
  methods
 */
function getFormattedDateRange() {
  return dateRange.value
    .map((date) => moment(date).format('YYYY-MM-DD'))
    .join(' - ')
}

function getCardData(card) {
  const { current, percentage, difference } = cardsData.value

  return {
    title: card.title || 'Default Title',
    total: current?.[card.key] || 0,
    growth: percentage?.[card.key] || 0,
    difference: difference?.[card.key] || 0,
    originalValue: current?.[card.key] || 0,
    tooltip: card.tooltip || 'Default Tooltip',
  }
}

const getDualGraphTitle = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()

  switch (graphName) {
    case DUAL_GRAPHS.ENGAGEMENT:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.ENGAGEMENT[loweredViewMode]?.title || ''
      )
    case DUAL_GRAPHS.VIDEO:
      return DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.VIDEO[loweredViewMode]?.title || ''
    case DUAL_GRAPHS.SUBSCRIBER:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.SUBSCRIBER[loweredViewMode]?.title || ''
      )
    default:
      return 'Title here'
  }
}

const getDualGraphTooltip = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()
  switch (graphName) {
    case DUAL_GRAPHS.ENGAGEMENT:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.ENGAGEMENT[loweredViewMode]?.tooltip ||
        ''
      )
    case DUAL_GRAPHS.VIDEO:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.VIDEO[loweredViewMode]?.tooltip || ''
      )
    case DUAL_GRAPHS.SUBSCRIBER:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.SUBSCRIBER[loweredViewMode]?.tooltip ||
        ''
      )
    case DUAL_GRAPHS.PERFORMANCE:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.PERFORMANCE[viewMode]?.tooltip || ''
      )
    default:
      return 'ToolTip here'
  }
}

// method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// method to get the tooltip corresponding to a given header value
const getHeaderTooltips = (header = '') => {
  return headerTooltips[header] || ''
}

// method to get the api key corresponding to a given header value
const getHeaderApiKey = (header = '') => {
  return validPostsTableApiKeys[header] || ''
}

// method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

export default function useYoutubeAnalytics(defaultLabel = 'Data') {
  const { getters } = useStore()

  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    axisLabelFormatter,
  } = useAnalytics(defaultLabel)

  const fetchMedia = async (type, extraPayload = {}) => {
    if (canFetchMedia.value) {
      try {
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          date: getFormattedDateRange(),
          youtube_id: selectedAccount.value?.platform_identifier,
          timezone: getters.getActiveWorkspace.timezone,
          ...extraPayload,
        }

        const { data } = await proxy.post(
          BASE_ENDPOINT + 'youtube/' + type,
          payload
        )

        switch (type) {
          case routes.CHANNEL_SUMMARY:
            cardsData.value = data.overview
            break
          case routes.CHANNEL_SUBSCRIBER_TREND:
            channelSubscribersData.value = data
            break
          case routes.CHANNEL_ENGAGEMENT_TREND:
            channelEngagementsData.value = data
            break
          case routes.CHANNEL_VIEWS_TREND:
            channelViewsData.value = data

            break
          case routes.CHANNEL_WATCH_TIME_TREND:
            channelWatchTimeData.value = data
            break
          case routes.CHANNEL_FIND_VIDEO:
            channelFindVideoData.value = data
            break
          case routes.CHANNEL_VIDEO_SHARING:
            channelVideoSharingData.value = data
            break
          case routes.PERFORMANCE_AND_VIDEO_POSTING_SCHEDULE:
            channelPerformanceAndVideoPostingScheduleData.value = data
            break
          case routes.TOP_PERFORMING_POSTS:
            topPosts.value.byEngagement = data?.top_posts_ordered_by_engagement
              ?.length
              ? transformObject(data?.top_posts_ordered_by_engagement)
              : []
            topPosts.value.byViews = data?.top_posts_ordered_by_views?.length
              ? transformObject(data?.top_posts_ordered_by_views)
              : []
            break
          case routes.LEAST_PERFORMING_POSTS:
            leastPosts.value.byEngagement = data
              ?.least_posts_ordered_by_engagement?.length
              ? transformObject(data?.least_posts_ordered_by_engagement)
              : []
            leastPosts.value.byViews = data?.least_posts_ordered_by_views
              ?.length
              ? transformObject(data?.least_posts_ordered_by_views)
              : []
            break
          case routes.SORTED_TOP_POSTS:
            if (extraPayload?.order_by) {
              allTopPosts.value[extraPayload?.order_by] = data?.top_posts
                ?.length
                ? transformObject(data?.top_posts)
                : []
            }
            break
          default:
            break
        }
      } catch (e) {
        console.error('FETCH DATA:::', e)
      }
    }
  }

  const transformObject = (data) => {
    return data.map((post) => {
      return {
        id: post.video_id,
        coverImage: post.thumbnail_url,
        link: post.share_url,
        embedLink: post.iframe_embed_url,
        description: post.description,
        thumbnail: post.thumbnail_url,
        engagement: post.engagement,
        engagementRate: post.engagement_rate,
        mediaType: post.media_type,
        likes: post.like,
        dislikes: post.dislike,
        comments: +post.comment,
        shares: post.share,
        views: post.views,
        averageViewDuration: post.average_view_duration,
        subscribersGained: post.subscribers_gained,
        createdAt: post.published_at,
      }
    })
  }

  const videosAndEngagementCharts = ref([
    {
      name: 'Engagements',
      color: '#78CEA0',
      position: 'left',
      chartType: 'bar',
      api_response_key: 'engagement',
    },
    {
      name: 'Video Views',
      color: '#8ABAF3',
      position: 'right',
      chartType: 'bar',
      api_response_key: 'video_views',
    },
  ])

  const videosAndEngagementChartOptions = ref({
    tooltip: defaultChartOptions.tooltip,
    grid: barChartOptions.value.grid,
    dataZoom: barChartOptions.value.dataZoom,
    xAxis: barChartOptions.value.xAxis,
    yAxis: [
      ...videosAndEngagementCharts.value?.map((chart, i) => ({
        type: 'value',
        sort: 'ascending',
        name: `{a|} {b|${chart.name}}`,
        nameTextStyle: {
          rich: {
            a: {
              backgroundColor: chart.color,
              width: 11,
              height: 11,
              borderRadius: 2,
              display: 'inline-block',
              textAlign: 'center',
              lineHeight: 14,
              fontSize: 12,
            },
            b: {
              color: chart.color,
              fontSize: 12,
              padding: [0, 0, 0, 6],
            },
          },
        },
        position: chart.position,
        nameLocation: 'center',
        nameGap: 40,
        min: 'dataMin',
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          formatter: (value) => value?.toFixed(0),
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          interval: 'int',
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        axisPointer: {
          label: {
            backgroundColor: chart.color,
            color: 'white',
            formatter: (dataPoint) =>
              formatNumber(dataPoint?.value) ?? dataPoint.value,
          },
        },
        splitLine: {
          show: i === 0,
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      })),
    ],
    series: [],
  })

  return {
    analyticsDesignSystem,
    DUAL_GRAPHS,
    SINGLE_GRAPH_TOOLTIPS,
    DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
    postModalFields,

    // variables
    routes,
    legendOptions,
    dataZoomOptions,
    postTableCurrentOffset,
    isPostDataLoading,

    // state
    postsLimit,
    selectedAccount,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    videosAndEngagementChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    videosAndEngagementCharts,
    topPosts,
    leastPosts,
    screenWidth,
    allTopPosts,

    cardsData,
    channelSubscribersData,
    channelEngagementsData,
    channelViewsData,
    channelWatchTimeData,
    channelFindVideoData,
    channelVideoSharingData,
    channelPerformanceAndVideoPostingScheduleData,
    isReportView,
    topAndLeastDropDownOptions,
    validPostsTableHeaders,
    nonSortableItems,
    headerTooltips,
    validPostsTableApiKeys,
    isReconnectRequired,

    // methods
    fetchMedia,
    getCardData,
    getFormattedDateRange,
    getDualGraphTitle,
    getDualGraphTooltip,
    getHeaderTitles,
    getBodyValues,
    getHeaderTooltips,
    getHeaderApiKey,
    axisLabelFormatter,
  }
}
