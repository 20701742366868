<template>
  <div class="bg-white w-[27.87%] h-100 overflow-y-auto">
    <div class="flex flex-col h-100">
      <div class="px-6 py-[1.375rem] bg-[#FAFBFD]">
        <div class="flex items-center relative">
          <span class="ml-1 text-sm font-semibold"> Post Account &  Status </span>
        </div>
      </div>

      <!-- Selected Accounts List -->
      <div
          class="flex flex-col max-h-[755px] text-gray-600 overflow-y-auto"
      >
        <template v-for="(channelName, key) in socialChannelsNameArray" :key="channelName">
          <template v-if="getSocialAccounts[channelName]">
            <template v-for="(account, index) in getSocialAccounts[channelName]" :key="`${channelName}-${index}`">
              <AccountDetailItem
                  :external-post-preview="externalPostPreview"
                  :type="channelName"
                  :active-account="activeAccount"
                  :account="account"
                  :plan="plan"
                  @select-social-account="socialAccountSelection"
              />
            </template>
          </template>
        </template>

        <template v-for="(account, key) in getBlogAccounts" :key="`blog_${key}`">
          <BlogStatusDetail :account="account" :plan="plan" :type="key"/>
<!--          <template v-if="account.website">-->
<!--            <BlogStatusDetail :account="account"/>-->
<!--                    <span-->
<!--                        class="flex items-center cursor-pointer"-->
<!--                    >-->
<!--                      <span class="relative">-->
<!--                        <img-->
<!--                            :src="getFullBlogAccount(account.website).platform_logo"-->
<!--                            alt=""-->
<!--                            class="rounded-full object-cover border"-->
<!--                            data-fallback="0"-->
<!--                            height="30"-->
<!--                            width="30"-->
<!--                            @error="-->
<!--                            onError(-->
<!--                              $event,-->
<!--                              getFullBlogAccount(account.website).platform_logo,-->
<!--                            )-->
<!--                          "-->
<!--                        />-->

<!--                        <img-->
<!--                            v-if="-->
<!--                            getFullBlogAccount(account.website).integration ===-->
<!--                            'wordpress'-->
<!--                          "-->
<!--                            src="https://storage.googleapis.com/lumotive-web-storage/default/wordpress.png"-->
<!--                            alt=""-->
<!--                            class="absolute -right-0.5 -bottom-0.5 w-4 h-4"-->
<!--                        />-->

<!--                        <img-->
<!--                            v-else-->
<!--                            :src="-->
<!--                            require('@assets/img/integration/' +-->
<!--                              getBlogImage(-->
<!--                                getFullBlogAccount(account.website).integration,-->
<!--                              ))-->
<!--                          "-->
<!--                            alt=""-->
<!--                            class="absolute -right-0.5 -bottom-0.5 w-5 h-5"-->
<!--                        />-->
<!--                      </span>-->
<!--                      <span class="flex flex-col ml-3 flex-1">-->
<!--                        <span-->
<!--                            v-tooltip="{-->
<!--                            content:-->
<!--                              getFullBlogAccount(account.website).platform_name-->
<!--                                .length > 12-->
<!--                                ? getFullBlogAccount(account.website)-->
<!--                                    .platform_name-->
<!--                                : '',-->
<!--                          }"-->
<!--                            class="text-sm font-normal text-black-100 select-none"-->
<!--                        >-->
<!--                          {{-->
<!--                            truncateString(-->
<!--                                getFullBlogAccount(account.website).platform_name,-->
<!--                                12,-->
<!--                            )-->
<!--                          }}-->
<!--                        </span>-->

<!--                        <span class="block text-xs select-none text-gray-700">-->
<!--                          Blog-->
<!--                        </span>-->
<!--                      </span>-->
<!--                    </span>-->
<!--          </template>-->
<!--                          <code>{{getFullBlogAccount(account.website)}}</code>-->
        </template>
        <template v-if="!hasAccountSelection">
          <div class="py-10 flex items-center justify-center w-full">
            <span class="font-medium text-sm"> No Account Selected </span>
          </div>
        </template>
      </div>
    </div>
  </div>

</template>

<script setup>
import {computed, defineProps} from 'vue'
import isEmpty from "lodash/isEmpty";
import AccountDetailItem from "@src/modules/planner_v2/components/AccountDetailItem.vue";
import BlogStatusDetail from "@src/modules/planner_v2/components/BlogStatusDetail.vue";
import {socialChannelsNameArray} from "@common/constants/common-attributes";


const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
  activeAccount: {
    type: Object ,
    required: true,
  },
  externalPostPreview: {
    type: Boolean,
    required: false
  }
})
const emit = defineEmits(['select-social-account']) //emit events

const getSocialAccounts = computed(() => {
  console.log('props.plan?.account_selection', props.plan?.account_selection)
  return props.plan?.account_selection || {}
})

const getBlogAccounts = computed(() => {
  return props.plan?.blog_selection
})

const hasPlan = computed(() => {
  return !isEmpty(props.plan)
})

const isSocialPost = computed(() => {
  return 'account_selection' in props.plan
})

const isBlogPost = computed(() => {
  return 'blog_selection' in props.plan
})

// To check if any account is selected or not
const hasAccountSelection = computed(() => {
  let hasAccSelection = false
  if (!hasPlan.value) return false
  if (isSocialPost.value) {
    const accountsArr = Object.values(props.plan?.account_selection)
    for (let i = 0; i < accountsArr.length; i++) {
      if (accountsArr[i].length > 0) {
        hasAccSelection = true
        return hasAccSelection
      }
    }
  } else if (isBlogPost.value) {
    const accountsArr = Object.values(props.plan?.blog_selection)
    for (let i = 0; i < accountsArr.length; i++) {
      if (accountsArr[i].website) {
        hasAccSelection = true
        return hasAccSelection
      }
    }
  }

  return hasAccSelection
})

// change selected social account on click
const socialAccountSelection = (data) => {
  emit('select-social-account', data)
}

</script>
