<template>
  <b-modal
    id="share-plan-modal"
    centered
    header-class="cs-modal-header"
    hide-footer
    no-close-on-backdrop
    size="lg"
    title-class="cs-modal-title"
    @hidden="onHidden"
  >
    <template v-slot:modal-title
      >Sharing {{ state.plans.length > 1 ? 'posts' : 'post' }} with a shareable
      link</template
    >

    <div v-if="state.isLinkGenerated" class="px-2 py-4">
      <CstAlert type="info" class="mb-6">
        You can manage the generated links from the <b>Shared links</b> button
        on the planner.
      </CstAlert>

      <CstFloatingLabelInput
        id="share-link"
        v-model="state.sharedLink"
        class="mb-6"
        type="text"
        label="Share Link"
        readonly
        show-icon-right
      >
        <template v-slot:icon-right>
          <i
            v-tooltip.top="'Copy Link'"
            class="far fa-link cursor-pointer"
            @click="copyLinkToClipboard(state.sharedLink)"
          ></i>
        </template>
      </CstFloatingLabelInput>

      <!--    Footer    -->
      <div class="flex justify-end mt-3.5">
        <CstButton
          variant="primary"
          size="large"
          text="Done"
          @click="$bvModal.hide('share-plan-modal')"
        />
      </div>
    </div>
    <div v-else class="px-2 py-4">
      <CstAlert type="info" class="mb-6">
        You are about to share
        {{ state.plans.length > 1 ? state.plans.length : 'a' }} post{{
          state.plans.length > 1 ? '(s)' : ''
        }}
        with a shareable link, anyone with the link can view these post(s).
      </CstAlert>
      <div>
        <CstFloatingLabelInput
          id="link-name"
          v-model="state.name"
          class="mb-2"
          type="text"
          label="Share Link Name"
          maxlength="30"
        />
        <div v-if="!validateInput" class="text-red-700">
          Link name can only be alphanumeric.
        </div>

        <div class="flex justify-between mb-2 mt-6">
          <div>
            <span class="text-gray-800 font-bold"
              >Enable Password Protection</span
            >
          </div>

          <div>
            <CstSwitch v-model="state.passwordProtected" size="small" />
          </div>
        </div>

        <CstFloatingLabelInput
          id="link-password"
          v-model="state.password"
          class="mb-6"
          :type="!state.passwordVisible ? 'password' : 'text'"
          label="Password"
          autocomplete="off"
          :disabled="!state.passwordProtected"
          show-icon-right
        >
          <template v-slot:icon-right>
            <div @click="switchPasswordVisibility">
              <i
                v-if="!state.passwordVisible"
                class="cs-eye cursor-pointer text-lg"
              ></i>
              <i v-else class="cs-eye-close cursor-pointer text-lg"></i>
            </div>
          </template>
        </CstFloatingLabelInput>
      </div>

      <!--    Footer    -->
      <div class="flex justify-end mt-3.5">
        <CstButton
          size="large"
          variant="secondary"
          class="mr-3 w-28"
          text="Cancel"
          :disabled="state.loader"
          @click="$bvModal.hide('share-plan-modal')"
        />
        <CstButton
          variant="primary"
          size="large"
          text="Create Link"
          :disabled="isCreateLinkDisabled || state.loader || !validateInput"
          :loading="state.loader"
          @click="generateLink"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import CstSwitch from '@ui/Switch/CstSwitch'
import CstAlert from '@ui/Alert/CstAlert'
import CstButton from '@ui/Button/CstButton'
import {
  defineComponent,
  onMounted,
  reactive,
  computed,
  onBeforeUnmount,
  inject,
} from 'vue'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'SharePlanModal',
  components: {
    CstFloatingLabelInput,
    CstSwitch,
    CstAlert,
    CstButton,
  },
  setup() {
    const root = inject('root')
    const { $bvModal } = root
    const { dispatch, getters } = useStore()

    const state = reactive({
      loader: false,
      plans: [],
      name: '',
      passwordProtected: false,
      password: '',
      passwordVisible: false,
      sharedLink: 'https://localhost:8080/nike/planner/list-view/1',
      isLinkGenerated: false,
    })

    onMounted(() => {
      // listen to show event
      EventBus.$on('share-plan::show', (plans) => {
        console.log('plans', plans)
        state.plans = plans
        $bvModal.show('share-plan-modal')
      })
    })

    onBeforeUnmount(() => {
      EventBus.$off('share-plan::show')
    })

    /**
     * Compute the disable state of create link button
     */
    const isCreateLinkDisabled = computed(() => {
      return (
        state.name.trim() === '' ||
        (state.passwordProtected && state.password.trim() === '')
      )
    })

    const validateInput = computed(() => {
      const regex = /^[a-zA-Z0-9 ]+$/gm
      if (state.name) {
        return regex.test(state.name)
      }
      return true
    })

    /**
     * Switch the visibility of password
     */
    const switchPasswordVisibility = () => {
      state.passwordVisible = !state.passwordVisible
    }

    /**
     * Generate the shareable link
     */
    const generateLink = async () => {
      state.loader = true
      try {
        const response = await serviceShareLink(ACTION_TYPES.CREATE_LINK, {
          name: state.name,
          password: state.password,
          is_password_protected: state.passwordProtected,
          plans: state.plans,
          workspace_id: getters.getActiveWorkspace._id,
        })

        console.log('response', response)

        if (response.link) {
          state.isLinkGenerated = true
          state.sharedLink = `${process.env.VUE_APP_BASE_URL}share/planner/${response.link}`
        }
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loader = false
      }
    }

    /**
     * Copy link to clipboard
     * @param link
     */
    const copyLinkToClipboard = (link) => {
      navigator.clipboard.writeText(link)

      dispatch('toastNotification', {
        message: 'Shareable link copied to clipboard',
        type: 'success',
      })
    }

    const onHidden = () => {
      state.name = ''
      state.passwordProtected = false
      state.password = ''
      state.passwordVisible = false
      state.sharedLink = ''
      state.isLinkGenerated = false
    }

    return {
      state,
      isCreateLinkDisabled,
      validateInput,
      generateLink,
      switchPasswordVisibility,
      onHidden,
      copyLinkToClipboard,
    }
  },
})
</script>

<style scoped></style>
