<script setup>
import { ref, inject } from 'vue'
import useSocialMediaUtils from '@/src/modules/common/composables/useSocialMediaUtils'
import useSocialAccountsModal from '@/src/modules/account/composables/useSocialAccountsModal'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';

const props = defineProps({
  platform: {
    type: Object,
    required: true,
  },
  account: {
    required: true,
    type: Object,
  },
})

// declarations
const root = inject('root')
const { $bvModal } = root
const { channelImage, getSocialImage } = useSocialMediaUtils()
const { selectedPlatform, selectedAccount } = useSocialAccountsModal()
const {getPlatformName, handleImageError} = useComposerHelper()
const isHovered = ref(false)
const images = {
  trash: {
    active: require('@assets/img/onboarding/trash.svg'),
    inactive: require('@assets/img/onboarding/trash-active.svg'),
  },
}

// methods
const handleAccountRemove = () => {
  selectedPlatform.value = props.platform
  selectedAccount.value = props.account
  $bvModal.show('removeIntegration')
}
</script>

<template>
  <div class="flex items-center">
    <div class="relative">
      <img
        :src="channelImage(account, account.channel_type)"
        alt=""
        class="rounded-full object-cover border w-7 h-7 lg:w-10 lg:h-10"
        @error="handleImageError"
      />
      <img
        :src="
          require('@assets/img/integration/' +
            getSocialImage(account.channel_type))
        "
        alt="Platform logo"
        class="absolute -right-0.5 -bottom-0.5 w-3 h-3 lg:w-5 lg:h-5"
      />
    </div>
    <div class="flex flex-col ml-3">
      <span
        v-tooltip="getPlatformName(account)"
        class="
          text-sm text-ellipsis
          font-normal
          text-black-100
          select-none
          truncate
          w-[7rem]
        "
      >
        {{ getPlatformName(account) }}
      </span>
      <span class="block text-xs select-none text-gray-800">
        {{ account?.type ?? 'Profile' }}
      </span>
    </div>
  </div>
  <div class="cursor-pointer">
    <img
      class="h-3 w-3 lg:h-6 lg:w-6"
      :src="isHovered ? images.trash.inactive : images.trash.active"
      @click="handleAccountRemove"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    />
  </div>
</template>
