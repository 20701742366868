<template>
  <div>
    <div
      v-if="!queueStatus && postStatus !== 'published'"
      class="mx-8 ml-14 mb-4"
    >
      <div class="my-2 flex flex-row items-center">
        <div class="w-2/12">When to post</div>
        <div class="w-10/12 flex items-center">
          <button
            v-b-modal.timeRecommendationModal
            :disabled="disableRecommendedTimeOption"
            class="focus:outline-none border-0 bg-transparent"
            :class="recommendedTimeClass"
            @click="fetchRecommendedTimeData"
            >Best time to Post
          </button>
          <v-menu
            class="mx-2"
            :popper-triggers="['hover']"
            placement="bottom-end"
            popper-class="first-comment__info-popover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <a href="#"
              ><i class="far fa-question-circle text-base text-blue-900"></i
            ></a>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>Available for:</div>
              <ul>
                <li>
                  <i class="fab fa-facebook-f"></i>
                  <i class="fab fa-instagram"></i>
                  <i class="fab fa-linkedin-in"></i>
                </li>
              </ul>
              <p class="first-comment__message-box">
                How to add recommended best time?
                <a
                  v-close-popper
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="61fb84dcd86136157d9a059a"
                  >Click here</a
                >
              </p>
            </template>
          </v-menu>
        </div>
      </div>
      <!--Posting Options-->
      <div
        v-if="!isGlobalContentCategory && getUserRole !== 'Approver'"
        class="my-2 flex flex-row items-center"
      >
        <div class="w-2/12"></div>
        <div class="w-10/12">
          <div
            class="grid lg:grid-flow-row desktop:grid-flow-col auto-cols-auto"
          >
            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="post_now"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="now"
                :tooltip-content="
                  selectedAccountsLength ? '' : 'Select account to post now'
                "
                :disabled="
                  !hasPermission('can_schedule_plan') ||
                  isContentCategorySelected ||
                  !selectedAccountsLength
                "
              >
                Post Now
              </CstRadio>
            </div>

            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="schedule"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="schedule"
                :disabled="isContentCategorySelected"
                @change="onClickSchedule"
              >
                <span @click="onClickSchedule">Schedule</span>
              </CstRadio>
            </div>

            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="add_to_queue"
                v-model="publishTimeOptions.time_type"
                :disabled="isContentCategorySelected"
                name="post_options"
                value="queued"
              >
                Add to Queue
              </CstRadio>
            </div>

            <div class="flex items-center py-2 mr-2">
              <CstRadio
                id="add_to_content_category"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="content_category"
              >
                Add To Content Category
              </CstRadio>
            </div>
            <div class="flex items-center py-2">
              <CstRadio
                id="draft"
                v-model="publishTimeOptions.time_type"
                name="post_options"
                value="draft"
                @change="onClickDraft"
              >
                Draft
              </CstRadio>
            </div>
          </div>
        </div>
      </div>
      <!--Post Schedule Info-->
      <div v-if="isSchedulePost" class="mt-5 mb-6 flex w-full">
        <br />
        <div class="w-2/12"></div>
        <div class="w-10/12 hover:bg-[#FAFBFC] custom-schedule-parent">
          <hr class="mt-0 !text-[#F4F6FA]" />
          <template v-if="showFixedScheduleData">
            <div
              class="
                w-max
                h-[58px]
                rounded-lg
                bg-[#F4F6FA]
                flex
                items-center
                gap-[28px]
                p-3
                cursor-pointer
                select-none
                px-4
                py-3
                custom-schedule-child
              "
              @click="onClickSchedule"
            >
              <div>
                <p class="text-sm font-bold mb-1 leading-none">
                  Publishing Date
                </p>
                <span
                  v-if="publishTimeOptions && publishTimeOptions.post_date"
                  class="text-xs leading-none"
                  >{{
                    moment(publishTimeOptions.post_date).format(
                      'MMM DD, YYYY hh:mm A',
                    )
                  }}</span
                >
              </div>
              <div class="flex items-center justify-center">
                <img
                  src="@src/assets/img/composer/editCustomSchedule.svg"
                  alt=""
                  class="w-3 h-3"
                />
              </div>
            </div>
          </template>

          <template v-else-if="flattenSchedulingData.length && customSchedule">
            <p class="text-sm mt-4 text-[#595C5F] leading-none"
              >Custom time has been selected for this post.</p
            >
            <div class="w-full flex gap-3 mt-4 flex-wrap">
              <template
                v-for="singleAccount in getSlicedCustomScheduleData"
                :key="`${singleAccount.id}_custom_schedule_account`"
              >
                <div
                  class="
                    w-full
                    max-w-[205px]
                    h-[58px]
                    rounded-lg
                    flex
                    items-center
                    gap-[13px]
                    p-3
                    custom-schedule-child
                    cursor-pointer
                  "
                  :class="singleAccount.time ? 'bg-[#F4F6FA]' : 'bg-red-100'"
                  @click="onClickSchedule"
                >
                  <div class="relative">
                    <img
                      :src="singleAccount.image"
                      alt=""
                      class="w-[32px] h-[32px] rounded-full"
                      @error="
                        $event.target.src =
                          'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                      "
                    />
                    <img
                      :src="
                        require('@src/assets/img/integration/' +
                          getSocialImage(singleAccount.channelType))
                      "
                      alt=""
                      class="absolute right-0 bottom-0 w-4 h-4 rounded-full"
                    />
                  </div>
                  <div class="overflow-hidden">
                    <p
                      class="
                        text-sm text-[#3C4549]
                        truncate
                        mb-1
                        font-bold
                        leading-none
                      "
                    >
                      {{ singleAccount.name }}
                    </p>
                    <span class="text-xs text-[#595C5F]">{{
                      singleAccount.time
                    }}</span>
                  </div>
                </div>
              </template>
              <div
                v-if="flattenSchedulingData.length > 4"
                class="
                  w-full
                  max-w-[205px]
                  h-[58px]
                  rounded-lg
                  bg-[#F4F6FA]
                  flex
                  items-center
                  p-3
                  gap-[13px]
                  cursor-pointer
                  custom-schedule-child
                "
                @click="onClickSchedule"
              >
                <div
                  class="
                    w-[36px]
                    h-[36px]
                    rounded-full
                    flex
                    items-center
                    justify-center
                    bg-[#3C4549]
                    text-white
                    font-bold
                    text-sm
                    flex-shrink-0
                  "
                >
                  +{{ flattenSchedulingData.length - 3 }}
                </div>
                <p class="text-sm text-[#3C4549] font-bold">
                  More Post{{ flattenSchedulingData.length > 1 ? 's' : '' }}
                </p>
                <img
                  src="@src/assets/img/composer/editCustomSchedule.svg"
                  alt=""
                  class="w-3 h-3 ml-auto mr-2"
                />
              </div>
            </div>
          </template>

          <template v-if="showSelectTimeOption && customSchedule">
            <div
              class="
                w-full
                bg-[#FFF9EE]
                py-5
                flex flex-col
                justify-center
                items-center
                mt-4
              "
            >
              <p> Please select time for newly selected account(s) </p>
              <button
                class="
                  w-[128px]
                  h-[43px]
                  mt-4
                  py-3
                  px-6
                  bg-cs-primary
                  text-cs-white
                  rounded-[8px]
                  focus:outline-none
                  border-0
                "
                @click="onClickSchedule"
              >
                Select time
              </button>
            </div>
          </template>
          <hr class="mt-5 mb-0 !text-[#F4F6FA]" />
        </div>
      </div>
      <!--Date Picker-->
      <div v-if="isDraftPost" class="my-2 flex flex-row items-center">
        <div class="w-2/12"></div>
        <div class="w-10/12">
          <transition name="slide-bottom">
            <div class="flex items-center">
              <DatePicker
                ref="posting-schedule-date-picker"
                v-model:open="datepickerOpen"
                class="w-60"
                :value="moment(calendarPointer).format('MMMM DD, YYYY hh:mm A')"
                :default-value="
                  moment(calendarPointer).format('MMMM DD, YYYY hh:mm A')
                "
                :disabled-date="disabledDate"
                :clearable="false"
                type="datetime"
                :show-time-panel="showTimePanel"
                format="MMMM DD, YYYY hh:mm A"
                title-format="MMMM DD, YYYY  hh:mm A"
                value-type="format"
                :append-to-body="false"
                :confirm="true"
                confirm-text="Apply"
                @change="onChangePostDate"
                @close="onClosePostDate"
                @click.native="onClickPostDate"
              >
                <template v-slot:footer="{ emit, value }">
                  <SelectTime
                    :emit="emit"
                    :selected-date="value"
                    :timezone="getWorkspaces.activeWorkspace.timezone"
                  />
                </template>
              </DatePicker>

              <p class="text-xs text-gray-900 ml-2 flex items-center">
                {{ getWorkspaces.activeWorkspace.timezone }}
                <v-menu
                  class="mx-2"
                  :popper-triggers="['hover']"
                  placement="bottom-start"
                  popper-class="bg-[#b3b2b2] max-w-[500px] rounded-lg border border-[#F4F6FA] custom-popover"
                  popper-inner-class="bg-[#ffffff] text-left p-3 pr-5 rounded-lg"
                >
                  <!-- This will be the popover target (for the events and position) -->
                  <a href="#"
                    ><i
                      class="far fa-question-circle text-base text-blue-900"
                    ></i
                  ></a>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div class="bg-[#ffffff] text-left p-3 pr-5 rounded-lg">
                      <p class="text-sm"
                        ><b>Timezone: </b>
                        {{ getWorkspaces.activeWorkspace.timezone }}</p
                      >
                      <p class="text-sm mt-2"
                        ><b>Current Time: </b>
                        <clock
                          time-format="MMMM DD, YYYY hh:mm A"
                          :timezone="getWorkspaces.activeWorkspace.timezone"
                        ></clock>
                      </p>
                    </div>
                  </template>
                </v-menu>
              </p>
            </div>
          </transition>
        </div>
      </div>
      <!--Info Box-->
      <div v-if="showInfoBox" class="my-2 flex flex-row items-center">
        <div class="w-2/12"></div>
        <div class="w-10/12">
          <div
            class="rounded-lg p-3"
            :class="{
              'bg-orange-100 text-orange-700':
                !isContentCategorySelected || selectedCategoryHasSlots,
              'bg-orange-100 text-orange-700 border border-red-900':
                isContentCategorySelected && !selectedCategoryHasSlots,
            }"
          >
            <template v-if="isQueuePost">
              <span class="font-semibold">Note:</span>
              There will be a separate post for each social account according to
              the next time-slot available in the respective account's queue.
              <a
                href="https://docs.contentstudio.io/article/691-what-are-social-queues"
                target="_blank"
                >learn more here</a
              >
            </template>
            <template v-if="globalContentCategory">
              <span class="font-semibold">Note:</span>
              This post will be published according to the time slots and social
              channels selected for this global category in each workspace.
            </template>
            <template
              v-if="
                isContentCategorySelected &&
                selectedCategoryHasSlots &&
                !globalContentCategory
              "
            >
              <div
                class="flex flex-col align-center justify-center text-center"
              >
                <div>
                  <span class="font-semibold">Note:</span> Your post will be
                  published at
                  {{
                    getDateTimeFormat(
                      nextContentCategorySlot.date,
                      'ddd MMM, DD YYYY - hh:mm A',
                    )
                  }}
                  ({{
                    getUtcFromWorkspaceTimeZoneFromNow(
                      nextContentCategorySlot.date,
                    )
                  }}) from now according to your slot.
                </div>
                <div class="font-0-75rem italic text-gray-800">
                  The delay in starting the campaign is because of the next
                  available slot for the
                  <span class="font-semibold">{{
                    selectedContentCategory.name
                  }}</span
                  >, if you want to start immediately either choose custom
                  slots, regular intervals or add a slot in your selected
                  content category.
                </div>
              </div>
            </template>
            <template
              v-if="
                isContentCategorySelected &&
                !selectedCategoryHasSlots &&
                !globalContentCategory
              "
            >
              <div
                class="
                  flex flex-col
                  align-center
                  justify-center
                  text-center
                  bg-orange-100
                  text-orange-700
                "
              >
                <div>
                  You have not created any slots for your category. You need to
                  add slots before you can add content to the category.
                  <a
                    class="text-blue-500 cursor-pointer"
                    href="#"
                    @click="
                      $router.push({ name: 'content_categories' }),
                        $bvModal.hide('composer-modal')
                    "
                  >
                    Click here to create slots.</a
                  >
                  <!--                  <router-link :to="{ name: 'content_categories' }">Click here-->
                  <!--                    to create slots.-->
                  <!--                  </router-link>-->
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!--Repeat Post-->
      <div v-if="showRepeatPostOption" class="my-3 flex flex-row items-center">
        <div class="w-2/12">Repeat Post?</div>
        <div class="w-10/12">
          <CstSwitch v-model="repeatPost" size="small"></CstSwitch>
        </div>
      </div>
      <!--Repeat Post Options-->
      <div
        v-if="repeatPost && showRepeatPostOption"
        class="my-2 flex flex-row items-center"
      >
        <div class="w-2/12"></div>
        <div class="w-10/12">
          <div class="col-start-2 col-span-2">
            <div class="flex flex-col space-y-4">
              <div class="flex items-center">
                <div class="w-36">Repeat</div>
                <div class="my-auto">
                  <input
                    v-model="publishTimeOptions.repeat_times"
                    class="w-14 border rounded-md"
                    style="margin-bottom: 0"
                    max="30"
                    min="1"
                    type="number"
                  />
                </div>
                <div class="my-auto ml-2"
                  >time(s)
                  <i
                    v-b-tooltip.hover
                    class="far fa-question-circle"
                    title="Repeat times must be between 1 to 30"
                  ></i>
                </div>
              </div>
              <div class="flex items-center">
                <div class="w-36">With the interval of</div>
                <div class="flex flex-row space-x-2">
                  <input
                    v-model="publishTimeOptions.repeat_gap"
                    type="number"
                    min="1"
                    max="99"
                    style="margin-bottom: 0"
                    class="w-14 my-auto border rounded-md"
                  />
                  <div class="relative">
                    <select
                      v-model="publishTimeOptions.repeat_type"
                      class="
                        appearance-none
                        px-4
                        pr-8
                        text-black-800
                        border
                        rounded-md
                        p-1
                        bg-white
                        cursor-pointer
                        opacity-80
                      "
                    >
                      <!-- <option value="Hour">Hour(s)</option> -->
                      <option value="Day">Day(s)</option>
                      <option value="Week">Week(s)</option>
                      <option value="Month">Month(s)</option>
                    </select>
                    <div
                      class="
                        pointer-events-none
                        absolute
                        inset-y-0
                        right-0
                        flex
                        items-center
                        px-2
                        text-gray-700
                      "
                    >
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-start-2 col-span-2 mt-4">
            <div class="grid grid-cols-4 gap-2">
              <div
                v-for="(time, key) in processPostTimings"
                :key="key"
                class="
                  border border-2
                  p-1
                  rounded-md
                  border-green-600
                  text-green-600 text-center
                "
              >
                {{ getDateTimeFormat(time, 'MMMM DD, HH:mm') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        id="timeRecommendationModal"
        centered
        header-class="cs-modal-header"
        hide-footer
        modal-class="time-recommendation-modal"
        no-close-on-backdrop
        size="xl"
        title-class="cs-modal-title"
      >
        <template v-slot:modal-title>
          Best Time To Post
          <a
            v-tooltip.top="'Learn more about recommended times'"
            class="beacon ml-2 font-1rem"
            data-beacon-article-modal="61fb84dcd86136157d9a059a"
            href="#"
          >
            <i class="far fa-question-circle"></i>
          </a>
        </template>
        <TimeRecommendations
          :plot-data="plotData"
          :plot-dates="plotDates"
          :plot-title="'Best Time For Social Posting'"
          :recommended-best-time="recommendedBestTime"
          :state="recommendedTimeResponse"
          :user-timezone="getWorkspaces.activeWorkspace.timezone"
          @setRecommendedTime="setRecommendedTime"
        ></TimeRecommendations>
      </b-modal>
    </div>

    <div
      v-else
      class="
        bg-orange-100
        flex
        mx-auto
        my-4
        p-4
        rounded-lg
        text-orange-700
        w-max
      "
    >
      <div class="text-center">
        <template v-if="postStatus === 'published'"
          >This post is published on
        </template>
        <template v-else>This post is queued and scheduled for</template>
        <span class="font-semibold">
          {{
            moment(publishTimeOptions.post_date).format('MMM DD, YYYY hh:mm A')
          }}
        </span>
        <i
          v-if="postStatus !== 'published'"
          class="
            bg-cs-white
            cursor-pointer
            icon-edit-cs
            mx-2
            p-2
            rounded-md
            shadow-sm
            text-black-200
          "
          @click.prevent="editQueuedPost"
        />
      </div>
    </div>
    <PostSchedulerModal
      :selected-accounts-length="selectedAccountsLength"
      :scheduling-data="customSchedulingData"
      :recommended-time-chart-data="plotData"
      :recommended-best-time="recommendedBestTime"
      :fixed-schedule-time="fixedScheduleTime"
      :show-time-recommendation="isRecommendedAccountSelection"
      :disable-best-time="disableRecommendedTimeOption"
      :best-time-class="recommendedTimeClass"
      @custom-schedule-data="handleCustomScheduleData"
      @fixed-schedule="handleFixedSchedule"
      @open-best-time-chart="handleBestTimeChart"
      @set-fixed-schedule="setFixSchedule"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CstSwitch from '@ui/Switch/CstSwitch'
import DatePicker from 'vue2-datepicker'
// import DatePicker from 'vue-datepicker-next'
import { nextAvailableSlotCategoryURL } from '@src/modules/setting/config/api-utils'
import proxy from '@src/modules/common/lib/http-common'
import TimeRecommendations from '@src/modules/publish/components/posting/TimeRecommendation'
import { getTimeRecommendationUrl } from '@src/modules/analytics/config/api-utils'
import CstRadio from '@ui/Radio/CstRadio'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper'
import SelectTime from '@src/modules/composer_v2/components/PostingSchedule/SelectTime'
import { socialChannelsNameArray } from '@common/constants/common-attributes'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import { nextTick } from 'vue'
import { EventBus } from '@/src/modules/common/lib/event-bus'

// components
import PostSchedulerModal from '@/src/modules/composer_v2/components/PostSchedulerModule/PostSchedulerModal'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
const clone = require('rfdc/default')

DatePicker.compatConfig = { RENDER_FUNCTION: false }

export default {
  name: 'PostingSchedule',
  components: {
    CstSwitch,
    DatePicker,
    TimeRecommendations,
    CstRadio,
    SelectTime,
    PostSchedulerModal,
  },
  mixins: [ComposerHelperMixin],
  props: {
    isInstagramSelected: {
      type: Boolean,
      required: true,
    },
    selectedContentCategory: {
      type: Object,
      default: () => {},
    },
    nextContentCategorySlot: {
      type: Object,
      default: () => {},
    },
    accountSelection: {
      type: Object,
      default: () => {},
    },
    publishTimeOptions: {
      type: Object,
      default: () => ({}),
    },
    globalContentCategory: {
      type: Boolean,
      default: false,
    },
    planId: {
      type: String,
      default: null,
    },
    queueStatus: {
      type: [Boolean, Number],
      default: null,
    },
    postStatus: {
      type: String,
      default: 'draft',
    },
    socialPostType: {
      type: String,
      default: 'new',
    },
    accountsList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { changeCalendarState } = useComposerHelper()

    return {
      changeCalendarState,
    }
  },
  data() {
    return {
      startHours: 0,
      repeatPost: false,
      disabledAPI: false,
      disabledMobile: false,
      // instagramPostingMethod: '',  // transferred to composerHelperMixin
      loadingSlots: false,
      recommendedBestTime: [],
      plotData: [],
      plotDates: [],
      recommendedTimeResponse: true,
      moment,
      hasAccounts: false,
      showTimePanel: false,
      postingScheduleState: 'now',
      customSchedule: false,
      fixedScheduleTime: '',
      showFixedScheduleData: false,
      customSchedulingData: {},
      initialScheduleTime: '',
      flattenSchedulingData: [],
      showSelectTimeOption: false,
      datepickerOpen: false,
      calendarPointer: moment(this.publishTimeOptions?.post_date).format(
        'MMMM DD, YYYY hh:mm A'
      ),
    }
  },
  computed: {
    ...mapGetters(['getMobileDevices', 'getWorkspaces', 'getActiveWorkspace']),

    /**
     * If the content category selected is of type 'global'
     * @returns {boolean|boolean}
     */
    isGlobalContentCategory() {
      return this.selectedContentCategory !== null
        ? this.selectedContentCategory.category_state === 'global'
        : false
    },

    /**
     * If content category is selected or is null
     * @returns {boolean}
     */
    isContentCategorySelected() {
      return this.selectedContentCategory !== null
    },

    /**
     * If 'Add to Queue' option is selected.
     * @returns {boolean}
     */
    isQueuePost() {
      return this.publishTimeOptions.time_type === 'queued'
    },

    /**
     * If 'Schedule' option is selected
     * @returns {boolean}
     */
    isSchedulePost() {
      return this.publishTimeOptions.time_type === 'schedule'
    },
    isDraftPost() {
      return this.publishTimeOptions.time_type === 'draft'
    },

    /**
     * Calculates and returns a start date.
     * @returns {string|*}
     */
    getStartDate() {
      if (this.publishTimeOptions.post_date) {
        return this.publishTimeOptions.post_date
      }
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(10, 'm')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },
    getMinDate() {
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(10, 'm')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },

    /**
     * Shows information box based on the positng option selected. (Error/Info) Box.
     * @returns {boolean}
     */
    showInfoBox() {
      return (
        (this.isQueuePost ||
          this.globalContentCategory ||
          this.selectedContentCategory) &&
        !this.loadingSlots &&
        this.publishTimeOptions.time_type !== 'draft'
      )
    },

    /**
     * Calculates post timings when repeat post option is selected.
     * @returns {*[]}
     */
    processPostTimings() {
      const times = []
      let time = ''

      const selection = this.publishTimeOptions

      if (selection.repeat === 'repeat') {
        if (selection.time_type === 'now') {
          time = this.getWorkspaceTimezonCurrentTime('YYYY-MM-DD HH:mm')
        } else if (selection.time_type === 'schedule') {
          time = selection.post_date
        }

        if (time) {
          times.push(time)

          if (selection.repeat_type === 'Hour') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'h')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Day') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'd')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Week') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'w')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Month') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'M')
                times.push(time)
              }
            }
          }
        }
      }

      return times
    },

    /**
     * If the content category that has been selected has slots.
     * @returns {boolean|*}
     */
    selectedCategoryHasSlots() {
      return (
        this.isContentCategorySelected && this.nextContentCategorySlot !== null
      )
    },

    /**
     * repeat post options is shown when post_now or scheduled options is selected.
     * @returns {boolean}
     */
    showRepeatPostOption() {
      const { time_type } = this.publishTimeOptions
      const { twitter } = this.accountSelection

      return (
        (time_type === 'now' ||
          (time_type === 'schedule' && this.showFixedScheduleData)) &&
        twitter.length === 0
      )
    },

    isRecommendedAccountSelection() {
      return (
        (this.accountSelection.facebook.length > 0 && !this.isOnlyFacebookGroupSelected) ||
        this.accountSelection.twitter.length > 0 ||
        this.accountSelection.linkedin.length > 0 ||
        this.accountSelection.instagram.length > 0
      )
    },

    // converts the custom scheduling data into a flat array to show custom scheduling data for each account.
    // also slices the array to show only 3 or 4 accounts depending on the length of the array
    getSlicedCustomScheduleData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.flattenSchedulingData = Object.values(
        this.customSchedulingData
      ).flatMap((channel) => channel.accounts)
      const slicingAmount = this.flattenSchedulingData.length > 4 ? 3 : 4
      return this.flattenSchedulingData?.slice(0, slicingAmount)
    },

    // returns the length of the selected accounts
    selectedAccountsLength() {
      return Object.values(this.accountSelection).reduce(
        (acc, val) => acc + val.length,
        0
      )
    },

    // disable recommended time option
    disableRecommendedTimeOption() {
      return (
        !this.hasAccounts ||
        this.isContentCategorySelected ||
        !this.isRecommendedAccountSelection ||
        this.isOnlyTwitterAccountSelected
      )
    },

    // returns the class for the recommended time option
    recommendedTimeClass() {
      return {
        'text-gray-800':
          !this.hasAccounts ||
          this.isContentCategorySelected ||
          this.isRecommendedAccountSelection,
        'text-blue-900 cursor-pointer':
          this.hasAccounts &&
          !this.isContentCategorySelected &&
          this.isRecommendedAccountSelection,
        '!cursor-not-allowed opacity-50': this.isOnlyTwitterAccountSelected,
      }
    },
    isOnlyTwitterAccountSelected() {
      const populatedArrays = Object.keys(this.accountSelection)?.filter(
        (platform) => this.accountSelection[platform]?.length > 0
      )

      return (
        populatedArrays?.length === 1 && populatedArrays?.includes('twitter')
      )
    },
    // Check if only facebook groups are selected
    isOnlyFacebookGroupSelected() {
      // Get selected Facebook accounts
      const facebookAccountsSelected = this.accountSelection.facebook;

      // Create a map of all Facebook accounts
      const facebookAccountsMap = this.accountsList.reduce((map, account) => {
        if (account.channel_type === 'facebook') {
          map[account.facebook_id] = account.type;
        }
        return map;
      }, {});

      // Check if selected accounts are only groups (type !== 'Page')
      const onlyFacebookGroupsSelected = facebookAccountsSelected.every(
        (selectedAccount) => facebookAccountsMap[selectedAccount] === 'Group'
      );

      return onlyFacebookGroupsSelected;
    },

  },
  watch: {
    async calendarPointer(newVal, oldVal) {
      if (newVal === oldVal) return
      await nextTick(() => {
        document.querySelector('.mx-input').value = moment(
          this.publishTimeOptions.post_date
        ).format('MMM DD, YYYY hh:mm A')
      })
    },
    'publishTimeOptions.post_date': {
      handler(newVal, oldVal) {
        this.calendarPointer = moment(newVal).format('MMMM DD, YYYY hh:mm A')
      },
      deep: true,
    },
    publishTimeOptions: {
      handler(val, oldVal) {
        console.log(
          'WATCH::publishTimeOptions ~ newVal, oldVal -> ',
          val,
          oldVal
        )
        if (val.repeat_times < 1) {
          val.repeat_times = 1
        }
        if (val.repeat_times > 30) {
          val.repeat_times = 30
        }

        if (val.repeat_gap < 3) {
          if (this.publishTimeOptions.repeat_type === 'Day') {
            val.repeat_gap = 3
          } else if (val.repeat_gap < 1) {
            val.repeat_gap = 1
          }
        }

        if (val.repeat_gap > 99) {
          val.repeat_gap = 99
        }
      },
      deep: true,
    },
    planId: function (newVal) {},
    selectedContentCategory: {
      handler(value) {
        if (value && value._id) {
          this.fetchContentCategoriesSlots(this.planId, value._id)
        }
      },
      deep: true,
      immediate: true,
    },
    accountSelection: {
      handler(newVal, oldVal) {
        let temp = false
        for (const channelName in newVal) {
          if (newVal[channelName].length) {
            temp = true
          }
        }
        this.hasAccounts = temp

        if (!this.initialScheduleTime) {
          this.initialScheduleTime = moment()
            .tz(this.getActiveWorkspace.timezone)
            .add(1, 'hours')
            .format('MMM DD, YYYY hh:mm A')
        }
        this.updateSchedulingData()
      },
      deep: true,
      immediate: true,
    },
    repeatPost: function (newVal) {
      if (newVal) {
        this.publishTimeOptions.repeat = 'repeat'
      } else {
        this.publishTimeOptions.repeat = ''
      }
    },
    /**
     * toggle repeat post to false if the option isn't shown
     * @param val
     */
    showRepeatPostOption(val) {
      if (!val && this.repeatPost) {
        this.repeatPost = val
      }
    },
    showSelectTimeOption(value) {
      if (this.customSchedule && value) {
        EventBus.$emit('force-disable-schedule-button')
        return
      }
      this.$emit(
        'disable-schedule-button',
        value && this.publishTimeOptions?.is_custom_schedule
      )
    },
  },
  mounted() {
    if (this.publishTimeOptions?.post_date) {
      this.initialScheduleTime = moment(
        this.publishTimeOptions.post_date
      ).format('MMM DD, YYYY hh:mm A')
      this.fixedScheduleTime = this.initialScheduleTime
      this.showFixedScheduleData = true
    }
    window.postingSchedule = this
    if (this.socialPostType === 'edit') {
      this.fixedScheduleTime = moment(this.publishTimeOptions.post_date).format(
        'MMM DD, YYYY hh:mm A'
      )
      if (this.publishTimeOptions.time_type === 'schedule')
        this.postingScheduleState = 'schedule'
      if (
        this.publishTimeOptions.post_date &&
        !this.publishTimeOptions.isCustomSchedule
      ) {
        this.showFixedScheduleData = true
      }
      if (this.publishTimeOptions.time_type === 'draft')
        this.postingScheduleState = 'draft'
    }

    EventBus.$on('reset-channel-time', ({ channel }) => {
      if (!this.customSchedulingData[channel]?.time) return
      this.customSchedulingData[channel].time = ''
      this.customSchedulingData[channel].commonTime = false
    })
    EventBus.$on('channel-time-change', (timeDetails) => {
      if (!this.customSchedulingData[timeDetails.channel]) return
      this.customSchedulingData[timeDetails.channel].time = timeDetails.time
      this.customSchedulingData[timeDetails.channel].commonTime =
        timeDetails.commonTime
      this.customSchedulingData[timeDetails.channel].accounts.forEach(
        (account) => {
          account.time = timeDetails.time
        }
      )
      this.publishTimeOptions.post_date = timeDetails.time
    })
    EventBus.$on('account-time-change', ({ accountId, channel, time }) => {
      if (!this.customSchedulingData[channel]) return
      this.customSchedulingData[channel].accounts.forEach((account) => {
        if (account.id === accountId) {
          account.time = time
        }
      })
    })

    EventBus.$on('toggle-repeat-post', (value) => {
      this.repeatPost = value
    })
  },
  methods: {
    onClickPostDate(event = '') {
      this.calendarPointer = this.changeCalendarState(
        event,
        this.calendarPointer
      )
    },
    /**
     * Triggers when user clicks on select time button in the time picker.
     */
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel
    },
    /**
     * Triggers when user clicks on View Recommendations button.
     */
    fetchRecommendedTimeData() {
      if (
        !this.hasAccounts ||
        this.isContentCategorySelected ||
        !this.isRecommendedAccountSelection
      )
        return

      this.plotData = []
      this.plotDates = []
      this.recommendedTimeResponse = true
      const accounts = []
      const tv = moment().tz(this.getWorkspaces.activeWorkspace.timezone)
      const fbPages = this.filterFacebookPages()
      this.accountSelection?.facebook.forEach((id) => {
        const page = fbPages.find((page) => page?.facebook_id === id)
        if (page) {
          accounts.push({ account_type: 'facebook', facebook_id: id })
        }
      })
      this.accountSelection.instagram.forEach((id) => {
        accounts.push({ account_type: 'instagram', instagram_id: id })
      })
      this.accountSelection.twitter.forEach((id) => {
        accounts.push({ account_type: 'twitter', twitter_id: id })
      })
      this.accountSelection.linkedin.forEach((id) => {
        if (typeof id !== 'string') {
          accounts.push({ account_type: 'linkedin', linkedin_id: id })
        }
      })
      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        date:
          tv.format('YYYY-MM-DD') + ' - ' + tv.add(6, 'd').format('YYYY-MM-DD'),
        accounts,
        timezone: this.getWorkspaces.activeWorkspace.timezone,
        state: 'merged',
      }

      proxy
        .post(getTimeRecommendationUrl, payload)
        .then((resp) => {
          if (resp?.data) {
            resp = JSON.parse(JSON.stringify(resp))
            this.plotDates = resp.data.data[0].keys
            this.plotDates = this.plotDates.map((val) => {
              const formatted = moment(val, 'YYYY-MM-DD')
              return formatted.format('DD-MMM-YYYY')
            })
            this.plotData = resp.data.data[0].plot
            this.recommendedTimeResponse = resp.data.data[0].state
            this.recommendedBestTime = resp.data.data[0].best
          }
        })
        .catch((error) => {
          console.log('ERROR::fetchRecommendedTimeData -> ', error)
        })
    },

    editQueuedPost() {
      this.$emit('editQueuedPost')
    },
    duplicatePost() {
      this.$emit('duplicatePost')
    },
    filterFacebookPages() {
      return this.accountsList.filter(
        (account) =>
          account?.channel_type === 'facebook' && account?.type === 'Page'
      )
    },
    /**
     * Triggers when user select recommended time
     */
    setRecommendedTime(recommendedTime, isBestTime = false) {
      if (isBestTime) {
        this.publishTimeOptions.time_type = 'schedule'
      }
      if (this.isSchedulePost) {
        this.fixedScheduleTime = moment(recommendedTime).format(
          'MMM DD, YYYY hh:mm A'
        )
      }
      const values = {}
      values.startDate = moment(recommendedTime, 'DD-MMM-YYYY hh:mm aa')
      this.publishTimeOptions.post_date = values.startDate.format(
        'MMMM DD, YYYY hh:mm A'
      )
      this.$bvModal.hide('timeRecommendationModal')
      this.publishTimeOptions.time_type = 'schedule'
    },

    /**
     * Fetches next content category available slot. Invoked on Content Category change.
     * @param {String} planId - plan id
     * @param categoryId
     */
    fetchContentCategoriesSlots(planId, categoryId) {
      const self = this
      self.loadingSlots = true

      proxy
        .post(nextAvailableSlotCategoryURL, {
          category_id: categoryId,
          workspace_id: self.getActiveWorkspace._id,
          plan_id: planId || null,
        })
        .then((res) => {
          if (res.data.status) {
            this.$emit('set-category-slot', res.data.data)
          }
          self.loadingSlots = false
        })
        .catch((err) => {
          console.log('ERROR::err -> ', err)
          self.loadingSlots = false
        })
    },

    /**
     * onChange event for publish time options when post now option is clicked.
     */
    onClickPostNow() {
      console.log('METHOD::onClickPostNow ~ ')
      this.publishTimeOptions.post_date = moment().format('MMM DD, YYYY HH:mm')
    },

    /**
     * onchange event for publish time options when schedule option is clicked (time calculation added).
     */
    async onClickSchedule() {
      EventBus.$emit('post-scheduler-modal')
      if (!this.showFixedScheduleData && !this.customSchedule)
        this.$emit('disable-schedule-button', true)
      if (this.isDraftPost) {
        if (this.customSchedule) {
          this.publishTimeOptions.post_date = this.moment(
            this.fixedScheduleTime
          ).format('MMM DD, YYYY hh:mm A')
        } else {
          this.fixedScheduleTime = moment(
            this.publishTimeOptions.post_date
          ).format('MMM DD, YYYY hh:mm A')
        }
      } else {
        this.publishTimeOptions.post_date = this.moment(
          this.fixedScheduleTime
        ).format('MMM DD, YYYY hh:mm A')
      }

      this.postingScheduleState = 'schedule'
    },

    /**
     * onChange event for publish time options when draft options is clicked (time calculation added).
     */
    onClickDraft() {
      if (
        this.postingScheduleState === 'draft' ||
        this.postingScheduleState === 'schedule'
      )
        return

      this.postingScheduleState = 'draft'
      this.publishTimeOptions.post_date = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
        .format('MMM DD, YYYY HH:mm')
    },

    /**
     * onClose event for when the user apply or click outside the picker
     */
    onClosePostDate() {
      this.calendarPointer = this.publishTimeOptions.post_date
      this.datepickerOpen = false
      this.showTimePanel = false
    },

    /**
     * on Change event for when the user modifies the date time to the customized time.
     * @param dateTime
     */
    onChangePostDate(dateTime) {
      this.calendarPointer = this.publishTimeOptions.post_date = clone(
        moment(dateTime).format('MMM DD, YYYY HH:mm')
      )
    },

    /**
     * Range calculator for disabled dates.
     * @param date
     * @returns {boolean}
     */
    disabledDate(date) {
      // Converting current time to given timezone.
      const timeStart = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: this.getActiveWorkspace.timezone,
        })
      )
      const timeEnd = new Date(date)

      // Removing 1 day from current time.
      const now = timeStart.setHours(
        timeStart.getHours() - 24,
        timeStart.getMinutes(),
        timeStart.getSeconds()
      )
      const value = timeEnd.setHours(
        timeEnd.getHours(),
        timeEnd.getMinutes(),
        timeEnd.getSeconds()
      )

      return value < now
    },

    /**
     * Range calculator for disabled time.
     * @param date
     * @returns {boolean}
     */
    disabledTime(date) {
      // Converting current time to given timezone.
      const timeStart = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: this.getActiveWorkspace.timezone,
        })
      )
      const timeEnd = new Date(date)

      // Adding 10 minutes into the current time.
      const now = timeStart.setHours(
        timeStart.getHours(),
        timeStart.getMinutes() + 10,
        timeStart.getSeconds()
      )
      const value = timeEnd.setHours(
        timeEnd.getHours(),
        timeEnd.getMinutes(),
        timeEnd.getSeconds()
      )

      return value < now
    },

    /**
     * handles the custom schedule data & sets it to the publish time options.
     */
    handleCustomScheduleData() {
      this.flattenSchedulingData = ['custom']
      this.showSelectTimeOption = false
      this.showFixedScheduleData = false
      this.customSchedule = true
      this.publishTimeOptions.is_custom_schedule = true
      this.publishTimeOptions.custom_schedule = clone(this.customSchedulingData)
    },

    /**
     * handles the fixed schedule time & sets it to the publish time options.
     */
    handleFixedSchedule(fixedScheduleTime) {
      this.fixedScheduleTime = fixedScheduleTime
    },

    /**
     * gets triggered when the account selection changes. updates the custom scheduling data.
     */
    updateSchedulingData() {
      socialChannelsNameArray.forEach((channel) => {
        // Check if there are selected accounts for the current channel
        if (this.accountSelection[channel].length) {
          const selectedAccounts = this.accountSelection[channel]
          const existingAccounts =
            this.customSchedulingData[channel]?.accounts || []
          // updatedAccounts = existing accounts that are still selected
          const updatedAccounts =
            existingAccounts?.filter((account) =>
              selectedAccounts.includes(account.id)
            ) || []

          // Iterate through all accounts to find newly checked accounts or unchecked accounts to remove
          this.accountsList.forEach((account) => {
            const accountId = account[account.channel_identifier]
            if (selectedAccounts.includes(accountId)) {
              if (
                account.checked &&
                !existingAccounts.find((acc) => acc.id === accountId)
              ) {
                // Add newly checked account to the updated list
                const accountToBePushed = {
                  id: accountId,
                  time: '',
                  image: account.image,
                  channelType: account.channel_type,
                  accountType:
                    'type' in account
                      ? account.type
                      : account.channel_type === 'instagram'
                      ? 'business'
                      : 'personal',
                  name:
                    'platform_name' in account
                      ? account.platform_name
                      : account.name,
                }

                updatedAccounts.push(accountToBePushed)
                this.showSelectTimeOption = true
              }
            }
          })
          // Update or create customSchedulingData for the channel
          // Create new data with initial schedule time and updated accounts
          this.customSchedulingData[channel] = {
            time: this.customSchedulingData[channel]?.time
              ? this.customSchedulingData[channel].time
              : '',
            accounts: updatedAccounts,
            commonTime: this.customSchedulingData[channel]?.commonTime
              ? this.customSchedulingData[channel].commonTime
              : null,
          }
        } else {
          // Remove channel from customSchedulingData if no accounts are selected
          delete this.customSchedulingData[channel]
        }
      })

      if (
        this.showSelectTimeOption &&
        this.selectedAccountsLength &&
        !this.showFixedScheduleData &&
        this.customSchedule
      ) {
        this.$emit(
          'disable-schedule-button',
          this.publishTimeOptions.time_type === 'schedule'
        )
      }
    },
    /**
     * @description handles the recommended best time for custom schedule
     */
    handleBestTimeChart() {
      this.$bvModal.show('timeRecommendationModal')
      this.fetchRecommendedTimeData()
    },

    /**
     * @description applies fixed schedule time when confirm is clicked in postSchedulerModal
     */
    setFixSchedule() {
      this.customSchedule = false
      this.showSelectTimeOption = false
      this.showFixedScheduleData = true
      this.publishTimeOptions.is_custom_schedule = false
      this.publishTimeOptions.post_date = this.fixedScheduleTime
    },
  },
}
</script>

<style lang="scss" scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  opacity: 1;
}

.custom-popover {
  box-shadow: 0 3px 40px rgba(0, 26, 119, 0.08);
}

.custom-schedule-parent:hover .custom-schedule-child {
  box-shadow: 0 0 0 2px #eceef5;
}
</style>
