<template>
  <div class="mx-8 ml-14 2xl:mr-40">
    <div class="my-4 flex flex-row items-center">
      <div class="w-2/12">Post Type</div>
      <div class="w-10/12 flex items-center">
        <CstRadio
          id="yt-video"
          class="w-max mr-4"
          name="yt-post-type-video"
          :model-value="youtubeOptions.post_type"
          value="video"
          @change="setYoutubePostType"
        >
          Video
        </CstRadio>
        <CstRadio
          id="yt-short"
          class="w-max mr-0"
          name="yt-post-type-short"
          :model-value="youtubeOptions.post_type"
          value="short"
          @change="setYoutubePostType"
        >
          Shorts
        </CstRadio>
        <v-menu
          :popper-triggers="['hover']"
          placement="bottom-start"
          popper-class="first-comment__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <i class="far fa-question-circle ml-2 text-base text-blue-900" />
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div> Requirements for Shorts:</div>
            <ul>
              <li>- Upto 60 seconds.</li>
              <li>- Can either be a square or vertical video.</li>
              <li>- Include #shorts in the title or description.</li>
            </ul>
          </template>
        </v-menu>
      </div>
    </div>

    <div class="my-4 flex flex-row items-center">
      <div class="w-2/12">Video Title</div>
      <div class="w-10/12">
        <CstInputFields
          :value="youtubeOptions.title"
          maxlength="100"
          class="w-full required-input"
          type="text"
          size="small"
          @value="onChangeTitle"
        />
      </div>
    </div>

    <div class="my-4 flex items-center">
      <div class="w-2/12">Category</div>
      <div class="w-10/12 flex items-center">
        <div class="w-5/12">
          <CstDropdown
            class="w-full"
            size="small"
            button-classes="flex justify-between bg-cs-light-gray"
          >
            <template v-slot:selected>
              {{ selectedCategory }}
            </template>

            <template v-slot>
              <CstDropdownItem
                v-for="(item, index) in category"
                :key="index"
                @click="onSelectCategory(item)"
              >
                {{ item.name }}
              </CstDropdownItem>
            </template>
          </CstDropdown>
        </div>
        <div class="w-1/6 text-center">Playlist</div>
        <div class="w-5/12">
          <CstDropdown
            v-tooltip="{
              content: `${isPlaylistDisabled ? 'Playlists are currently disabled when multiple YouTube accounts are selected. Please select a single account to access playlists.' : ''}`,
            }"
            class="w-full"
            size="small"
            :disabled="isPlaylistDisabled"
            button-classes="flex justify-between bg-cs-light-gray "
          >
            <template v-slot:selected>
              {{ selectedPlaylist }}
            </template>

            <template v-slot>
              <CstDropdownItem
                v-for="(item, index) in accountPlaylists"
                :key="index"
                @click="onSelectPlaylist(item)"
              >
                {{ item.name }}
              </CstDropdownItem>
            </template>
          </CstDropdown>
        </div>
      </div>
    </div>

    <div class="my-4 flex flex-row items-center">
      <div class="w-2/12">Video Tags</div>
      <div class="w-10/12 flex items-center">
        <div class="w-5/12">
          <YoutubeTags
            class="w-full"
            :video-tags="youtubeOptions.tags"
          ></YoutubeTags>
        </div>
        <div class="w-1/6 text-center">License</div>
        <div class="w-5/12">
          <CstDropdown
            class="w-100"
            size="small"
            button-classes="flex justify-between bg-cs-light-gray "
          >
            <template v-slot:selected>
              {{ selectedLicence }}
            </template>

            <template v-slot>
              <CstDropdownItem
                v-for="(item, index) in youtubeLicence"
                :key="index"
                @click="onChangeYoutubeLicence(item)"
              >
                {{ item.name }}
              </CstDropdownItem>
            </template>
          </CstDropdown>
        </div>
      </div>
    </div>

    <div class="my-4 flex flex-row items-center">

      <!--      </div>-->
      <div class="w-full w-12/12 flex flex-row items-center">
        <div class="w-4/12 flex space-x-2 items-center">
          <div class="bg-gray-200 rounded-md w-max box-border py-2.5 px-1">
            <span
              class="px-4 py-2 cursor-pointer rounded-md border-0 focus:outline-none"
              :class="{
                'bg-white text-primary-variant-1':
                  youtubeOptions.privacy_status === 'public',
              }"
              @click="onClickPrivacyStatus('public')"
              >Public</span
            >
            <span
              class="px-4 py-2 cursor-pointer rounded-md border-0 focus:outline-none"
              :class="{
                'bg-white text-primary-variant-1':
                  youtubeOptions.privacy_status === 'private',
              }"
              @click="onClickPrivacyStatus('private')"
              >Private</span
            >
          </div>
        </div>
        <div class="w-3/12 flex space-x-2 items-center">
          <CstSimpleCheckbox v-model="youtubeOptions.embeddable" reverse>
            <template v-slot:label>Embeddable</template>
          </CstSimpleCheckbox>
          <!--            <input id="embeddable" v-model="youtubeOptions.embeddable" type="checkbox">-->
          <!--            <label class="my-auto cursor-pointer" for="embeddable">Embeddable</label>-->
        </div>
        <div class="w-3/12 flex space-x-2 items-center">
          <CstSimpleCheckbox
            v-model="youtubeOptions.notify_subscribers"
            reverse
          >
            <template v-slot:label>Notify Subscribers</template>
          </CstSimpleCheckbox>
          <!--            <input id="notify_subscribers" v-model="youtubeOptions.notify_subscribers" type="checkbox">-->
          <!--            <label class="my-auto cursor-pointer" for="notify_subscribers">Notify Subscribers</label>-->
        </div>
        <div class="w-2/12 flex space-x-2 items-center">
          <CstSimpleCheckbox v-model="youtubeOptions.made_for_kids" reverse>
            <template v-slot:label>Made for Kids</template>
          </CstSimpleCheckbox>
          <!--            <input id="made_for_kids" v-model="youtubeOptions.made_for_kids" type="checkbox">-->
          <!--            <label class="my-auto cursor-pointer" for="made_for_kids">Made for Kids</label>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
import CstInputFields from '@ui/Input/CstInputFields'
import YoutubeTags from '@src/modules/composer_v2/components/ChannelOptions/YoutubeTags'
import debounce from 'lodash/debounce'

export default {
  name: 'YoutubeOptions',
  components: {
    CstRadio,
    YoutubeTags,
    CstDropdown,
    CstDropdownItem,
    CstSimpleCheckbox,
    CstInputFields,
  },
  props: {
    youtubeOptions: {
      type: Object,
      default: () => {},
    },
    accountsList: {
      type: Array,
      default: () => {},
    },
    accountSelection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      privacyStatus: [
        {
          name: 'Private',
          value: 'private',
        },
        {
          name: 'Public',
          value: 'public',
        },
      ],
      category: [
        {
          name: 'Cars & Vehicles',
          value: '2',
        },
        {
          name: 'Comedy',
          value: '23',
        },
        {
          name: 'Education',
          value: '27',
        },
        {
          name: 'Entertainment',
          value: '24',
        },
        {
          name: 'Film & Animation',
          value: '1',
        },
        {
          name: 'Gaming',
          value: '20',
        },
        {
          name: 'How-to & Style',
          value: '26',
        },
        {
          name: 'Music',
          value: '10',
        },
        {
          name: 'News & Politics',
          value: '25',
        },
        {
          name: 'Non-profits & Activism',
          value: '29',
        },
        {
          name: 'People & Blogs',
          value: '22',
        },
        {
          name: 'Pets & Animals',
          value: '15',
        },
        {
          name: 'Science & Technology',
          value: '28',
        },
        {
          name: 'Sport',
          value: '17',
        },
        {
          name: 'Travel & Events',
          value: '19',
        },
      ],
      youtubeLicence: [
        {
          name: 'Standard YouTube Licence',
          value: 'youtube',
        },
        {
          name: 'Creative Commons - Attribution',
          value: 'creativeCommon',
        },
      ],
    }
  },
  computed: {
    selectedPrivacyStatus() {
      const status = this.privacyStatus.find(
        (item) => item.value === this.youtubeOptions.privacy_status
      )
      if (status) {
        return status.name
      }
      return ''
    },
    selectedCategory() {
      const cat = this.category.find(
        (item) => item.value === this.youtubeOptions.category
      )
      if (cat) {
        return cat.name
      }
      return 'Select Category'
    },
    selectedLicence() {
      const licence = this.youtubeLicence.find(
        (item) => item.value === this.youtubeOptions.license
      )
      if (licence) {
        return licence.name
      }
      return 'Standard YouTube Licence'
    },
    accountPlaylists() {
      if (this.accountSelection.youtube.length) {
        const selectedYoutubeChannel = this.accountSelection.youtube[0]
        const youtubeChannel = this.accountsList.find(
          (item) => item[item.channel_identifier] === selectedYoutubeChannel
        )
        if (youtubeChannel) {
          return youtubeChannel.playlists
        }
      }
      return []
    },
    selectedPlaylist() {
      if (this.accountSelection.youtube.length) {
        const playlist = this.accountPlaylists.find(
          (item) => item.id === this.youtubeOptions.playlist
        )
        if (playlist) {
          return playlist.name
        }
      }
      return 'Select Youtube Playlist'
    },
    isPlaylistDisabled() {
      if (this.accountSelection?.youtube?.length > 1) {
        this.clearPlaylist()
        return true
      }
      return false
    },
  },
  watch: {
    'youtubeOptions.post_type': function (val) {
      console.debug('youtubeOptions.post_type', val)
      if (
        val === 'short' &&
        this.youtubeOptions.title.length <= 92 &&
        !this.youtubeOptions.title.includes('#shorts')
      ) {
        this.youtubeOptions.title += ' #shorts'
      } else if (val === 'video') {
        this.youtubeOptions.title = this.youtubeOptions.title.replace(
          ' #shorts',
          ''
        )
      }
    },
  },
  mounted() {
    console.debug('Youtube Options Mounted')
  },
  methods: {
    onChangeTitle: debounce(function (text) {
      this.youtubeOptions.title = text
    }, 500),
    onSelectPrivacyStatus(status) {
      this.youtubeOptions.privacy_status = status.value
    },
    onSelectCategory(category) {
      this.youtubeOptions.category = category.value
      this.$emit('setYoutubeOptionsEdited', true)
    },
    onSelectPlaylist(playlist) {
      this.youtubeOptions.playlist = playlist.id
      this.$emit('setYoutubeOptionsEdited', true)
    },
    clearPlaylist() {
      this.youtubeOptions.playlist = ''
      this.$emit('setYoutubeOptionsEdited', true)
    },
    onChangeYoutubeLicence(license) {
      this.youtubeOptions.license = license.value
      this.$emit('setYoutubeOptionsEdited', true)
    },
    onClickPrivacyStatus(status) {
      this.youtubeOptions.privacy_status = status
      this.$emit('setYoutubeOptionsEdited', true)
    },
    setYoutubePostType(val) {
      this.$emit('setYoutubePostType', val)
    },
  },
}
</script>

<style scoped></style>
