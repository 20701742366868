<template>
  <img
    v-if="icon === 'twitter'"
    src="@assets/img/integration/twitter-x-square.svg"
    alt="twitter_logo"
  />
  <span v-else :class="`integration-icon-${icon}`">
    <span v-for="path in numberOfPaths()" :key="path" :class="`path${path}`"></span>
  </span>
</template>

<script>
export default {
  data() {
    return {
      socialMediaIcons: ['facebook', 'instagram', 'twitter', 'youtube', 'linkedin', 'tumblr', 'pinterest', 'webflow', 'threads']
    }
  },
  props: {
    icon: {
      type: String,
      default: 'facebook',
      required: true
    }
  },
  methods: {
    numberOfPaths() {
      if (this.socialMediaIcons.includes(this.icon)) {
        return 2
      } else if (this.icon === 'shopify' || this.icon === 'wordpress') {
        return 3
      } else if (this.icon === 'medium') {
        return 4
      } else if (this.icon === 'tiktok') {
        return 8
      } else if (this.icon === 'gmb') {
        return 11
      } else {
        return 0
      }
    }
  },

}
</script>
