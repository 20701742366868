<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl px-3 py-2">
    <div class="flex items-center py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.image"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-bold">
        {{ account.name }}
        <span class="block font-normal text-xs text-gray-800"
          >@{{ account.screen_name }}</span
        >
      </div>
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-blue"
      >
        <img
          src="@assets/img/integration/twitter-x-rounded.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div v-if="getDescription" class="items-center py-2">
      <span
        class="whitespace-pre-wrap leading-4"
        v-html="getDescription"
      ></span>
      <span
        v-if="getDescription.length > charLimit && loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        Load More</span
      >
      <span
        v-if="getDescription.length > charLimit && !loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
    <div class="w-full h-full">
      <div
        v-if="linkDetail.url && linkDetail.image.length > 0"
        class="border rounded-md"
      >
        <div
          class="bg-cover bg-no-repeat bg-center rounded-t-md min-h-[160px] max-h-[500px]"
          :style="{ 'background-image': 'url(' + linkDetail.image[0] + ')' }"
        >
          <img
            class="max-w-full max-h-full opacity-0 w-full"
            :src="linkDetail.image[0]"
            alt=""
            @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
          />
        </div>
        <div
          v-if="linkDetail.url || linkDetail.title"
          class="w-full h-max p-2 overflow-hidden rounded-b-md"
        >
          <div
            v-if="linkDetail.url"
            class="block uppercase text-gray-700 text-sm"
            >{{ linkDetail.url }}</div
          >
          <div
            v-if="linkDetail.title"
            class="font-medium text-[0.9rem] leading-4 mt-0.5 truncate"
            >{{ linkDetail.title }}</div
          >
          <div
            v-if="linkDetail.description"
            class="line-clamp max-h-[30px] !overflow-hidden whitespace-pre-wrap text-ellipsis text-sm leading-4 mt-0.5 text-gray-800"
            >{{ linkDetail.description }}</div
          >
        </div>
      </div>

      <div
        v-else-if="detail.image && detail.image.length > 0"
        class="flex flex-wrap relative rounded-lg overflow-hidden"
      >
        <template v-for="(item, imgIndex) in detail.image">
          <div
            v-if="imgIndex <= 3"
            :key="imgIndex"
            class="flex-img bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
            :style="{ 'background-image': 'url(' + item + ')' }"
          >
            <img
            v-tooltip="{
              content: 'Preview image',
              placement: 'auto',
            }"
              class="max-w-full opacity-0 w-full cursor-pointer"
              :src="item"
              alt=""
              @click="openLightbox(item, imgIndex)"
              @error="
                (event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  event.target.classList.remove('opacity-0')
                }
              "
            />
          </div>
        </template>
      </div>
      <div
        v-else-if="detail.video && detail.video.thumbnail"
        class="relative h-100 text-white text-center"
      >
        <img
          class="w-full min-h-48"
          style="max-height: 500px"
          :src="detail.video.thumbnail"
          alt=""
        />
         <!-- playback icon -->
         <div
         class="
           absolute
           top-0
           left-0
           h-full
           w-full
           cursor-pointer
           z-10
         "
         @click.prevent="
           displayFile('video', detail.video.converted_video || detail.video.link, 0)
         "
       >
         <i
         class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"></i>
       </div>
      </div>
    </div>
    <div class="meta flex py-2">
      <span class="block font-normal text-sm text-gray-800">
        <template v-if="type === 'composer'">
          <span v-if="executionTime.date">{{
            $filters.publishedOnlyDateWithWeekdayAndMonth(getWorkspaceTimeZoneTime(executionTime.date, 'YYYY-MM-DD HH:mm:ss'))
          }}</span>
          <span v-else>{{ getWorkspaceTimezonCurrentTime('D MMM YYYY') }}</span>
        </template>

        <span v-if="isLocationAdded"
          >. from
          {{ isLocationAdded }}
        </span>
      </span>
    </div>

    <!-- Threaded tweet Preview -->
    <template v-if="thread.length > 0">
      <template v-for="(item, iter) in thread">
        <div
          v-if="item.message || item.image.length || item.video.thumbnail"
          :key="iter"
          class="thread-box"
        >
          <div class="thread-line"></div>
          <div class="flex items-center py-2">
            <img
              class="rounded-full object-cover border border-solid border-black z-50"
              width="40"
              height="40"
              :src="account.image"
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <div class="px-2 font-bold">
              {{ account.name }}
              <span class="font-normal text-xs text-gray-800"
                >@{{ account.screen_name }}</span
              >
              <span class="font-normal text-sm text-gray-800">
                .
                <span v-if="executionTime.date">{{
                  $filters.publishedOnlyDateWithWeekdayAndMonth(getWorkspaceTimeZoneTime(
                          executionTime.date,
                          'YYYY-MM-DD HH:mm:ss',
                      ))
                }}</span>
                <span v-else>{{
                  getWorkspaceTimezonCurrentTime('D MMM YYYY')
                }}</span>
              </span>
              <span class="block font-normal text-xs text-gray-800"
                >Replying to
                <span class="text-blue-400"
                  >@{{ account.screen_name }}</span
                ></span
              >
            </div>
          </div>
          <div class="ml-12">
            <div v-if="item.message" class="items-center py-1">
              <span
                class="whitespace-pre-wrap"
                v-html="getThreadsDescription(item.message)"
              ></span>
            </div>
            <div class="w-full h-full">
              <div
                  v-if="item.url && item.image.length > 0"
                  class="border rounded-md mt-1"
              >
                <div
                    class="bg-cover bg-no-repeat bg-center rounded-t-md min-h-[160px] max-h-[500px]"
                    :style="{ 'background-image': 'url(' + item.image[0] + ')' }"
                >
                  <img
                      class="max-w-full max-h-full opacity-0 w-full"
                      :src="item.image[0]"
                      alt=""
                      @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
                  />
                </div>
                <div
                    v-if="item.url || item.title"
                    class="w-full h-max p-2 overflow-hidden rounded-b-md"
                >
                  <div
                      v-if="item.url"
                      class="block uppercase text-gray-700 text-sm"
                  >{{ item.url }}</div
                  >
                  <div
                      v-if="item.title"
                      class="font-medium text-[0.9rem] leading-4 mt-0.5 truncate"
                  >{{ item.title }}</div
                  >
                  <div
                      v-if="item.description"
                      class="line-clamp max-h-[30px] !overflow-hidden whitespace-pre-wrap text-ellipsis text-sm leading-4 mt-0.5 text-gray-800"
                  >{{ item.description }}</div
                  >
                </div>
              </div>
              <div
                v-else-if="item.image && item.image.length > 0"
                class="flex flex-wrap relative rounded-lg overflow-hidden"
                style="max-height: 300px; min-height: 150px"
              >|
                <template v-for="(threadImage, index) in item.image">
                  <div
                    v-if="index <= 3"
                    :key="index"
                    class="flex-img bg-cover bg-no-repeat bg-center min-h-[150px] max-h-[500px]"
                    :style="{ 'background-image': 'url(' + threadImage + ')' }"
                    @click="handleImagePreview(index)"
                  >
                    <img
                      class="max-w-full max-h-full opacity-0"
                      :src="threadImage"
                      alt=""
                      @error="
                        (event) => {
                          event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                          event.target.classList.remove('opacity-0')
                        }
                      "
                    />
                  </div>
                </template>
              </div>
              <div
                v-else-if="item.video && item.video.thumbnail"
                class="relative h-100 text-white"
              >
                <img class="w-100" :src="item.video.thumbnail" alt="" />
                <i
                  class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
                  style="transform: translate(-50%, -50%)"
                  @click.prevent="
                    displayFile(
                      'video',
                      detail.video.converted_video || detail.video.link,
                      0,
                    )
                  "
                ></i>
              </div>
            </div>

          </div>
        </div>
        <div  v-if="threadedTweetDetails.length > 0" :key="iter" class="comments-status-box">
          <p v-if="threadedTweetDetails[iter]?.link " class="ml-2">
            <span class="status published"></span>
            <span class="text">Published</span>
            <span> | [Threaded tweet has been posted successfully.]</span>
          </p>
          <p v-else-if="!threadedTweetDetails[iter]?.status && !threadedTweetDetails.link" class="ml-2">
            <span class="status failed"></span>
            <span class="text">Failed</span>
            <span> | {{threadedTweetDetails[iter]?.error}}</span>
          </p>
        </div>
      </template>
    </template>
  </div>

  <VueEasyLightbox
  :visible="visible"
  :imgs="detail.image"
  :index="selectedImageIndex"
  @hide="visible = false"
></VueEasyLightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'

export default {
  name: 'TwitterPreview',
  components: {
    VueEasyLightbox,
  },
  props: {
    commonBoxStatus: {
      type: Boolean,
      default: false,
      required: true,
    },
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    thread: {
      type: Array,
      default: () => [],
      required: false,
    },
    executionTime: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
    linkDetail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    threadedTweetDetails: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      loadMore: true,
      charLimit: 500,
      visible: false,
      imgs: [],
      selectedImageIndex: 0,
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
    isLocationAdded() {
      if (this.commonBoxStatus) return this.detail.location?.twitter?.name
      return this.detail.location?.name
    },
  },
  mounted() {
    console.log('mounted',this.threadedTweetDetails,this.thread)
  },
  methods: {
    changeLoadMore(val) {
      this.loadMore = val
    },
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
    openLightbox(item,index=0) {
      this.selectedImageIndex = index
      this.imgs = item
      this.visible = true
    },
    getThreadsDescription(msg) {
      return parseDescriptionHtml(msg, 5000)
    },
  },
}
</script>

<style lang="scss" scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.thread-box {
  margin: 0;
  position: relative;
  padding-top: 10px;
  .thread-line {
    position: absolute;
    background-color: rgb(207, 217, 222);
    width: 2px;
    height: 100%;
    left: 20px;
    top: 0;
  }
}
.thread-box:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}
</style>
