<template>

    <div class="flex w-100 h-screen  justify-center bg-white">
        <div class="block fit-content text-center mt-auto mb-auto w-160 max-w-160">
          <img class="-mt-28 mb-8 w-52 max-w-52" src="../assets/img/logo/logo_text_logo.png" alt="ContentStudio" />
          <h2 class="font-bold text-black mb-8">Verify your email address!</h2>
          <p class="font-normal text-[#56585c] text-base md:text-lg my-3">
            A verification email has been sent to your email
            <span class="text-blue-500 font-semibold">{{ getProfile.email }}</span>
          </p>
          <p class="font-normal text-[#56585c] text-base md:text-lg my-2 px-20">Please check your email & input the six digit code to complete your account registration. </p>


          <div
              v-if="isVerified"
              class="flex justify-center items-center my-4"
          >
            <clip-loader
                :color="'#5c77f8'"
                :size="'30px'"
            ></clip-loader>
          </div>
          <CodeInput v-else :loading="false" class="input" @complete="onComplete" />


          <p class="px-12 text-center text-base text-gray-800">
            If you do not receive the email within the next 5 minutes, use the
            button below to resend verification email.
          </p>

          <div class="flex justify-center mt-5">
            <button
                class="btn btn-studio-theme-space text-base  mx-2"
                :disabled='isDisabled || loading'
                @click.prevent="resendEmailVerification"
            >Resend <span v-if="counter" class="inline-block rounded-full text-white bg-blue-700 bg-opacity-80 px-2 py-1 text-xs font-bold ml-2.5">{{ counter }}</span></button
            >
            <button
                class="btn btn-studio-theme-transparent  text-base mx-2"
                :disabled='loading'
                @click.prevent="logout"
            >Try with another account</button>
          </div>
        </div>


    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { resendVerificationEmail, verifyEmailURL } from '@src/config/api-utils'
import CodeInput from '../components/CodeInput'


export default {
  name: 'VerifyEmail',
  components: {
    CodeInput
  },
  data () {
    return {
      code: '',
      isDisabled: false,
      isVerified:false,
      loading: false,
      counter: 0
    }
  },
  computed: {
    ...mapGetters(['getTrialExpired'])
  },
  created () {
    this.trackUserMaven('pageview')
  },

  methods: {
    ...mapActions([]),
    resendEmailVerification () {
      this.isDisabled = true
      const self = this
      this.postRequest(
        resendVerificationEmail,
        {},
        (response) => {
          if (response.data.status) {
            this.alertMessage(response.data.message)

            this.counter = 60;
            const downloadTimer = setInterval(() => {
              if(self.counter <= 0){
                self.isDisabled = false
                clearInterval(downloadTimer);
              } else {
                this.counter -= 1;
              }

            }, 1000);

          } else {
            this.alertMessage(response.data.message, 'error')
          }
        },
        (response) => {
          console.error("An error occurred while resending verification email: ", response)
          this.alertMessage('Something went wrong. Please try again', 'error')
          this.isDisabled = false
        }
      )
    },
    onComplete(v) {
      if (this.code && this.code === v) return
      else{
        this.code = v
        this.verifyEmail(v)
      }

    },
    async verifyEmail (code) {
      this.loading = true
      const resp = await this.$http
          .post(verifyEmailURL, { code }, { headers: { Authorization: 'Bearer ' + this.getJWTToken } })
          .catch((err) => {
            if (err.response.status === 500) {
              this.alertMessage(UNKNOWN_ERROR, 'error')
              return false
            }
          })

      if (resp.data.status === true) {
        this.isVerified = true
        gtag('event', 'conversion', {'send_to': 'AW-367072414/vbrFCKfq1f0YEJ6phK8B'});
        await this.fetchWorkspaces(true)
        this.$router.push({name: 'onboardingWorkspace'})
      }
      else if (resp.data.status === false) {
        this.alertMessage(resp.data.message, 'error')
      }
      this.loading = false
    }
  },
  mounted () {
  }
}
</script>
