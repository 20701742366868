<script>
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'

export default {
  components: {
    SkeletonBox,
  },
  data() {
    return {
      loading: false,
      startIndex: 0, // The index of the first item to render
      endIndex: 20, // The index of the last item to render
    }
  },

  computed: {
    ...mapGetters([
      'getDownloadReports',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinCompanyPages',
      'getPinterestBoards',
    ]),
  },
  watch: {},
  async mounted() {
    this.loading = true
    try {
      const res = await this.fetchDownloadReportsService()
      if (res) {
        this.loading = false
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    ...mapActions([
      'fetchDownloadReportsService',
      'removeDownloadReportService',
    ]),
    ...mapMutations(['SET_SCHEDULED_REPORT_ITEM']),
    swalConfig() {
      return swalAttributes()
    },
    async onDeleteConfirmation(event, { reportId, index }) {
      if (this.modalClosable) {
        this.modalClosable = false
      } else {
        event.preventDefault()
        const res = await this.$root.$bvModal.msgBoxConfirm(
          'Are you sure you want to delete this report? This action cannot be undone.',
          {
            title: 'Delete Report',
            ...this.swalConfig(),
            cancelTitle: 'Cancel',
            okTitle: 'Delete Anyway',
          }
        )
        if (res) {
          this.modalClosable = true
          const status = await this.removeDownloadReportService({
            reportId,
            index,
          })
          if (status) {
            this.$store.dispatch('toastNotification', {
              message: 'Report has been deleted successfully.',
              type: 'success',
            })
          }
        }
      }
    },
  },
}
</script>

<template>
  <div class="analytic_common_component custom_report_main">
    <div class="component_inner">
      <div class="report_list clear">
        <h2 class="heading">Download Reports</h2>
        <div class="clearfix"></div>

        <div class="card_content mt-3">
          <!--top_post_table-->
          <div class="social_table">
            <div class="table_wrapper">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 35%">Accounts</th>
                    <th style="width: 200px" class="text-center">Type</th>
                    <th style="width: 124px" class="text-center">Format</th>
                    <th style="width: 300px" class="text-center">Period</th>
                    <th style="width: 180px" class="text-center"
                      >Creation date</th
                    >
                    <th style="width: 120px" class="text-center">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  <template v-if="getDownloadReports?.list.length > 0">
                    <tr
                      v-for="(item, key) in getDownloadReports.list"
                      :key="`download-report-${key}`"
                    >
                      <template v-if="key >= startIndex && key < endIndex">
                        <td>
                          <div>
                            <template
                              v-for="(account, accountIndex) in item.accounts"
                              :key="`accounts_${accountIndex}`"
                            >
                              <div
                                v-if="account"
                                v-tooltip="{
                                  content: channelTooltipHTML(
                                    account.account_type,
                                    account,
                                  ),
                                  classes: 'team_tooltip',
                                  allowHTML: true,
                                  theme: 'light',
                                }"
                                class="d-inline-block mr-3"
                              >
                                <individual-channel-tooltip
                                  :account="account"
                                  :type="account.account_type"
                                ></individual-channel-tooltip>
                              </div>
                            </template>
                          </div>
                        </td>
                        <td class="make-text-center uppercase">
                          {{ item.type === 'twitter' ? 'X (Twitter)' : item.type }} Report
                        </td>
                        <td class="make-text-center"> PDF </td>
                        <td class="make-text-center">
                          {{ $filters.convertToWeekdaySplit(item.date) }}
                        </td>

                        <td class="make-text-center">
                          {{ $filters.convertToWeekday(item.created_at) }}
                        </td>
                        <td class="">
                          <div class="action_icons text-right">
                            <a :href="item.export_url" target="_blank">
                              <i
                                v-tooltip="'Download Report'"
                                class="icon_table fa fa-download"
                              ></i>
                            </a>

                            <i
                              v-tooltip="'Remove Report'"
                              class="icon_table icon-delete-cs"
                              @click="
                                onDeleteConfirmation($event, {
                                  reportId: item._id,
                                  index: key,
                                })
                              "
                            ></i>
                          </div>
                        </td>
                      </template>
                    </tr>

                    <template v-if="endIndex < getDownloadReports?.list.length">
                      <tr>
                        <td colspan="6" class="make-text-center">
                          <button
                            class="btn bg-gray-300 rounded view-more-button"
                            @click="endIndex += 20"
                            >View more</button
                          >
                        </td>
                      </tr>
                    </template>
                  </template>
                  <!-- if loading then show loader else show you have no reports -->
                  <template v-else>
                    <tr>
                      <td colspan="6" class="make-text-center">
                        <div v-if="loading" class="loader">
                          <SkeletonBox
                            width="100%"
                            height="250px"
                            radius="12px"
                          />
                        </div>
                        <div v-else>
                          <p class="no_report"
                            >You have not exported any report yet.</p
                          >
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.view-more-button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;

  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #e0e0e0;
    color: #2972b6; // Change the color to blue
  }
}
</style>
