<script>
// libraries
import {defineComponent, watch, ref, inject} from 'vue'

// components
import TableData from '@src/modules/setting/components/content-categories/calendar/TableData.vue'
import TableHeader from '@src/modules/setting/components/content-categories/calendar/TableHeader.vue'

export default defineComponent({
  name: 'TimeGridCalendar',
  components: {TableData, TableHeader},
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    editSlotModal: {
      type: Function,
      default: () => {
      },
    },
    removeSlotModal: {
      type: Function,
      default: () => {
      },
    },
    addSlotModal: {
      type: Function,
      default: () => {
      },
    },
  },
  setup(props) {
    const root = inject('root')
    const days = ref([
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ])

    /**
     * create div element
     * @param htmlString
     * @returns {ChildNode}
     */
    const createElementFromHTML = (htmlString) => {
      const div = document.createElement('div')
      div.innerHTML = htmlString.trim()
      // Change this to div.childNodes to support multiple top-level nodes.
      return div.firstChild
    }

    // return time e.g. 12a, e.t.c
    const showHour = (index) => {
      let time = ''
      if (index > 12) {
        time = (index % 12) + 'pm'
      } else if (index === 0) {
        time = 12 + 'am'
      } else if (index === 12) {
        time = 12 + 'pm'
      } else {
        time = index + 'am'
      }
      return time
    }
    return {
      createElementFromHTML,
      days,
      showHour,
    }
  },
})
</script>

<template>
  <table class="table-fixed w-full">
    <thead>
    <tr>
      <th
          class="sticky top-0 z-10 h-[2rem] box-content border border-2 border-[#2a8bc3] w-1/5"
      ></th>
      <TableHeader
          v-for="(day, i) in days"
          :key="`table_header_${i}_${Math.random()}`"
          :day="day"
      />
    </tr>
    </thead>
    <tbody>
    <tr v-for="(_, index) in 24" :key="`time_calendar_${index}`">
      <td
          class="custom-table-time-td lg:text-[0.85rem] sm:text-[0.688rem] group relative h-[2rem] box-content border border-2 border-[#2a8bc3] text-center"
      >
        {{ showHour(index) }}
      </td>
      <TableData
          v-for="(day, keyIdx) in days"
          :key="`table_data_${keyIdx}_${Math.random()}`"
          :index="index"
          :day="day"
          :add-slot-modal="addSlotModal"
          :remove-slot-modal="removeSlotModal"
          :edit-slot-modal="editSlotModal"
          :slots="slots"
      />
    </tr>
    </tbody>
  </table>
</template>

<style lang="less">
/*writing custom media queries to handle responsiveness at laptop screen  */
@media screen and (min-width: 1270px) and (max-width: 1400px) {
  .edit-delete-slot {
    padding-left: 0.15rem !important;
    padding-right: 0.15rem !important;
    font-size: 0.7rem !important;
  }
}

@media screen and (min-width: 1270px) and (max-width: 1600px) {
  .custom-table-time-td {
    font-size: 0.7rem !important;
  }
}
</style>
