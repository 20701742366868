<template>
  <div class="authentication_component">
    <AuthenticationLeft></AuthenticationLeft>
    <div class="auth_right login_block">
      <div class="d-flex align-items-center height" style="overflow: auto">
        <div class="_wrapper m-auto">
          <div class="right_logo">
            <img src="../../assets/img/logo/logo_text_logo.png" alt="" />
          </div>
          <div v-if="is_valid_token" class="auth_right_inner">
            <div class="top_block">
              <h2>Recover your password.</h2>
              <p>Enter your new password and confirm password below.</p>
            </div>

            <div class="line_input clear">
              <div class="row">
                <div
                  class="input_field col-md-12 col-sm-12"
                  :class="{ active: password }"
                >
                  <input
                    id="reset_new_password"
                    v-model="password"
                    type="password"
                  />
                  <div class="label">
                    <label for="">
                      <i class="icon_item cs-key"></i>
                      <span class="lbl_txt">New Password</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="input_field col-md-12 col-sm-12"
                  :class="{ active: confirm_password }"
                >
                  <input
                    id="reset_confirm_password"
                    v-model="confirm_password"
                    type="password"
                  />
                  <div class="label">
                    <label for="">
                      <i class="icon_item cs-key"></i>
                      <span class="lbl_txt">Confirm Password</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="btn_block">
                <button
                  class="btn btn_loader loader_right"
                  @click.prevent="recoverPassword"
                >
                  <span>Reset</span>
                </button>
              </div>
            </div>
          </div>
          <div v-else-if="resetLoader" class="auth_right_inner">
            <div class="top_block">
              <beat-loader :color="'#436aff'"></beat-loader>
            </div>
          </div>
          <div v-else class="auth_right_inner">
            <div class="top_block">
              <h2>Link invalid or expired</h2>
              <p
                >Your token is either invalid or has been expired. Please
                request for the new password reset link from here.</p
              >
              <div class="btn_block mt-4">
                <button
                  class="btn btn_loader loader_right"
                  @click.prevent="$router.push({ name: 'forgotpassword' })"
                >
                  <span>Request New Password</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import { authenticationTypes } from '@state/mutation-types'
import {
  recoverPasswordURL,
  resetPasswordTokenURL,
} from '@src/config/api-utils.js'
import AuthenticationLeft from './AuthenticationLeft.vue'

export default {
  components: {
    AuthenticationLeft,
  },
  data() {
    return {
      is_valid_token: false,
      password: '',
      confirm_password: '',
      resetLoader: false,
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  created() {
    this.verifyResetToken()
  },
  methods: {
    ...mapMutations(['SET_PROFILE']),
    verifyResetToken() {
      this.resetLoader = true
      let resetToken = ''
      if (this.$route.query && this.$route.query.token) {
        resetToken = this.$route.query.token
      }
      this.$http.post(resetPasswordTokenURL, { token: resetToken }).then(
        async (response) => {
          this.resetLoader = false
          if (response.data.status) {
            this.is_valid_token = true
          }
        },
        (response) => {}
      )
    },

    recoverPassword() {
      let resetToken = ''
      if (this.$route.query && this.$route.query.token) {
        resetToken = this.$route.query.token
      }
      this.$http
        .post(recoverPasswordURL, {
          password: this.password,
          confirm_password: this.confirm_password,
          token: resetToken,
        })
        .then(
          async (response) => {
            if (response.data.status) {
              this.alertMessage(response.data.message)

              // check if user has enabled 2FA
              if (response.data['2fa_enabled']) {
                await this.$router.push({
                  name: 'twoFactor',
                  params: {
                    token: response.data.user_info,
                  },
                  query: {
                    ...this.$route.query,
                  },
                })
                return
              }

              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
              if (response.data.user) {
                this.SET_PROFILE(response.data.user)
              }
              if (response.data.activeWorkspace) {
                this.$store.commit(
                  workspaceTypes.SET_ACTIVE_WORKSPACE,
                  response.data.activeWorkspace.workspace
                )
                console.debug('activate workspace set')
                const self = this

                this.fetchWorkspaces()
                let redirectRoute = 'dashboard'
                const activeWorkspaceMember = this.getLoggedUserRole(
                  response.data.activeWorkspace.workspace
                )
                if (
                  activeWorkspaceMember &&
                  activeWorkspaceMember.role === 'approver'
                )
                  redirectRoute = 'feed_view'

                setTimeout(function () {
                  self.$router.push({
                    name: redirectRoute,
                    params: {
                      workspace: response.data.activeWorkspace.workspace.slug,
                    },
                  })
                }, 100)
              } else {
                // if (this.getProfile.onBoarding) {
                if (this.getProfile.email_verify === false) {
                  this.$router.push({ name: 'verify_email' })
                } else if (this.getWorkspaces.length > 0) {
                  this.$router.push({ name: 'dashboard', params: { workspace: this.getWorkspaces[0].slug }})
                } else {
                  this.$router.push({ name: 'onboardingWorkspace' })
                }
                // } else {
                //   console.debug('ResetPassword: Redirecting to workspaces')
                //   this.$router.push({ name: 'workspaces' })
                // }
              }
            } else {
              this.alertMessage(response.data.message, 'error')
            }
          },
          (response) => {}
        )
    },
  },
}
</script>
