<template>
  <div
    class="pl-6 pr-1.5"
    :class="{
      'border !border-b-[#ECEEF5] !border-l-0 !border-r-0 !border-t-0 pb-6 mb-6':
        state.isOpen,
    }"
  >
    <!-- Header -->
    <div
      class="pl-[1.33rem] pr-[1.32rem] py-3 flex items-center justify-between rounded-lg mb-1"
      :class="getSocialChannelBackgroundColor(channel)"
    >
      <div class="flex items-center">
        <img
          :src="channelLogo"
          class="w-[20px] h-[20px]"
          alt="social icon"
        />
        <p class="text-[#000000] text-sm  ml-2.5 capitalize leading-none">{{
          channel
        }}</p>
      </div>
      <div class="flex items-center">
        <DatePicker
            :default-value="moment(calendarPointer).format(
                      'MMM DD, YYYY hh:mm A',
                    )"
            :value="moment(calendarPointer).format(
                      'MMM DD, YYYY hh:mm A',
                    )"
            value-type="format"
            format="MMM DD, YYYY hh:mm A"
            title-format="MMM DD, YYYY  hh:mm A"
            type="datetime"
            class="w-[13rem] border-0 social-date-picker"
            input-class="hidden"
            popup-class="border-0 rounded-xl"
            :confirm="true"
            confirm-text="Apply"
            :popup-style="{ boxShadow: '0px 3px 40px rgba(0, 26, 119, 0.08)' }"
            :disabled-date="checkDateDisable"
            :show-time-panel="false"
            :append-to-body="false"
            :clearable="false"
            @change="handleDatePickerChange"
            @click.native="onClickPostDate"
        >
          <template v-slot:footer="{ emit, value }">
            <SelectTime
              :emit="emit"
              :selected-date="value"
              :timezone="getWorkspaces.activeWorkspace.timezone"
            />
          </template>
        </DatePicker>
        <i
          class="fas fa-chevron-down block transition ml-5 text-[#26303E] cursor-pointer text-xs"
          :class="{ 'fa-rotate-180': state.isOpen }"
          @click="() => (state.isOpen = !state.isOpen)"
        ></i>
      </div>
    </div>
    <!--Accounts list-->
    <div v-show="state.isOpen">
      <template
        v-for="(account, index) in customScheduleDataArray"
        :key="`selected-accounts-${index}`"
      >
        <SingleAccountSchedulerComponent
          ref="singleAccountScheduler"
          :account="account"
          :account-image="channelImage(account, account.channelType)"
          :channel-rounded-image="channelLogoRounded"
          :account-time="account.time"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
// libraries
import {reactive, ref, watchEffect} from 'vue'

// components
import DatePicker from 'vue2-datepicker'
import SingleAccountSchedulerComponent from '@src/modules/composer_v2/components/PostSchedulerModule/SingleAccountSchedulerComponent.vue'

// constants
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import { EventBus } from '@common/lib/event-bus'
import SelectTime from '@src/modules/composer_v2/components/PostingSchedule/SelectTime.vue'
import moment from "moment";

const props = defineProps({
  channel: {
    type: String,
    default: 'Channel',
  },
  channelLogo: {
    type: Object,
    default: () => {},
  },
  channelLogoRounded: {
    type: Object,
    default: () => {},
  },
  customScheduleDataArray: {
    type: Array,
    default: () => [],
  },
  channelTime: {
    type: String,
    default: '',
  },
})

const singleAccountScheduler = ref(null)

const calendarPointer = ref(props?.channelTime === '' ? moment().format('YYYY-MM-DD HH:mm:ss') : props?.channelTime)

const {channelImage, getSocialChannelBackgroundColor, checkDateDisable, changeCalendarState} =
    useComposerHelper()

const state = reactive({
  channelScheduleDataArray: [],
  isOpen: true,
})

/**
 * @description emits channel and time info to postingSchedule
 * @param dateTime
 */
const handleDatePickerChange = (dateTime) => {
  if (!dateTime) return
  const dataObject = {
    channel: props.channel,
    time: dateTime,
    commonTime: true,
  }
  EventBus.$emit('channel-time-change', dataObject)
}
const onClickPostDate = (event) => {
  calendarPointer.value = changeCalendarState(event, calendarPointer.value)
}

// watchers
watchEffect(() => {
  calendarPointer.value = props.channelTime === '' ? moment().format('YYYY-MM-DD HH:mm:ss') : props.channelTime
});

</script>

<style lang="scss">
.social-date-picker {
  .mx-datepicker-main {
    border: 0.5px solid #f2f2f7 !important;
  }
  .mx-icon-calendar {
    cursor: pointer;
    position: absolute;
    right: 11px;
    color: #26303e;

    &:hover,
    &:focus {
      color: #1877f2;
    }
  }
  .mx-icon-clear {
    color: #26303e;
    right: 3px;
  }
}

.account-date-picker {
  .mx-datepicker-main {
    border: 0.5px solid #f2f2f7 !important;
  }
  .mx-icon-calendar{
    cursor: pointer;
    &:hover,
    &:focus {
      color: #1877f2;
    }
  }
  .mx-icon-clear {
    color: #26303e;
    padding-right: 0.25rem;

    &:hover,
    &:focus {
      color: #1877f2;
    }
  }
}

@media (max-height: 900px) {
  .account-date-picker .mx-datepicker-main {
    top: 26px !important;
    left: -60px !important;
  }
  .social-date-picker .mx-datepicker-main {
    top: 14px !important;
    left: -60px !important;
  }
}
</style>
