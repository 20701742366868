<template>
  <div class="comment-box-item relative rounded flex p-4 bg-cs-white hover:bg-cs-foggray">
    <div class="mr-2.5">
      <img class="w-8 h-8 rounded-full" :src="comment.user.avatar" alt="">
    </div>
    <div class="text-xs flex-1">
      <div class=" mb-1.5">
        <span class="font-semibold">{{comment.user.name}}</span>
        <span class="ml-1.5">{{$filters.relative(comment.time)}}</span>
      </div>

      <div v-if="!editMode" class="mb-1.5">
        <span class="whitespace-pre-wrap" v-html="getMentionedUsersNames(comment.content.text)"></span>
      </div>
      <div v-else class="mb-1.5  w-full atwho_dropdown">
        <AtTa
            v-model="local.comment"
            :hover-select="true"
            :members="getActiveWorkspaceMembersName"
        >
        <textarea
id="comment-box-edit" ref="ed" v-model="local.comment" maxlength="2200" rows="2"
                  class="rounded-md w-full p-3 border border-gray-300 text-sm"
                  placeholder="Write a comment..."
        ></textarea>
        </AtTa>
        <div class="composer-comment-toolbar flex space-x-1.5 justify-end items-center mt-1.5">
          <Emoji
              dropdown-position="bottom_side"
              type="comment-edit"
          />
          <label class="mb-0 cursor-pointer">
            <i class="far fa-paperclip"></i>
            <input
                accept="image/*"
                type="file"
                class="d-none"
                @change.prevent="uploadCommentImage"
            />
          </label>

          <i class="far fa-at cursor-pointer" @click="triggerAt"></i>
      </div>
      </div>
      <div class="flex flex-wrap space-x-1.5">
        <div v-for="(img, key) in getCommentMedia()" class="flex flex-col mb-2 group relative">
          <img :key="key" class="w-16 h-16 rounded cursor-pointer" :src="img.link" alt="">
          <i
v-if="editMode"
             class="cs-cross absolute hidden  group-hover:block top-0 right-0  text-center p-1.5 bg-red-800 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
             @click="() => {
 local.media.splice(key, 1)
}">
          </i>
          <i
class="absolute hidden group-hover:block w-full bottom-0 cs-eye text-center p-1.5 bg-black-900 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
             @click="previewCommentImage(img.link)"></i>
        </div>
      </div>

      <div v-if="editMode" class="flex justify-start items-center">
        <button class="ml-auto text-xs bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-1.5"  @click="() => updateComment()">Update Comment</button>

        <clip-loader
            v-if="local.loader"
            variant="info"
            class="spinner ml-2.5 mr-1"
            :color="'#9da6ac'"
            :size="'16px'"
        ></clip-loader>
      </div>
    </div>

    <div class="comment-box-item__actions absolute -top-3 bg-white right-3 border">
      <div class="dropdown default_style_dropdown taskDropdown d-inline-block align-middle">
        <i
v-tooltip.top="'Edit'" class="icon-edit-cs text-gray-700 p-1.5 cursor-pointer"
           @click="() => !disabled && handleEdit()"></i>

        <i
v-tooltip.top="'Remove'"
           class="icon-delete-cs dropdown_header text-gray-700 p-1.5 cursor-pointer"
           data-toggle="dropdown"/>
        <div class="dropdown-menu dropdown-menu-right font-semibold">
          <ul class="inner">
            <div class="head_sec">
              <p>Confirmation</p>
            </div>
            <div class="body_text">
              <p>Are you sure?
                <a
                    class="red"
                    @click.prevent="() => !disabled && $emit('delete', comment.id)"
                >Yes</a
                >
                -<a>No</a></p
              >
            </div>
          </ul>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Emoji from "@src/modules/publish/components/posting/social/emoji/Emoji";
import {mapGetters} from "vuex";
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import At from 'vue-at/dist/vue-at.umd'

export default {
  name: 'CommentItemBox',

  components: {
    AtTa,
    At,
    Emoji
  },
  props: {
    comment: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editMode: false,
      local: {
        comment: '',
        mentioned_user: [],
        media: [],
        loader: false
      },
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspaceMembersName']),
  },
  watch: {
    editMode: {
      handler(val) {
        if (val) {
          this.local.comment = this.comment.content.text;
          this.local.mentioned_user = this.comment.content.mentioned_user;
          this.local.media = this.comment.content.media;
        }
      }
    },
  },
  methods: {
    getCommentMedia: function () {
      return this.editMode ? this.local.media : this.comment.content.media
    },
    triggerAt() {
      const {ed} = this.$refs
      ed.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      ed.dispatchEvent(e)
    },
    updateComment() {
      this.$emit('update', this.local, this.comment.id, () => {
        this.editMode = false;
        this.local = {
          comment: '',
          mentioned_user: [],
          media: [],
          loader: false
        }
      });
    },
    handleEdit() {
      this.editMode = !this.editMode
    },
    uploadCommentImage: async function uploadCommentImage() {
      const res = await this.$store.dispatch('uploadImageForComment', event)
      if (res) {
        if (res.data.status) {
          this.local.media = [...this.local.media, res.data.media]
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      }
    },
  }
}
</script>

<style lang="scss">
.comment-box-item {

  &__actions {
    display: none;

  }

  &:hover {
    .comment-box-item__actions {
      display: flex;
    }

  }

  .mention {
    font-weight: 500;
    color: #4e92ff;
  }

}
</style>
