<script setup>

import PartialFailedDetail from "@src/modules/planner_v2/components/PartialFailedDetail.vue";
import PlanSubstatusList from "@modules/planner_v2/components/PlanSubstatusList.vue";
import PushNotificationDetail from "@src/modules/planner_v2/components/PushNotificationDetail.vue";
import {defineProps, ref, watch, computed} from "vue";
import {getPlatformIdentifierValue} from "@common/lib/integrations";
import {getStatusClass, platformPosting} from "@common/lib/planner";
import proxy from "@common/lib/http-common";
import {retryPostPublishURL} from "@src/modules/planner/config/api-utils";
import {useStore} from "@state/base";

// Define props for the component
const props = defineProps({
  plan: {type: Object, required: true},
  account: {type: Object, required: true},
  activeAccount: {type: Object, required: true},
  type: {type: String, required: true},
  externalPostPreview: {type: Boolean, required: false}
});

// Define emits for the component
const emit = defineEmits(['select-social-account']);

// Reactive references
const showStatusDetail = ref(false);
const showSubstatusDetail = ref(false);
const showStatusDetailMsg = ref('');
const firstCommentFailed = ref(false);
const retryLoader = ref(false);
const pushNotificationLoader = ref(false);


const {getters, dispatch} = useStore()

const isDeleted = computed(() => {
  const platformId = getPlatformIdentifierValue(props.account, props.type)
  const postedObj = props.plan?.posting?.find((item) => item.platform_id === platformId)
  return !!postedObj?.deleted;
})

// Constants
const INSTAGRAM_ERROR_CODES = [201, 110]; // Instagram error codes for Collaborator and Tagging


// Computed properties
const isInstagramPosting = computed(() => props.type === 'instagram');
const isInstagramCollaboratorsExists = computed(() => !!props.plan?.instagram_collaborators?.length && isInstagramPosting.value);

// Watch for changes in activeAccount
watch(() => props.activeAccount, (newVal) => {
  if (newVal._id !== props.account._id) {
    updateStatusDetail();
  }
});

// Update status detail based on current state
const updateStatusDetail = () => {

  showStatusDetail.value = showSubstatusDetail.value
  const platformId = getPlatformIdentifierValue(props.account, props.type)
  const postedObj = props.plan?.posting?.find((item) => item.platform_id === platformId)

  if (!showStatusDetail.value) return

  if (!postedObj) {
    handleNoPostedObj()
  } else if (postedObj?.first_comment?.status === false) {
    handleFailedFirstComment(postedObj)
  } else if (getThreadedTweetStatus(postedObj?.threaded_tweets) || getThreadedTweetStatus(postedObj?.multi_threads)) {
    handleFailedThreadedTweet(postedObj);
  } else if (postedObj?.error) {
    handlePostingError(postedObj);
  } else if (isInstagramPosting.value && resendNotificationAvailability()) {
    handleInstagramNotification();
  } else {
    showStatusDetail.value = false
  }
}

// Handle case when there's no posted object
function handleNoPostedObj() {
  showStatusDetail.value = true;
  if (isInstagramPosting.value && INSTAGRAM_ERROR_CODES.includes(props.plan?.posting[0]?.error_code ?? 0)) {
    showStatusDetailMsg.value = errorMessageForCollabAndTag(props.plan?.posting[0]?.error_message, props.plan?.posting[0]?.error_code);
  } else {
    showStatusDetailMsg.value = props.plan?.posting[0]?.error_message;
  }
}

// Handle failed first comment
function handleFailedFirstComment(postedObj) {
  showStatusDetail.value = true;
  firstCommentFailed.value = true;
  showStatusDetailMsg.value = postedObj?.first_comment?.message;
}

// Handle failed threaded tweet
function handleFailedThreadedTweet(postedObj) {
  showStatusDetail.value = true;
  firstCommentFailed.value = true;
  showStatusDetailMsg.value = postedObj?.first_comment?.message;
}

// Handle posting error
function handlePostingError(postedObj) {
  showStatusDetail.value = true;
  if (isInstagramPosting.value && INSTAGRAM_ERROR_CODES.includes(postedObj?.error_code ?? 0)) {
    showStatusDetailMsg.value = errorMessageForCollabAndTag(postedObj?.error_message, postedObj?.error_code);
  } else {
    showStatusDetailMsg.value = postedObj?.error_message;
  }
}

// Handle Instagram notification
function handleInstagramNotification() {
  showStatusDetail.value = true;
  showStatusDetailMsg.value = 'Push notification/reminder sent to your mobile device.';
}

// Select social account and update status
function selectSocialAccount(account) {
  showSubstatusDetail.value = !showSubstatusDetail.value;
  emit('select-social-account', {account, channelKey: props.type});
  updateStatusDetail();
}

// Generate error message for collaborator and tag issues
function errorMessageForCollabAndTag(errorMessage = '', errorCode = 0) {
  return `${errorMessage} (code ${errorCode}): This means either an invalid/private Instagram account was tagged on the image during post creation or invited as a collaborator on an Instagram collaborative post.`;
}

// Check if the current account is active
function activeSocialAccount(account) {
  return props.activeAccount?._id === account?._id;
}

// Compute substatus list for Instagram stories
function computeSubstatusList() {
  const platformId = getPlatformIdentifierValue(props.account, props.type);
  const stories = props.plan?.posting.find(item => item.platform_id === platformId)?.stories ?? [];
  return isInstagramPosting.value ? stories : [];
}

// Generate collaborators tooltip content
function collaboratorsTooltip(collaborators) {
  const list = collaborators.map((collaborator) => `
    <div class="flex mt-2 gap-1 items-center">
      <i class="fab fa-instagram inline-flex items-center w-5 h-5 rounded-lg text-lg text-cs-red"></i>
      <span class="text-sm font-medium">${collaborator}</span>
    </div>
  `).join('');

  return `
    <div class="p-1">
      <p class="text-sm"><strong>Collab Post:</strong> <span>Invitation sent to the following collaborators:</span></p>
      ${list}
      <p class="mt-3"><strong>Note:</strong> <span class="text-sm"> Due to API limitations, we are unable to track the invitations we send to collaborators. Therefore, you'll need to check directly on Instagram to see if they accepted or declined. </span></p>
    </div>
  `;
}

// Get status image URL based on posting status
function getStatusImgUrl(account, type) {
  const platformId = getPlatformIdentifierValue(account, type);
  const postedObj = props.plan?.posting?.find((item) => item.platform_id === platformId);

  if (!postedObj) {
    return require('@assets/img/icons/planner/failed_icon.svg');
  } else if (postedObj?.first_comment?.status === false || getThreadedTweetStatus(postedObj?.threaded_tweets) || getThreadedTweetStatus(postedObj?.multi_threads)) {
    return require('@assets/img/icons/planner/partially_failed_icon.svg');
  } else if (postedObj?.error) {
    return require('@assets/img/icons/planner/failed_icon.svg');
  } else {
    return require('@assets/img/icons/planner/published_icon.svg');
  }
}

// Get status tooltip text
function getStatusTooltip(account, type) {
  const platformId = getPlatformIdentifierValue(account, type);
  const postedObj = props.plan?.posting?.find((item) => item.platform_id === platformId);

  if (!postedObj) {
    return 'Failed';
  }
  if (postedObj?.first_comment?.status === false || getThreadedTweetStatus(postedObj?.threaded_tweets) || getThreadedTweetStatus(postedObj?.multi_threads)) {
    return 'First comment/Threads failed';
  } else if (postedObj?.error) {
    return 'Failed';
  } else {
    return 'Published';
  }
}

// Check if the post has a live link
function isLink(account, type, getUrl = false, checkLink = false) {
  const platformId = getPlatformIdentifierValue(account, type);
  const postedObj = props.plan?.posting?.find((item) => item.platform_id === platformId);
  if (checkLink) {
    return postedObj?.hasOwnProperty('link') && postedObj?.link !== '';
  } else {
    return postedObj?.link;
  }
}

// Check if the post is in progress
function isInProgress() {
  const posting = platformPosting(props.plan, props.type, getPlatformIdentifierValue(props.account, props.type));
  return posting?.retrying;
}

// Get social image URL
function getSocialImage(channelType) {
  return channelType
      ? require(`@assets/img/integration/${channelType}-rounded.svg`)
      : '';
}

// Sanitize social tooltip text
function sanitizedSocialTooltip(name) {
  return name.length > 15 ? name : '';
}

// Get platform posting object
function platformPostingObj() {
  return platformPosting(
      props.plan,
      props.type,
      getPlatformIdentifierValue(props.account, props.type)
  );
}

// Retry posting if failed
async function retryPosting() {
  if (retryLoader.value) return;
  const payload = {
    posting_id: platformPostingObj()._id,
    workspace_id: getters.getActiveWorkspace?._id,
  };
  retryLoader.value = true;
  try {
    const {data} = await proxy.post(retryPostPublishURL, payload);
    handleRetryResponse(data);
  } catch (error) {
    console.error('Retry posting failed:', error);
    await dispatch('toastNotification', {message: 'Retry failed', error: true});
  } finally {
    retryLoader.value = false;
    showStatusDetail.value = false;
  }
}

// Handle retry posting response
function handleRetryResponse(data) {
  const {status, message} = data;
  if (status) {
    updatePostingRetryStatus();
    dispatch('toastNotification', {message, success: true});
  } else {
    dispatch('toastNotification', {message, error: true});
  }
}

// Update posting retry status
function updatePostingRetryStatus() {
  const postingIndex = props.plan.posting.findIndex(
      (posting) => posting._id === platformPostingObj()._id
  );
  if (postingIndex > -1) {
    props.plan.posting[postingIndex].retrying = retryLoader.value;
  }
}

// Check if resend mobile notification option is available
function resendNotificationAvailability() {
  const status = getStatusClass(props.plan);
  const posting = platformPosting(
      props.plan,
      props.type,
      getPlatformIdentifierValue(props.account, props.type)
  );
  if (posting && posting.zapier_published) return false;
  if (posting && posting.api_published) return false;
  return (
      isInstagramPosting.value &&
      (status === 'published' || status === 'failed')
  );
}

// Resend mobile notification if the post failed
async function resendPostNotification() {
  try {
    pushNotificationLoader.value = true;
    await dispatch('sendPostNotification', {
      platform_identifier: props.account.instagram_id,
      planner_id: props.plan._id,
      workspace_id: props.plan.workspace_id,
    });
  } catch (error) {
    console.error('Error resending post notification:', error);
  } finally {
    pushNotificationLoader.value = false;
  }
}

// Get threaded tweet status
function getThreadedTweetStatus(tweetObj) {
  if (!tweetObj) return false;
  return tweetObj.some(item => {
    if (!item.link) {
      showStatusDetailMsg.value = item.error;
      return true;
    }
    return false;
  });
}


</script>

<template>
  <span
      class="group flex items-center cursor-pointer px-6 py-4 border !border-b border-[#ECEEF5] !border-r-0 !border-t-0 !border-l-0 hover:bg-[#FBFCFD]"
      :class="{ 'bg-[#E3F2FF] pl-4 !border-l-[#2593FC] !border-l-8 hover:bg-[#E3F2FF]': activeSocialAccount(account) }"
      @click="selectSocialAccount(account, type)"
  >
    <span class="relative">
      <img
          :src="channelImage(account, type)"
          alt=""
          class="rounded-full object-cover border w-[2.625rem] h-[2.625rem]"
          data-fallback="0"
          @error="
            $event.target.src =
              'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
          "
      />
      <img
          :src="getSocialImage(type)"
          alt=""
          class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
      />
    </span>
    <div class="flex justify-content-between w-[100%]">
      <span class="flex flex-col ml-3 flex-1">

      <span class="flex flex-row">
        <span
            v-tooltip="{
            content: sanitizedSocialTooltip(
              'platform_name' in account
                ? account.platform_name
                : account.name,
            ),
          }"
            class="text-sm text-ellipsis font-medium text-black-100 select-none"
            :class="{ '!text-[#2961D2]': activeSocialAccount(account) }"
        >
          {{
            truncateString(
                'platform_name' in account
                    ? account.platform_name
                    : account.name,
                20,
            )
          }}
        </span>
      </span>

      <span class="block text-xs font-normal select-none text-[#757A8A]">
        {{ 'type' in account ? account.type : 'Profile' }}
      </span>
    </span>
      <!-- check if deleted is true show delete text             -->
        <span
            v-if="isDeleted"
            class="text-sm text-[#B52D4A] font-medium mt-2"
        >
        Deleted
        </span>
      <div
          v-else-if="['published', 'failed'].includes(plan.post_state) && !isInProgress()"
          class="flex align-items-center">
        <!-- status section -->
        <img
            v-if="isInstagramCollaboratorsExists"
            v-tooltip="{ content: collaboratorsTooltip(props?.plan?.instagram_collaborators), allowHTML: true, maxWidth: 500 }"
            src="@src/assets/img/composer/instagram_collab.svg"
            class="w-8 h-8 p-1 mr-1 far text-gray-900 text-md fa-user-plus hover:bg-[#F2F3F8] rounded-full"
            alt="instagram collaborator"/>
        <div v-if="isInstagramCollaboratorsExists" class="h-[1.2rem] mx-2 w-px bg-gray-600"></div>
                <img
                    v-if="type === 'tiktok' && platformPostingObj()?.device_notification"
                    v-tooltip="'This post was published via TikTok mobile notification'"
                    :src="require('@assets/img/icons/planner/grid-view/info-icon.svg')"
                    alt=""
                    class="w-4 h-4 mr-1"
                >
        <div v-if="isLink(account, type, false, true) " class="flex align-items-center">
          <a
              v-tooltip="'Live Link'" :href="isLink(account, type, true, false)" target="_blank"
              class="hover:bg-[#F2F3F8] rounded-full px-2 py-1" @click.stop
          >
            <img
                src="@assets/img/icons/live-link.svg" alt="Live link"
                class="w-[0.875rem] h-[0.875rem]"/>
          </a>
          <div class="h-[1.2rem] mx-2 w-px bg-gray-600"></div>
        </div>
        <img
            v-tooltip="getStatusTooltip(account, type)" :src="getStatusImgUrl(account, type)"
            alt="status icon" class="ml-2 w-5 h-5"
        >
        <i
            v-if="computeSubstatusList().length || getStatusTooltip(account, type) === 'Failed'"
            :class="[showStatusDetail ? 'fa-angle-up' : 'fa-angle-down']"
            class="far ml-4 text-2xl text-gray-900"></i>
        <i v-else class="far fa-angle-up ml-4 text-2xl text-transparent"></i>
      </div>
      <div
          v-else-if="['queued', 'processing'].includes(plan.post_state) || isInProgress()"
          class="flex align-items-center">
        <!-- status section -->
        <div
            v-tooltip="'In Progress'"
            class="flex align-items-center"
            style="background-color: #62B5FF;width: 28px;height: 28px;border-radius: 50%;justify-content: center;">
          <!-- status section -->
          <div class="v-spinner">
            <div class="v-clip inside-loader">
            </div>
          </div>
        </div>
      </div>
      <div
          v-else-if="['draft', 'scheduled', 'reviewed'].includes(plan.post_state)"
          class="flex align-items-center">
        <img
            v-if="isInstagramCollaboratorsExists"
            v-tooltip="{ content: collaboratorsTooltip(props?.plan?.instagram_collaborators), allowHTML: true, maxWidth: 500 }"
            src="@src/assets/img/composer/instagram_collab.svg"
            class="w-8 h-8 p-1 mr-1 far text-gray-900 text-md fa-user-plus hover:bg-[#F2F3F8] rounded-full" alt=""/>
      </div>
    </div>
  </span>

  <Transition name="bounce">
    <template v-if="showStatusDetail && getStatusTooltip(account, type) === 'Published'">
      <PushNotificationDetail
          :msg="showStatusDetailMsg"
          :can-perform="!!plan.can_perform"
          :first-comment-status="firstCommentFailed"
          :resend-notification-availability="resendNotificationAvailability"
          :type="type"
          :push-notification-loader="pushNotificationLoader"
          @retry-post="retryPosting"
          @resend-mobile-notification="resendPostNotification"/>
    </template>
    <template v-else-if="showStatusDetail && ['failed', 'published'].includes(plan.post_state) && !isInProgress()">
      <PartialFailedDetail
          :msg="showStatusDetailMsg"
          :can-perform="!!plan.can_perform"
          :first-comment-status="firstCommentFailed"
          :resend-notification-availability="resendNotificationAvailability"
          :type="type"
          @retry-post="retryPosting"
          @resend-mobile-notification="resendPostNotification"
      />
    </template>
  </Transition>
  <Transition name="bounce">
    <PlanSubstatusList
        v-if="computeSubstatusList().length && showSubstatusDetail"
        title="Story Status"
        :status-list="computeSubstatusList()"
    />
  </Transition>

</template>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.inside-loader {
  height: 1rem;
  width: 1rem;
  border-width: 2px;
  border-style: solid;
  border-color: white white transparent;
  border-radius: 100%;
  background: transparent !important;
}

</style>
