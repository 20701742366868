<template>
  <div class="page_view d-flex align-items-center justify-content-center">
    <div class="subpage">
      <div v-if="company_logo" class="flex-column company-logo-cover m-0">
        <img :src="company_logo" alt="" />
        <h3>{{ company_name }}</h3>
      </div>
      <div class="logo_block flex-column">
        <template
          v-if="['group', 'individual', 'campaign-and-label'].includes(type)"
        >
          <i
            :class="getIcon()"
            class="d-flex align-items-center justify-content-center"
          ></i>
        </template>
        <template v-else-if="type === 'tiktok' || type === 'youtube'">
          <img
            class="icon w-25 rounded-full square-aspect-ratio"
            :src="accounts?.platform_logo"
            :alt="`Logo of ${accounts.platform_name || accounts.name}`"
            @error="handleImageError"
          />
        </template>
        <template v-else>
          <img
            class="icon w-25 rounded-full square-aspect-ratio"
            :src="accounts.image"
            alt=""
          />
        </template>
        <div class="flex gap-2 justify-center items-center">
          <img
            v-if="isPinterestProfile"
            :src="getSocialImageRounded('pinterest')"
            alt="Platform Logo"
            class="h-8 w-8 grayscale"
          />
          <h3>{{
            accounts.platform_name ?? accounts.name ?? accounts.username
          }}</h3>
        </div>

        <h4 class="mt-1">{{ getReportName }} Analytics Report</h4>
        <div
          v-if="['group', 'individual', 'campaign-and-label'].includes(type)"
          class="d-flex bg_main pb-2"
        >
          <!-- for the social channels tooltip -->
          <template v-for="(channel, index) in accounts">
            <div v-if="index < 10 && channel" :key="index" class="flex-row pr-1">
              <div>
                <img
                  class="bg"
                  :src="channel?.platform_logo || channel?.image"
                  alt=""
                  @error="handleImageError"
                />
                <div
                  :class="getClassName(channel?.account_type)"
                  class="bg_i"
                ></div>
              </div>
            </div>
          </template>

          <div v-if="accounts.length > 10" class="flex-row">
            <div class="bg_count">
              <div class="bg_p">+ {{ accounts.length - 10 }}</div>
            </div>
          </div>
        </div>

        <p class="mt-2">{{ getReportDate }}</p>
      </div>
      <div class="generated-date flex-column">
        <p>Generated on {{ getGeneratedDate() }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: {
    reportDate: {
      type: String,
      default: '',
    },
    accounts: {
      type: [Array, Object],
      default: () => [],
    },
    type: {
      type: String,
      default: 'Twitter',
    },
  },
  setup() {
    const { handleImageError, getSocialImageRounded } = useComposerHelper()

    return {
      handleImageError,
      getSocialImageRounded,
    }
  },
  data() {
    return {
      company_name: '',
      company_logo: '',
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),
    getReportDate() {
      if (!this.reportDate) return

      const splitDates = this.reportDate.split(' - ')
      if (splitDates.length < 2) return
      const formattedStartDate = moment(splitDates[0])
        .tz(this.getActiveWorkspace.timezone)
        .format('LL')
      const formattedEndDate = moment(splitDates[1])
        .tz(this.getActiveWorkspace.timezone)
        .format('LL')

      return `${formattedStartDate} - ${formattedEndDate}`
    },

    getReportName() {
      if (!this.type) return
      if(this.type === 'campaign-and-label') return 'Campaigns & Labels';
      else if(this.type === 'twitter') return 'X (Twitter)';
      const format = (
        this.type.charAt(0).toUpperCase() + this.type.slice(1)
      ).replace(/-/g, ' ')

      return format
    },

    isPinterestProfile() {
      return this.type === 'pinterest' && this.accounts?.type === 'Profile'
    },
  },
  mounted() {
    this.getCompanyDetails()
  },
  methods: {
    getCompanyDetails() {
      this.company_name = this.getActiveWorkspace.company_name
      this.company_logo = this.getActiveWorkspace.company_logo
    },
    getGeneratedDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }

      return Intl.DateTimeFormat('en-US', options).format(Date.now())
    },
    getIcon() {
      return this.getClassName(this.type)
    },
    getClassName(type) {
      switch (type) {
        case 'twitter':
          return 'tw_bg cs-twitter'
        case 'facebook':
          return 'fb_bg cs-facebook'
        case 'linkedin':
          return 'lin_bg cs-linkedin'
        case 'pinterest':
          return 'pin_bg cs-pinterest'
        case 'instagram':
          return 'ins_bg cs-instagram'
        case 'youtube':
          return 'youtube_bg cs-youtube'
        case 'tiktok':
          return 'tik_bg cs-tiktok'
        case 'campaign-and-label':
        case 'group':
        case 'individual':
          return 'tum_bg cs-group'
      }
    },
  },
}
</script>

<style>
.bg_main {
  justify-content: center;
}

.bg_count {
  margin: auto;
  background-color: rgba(47, 138, 224, 0.1);
  border-radius: 0.9rem;
  /* justify-content: center; */
  height: 2rem;
  width: 2rem;
}

.bg_p {
  font-size: 1rem;
  color: #357be2;
  position: relative;
  top: 0.3rem;
}

.bg {
  font-size: 13px;
  height: 30px;
  margin: auto;
  width: 30px;
  border-radius: 0.9rem;
}

.bg_i {
  border-radius: 20px;
  color: #ffffff;
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
  position: relative;
  bottom: 0.7rem;
  left: 1rem;
}
</style>
