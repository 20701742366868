import {
  updateProfileUrl,
  changePasswordUrl,
  saveProfileImageUrl,
} from '@src/modules/account/config/api-utils'
import { mapGetters, mapActions } from 'vuex'
// eslint-disable-next-line import/no-relative-parent-imports
import {Crisp} from "crisp-sdk-web";
import { UNKNOWN_ERROR } from '../../constants/messages'
import proxy from '../../lib/http-common';

export const profileMixin = {
  computed: {
    ...mapGetters(['getProfile']),
  },
  methods: {
    ...mapActions([
      'setUpdateProfileLoader',
      'setChangePasswordLoader',
      'setSaveProfileImageLoader',
    ]),

    /**
     * update profile of the logged in user.
     */
    updateProfile() {
      console.debug('Method:updateProfile')

      if (!this.firstName) {
        this.alertMessage('Please enter your first name.', 'error')
        return false
      }

      if (!this.lastName) {
        this.alertMessage('Please enter your last name.', 'error')
        return false
      }

      if (!this.email) {
        this.alertMessage('Please enter your email.', 'error')
        return false
      }

      if (this.validateEmail(this.email) === false) {
        this.alertMessage('Please enter a valid email.', 'error')
        return false
      }
      this.setUpdateProfileLoader(true)
      this.postRequest(
        updateProfileUrl,
        {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          phone_no: this.phone,
        },
        (response) => {
          if (response.data.status === true) {
            this.getProfile.full_name =
              this.getProfile.firstname + ' ' + this.getProfile.lastname

            // update the profile in the store
            this.getProfile.firstname = this.firstName
            this.getProfile.lastname = this.lastName
            this.getProfile.email = this.email
            this.getProfile.phone_no = this.phone
            Crisp.user.setEmail(this.email)
            Crisp.user.setPhone(this.phone)
            Crisp.user.setNickname(this.getProfile.firstname + ' ' + this.getProfile.lastname)


            this.alertMessage(
              'Profile details have been successfully updated',
              'success'
            )

          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to update profile details.', 'error')
          }

          this.setUpdateProfileLoader(false)
        },
        (error) => {
          if (error?.response?.data?.message) {
            this.alertMessage(error?.response?.data?.message, 'error')
          } else {
            this.alertMessage(error.message, 'error')
          }

          console.debug('Exception in updateProfile', error)
          this.setUpdateProfileLoader(false)
        }
      )
    },

    /**
     * change password of the logged in user.
     */
    changePassword() {
      console.debug('Method:changePassword')

      if (!this.old_password)
        return this.alertMessage('Please enter your old password.', 'error')
      if (!this.new_password)
        return this.alertMessage('Please enter your new password.', 'error')
      if (!this.confirm_password)
        return this.alertMessage(
          'Please enter your new password twice.',
          'error'
        )
      if (this.new_password !== this.confirm_password)
        return this.alertMessage(
          'New password does not match in confirmation box.',
          'error'
        )
      this.setChangePasswordLoader(true)

      this.postRequest(
        changePasswordUrl,
        {
          old_password: this.old_password,
          new_password: this.new_password,
          confirm_new_password: this.confirm_password,
        },
        (response) => {
          this.setChangePasswordLoader(false)
          if (response.data.status === true) {
            this.old_password = null
            this.new_password = null
            this.confirm_password = null
            return this.alertMessage(
              'Password has been successfully updated.',
              'success'
            )
          }

          if (response.data.message)
            return this.alertMessage(response.data.message, 'error')
          this.alertMessage('Unable to change password.', 'error')
        },
        (error) => {
          console.debug('Exception in changePassword', error)
          this.setChangePasswordLoader(false)
        }
      )
    },

    triggerProfileImageFileChange() {
      console.debug('Method:triggerProfileImageFile')
      document.getElementById('profile_image').click()
    },

    async saveProfileImage(event) {
      const files = event.target.files || event.dataTransfer.files
      if(!files?.length){
        return
      }

      const formData = new FormData()
      formData.append('file', files[0])
      formData.append('workspace_id', this.$store.getters?.getActiveWorkspace?._id)

      this.setSaveProfileImageLoader(true)

      return await proxy
        .post(saveProfileImageUrl, formData)
        .then((response) => {
          if (response.data.status === true) {
            this.getProfile.image = response.data.media.link
            this.alertMessage(
              'Profile image has been successfully updated.',
              'success'
            )
          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to change the profile image.', 'error')
          }

          this.setSaveProfileImageLoader(false)

          return
        })
        .catch((error) => {
          console.debug('Exception in saveProfileImage', error)
          event.target.value = ''
          this.setSaveProfileImageLoader(false)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        })
    },
  },
}
