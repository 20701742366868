<template>
  <div class="analytic_common_component">
    <div v-if="loader" class="component_inner">
      <div class="page_view d-flex align-items-center justify-content-center">
        <div class="subpage">
          <div class="logo_block">
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loader && !getReportsServiceCalls.retrieve"
      class="component_inner"
    >
      <CoverPage
        :type="type"
        :accounts="metrics.accounts"
        :report-date="report_date"
      ></CoverPage>
      <template v-if="type === 'twitter'">
        <TwitterReport :metrics="metrics"></TwitterReport>
      </template>
      <template v-else-if="type === 'pinterest'">
        <PinterestReport :metrics="metrics"></PinterestReport>
      </template>
      <template v-else-if="type === 'linkedin'">
        <LinkedinReport :metrics="metrics"></LinkedinReport>
      </template>
      <template v-else-if="type === 'facebook'">
        <FacebookReport :metrics="metrics"></FacebookReport>
      </template>
      <template v-else-if="type === 'instagram'">
        <InstagramReport :metrics="metrics"></InstagramReport>
      </template>
      <template v-else-if="type === 'tiktok'">
        <TiktokReport :metrics="metrics"></TiktokReport>
      </template>
      <template v-else-if="type === 'youtube'">
        <YoutubeReport :metrics="metrics"></YoutubeReport>
      </template>
      <template v-else-if="type === 'campaign-and-label'">
        <LabelCampaignReport :metrics="metrics"></LabelCampaignReport>
      </template>
      <template v-else-if="type === 'group' || type === 'individual'">
        <OverviewReport
          :metrics="metrics"
          :account-select-flags="accountSelectFlag"
        ></OverviewReport>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OverviewReport from '@src/modules/analytics/views/reports/OverviewReport'
import LinkedinReport from '@src/modules/analytics/views/reports/LinkedinReport'
import FacebookReport from '@src/modules/analytics/views/reports/FacebookReport'
import TwitterReport from '@src/modules/analytics/views/reports/TwitterReport'
import InstagramReport from '@src/modules/analytics/views/reports/InstagramReport'
import TiktokReport from '@src/modules/analytics/views/reports/TiktokReport'
import YoutubeReport from '@src/modules/analytics/views/reports/YoutubeReport'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types.js'
import { commonMethods } from '@common/store/common-methods'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'
import { dataValues } from './common/helper'
import PinterestReport from './reports/channel/PinterestReport'
import CoverPage from './reports/CoverPage'
import analyticsUtilsMixin from './common/analyticsUtilsMixin'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import useLabelAndCampaign from '@/src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import LabelCampaignReport from '@/src/modules/analytics/components/reports/channel/LabelCampaignReport.vue'

const {
  routes: tiktokRoutes,
  selectedAccount: selectedTiktokAccount,
  isPostDataLoading: isTiktokPostDataLoading,
  dateRange: tiktokDateRange,
  postsLimit: tiktokPostLimit,
  fetchMedia: fetchTiktokMedia,
} = useTiktokAnalytics()

const {
  DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
  routes: youtubeRoutes,
  selectedAccount: selectedYoutubeAccount,
  isPostDataLoading: isYoutubePostDataLoading,
  dateRange: youtubeDateRange,
  postsLimit: youtubePostsLimit,

  fetchMedia: fetchYoutubeMedia,
} = useYoutubeAnalytics()

const {
  routes: pinterestRoutes,
  selectedAccount: selectedPinterestAccount,
  dateRange: pinterestDateRange,
  isPostDataLoading: pinterestIsPostDataLoading,
  postsLimit: pinterestPostsLimit,
  getPerformanceChartPayload,
  getTopLeastEngagingPayload,
  TopLeastEngagementDropdown,
  fetchMedia: fetchPinterestMedia,
} = usePinterestAnalytics()

const {
  routes: twitterRoutes,
  selectedAccount: selectedTwitterAccount,
  isPostDataLoading: isTwitterPostDataLoading,
  dateRange: twitterDateRange,
  postsLimit: twitterPostsLimit,

  fetchMedia: fetchTwitterMedia,
} = useTwitterAnalytics()

const {
  routes: labelCampaignRoutes,
  selectedAccount: selectedLabelCampaignAccount,
  dateRange: labelCampaignDateRange,
  isAllAccountsSelected: isAllLabelCampaignAccountsSelected,
  selectedCampaignsAndLabels,
  fetchMedia: fetchLabelCampaignMedia,
} = useLabelAndCampaign()

const { isReportView } = useAnalytics()

export default {
  components: {
    CoverPage,
    TwitterReport,
    PinterestReport,
    LinkedinReport,
    FacebookReport,
    InstagramReport,
    TiktokReport,
    YoutubeReport,
    OverviewReport,
    LabelCampaignReport,
  },
  mixins: [analyticsUtilsMixin],
  data() {
    return {
      metrics: {},
      report_date: '',
      is_rendered: false,
      loader: true,
      type: '',
      workspace: [],
      accounts: [],
      top_post_loader: false,
      previous_date: '',
    }
  },
  computed: {
    ...mapGetters(['getReportsServiceCalls']),
  },
  watch: {},
  created() {
    // setTimeout(function () {
    //     window.print();
    // },5000)
  },
  async mounted() {
    isReportView.value = true

    setTimeout(function () {
      commonMethods.toggleWidgets(true)
    }, 5000)
    if (this.$route.params.reportId) {
      const response = await this.getReportsService({
        _id: this.$route.params.reportId,
      })

      if (response) {
        const {
          workspace_id: workspaceId,
          date,
          previous_date: previousDate,
          accounts,
          type,
        } = response
        this.metrics.topPostsLimit = response?.topPosts || 5
        this.metrics.campaigns = response?.campaigns || []
        this.metrics.labels = response?.labels || []
        this.metrics.allAccountsSelected = response?.allAccountsSelected || false

        this.workspace = await this.fetchWorkspace({
          id: response.workspace_id,
        })
        this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, this.workspace)
        this.previous_date = previousDate
        await this.getAnalyticsForReports(
          {
            date,
            workspaceId,
            accounts,
            type,
            previousDate,
            timezone: this.workspace.timezone,
          },
          response.accounts
        )

        this.report_date = response.date
        this.type = response.type
        this.accounts = response.accounts

        if (!this.isEmpty(this.metrics)) {
          this.loader = false
        }

        const self = this
        setTimeout(() => {
          self.is_rendered = true
        }, 10000)
      }
    }
    await this.getTopPosts()
  },
  beforeUnmount() {
    isReportView.value = false
  },
  methods: {
    ...mapActions([
      'getAnalyticsService',
      'getReportsService',
      'getAnalyzeService',
      'getTwitterAnalyticsService',
    ]),
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
    GoToSocial() {
      this.$router.push({ name: 'social' })
      // eslint-disable-next-line no-undef
      $('.modal').modal('hide')
    },
    async getTopPosts() {
      if (
        'facebook_id' in this.accounts[0] &&
        this.accounts[0].facebook_id !== null
      ) {
        this.top_post_loader = true
        const response = await this.getAnalyzeService({
          facebook_id: this.accounts[0].facebook_id,
          date: this.report_date,
          tab: 'posts',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        if (response) {
          const formatedData = {
            facebook: response.top_posts,
          }
          this.metrics.top_posts = formatedData
        }
      }
      this.top_post_loader = false
    },
    async getSummaryDataForInstagram(payloadCopy) {
        payloadCopy.tab = 'overview'

        payloadCopy.section = 'summary'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.summary = response.overview.summary
    },
    async getAudienceGrowthDataForInstagram(payloadCopy) {
        payloadCopy.section = 'audience_growth'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.audience_growth = response.overview.audience_growth
        this.metrics.overview.audience_growth_rollup = response.overview.audience_growth_rollup
    },
    async getPublishingBehaviourDataForInstagram(payloadCopy) {
        payloadCopy.section = 'publishing_behaviour'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.publishing_behaviour = response.overview.publishing_behaviour
        this.metrics.overview.publishing_behaviour_rollup = response.overview.publishing_behaviour_rollup
    },
    async getTopPostsDataForInstagram(payloadCopy) {
        payloadCopy.section = 'top_posts'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_posts = response.top_posts
    },
    async getActiveUsersDataForInstagram(payloadCopy) {
        payloadCopy.section = 'active_users'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.active_users = response.overview
    },
    async getImpressionsDataForInstagram(payloadCopy) {
        payloadCopy.section = 'impressions'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.impressions = response.overview
    },
    async getEngagementDataForInstagram(payloadCopy) {
        payloadCopy.section = 'engagement'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.engagement = response.overview
    },
    async getHashtagsDataForInstagram(payloadCopy) {
        payloadCopy.section = 'hashtags'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.hashtags = response
    },
    async getStoriesPerformanceDataForInstagram(payloadCopy) {
        payloadCopy.section = 'stories_performance'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.stories_performance = response.overview
    },
    async getAudienceAgeDemographicForInstagram(payloadCopy) {
        payloadCopy.section = 'audience_age'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.fans_age = response.audience_age
        this.metrics.demographics.fans_gender = response.audience_gender
        this.metrics.demographics.max_gender_age = response.max_audience_age
        this.metrics.demographics.fans = response.audience_gender.audience_fans
    },
    async getCountryCityDemographicForInstagram(payloadCopy) {
        payloadCopy.section = 'country_city'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.audience_country = response.audience_country
        this.metrics.demographics.audience_country = this.sortList(
          this.metrics.demographics.audience_country
        )
        this.metrics.demographics.audience_city = response.audience_city
        this.metrics.demographics.audience_city = this.sortList(
          this.metrics.demographics.audience_city
        )
    },
    async getReelPerformanceDataForInstagram(payloadCopy) {
        payloadCopy.section = 'reels_performance'
        const response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.reels_performance = response.overview
    },
    async getAnalyticsForReports(payload = {}, accounts) {
      this.loader = true
      const payloadCopy = payload
      const res = payloadCopy.date.split(' - ')

      // Sets the value of the dateRange variable to the response received from the server.
      youtubeDateRange.value = res
      pinterestDateRange.value = res
      twitterDateRange.value = res
      labelCampaignDateRange.value = res
      tiktokDateRange.value = res

      payloadCopy.previous_date = `${this.previousAnalyticsPeriodDate(
        res[0],
        res[1]
      )} - ${res[0]}`

      if (payloadCopy.type === 'group' || payloadCopy.type === 'individual') {
        const sections = [
          'overview',
          'top_posts',
          'engagement_rollup',
          'posts_engagements',
          'account_performance',
        ]

        for (const section in sections) {
          payloadCopy.section = sections[section]
          payloadCopy.accounts = accounts
          const accountTypes = [
            'facebook',
            'instagram',
            'linkedin',
            'tiktok',
            'youtube',
            'twitter',
          ]
          for (const type of accountTypes) {
            this.accountSelectFlag[type] = accounts.some(
              (item) => item.account_type === type
            )
          }
          const resp = await this.getAnalyzeService(payloadCopy)

          this.$set(this.metrics, sections[section], resp[sections[section]])

          // this.$set(this.metrics.accounts, 'accounts', accounts)
        }
        this.$set(this.metrics, 'accounts', payloadCopy.accounts)
        // this.metrics = Object.assign({}, this.metrics, {
        //   s_accounts: payloadCopy.accounts
        // })
      } else if (payloadCopy.type === 'facebook') {
        this.metrics = dataValues()[payloadCopy.type]
        this.metrics.accounts = payloadCopy.accounts[0]
        payloadCopy.accounts = payloadCopy.accounts[0].facebook_id
        payloadCopy.tab = 'overview'

        payloadCopy.section = 'summary'
        let response = await this.getAnalyzeService(payloadCopy)
        if ('current' in response.overview) {
          this.metrics.overview.summary = response.overview
        }

        payloadCopy.section = 'audience_growth'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.audience.audience_growth =
          response.audience_growth
        if ('fan_count' in response.audience_growth_rollup.current) {
          this.metrics.overview.audience.audience_growth_rollup =
            response.audience_growth_rollup
        }

        payloadCopy.section = 'publishing_behaviour'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.publishing_behaviour.publishing_behaviour =
          response.publishing_behaviour
        this.metrics.overview.publishing_behaviour.publishing_behaviour_rollup =
          response.publishing_behaviour_rollup

        payloadCopy.section = 'top_posts'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_posts = response.top_posts

        payloadCopy.section = 'active_users'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.active_users = response.active_users

        payloadCopy.section = 'impressions'
        response = await this.getAnalyzeService(payloadCopy)
        if ('impressions_rollup' in response) {
          this.metrics.overview.impressions.impressions_rollup =
            response.impressions_rollup
        }
        this.metrics.overview.impressions.impressions = response.impressions

        payloadCopy.section = 'reach'
        response = await this.getAnalyzeService(payloadCopy)
        if ('reach_rollup' in response) {
          this.metrics.overview.reach.reach_rollup =
            response.reach_rollup
        }
        this.metrics.overview.reach.reach = response.reach

        payloadCopy.section = 'engagement'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.engagement.engagement = response.engagement
        if ('engagement_rollup' in response) {
          this.metrics.overview.engagement.engagement_rollup =
            response.engagement_rollup
        }

        payloadCopy.section = 'reels'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.reels.reels = response.reels
        if ('reels_rollup' in response) {
          this.metrics.overview.reels.reels_rollup =
            response.reels_rollup
        }

        payloadCopy.section = 'video_analytics'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.video_analytics = response.video_insights

        payloadCopy.tab = 'demographics'

        payloadCopy.section = 'audience_age'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.fans_age = response.audience_age
        this.metrics.demographics.fans_gender = response.audience_gender
        this.metrics.demographics.max_gender_age = response.max_gender_age
        this.metrics.demographics.fans = response.fans

        payloadCopy.section = 'country_city'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.demographics.audience_country = response.audience_country
        this.metrics.demographics.audience_country = this.sortList(
          this.metrics.demographics.audience_country
        )
        this.metrics.demographics.audience_city = response.audience_city
        this.metrics.demographics.audience_city = this.sortList(
          this.metrics.demographics.audience_city
        )
      } else if (payloadCopy.type === 'instagram') {
        this.metrics = dataValues()[payloadCopy.type]
        this.metrics.accounts = payloadCopy.accounts[0]
        payloadCopy.accounts = payloadCopy.accounts[0].instagram_id
        this.getSummaryDataForInstagram(payloadCopy)

        this.getAudienceGrowthDataForInstagram(payloadCopy)

        this.getPublishingBehaviourDataForInstagram(payloadCopy)

        this.getTopPostsDataForInstagram(payloadCopy)

        this.getActiveUsersDataForInstagram(payloadCopy)

        this.getImpressionsDataForInstagram(payloadCopy)

        this.getEngagementDataForInstagram(payloadCopy)

        this.getHashtagsDataForInstagram(payloadCopy)

        this.getStoriesPerformanceDataForInstagram(payloadCopy)

        this.getReelPerformanceDataForInstagram(payloadCopy)

        payloadCopy.tab = 'demographics'

        this.getAudienceAgeDemographicForInstagram(payloadCopy)

        this.getCountryCityDemographicForInstagram(payloadCopy)
      } else if (payloadCopy.type === 'linkedin') {
        this.metrics = dataValues()[payloadCopy.type]
        this.metrics.accounts = payloadCopy.accounts[0]
        this.metrics.profile = payloadCopy.accounts[0].type
        payloadCopy.accounts = payloadCopy.accounts[0].linkedin_id
        payloadCopy.tab = 'overview'

        payloadCopy.section = 'summary'
        let response = await this.getAnalyzeService(payloadCopy)
        if ('current' in response.overview) {
          this.metrics.overview.summary = response.overview
        }

        payloadCopy.section = 'audience_growth'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.audience.audience_growth =
          response.audience_growth
        this.metrics.overview.audience.audience_growth_rollup =
          response.audience_growth_rollup

        payloadCopy.section = 'top_posts'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_posts = response.top_posts

        payloadCopy.section = 'engagement'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.engagement.engagement = response.engagement
        if ('engagement_rollup' in response) {
          this.metrics.overview.engagement.engagement_rollup =
            response.engagement_rollup
        }

        payloadCopy.section = 'posts_per_days'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.posts_per_days = response.posts_per_days

        payloadCopy.section = 'hashtags'
        response = await this.getAnalyzeService(payloadCopy)
        this.metrics.overview.top_hashtags = response.top_hashtags
      } else if (payloadCopy.type === 'tiktok') {
        selectedTiktokAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        tiktokPostLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT

        fetchTiktokMedia(tiktokRoutes.POSTS_DATA, {
          limit: isReportView.value
            ? tiktokPostLimit.value
            : DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
          sort_order: 'total_engagement',
        })
        isTiktokPostDataLoading.value = false
        fetchTiktokMedia(tiktokRoutes.PAGE_AND_POST_INSIGHTS)
        fetchTiktokMedia(tiktokRoutes.PAGE_FOLLOWERS_AND_VIEWS)
        fetchTiktokMedia(tiktokRoutes.POSTS_AND_ENGAGEMENTS)
        fetchTiktokMedia(tiktokRoutes.TOP_AND_LEAST_PERFORMING_POSTS)
        fetchTiktokMedia(tiktokRoutes.DAILY_ENGAGEMENTS_DATA)
      } else if (payloadCopy.type === 'youtube') {
        selectedYoutubeAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        youtubePostsLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT

        isYoutubePostDataLoading.value = false
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_SUBSCRIBER_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_SUMMARY)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_ENGAGEMENT_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_VIEWS_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_WATCH_TIME_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_FIND_VIDEO)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_VIDEO_SHARING)
        fetchYoutubeMedia(youtubeRoutes.SORTED_TOP_POSTS, {
          order_by: 'engagement',
          limit: isReportView.value
            ? youtubePostsLimit.value
            : DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
        })
        fetchYoutubeMedia(youtubeRoutes.TOP_PERFORMING_POSTS)
        fetchYoutubeMedia(youtubeRoutes.LEAST_PERFORMING_POSTS)
        fetchYoutubeMedia(youtubeRoutes.PERFORMANCE_AND_VIDEO_POSTING_SCHEDULE)
      } else if (payloadCopy.type === 'pinterest') {
        selectedPinterestAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]

        pinterestIsPostDataLoading.value = false
        pinterestPostsLimit.value = this.metrics?.topPostsLimit

        fetchPinterestMedia(pinterestRoutes.OVERVIEW_SUMMARY, {
          type: 'summary',
        })
        fetchPinterestMedia(pinterestRoutes.FOLLOWERS_TREND)
        fetchPinterestMedia(pinterestRoutes.IMPRESSIONS_TREND)
        fetchPinterestMedia(pinterestRoutes.ENGAGEMENTS_TREND)
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_PER_DAY, {
          filter_by: 'image',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_ROLLUP, {
          filter_by: 'image',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_PER_DAY, {
          filter_by: 'video',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_ROLLUP, {
          filter_by: 'video',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_PER_DAY)
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_ROLLUP)

        TopLeastEngagementDropdown.forEach((dropdownOption) => {
          fetchPinterestMedia(pinterestRoutes.LEAST_PERFORMING_PINS, {
            limit: 5,
            order_by: dropdownOption.api_key,
          })
          fetchPinterestMedia(pinterestRoutes.TOP_PERFORMING_PINS, {
            limit: 5,
            order_by: dropdownOption.api_key,
          })
        })

        fetchPinterestMedia(
          pinterestRoutes.TOP_PERFORMING_PINS,
          getTopLeastEngagingPayload('report', this.metrics?.topPostsLimit)
        )
        fetchPinterestMedia(
          pinterestRoutes.PIN_POSTING_PERFORMANCE,
          getPerformanceChartPayload()
        )
      } else if (payloadCopy.type === 'twitter') {
        selectedTwitterAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        twitterPostsLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT

        isTwitterPostDataLoading.value = false
        fetchTwitterMedia(twitterRoutes.PAGE_AND_POST_INSIGHTS)
        fetchTwitterMedia(twitterRoutes.ENGAGEMENTS_AND_IMPRESSIONS)
        fetchTwitterMedia(twitterRoutes.FOLLOWERS_TREND)
        fetchTwitterMedia(twitterRoutes.TOP_PERFORMING_TWEETS, {
          limit: isReportView.value
            ? twitterPostsLimit.value
            : DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
        })
        fetchTwitterMedia(twitterRoutes.LEAST_PERFORMING_TWEETS)

      } else if (payloadCopy.type === 'campaign-and-label'){
        selectedLabelCampaignAccount.value = payloadCopy.accounts
        this.metrics.accounts = payloadCopy.accounts
        isAllLabelCampaignAccountsSelected.value  = this.metrics.allAccountsSelected
        selectedCampaignsAndLabels.value = {
          campaigns: this.metrics.campaigns,
          labels: this.metrics.labels,
        }
        // routes.ALL_LABELS_AND_CAMPAIGNS should always be awaited, before rest of the APIs
        await fetchLabelCampaignMedia(labelCampaignRoutes.ALL_LABELS_AND_CAMPAIGNS)
        fetchLabelCampaignMedia(labelCampaignRoutes.SUMMARY_ANALYTICS)
        fetchLabelCampaignMedia(labelCampaignRoutes.BREAKDOWN_DATA)
        fetchLabelCampaignMedia(labelCampaignRoutes.INSIGHTS_GRAPH_BREAKDOWN)
      }
      else {
        const accounts = payloadCopy.accounts[0]
        payloadCopy.accounts = payloadCopy.accounts[0].instagram_id
        const resp = await this.getAnalyticsService(payloadCopy)
        this.metrics = Object.assign(dataValues()[payloadCopy?.type], resp)
        this.metrics.accounts = accounts
        if (payloadCopy.type === 'instagram') {
          payloadCopy.section = 'demographics'
          this.metrics.demographics = await this.getAnalyticsService(
            payloadCopy
          )
        }
      }
      this.loader = false

      setTimeout(() => {
        window.print()
      }, 30000)
    },
  },
}
</script>

<style lang="scss">
.analytics_dp {
  /*background: #f5f9fc !important;*/
}

.profile_image {
  border-radius: 4px;
  width: 38px;
}
</style>
