<template>
  <ConfirmBox
    id="upgrade-plan-confirmation-box"
    :call-back-action="getAction"
    confirm-text="Proceed"
    :cancel-text="isDowngrade ? 'Let me adjust my limits' : 'No'"
  >
    <template v-if="getProfile.state === 'paused' || getProfile.state === 'deleted'" v-slot:header>
      <h2>Purchase Plan</h2>
    </template>
    <template v-else-if="isDowngrade" v-slot:header>
      <h2>You are about to purchase a lower-tier plan!</h2>
    </template>
    <template v-else v-slot:header>
      <h2>Upgrade Plan</h2>
    </template>

    <template v-if="getProfile.state === 'paused' || getProfile.state === 'deleted'" v-slot:footer>
      <p>You are about to purchase the {{ subscription.display }}
        {{ subscription.display_tag ? ' - '+subscription.display_tag : '' }} plan, please confirm if you want to go
        ahead with this?</p
      >
    </template>
    <template v-else-if = "isDowngrade" v-slot:footer>
        <p>Please adjust your limits accordingly so that your workspaces don't get locked automatically.</p>
      </template>
    <template v-else v-slot:footer>
      <p
      >You are about to upgrade your current plan
        {{ getPlan.subscription.display_name }} to
        {{ subscription.display }} - {{ subscription.display_tag }}, please
        confirm if you want to go ahead with this?</p
      >
    </template>
  </ConfirmBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils.js'
import { EventBus } from '@common/lib/event-bus'
import useWorkspace from "@common/composables/useWorkspace";
import {UNKNOWN_ERROR} from "@common/constants/messages";

export default {
  components: {
    ConfirmBox,
  },

  setup(){
    const { getSuperAdmin } = useWorkspace()
    return {
      getSuperAdmin
    }
  },

  // eslint-disable-next-line vue/require-prop-types,vue/prop-name-casing
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    billing_period: {
      type: String,
      required: true,
    },
    isDowngrade: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getPlan', 'getProfile', 'getActiveWorkspace']),

    isAdmin () {
      return this.superAdmin._id !== this.$store.getters.getProfile._id
    },
    superAdmin() {
      return this.getSuperAdmin()
    }
  },
  created() {},
  mounted() {},

  methods: {
    getAction(confirmation) {
      console.debug('confirmation', confirmation.type)
      if (confirmation.status) {
        this.upgradeSubscriptionPlanAction()
      }
      // eslint-disable-next-line no-undef
      $('#' + confirmation.type).modal('hide')
    },
    async upgradeSubscriptionPlanAction() {
      const routeQueryValue = this.$route.query.special
      console.log(this.isTrialPlan())

      if(!this.superAdmin?._id) {
        this.alertMessage(UNKNOWN_ERROR, 'error')
      }

      const self = this
      let couponCode = this.billing_period === 'annually' ? 'ANNUAL20OFF' : ''

      if (routeQueryValue && this.isTrialPlan()) {
        couponCode = this.billing_period === 'annually' ? 'BF2020DEAL50OFF' : ''
      }

      // upgrade via Paddle subscription. There would be paddle_subscription_id

      if (this.superAdmin.paddle_account_id) {
        const res = await this.upgradeBySubscriptionId(this.subscription.paddle_id)
        if(!res?.need_subscription) return
      }

      // if the account does not have any paddle account id.
      const passthrough = {
        is_admin: this.isAdmin,
        admin_id: this.isAdmin ? this.$store.getters.getProfile._id : null,
        user_id: this.superAdmin._id,
        user_email: this.superAdmin.email,
        paddle_account_id: this.superAdmin.paddle_account_id || '',
        paddle_subscription_id: this.superAdmin.paddle_subscription_id || '',
      }

      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        product: this.subscription.paddle_id,
        email: this.superAdmin.email,
        title: '',
        coupon: couponCode,
        passthrough: JSON.stringify(passthrough),
        successCallback: function (data) {
          console.log(data)
          window.fbq('track', 'Purchase', {
            value: parseInt(data.checkout.prices.customer.total),
            currency: data.checkout.prices.customer.currency,
          })
          self.triggerGTMEvent(data, 'plan')
          // send event to usermaven
          self.trackUserMaven('plan_upgraded', {
            plan_name: self.subscription?.fs_name,
            plan_price: self.subscription?.price_int || 0,
            plan_billing_period: self.billing_period === 'annually' ? 'yearly' : 'monthly',
            old_plan_name: self.getPlan?.subscription?.fastspring_name || '',
          })
          alert('Thanks for your purchase.')
          setTimeout(async () => {
            window.location.reload();
          }, 3000)
          // self.upgradeCallback(data)
        },
      })
    },
    async upgradeBySubscriptionId(subscriptionId) {
      const res = await proxy
        .post(`${billingBaseUrl}paddle/upgrade_subscription_by_id`, {
          paddle_subscription_id: subscriptionId,
          workspace_id: this.$store.getters.getActiveWorkspace._id,
        })
        .then((res) => {
          console.log(res)
          if(res.data.need_subscription) {
            return res.data
          }
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
            })
            return true
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 422) {
            this.$store.dispatch('toastNotification', {
              message: err.response.data.message,
              type: 'error',
            })
          }
          return false
        })

      if(res?.need_subscription) return res
      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.$bvModal.hide('upgrade-plan-dialog')
        EventBus.$emit('reload-billing-history')
      }
    },

    async upgradeCallback(data) {
      console.log('data ->', JSON.stringify(data))

      const res = await proxy
        .post(`${billingBaseUrl}paddle/update_from_callback`, data)
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
            })
            return true
          }
        })
        .catch((err) => {
          console.error('upgrade callback Error', err)
          return false
        })
      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.$bvModal.hide('upgrade-plan-dialog')
        EventBus.$emit('reload-billing-history')
        if (
          [
            'upgrade_subscription',
            'trial_expired',
            'subscription_expired',
          ].indexOf(this.$route.name) >= 0
        ) {
          this.$router.push({ name: 'workspaces' })
        }
      }
    },
  },
}
</script>

<style>
#upgrade-plan-confirmation-box {
  z-index: 1500;
}
</style>
