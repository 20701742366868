import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    getAudienceGrowthSeries (audience) {
        for(let i = 1; i<audience.audience_growth.page_fans_by_like.length; i++ ){
          if(audience.audience_growth.page_fans_by_like[i] === 0){
            audience.audience_growth.page_fans_by_like[i] = audience.audience_growth.page_fans_by_like[i - 1]
          }
          if (audience.audience_growth.page_fans_by_unlike[i] === 0){
            audience.audience_growth.page_fans_by_unlike[i] = audience.audience_growth.page_fans_by_unlike[i - 1]
          }
          if (audience.audience_growth.fan_count[i] === 0){
            audience.audience_growth.fan_count[i] = audience.audience_growth.fan_count[i - 1]
          }
        }
      return [
        {
          name: 'Fans By Likes',
          data: audience?.audience_growth?.page_fans_by_like,
          color: '#ffe202'
        },
        {
          name: 'Fans By Unlikes',
          data: audience?.audience_growth?.page_fans_by_unlike,
          color: '#2fe095'
        },
        {
          name: 'Fans Count',
          data: audience?.audience_growth?.fan_count,
          color: '#e02f5f'
        }
      ]
    },
    getPublishingBehaviourSeries (publishing_behaviour) {
      return [
        {
          name: 'Published Texts',
          data: publishing_behaviour?.publishing_behaviour?.text,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Published Photos',
          data: publishing_behaviour?.publishing_behaviour?.photo,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Published Links',
          data: publishing_behaviour?.publishing_behaviour?.link,
          color: '#e02f5f',
          type: 'column'
        },
        {
          name: 'Published Videos',
          data: publishing_behaviour?.publishing_behaviour?.video,
          color: '#6173ff',
          type: 'column'
        },
        {
          name: 'Published Carousel',
          data: publishing_behaviour?.publishing_behaviour?.carousel,
          color: '#61731f',
          type: 'column'
        },
        {
          name: 'Shared posts',
          data: publishing_behaviour?.publishing_behaviour?.share,
          color: '#c9532f',
          type: 'column'
        },
        {
          name: 'Reel posts',
          data: publishing_behaviour?.publishing_behaviour?.reels,
          color: '#c2139f',
          type: 'column'
        },
        {
          name: 'Other posts',
          data: publishing_behaviour?.publishing_behaviour?.others,
          color: '#81532f',
          type: 'column'
        },
        {
          name: 'Total Engagements',
          data: publishing_behaviour?.publishing_behaviour?.engagement,
          color: '#000000',
          type: 'spline'
        }
      ]
    },
    getActiveUsersSeries (active_users, period) {
      if (period === 'days') {
        const data = active_users?.active_users_days?.map(element => {
          return element.active_users;
        });
        return [
          {
            name: 'Active Users By Days',
            data,
            color: '#2fe095'
          }
        ]
      }
      if (period === 'hours') {
        return [
          {
            name: 'Active Users By Hours',
            data: active_users?.active_users_hours?.values,
            color: '#6173ff'
          }
        ]
      }
    },
    getActiveUsersWeekDaysSeries (active_users, period){
      if (period === 'days') {
        return active_users?.active_users_days?.map(element => {
              return element.day_of_week;
            })
      }
    },

    getImpressionsSeries (impressions) {
      return [
        {
          name: 'Paid Post Impressions',
          data: impressions?.impressions?.post_impressions_paid,
          color: '#2fe095'
        },
        {
          name: 'Viral Post Impressions',
          data: impressions?.impressions?.post_impressions_viral,
          color: '#ffe202'
        },
        {
          name: 'Organic Post Impressions',
          data: impressions?.impressions?.post_impressions_organic,
          color: '#e02f5f'
        }
      ]
    },

    getReachSeries(reach) {
      return [
        {
          name: 'Paid Post Reach',
          data: reach?.reach?.post_reach_paid,
          color: '#2fe095',
        },
        {
          name: 'Viral Post Reach',
          data: reach?.reach?.post_reach_viral,
          color: '#6173fc',
        },
        {
          name: 'Organic Post Reach',
          data: reach?.reach?.post_reach_organic,
          color: '#ffe202',
        },
      ]
    },

    getReelsSeries(reels, selectedMetric){
      if (selectedMetric === "Initial Plays"){
        return [
          {
            name: 'Initial Plays',
            data: reels?.reels?.initial_plays,
            color: '#2fe095',
          },
        ]
      }
      else if (selectedMetric === 'Watch Time (sec)') {
        return [
          {
            name: 'Total Watch Time (sec)',
            data: reels?.reels?.total_seconds_watched,
            color: '#6173fc',
          },
        ]
      } else if (selectedMetric === 'Engagement') {
        return [
          {
            name: 'Total Engagement',
            data: reels?.reels?.engagement,
            color: '#ffe202',
          },
        ]
      }

    },
    getReelsRollupTooltips(value){
      if(value === 'Initial Plays'){
        return 'See how many times your reels, posted within the selected time period, were played for more than 1 ms.'
      }
      else if (value === 'Total Time Watched (sec)') {
        return 'See the cumulative watch time of your reels, posted within the selected time period.'
      }
      else if (value === 'Avg. Time Watched (sec)') {
        return 'See the average watch time per play of your reels, posted within the selected time period.'
      }
      else if (value === 'Engagement') {
        return 'See how the Facebook community engaged with your reels, posted within the selected time period.'
      }
      else if (value === 'Reach') {
        return 'See how many people have seen your reels, posted within the selected time period'
      }
      else if (value === 'Total Reels') {
        return 'See how many reels have been posted within the selected time period.'
      }
    },
    getReelDropdownOptions(){
      return [
        "Initial Plays",
        "Watch Time (sec)",
        "Engagement"
      ]
    },
    getEngagementSeries(engagement) {
      return [
        {
          name: 'Total Engagement',
          data: engagement?.engagement?.total_engagement,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Total Posts',
          data: engagement?.engagement?.post_count,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Reactions',
          data: engagement?.engagement?.reactions,
          color: '#e02f5f',
          type: 'column'
        },
        {
          name: 'Comments',
          data: engagement?.engagement?.comments,
          color: '#6173ff',
          type: 'column'
        },
        {
          name: 'Shares',
          data: engagement?.engagement?.shares,
          color: '#f99350',
          type: 'column'
        },
        {
          name: 'Post Clicks',
          data: engagement?.engagement?.post_clicks,
          color: '#000000',
          type: 'spline'
        }
      ]
    },
    getVideoColors (value) {
      switch (value) {
        case 'engagement':
          return ['#6173fc', '#2fe095', '#ffe202']
        case 'organic':
          return ['#6173fc', '#2fe095']
        case 'play':
          return ['#6173fc', '#2fe095']
      }
    },
    getVideoData (video_analytics, value) {
      switch (value) {
        case 'engagement':
          return [
            [
              'Total Video Play Count',
              video_analytics?.current?.total_video_play_count
            ],
            [
              'Total Video Views',
              video_analytics?.current?.total_video_views
            ],
            [
              'Total Video Engagements',
              video_analytics?.current?.total_engagement
            ]
          ]
        case 'organic':
          return [
            [
              'Paid Video Views',
              video_analytics?.current?.total_video_views_paid
            ],
            [
              'Organic Video Views',
              video_analytics?.current?.total_video_views_organic
            ]
          ]
        case 'play':
          return [
            [
              'Total Videos Clicked To Play',
              video_analytics?.current?.total_video_complete_views_clicked_to_play
            ],
            [
              'Total Videos Autoplayed',
              video_analytics?.current?.total_video_views_autoplayed
            ]
          ]
      }
    }
  }
}
