<template>
  <div class="facebook-analytics-demo fb-demographics-height">
    <section>
      <template v-if="fan_count <= 100">
        <div class="analytics-main-errors-overflow">
          <img
            draggable="false"
            src="../../../assets/imgs/no_data_images/no_demographics_for_less_than_100_fans_2x.png"
            alt=""
          />

          <p> No Demographics for less than 100 fans </p>
        </div>
      </template>

      <h2>Facebook Audience Demographics</h2>
      <p>Review your audience demographics during the reporting period.</p>
      <div class="w-full text-white p-3 bg-[black] mb-2 rounded-md">Meta has deprecated <span class="font-bold">Facebook Audience by Age and Gender</span> data.
        Insights prior to <span class="font-bold">March 14, 2024</span>, are available if your account was connected before that date.</div>
      <template v-if="fans > 100">
        <div class="row">
        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header">
              <h3>Audience by Age</h3>
            </div>
            <template v-if="age_gender_loader">
              <div class="straight-line-chart">
                <div v-for="i in 7" :key="i" class="straight-line-chart__item">
                  <div class="straight-line-chart__header">
                    <SkeletonBox width="80px" radius=".2rem" />
                    <SkeletonBox width="1.1rem" radius=".2rem" />
                  </div>
                  <SkeletonBox width="100%" height="8px" radius="50rem" />
                </div>
              </div>
            </template>
            <template v-else>
              <StraightLineChart
                :values="fans_age.fans_age"
                :max_value="fans_age.max_age"
              />
            </template>
          </div>
        </div>

        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header facebook-analytics-demo__gender">
              <h3>Audience by Gender</h3>
            </div>
            <template v-if="age_gender_loader">
              <div class="h-chart flex-center-center" style="height: 90%">
                <SkeletonBox
                  :innerfill="true"
                  :width="'240px'"
                  :height="'240px'"
                  radius="50%"
                />
              </div>
            </template>
            <template v-else>
              <PieInnerChart
                :chart-ref="`gender-overall-analytics-pie`"
                :colors="['#6173fc', '#2fe095', '#ffe202']"
                :data="[
                  ['Male', fans_gender['M']],
                  ['Female', fans_gender['F']],
                  ['Unspecified', fans_gender['U']],
                ]"
                class="flex-center-center"
                style="flex-direction: column"
                :width="270"
                :height="270"
                @mounted="getPieRef"
              />
              <div class="d-flex justify_center analytics-pie-legends">
                <template
                  v-for="(obj, i) in legends['gender-overall-analytics-pie']"
                  :key="i"
                >
                  <div
                    class="analytics-pie-legends__detail-item"
                    @click="legendClick(obj, obj.name !== 'No Data Found')"
                  >
                    <div class="analytics-pie-legends__detail-label v-metrics">
                      <span
                        :style="{ borderColor: obj.color }"
                        class="analytics-pie-legends__detail-label-icon"
                      ></span>
                      {{ capitalizeFirstLetter(obj.name) }}
                    </div>
                    <div
                      v-if="obj.name !== 'No Data Found'"
                      class="analytics-pie-legends__detail-value"
                    >
                      {{ obj.y }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      </template>

      <div v-if="fan_count > 100 && fans > 100" class="row">
        <div class="col-12">
          <div class="bordered-box bordered-box--margined">
            <div class="bordered-box__header justify_center mb-0">
              <template v-if="age_gender_loader">
                <SkeletonBox width="60%" radius=".2rem" />
              </template>
              <template v-else>
                <h3 class="normal"
                  ><b>{{ gender[max_gender_age.gender] }}</b> between the ages
                  of <b>{{ max_gender_age.age }}</b> appear to be the leading
                  force among your followers.</h3
                >
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header">
              <h3>Audience Top Countries</h3>
            </div>

            <div class="analytics-audience">
              <template v-if="country_city_loader">
                <div v-for="i in 5" :key="i" style="margin-top: 1.3rem">
                  <SkeletonBox :width="'100%'" height="90px" radius="10px" />
                </div>
              </template>
              <template v-else>
                <div
                  v-if="fan_count <= 100 || !Object.keys(audience_country)?.length"
                  class="flex-center-center"
                  style="height: 90%"
                >
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
                <template
                  v-for="(
                    audience_value, audience_key, index
                  ) in audience_country"
                  v-else
                  :key="index"
                >
                  <StatsCard
                    v-if="index < 10"
                    :title="audience_key"
                    :value="audience_value"
                    variant="country"
                  />
                </template>
              </template>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header">
              <h3>Audience Top Cities</h3>
            </div>
            <div class="analytics-audience">
              <template v-if="country_city_loader">
                <div v-for="i in 5" :key="i" style="margin-top: 1.3rem">
                  <SkeletonBox :width="'100%'" height="90px" radius="10px" />
                </div>
              </template>
              <template v-else>
                <div
                  v-if="fan_count <= 100 || !Object.keys(audience_city)?.length"
                  class="flex-center-center"
                  style="height: 90%"
                >
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
                <div v-else>
                  <template
                    v-for="(
                      audience_value, audience_key, index
                    ) in audience_city" :key="index"
                  >
                    <StatsCard
                      v-if="index < 10"
                      :title="audience_key"
                      :value="audience_value"
                      variant="city"
                    />
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StraightLineChart from '@src/modules/analytics/views/common/Infographics/StraightLineChart'
import StatsCard from '@src/modules/analytics/views/common/StatsCard'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'

import { mapActions } from 'vuex'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

export default {
  name: 'DemographicsTab',
  components: {
    StraightLineChart,
    StatsCard,
    SkeletonBox,
    PieInnerChart
  },
  mixins: [analyticsUtilsMixin],
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['account', 'date', 'previous_date', 'getDemographicsLoader'],
  data () {
    return dataValues().facebook.demographics
  },
  computed: {
    getFetchingData () {
      return this.age_gender_loader || this.country_city_loader || this.summary_loader
    }
  },
  watch: {
    account (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    previous_date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    getFetchingData (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getDemographicsLoader(newVal)
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions(['getAnalyzeService']),
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    initialize () {
      this.getSummary()
      this.getFansAgeGender()
      this.getFansCountryCity()
    },
    async getFansAgeGender () {
      if (this.age_gender_loader === true) {
        return
      }
      this.age_gender_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id,
          date: this.date,
          tab: 'demographics',
          section: 'audience_age',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })

        if (response) {
          this.fans_age = response.audience_age
          this.fans_gender = response.audience_gender
          this.max_gender_age = response.max_gender_age
          this.fans = response.fans
        }
      }
      this.age_gender_loader = false
    },
    async getFansCountryCity () {
      if (this.country_city_loader === true) {
        return
      }
      this.country_city_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id,
          date: this.date,
          tab: 'demographics',
          section: 'country_city',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
        if (response) {
          this.audience_country = response.audience_country
          this.audience_country = this.sortList(this.audience_country)
          this.audience_city = response.audience_city
          this.audience_city = this.sortList(this.audience_city)
        }
      }
      this.country_city_loader = false
    },
    async getSummary() {
      if (this.summary_loader === true) {
        return
      }
      this.summary_loader = true
      if ('facebook_id' in this.account) {
        const response = await this.getAnalyzeService({
          facebook_id: this.account.facebook_id ? this.account.facebook_id : '',
          date: this.date,
          section: 'summary',
          tab: 'overview',
          type: 'facebook',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id,
        })
        if ('current' in response.overview) {
          this.fan_count = response.overview?.current?.fan_count || 0;
        }
      }
      this.summary_loader = false
    }
  },

}
</script>
