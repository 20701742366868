<script setup>
import VideoIntro from '@src/modules/account/views/onboarding/VideoIntro.vue'
import { onMounted, onUnmounted, inject, ref } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import CstButton from "@ui/Button/CstButton.vue";

const root = inject('root')

const { $bvModal } = root

const videoID = ref('')

onMounted(() => {
  EventBus.$on('show-yt-video-modal', (sourceID) => {
    videoID.value = sourceID
    $bvModal.show('yt-video-modal')
  })
})

onUnmounted(() => {
  EventBus.$off('show-yt-video-modal')
})
</script>

<template>
  <b-modal
    id="yt-video-modal"
    body-class="!p-0"
    dialog-class="xl:!max-w-[80rem]"
    content-class="!rounded-2xl"
    size="xl"
    centered
    hide-header
    hide-footer
  >
    <div class="bg-gray-50 lg:!bg-transparent lg:h-[41rem] rounded-2xl">
      <VideoIntro :video-id="videoID" />
    </div>
    <div class="flex justify-center align-items-center h-[5rem]">
      <CstButton
          size="default"
          variant="outlined"
          text="Close"
          class="
          h-[2.2rem]
          flex
          align-items-center
          !rounded-lg
          !border-primary-cs
        "
          @click="$bvModal.hide('yt-video-modal')"
      />
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.modal_body {
  padding: 0 !important;
}
</style>
