<template>
  <div id="notificationCarousel">
    <div
      v-if="isExpiredAccount"
      class="relative flex items-center w-max mx-auto bg-[#ffd372] font-medium rounded-xl my-2 px-4 py-2"
    >
      <a
        :class="{ hide: getImportantNotifications.expiring_soon.length === 0 }"
        class="my-0 mx-1.5"
        role="button"
        @click="getSliderData(false)"
      >
        <i class="cs-angle-right"> </i>
      </a>

      Access token for

      <template v-if="getImportantNotifications?.expired?.length">
        <b-dropdown
          class="mx-1"
          menu-class="max-h-60 overflow-y-auto"
          no-caret
          :popper-triggers="['hover']"
          toggle-class="bg-transparent text-blue-400 hover:text-blue-400  border-0 focus:outline-none p-0"
          variant="primary"
        >
          <template v-slot:button-content>
            {{ getImportantNotifications.expired[0]?.name }}
            {{ getImportantNotifications.expired.length > 1 ? ' and ' + (getImportantNotifications.expired.length - 1) + ' others' : '' }}
          </template>

          <template
            v-for="(item, index) in getImportantNotifications.expired"
            :key="index"
          >
            <b-dropdown-item class="p-0 m-0 pointer-events-none">
              <span class="relative inline-block">
                <img
                  :src="item.image"
                  alt=""
                  class="rounded-full object-cover border pointer-events-none w-9 h-9"
                  @error="
                    $event.target.src =
                      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                />
                <CstSocialIcon
                  :icon="item.type"
                  class="rounded-full w-4 h-4 text-white block absolute -right-0.5 -bottom-0.5 flex justify-center items-center text-lg"
                />
              </span>
              {{ item.name }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
      has expired, please refresh your token for uninterrupted service.
      <router-link
        class="mx-2 bg-[#ff9300] rounded-lg font-semibold cursor-pointer text-white px-3 py-2"
        :to="{ name: 'social' }"
      >
        Proceed
      </router-link>
      <button
        type="button"
        class="close close_slider ml-2"
        @click="SET_IMPORTANT_NOTIFICATIONS_VISIBILITY(false)"
        >&times;
      </button>
    </div>
    <div
      v-if="isExpiringAccount"
      class="relative flex items-center w-max mx-auto bg-[#ffd372] font-medium rounded-xl my-2 px-4 py-2"
    >
      <a
        :class="{ hide: getImportantNotifications.expired.length === 0 }"
        class="my-0 mx-1.5"
        role="button"
        @click="getSliderData(true)"
      >
        <i class="cs-angle-left"> </i>
      </a>
      Access token for
      <span
        v-if="getImportantNotifications.expiring_soon.length === 1"
        class="mx-2"
      >
        '{{ getImportantNotifications.expiring_soon[0].name }}' is expiring in
        {{ getImportantNotifications.expiring_soon[0].days_remaining }} day(s),
      </span>
      <template v-if="getImportantNotifications.expiring_soon.length > 1">
        <b-dropdown
          class="mx-1"
          menu-class="max-h-60 overflow-y-auto"
          no-caret
          :popper-triggers="['hover']"
          toggle-class="bg-transparent text-blue-400 hover:text-blue-400  border-0 focus:outline-none p-0"
          variant="primary"
        >
          <template v-slot:button-content>
            {{ getImportantNotifications.expiring_soon[0].name }} and
            {{ getImportantNotifications.expiring_soon.length - 1 }} others
          </template>

          <template
            v-for="(item, index) in getImportantNotifications.expiring_soon"
            :key="index"
          >
            <b-dropdown-item class="p-0 m-0 pointer-events-none">
              <span class="relative inline-block">
                <img
                  :src="item.image"
                  alt=""
                  class="rounded-full object-cover border pointer-events-none w-9 h-9"
                  @error="
                    $event.target.src =
                      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                />
                <CstSocialIcon
                  :icon="item.type"
                  class="rounded-full w-4 h-4 text-white block absolute -right-0.5 -bottom-0.5 flex justify-center items-center text-lg"
                />
              </span>
              {{ item.name }}

              <span class="text-sm">({{ item.days_remaining }} days)</span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
        are expiring soon,
      </template>
      please refresh your token for uninterrupted service.
      <router-link
        class="mx-2 bg-[#ff9300] rounded-lg font-semibold cursor-pointer text-white px-3 py-2"
        :to="{ name: 'social' }"
      >
        Proceed
      </router-link>
      <button
        type="button"
        class="close close_slider ml-2"
        @click="SET_IMPORTANT_NOTIFICATIONS_VISIBILITY(false)"
        >&times;
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CstSocialIcon from '@ui/SocialIcon/CstSocialIcon'

export default {
  components: {
    CstSocialIcon,
  },
  data() {
    return {
      toggle: true,
    }
  },
  computed: {
    ...mapGetters([
      'getImportantNotifications',
      'getImportantNotificationsVisibility',
    ]),
    isExpiredAccount() {
      return (
        this.toggle &&
        this.getImportantNotificationsVisibility &&
        this.getImportantNotifications.expired?.length > 0
      )
    },
    isExpiringAccount() {
      return (
        !this.toggle &&
        this.getImportantNotificationsVisibility &&
        this.getImportantNotifications.expiring_soon?.length > 0
      )
    },
  },
  mounted() {
    if (
      this.getImportantNotifications.expired &&
      this.getImportantNotifications.expired.length === 0
    )
      this.toggle = false
  },
  methods: {
    ...mapMutations(['SET_IMPORTANT_NOTIFICATIONS_VISIBILITY']),
    getSliderData(state = true) {
      this.$nextTick(() => {
        this.toggle = state
      })
    },
  },
}
</script>

<style lang="scss"></style>
