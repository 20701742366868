<template>
  <div
    class="w-full flex flex-row overflow-hidden h-[calc(100vh-60px)]"
    :class="{
      'max-h-[calc(100vh-60px)]':
        $route.name.includes('_competitor_overview_v3') ||
        $route.name.includes('_competitor_v3'),
    }"
  >
    <aside
      class="!max-w-[15rem] w-full overflow-auto bg-white max-h-[calc(100vh - 60px)]"
    >
      <AnalyticsSidebar
        :is-competitor-analytics-enabled="isCompetitorAnalyticsEnabled"
      />
    </aside>
    <main
      class="overflow-y-hidden overflow-x-hidden"
      :class="
        $route.name.includes('_competitor') ? 'flex-1' : 'analytics-wrapper'
      "
    >
      <MainAnalyticsHeader v-if="$route.name.includes('_competitor')" />
      <div
        class="w-full overflow-y-auto"
        :class="{
          'max-h-[calc(100vh-140px)] h-[calc(100vh-140px)]':
            $route.name.includes('_competitor_overview_v3') ||
            $route.name.includes('_competitor_v3'),
        }"
      >
        <router-view
          :key="$route.params.reportId"
          :is-competitor-analytics-enabled="isCompetitorAnalyticsEnabled"
        />
      </div>
    </main>
    <ManageCompetitorsModal />
    <SendReportByEmailModal></SendReportByEmailModal>
    <ScheduleReportModal></ScheduleReportModal>
    <AnalyticPreview />
  </div>
</template>

<script setup>
// libraries
import { computed, onMounted, onBeforeUnmount } from 'vue'

// components
import AnalyticsSidebar from '@src/modules/analytics_v3/components/AnalyticsSidebar.vue'
import MainAnalyticsHeader from '@src/modules/analytics_v3/components/MainAnalyticsHeader.vue'
import ManageCompetitorsModal from '@src/modules/analytics_v3/components/ManageCompetitorsModal.vue'
import SendReportByEmailModal from '@src/modules/analytics/components/reports/modals/SendReportByEmailModal.vue'
import ScheduleReportModal from '@src/modules/analytics/components/reports/modals/ScheduleReportModal.vue'
import AnalyticPreview from '@src/modules/analytics/components/common/AnalyticPreview.vue'

// constants
import { useStore } from '@state/base'

// functionality

const { getters } = useStore()

const isCompetitorAnalyticsEnabled = computed(
  () => getters.getPlan.subscription?.default_add_ons?.competitor_analytics
)

onBeforeUnmount(() => {
  localStorage.removeItem('defaultCompetitorRoute')
})

onMounted(() => {
  document.body.style.overflow = 'hidden'
})

onBeforeUnmount(() => {
  document.body.style.overflow = 'revert'
})
</script>
