<template>
  <div>
    <div
      v-if="
        detail.video &&
        detail.video.thumbnails_suggestions &&
        youtubePostType === 'short'
      "
      :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
    >
      <div class="relative text-white">
        <!--   background   -->
        <div class="ratio ratio-9x16 !bg-[#020B12]">
          <img
            class="w-full object-contain ratio ratio-9x16 rounded-b-lg"
            :src="
              detail.video.thumbnails_suggestions[0] || detail.video.thumbnail
            "
            alt=""
          />
        </div>
         <!-- playback icon -->
     <div
     class="absolute top-0 left-0 h-full w-full cursor-pointer z-10"
     @click.prevent="
     displayFile('video', detail.video.converted_video || detail.video.link, 0)
   ">
      <i class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"></i>
     </div>
        <!--   footer   -->
        <div class="absolute bottom-px left-0 w-full p-3 text-white">
          <div class="flex space-x-4">
            <div class="min-w-0 w-full flex mx-2 flex-col justify-end">
              <div
                v-if="videoTitle"
                class="pb-3 text-base font-medium pointer-events-none truncate"
              >
                <span v-html="videoTitle"></span>
              </div>
              <div class="flex items-center font-bold">
                <img
                  :src="account.platform_logo"
                  alt=""
                  class="rounded-full object-cover border border-solid border-black"
                  width="35"
                  height="35"
                  @error="
                    $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                  "
                />
                <div class="px-2 font-bold select-none">
                  {{ account.platform_name }}
                  <!---->
                </div>
                <span class="ml-auto mr-2 youtube_bg rounded py-3 px-2"
                  >SUBSCRIBE</span
                >
              </div>
            </div>
            <div class="ml-auto">
              <div class="flex flex-col justify-center items-center">
                <i class="fas fa-thumbs-up text-2xl w-6 h-6"></i>
                <p class="text-bold-500 text-xs whitespace-nowrap mt-1.5">14</p>
              </div>
              <div class="flex flex-col justify-center items-center mt-3.5">
                <i class="fas fa-thumbs-down text-2xl w-6 h-6"></i>
                <p class="text-bold-500 text-xs whitespace-nowrap mt-1.5"
                  >Dislike</p
                >
              </div>
              <div class="flex flex-col justify-center items-center mt-8">
                <i class="fas fa-share text-2xl w-6 h-6"></i>
                <p class="text-bold-500 text-xs whitespace-nowrap mt-1.5"
                  >Share</p
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full cst-drop-shadow bg-white rounded-xl">
      <div class="flex items-center px-3 py-2">
        <div
          class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-red"
        >
          <img
            src="@assets/img/integration/youtube-icon.svg"
            alt=""
            class="w-5"
          />
        </div>
      </div>

      <video
        v-if="
          detail.video && detail.video.thumbnail && youtubePostType === 'video'
        "
        controls
        :poster="detail.video.thumbnail"
        class="px-2 m-0 w-full"
      >
        <source
          :src="detail.video.converted_video || detail.video.link"
          type="video/mp4"
        />
        <source
          :src="detail.video.converted_video || detail.video.link"
          type="video/ogg"
        />
        Your browser does not support the video tag.
      </video>
      <img
        v-else
        class="w-100 min-h-48"
        src="@src/assets/img/no_data_images/no_media_found.svg"
        alt=""
      />
      <template v-if="youtubePostType === 'video'">
        <div class="flex items-center px-2 py-1 w-100 text-lg text-black">
          {{ videoTitle }}
        </div>
        <div
          v-if="type === 'composer'"
          class="flex px-2 items-center justify-items-center w-100 font-normal text-xs text-gray-800"
        >
          <div class="flex ml-auto">
            <div class="flex items-center font-bold text-sm mx-1">
              <svg
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
                class="w-5 mx-1 style-scope yt-icon"
              >
                <g class="style-scope yt-icon">
                  <path
                    d="M18.77,11h-4.23l1.52-4.94C16.38,5.03,15.54,4,14.38,4c-0.58,0-1.14,0.24-1.52,0.65L7,11H3v10h4h1h9.43 c1.06,0,1.98-0.67,2.19-1.61l1.34-6C21.23,12.15,20.18,11,18.77,11z M7,20H4v-8h3V20z M19.98,13.17l-1.34,6 C18.54,19.65,18.03,20,17.43,20H8v-8.61l5.6-6.06C13.79,5.12,14.08,5,14.38,5c0.26,0,0.5,0.11,0.63,0.3 c0.07,0.1,0.15,0.26,0.09,0.47l-1.52,4.94L13.18,12h1.35h4.23c0.41,0,0.8,0.17,1.03,0.46C19.92,12.61,20.05,12.86,19.98,13.17z"
                    class="style-scope yt-icon"
                  ></path>
                </g>
              </svg>
              LIKE
            </div>
            <div class="flex items-center font-bold text-sm mx-1">
              <svg
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
                class="w-5 mx-1 style-scope yt-icon"
              >
                <g class="style-scope yt-icon">
                  <path
                    d="M17,4h-1H6.57C5.5,4,4.59,4.67,4.38,5.61l-1.34,6C2.77,12.85,3.82,14,5.23,14h4.23l-1.52,4.94C7.62,19.97,8.46,21,9.62,21 c0.58,0,1.14-0.24,1.52-0.65L17,14h4V4H17z M10.4,19.67C10.21,19.88,9.92,20,9.62,20c-0.26,0-0.5-0.11-0.63-0.3 c-0.07-0.1-0.15-0.26-0.09-0.47l1.52-4.94l0.4-1.29H9.46H5.23c-0.41,0-0.8-0.17-1.03-0.46c-0.12-0.15-0.25-0.4-0.18-0.72l1.34-6 C5.46,5.35,5.97,5,6.57,5H16v8.61L10.4,19.67z M20,13h-3V5h3V13z"
                    class="style-scope yt-icon"
                  ></path>
                </g>
              </svg>
              DISLIKE
            </div>
            <div class="flex items-center font-bold text-sm mx-1">
              <svg
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
                class="w-5 mx-1 style-scope yt-icon"
              >
                <g class="style-scope yt-icon">
                  <path
                    d="M15,5.63L20.66,12L15,18.37V15v-1h-1c-3.96,0-7.14,1-9.75,3.09c1.84-4.07,5.11-6.4,9.89-7.1L15,9.86V9V5.63 M14,3v6 C6.22,10.13,3.11,15.33,2,21c2.78-3.97,6.44-6,12-6v6l8-9L14,3L14,3z"
                    class="style-scope yt-icon"
                  ></path>
                </g>
              </svg>
              SHARE
            </div>
          </div>
        </div>
        <div
          class="flex px-2 py-1 my-2 mx-2"
          style="border-top: 1px solid rgba(0, 0, 0, 0.1)"
        >
          <img
            class="rounded-full object-cover border border-solid border-black"
            width="40"
            height="40"
            :src="account.platform_logo"
            alt=""
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            "
          />
          <div class="px-2 font-bold">
            {{ account.platform_name }}
            <span
              class="block font-normal text-sm whitespace-pre-wrap"
              v-html="getDescription"
            ></span>
            <span
              v-if="getDescription.length > charLimit && loadMore"
              class="text-blue-300 cursor-pointer"
              @click="changeLoadMore(false)"
            >
              Load More</span
            >
            <span
              v-if="getDescription.length > charLimit && !loadMore"
              class="text-blue-300 cursor-pointer"
              @click="changeLoadMore(true)"
            >
              Show Less</span
            >
          </div>
        </div>
        <div
            v-if="commentDetails?.first_comment !== undefined || comment"
          class="flex justify-center px-2 py-3"
          style="border-top: 1px solid #dee2e6"
        >
          <div>
            <img
              class="rounded-full object-cover border border-solid border-black"
              width="30"
              height="30"
              :src="account.platform_logo"
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
          </div>
          <div class="ml-1 w-full rounded-lg px-2">
            <span class="font-bold">{{ account.platform_name }} </span>
            {{ comment }}
          </div>
        </div>
        <div v-if="commentDetails?.first_comment !== undefined" class="comments-status-box">
          <p v-if="commentDetails?.first_comment?.status === true" class="ml-2">
            <span class="status published"></span>
            <span class="text">Published</span>
            <span> | [First comment has been posted successfully.]</span>
          </p>
          <p v-else-if="!commentDetails?.first_comment?.status" class="ml-2">
            <span class="status failed"></span>
            <span class="text">Failed</span>
            <span> | {{commentDetails?.first_comment?.message}}</span>
          </p>

        </div>

      </template>
    </div>
  </div>
</template>

<script>
import { parseDescriptionHtml } from '@common/lib/helper'
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'YoutubePreview',
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    comment: {
      type: String,
      default: '',
      required: false,
    },
    videoTitle: {
      type: String,
      default: '',
      required: true,
    },
    youtubePostType: {
      type: String,
      default: 'video',
    },
    type: {
      type: String,
      default: 'composer',
    },
    commentDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  data() {
    return {
      loadMore: true,
      charLimit: 500,
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
  },
  methods: {
    changeLoadMore(val) {
      this.loadMore = val
    },
    /**
     * Previewing/playing video file via event bus.
     * @param type
     * @param media
     * @param index
     */
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
  },
}
</script>
