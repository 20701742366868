<template>
  <ul
    id="accordion"
    class="feeds-left-pane--feed-groups"
    :class="{ disable_click: getTopicDisabled }"
  >
    <li class="feeds-left-pane--feed-groups__heading">
      <i class="title_icon icon-feeds_icon"></i>
      <span class="feeds-left-pane--feed-groups__heading__text">Feeds</span>
      <span
        v-tooltip.top="'Create New Group'"
        class="btn add_icon feeds-left-pane--feed-groups__heading__button"
        @click="feedGroupModal"
      >
        <i class="far fa-plus d-block"></i>
      </span>
    </li>

    <template v-if="getFeederGroupList.length > 0">
      <li
        v-for="(feeder, index) in getSortedFeederGroupList"
        :key="feeder.uuid"
        class="feeds-left-pane--feed-groups__list-items"
        :class="{
          feed_removal:
            feeder.hasOwnProperty('state') && feeder.state === 'removing',
        }"
      >
        <span
          class="d-block feeds-left-pane--feed-groups__parent"
          style="position: relative"
          :class="{
            'active animate': $route.params.collection_id === feeder.uuid,
          }"
        >
          <span
            class="feeds-left-pane--feed-groups__collapse_icon"
            data-toggle="collapse"
            :href="'#_' + feeder.uuid"
          >
            <!--<i style="font-size: 13px;" class="edit_icon far fa-angle-right" v-if="$route.params.collection_id === feeder.uuid"></i>-->
            <i
              v-if="getSortedFeederGroupList"
              style="font-size: 13px"
              class="edit_icon far fa-angle-right"
            ></i>
          </span>
          <router-link
            class="feeds-left-pane--feed-groups__group_name d-block"
            :to="{
              name: 'feeder_collection',
              params: { collection_id: feeder.uuid },
            }"
          >
            <span>
              <!-- :class="{'feeder_feed_title': $route.params.collection_id === feeder.uuid}"-->
              {{ limitTextLength(feeder.name, 22) }}
            </span>
          </router-link>

          <span
            v-if="feeder.total_posts > 0"
            class="feeds-left-pane--feed-groups__total_statistics"
          >
            <span class="total_no_of_posts">
              {{ intToString(feeder.total_posts) }}
            </span>
          </span>
          <span
            class="feeds-left-pane--feed-groups__action_buttons d-flex align-items-center"
          >
            <i
              v-tooltip.top="'Edit'"
              class="icon-edit-cs feeds-left-pane--feed-groups__action_buttons__edit"
              @click.prevent="editFeedGroupModal(feeder)"
            >
            </i>
            <span class="dropdown dropdown-confirmation">
              <i
                :id="'dropdown_' + feeder._id"
                v-tooltip.top="'Remove'"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="icon-delete-cs feeds-left-pane--feed-groups__action_buttons__remove"
              ></i>
              <div
                aria-labelledby="'dropdown_'+feeder._id"
                class="dropdown-menu dropdown-confirmation__menu"
              >
                <div class="dropdown-confirmation__menu__header">
                  Confirmation
                </div>
                <div class="dropdown-confirmation__menu__actions">
                  Are you sure?
                  <span
                    class="dropdown-confirmation__menu__actions__delete"
                    @click.prevent="deleteFeederGroupAction(feeder)"
                    >Yes</span
                  >
                  <span class="dropdown-confirmation__menu__actions__cancel"
                    >No</span
                  >
                </div>
              </div>
            </span>
          </span>
        </span>

        <ul
          :id="'_' + feeder.uuid"
          class="feeds-sublist__items collapse"
          data-parent="#accordion"
        >
          <li
            v-for="user_followed_feed in sortUserFeedDetails(
              feeder.user_feed_details,
              feeder,
            )"
            :class="{
              feed_removal:
                user_followed_feed.hasOwnProperty('state') &&
                user_followed_feed.state === 'removing',
            }"
          >
            <router-link
              :to="{
                name: 'feeder_feed_view',
                params: {
                  collection_id: feeder.uuid,
                  feed_id: user_followed_feed.uuid,
                },
              }"
            >
              <!-- edit icon and saving the name of the feed -->
              <template
                v-if="
                  user_followed_feed.hasOwnProperty('edit_feed_input') &&
                  user_followed_feed.edit_feed_input
                "
              >
                <span class="d-inline-block feeder-input-edit">
                  <input
                    v-model="user_followed_feed.title"
                    class=""
                    type="text"
                    @keydown.enter="
                      feederEditIndividualFeedStore(user_followed_feed)
                    "
                  />
                  <template
                    v-if="
                      user_followed_feed.hasOwnProperty(
                        'edit_feed_name_store_loader',
                      ) && user_followed_feed.edit_feed_name_store_loader
                    "
                  >
                    <clip-loader color="#436aff" :size="'13px'"></clip-loader>
                  </template>
                </span>

                <!-- close edit input icon -->

                <div
                  class="t_icon d-flex align-items-center"
                  style="width: 20px; height: 20px"
                >
                  <i
                    v-tooltip.top="'Cancel'"
                    style="font-size: 13px"
                    class="edit_icon far fa-times"
                    @click="feederEditIndividualFeed(user_followed_feed, false)"
                  ></i>
                </div>
              </template>
              <template v-else>
                <!-- displaying the feed details -->
                <div class="feeds-sublist__items__image_logo d-inline-block">
                  <img
                    v-if="user_followed_feed.logo"
                    class="d-inline-block mr-2"
                    :src="feedLogoURL(user_followed_feed)"
                    alt=""
                  />
                  <template v-else>
                    <i class="far fa-rss"></i>
                  </template>
                </div>
                <span
                  class="feeds-sublist__items__text inner_link_text d-inline-block"
                  >{{ limitTextLength(user_followed_feed.title, 16) }}</span
                >

                <div
                  v-if="user_followed_feed.total_posts > 0"
                  class="feeds-sublist__items__statistics"
                >
                  <span class="total_no_of_posts">
                    {{ intToString(user_followed_feed.total_posts) }}
                  </span>
                </div>
                <div
                  class="feeds-sublist__items__action_buttons t_icon d-flex align-items-center"
                >
                  <!--<i @click="feederEditIndividualFeed(user_followed_feed, true)"-->
                  <!--style="font-size: 13px;"-->
                  <!--v-tooltip.top="'Edit'" class="edit_icon icon-edit-cs"></i>-->

                  <i
                    v-tooltip.top="'Remove'"
                    style="font-size: 13px"
                    class="far icon-delete-cs feeds-sublist__items__action_buttons__remove"
                    @click.prevent="
                      feederRemoveIndividualFeed(feeder, user_followed_feed)
                    "
                  ></i>
                </div>
              </template>
            </router-link>
          </li>
          <li
            v-if="
              feeder.user_feed_details && feeder.user_feed_details.length === 0
            "
            class="feeds-sublist-extended-items feeds-sublist-extended-items-without-underline"
          >
            You have not followed any feed yet.
          </li>
          <!--
          conditionally showing the list of feeds

          TODO: we'll be showing the no.of feeds based on the articles read no. later on.
          -->
          <li
            v-if="
              feeder.show_more &&
              feeder.user_feed_details &&
              feeder.user_feed_details.length > 6
            "
            class="feeds-sublist-extended-items"
            @click="feederToggleMore(feeder, false)"
          >
            Show less
          </li>
          <li
            v-else-if="feeder.user_feed_details.length > 6"
            class="feeds-sublist-extended-items"
            @click="feederToggleMore(feeder, true)"
          >
            Show {{ feeder.user_feed_details.length - 6 }} more sources
          </li>
        </ul>
      </li>
      <hr v-if="import_state === 'started'" />
      <div v-if="import_state === 'started'" class="import_opml_started">
        <span>
          Importing new sources
          <span>
            <clip-loader :size="'11px'" :color="'#989eb5'"></clip-loader>
          </span>
        </span>
      </div>
      <!--:class="{selected_disable: !sibarItemStatus('quickTopics',topic.name),disable_box : getSidebarCustomization}"-->
    </template>

    <template v-else>
      <div class="feeds-create-group">
        <img :src="create_feed_group_image" alt="" />
        <div class="feeds-create-group__content">
          <div class="feeds-create-group__content__left">
            <p> Keep up-to date with your favorite sources. </p>
          </div>
          <div class="feeds-create-group__content__right">
            <b-button variant="studio-theme" @click="feedGroupModal"
              >+</b-button
            >
          </div>
        </div>
      </div>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'
import { pusherSocket } from '@common/lib/pusher'
import CreateFeedGroupIcon from '@src/assets/img/modules/feeds/create-feed-group-icon.svg'
import {EventBus} from "@common/lib/event-bus";
import { feederTypes } from '../../../store/mutation-types'

export default {
  computed: {
    ...mapGetters([
      'getTopicDisabled',
      'getSidebarCustomization',
      'getFeederGroupList',
      'getFeederGroupStore',
      'getFeederGroupValidations',
      'getCustomSidebar',
      'getActiveTopicTab',
      'getActiveChannelRoute',
      'getActiveWorkspace'
    ]),
    getSortedFeederGroupList () {
      return sortBy(this.getFeederGroupList, ['name'], ['asc'])
    }
  },
  watch: {
    'getActiveWorkspace._id' (value) {
      if (this.channel) this.channel.unsubscribe()
      this.channel = pusherSocket.subscribe(`opml_${value}`)
      this.$store.commit(feederTypes.SET_GROUP_ITEMS, [])
      this.bindFeederPushOPML()
      this.import_state = ''
    }
  },
  created () {},
  mounted () {
    console.log('bind opml state')
    this.channel = pusherSocket.subscribe(`opml_${this.getActiveWorkspace._id}`)
    this.bindFeederPushOPML()
  },
  data () {
    return {
      feederEditStatus: false,
      import_state: '',
      channel: null,
      create_feed_group_image: CreateFeedGroupIcon
    }
  },
  beforeUnmount () {
    console.log('unbind opml state')
    this.channel.unbind('opml_state')
    this.channel.unsubscribe()
  },
  methods: {
    bindFeederPushOPML () {
      this.channel.bind('opml_state', async (data) => {
        console.log(data)
        this.import_state = data.state
        if (data.state === 'completed') {
          await this.$store.dispatch('fetchFeederGroups')
          await this.$store.dispatch('fetchFeederStatistics')
        }
      })
    },
    /**
     *  Reset the states value and show the modal
     **/
    feedGroupModal () {
      this.$set(this.getFeederGroupStore, 'name', null)
      this.$set(this.getFeederGroupStore, '_id', null)
      this.$set(this.getFeederGroupValidations.fields, 'name', false)
      this.$store.commit(feederTypes.RESET_CREATE_GROUP_STATE)
      this.$bvModal.show('add_feeder_group')
    },
    /**
     * Set the state data and show the dialog.
     * @param feeder
     */
    editFeedGroupModal (feeder) {
      this.$store.commit(feederTypes.SET_GROUP_STORE_ITEM, feeder)
      this.$bvModal.show('add_feeder_group')
    },
    preventCollapse (e) {
      e.stopPropagation()
    },
    feederEditIndividualFeed (userFollowedFeed, isInputEnabled = true) {
      // show the edit feed input

      this.$set(userFollowedFeed, 'edit_feed_input', isInputEnabled)
      // if it is cross, set the edit name field.

      if (isInputEnabled === false) {
        // restore the original feed name
        this.$set(
          userFollowedFeed,
          'title',
          JSON.parse(JSON.stringify(userFollowedFeed)).edit_name_value
        )
      } else {
        // on the first click when edits it, we are storing the original name to the edit_name_value field
        this.$set(
          userFollowedFeed,
          'edit_name_value',
          JSON.parse(JSON.stringify(userFollowedFeed)).title
        )
      }
    },
    async feederEditIndividualFeedStore (userFollowedFeed) {
      console.log(userFollowedFeed)
      this.$set(userFollowedFeed, 'edit_feed_name_store_loader', true)
      const res = await this.$store.dispatch('feederStoreUserFeedName', {
        uuid: userFollowedFeed.uuid,
        title: userFollowedFeed.title
      })
      // hide the loaders and input values
      this.$set(userFollowedFeed, 'edit_feed_input', false)
      this.$set(userFollowedFeed, 'edit_feed_name_store_loader', false)
    },
    async deleteFeederGroupAction (group) {
      // added to use the opacity option while removing the item and disable the group for multiple clicks.

      this.$set(group, 'state', 'removing')

      // for the edge casem prepare the feed ids list.

      let feedIdsList = []
      if (group.user_feed_details && group.user_feed_details.length) {
        feedIdsList = group.user_feed_details.map((feed) => feed.feed_id)
      }

      // send the reuqest to remove the group

      const res = await this.$store.dispatch('deleteFeederGroup', group._id)
      // if the group removed, emit an event to remove the already followed feed ids list.

      if (res.data && res.data.status) {
        EventBus.$emit('remove_group_feeds_from_followed_list', feedIdsList)
      }
      // set the state to empty.

      this.$set(group, 'state', '')

      // edge case if the length of the group is set to 0.
      if (this.getFeederGroupList.length === 0) {
        this.$router.push({ name: 'feeder_follow_sources' })
      }

      // edge case, if removes the collection redirect to the today page.
      if (this.$route.params.collection_id) {
        if (this.$route.params.collection_id === group.uuid) {
          this.$router.push({
            name: 'feeder_aggregate_view',
            params: { content_type: 'today' }
          })
        }
      }
    },
    /**
     * Showing only the top feeds, later on we will be showing the feeds that have the posts available.
     * Currently, it is based on the group feeds length.
     * @param feedsList
     * @param feeder
     * @returns {*}
     */
    sortUserFeedDetails (feedsList, feeder) {
      if (feeder.show_more) {
        return sortBy(feedsList, ['title'], ['asc'])
      } else {
        if (feedsList && feedsList.length > 6) {
          return feedsList.slice(0, 6)
        } else {
          return sortBy(feedsList, ['title'], ['asc'])
        }
      }
    },
    feederToggleMore (feeder, value) {
      this.$set(feeder, 'show_more', value)
    },
    async feederRemoveIndividualFeed (group, followedFeed) {
      this.$set(followedFeed, 'state', 'removing')
      const followedFeedId = followedFeed.uuid
      const res = await this.$store.dispatch('feederUnfollowIndividualFeed', {
        group_ids: [group.uuid],
        feed_id: followedFeed.feed_id
      })
      this.$set(followedFeed, 'state', '')
      if (res.data && res.data.status) {
        EventBus.$emit('remove_from_followed_list', followedFeed.feed_id)
        group.user_feed_details.splice(
          group.user_feed_details.findIndex(
            (feed) => feed.feed_id === followedFeed.feed_id
          ),
          1
        )
        group.total_posts -= followedFeed.total_posts
      }

      // edge case, if feed removed on which currently residing, redirect to the collection page.
      if (this.$route.params.feed_id) {
        console.log(
          '[feeder individual feed] -> ',
          followedFeedId,
          this.$route.params.feed_id
        )
        if (this.$route.params.feed_id === followedFeedId) {
          this.$router.push({
            name: 'feeder_collection',
            params: { collection_id: group.uuid }
          })
        }
      }
    },
    feedLogoURL(feed) {
      if (feed && feed.domain) {
        return `https://favicon.cstuinternal.com/?url=${feed.domain}&size=16`
      }
      return feed.logo
    },
  }
}
</script>
