<template>
  <div>
    <div
        id="g_id_onload"
        :data-client_id="GOOGLE_CLIENT_ID"
        data-context="signup"
        data-ux_mode="redirect"
        :data-login_uri="GOOGLE_LOGIN_URI"
        data-nonce=""
        data-auto_prompt="false">
    </div>
    <div
        class="g_id_signin"
        data-type="icon"
        data-shape="square"
        data-text="signin_with"
        data-size="large"
    >
    </div>
  </div>
</template>
<script setup>
  import { onMounted } from 'vue'

  const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_SSO_CLIENT_ID
  const GOOGLE_LOGIN_URI = `${process.env.VUE_APP_CS_BACKEND_URL}google/sso`

  /**
   * Load Google Sign-In script
   */
  const loadGoogleScript = () => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    document.body.appendChild(script)
  }

  onMounted(() => {
    loadGoogleScript()
  })
</script>
