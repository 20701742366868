<script setup>
import CstButton from '@ui/Button/CstButton'
import useSocialAccountModal from '@/src/modules/account/composables/useSocialAccountsModal'

const {OPTIONS, selectedOption} = useSocialAccountModal()

// Open the onboarding tutorial
const handleOnboardingTutorial = () => {
  selectedOption.value = OPTIONS.VIDEO
}

// Book a demo
const handleBookADemo = () => {
  window.open('https://contentstudio.io/book-a-demo', '_blank')
}

</script>

<template>
  <p class="text-[1.2rem] lg:text-[2rem] text-[#3C4549] font-medium">
    Welcome to
    <span
        class="
        text-[1.2rem]
        lg:text-[2rem]
        inline-flex
        align-items-center
        font-bold
        text-[#2961D2]
        gap-2
      "
    >
      ContentStudio
    </span>
  </p>

  <!-- Body -->
  <div>
    <p
        class="
        mb-2
        text-[2.375rem] text-[#3C4549]
        font-extrabold
        lg:max-w-[20rem]
        leading-[3.125rem]
      "
    >
      Connect Your Social Networks To Manage
    </p>
    <p class="text-sm text-[#3C4549] font-normal">
      To get started, please begin by connecting your social accounts.
    </p>
    <div class="flex align-items-center gap-1 mt-[3px]">
      <img class="h-4 w-4" :src="require('@assets/img/onboarding/info.svg')" alt="info"/>
      <p class="text-sm text-[#757A8A] font-normal mt-[1px]">
        Your data is completely secured.
      </p>
    </div>
  </div>

<div class="relative w-50 cursor-pointer"
     v-tooltip="'View Onboarding Tutorial'"
     @click="handleOnboardingTutorial"
>
  <img
      class="h-10 w-10 z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      :src="require('@/src/modules/discovery/assets/youtube_icon.png')"
      alt="youtube"
  />
  <img :src="'https://i.ytimg.com/vi_webp/uAuiDkRGYhI/maxresdefault.webp'"
       alt="onboard"
       class="my-4 w-full rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:scale-105"
  />
</div>
  <!-- Buttons -->
  <div class="flex flex-col gap-3 buttons mt-2">
    <CstButton
        variant="primary"
        class="
        !bg-[#202324]
        !text-white
        !border-black-500 !border-2
        !rounded-lg
        !text-xs
        lg:!text-sm
        !w-[12rem]
        font-semibold
      "
        @click="handleBookADemo"
    >
      <div class="flex gap-[0.625rem]">
        <img
            class="h-5 w-5"
            :src="require('@assets/img/onboarding/calendar.svg')"
            alt="calendar"
        />
        <p class="mt-[1px]"> Book a demo </p>
      </div>
    </CstButton>
  </div>
</template>

<style lang="scss" scoped>
.buttons {
  button {
    width: fit-content;
  }
}
</style>