<template>
  <div class="analytics-performance-box">
    <div class="analytics-performance-box__header">
      <div>
        <h3>{{ account.name ?? account.platform_name }}</h3>
        <p>{{ getProfileType(account.account_type) }}</p>
      </div>
      <div>
        <img
          :src="channelImage(account, account.account_type)"
          alt=""
          @error="
            $event.target.src =
              'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
          "
        />
        <img
          :src="
            require('@assets/img/integration/' +
              getSocialImage(account.account_type))
          "
          alt=""
          class="absolute -right-0.5 -bottom-0.5 !w-5 !h-5"
        />
      </div>
    </div>

    <div class="analytics-performance-box__body">
      <div class="d-flex">
        <div class="analytics-performance-box__label">Likes</div>
        <div class="flex-grow-1">
          <span
            class="green"
            :style="
              getWidth(
                account.reactions?.value
                  ? account.reactions.value
                  : account.reactions
              )
            "
          ></span>
        </div>
        <div class="analytics-performance-box__num">
          {{
            account.reactions?.value
              ? account.reactions.value
              : account.reactions
          }}
        </div>
      </div>
      <div class="d-flex">
        <div class="analytics-performance-box__label">Comments</div>
        <div class="flex-grow-1">
          <span
            class="blue"
            :style="
              getWidth(
                account.comments.value
                  ? account.comments.value
                  : account.comments
              )
            "
          ></span>
        </div>
        <div class="analytics-performance-box__num">
          {{
            account.comments.value ? account.comments.value : account.comments
          }}
        </div>
      </div>
      <div class="d-flex">
        <div class="analytics-performance-box__label">{{
          account.account_type === 'twitter'
            ? 'Retweets'
            : account.account_type === 'instagram'
            ? 'Saved'
            : 'Shares'
        }}</div>
        <div class="flex-grow-1">
          <span
            class="yellow"
            :style="
              getWidth(
                account.reposts.value ? account.reposts.value : account.reposts
              )
            "
          ></span>
        </div>
        <div class="analytics-performance-box__num">
          {{ account.reposts.value ? account.reposts.value : account.reposts }}
        </div>
      </div>
      <div class="d-flex">
        <div class="analytics-performance-box__label">{{
          account.account_type === 'youtube' ? 'Subscribers' : 'Followers'
        }}</div>
        <div class="flex-grow-1">
          <span
            class="red"
            :style="getWidth(account.total_followers ?? 0)"
          ></span>
        </div>
        <div class="analytics-performance-box__num">
          {{ account.total_followers }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper.js'

export default {
  name: 'AnalyticsPerformanceBox',
  mixins: [analyticsUtilsMixin, ComposerHelperMixin],
  props: {
    account: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      overall: [],
    }
  },
  methods: {
    getWidth(value) {
      const total =
        Number(this.account.reposts) +
        Number(this.account.comments) +
        Number(this.account.reactions) +
        (Number(this.account.total_followers) ?? 0)
      if (total === 0 || this.account.total_followers === 'N/A') {
        return 'width: 0%'
      }
      return 'width: ' + (value / total) * 100 + '%'
    },
  },
}
</script>
