<template>
  <div>
    <label
      class="cst-account-checkbox cursor-pointer px-3 py-2 pl-6 m-0 flex flex-row-reverse justify-between items-center hover:bg-cs-foggray"
      :class="{
        'inline-flex': collapsed,
        'opacity-100': value,
        'bg-white rounded-t-lg': account.posted_as && showPublishAs,
      }"
      :style="
        account.posted_as && account.posted_as.length && showPublishAs
          ? 'box-shadow: 0 1px 6px 0 rgb(184 189 209 / 50%);'
          : ''
      "
    >
      <input
        v-if="!disabled"
        v-model="value"
        :class="{ 'opacity-0 absolute': disabled }"
        :disabled="isThreadedTweet ? true : disabled"
        class="opacity-0 absolute h-5 w-5 cursor-pointer"
        name="account-selection1"
        type="checkbox"
        @input="handleCheck"
      />

      <div
        v-show="!collapsed && !disabled"
        class="check-icon relative cursor-pointer rounded-md flex flex-shrink-0 justify-center items-center focus-within:border-blue-500"
      >
        <svg
          class="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none"
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-9 -11)" fill="#0088cc" fill-rule="nonzero">
              <path
                d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
              />
            </g>
          </g>
        </svg>
      </div>

      <template v-if="collapsed && disabled">
        <i
          :id="'tooltip-target-collapsed-' + channelId"
          class="z-50 fas fa-exclamation-square text-red-500 text-xl"
        ></i>
        <b-tooltip
          :target="'tooltip-target-collapsed-' + channelId"
          triggers="hover"
        >
          {{ firstCommentWarning }}
        </b-tooltip>
      </template>

      <template v-if="!collapsed && validity" class="z-99">
        <div v-if="disabled && !isApprover">
          <i
            :id="'tooltip-target-' + accountName"
            class="fas fa-exclamation-square text-red-500 text-xl"
          ></i>
          <b-tooltip :target="'tooltip-target-' + accountName" triggers="hover">
            <div v-if="disabled && isTwitterDisabled">
              X (Twitter) integration is not supported on your plan. Please
              reconnect with a custom app to proceed.
              <a
                target="_blank"
                href="https://docs.contentstudio.io/article/966-how-to-connect-with-a-twitter-custom-app"
                class="text-yellow-400 underline hover:text-yellow-600"
                >Learn more.</a
              >
            </div>
            <div v-else-if="disabled && isIgPersonalAccount">
              Mobile device is not connected, direct publishing is not available
              for instagram personal accounts.
            </div>
            <div v-else-if="disabled && isFacebookGroup">
              Direct publishing is not available for Facebook Groups. Please install the ContentStudio app on your mobile device to post via push notification.
            </div>
            <div v-else-if="disabled">
              <span v-if="instaMobileDeviceWarning" class="font-semibold"
                >1.</span
              >
              The access token for this social account has been invalidated,
              please reconnect to continue posting.
            </div>
            <div v-if="instaMobileDeviceWarning && !isIgPersonalAccount"
              ><span class="font-semibold">2.</span> Mobile device is not
              connected, however you can still post via Instagram API
            </div>
          </b-tooltip>
        </div>
        <div v-else-if="isApprover">
          <i class="fas fa-check text-cs-green"></i>
        </div>

        <div v-if="isIgPersonalAccount" class="ml-auto mr-2">
          <i
            v-b-tooltip
            title="Direct publishing is not supported on IG personal profiles and will be sent via Push Notification method instead."
            class="fas fa-exclamation-square text-red-500 text-xl"
          ></i>
        </div>

        <div v-if="instaMobileDeviceWarning && !disabled" class="ml-auto mr-2">
          <i
            v-b-tooltip
            title="Mobile device is not connected, however you can still post via Instagram API"
            class="fas fa-exclamation-square text-red-500 text-xl"
          ></i>
        </div>
      </template>

      <span
        :class="{ 'opacity-100': value }"
        class="flex items-center opacity-70 w-full"
      >
        <span
          :id="channelId + '-collapsed'"
          class="relative border rounded-full"
        >
          <img
            :src="accountImage"
            :width="imgSize"
            :height="imgSize"
            alt=""
            class="rounded-full object-cover"
            @error="
              $event.target.src =
                'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
            "
          />
          <img
            :src="
              require('@assets/img/integration/' +
                getSocialImage(accountPlatform))
            "
            alt=""
            class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
            :class = "{ 'bg-white rounded-full': accountPlatform === 'twitter' }"
          />
        </span>
        <span v-if="!collapsed" class="flex flex-col ml-3">
          <span
            v-if="accountName.length > accountNameLength"
            :id="channelId"
            v-tooltip.left="{ content: accountName, distance: 12 }"
            :class="{ 'font-semibold': value }"
            class="block text-black-100 text-sm select-none"
          >
            {{
              accountName.length > accountNameLength
                ? accountName.substr(0, accountNameLength - 1) + '...'
                : accountName
            }}
          </span>
          <span
            v-else
            :id="channelId"
            :class="{ 'font-semibold': value }"
            class="block text-black-100 text-sm select-none"
          >
            {{
              accountName.length > accountNameLength
                ? accountName.substr(0, accountNameLength - 1) + '...'
                : accountName
            }}
          </span>
          <span
            class="block text-xs text-gray-700 cst-account-checkbox__type select-none"
          >
            {{ accountType.charAt(0).toUpperCase() + accountType.slice(1) }}
          </span>
        </span>
        <span v-else class="flex flex-col">
          <b-tooltip :target="channelId + '-collapsed'" triggers="hover">
            {{ accountName }}
          </b-tooltip>
        </span>
      </span>
    </label>
  </div>
</template>

<script>
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import CstFbAccountPublishAs from '@ui/FBPublishAs/CstFbAccountPublishAs'
export default {
  name: 'CstAccountCheckbox',
  components: { CstFbAccountPublishAs },
  mixins: [ComposerHelper],
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    channelId: {
      type: [String, Number],
      default: '',
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    accountType: {
      type: String,
      required: true,
    },
    accountPlatform: {
      type: String,
      default: 'facebook',
      required: true,
    },
    accountImage: {
      type: String,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },

    firstCommentWarning: {
      type: String,
      default: '',
    },
    isThreadedTweet: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      },
    },
    mobileDevices: {
      type: Array,
      default: () => [],
    },
    publishAs: {
      type: Object,
      default: () => {},
    },
    isApprover: {
      type: Boolean,
      default: false,
    },
    validity: {
      type: Boolean,
      default: true,
    },
    isIgPersonalAccount: {
      type: Boolean,
      default: false,
    },
    isTwitterDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.isChecked,
      accountNameLength: 20,
      instaMobileDeviceWarning:
        this.accountPlatform === 'instagram'
          ? !(this.mobileDevices && this.mobileDevices.length > 0)
          : false,
      showPublishAs: false,
    }
  },
  computed: {
    imgSize() {
      if (this.size === 'default') {
        return '40'
      } else if (this.size === 'small') {
        return '30'
      } else if (this.size === 'large') {
        return '45'
      }

      return '40'
    },

    isFacebookGroup() {
      return  this.accountPlatform === 'facebook' &&  this.accountType === 'Group'
    },
  },
  watch: {
    isChecked: {
      handler: function (val) {
        this.value = val
      },
      deep: true,
    },
  },
  mounted() {
    console.log('Mounted::CstAccountCheckBox ~ this.mobileDevices -> ')
    // this.instagramDeviceWarning()
  },
  methods: {
    handleCheck(event) {
      if (!event.target.checked) this.showPublishAs = false
      this.$emit('change', {
        channelId: this.channelId,
        value: event.target.checked,
      })
      this.$emit('input', this.channelId)
    },

    handleClickAway() {
      this.showPublishAs = !this.showPublishAs
    },
  },

  /**
   * Checks for account's API token validity.
   * @param account
   * @returns {boolean}
   */
  /* accountTokenStatus(account) {
    if('validity' in account) {
      return account.validity.toLowerCase() !== 'valid'
    }
    return false
  } */
}
</script>

<style lang="scss" scoped>
.cst-account-checkbox {
  &__type {
    //font-size: 0.6rem;
  }
  &__name {
  }
  &__platform {
    font-size: 0.7rem;
    text-align: center;
  }

  //input ~ span {
  //  opacity: .7;
  //  filter: grayscale(10%);
  //}
  //
  //input:checked + span {
  //  opacity: 1;
  //  filter: grayscale(0%);
  //
  //}

  input + .check-icon {
    background-color: transparent;
    border: 1.5px solid #99a3a982;
    border-radius: 0.3rem;
    width: 16px;
    height: 16px;
  }
  input:checked + .check-icon {
    @apply border-blue-300;
  }
  input:checked + .check-icon svg {
    @apply block;
  }
}
</style>
