import moment from 'moment'
import {
  LINKEDIN_ANALYTICS_PUBLISHED_MESSAGES,
  TWITTER_ANALYTICS_PUBLISHED_MESSAGES
} from '@common/constants/messages'
import { getChartColorCode } from '@common/constants/common-attributes'

export const analyticsDatePickerValues = () => {
  return {
    analytics: {
      isChanged: false,
      locale: {
        format: 'MMMM DD, YYYY'  // fomart of the dates displayed
      },
      opens: 'left',
      startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
      minDate: '2016-09-02',
      maxDate: moment.utc().format('YYYY-MM-DD'),
      dateRange: {
        endDate: moment().utc()
      },
      show_ranges: true,
      singleDatePicker: false,
      timePicker: false,
      timePicker24Hour: true,
      showDropdowns: true,
      autoApply: false,
      showWeekNumbers: true,
      ranges: {
        'All Time': [moment.utc().subtract(5, 'years'), moment.utc()],
        '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
        '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
        'Last 3 days': [moment.utc().subtract(3, 'day'), moment.utc()],
        'Last 7 Days': [moment.utc().subtract(7, 'days'), moment.utc()],
        'Last 30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
        'Last 3 Months': [moment.utc().subtract(90, 'days'), moment.utc()],
        'Last 6 Months': [moment.utc().subtract(180, 'days'), moment.utc()],
        'Last 1 Year': [moment.utc().subtract(360, 'days'), moment.utc()],
        'Last 2 Years': [moment.utc().subtract(720, 'days'), moment.utc()]
      }
    }
  }
}

export const splineTooltip = () => {
  return {
    headerFormat:
      '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
    pointFormat:
      '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
    footerFormat: '</div>',
    backgroundColor: null,
    shadow: false,
    useHTML: true,
    borderWidth: 0,
    crosshairs: {
      width: 50,
      color: '#fafafa'
    }
  }
}

export const splineChart = (id) => {
  return {
    type: 'areaspline',
    spacingBottom: 10,
    spacingTop: 10,
    spacingLeft: 10,
    spacingRight: 10,
    style: {
      fontFamily: "'Source Sans Pro', sans-serif"
    },
    events: {
      load: function () {
        const chart = this
        $(chart.series).each(function (_i, seriesItem) {
          $(
            '<li><span style="background: ' +
              getChartColorCode(seriesItem) +
              ';" class="pink color_box"></span> <span>' +
              seriesItem.name +
              '</span></li>'
          )
            .click(function () {
              if (seriesItem.visible) {
                seriesItem.setVisible(false)
                $(this).addClass('disabled')
              } else {
                seriesItem.setVisible(true)
                $(this).removeClass('disabled')
              }
            })
            .appendTo(id)
        })
      }
    }
  }
}

export const splineXAxis = () => {
  return {
    type: 'datetime',
    dateTimeLabelFormats: {
      hour: '%l %P',
      month: '%e. %b',
      year: '%b'
    },
    gridLineWidth: 0,
    gridLineColor: '#eaf0f4',
    title: {
      text: ''
    },
    tickWidth: 1,
    tickLength: 20,
    tickPixelInterval: 50
  }
}

export const splineYAxis = () => {
  return {
    gridLineWidth: 1,
    gridLineColor: '#eaf0f4',
    title: {
      text: ''
    }
  }
}

export const splinePlotOptions = () => {
  return {
    series: {
      marker: {
        enabled: false,
        symbol: 'circle',
        lineWidth: 2,
        fillColor: 'white',
        lineColor: null
      }
    }
  }
}

export const splineCredits = () => {
  return {
    enabled: false
  }
}
export const splineLegend = () => {
  return {
    enabled: false
  }
}

export const pieChart = (id, value = false) => {
  return {
    height: 200,
    type: 'pie',
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,

    style: {
      fontFamily: "'Source Sans Pro', sans-serif"
    },
    events: {
      load: function () {
        const chart = this
        $(id).empty()
        if (value) {
          $(chart.series[0].data).each(function (_i, serie) {
            console.debug('serieserie', serie)
            $(
              '<li><span style="background: ' +
                serie.color +
                ';" class=" color_box"></span> <span>' +
                serie.name +
                '</span><span class="value">' +
                serie.y +
                '</span></li>'
            )
              .click(function () {
                if (serie.visible) {
                  serie.setVisible(false)
                  $(this).addClass('disabled')
                } else {
                  serie.setVisible(true)
                  $(this).removeClass('disabled')
                }
              })
              .appendTo(id)
          })
        } else {
          $(chart.series[0].data).each(function (_i, serie) {
            $(
              '<li><span style="background: ' +
                serie.color +
                ';" class=" color_box"></span> <span>' +
                serie.name +
                '</span></li>'
            )
              .click(function () {
                if (serie.visible) {
                  serie.setVisible(false)
                  $(this).addClass('disabled')
                } else {
                  serie.setVisible(true)
                  $(this).removeClass('disabled')
                }
              })
              .appendTo(id)
          })
        }
      }
    }
  }
}

export const pieTitle = () => {
  return {
    text: ''
  }
}
export const piePlotOptions = () => {
  return {
    series: {
      borderWidth: 0
    },
    pie: {
      // innerSize: '60%',

      shadow: false,
      center: ['50%', '50%'],
      dataLabels: false
    }
  }
}

export const pieDataLabels = () => {
  return {
    enabled: true,
    format: '{point.percentage:.1f} {point.name} %',
    distance: -20,
    filter: {
      property: 'percentage',
      operator: '>',
      value: 4
    }
  }
}

export const pieTooltip = () => {
  return {
    headerFormat:
      '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
    pointFormat:
      '<p class="engagement_point"><span>{point.y} Total Engagement</span></p>',
    footerFormat: '</div>',
    backgroundColor: null,
    shadow: false,
    useHTML: true,
    borderWidth: 0,
    crosshairs: {
      width: 50,
      color: '#fafafa'
    }
  }
}

export const dataValues = () => {
  return {
    twitter: {
      previous_start_date: null,
      previous_end_date: null,
      accounts_limit: 10,
      report_date: '',
      isRendered: false,
      value: '',
      loader: true,
      selected_account: null,
      metrics: {
        statistics: {
          retweet_count: 0,
          friends_count: 0,
          total_engagement: 0,
          followers_count: 0,
          statuses_count: 0,
          screen_name: '',
        },
        top_posts: [],
        aggregations: {
          fans: {
            buckets: [],
          },
          audience: {
            buckets: [],
          },
          total_engagement: {
            value: 0,
          },
          favorites: {
            value: 0,
          },
          total_retweets: {
            value: 0,
          },
          total_followers_count: {
            value: 0,
          },
          top_hashtags: {
            buckets: [],
          },
          published_posts_with_engagement: {
            buckets: [],
          },
          publish_posts_with_mentions: {
            buckets: [],
          },
          mentioned_by_people: {
            buckets: [],
          },
          content_distribution_daily: {},
          best_time: {},
        },
        previous_aggregations: {
          total_engagement: {
            value: 0,
          },
          favorites: {
            value: 0,
          },
          total_followers_count: {
            value: 0,
          },
        },
      },
      analytics: analyticsDatePickerValues().analytics,
      messages: {
        posts_published: TWITTER_ANALYTICS_PUBLISHED_MESSAGES,
      },
    },
    pinterest: {
      previous_start_date: null,
      previous_end_date: null,
      accounts_limit: 10,
      loader: false,
      selected_account: '',
      analytics: analyticsDatePickerValues().analytics,
      messages: {
        posts_published: LINKEDIN_ANALYTICS_PUBLISHED_MESSAGES,
      },
      overview: {
        summary_loader: false,
        engagement_loader: false,
        top_post_loader: false,
        posts_density_loader: false,
        hashtag_loader: false,
        summary: {
          current: {
            impressions: {
              value: 0,
            },
            outbound_click: {
              value: 0,
            },
            pin_click: {
              value: 0,
            },
            repins_count: {
              value: 0,
            },
          },
          previous: {
            impressions: {
              value: 0,
            },
            outbound_click: {
              value: 0,
            },
            pin_click: {
              value: 0,
            },
            repins_count: {
              value: 0,
            },
          },
        },
        summary_values: {
          impressions: 'Impressions',
          outbound_click: 'Outbound Click',
          pin_click: 'Pin Click',
          repins_count: 'Repins Count',
        },
        engagement: {
          engagement: {
            buckets: [],
            data: {
              total_engagement: [],
              pin_click: [],
              repins_count: [],
              show_data: 0,
            },
          },
          engagement_rollup: {
            current: {
              total_engagement: 0,
              repins_count: 0,
              pin_click: 0,
              avg_engagement: 0,
            },
            previous: {
              total_engagement: 0,
              repins_count: 0,
              pin_click: 0,
              avg_engagement: 0,
            },
          },
          engagement_values: {
            total_engagement: 'Total Engagement',
            repins_count: 'Repins Count',
            pin_click: 'Pin Click',
            avg_engagement: 'Average Engagement',
          },
        },
        posts_per_days: {
          data: {
            show_data: 0,
            days: {
              Monday: 0,
              Tuesday: 0,
              Wednesday: 0,
              Thursday: 0,
              Friday: 0,
              Saturday: 0,
              Sunday: 0,
            },
          },
        },
        top_hashtags: {},
        top_posts: {},
      },
    },
    linkedin: {
      previous_start_date: null,
      previous_end_date: null,
      accounts_limit: 10,
      loader: false,
      selected_account: '',
      analytics: analyticsDatePickerValues().analytics,
      overview: {
        summary_loader: false,
        audience_loader: false,
        engagement_loader: false,
        top_post_loader: false,
        posts_density_loader: false,
        hashtag_loader: false,
        top_posts: [],
        summary: {
          current: {
            followers: 0,
            total_posts: 0,
            total_engagement: 0,
            favorites: 0,
            comments: 0,
          },
          previous: {
            followers: 0,
            total_posts: 0,
            total_engagement: 0,
            favorites: 0,
            comments: 0,
          },
        },
        summary_values: {
          followers: 'Followers',
          total_posts: 'Total Posts',
          total_engagement: 'Total Engagement',
          favorites: 'Favorites',
        },
        summary_values_profile: {
          total_posts: 'Total Posts',
          total_engagement: 'Total Engagement',
          favorites: 'Favorites',
          comments: 'Comments',
        },
        audience: {
          audience_growth: {
            buckets: [],
            show_data: 0,
            paid_follower_count: [],
            organic_follower_count: [],
            total_follower_count: [],
          },
          audience_growth_rollup: {
            current: {
              paid_follower_count: 0,
              organic_follower_count: 0,
              total_follower_count: 0,
              avg_follower_count: 0,
            },
            previous: {
              paid_follower_count: 0,
              organic_follower_count: 0,
              total_follower_count: 0,
              avg_follower_count: 0,
            },
          },
          audience_growth_values: {
            paid_follower_count: 'Total Paid Followers',
            organic_follower_count: 'Total Organic Followers',
            total_follower_count: 'Total Followers',
            avg_follower_count: 'Average Followers',
          },
        },
        engagement: {
          engagement: {
            buckets: [],
            total_engagement: [],
            comments: [],
            favorites: [],
            show_data: 0,
          },
          engagement_rollup: {
            current: {
              total_engagement: 0,
              comments: 0,
              favorites: 0,
              avg_engagement: 0,
            },
            previous: {
              total_engagement: 0,
              comments: 0,
              favorites: 0,
              avg_engagement: 0,
            },
          },
          engagement_values: {
            total_engagement: 'Total Engagement',
            comments: 'Total Comments',
            favorites: 'Total Favorites',
            avg_engagement: 'Average Engagement',
          },
        },
        posts_per_days: {
          data: {
            show_data: 0,
            days: {
              Monday: 0,
              Tuesday: 0,
              Wednesday: 0,
              Thursday: 0,
              Friday: 0,
              Saturday: 0,
              Sunday: 0,
            },
          },
        },
        top_hashtags: {},
      },
      messages: {
        posts_published: LINKEDIN_ANALYTICS_PUBLISHED_MESSAGES,
      },
    },
    instagram: {
      overview: {
        summary_loader: false,
        audience_loader: false,
        publishing_loader: false,
        top_post_loader: false,
        active_users_loader: false,
        impressions_loader: false,
        engagement_loader: false,
        hashtag_loader: false,
        story_loader: false,
        reels_loader: false,
        selectedReelMetric: 'Engagement',
        summary_values: {
          followers_count: 'Followers',
          profile_views: 'Profile Views',
          engagement: 'Total Engagement',
          impressions: 'Total Impressions',
          comments: 'Comments',
          saved: 'Saved Posts',
          reactions: 'Total Reactions',
          reach: 'Total Reach',
        },
        summary: {
          current: {
            followers_count: 0,
            profile_views: 0,
            engagement: 0,
            impressions: 0,
            comments: 0,
            saved: 0,
            reactions: 0,
            reach: 0,
          },
          previous: {
            followers_count: 0,
            profile_views: 0,
            engagement: 0,
            impressions: 0,
            comments: 0,
            saved: 0,
            reactions: 0,
            reach: 0,
          },
        },
        audience_rollup_values: {
          followers_count: 'Followers',
        },
        audience: {
          audience_growth_rollup: {
            current: {
              followers_count: 0,
            },
            previous: {
              followers_count: 0,
            },
          },
        },
        publishing_behaviour_rollup_values: {
          total: 'Total Posts',
          image: 'Image Posts',
          video: 'Video Posts',
          carousel_album: 'Carousel Posts',
          reels: 'Reels',
        },
        publishing_behaviour: {
          publishing_behaviour_rollup: {
            current: {
              total: 0,
              image: 0,
              video: 0,
              carousel_album: 0,
              reels: 0,
            },
            previous: {
              total: 0,
              image: 0,
              video: 0,
              carousel_album: 0,
              reels: 0,
            },
          },
        },
        top_posts: [],
        active_users: {
          active_users_hours: {
            buckets: [],
            highest_hour: '0',
            highest_value: '1000',
          },
          active_users_days: {
            buckets: [],
            highest_day: 'Monday',
            highest_value: '1000',
          },
        },
        impressions: {
          impressions: {
            buckets: [],
            show_data: 0,
            impressions: [],
          },
          impressions_rollup: {
            current: {
              impressions: 0,
              avg_impressions: 0,
            },
            previous: {
              impressions: 0,
              avg_impressions: 0,
            },
          },
        },
        impressions_rollup_values: {
          total_impressions: 'Impressions',
          avg_impressions: 'Average Impressions',
        },
        engagement: {
          engagements: {
            buckets: [],
            show_data: 0,
            engagement: [],
          },
          engagements_rollup: {
            current: {
              engagement: 0,
              avg_engagement: 0,
              reactions: 0,
              comments: 0,
            },
            previous: {
              engagement: 0,
              avg_engagement: 0,
              reactions: 0,
              comments: 0,
            },
          },
        },
        engagement_rollup_values: {
          engagement: 'Total Posts Engagement',
          avg_engagement: 'Average Engagement',
          comments: 'Posts Comments',
          reactions: 'Posts Reactions',
        },
        reels_performance: {
          reels: {
            buckets: [],
            show_data: 0,
            total_posts: [],
            engagement: [],
            shares: [],
            avg_watch_time: [],
            total_watch_time: []
          },
          reels_rollup: {
            current: {
              total_posts: 0,
              engagement: 0,
              shares: 0,
              avg_watch_time: 0,
              total_watch_time: 0
            },
            previous: {
              total_posts: 0,
              engagement: 0,
              shares: 0,
              avg_watch_time: 0,
              total_watch_time: 0
            }
          }
        },
        reels_rollup_values: {
          total_posts: 'Total Reels',
          total_watch_time: 'Total Time Watched (sec)',
          avg_watch_time: 'Avg. Time Watched (sec)',
          shares: 'Shares',
          engagement: 'Engagement'
        },
        hashtags: {
          top_hashtags: {},
          top_engagement_hashtags: {},
        },
        stories_performance: {
          stories_performance: {
            story_reply: [],
            story_taps_back: [],
            story_taps_forward: [],
            story_exits: [],
            buckets: [],
          },
          stories_published: {
            buckets: [],
            published_stories: [],
          },
          stories_rollup: {
            story_reply: 0,
            story_taps_back: 0,
            story_taps_forward: 0,
            story_exits: 0,
            story_reach: 0,
            story_impressions: 0,
            avg_story_impressions: 0,
          },
        },
        story_rollup_values: {
          story_reply: 'Story Reply Count',
          story_taps_back: 'Story Tap Backs Count',
          story_taps_forward: 'Story Tap Forwards Count',
          story_exits: 'Story Exits Count',
          story_reach: 'Total Story Reach',
          story_impressions: 'Total Story Impressions',
          avg_story_impressions: 'Average Story Impressions',
        },
      },
      demographics: {
        age_gender_loader: false,
        country_city_loader: false,
        fans_age: {},
        fans_gender: {},
        max_gender_age: {},
        audience_country: {},
        audience_city: {},
        gender: {
          M: 'Male',
          F: 'Female',
          U: 'Unspecified',
        },
        fans: 1000,
        legends: {},
      },

      insta_permission: true,
      previous_start_date: null,
      previous_end_date: null,
      accounts_limit: 10,
      post_count: {
        posts: 5,
        stories: 5,
      },
      loader: false,
      status: false,
      payload: {},
      active_section: 'overview',
      active_account: {},
      selected_account: '',
      analytics: analyticsDatePickerValues().analytics,
      metrics: {
        top_posts: [],
        top_stories: [],
        overview: {
          current: {
            total_reactions: {
              value: 0,
            },
            total_engagement: {
              value: 0,
            },
            avg_total_engagement: {
              value: 0,
            },
            saved: {
              value: 0,
            },
            reach: {
              value: 0,
            },
            total_comments: {
              value: 0,
            },
            total_impressions: {
              value: 0,
            },
            profile_views_day: {
              value: 0,
            },
          },
          previous: {},
        },
        statistics: {
          clicks: 0,
          total_engagement: 0,
          total_impressions: 0,
          total_reactions: 0,
        },
        insights: {
          is_business: true,
          instagram_id: '',
          follower_count_day: 0,
          impressions_day: 0,
          profile_views_day: 0,
          email_contacts_day: 0,
          website_clicks_day: 0,
          get_directions_clicks_day: 0,
          phone_call_clicks_day: 0,
          reach_day: 0,
          text_message_clicks_day: 0,
          followers_count: 0,
          follows_count: 0,
          media_count: 0,
          name: '',
          profile_picture_url: '',
          username: '',
          tags: 11,
          stored_event_at: '',
          timestamp: 0,
        },
        aggregations: {
          audience_growth: {
            buckets: [],
          },
        },
      },
      top_posts_metrics: {
        'Total Engagements': 'engagement',
        Likes: 'like_count',
        Comments: 'comments_count',
        Saves: 'saved',
      },
      posts_and_stories: {},
      sort_types: {
        posts: {
          engagement: 'desc',
          like_count: 'desc',
          comments_count: 'desc',
          saved: 'desc',
        },
        stories: {
          reach: 'desc',
          replies: 'desc',
          impressions: 'desc',
          exits: 'desc',
        },
      },
      audience_age: [{}],
    },
    group: {
      loader: false,
      select_all_accounts: true,
      selected_account: [],
      accounts_limit: 0,
      analytics: analyticsDatePickerValues().analytics,
      metrics: {
        top_posts: {
          facebook: [],
          twitter: [],
          linkedin: [],
          pinterest: [],
          overall: [],
        },
        interaction: {
          facebook: {
            total_reactions: {
              value: 0,
            },
            total_engagement: {
              value: 0,
            },
            total_impressions: {
              value: 0,
            },
            total_shares: {
              value: 0,
            },
            total_comments: {
              value: 0,
            },
          },
          twitter: {
            total_engagement: {
              value: 0,
            },
            favorites: {
              value: 0,
            },
            retweets: {
              value: 0,
            },
          },
          linkedin: {
            favorites: {
              value: 0,
            },
            comments: {
              value: 0,
            },
            total_engagement: {
              value: 0,
            },
          },
          pinterest: {
            repins_count: {
              value: 0,
            },
            total_engagement: {
              value: 0,
            },
            comments_count: {
              value: 0,
            },
          },
          overall: {
            value: 0,
          },
        },
        performance_histogram: [],
      },
    },
    overview: {
      top_posts_loader: false,
      top_posts: {
        overall: [],
        facebook: [],
        instagram: [],
        twitter: [],
        linkedin: [],
      },
      overview_loader: false,
      posts_loader: false,
      engagement_rollup_loader: false,
      performance_loader: false,
      accounts: [],
      date: '',
      previous_date: '',
      overview: {
        current: {
          total_posts: 0,
          reactions: 0,
          comments: 0,
          reposts: 0,
        },
        previous: {
          total_posts: 0,
          reactions: 0,
          comments: 0,
          reposts: 0,
        },
      },
      overview_values: {
        total_posts: 'Total Posts',
        reactions: 'Reactions',
        comments: 'Comments',
        reposts: 'Reposts',
      },
      engagement_rollup: {
        current: {
          total_posts: 0,
          posts_per_day: 0,
          total_engagement: 0,
          engagement_per_day: 0,
        },
        previous: {
          total_posts: 0,
          posts_per_day: 0,
          total_engagement: 0,
          engagement_per_day: 0,
        },
      },
      engagement_rollup_values: {
        total_posts: 'Total Posts',
        posts_per_day: 'Posts Per Day',
        total_engagement: 'Total Engagement',
        engagement_per_day: 'Engagement Per Day',
      },
      posts_engagements: {
        buckets: [],
        data: [],
      },
      account_performance: {},
    },
    facebook: {
      overview: {
        summary_loader: false,
        audience_loader: false,
        publishing_loader: false,
        top_post_loader: false,
        active_users_loader: false,
        impressions_loader: false,
        reach_loader: false,
        engagement_loader: false,
        video_loader: false,
        reels_loader: false,
        selectedReelMetric: 'Initial Plays',
        audience: {
          audience_growth: {
            buckets: [],
            show_data: 0,
            page_fans_by_like: [],
            page_fans_by_unlike: [],
            fan_count: [],
          },
          audience_growth_rollup: {
            current: {
              fan_count: 0,
              avg_page_fans_by_like: 0,
              avg_page_fans_by_unlike: 0,
              talking_about_count: 0,
            },
            previous: {
              fan_count: 0,
              avg_page_fans_by_like: 0,
              avg_page_fans_by_unlike: 0,
              talking_about_count: 0,
            },
          },
        },
        publishing_behaviour: {
          publishing_behaviour: {
            buckets: [],
            show_data: 0,
            engagement: [],
            video: [],
            link: [],
            photo: [],
            text: [],
            carousel: [],
            share: [],
            reels: [],
            others: [],
          },
          publishing_behaviour_rollup: {
            current: {
              total: 0,
              text: 0,
              video: 0,
              photo: 0,
              link: 0,
              carousel: 0,
              share: 0,
              reels: 0,
              others: 0,
            },
            previous: {
              total: 0,
              text: 0,
              video: 0,
              photo: 0,
              link: 0,
              carousel: 0,
              share: 0,
              reels: 0,
              others: 0,
            },
          },
        },
        summary: {
          current: {
            total_engagement: 0,
            reactions: 0,
            posts_clicks: 0,
            impressions: 0,
            reach: 0,
            doc_count: 0,
            repost: 0,
            positive_sentiment: 0,
            fan_count: 0,
            negative_sentiment: 0,
            page_positive_feedback: 0,
            page_negative_feedback: 0,
          },
          previous: {
            total_engagement: 0,
            reactions: 0,
            posts_clicks: 0,
            impressions: 0,
            reach: 0,
            doc_count: 0,
            repost: 0,
            positive_sentiment: 0,
            fan_count: 0,
            negative_sentiment: 0,
            page_positive_feedback: 0,
            page_negative_feedback: 0,
          },
        },
        summary_values: {
          fan_count: 'Fans Count',
          doc_count: 'Total Posts',
          total_engagement: 'Engagements',
          impressions: 'Impressions',
          reach: 'Reach',
          posts_clicks: 'Post Clicks',
          reactions: 'Reactions',
          repost: 'Reposts',
          positive_sentiment: 'Sentiments',
          page_positive_feedback: 'Feedback',
        },
        audience_rollup_values: {
          fan_count: 'Total Fans',
          avg_page_fans_by_like: 'Average Page Fans by Like',
          avg_page_fans_by_unlike: 'Average Page Fans by Unlike',
          talking_about_count: 'Talking About Count',
        },
        publishing_behaviour_rollup_values: {
          total: 'Total Posts Published',
          text: 'Published Texts',
          photo: 'Published Photos',
          video: 'Published Videos',
          link: 'Published Links',
          carousel: 'Published Carousels',
          share: 'Shared Posts',
          reels: 'Reel Posts',
          others: 'Other Posts',
        },
        top_posts: [],
        active_users: {
          active_users_hours: {
            buckets: [],
            highest_hour: '0',
            highest_value: '1000',
            values: [],
          },
          active_users_days: [
            {
              active_users: '0',
              day_of_week: 'Saturday',
            },
            {
              active_users: '0',
              day_of_week: 'Thursday',
            },
            {
              active_users: '0',
              day_of_week: 'Sunday',
            },
            {
              active_users: '0',
              day_of_week: 'Tuesday',
            },
            {
              active_users: '0',
              day_of_week: 'Monday',
            },
            {
              active_users: '0',
              day_of_week: 'Friday',
            },
            {
              active_users: '0',
              day_of_week: 'Wednesday',
            },
          ],
          highest_day: 'Monday',
          highest_value: '1000',
        },
        impressions: {
          impressions: {
            buckets: [],
            show_data: 0,
            post_impressions_paid: [],
            post_impressions_viral: [],
            post_impressions_organic: [],
          },
          impressions_rollup: {
            current: {
              total_reach: 0,
              avg_reach: 0,
              post_reach_organic: 0,
              post_reach_viral: 0,
              post_reach_paid: 0,
            },
            previous: {
              total_reach: 0,
              avg_reach: 0,
              post_reach_organic: 0,
              post_reach_viral: 0,
              post_reach_paid: 0,
            },
          },
        },
        impressions_rollup_values: {
          total_reach: 'Total Posts Impressions',
          avg_reach: 'Average Total Impressions',
          post_reach_organic: 'Organic Post Impressions',
          post_reach_viral: 'Viral Post Impressions',
          post_reach_paid: 'Paid Post Impressions',
        },
        reach: {
          reach: {
            buckets: [],
            show_data: 0,
            post_reach_paid: [],
            post_reach_viral: [],
            post_reach_organic: [],
          },
          reach_rollup: {
            current: {
              total_reach: 0,
              avg_reach: 0,
              post_reach_organic: 0,
              post_reach_viral: 0,
              post_reach_paid: 0,
            },
            previous: {
              total_reach: 0,
              avg_reach: 0,
              post_reach_organic: 0,
              post_reach_viral: 0,
              post_reach_paid: 0,
            },
          },
        },
        reach_rollup_values: {
          total_reach: 'Total Posts Reach',
          avg_reach: 'Average Total Reach',
          post_reach_organic: 'Organic Post Reach',
          post_reach_viral: 'Viral Post Reach',
        },
        reels: {
          reels: {
            buckets: [],
            show_data: 0,
            total_reels: [],
            total_seconds_watched: [],
            initial_plays: [],
            reach: [],
            engagement: [],
          },
          reels_rollup: {
            current: {
              total_reels: 0,
              average_seconds_watched: 0,
              total_seconds_watched: 0,
              initial_plays: 0,
              reach: 0,
              engagement: 0,
            },
            previous: {
              total_reels: 0,
              average_seconds_watched: 0,
              total_seconds_watched: 0,
              initial_plays: 0,
              reach: 0,
              engagement: 0,
            },
          },
        },
        reels_rollup_values: {
          total_reels: 'Total Reels',
          total_seconds_watched: 'Total Time Watched (sec)',
          average_seconds_watched: 'Avg. Time Watched (sec)',
          initial_plays: 'Initial Plays',
          reach: 'Reach',
          engagement: 'Engagement',
        },
        engagement: {
          engagement: {
            buckets: [],
            show_data: 0,
            total_engagement: [],
            shares: [],
            reactions: [],
            posts_count: [],
            comments: [],
            post_clicks: [],
          },
          engagement_rollup: {
            current: {
              total_engagement: 0,
              count: 0,
              shares: 0,
              comments: 0,
              post_clicks: 0,
              reactions: 0,
            },
            previous: {
              total_engagement: 0,
              count: 0,
              shares: 0,
              comments: 0,
              post_clicks: 0,
              reactions: 0,
            },
          },
        },
        engagement_rollup_values: {
          total_engagement: 'Total Posts Engagement',
          count: 'Total Posts Count',
          shares: 'Posts Shares',
          comments: 'Posts Comments',
          post_clicks: 'Posts Clicks',
          reactions: 'Posts Reactions',
        },
        video_analytics: {
          current: {
            total_video_views_paid: 0,
            total_video_play_count: 0,
            total_video_views: 0,
            total_video_complete_views_clicked_to_play: 0,
            total_video_views_autoplayed: 0,
            total_video_engagement: 0,
            total_video_views_organic: 0,
          },
          previous: {
            total_video_views_paid: 0,
            total_video_play_count: 0,
            total_video_views: 0,
            total_video_complete_views_clicked_to_play: 0,
            total_video_views_autoplayed: 0,
            total_video_engagement: 0,
            total_video_views_organic: 0,
          },
        },
        legends: {},
      },
      demographics: {
        age_gender_loader: false,
        country_city_loader: false,
        summary_loader: false,
        fan_count: 0,
        fans_age: {},
        fans_gender: {},
        max_gender_age: {},
        audience_country: {},
        audience_city: {},
        gender: {
          M: 'Male',
          F: 'Female',
          U: 'Unspecified',
        },
        fans: 1000,
        legends: {},
      },
    },
    tiktok: {},
  }
}

export const columnChartTooltip = () => {
  return {
    headerFormat:
      '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
    pointFormat:
      '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
    footerFormat: '</div>',
    backgroundColor: null,
    shadow: false,
    useHTML: true,
    borderWidth: 0,
    crosshairs: {
      // width: 50,
      color: '#fafafa'
    }
  }
}

export const columnChart = () => {
  return {
    // width: 700,
    type: 'column',
    height: 200,
    inverted: true,
    spacingBottom: 0,
    marginBottom: 0,
    spacingTop: 10,
    spacingLeft: 10,
    spacingRight: 10,
    style: {
      fontFamily: "'Source Sans Pro', sans-serif"
    },
    events: {
      /*
                                                              load: function () {
                                                                var chart = this
                                                                $('#account_performance_item_highlight').empty()
                                                                $(chart.series).each(function (i, serie) {
                                                                  $('<li><span style="background: ' + serie.color + ';" class=" color_box"></span> <span>' + serie.name + '</span></li>').click(function () {
                                                                    if (serie.visible) {
                                                                      serie.setVisible(false)
                                                                      $(this).addClass('disabled')
                                                                    } else {
                                                                      serie.setVisible(true)
                                                                      $(this).removeClass('disabled')
                                                                    }
                                                                  }).appendTo('#account_performance_item_highlight')
                                                                })
                                                              } */
    }
  }
}
export const columnLegend = () => {
  return {
    align: 'center',
    verticalAlign: 'bottom',
    y: 10,
    floating: true,
    borderWidth: 1,
    shadow: false,
    enabled: false,
    backgroundColor: 'white',
    borderColor: '#f6f7fb',
    itemStyle: {
      font: "'Source Sans Pro', sans-serif'",
      color: '#64717c',
      fontWeight: 'normal'
    }
  }
}

export const columnXAxis = () => {
  return {
    gridLineWidth: 1,
    gridLineColor: '#eaf0f4',
    title: {
      text: ''
    },
    labels: {
      enabled: false
    }
  }
}

export const columnYAxis = () => {
  return {
    gridLineWidth: 1,
    labels: {
      style: {
        color: 'rgba(67, 66, 93, 0.5)',
        fontSize: '15px'
      }
    },
    gridLineColor: '#eaf0f4',
    title: {
      text: ''
    },
    min: 0
    // categories: ['0', '5', '10']
  }
}

export const columnPlotOptions = () => {
  return {
    column: {
      stacking: 'normal'
    },
    series: {
      pointWidth: 20,
      pointPadding: 0,
      groupPadding: 0.1,
      borderWidth: 0,
      lineWidth: 3,
      lineColor: '#0169a8'
    }
  }
}

export const lineChart = (id) => {
  return {
    type: 'spline',
    // spacingBottom: 10,
    // spacingTop: 10,
    // spacingLeft: 10,
    spacingRight: 10,
    marginTop: 10,
    // marginLeft: 0,
    marginRight: 0,
    height: 400,

    style: {
      fontFamily: "'Source Sans Pro', sans-serif"
    },
    events: splineChart(id).events
  }
}
export const linePlotOptions = () => {
  return {
    column: {
      stacking: 'normal'
    },
    series: {
      pointWidth: 20,
      pointPadding: 0,
      groupPadding: 0.1,
      borderWidth: 0,
      lineWidth: 3
    }
  }
}
export const lineXAxis = () => {
  return {
    gridLineWidth: 1,
    gridLineColor: '#eaf0f4',
    title: {
      text: ''
    },
    labels: {
      enabled: true
    }
  }
}
export const lineYAxis = () => {
  return {
    gridLineWidth: 1,
    labels: {
      style: {
        color: 'rgba(67, 66, 93, 0.5)',
        fontSize: '15px'
      }
    },
    gridLineColor: '#eaf0f4',
    title: {
      text: ''
    },
    min: 0
    // categories: ['0', '5', '10']
  }
}
export const lineChartTooltip = () => {
  return {
    headerFormat: '<div class="chart_tooltip engagement_tooltip">',
    pointFormat:
      '<div class="engagement_point"><h3 class="date">{point.x:%A, %B %e}</h3><span>{point.y:f}</span></div>',
    footerFormat: '</div>',
    backgroundColor: null,
    shadow: false,
    useHTML: true,
    borderWidth: 0,
    crosshairs: {
      // width: 50,
      color: '#fafafa'
    }
  }
}
export const lineLegend = () => {
  return {
    align: 'center',
    verticalAlign: 'bottom',
    y: 10,
    floating: true,
    borderWidth: 1,
    shadow: false,
    enabled: false,
    backgroundColor: 'white',
    borderColor: '#f6f7fb',
    itemStyle: {
      font: "'Source Sans Pro', sans-serif'",
      color: '#64717c',
      fontWeight: 'normal'
    }
  }
}

export const heatMapChart = (id) => {
  return {
    type: 'heatmap',
    // spacingBottom: 0,
    // spacingTop: 0,
    // spacingLeft: 0,
    // spacingRight: 0,
    // marginTop: 0,
    // marginLeft: 0,
    // marginRight: 0,

    style: {
      fontFamily: "'Source Sans Pro', sans-serif"
    },
    events: {
      load: function () {
        const chart = this
        $(id).empty()
        $(chart.series[0].data).each(function (_i, serie) {
          $(
            '<li><span style="background: ' +
              serie.color +
              ';" class=" color_box"></span> <span>' +
              serie.name +
              '</span></li>'
          )
            .click(function () {
              if (serie.visible) {
                serie.setVisible(false)
                $(this).addClass('disabled')
              } else {
                serie.setVisible(true)
                $(this).removeClass('disabled')
              }
            })
            .appendTo(id)
        })
      }
    }
  }
}
export const heatMapColorAxis = () => {
  return {
    min: 0,
    minColor: '#fafafa',
    maxColor: '#00c489'
  }
}
export const heatMapXAxis = () => {
  return {
    gridLineWidth: 0,
    categories: [
      '12am',
      '1am',
      '2am',
      '3am',
      '4am',
      '5am',
      '6am',
      '7am',
      '8am',
      '9am',
      '10am',
      '11am',
      '12pm',
      '1pm',
      '2pm',
      '3pm',
      '4pm',
      '5pm',
      '6pm',
      '7pm',
      '8pm',
      '9pm',
      '10pm',
      '11pm'
    ],
    title: {
      text: null
    }
  }
}
export const heatMapYAxis = () => {
  return {
    gridLineWidth: 0,
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    title: {
      text: null
    }
  }
}
export const heatMapChartTooltip = () => {
  return {
    headerFormat:
      '<div class="chart_tooltip engagement_tooltip"><h4>Engagement</h4>',
    pointFormat:
      '<p class="engagement_point">{point.x}, {point.y}: {point.value}</p>',
    footerFormat: '</div>',
    backgroundColor: null,
    shadow: false,
    useHTML: true,
    borderWidth: 0,
    crosshairs: {
      // width: 50,
      color: '#fafafa'
    }
  }
}
export const heatMapLegend = () => {
  return {
    align: 'center',
    verticalAlign: 'bottom',
    y: 10,
    floating: true,
    borderWidth: 1,
    shadow: false,
    enabled: false,
    backgroundColor: 'white',
    borderColor: '#f6f7fb',
    itemStyle: {
      font: "'Source Sans Pro', sans-serif'",
      color: '#64717c',
      fontWeight: 'normal'
    }
  }
}

export const stackedPlotOptions = () => {
  return {
    column: {
      stacking: 'normal'
    },

    series: {
      pointWidth: 15,
      borderWidth: 0,
      lineWidth: 3,
      lineColor: '#3a4557'
    }
  }
}

export const stackedColumnChart = (type) => {
  return {
    // type: 'areaspline',
    type: 'column',
    spacingBottom: 10,
    spacingTop: 10,
    spacingLeft: 10,
    spacingRight: 10,
    style: {
      fontFamily: "'Source Sans Pro', sans-serif"
    },
    events: splineChart(type).events
  }
}

export const bluePerformanceColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#1da1f2'],
      [1, '#9cd9ff']
    ]
  }
}
export const lightBluePerformanceColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#7fcdff'],
      [1, '#9fd8fc']
    ]
  }
}
export const orangePerformanceColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#fbbb1c'],
      [1, '#fcdb8d']
    ]
  }
}

export const darkBlueColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#536eff'],
      [1, '#96aaff']
    ]
  }
}

export const darkYellowColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#ffdf46'],
      [1, '#ffe8a2']
    ]
  }
}

export const darkGreenColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#27ca7a'],
      [1, '#61eea8']
    ]
  }
}
export const darkLightGreenColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#2fe095'],
      [1, '#78fdc5']
    ]
  }
}
export const darkRedColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#e02f5f'],
      [1, '#ff8ead']
    ]
  }
}
export const darkBlueShadeColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#2d3f8e'],
      [1, '#6275c7']
    ]
  }
}
export const darkOrangeColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#fb8c00'],
      [1, '#ffb357']
    ]
  }
}
export const darkBlackColor = () => {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1
    },
    stops: [
      [0, '#65738b'],
      [1, '#3a4557']
    ]
  }
}
