<script setup>
import arrowUp from '@assets/img/analytics/postTable/arrow-upward.svg'
import arrowDown from '@assets/img/analytics/postTable/arrow-downward.svg'
import { dragscroll as vDragScroll } from 'vue-dragscroll'
import { defineProps, defineEmits, inject, ref, computed } from 'vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'

const props = defineProps({
  indexToSortBy: {
    type: Number,
    default: 1,
  },
  nonSortableItems: {
    type: Array,
    default: () => [],
  },
  dataList: {
    type: Array,
    default: () => [],
  },
  selectedAccount: {
    type: Object,
    default: () => ({}),
  },
  validHeadersList: {
    type: Array,
    default: () => [],
  },
  mutateHeaderTitles: {
    type: Function,
    default: () => {},
  },
  mutateBodyValues: {
    type: Function,
    default: () => {},
  },
  mutateHeaderTooltips: {
    type: Function,
    default: () => {},
  },
  rangeStartValue: {
    type: Number,
    default: 0,
  },
  rangeEndValue: {
    type: Number,
    default: 10,
  },
  isReportView: {
    type: Boolean,
    default: false,
  },
  customNoDataMessage: {
    type: String,
    default: 'No data found',
  },
  customThumbnailKey: {
    type: String,
    default: 'thumbnail',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  rangeMaxValue: {
    type: Number,
    default: 100,
  },
})
const emit = defineEmits(['updateSort'])

const { tooltipHtml, handleImageError } = useComposerHelper()

const root = inject('root')
const { $bvModal } = root

const selectedPost = ref({})
const sortedHeaderKey = ref(props.indexToSortBy || 0)
const isDescending = ref(true) // Default is descending (true) order (large to small)
const sortingDirectionIcon = computed(() =>
  isDescending.value ? arrowDown : arrowUp
)
const computedDataList = computed(() =>
  props.dataList && Array.isArray(props.dataList)
    ? props.dataList?.slice(props.rangeStartValue, sliceEndIndex.value)
    : []
)

// If the view is report view, then the slice end index will be the end value of the range
const sliceEndIndex = computed(() =>
  props.isReportView ? props.rangeEndValue : props.rangeMaxValue
)

const tableStyle = computed(() =>
  props.isReportView ? 'max-height: 100%' : 'max-height: calc(100vh - 320px)'
)

/**
 * Sorts the column based on the header and index
 * @param header
 * @param index
 */
const sortColumn = (header, index) => {
  if (sortedHeaderKey.value === index && isDescending.value) {
    computedDataList.value?.sort((a, b) => a[header] - b[header])
    isDescending.value = false // Column is sorted in descending order
    return
  }
  if (sortedHeaderKey.value !== index) {
    emit('updateSort', header)
  }
  sortedHeaderKey.value = index
  isDescending.value = true
  computedDataList.value?.sort((a, b) => b[header] - a[header])
}

/**
 * Checks if the column is sortable, provide the list in props for non-sortable items
 * @param key
 * @param headerName
 * @returns {boolean}
 */
const isSortable = (key = 0, headerName = '') => {
  const nonSortableItems = props.nonSortableItems || []
  return !nonSortableItems.includes(headerName) && key === sortedHeaderKey.value
}

const handleRowClick = (post = {}) => {
  selectedPost.value = post
  $bvModal.show('post-details-modal')
}
</script>

<template>
  <div
    v-if="computedDataList?.length || props.isLoading"
    v-drag-scroll
    class="bg-white w-full shadow-sm select-none overflow-x-scroll"
    :style="tableStyle"
  >
    <table class="w-full rounded-lg">
      <thead
        id="header"
        class="gap-0.5 flex xl:w-full rounded-md sticky z-[2] top-0"
      >
        <template
          v-for="(header, index) in validHeadersList"
          :key="`table_header_${index}`"
        >
          <th
            v-tooltip="{
              content: tooltipHtml(mutateHeaderTooltips(header)),
              allowHTML: true,
              theme: 'light',
            }"
            scope="col"
            class="flex justify-between items-center px-4 py-3 w-full text-secondary-cs gap-2 first:sticky"
            :class="[
              isSortable(index, header) && !isReportView
                ? 'bg-gray-300'
                : 'bg-gray-200',
              isReportView ? '!min-w-[auto]' : '',
            ]"
            @click="sortColumn(header, index)"
          >
            <span
              class="text-gray-900"
              :class="[
                isReportView
                  ? 'text-xs font-normal'
                  : 'text-[0.9rem] font-medium',
              ]"
              >{{ mutateHeaderTitles(header) }}</span
            >
            <img
              v-if="isSortable(index, header) && !isReportView"
              :src="sortingDirectionIcon"
              :aria-label="
                isDescending ? 'Sorted descending' : 'Sorted ascending'
              "
              alt="sorting arrow icon"
            />
          </th>
        </template>
      </thead>
      <tbody>
        <tr v-if="props.isLoading">
          <td>
            <div class="p-4">
              <SkeletonBox width="100%" height="320px" />
            </div>
          </td>
        </tr>
        <tr
          v-for="(post, postsIndex) in computedDataList"
          v-else
          :key="`table_header_${postsIndex}`"
          class="flex border-b border-t-0 border-l-0 border-r-0 border-gray-300 border-solid bg-white min-h-[3rem] hover:bg-gray-100"
        >
          <template
            v-for="(header, keyIndex) in validHeadersList"
            :key="`table_header_${keyIndex}`"
          >
            <td
              class="flex flex-1 items-center bg-white"
              :class="`${isReportView ? '!min-w-[auto]' : 'px-6 py-4'} ${
                isReportView && header === customThumbnailKey ? 'flex-col' : ''
              }`"
              @click="keyIndex === 0 ? handleRowClick(post) : null"
            >
              <template v-if="header === customThumbnailKey">
                <div
                  class="items-center justify-center gap-3 bg-gray-cs-200 flex"
                >
                  <img
                    v-if="mutateBodyValues(header, post[header])"
                    :src="mutateBodyValues(header, post[header])"
                    :alt="customThumbnailKey"
                    class="w-[6rem] h-[6rem]"
                    @error="handleImageError"
                  />
                </div>
                <span
                  v-if="
                    !isReportView || !mutateBodyValues(header, post[header])
                  "
                  class="text-secondary-cs ml-6 text-sm line-clamp-3"
                >
                  {{ mutateBodyValues('description', post['description']) }}
                </span>
              </template>
              <span
                v-else
                v-tooltip="{
                  content: post[header] || '',
                  theme: 'light',
                }"
                class="font-medium text-secondary-cs ml-6 font-0-95rem"
              >
                {{ mutateBodyValues(header, post[header]) }}
              </span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else class="analytics-no-data-found">
    <img
      src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
      alt=""
    />
    <p>{{ customNoDataMessage }}</p>
  </div>

  <slot
    name="post-modal"
    :selected-account="selectedAccount"
    :selected-post="selectedPost"
  ></slot>
</template>

<style scoped>
table {
  overflow-x: auto;

  thead {
    th:first-child {
      /* Make the first column sticky */
      left: 0;
      z-index: 1;
      min-width: 400px;
    }

    th:not(:first-child) {
      /* Ensure other header cells don't overlap */
      position: relative;
      min-width: 180px;
      cursor: pointer;

      &:hover {
        background-color: #d1d5db;
      }
    }
  }

  tbody {
    td:first-child {
      /* Make the first column sticky */
      position: sticky;
      left: 0;
      z-index: 1;
      min-width: 400px;
      cursor: pointer;
    }

    td:not(:first-child) {
      /* Ensure other cells don't overlap */
      position: relative;
      min-width: 180px;
      cursor: move;
    }
  }
}
</style>
