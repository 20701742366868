<template>
  <div class="auth">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="auth-form-container auth-form-container--block">
          <form class="auth-form">
            <div class="auth-form__heading auth-form__heading--center">
              <h2>Forgot your password?</h2>
              <p
                >Enter your email address below and we’ll get you back on
                track.</p
              >
            </div>
            <CstFloatingLabelInput
                id="email"
                v-model="email"
                class="mb-6"
                type="email"
                label="Email Address"
                show-icon-left
            >
              <template v-slot:icon>
                <i class="icon-Email"></i>
              </template>
            </CstFloatingLabelInput>

            <div class="auth-form__button-field">
              <button
                :disabled="forgotPasswordLoader"
                class="btn btn-studio-theme-space btn-size-large"
                type="submit"
                @click.prevent="forgotPassword"
              >
                <span>Send</span>
                <img
                  v-if="forgotPasswordLoader"
                  style="width: 20px; margin-left: 8px"
                  src="../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>
          </form>

          <div class="auth-form-container__footer mt-5">
            <p
              ><router-link :to="{ name: 'login' }"
                >Take me back to login</router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <FeaturesSlider />
      </div>
    </div>
  </div>
</template>

<script>
import { forgotPasswordURL } from '@src/config/api-utils'
import CstFloatingLabelInput from "@ui/Input/CstFloatingLabelInput";
import FeaturesSlider from '../components/FeaturesSlider'

export default {
  name: 'ForgotPassword',
  components: {
    FeaturesSlider,
    CstFloatingLabelInput
  },
  data () {
    return {
      email: '',
      forgotPasswordLoader: false,
      message: false
    }
  },
  methods: {
    forgotPassword () {
      this.message = false
      this.forgotPasswordLoader = true
      this.$http
        .post(forgotPasswordURL, {
          email: this.email
        })
        .then(
          (response) => {
            this.forgotPasswordLoader = false
            if (response.data.status) {
              this.alertMessage(response.data.message)
            } else {
              if (response.data.message === 'invited') this.message = true
              else this.alertMessage(response.data.message, 'error')
            }
          },
          (response) => {}
        )
    }
  }
}
</script>

<style scoped></style>
