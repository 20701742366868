import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import useAnalyticsUtils from '@/src/modules/analytics/components/common/composables/useAnalyticsUtils'
import useWorkspace from '@/src/modules/common/composables/useWorkspace'

const { updateWorkspacePreferences, getPreferences } = useWorkspace()

const { getAccountId } = useAnalyticsUtils()
const { formatNumber } = useNumber()
const {
  ANALYTICS_DELIMITER,
} = useAnalytics()
// constants
const IGNORE_ACCOUNTS = ['youtube'];
const SAMPLE_SUMMARY_DATA = [
  {
    title: 'Posts Published',
    total: 20,
    growth: 2000,
    difference: 20,
    originalValue: 20,
    tooltip:
      'The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.',
  },
  {
    title: 'Post Impressions',
    total: 1076,
    growth: 107600,
    difference: 1076,
    originalValue: 1076,
    tooltip:
      'The total number of impressions received by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Impressions are based on the lifetime performance of the posts.',
  },
  {
    title: 'Post Engagements',
    total: 42,
    growth: 4200,
    difference: 42,
    originalValue: 42,
    tooltip:
      'The total number of engagement actions generated by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Engagement includes actions such as likes, comments, shares, and other interactions with the posts, based on their lifetime performance.',
  },
  {
    title: 'Engagement Rate per Impression',
    total: 0.04,
    growth: 0,
    difference: 0,
    originalValue: 0.04,
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
  },
]
const SAMPLE_BREAKDOWN_DATA = [
  {
    id: '6645d3b25b840fa876015982',
    name: 'Instagram Only',
    type: 'campaigns',
    color: 'color_8',
    totalPost: 5,
    previousTotalPost: 0,
    rateOfChange: '500',
    chartApiKey: 'total_posts',
    dataType: 'posts',
  },
  {
    id: '6645d3beee2c713d720eeb13',
    name: 'Tiktok Only',
    type: 'campaigns',
    color: 'color_14',
    totalPost: 2,
    previousTotalPost: 0,
    rateOfChange: '200',
    chartApiKey: 'total_posts',
    dataType: 'posts',
  },
  {
    id: '6645d3e4a164d4bfd40220c5',
    name: 'Pinterest Only',
    type: 'campaigns',
    color: 'color_19',
    totalPost: 4,
    previousTotalPost: 0,
    rateOfChange: '400',
    chartApiKey: 'total_posts',
    dataType: 'posts',
  },
  {
    id: '6194f1c83e2b6b05d8070872',
    name: 'Instagram multiple image posting',
    type: 'labels',
    color: 'color_10',
    totalPost: 2,
    previousTotalPost: 0,
    rateOfChange: '200',
    chartApiKey: 'total_posts',
    dataType: 'posts',
  },
  {
    id: '6645d647036af3e91c022ec2',
    name: 'Facebook Only',
    type: 'labels',
    color: 'color_18',
    totalPost: 7,
    previousTotalPost: 0,
    rateOfChange: '700',
    chartApiKey: 'total_posts',
    dataType: 'posts',
  },
  {
    id: '6645d65d036af3e91c022ec3',
    name: 'Tiktok only',
    type: 'labels',
    color: 'color_17',
    totalPost: 2,
    previousTotalPost: 0,
    rateOfChange: '200',
    chartApiKey: 'total_posts',
    dataType: 'posts',
  },
]
const SAMPLE_CHART_DATA = {
  '6645d3b25b840fa876015982': {
    buckets: [
      '2024-04-27',
      '2024-04-28',
      '2024-04-29',
      '2024-04-30',
      '2024-05-01',
      '2024-05-02',
      '2024-05-03',
      '2024-05-04',
      '2024-05-05',
      '2024-05-06',
      '2024-05-07',
      '2024-05-08',
      '2024-05-09',
      '2024-05-10',
      '2024-05-11',
      '2024-05-12',
      '2024-05-13',
      '2024-05-14',
      '2024-05-15',
      '2024-05-16',
      '2024-05-17',
      '2024-05-18',
      '2024-05-19',
      '2024-05-20',
      '2024-05-21',
      '2024-05-22',
      '2024-05-23',
      '2024-05-24',
      '2024-05-25',
      '2024-05-26',
      '2024-05-27',
    ],
    total_engagement: [
      1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 6765, 4, 10, 8, 6, 4,
      3, 2, 1, 0, 0, 0, 0, 0
    ],
    total_impressions: [
      2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 6765, 10946, 17711, 20, 15, 10, 5,
      4, 3, 2, 1, 0, 0, 0, 0
    ],
    total_posts: [
      1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 2, 3, 2, 1, 0, 0,
      0, 0, 0, 0, 0, 0
    ],
  },
  '6645d3beee2c713d720eeb13': {
    buckets: [
      '2024-04-27',
      '2024-04-28',
      '2024-04-29',
      '2024-04-30',
      '2024-05-01',
      '2024-05-02',
      '2024-05-03',
      '2024-05-04',
      '2024-05-05',
      '2024-05-06',
      '2024-05-07',
      '2024-05-08',
      '2024-05-09',
      '2024-05-10',
      '2024-05-11',
      '2024-05-12',
      '2024-05-13',
      '2024-05-14',
      '2024-05-15',
      '2024-05-16',
      '2024-05-17',
      '2024-05-18',
      '2024-05-19',
      '2024-05-20',
      '2024-05-21',
      '2024-05-22',
      '2024-05-23',
      '2024-05-24',
      '2024-05-25',
      '2024-05-26',
      '2024-05-27',
    ],
    total_engagement: [
      0, 1, 3, 6, 10, 15, 21, 28, 36, 45, 50, 40, 30, 20, 15, 10, 8, 6, 4, 4, 10, 9, 7, 5, 3, 2, 1, 0, 0, 0, 0,
    ],
    total_impressions: [
      0, 2, 5, 9, 14, 20, 27, 35, 44, 54, 60, 48, 36, 24, 18, 12, 9, 6, 3, 3, 20, 18, 15, 12, 9, 6, 3, 0, 0, 0, 0,
    ],
    total_posts: [
      0, 1, 2, 4, 7, 11, 16, 22, 29, 37, 40, 32, 24, 16, 12, 8, 6, 4, 2, 2, 3, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0,
    ],
  },
  '6645d3e4a164d4bfd40220c5': {
    buckets: [
      '2024-04-27',
      '2024-04-28',
      '2024-04-29',
      '2024-04-30',
      '2024-05-01',
      '2024-05-02',
      '2024-05-03',
      '2024-05-04',
      '2024-05-05',
      '2024-05-06',
      '2024-05-07',
      '2024-05-08',
      '2024-05-09',
      '2024-05-10',
      '2024-05-11',
      '2024-05-12',
      '2024-05-13',
      '2024-05-14',
      '2024-05-15',
      '2024-05-16',
      '2024-05-17',
      '2024-05-18',
      '2024-05-19',
      '2024-05-20',
      '2024-05-21',
      '2024-05-22',
      '2024-05-23',
      '2024-05-24',
      '2024-05-25',
      '2024-05-26',
      '2024-05-27',
    ],
    total_engagement: [
      0, 2, 5, 9, 14, 20, 27, 35, 44, 54, 60, 48, 36, 24, 18, 12, 9, 6, 3, 3, 20, 18, 15, 12, 9, 6, 3, 0, 0, 0, 0,
    ],
    total_impressions: [
      0, 1, 2, 4, 7, 11, 16, 22, 29, 37, 40, 32, 24, 16, 12, 8, 6, 4, 2, 2, 3, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0,
    ],
    total_posts: [
      0, 1, 3, 6, 10, 15, 21, 28, 36, 45, 50, 40, 30, 20, 15, 10, 8, 6, 4, 4, 10, 9, 7, 5, 3, 2, 1, 0, 0, 0, 0,
    ],
  },
  '6194f1c83e2b6b05d8070872': {
    buckets: [
      '2024-04-27',
      '2024-04-28',
      '2024-04-29',
      '2024-04-30',
      '2024-05-01',
      '2024-05-02',
      '2024-05-03',
      '2024-05-04',
      '2024-05-05',
      '2024-05-06',
      '2024-05-07',
      '2024-05-08',
      '2024-05-09',
      '2024-05-10',
      '2024-05-11',
      '2024-05-12',
      '2024-05-13',
      '2024-05-14',
      '2024-05-15',
      '2024-05-16',
      '2024-05-17',
      '2024-05-18',
      '2024-05-19',
      '2024-05-20',
      '2024-05-21',
      '2024-05-22',
      '2024-05-23',
      '2024-05-24',
      '2024-05-25',
      '2024-05-26',
      '2024-05-27',
    ],
    total_engagement: [
      2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 6765, 10946, 17711, 20, 15, 10, 5,
      4, 3, 2, 1, 0, 0, 0, 0
    ],
    total_impressions: [
      2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 6765, 10946, 17711, 20, 15, 10, 5,
      4, 3, 2, 1, 0, 0, 0, 0
    ],
    total_posts: [
      1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 2, 3, 2, 1, 0, 0,
      0, 0, 0, 0, 0, 0
    ],
  },
  '6645d647036af3e91c022ec2': {
    buckets: [
      '2024-04-27',
      '2024-04-28',
      '2024-04-29',
      '2024-04-30',
      '2024-05-01',
      '2024-05-02',
      '2024-05-03',
      '2024-05-04',
      '2024-05-05',
      '2024-05-06',
      '2024-05-07',
      '2024-05-08',
      '2024-05-09',
      '2024-05-10',
      '2024-05-11',
      '2024-05-12',
      '2024-05-13',
      '2024-05-14',
      '2024-05-15',
      '2024-05-16',
      '2024-05-17',
      '2024-05-18',
      '2024-05-19',
      '2024-05-20',
      '2024-05-21',
      '2024-05-22',
      '2024-05-23',
      '2024-05-24',
      '2024-05-25',
      '2024-05-26',
      '2024-05-27',
    ],
    total_engagement: [
      2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 6765, 10946, 17711, 20, 15, 10, 5,
      4, 3, 2, 1, 0, 0, 0, 0
    ],
    total_impressions: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 8, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0,
    ],
    total_posts: [
      2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584, 4181, 6765, 10946, 17711, 20, 15, 10, 5,
      4, 3, 2, 1, 0, 0, 0, 0
    ],
  },
  '6645d65d036af3e91c022ec3': {
    buckets: [
      '2024-04-27',
      '2024-04-28',
      '2024-04-29',
      '2024-04-30',
      '2024-05-01',
      '2024-05-02',
      '2024-05-03',
      '2024-05-04',
      '2024-05-05',
      '2024-05-06',
      '2024-05-07',
      '2024-05-08',
      '2024-05-09',
      '2024-05-10',
      '2024-05-11',
      '2024-05-12',
      '2024-05-13',
      '2024-05-14',
      '2024-05-15',
      '2024-05-16',
      '2024-05-17',
      '2024-05-18',
      '2024-05-19',
      '2024-05-20',
      '2024-05-21',
      '2024-05-22',
      '2024-05-23',
      '2024-05-24',
      '2024-05-25',
      '2024-05-26',
      '2024-05-27',
    ],
    total_engagement: [
      0, 1, 3, 6, 10, 15, 21, 28, 36, 45, 50, 40, 30, 20, 15, 10, 8, 6, 4, 4, 10, 9, 7, 5, 3, 2, 1, 0, 0, 0, 0,
    ],
    total_impressions: [
      0, 2, 5, 9, 14, 20, 27, 35, 44, 54, 60, 48, 36, 24, 18, 12, 9, 6, 3, 3, 20, 18, 15, 12, 9, 6, 3, 0, 0, 0, 0,
    ],
    total_posts: [
      0, 1, 2, 4, 7, 11, 16, 22, 29, 37, 40, 32, 24, 16, 12, 8, 6, 4, 2, 2, 3, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0,
    ],
  },
}
const SAMPLE_BREAKDOWN_DATA_BY_IMPRESSION = [
  {
    id: '6645d3b25b840fa876015982',
    name: 'Instagram Only',
    type: 'campaigns',
    color: 'color_8',
    totalImpressions: 20,
    previousTotalImpressions: 0,
    rateOfChange: '2K',
    chartApiKey: 'total_impressions',
    dataType: 'impressions',
  },
  {
    id: '6645d3beee2c713d720eeb13',
    name: 'Tiktok Only',
    type: 'campaigns',
    color: 'color_14',
    totalImpressions: 795,
    previousTotalImpressions: 0,
    rateOfChange: '80K',
    chartApiKey: 'total_impressions',
    dataType: 'impressions',
  },
  {
    id: '6645d3e4a164d4bfd40220c5',
    name: 'Pinterest Only',
    type: 'campaigns',
    color: 'color_19',
    totalImpressions: 249,
    previousTotalImpressions: 0,
    rateOfChange: '25K',
    chartApiKey: 'total_impressions',
    dataType: 'impressions',
  },
  {
    id: '6194f1c83e2b6b05d8070872',
    name: 'Instagram multiple image posting',
    type: 'labels',
    color: 'color_10',
    totalImpressions: 0,
    previousTotalImpressions: 0,
    rateOfChange: '0',
    chartApiKey: 'total_impressions',
    dataType: 'impressions',
  },
  {
    id: '6645d647036af3e91c022ec2',
    name: 'Facebook Only',
    type: 'labels',
    color: 'color_18',
    totalImpressions: 12,
    previousTotalImpressions: 0,
    rateOfChange: '1.2K',
    chartApiKey: 'total_impressions',
    dataType: 'impressions',
  },
  {
    id: '6645d65d036af3e91c022ec3',
    name: 'Tiktok only',
    type: 'labels',
    color: 'color_17',
    totalImpressions: 795,
    previousTotalImpressions: 0,
    rateOfChange: '80K',
    chartApiKey: 'total_impressions',
    dataType: 'impressions',
  },
]
const SAMPLE_BREAKDOWN_DATA_BY_ENGAGEMENT = [
  {
    id: '6645d3b25b840fa876015982',
    name: 'Instagram Only',
    type: 'campaigns',
    color: 'color_8',
    totalEngagements: 14,
    previousTotalEngagements: 0,
    rateOfChange: '1.4K',
    chartApiKey: 'total_engagement',
    dataType: 'engagements',
  },
  {
    id: '6645d3beee2c713d720eeb13',
    name: 'Tiktok Only',
    type: 'campaigns',
    color: 'color_14',
    totalEngagements: 8,
    previousTotalEngagements: 0,
    rateOfChange: '800',
    chartApiKey: 'total_engagement',
    dataType: 'engagements',
  },
  {
    id: '6645d3e4a164d4bfd40220c5',
    name: 'Pinterest Only',
    type: 'campaigns',
    color: 'color_19',
    totalEngagements: 14,
    previousTotalEngagements: 0,
    rateOfChange: '1.4K',
    chartApiKey: 'total_engagement',
    dataType: 'engagements',
  },
  {
    id: '6194f1c83e2b6b05d8070872',
    name: 'Instagram multiple image posting',
    type: 'labels',
    color: 'color_10',
    totalEngagements: 0,
    previousTotalEngagements: 0,
    rateOfChange: '0',
    chartApiKey: 'total_engagement',
    dataType: 'engagements',
  },
  {
    id: '6645d647036af3e91c022ec2',
    name: 'Facebook Only',
    type: 'labels',
    color: 'color_18',
    totalEngagements: 6,
    previousTotalEngagements: 0,
    rateOfChange: '600',
    chartApiKey: 'total_engagement',
    dataType: 'engagements',
  },
  {
    id: '6645d65d036af3e91c022ec3',
    name: 'Tiktok only',
    type: 'labels',
    color: 'color_17',
    totalEngagements: 8,
    previousTotalEngagements: 0,
    rateOfChange: '800',
    chartApiKey: 'total_engagement',
    dataType: 'engagements',
  },
]

const PREFERNCES_TYPES = {
  date: 'date',
  campaigns_and_labels: 'campaigns_and_labels',
  accounts: 'accounts',
}
const ongoingRequests = new Map();

const selectedAccount = ref(null)
const isAllAccountsSelected = ref(false)
const isLoadingStates = ref({
  getSummaryAnalytics: false,
  getCampaignLabelBreakdownData: false,
  getCampaignLabelInsightsBreakdown: false,
  fetchCampaignsAndLabels: false,
})
const TopLeastEngagementDropdown = [
  { label: 'Impressions', api_key: 'impressions' },
  { label: 'Engagements', api_key: 'engagement_rate' },
]
const selectedTopLeastSortType = ref({
  top: TopLeastEngagementDropdown.find((i) => i.label === 'Impressions'),
  least: TopLeastEngagementDropdown.find((i) => i.label === 'Impressions'),
})

const routes = {
  ALL_LABELS_AND_CAMPAIGNS: 'fetchCampaignsAndLabels',
  SUMMARY_ANALYTICS: 'getSummaryAnalytics',
  BREAKDOWN_DATA: 'getCampaignLabelBreakdownData',
  INSIGHTS_GRAPH_BREAKDOWN: 'getCampaignLabelInsightsBreakdown',
}

const WORKSPACE_PREFERENCE_KEY = 'analytics_campaign_label'

const LABELS_COLOR_MAP = {
  color_1: '#69c366',
  color_2: '#5cc6ff',
  color_3: '#ff6462',
  color_4: '#fea28b',
  color_5: '#ff5f31',
  color_6: '#864de9',
  color_7: '#e7af4d',
  color_8: '#fa6ab6',
  color_9: '#0095f3',
  color_10: '#dc70ea',
  color_11: '#456990',
  color_12: '#028090',
  color_13: '#ffa13f',
  color_14: '#231942',
  color_15: '#544c72',
  color_16: '#975816',
  color_17: '#0e4749',
  color_18: '#a5be00',
  color_19: '#fc1100',
  color_20: '#000',
}

// state
const { DEFAULT_DATE_RANGE } = useAnalytics()
const dateRange = ref(DEFAULT_DATE_RANGE)

const cardsData = ref({})
const breakdownDataByLabel = ref({})
const breakdownDataByImpression = ref({})
const breakdownDataByEngagement = ref({})
const insightsGraphBreakDown = ref({})

const allCampaignsAndLabels = ref({
  campaigns: [],
  labels: [],
})

const labelCampaignByKeys = ref({})

const selectedCampaignsAndLabels = ref({
  campaigns: [],
  labels: [],
})

const TABLE_CHART_API_KEYS = {
  POST: 'total_posts',
  IMPRESSION: 'total_impressions',
  ENGAGEMENT: 'total_engagement',
}

const cards = ref([
  {
    title: 'Posts Published',
    key: 'total_posts',
    tooltip: `The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.`,
  },
  {
    title: 'Post Impressions',
    key: 'total_impressions',
    tooltip: `The total number of impressions received by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Impressions are based on the lifetime performance of the posts.`,
  },
  {
    title: 'Post Engagements',
    key: 'total_engagement',
    tooltip:
      'The total number of engagement actions generated by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Engagement includes actions such as likes, comments, shares, and other interactions with the posts, based on their lifetime performance.',
  },
  {
    title: 'Engagement Rate per Impression',
    key: 'total_engagement_rate_per_impression',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => moment(date).format('YYYY-MM-DD'))
    .join(' - ')
}

const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { current, percentage, difference } = cardsData.value

  return {
    title: card.title,
    total: current?.[card.key] || defaultValue.total,
    growth: percentage?.[card.key] || defaultValue.growth,
    difference: difference?.[card.key] || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card.tooltip,
  }
}

/**
 * @description as the header data is created by the keys of tableData, this method returns proper names on basis of the key names.
 * @param value
 * @returns {string}
 */
const getHeaderTitles = (value) => {
  switch (value) {
    case 'totalPost':
      return 'Posts Published'
    case 'rateOfChange':
      return '% Change'
    case 'totalImpressions':
      return 'Total Impressions'
    case 'totalEngagements':
      return 'Total Engagements'
    default:
      return 'Analytics'
  }
}
const getComponentInfoTooltip = (name = '', platform = '', postType = '') => {
  switch (name) {
    case 'breakdown':
      return `Provides insights into how posts are distributed across campaigns and labels, ensuring comprehensive coverage across all categories and areas of focus.`
    case 'impressions':
      return `Evaluate the impressions received on posts categorized by their associated campaigns and labels.`
    case 'engagements':
      return `Evaluate the engagements received on posts categorized by their associated campaigns and labels.`
    default:
      return ``
  }
}

const getDetailedPulseInfoTooltip = (name = '') => {
  switch (name) {
    case 'posts':
      return `Gain insights into your posting frequency and timing trends over the selected time period.`
    case 'impressions':
      return `Monitor how your post impressions vary over time to optimize your posting schedule.`
    case 'engagements':
      return `Analyze how your audience engages with your posts over the selected period to improve your engagement strategy.`
    default:
      return ``
  }
}

const getPulseTooltipTitle = (name = '') => {
  switch (name) {
    case 'posts':
      return `Posts Published`
    case 'impressions':
      return `Impressions`
    case 'engagements':
      return `Engagements`
    default:
      return name
  }
}

const getDetailedPulseChartTitle = (name = '') => {
  switch (name) {
    case 'posts':
      return `Posting pattern over time`
    case 'impressions':
      return `Impressions trend over time`
    case 'engagements':
      return `Engagements trend over time`
    default:
      return ``
  }
}

const getTopLeastEngagingPayload = (type, topPostsLimit = 10) => {
  const filterPayload = {}
  switch (type) {
    case 'top':
      filterPayload.limit = 5
      filterPayload.order_by = selectedTopLeastSortType.value.top.api_key

      break
    case 'least':
      filterPayload.limit = 5
      filterPayload.order_by = selectedTopLeastSortType.value.least.api_key
      break
    case 'report':
      filterPayload.limit = topPostsLimit
      filterPayload.order_by = TopLeastEngagementDropdown.find(
        (i) => i.label === 'Engagements'
      ).api_key
      break
  }
  return filterPayload
}
const getTableHeaderTooltips = (value ,type) => {
  const METRIC_LABELS = {
    breakdown: 'Posts Published',
    impressions: 'Post Impressions',
    engagements: 'Post Engagements',
  }

  switch (value) {
    case 'totalPost':
      return `The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.`
    case 'rateOfChange':
      return `The percentage change in the number of ${METRIC_LABELS[type]} compared to the previous time period.`
    case 'totalImpressions':
       return `The total number of impressions received by the posts published during the selected time period, associated with the campaign and label, across the chosen social accounts.
       Impressions are based on the lifetime performance of the posts.`;
    case 'totalEngagements':
      return `The total number of engagement actions generated by the posts published during the selected time period, associated with the campaign and label, across the chosen social accounts.
      Engagement includes actions such as likes, comments, shares, and other interactions with the posts, based on their lifetime performance.`
    default:
      return ``
  }
}

const convertLabelsAndCampaignsObject = (data) => {
  const returnObject = {}
  const keys = Object.keys(data)
  keys.forEach((key) => {
    data[key].forEach((item) => {
      returnObject[item._id] = {
        name: item.name,
        type: key,
        color: item?.color ?? '',
      }
    })
  })
  return returnObject
}

const transformBreakdownData = (data) => {

  const transformedData = []
  const keys = [...selectedCampaignsAndLabels.value.labels, ...selectedCampaignsAndLabels.value.campaigns]
  const sumTotalPosts = keys.reduce((acc, key) => {
    if (!data[key]) {
      return acc
    }
    return acc + (data[key]?.current?.[0]?.total_posts || 0)
  }, 0)

  keys.forEach((key) => {
    if(!data[key]) {
      transformedData.push({
        id: key,
        name: labelCampaignByKeys.value[key]?.name,
        type: labelCampaignByKeys.value[key]?.type,
        color: labelCampaignByKeys.value[key]?.color,
        totalPost: 0,
        previousTotalPost: 0,
        rateOfChange: 0,
        distributionPercentage: 0,
        chartApiKey: TABLE_CHART_API_KEYS.POST,
        dataType: 'posts',
      })
      return;
    }
    const current = data[key]?.current?.[0]
    const previous = data[key]?.previous?.[0]
    const totalPost = current?.total_posts || 0
    const previousTotalPost = previous?.total_posts || 0
    const divisor = (previousTotalPost === 0) ? 1 : previousTotalPost

    const distributionPercentage = ((totalPost / sumTotalPosts) * 100) || 0

    transformedData.push({
      id: key,
      name: labelCampaignByKeys.value[key]?.name,
      type: labelCampaignByKeys.value[key]?.type,
      color: labelCampaignByKeys.value[key]?.color,
      totalPost,
      previousTotalPost,
      rateOfChange: formatNumber(
        ((totalPost - previousTotalPost) / divisor) * 100
      ),
      distributionPercentage : (distributionPercentage % 1 === 0) ? distributionPercentage : distributionPercentage.toFixed(2),
      chartApiKey: TABLE_CHART_API_KEYS.POST,
      dataType: 'posts',
    })
  })
  return transformedData
}

const transformBreakdownDatabyImpression = (data) => {

  const transformedData = []
  const keys = [...selectedCampaignsAndLabels.value.labels, ...selectedCampaignsAndLabels.value.campaigns]

  const sumTotalImpressions = keys.reduce((acc, key) => {
    if (!data[key]) {
      return acc
    }
    return acc + (data[key]?.current?.[0]?.total_impressions || 0)
  }, 0)
  keys.forEach((key) => {
    if(!data[key]) {
      transformedData.push({
        id: key,
        name: labelCampaignByKeys.value[key]?.name,
        type: labelCampaignByKeys.value[key]?.type,
        color: labelCampaignByKeys.value[key]?.color,
        totalImpressions: 0,
        previousTotalImpressions: 0,
        rateOfChange: 0,
        distributionPercentage: 0,
        chartApiKey: TABLE_CHART_API_KEYS.IMPRESSION,
        dataType: 'impressions',
      })
      return;
    }

    const current = data[key]?.current?.[0]
    const previous = data[key]?.previous?.[0]
    const totalImpressions = current?.total_impressions || 0
    const previousTotalImpressions = previous?.total_impressions || 0
    const divisor = (previousTotalImpressions === 0) ? 1 : previousTotalImpressions
    const distributionPercentage = ((totalImpressions / sumTotalImpressions) * 100) || 0

    transformedData.push({
      id: key,
      name: labelCampaignByKeys.value[key]?.name,
      type: labelCampaignByKeys.value[key]?.type,
      color: labelCampaignByKeys.value[key]?.color,
      totalImpressions,
      previousTotalImpressions,
      distributionPercentage: (distributionPercentage % 1 === 0) ? distributionPercentage : distributionPercentage.toFixed(2),
      rateOfChange: formatNumber(
        ((totalImpressions - previousTotalImpressions) / divisor) * 100
      ),
      chartApiKey: TABLE_CHART_API_KEYS.IMPRESSION,
      dataType: 'impressions',
    })
  })
  return transformedData
}

const transformBreakdownDatabyEngagements = (data) => {

  const transformedData = []
  const keys = [...selectedCampaignsAndLabels.value.labels, ...selectedCampaignsAndLabels.value.campaigns]

  const sumTotalEngagements = keys.reduce((acc, key) => {
    if (!data[key]) {
      return acc
    }
    return acc + (data[key]?.current?.[0]?.total_engagement || 0)
  }, 0)
  keys.forEach((key) => {
    if(!data[key]) {
      transformedData.push({
        id: key,
        name: labelCampaignByKeys.value[key]?.name,
        type: labelCampaignByKeys.value[key]?.type,
        color: labelCampaignByKeys.value[key]?.color,
        totalEngagements: 0,
        previousTotalEngagements: 0,
        rateOfChange: 0,
        distributionPercentage: 0,
        chartApiKey: TABLE_CHART_API_KEYS.ENGAGEMENT,
        dataType: 'engagements',
      })
      return;
    }

    const current = data[key]?.current?.[0]
    const previous = data[key]?.previous?.[0]
    const totalEngagements = current?.total_engagement || 0
    const previousTotalEngagements = previous?.total_engagement || 0
    const divisor = previousTotalEngagements === 0 ? 1 : previousTotalEngagements
    const distributionPercentage = ((totalEngagements / sumTotalEngagements) * 100) || 0

    transformedData.push({
      id: key,
      name: labelCampaignByKeys.value[key]?.name,
      type: labelCampaignByKeys.value[key]?.type,
      color: labelCampaignByKeys.value[key]?.color,
      totalEngagements,
      previousTotalEngagements,
      rateOfChange: formatNumber(
        ((totalEngagements - previousTotalEngagements) / divisor) * 100
      ),
      distributionPercentage: (distributionPercentage % 1 === 0) ? distributionPercentage : distributionPercentage.toFixed(2),
      chartApiKey: TABLE_CHART_API_KEYS.ENGAGEMENT,
      dataType: 'engagements',
    })
  })
  return transformedData
}

const getChartData = (key = '') => {
  const data = isNoLabelOrCampaignSelected.value ? SAMPLE_CHART_DATA : insightsGraphBreakDown.value

  const graphOptions = []
  const keys = Object.keys(data)

  const dataKey = `total_${key}`

  keys.forEach((key) => {
    graphOptions.push({
      name: `${labelCampaignByKeys.value[key]?.name}${ANALYTICS_DELIMITER}${key}`,
      yAxisIndex: 0,
      type: 'line',
      data: data[key][dataKey] || [],
      color: LABELS_COLOR_MAP[labelCampaignByKeys.value[key]?.color],
      areaStyle: { opacity: 0.3, cursor: 'auto'},
      lineStyle: { opacity : 0.6 },
      cursor: 'auto',
      showSymbol: true,
      symbolSize: 8,
      colorBy: 'series'
    })
  })

  const returnObject = {
    [key]: graphOptions,
    buckets: data[keys[0]]?.buckets || [],
  }

  return returnObject
}

const transformedInsightData = (data) => {

  const selectedKeys = [...selectedCampaignsAndLabels.value.labels, ...selectedCampaignsAndLabels.value.campaigns]
  const graphOptions = {}

  selectedKeys.forEach((key) => {
    const current = data?.[key]?.[0]
    if(!current) {
      const defaultDates = Object.keys(dateValueMap.value)
      const defaultValues = Object.values(dateValueMap.value)
      graphOptions[key] = {
        total_posts: defaultValues,
        total_impressions: defaultValues,
        total_engagement: defaultValues,
        buckets: defaultDates,
      }
      return;
    }
    const totalPosts = {... dateValueMap.value};
    const totalImpressions = {... dateValueMap.value};
    const totalEngagements = {... dateValueMap.value};

    current?.created_at?.forEach((date, index) => {
      totalPosts[date] = current?.total_posts[index];
      totalImpressions[date] = current?.total_impressions[index];
      totalEngagements[date] = current?.total_engagement[index];
    })
    graphOptions[key] = {
      total_posts : Object.values(totalPosts) || [],
      total_impressions: Object.values(totalImpressions) || [],
      total_engagement: Object.values(totalEngagements) || [],
      buckets: Object.keys(dateValueMap.value) || [],
    }
  })

  return graphOptions
}
const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

/**
 * @description checks if no label or campaign is selected, when all labels and campaigns are present
 * @type {ComputedRef<boolean>}
 * @returns {boolean}
 */
const isNoLabelOrCampaignSelected = computed(() => {
  return (
    (
      !!allCampaignsAndLabels.value.campaigns.length ||
      !!allCampaignsAndLabels.value.labels.length
    ) &&
    selectedCampaignsAndLabels.value.campaigns.length === 0 &&
    selectedCampaignsAndLabels.value.labels.length === 0
  )
})

const getAnalyticsPreference = computed(() => {
  return getPreferences.value[WORKSPACE_PREFERENCE_KEY] || {};
})

const dateValueMap = computed(() => {
  const dateObj = {};
  const currentDate = new Date(dateRange.value?.[0]);
  const end = new Date(dateRange.value?.[1]);
  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= end) {
    dateObj[currentDate.toISOString().split('T')[0]] = 0;
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateObj;
})

const setIsloading = (type, value) => {
  isLoadingStates.value[type] = value
}

const updatePreferences = (type = '') => {
  const payload = {
    ...(getPreferences.value[WORKSPACE_PREFERENCE_KEY] || {}),
    facebook_accounts: [],
    instagram_accounts: [],
    linkedin_accounts: [],
    tiktok_accounts: [],
    youtube_accounts: [],
    pinterest_accounts: [],
    twitter_accounts: [],
  }
  switch (type) {
    case PREFERNCES_TYPES.date:
      updateWorkspacePreferences( WORKSPACE_PREFERENCE_KEY , {
        ...(getPreferences.value[WORKSPACE_PREFERENCE_KEY] || {}),
        date: getFormattedDateRange(),
      })
      break
    case PREFERNCES_TYPES.campaigns_and_labels:
      updateWorkspacePreferences( WORKSPACE_PREFERENCE_KEY , {
        ...(getPreferences.value[WORKSPACE_PREFERENCE_KEY] || {}),
        campaigns: selectedCampaignsAndLabels.value.campaigns,
        labels: selectedCampaignsAndLabels.value.labels,
      })
      break
    case PREFERNCES_TYPES.accounts:
      selectedAccount.value?.forEach((account) => {
        payload[`${account?.account_type}_accounts`]?.push(
          getAccountId(account)
        )
      })
      updateWorkspacePreferences( WORKSPACE_PREFERENCE_KEY, payload)
      break
    default:
      break
  }
}

export default function useLabelAndCampaignAnalytics(defaultLabel = 'Data') {
  const { getters } = useStore()
  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASIC_ENDPOINT,
    LABEL_AND_CAMPAIGNS_BASE_ENDPOINT,
    lineChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
  } = useAnalytics(defaultLabel)


  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      // Check if there's an ongoing request of the same type and abort it

      if (ongoingRequests.has(type)) {
        ongoingRequests.get(type).abort();
      }

      // Create a new AbortController for the new request
      const controller = new AbortController();
      const { signal } = controller;
      ongoingRequests.set(type, controller);

      try {
        setIsloading(type, true);
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          date: getFormattedDateRange(),
          timezone: getters.getActiveWorkspace.timezone,
          ...extraPayload,
        };

        let data;
        if (type === routes.ALL_LABELS_AND_CAMPAIGNS) {
          const { data: resData } = await proxy.post(
            BASIC_ENDPOINT + type,
            payload,
            { signal }
          );
          data = resData;
        } else {
          Object.assign(payload, {
            campaigns: selectedCampaignsAndLabels.value.campaigns,
            labels: selectedCampaignsAndLabels.value.labels,
            include_all_accounts: false,
            facebook_accounts: [],
            instagram_accounts: [],
            linkedin_accounts: [],
            tiktok_accounts: [],
            youtube_accounts: [],
            pinterest_accounts: [],
            twitter_accounts: [],
          });

          selectedAccount.value?.forEach((account) => {
            payload[`${account?.account_type}_accounts`]?.push(
              getAccountId(account)
            );
          });

          const { data: resData } = await proxy.post(
            LABEL_AND_CAMPAIGNS_BASE_ENDPOINT + type,
            payload,
            { signal }
          );
          data = resData;
        }

        // Handle the data based on the type
        switch (type) {
          case routes.ALL_LABELS_AND_CAMPAIGNS:
            allCampaignsAndLabels.value = data;
            labelCampaignByKeys.value = convertLabelsAndCampaignsObject(data);
            break;
          case routes.SUMMARY_ANALYTICS:
            cardsData.value = data;
            break;
          case routes.BREAKDOWN_DATA:
            breakdownDataByLabel.value = transformBreakdownData(data);
            breakdownDataByImpression.value = transformBreakdownDatabyImpression(data);
            breakdownDataByEngagement.value = transformBreakdownDatabyEngagements(data);
            break;
          case routes.INSIGHTS_GRAPH_BREAKDOWN:
            insightsGraphBreakDown.value = transformedInsightData(data);
            break;
          default:
            break;
        }
      } catch (e) {
        if (e.name === 'CanceledError') {
          console.log(`Request for ${type} was aborted`);
          return;
        } else {
          console.error('FETCH DATA:::', e);
        }
      }
        setIsloading(type, false);
        // Clean up the ongoing request map
        ongoingRequests.delete(type);

    }
  };


  return {
    LABELS_COLOR_MAP,
    ANALYTICS_DELIMITER,
    IGNORE_ACCOUNTS,
    SAMPLE_SUMMARY_DATA,
    SAMPLE_BREAKDOWN_DATA,
    SAMPLE_CHART_DATA,
    SAMPLE_BREAKDOWN_DATA_BY_IMPRESSION,
    SAMPLE_BREAKDOWN_DATA_BY_ENGAGEMENT,
    PREFERNCES_TYPES,
    analyticsDesignSystem,
    // variables
    routes,
    legendOptions,
    dataZoomOptions,
    defaultChartOptions,

    // state
    selectedAccount,
    isAllAccountsSelected,
    allCampaignsAndLabels,
    labelCampaignByKeys,
    selectedCampaignsAndLabels,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    TopLeastEngagementDropdown,
    selectedTopLeastSortType,
    isReportView,
    screenWidth,
    breakdownDataByLabel,
    breakdownDataByImpression,
    breakdownDataByEngagement,
    insightsGraphBreakDown,
    isLoadingStates,
    isNoLabelOrCampaignSelected,
    getAnalyticsPreference,

    cardsData,
    // methods
    fetchMedia,
    getCardData,
    isNoAnalyticsData,
    axisLabelFormatter,
    getHeaderTitles,
    getComponentInfoTooltip,
    getTableHeaderTooltips,
    transformBreakdownData,
    getTopLeastEngagingPayload,
    getChartData,
    tooltipFormatterScroll,
    setIsloading,
    getDetailedPulseInfoTooltip,
    getDetailedPulseChartTitle,
    getPulseTooltipTitle,
    updatePreferences,
  }
}
