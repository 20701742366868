<script>
import { mapGetters, mapActions } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import ContentCategoriesSlotTime from '../../publish/components/posting/social/ContentCategoriesSlotTime'
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
export default {
  components: {
    DateRangePicker,
    ContentCategoriesSlotTime,
  },
  props: ['type', 'tooltipValue'],
  data() {
    return {
      dayIndex1: false,
      locale: {
        format: 'YYYY-MM-DD HH:mm:ss', // fomart of the dates displayed
      },
      opens: 'center',
      startDate: '2017-09-19',
      endDate: '2017-10-09',
      minDate: '2016-09-02',
      maxDate: '2019-10-02',
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: false,
      showWeekNumbers: false,
      queueOptions: [
        'ArticleBlogAutomation',
        'VideoBlogAutomation',
        'evergreen',
      ],
    }
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['getAutomationScheduleOptions', 'getEvergreenSelection']),
    getStartMinDate() {
      let minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      minDate = minDate.format('YYYY-MM-DDTHH:mm:ss')
      if (!this.getAutomationScheduleOptions.start_time) {
        this.setAutomationStartTime(minDate)
      }
      return minDate
    },
    getStartDate() {
      if (this.getAutomationScheduleOptions.start_time) {
        return moment(this.getAutomationScheduleOptions.start_time).format(
          'YYYY-MM-DDTHH:mm:ss'
        )
      }
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      return minDate.format('YYYY-MM-DDTHH:mm:ss')
    },
    getEndMinDate() {
      return moment(this.getAutomationScheduleOptions.start_time).format(
        'YYYY-MM-DDTHH:mm:ss'
      )
    },
    getEndStartDate() {
      if (this.getAutomationScheduleOptions.end_time) {
        return moment(this.getAutomationScheduleOptions.end_time).format(
          'YYYY-MM-DDTHH:mm:ss'
        )
      }
      const endDate = moment(
        this.getAutomationScheduleOptions.start_time,
        'YYYY-MM-DDTHH:mm:ss'
      ).add(2, 'days')
      this.setAutomationEndTime(endDate.format('YYYY-MM-DDTHH:mm:ss'))
      return endDate.format('YYYY-MM-DDTHH:mm:ss')
    },
    showQueueOption() {
      return !this.queueOptions.includes(this.type)
    },
  },
  methods: {
    ...mapActions([
      'setRecurringValue',
      'setAutomationScheduleSlots',
      'setAutomationEndTime',
      'setAutomationStartTime',
    ]),
    updateStartValues(values) {
      const myDate = moment(values.startDate)
      this.setAutomationStartTime(myDate.format('YYYY-MM-DDTHH:mm:ss'))
      const endDate = moment(myDate, 'YYYY-MM-DDTHH:mm:ss').add(2, 'days')
      this.setAutomationEndTime(endDate.format('YYYY-MM-DDTHH:mm:ss'))
      this.scheduleOptionsChanges()
    },
    updateEndValues(values) {
      const myDate = moment(values.startDate)
      this.setAutomationEndTime(myDate.format('YYYY-MM-DDTHH:mm:ss'))
      this.scheduleOptionsChanges()
    },
    recurringTypeChange() {
      console.debug('Method:recurringTypeChange')
      this.setRecurringValue(1)
      this.scheduleOptionsChanges()
    },
    clearAllAutomationScheduleSlots() {
      const slots = this.getAutomationScheduleOptions.custom.days
      for (let i = 0; i < slots.length; i++) {
        slots[i].status = false
        slots[i].times = []
      }
      this.setAutomationScheduleSlots(slots)
      this.scheduleOptionsChanges()
    },
    removeAutomationScheduleSlot(slotIndex, timeIndex) {
      const slots = this.getAutomationScheduleOptions.custom.days
      slots[slotIndex].times.splice(timeIndex, 1)
      this.setAutomationScheduleSlots(slots)
      this.scheduleOptionsChanges()
    },
    addAutomationScheduleSlot(slotIndex) {
      const slots = this.getAutomationScheduleOptions.custom.days
      slots[slotIndex].times.push('12:00')
      this.setAutomationScheduleSlots(slots)
      this.scheduleOptionsChanges()
    },
    scheduleOptionsChanges() {
      console.debug('Method::scheduleOptionsChanges')
      this.$store.dispatch('fetchAutomationNextPosting', this.$route.name)
    },
  },
  watch: {
    // eslint-disable-next-line camelcase
    'getAutomationScheduleOptions.schedule_type'(schedule_type) {
      // eslint-disable-next-line camelcase
      if (schedule_type === 'fix') {
        this.showAutomationDatePickers()
      }
    },
    'getAutomationScheduleOptions.time_type'(value) {
      if (value === 'content_category' && this.type === 'evergreen') {
        const categoryId = this.getEvergreenSelection.content_category_id
        if (categoryId && categoryId.length > 3) {
          this.$store.dispatch('nextAvailableSlot', categoryId)
        }
      } else if (value === 'content_category' && this.type === 'csv') {
        const categoryId = this.getCsvAutomationSelection.content_category_id
        if (categoryId && categoryId.length > 3) {
          this.$store.dispatch('nextAvailableSlot', categoryId)
        }
      }
    },
    'getAutomationScheduleOptions.recurring.value': function (newVal, oldVal) {
      if (
        this.getAutomationScheduleOptions.recurring.type === 'Hour' &&
        newVal > 24
      ) {
        this.getAutomationScheduleOptions.recurring.value = oldVal
      } else if (
        this.getAutomationScheduleOptions.recurring.type === 'Day' &&
        newVal > 30
      ) {
        this.getAutomationScheduleOptions.recurring.value = oldVal
      } else if (
        this.getAutomationScheduleOptions.recurring.type === 'Week' &&
        newVal > 12
      ) {
        this.getAutomationScheduleOptions.recurring.value = oldVal
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="input_field">
      <label>Schedule time</label>
      <div class="radio_list">
        <div class="radio_input_image">
          <input
            id="schedule_custom"
            v-model="getAutomationScheduleOptions.time_type"
            type="radio"
            value="Custom"
            name="schedule_time"
            @change="scheduleOptionsChanges"
          />
          <label
            v-tooltip.top="tooltipValue"
            for="schedule_custom"
            class="radio_left"
          >
            <template v-if="type === 'csv'"
              >Custom time-slots from CSV file</template
            >
            <template v-else>Custom time-slots</template>
          </label>
        </div>
        <div class="radio_input_image">
          <input
            id="schedule_recurring"
            v-model="getAutomationScheduleOptions.time_type"
            value="Recurring"
            type="radio"
            name="schedule_time"
            @change="scheduleOptionsChanges"
          />
          <label
            v-tooltip.top="'Post with a fixed interval'"
            for="schedule_recurring"
            class="radio_left"
            >Regular Intervals</label
          >
        </div>

        <div
          v-if="type === 'evergreen' || type === 'csv'"
          class="radio_input_image"
        >
          <input
            id="content_category"
            v-model="getAutomationScheduleOptions.time_type"
            :disabled="
              (type === 'evergreen' &&
                !getEvergreenSelection.content_category_id) ||
              (type === 'csv' && !getCsvAutomationSelection.content_category_id)
            "
            type="radio"
            value="content_category"
            name="postingType"
            @change="scheduleOptionsChanges"
          />
          <label for="content_category" class="radio_left"
            >Add to Content Category</label
          >

          <i
            v-if="
              (type === 'evergreen' &&
                !getEvergreenSelection.content_category_id) ||
              (type === 'csv' && !getCsvAutomationSelection.content_category_id)
            "
            v-tooltip="
              'Please select content category from first step to add content to the category'
            "
            class="far fa-question-circle cursor_pointer m-2"
            aria-hidden="true"
          ></i>
          <a
            v-else
            v-tooltip.top="'Learn more about Content Category'"
            href="https://docs.contentstudio.io/article/692-what-are-the-queues-in-content-categories"
            target="_blank"
            ><i class="far fa-question-circle ml-1"></i
          ></a>
        </div>
        <!-- v-if="type!=='ArticleBlogAutomation' && type!=='VideoBlogAutomation'"-->
        <div v-if="showQueueOption" class="radio_input_image">
          <input
            id="schedule_queue"
            v-model="getAutomationScheduleOptions.time_type"
            type="radio"
            value="Queue"
            name="schedule_time"
            @change="scheduleOptionsChanges"
          />
          <label
            v-tooltip.top="'Add posts in the pre-defined queue slots'"
            for="schedule_queue"
            class="radio_left"
            >Queue</label
          >
          <a
            v-tooltip.top="'Learn more about queue slots'"
            href="https://docs.contentstudio.io/article/691-what-are-social-queues"
            target="_blank"
            ><i class="far fa-question-circle ml-1"></i
          ></a>
        </div>
      </div>
      <div
        v-if="
          getAutomationScheduleOptions.time_type === 'Recurring' &&
          type === 'evergreen'
        "
        class="recurring_block"
      >
        <div class="d-flex align-items-center">
          <p class="text">Post Every</p>
          <div
            v-if="getAutomationScheduleOptions.recurring.type === 'Hour'"
            class="field_group mt-4"
          >
            <input
              v-model="getAutomationScheduleOptions.recurring.value"
              class="inline w-auto"
              type="number"
              min="1"
              max="24"
              onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
            />
          </div>
          <div
            v-if="getAutomationScheduleOptions.recurring.type === 'Day'"
            class="field_group mt-4"
          >
            <input
              v-model="getAutomationScheduleOptions.recurring.value"
              class="inline w-auto"
              type="number"
              min="1"
              max="30"
              onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
            />
          </div>
          <div
            v-if="getAutomationScheduleOptions.recurring.type === 'Week'"
            class="field_group mt-4"
          >
            <input
              v-model="getAutomationScheduleOptions.recurring.value"
              class="inline w-auto"
              type="number"
              min="1"
              max="12"
              onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
            />
          </div>
          <div class="field_group d-inline-block">
            <select
              v-model="getAutomationScheduleOptions.recurring.type"
              class="ml-2 select_sm"
              @change="recurringTypeChange"
            >
              <option value="Hour">Hour(s)</option>
              <option value="Day">Day(s)</option>
              <option value="Week">Week(s)</option>
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>
        </div>
      </div>
      <div
        v-else-if="getAutomationScheduleOptions.time_type === 'Recurring'"
        class="recurring_block"
      >
        <div class="d-flex align-items-center">
          <p class="text">Post Every</p>

          <div
            v-if="getAutomationScheduleOptions.recurring.type === 'Hour'"
            class="with_right_icon"
          >
            <select
              v-model="getAutomationScheduleOptions.recurring.value"
              class="inline w-auto"
              @change="scheduleOptionsChanges"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
            </select>
            <i class="right_icon fa fa-caret-down"></i>
          </div>
          <div class="with_right_icon">
            <select
              v-if="getAutomationScheduleOptions.recurring.type === 'Day'"
              v-model="getAutomationScheduleOptions.recurring.value"
              class="inline w-auto"
              @change="scheduleOptionsChanges"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>

            <select
              v-if="getAutomationScheduleOptions.recurring.type === 'Week'"
              v-model="getAutomationScheduleOptions.recurring.value"
              class="inline w-auto"
              @change="scheduleOptionsChanges"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <i class="right_icon fa fa-caret-down"></i>
          </div>
          <div class="with_right_icon">
            <select
              v-model="getAutomationScheduleOptions.recurring.type"
              class="ml-2 inline w-auto"
              @change="recurringTypeChange"
            >
              <option value="Hour">Hour(s)</option>
              <option value="Day">Day(s)</option>
              <option value="Week">Week(s)</option>
            </select>
            <i class="right_icon fa fa-caret-down"></i>
          </div>
        </div>
      </div>

      <div
        v-if="
          getAutomationScheduleOptions.time_type === 'Custom' && type !== 'csv'
        "
        class="custom_block"
      >
        <div class="time_table_main_top">
          <div class="time_table d-flex align-items-start">
            <div
              v-for="(day, dayIndex) in getAutomationScheduleOptions.custom
                .days"
              :key="dayIndex"
              class="time_box_main"
            >
              <div class="box_inner">
                <div class="day_box">
                  <input
                    :id="dayIndex"
                    v-model="
                      getAutomationScheduleOptions.custom.days[dayIndex].status
                    "
                    type="checkbox"
                    @change="scheduleOptionsChanges"
                  />
                  <label :for="dayIndex">{{ day.name }}</label>
                </div>

                <div
                  v-for="(time, timeIndex) in day.times"
                  :key="timeIndex"
                  class="time_list"
                >
                  <div
                    class="time_box"
                    :class="{
                      disabled:
                        !getAutomationScheduleOptions.custom.days[dayIndex]
                          .status,
                    }"
                  >
                    <input
                      v-model="
                        getAutomationScheduleOptions.custom.days[dayIndex]
                          .times[timeIndex]
                      "
                      type="text"
                      @change="scheduleOptionsChanges"
                    />
                    <span
                      class="
                        cross_icon_red
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                      @click="removeAutomationScheduleSlot(dayIndex, timeIndex)"
                    >
                      <i class="cs-cross"></i>
                    </span>
                  </div>
                </div>

                <div
                  class="
                    add_time_box add_icon
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  @click="addAutomationScheduleSlot(dayIndex)"
                >
                  <i class="cs-plus"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center time_bottom">
            <span class="clear_all_btn" @click="clearAllAutomationScheduleSlots"
              >Clear all</span
            >
            <span class="timezone_box ml-auto">
              Timezone: {{ getWorkspaces.activeWorkspace.timezone }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <transition
      v-if="type === 'evergreen' || type === 'csv'"
      name="slide-bottom"
    >
      <template
        v-if="getAutomationScheduleOptions.time_type === 'content_category'"
      >
        <ContentCategoriesSlotTime
          :type="type"
        ></ContentCategoriesSlotTime>
      </template>
    </transition>

    <div v-if="type !== 'csv'" class="input_field">
      <label>Schedule options</label>
      <div class="radio_list">
        <div class="radio_input_image">
          <input
            id="schedule_continuously"
            v-model="getAutomationScheduleOptions.schedule_type"
            value="continuously"
            type="radio"
            name="schedule_type"
            @change="scheduleOptionsChanges"
          />
          <label for="schedule_continuously" class="radio_left"
            >Run this campaign continuously starting today</label
          >
        </div>
        <div class="radio_input_image">
          <input
            id="schedule_fix"
            v-model="getAutomationScheduleOptions.schedule_type"
            type="radio"
            value="fix"
            name="schedule_type"
            @change="scheduleOptionsChanges"
          />
          <label for="schedule_fix" class="radio_left"
            >Set a start and end date/time</label
          >
        </div>
      </div>
      <div
        v-if="getAutomationScheduleOptions.schedule_type === 'fix'"
        class="recurring_block extra_options mt-3 mb-3"
      >
        <div class="d-flex align-items-center">
          <div class="ml-0 automation_start_time datepicker_style">
            <!--'parentEl': '.schedule_datepicker',-->
            <!--<input id="automation_start_time" type="text"  name="daterange" placeholder="Select end time..."-->
            <!--readonly v-model="getAutomationScheduleOptions.start_time">-->

            <DateRangePicker
              v-tooltip.top="'Start date/time'"
              class="datepicker_input"
              :opens="opens"
              :start-date="getStartDate"
              :locale-data="locale"
              :min-date="getStartMinDate"
              :single-date-picker="singleDatePicker"
              :time-picker="timePicker"
              :time-picker24hour="timePicker24Hour"
              :show-week-numbers="showWeekNumbers"
              :show-dropdowns="showDropdowns"
              :auto-apply="autoApply"
              :ranges="show_ranges ? undefined : false"
              @update="updateStartValues"
            >
              <template #input="{ startDate }">
                <div class="datepicker_content with_bg" style="min-width: 250px">
                  <i class="cs-calendar-fill"></i>
                  <span>{{ $filters.date(startDate) }}</span>
                </div>
              </template>
            </DateRangePicker>
          </div>
          <div class="automation_end_time datepicker_style">
            <!--<input id="automation_end_time" type="text" name="daterange"  placeholder="Select end time..."-->
            <!--readonly v-model="getAutomationScheduleOptions.end_time">-->

            <DateRangePicker
              v-tooltip.top="'End date/time'"
              class="datepicker_input"
              :opens="opens"
              :start-date="getEndStartDate"
              :locale-data="locale"
              :min-date="getEndMinDate"
              :single-date-picker="singleDatePicker"
              :time-picker="timePicker"
              :time-picker24hour="timePicker24Hour"
              :show-week-numbers="showWeekNumbers"
              :show-dropdowns="showDropdowns"
              :auto-apply="autoApply"
              :ranges="show_ranges ? undefined : false"
              @update="updateEndValues"
            >
              <template #input="{ startDate }">
                <div
                    class="datepicker_content with_bg"
                    style="min-width: 250px"
                >
                  <i class="cs-calendar-fill"></i>
                  <span>{{ $filters.date(startDate) }}</span>
                </div>
              </template>
            </DateRangePicker>

            <!--&gt;-->
            <!--<div slot="input" class="with_bg publish_time_datepicker" slot-scope="picker" style="min-width: 250px;">-->
            <!--<i class="icon-Search"></i> {{ $filters.date(picker.startDate) }}-->
            <!--</div>-->
            <!--</date-range-picker>-->
          </div>
          <p
            v-if="
              getAutomationScheduleOptions.start_time &&
              getAutomationScheduleOptions.end_time
            "
            style="min-width: 55px; white-space: nowrap; margin-left: 10px"
            >{{
              dateDifferenceInDays(
                getAutomationScheduleOptions.start_time,
                getAutomationScheduleOptions.end_time,
              )
            }}
            Day(s)</p
          >
        </div>
      </div>
    </div>

    <div v-if="type === 'evergreen'" class="input_field">
      <div
        :class="{ disabled: !getEvergreenSelection.cycleGapStatus }"
        class="recurring_block_evergreen recurring_block extra_options"
      >
        <div class="d-flex align-items-center">
          <div class="checkbox_input_image">
            <input
              id="c_gap"
              v-model="getEvergreenSelection.cycleGapStatus"
              type="checkbox"
              name="c_gap"
            />
            <label for="c_gap" class="checkbox_left">Add a cycle gap of</label>
          </div>
          <input
            v-model="getEvergreenSelection.cycleGap"
            :class="{ disabled: !getEvergreenSelection.cycleGapStatus }"
            :disabled="!getEvergreenSelection.cycleGapStatus"
            type="number"
            min="1"
            max="400"
            placeholder="Give number of days to add gap."
          />
          <p
            :class="{ disabled: !getEvergreenSelection.cycleGapStatus }"
            class="last_text"
            >day(s) between 2 cycles</p
          >

          <!--isHover=true if to show on hover-->
          <custom-tooltip
            class="evergreen_cycle_tooltip"
            :width="350"
            :is-hover="true"
          >
            <div slot="iconHtml">
              <i class="far fa-question-circle"></i>
            </div>
            <div slot="htmlContent" class="guide_tooltip">
              <p
                >No content will be shared for the selected period between two
                cycles. And one cycle is done when share of all posts/variations
                in the evergreen campaign is completed.</p
              >
            </div>
          </custom-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
