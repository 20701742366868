<script setup>
import { computed, watch } from 'vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useNumber from '@common/composables/useNumber'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'

const props = defineProps({
  label: {
    type: String,
    default: 'Watch Time',
  },
})

const {
  barChartOptions,
  legendOptions,
  channelWatchTimeData,
  isReportView,
  dataZoomOptions,
  SINGLE_GRAPH_TOOLTIPS,
  analyticsDesignSystem,
} = useYoutubeAnalytics(props.label)

const { roundNumber } = useNumber()

const chartTypes = [
  {
    name: 'Subscribers',
    api_response_key: {
      daily: 'subscriber_watch_time_daily',
      total: 'subscriber_watch_time_total',
    },
    color: '#8AC1A2',
  },
  {
    name: 'Non-Subscribers',
    api_response_key: {
      daily: 'non_subscriber_watch_time_daily',
      total: 'non_subscriber_watch_time_total',
    },
    color: '#61BAE4',
  },
]

const averageWatchTimeKey = 'average_watch_time'

const noDataFound = computed(() => {
  return !channelWatchTimeData.value?.buckets?.length
})

watch(
  () => channelWatchTimeData,
  () => {
    barChartOptions.value.series = []
    barChartOptions.value.legend = {
      ...legendOptions,
      data: chartTypes?.map((chartType) => chartType.name),
    }

    barChartOptions.value.tooltip = {
      ...barChartOptions.value.tooltip,
      formatter: (series) =>
        tooltipFormatter(
          series,
          channelWatchTimeData.value[averageWatchTimeKey]
        ),
    }

    const xAxisData = channelWatchTimeData.value?.buckets

    // bar and line chart options
    chartTypes.forEach((chartType) => {
      const apiKey = chartType?.api_response_key

      const seriesStack = {
        stack: 'total',
      }

      let barSeries = {
        type: 'bar',
        color: chartType.color,
        data: channelWatchTimeData.value[apiKey.daily] || [],
        yAxisIndex: 0,
        barMaxWidth: analyticsDesignSystem?.graphs?.singleBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
        ...seriesStack,
      }

      if (
        channelWatchTimeData.value &&
        channelWatchTimeData.value[averageWatchTimeKey]
      ) {
        barSeries = {
          ...barSeries,
          markLine: {
            data: [
              {
                yAxis: channelWatchTimeData.value[averageWatchTimeKey],
                symbol: 'arrow',
                lineStyle: {
                  type: 'dashed',
                  color: '#979CA0',
                },
              },
            ],
          },
        }
      }

      barChartOptions.value.series.push({
        ...barSeries,
        name: chartType.name,
      })
    })

    barChartOptions.value.xAxis.data = xAxisData
    barChartOptions.value.yAxis = {
      ...barChartOptions.value.yAxis,
      name: `{subscribers|} {nonSubscribers|} {comments|} {shares|} {b|${props.label}}`,
      nameTextStyle: {
        rich: {
          subscribers: {
            ...analyticsDesignSystem?.graphs?.labelBoxStyle,
            backgroundColor: '#8AC1A2',
          },
          nonSubscribers: {
            ...analyticsDesignSystem?.graphs?.labelBoxStyle,
            backgroundColor: '#61BAE4',
          },
          b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
        },
      },
    }
  },
  {
    deep: true,
    immediate: true,
  }
)

const tooltipFormatter = (series, averageWatchTime = 0) => {
  const { axisValue: axisDate } = series[0]
  const date = new Date(axisDate)
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()
  let html = ''

  series.forEach((series) => {
    const { seriesName, data: value } = series

    html += `
    <div class="flex items-end justify-between mt-2.5">
      <span class="text-[979CA0] text-sm mr-1.5">${seriesName}: </span>
      <span class="text-[#69788E] text-sm font-bold">${value}</span>
    </div>`
  })

  return `
      <div class="flex flex-col">
        <div class="flex items-center">
          <span class="text-[#000D21] font-weight-300">${month} ${date.getDate()}, ${year}</span>
        </div>
        ${html}
        <div class="flex items-end justify-between mt-2.5">
          <span class="text-[979CA0] text-sm mr-1.5">Average View Duration:  </span>
          <span class="text-[#69788E] text-sm font-bold">${roundNumber(
            averageWatchTime
          )}</span>
        </div>
      </div>
  `
}
</script>

<template>
  <AnalyticsCardWrapper
    :type="`specific-type-${Math.random()}`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <h2 class="text-base font-weight-500 select-none">
            {{'Daily Watch Time (minutes)'}}
          </h2>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                {{ SINGLE_GRAPH_TOOLTIPS.WATCH_TIME }}
              </p>
            </template>
          </v-menu>
        </div>
        <div>
          <slot name="header-center" />
        </div>
        <div>
          <slot name="header-right" />
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div v-if="noDataFound" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p>No data found.</p>
      </div>
      <div v-else class="flex-1">
        <MainGraph
          :key="Math.random()"
          :chart-options="
            slotProps.isModal
              ? {
                  ...barChartOptions,
                  ...dataZoomOptions,
                  grid: { ...barChartOptions?.grid, bottom: 70 },
                }
              : barChartOptions
          "
          :is-modal="slotProps.isModal"
          type="bar"
        />
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
