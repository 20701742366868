import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@/src/modules/common/composables/useNumber'

// constants
const mediaTypes = { VIDEO: 'Video' }
const selectedAccount = ref(null)
const routes = {
  PAGE_AND_POST_INSIGHTS: 'getPageAndPostsInsights',
  PAGE_FOLLOWERS_AND_VIEWS: 'getPageFollowersAndViews',
  POSTS_AND_ENGAGEMENTS: 'getPostsAndEngagements',
  TOP_AND_LEAST_PERFORMING_POSTS: 'getTopAndLeastPerformingPosts',
  POSTS_DATA: 'getPostsData',
  DAILY_ENGAGEMENTS_DATA: 'getDailyEngagementsData',
}
const customThumbnailKey = 'coverImage'
// state
const { DEFAULT_DATE_RANGE } = useAnalytics()
const { formatNumber } = useNumber()
const dateRange = ref(DEFAULT_DATE_RANGE)
const cardsData = ref([])
const pageFollowersAndViews = ref([])
const postsAndEngagements = ref([])
const dailyAndTotalEngagements = ref([])
const topAndLeastEngagingPosts = ref({
  top_posts: [],
  least_posts: [],
})
const allPosts = ref([])

const cards = ref([
  {
    title: 'Followers',
    key: 'total_followers',
    tooltip:
      'The total number of people following your TikTok up to the end of the selected time period.',
  },
  {
    title: 'Following',
    key: 'total_followings',
    tooltip:
      'The total number of accounts you are subscribed to on TikTok up to the end of the selected time period.',
  },
  {
    title: 'Videos',
    key: 'total_posts',
    tooltip:
      'The total number of videos published on your TikTok account during the selected time period.',
  },
  {
    title: 'Video Views',
    key: 'total_video_views',
    tooltip:
      'The total number of times your videos have been watched up to the end of the selected time period, regardless of their publication date.',
  },

  {
    title: 'Engagements',
    key: 'total_engagements',
    tooltip:
      'The total number of engagement actions (likes, comments, shares) on all the video posts published during the selected time period, based on their lifetime performance.',
  },
  {
    title: 'Likes',
    key: 'total_likes',
    tooltip:
      'The total number of hearts received on all the video posts published during the selected time period, based on their lifetime performance.',
  },
  {
    title: 'Shares',
    key: 'total_shares',
    tooltip:
      'The total number of times all video posts (published during the selected time period), have been forwarded to others or shared on different platforms, based on their lifetime performance.',
  },
  {
    title: 'Comments',
    key: 'total_comments',
    tooltip:
      'The total number of comments left by viewers on all the video posts published during the selected time period, based on their lifetime performance.',
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => moment(date).format('YYYY-MM-DD'))
    .join(' - ')
}

const getCardData = (card) => {
  return {
    title: card.title,
    total: !isNaN(cardsData.value[card.key])
      ? formatNumber(+cardsData.value[card.key])
      : cardsData.value[card.key],
    growth: cardsData.value[card.key + '_growth'],
    difference: cardsData.value[card.key + '_diff'],
    tooltip: card.tooltip,
    originalValue: cardsData.value[card.key],
  }
}

const canFetchMedia = computed(() => {
  return !!(selectedAccount.value !== null && dateRange.value.length > 0)
})

const topEngagingPosts = computed(
  () => topAndLeastEngagingPosts.value.top_posts
)
const hasTopEngagingPosts = computed(() => topEngagingPosts.value.length > 0)

const leastEngagingPosts = computed(
  () => topAndLeastEngagingPosts.value.least_posts
)
const hasLeastEngagingPosts = computed(
  () => leastEngagingPosts.value.length > 0
)

const totalAccountPosts = computed(() =>
  allPosts.value.length > 0 ? allPosts.value[0]?.total || 0 : 0
)

const postsLimit = ref(10)
const headersTooltips = {
  engagements:
    'The total number of engagement actions (likes, comments, shares) on all the video posts published during the selected time period, based on their lifetime performance.',
  likes:
    'The total number of hearts received on all the video posts published during the selected time period, based on their lifetime performance.',
  comments:
    'The total number of comments left by viewers on all the video posts published during the selected time period, based on their lifetime performance.',
  shares:
    'The total number of times all video posts (published during the selected time period), have been forwarded to others or shared on different platforms, based on their lifetime performance.',
  views:
    'The total number of times your videos have been watched up to the end of the selected time period, regardless of their publication date.',
}
const nonSortableItems = ['coverImage', 'description']
// object mapping of header values to their corresponding titles
const headerTitles = {
  coverImage: 'Posts',
  engagement: 'Engagements',
  likes: 'Likes',
  comments: 'Comments',
  shares: 'Shares',
  views: 'Views',
}
// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  coverImage: (value) => value,
  engagement: (value) => formatNumber(value),
  likes: (value) => formatNumber(value),
  comments: (value) => formatNumber(value),
  shares: (value) => formatNumber(value),
  views: (value) => formatNumber(value),
}
// object mapping of header api keys to their corresponding titles
const validPostsTableApiKeys = {
  engagement: 'total_engagement',
  likes: 'likes_count',
  comments: 'comments_count',
  shares: 'shares_count',
  views: 'views_count',
}

const sortingFields = {
  ENGAGEMENT: 'total_engagement',
  LIKES: 'likes',
  COMMENTS: 'comments',
  SHARES: 'shares',
  VIEWS: 'views',
}

const sortingOrder = {
  DESC: 'desc',
  ASC: 'asc',
}

const DUAL_GRAPHS = {
  ENGAGEMENT: 'ENGAGEMENT',
  VIDEO: 'VIDEO',
  FOLLOWER: 'FOLLOWER',
}

const validPostsTableHeaders = [
  'coverImage',
  'engagement',
  'likes',
  'comments',
  'shares',
  'views',
]

const DUAL_GRAPHS_TITLE_AND_TOOLTIPS = {
  ENGAGEMENT: {
    daily: {
      title: 'Engagement Activity',
      tooltip:
        'Inspect the day-to-day engagement metrics (likes, comments, and shares) received each day during the selected time period, regardless of the post publication date.',
    },
    cumulative: {
      title: 'Engagement Trends',
      tooltip:
        'Examine the overall growth in engagement metrics (likes, comments, and shares) throughout the selected time period, aggregating daily changes to show a running total, regardless of the post publication date.',
    },
  },
  VIDEO: {
    daily: {
      title: 'Video Views',
      tooltip:
        'Analyze the day-to-day video views received each day during the selected time period, regardless of the post publication date.',
    },
    cumulative: {
      title: 'Video Views over Time',
      tooltip:
        'Track the cumulative count of video views throughout the selected time period, aggregating daily changes to show a running total, regardless of the post publication date.',
    },
  },
  FOLLOWER: {
    daily: {
      title: 'Followers Change',
      tooltip:
        'Inspect the number of new followers gained or lost on each specific day during the selected time period.',
    },
    cumulative: {
      title: 'Followers Trend',
      tooltip:
        'Examine the overall growth or decline in TikTok followers count over time, aggregating daily changes to show a running total, throughout the selected time period.',
    },
  },
}

const getDualGraphTitle = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()

  switch (graphName) {
    case DUAL_GRAPHS.ENGAGEMENT:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.ENGAGEMENT[loweredViewMode]?.title || ''
      )
    case DUAL_GRAPHS.VIDEO:
      return DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.VIDEO[loweredViewMode]?.title || ''
    case DUAL_GRAPHS.FOLLOWER:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWER[loweredViewMode]?.title || ''
      )

    default:
      return 'Title here'
  }
}

const getDualGraphTooltip = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()

  switch (graphName) {
    case DUAL_GRAPHS.ENGAGEMENT:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.ENGAGEMENT[loweredViewMode]?.tooltip ||
        ''
      )
    case DUAL_GRAPHS.VIDEO:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.VIDEO[loweredViewMode]?.tooltip || ''
      )
    case DUAL_GRAPHS.FOLLOWER:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWER[loweredViewMode]?.tooltip || ''
      )

    default:
      return 'ToolTip here'
  }
}

// method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

// method to get the tooltip for a given header value
const getHeadersTooltips = (header = '') => {
  return headersTooltips[header] || ''
}

const getHeaderApiKey = (header = '') => {
  return validPostsTableApiKeys[header] || ''
}

const postTableCurrentOffset = ref(0)
const selectedTableSort = ref(sortingFields.ENGAGEMENT)
const selectedTableSortOrder = ref(sortingOrder.DESC)
const isPostDataLoading = ref(false)

export default function useTiktokAnalytics(defaultLabel = 'Data') {
  const { getters } = useStore()

  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    defaultChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
  } = useAnalytics(defaultLabel)

  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value && selectedAccount.value?.platform_identifier) {
      try {
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          date: getFormattedDateRange(),
          tiktok_id: selectedAccount.value?.platform_identifier,
          timezone: getters.getActiveWorkspace.timezone,
          ...extraPayload,
        }

        const { data } = await proxy.post(
          BASE_ENDPOINT + 'tiktok/' + type,
          payload
        )

        if (type === routes.PAGE_AND_POST_INSIGHTS) {
          cardsData.value = data.data
        }

        if (type === routes.PAGE_FOLLOWERS_AND_VIEWS) {
          pageFollowersAndViews.value = data.data
        }

        if (type === routes.DAILY_ENGAGEMENTS_DATA) {
          dailyAndTotalEngagements.value = data.data
        }

        if (type === routes.POSTS_AND_ENGAGEMENTS) {
          postsAndEngagements.value = data.data
        }

        if (type === routes.TOP_AND_LEAST_PERFORMING_POSTS) {
          const leastPosts = data?.data?.least_posts ?? []
          const topPosts = data?.data?.top_posts ?? []

          topAndLeastEngagingPosts.value = {
            top_posts: topPosts.length > 0 ? transformObject(topPosts) : [],
            least_posts: topPosts.length > 0 ? transformObject(leastPosts) : [],
          }
        }

        if (type === routes.POSTS_DATA) {
          allPosts.value = transformObject(data.data)
        }
      } catch (e) {
        console.error('FETCH DATA:::', e)
      }
    }
  }

  const transformObject = (data) => {
    return data.map((post) => {
      return {
        id: post.tiktok_id,
        coverImage: post.cover_image_url,
        mediaType: mediaTypes.VIDEO,
        engagement: post.engagements_count || post.engagement_count,
        engagementRate: +post.engagement_rate,
        likes: +post.likes_count,
        comments: +post.comments_count,
        embedHtml: post.embed_html,
        embedLink: post.embed_link,
        createdAt: post.created_time,
        description: post.post_description,
        link: post.share_url,
        shares: +post.shares_count,
        followers: +post?.total_follower_count ?? 0,
        views: post.views_count,
      }
    })
  }

  const videosAndEngagementCharts = ref([
    {
      name: 'Engagements',
      color: '#78CEA0',
      position: 'left',
      api_response_key: 'sum_engagement_count',
    },
    {
      name: 'Videos',
      color: '#8ABAF3',
      position: 'right',
      api_response_key: 'post_count',
    },
  ])

  const videosAndEngagementChartOptions = ref({
    tooltip: defaultChartOptions.tooltip,
    grid: barChartOptions.value.grid,
    dataZoom: barChartOptions.value.dataZoom,
    xAxis: barChartOptions.value.xAxis,
    yAxis: [
      ...videosAndEngagementCharts.value?.map((chart, i) => ({
        type: 'value',
        sort: 'ascending',
        name: `{a|} {b|${chart.name}}`,
        nameTextStyle: {
          rich: {
            a: {
              backgroundColor: chart.color,
              width: 11,
              height: 11,
              borderRadius: 2,
              display: 'inline-block',
              textAlign: 'center',
              lineHeight: 14,
              fontSize: 12,
            },
            b: {
              color: chart.color,
              fontSize: 12,
              padding: [0, 0, 0, 6],
            },
          },
        },
        position: chart.position,
        nameLocation: 'center',
        nameGap: 40,
        min: 'dataMin',
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          formatter: (value) => formatNumber(value ? value.toFixed(0) : '0'),
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          interval: 'int',
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        axisPointer: {
          label: {
            backgroundColor: chart.color,
            color: 'white',
            formatter: (dataPoint) =>
              formatNumber(dataPoint?.value) ?? dataPoint.value,
          },
        },
        splitLine: {
          show: i === 0,
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      })),
    ],
    series: [],
  })

  /**
   * Sets default values for the postTableCurrentOffset and selectedTableSortOrder.
   * - Sets postTableCurrentOffset to 0.
   * - Sets selectedTableSortOrder to sortingOrder.DESC.
   *
   * @function
   * @name setPostTableDefaultValues
   * @returns {void}
   * @example
   * // Usage example:
   * setPostTableDefaultValues();
   */
  const setPostTableDefaultValues = () => {
    postTableCurrentOffset.value = 0
    selectedTableSortOrder.value = sortingOrder.DESC
  }

  return {
    analyticsDesignSystem,
    DUAL_GRAPHS,
    DUAL_GRAPHS_TITLE_AND_TOOLTIPS,

    // variables
    routes,
    legendOptions,
    dataZoomOptions,
    postsLimit,
    sortingFields,
    sortingOrder,
    postTableCurrentOffset,
    selectedTableSort,
    selectedTableSortOrder,
    isPostDataLoading,
    validPostsTableHeaders,
    nonSortableItems,
    customThumbnailKey,

    // state
    selectedAccount,
    cards,
    dateRange,
    topEngagingPosts,
    leastEngagingPosts,
    barChartOptions,
    lineChartOptions,
    videosAndEngagementChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    videosAndEngagementCharts,
    dailyAndTotalEngagements,
    isReportView,
    screenWidth,
    validPostsTableApiKeys,

    // computed
    pageFollowersAndViews,
    postsAndEngagements,
    topAndLeastEngagingPosts,
    cardsData,
    allPosts,

    hasTopEngagingPosts,
    hasLeastEngagingPosts,
    totalAccountPosts,

    // methods
    fetchMedia,
    getCardData,
    getFormattedDateRange,
    setPostTableDefaultValues,
    getDualGraphTitle,
    getDualGraphTooltip,
    isNoAnalyticsData,
    axisLabelFormatter,
    getHeaderTitles,
    getBodyValues,
    getHeadersTooltips,
    getHeaderApiKey,
  }
}
