<script setup>
import moment from "moment";
import DateTime from "@common/mixins/utils/dateTimeMixin";
import {computed} from "vue";

const props = defineProps({
  linkedAccounts : {
    type: Array,
    default: () => []
  },
  data: {
    type: Object,
    default: () => {}
  },
  item: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: ''
  }
})

const nameKey = computed(() => {
  if (props.type === 'tumblr' || props.type === 'tiktok' || props.type === 'youtube' || props.type === 'gmb') {
    return 'platform_name'
  } else if (props.type === 'pinterest') {
    return 'username'
  }
  return 'name'
})

const accountExpirationStates = (account) => {
  if (account) {
    if (account.validity) {
      // returning status expired in case of invalid and expired validity
      if (
          account.validity === 'invalid' ||
          account.validity === 'expired'
      ) {
        return '<span class="red">Expired</span>'
      }

      if (account.validity === 'limit_exceeded') {
        return '<span class="red">Daily Limit Reached</span>'
      }
    }

    if (account.days_remaining !== undefined) {
      // returning in case of remaining days are greater than 7
      if (account.days_remaining > 0) {
        if (!props.type === 'linkedin' || account.days_remaining < 30) {
          return (
              '<span class="green">Expiring in ' +
              account.days_remaining +
              ' days</span>'
          )
        }
      }
      return '<span class="green">Valid</span>'
    }

    if (account && account.expires_at) {
      // returning status expired in case of expired_at time passed
      if (DateTime.methods.utcTimePassingCheck(account.expires_at.date)) {
        return '<span class="red">Expired</span>'
      }

      const now = moment.utc().format('YYYY-MM-DD HH:mm')
      const days = DateTime.methods.checkTimeDifference(
          now,
          account.expires_at.date,
          'days'
      )

      // checking remaining time in case of expiring on today
      if (!this.type === 'linkedin'&& days === 0) {
        const hours = DateTime.methods.checkTimeDifference(
            account.expires_at.date,
            now,
            'hours'
        )
        if (hours === 0) {
          const minutes = DateTime.methods.checkTimeDifference(
              now,
              account.expires_at.date,
              'minutes'
          )
          if (minutes === 0) {
            return (
                '<span class="yellow">Expiring in ' +
                DateTime.methods.checkTimeDifference(
                    now,
                    account.expires_at.date,
                    'seconds'
                ) +
                ' seconds</span>'
            )
          }
          return (
              '<span class="yellow">Expiring in ' +
              minutes +
              ' minutes</span>'
          )
        }
        return '<span class="yellow">Expiring in ' + hours + ' hours</span>'
      }

      // returning in case of remaining days less than 7
      if (days <= 7) {
        return '<span class="yellow">Expiring in ' + days + ' days</span>'
      }

      // returning in case of remaining days are greater than 7
      if (!this.type === 'linkedin' || days < 30) {
        return '<span class="green">Expiring in ' + days + ' days</span>'
      }
    }
    return '<span class="green">Valid</span>'
  }
  return ''
}

</script>

<template>
  <div
      v-for="(account, index) in linkedAccounts" v-if="(data.selectedLink._id === item._id) && data.toggleLinks === true"
      :key="index" class="py-[0.75rem] px-5 border-bottom-custom hover:!ring-1 hover:!ring-[#90CAFF]"
  >
    <div
        class="flex items-center cursor-pointer"
    >
                      <span class="relative">
                        <img
                            :src="channelImage(account, type)"
                            alt=""
                            class="rounded-full object-cover border"
                            data-fallback="0"
                            height="30"
                            width="30"
                            @error="($event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`)"
                        />
                        <img
                            :src="require(`@assets/img/integration/${type === 'twitter' ? 'twitter-x' : type}-rounded.svg`)"
                            alt=""
                            class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
                            :class = "{ 'bg-white rounded-full': type === 'twitter' }"
                        />
                      </span>
      <span class="flex flex-col ml-3 flex-1">
                        <span class="flex flex-row">
                          <span
                              class="text-sm text-ellipsis font-normal text-black-100 select-none"
                          >
                            {{account[nameKey]}}
                          </span>
                        </span>

                        <span class="block text-xs select-none text-gray-800">
                          {{account.type || 'Profile'}}
                        </span>
                      </span>
      <div v-html="accountExpirationStates(account)"></div>
    </div>
  </div>
</template>

<style scoped>
.border-bottom-custom {
  border-bottom: 1px solid #ECEEF5;
}
</style>
