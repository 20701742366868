<template>
  <div
    class="w-full cst-drop-shadow bg-white rounded-xl"
    :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
  >
    <!--    <div class="flex items-center px-3 py-2">-->
    <!--      <div-->
    <!--        class="ml-auto inline-flex justify-center items-center w-8 h-8 bg-cs-light-violet"-->
    <!--      >-->
    <!--        <img-->
    <!--          src="@assets/img/integration/tiktok-icon.svg"-->
    <!--          alt=" "-->
    <!--          class="w-5"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- tiktok mobile preview -->
    <div class="relative text-white">
      <div v-show="isTruncated" class="view-more-gradient"></div>
      <template v-if="isVideoPost">
        <!--   background   -->

        <div class="ratio ratio-9x16 preview-background !bg-[#020B12]">
          <img
            v-if="detail?.video?.thumbnail"
            class="w-full object-contain"
            :src="detail.video.thumbnail"
            alt="thumbnail"
          />
        </div>
        <!-- playback icon -->
        <div
          class="absolute top-0 left-0 h-full w-full cursor-pointer z-10"
          @click.prevent="
            displayFile(
              'video',
              detail.video.converted_video || detail.video.link,
              0
            )
          "
        >
          <i
            class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
          ></i>
        </div>
      </template>
      <template v-else-if="isCarouselPost">
        <Carousel
          id="tiktok-preview-carousel"
          :navigation-next-label="navigationNext"
          :navigation-prev-label="navigationPrev"
          :navigation-enabled="true"
          :scroll-per-page="true"
          :per-page="1"
          :mouse-drag="true"
          :center-mode="true"
          :pagination-enabled="true"
          class="ratio ratio-9x16 tiktok_preview"
        >
          <template
            v-for="(image, index) in carouselSlides"
            :key="`image-index-${index}`"
          >
            <Slide
              v-tooltip="{
                content: 'Carousel Preview',
                placement: 'auto',
              }"
              @slide-click="openImageLightBox(carouselSlides, index)"
            >
              <div
                class="flex justify-center items-center w-full h-full cursor-pointer"
              >
                <img
                  :src="image"
                  alt=""
                  class="min-h-[12rem] max-h-[30rem] h-full w-full object-contain"
                />
              </div>
            </Slide>
          </template>
        </Carousel>
      </template>
      <!--   header   -->
      <div class="absolute top-px left-0 w-full text-xl text-white p-5">
        <div class="flex">
          <div class="flex w-full justify-center items-center">
            <span class="font-normal text-sm select-none"> Following </span>
            <span class="mx-1 text-sm font-medium">|</span>
            <span class="font-bold text-sm select-none"> For You </span>
          </div>
          <div class="w-max">
            <i class="text-base icon-Search"></i>
          </div>
        </div>
      </div>

      <!--   footer   -->
      <div
        class="absolute bottom-px left-0 w-full p-5 text-white z-30"
        :class="{ 'view-less-gradient': !isTruncated }"
      >
        <div class="flex space-x-4">
          <div class="min-w-0 flex flex-col justify-end">
            <div class="flex items-center font-bold">
              <span class="text-sm font-medium select-none">
                <span class="mr-0.5">@</span>{{ account.platform_name }}
              </span>
              <img
                src="@assets/img/composer/verified-icon.svg"
                width="14"
                height="14"
                alt="verified"
                class="mx-1"
              />
              <span class="text-sm font-medium select-none"> 3h ago</span>
            </div>
            <div v-if="isCarouselPost" class="pt-3 py-0.5">
              <span v-if="tiktokOptions?.title" class="text-lg font-semibold">
                {{ tiktokOptions?.title }}
              </span>
              <span v-else class="text-lg font-semibold">Title</span>
            </div>
            <div class="pt-3 py-0.5 overflow-auto no-scrollbar max-h-[14rem]">
              <span v-if="detail.message && !isVideoPost">
                {{ descriptionText }}
                <template v-if="detail.message.length > 45">
                  <span
                    class="cursor-pointer font-bold text-white"
                    @click="isTruncated = !isTruncated"
                  >
                    {{ !isTruncated ? 'View More' : 'View Less' }}
                  </span>
                </template>
              </span>

              <span v-else-if="isComposerModalOpen && !isVideoPost">Your Description</span>
            </div>
            <div class="flex items-center mt-2.5">
              <img
                src="@assets/img/composer/music-icon.svg"
                width="18"
                height="18"
                alt="music"
              />
              <span class="ml-2 text-sm font-normal">Your original Sound</span>
            </div>
          </div>
          <div class="ml-auto">
            <div class="flex flex-col justify-center items-center">
              <img
                :src="account.platform_logo"
                alt=""
                class="rounded-full object-cover border border-solid border-black"
                width="30"
                height="30"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
            </div>
            <div class="flex flex-col justify-center items-center mt-3.5">
              <i class="fas fa-heart text-2xl"></i>
              <p class="text-xs font-medium whitespace-nowrap mt-1.5">14.8 k</p>
            </div>
            <div class="flex flex-col justify-center items-center mt-3.5">
              <i class="fas fa-comment-dots text-2xl"></i>
              <p class="text-xs font-medium whitespace-nowrap mt-1.5">521</p>
            </div>
            <div class="flex flex-col justify-center items-center mt-3.5">
              <i class="fas fa-share text-2xl"></i>
              <p class="text-xs font-medium whitespace-nowrap mt-1.5">12</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      src="@assets/img/composer/tiktok-panel.svg"
      alt="tiktok-panel"
      class="w-full"
    />
  </div>
  <VueEasyLightbox
    :visible="visible"
    :imgs="imgs"
    :index="selectedImageIndex"
    @hide="handleHide"
  >
  </VueEasyLightbox>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper.js'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'TikTokPreview',
  components: {
    Carousel,
    Slide,
    VueEasyLightbox,
  },
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
    tiktokOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isComposerModalOpen } = useComposerHelper()

    return {
      isComposerModalOpen,
    }
  },
  data() {
    return {
      isTruncated: false,
      visible: false,
      imgs: [],
      selectedImageIndex: 0,
    }
  },
  computed: {
    descriptionText() {
      if (
        !this.isTruncated &&
        this.detail.message &&
        this.detail.message.length > 45
      ) {
        return this.detail.message.slice(0, 45) + '...'
      } else {
        return this.detail.message
      }
    },
    navigationNext() {
      return `<i class="fas fa-chevron-right"></i>`
    },
    navigationPrev() {
      return `<i class="fas fa-chevron-left"></i>`
    },
    carouselSlides() {
      return this.detail.image
    },
    isCarouselPost() {
      return this.tiktokOptions?.post_type === 'carousel'
    },
    isVideoPost() {
      return this.tiktokOptions?.post_type !== 'carousel'
    },
  },
  methods: {
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
    showNext() {
      this.$refs.tiktokCarousel.handleNavigation('forward')
    },
    openImageLightBox(item, index = 0) {
      this.selectedImageIndex = index
      this.imgs = item
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.preview__tiktok-video {
  width: 100%;
  min-height: 550px;
  max-width: 350px;
  background-color: black;
}
.preview-background {
  &::after {
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 72%,
      rgba(133, 133, 133, 0.3015406846) 100%
    );
    content: '';
    @apply absolute block w-full h-full top-0 left-0;
  }
}
.view-less-gradient {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 82%
    )
    no-repeat;
}
.view-more-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0.1) 40%
    )
    no-repeat;
  z-index: 20;
}
</style>

<style lang="scss">
.tiktok_preview {
  .VueCarousel-wrapper {
    height: 100%;
    background: black;

    .VueCarousel-inner {
      height: 100% !important;
    }
  }
}
</style>
