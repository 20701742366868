<script setup>
import CstPopup from '@ui/Popup/CstPopup.vue'
import { ref, computed } from 'vue'
import useDashboard from '@src/modules/dashboard/composables/useDashboard'
import moment from 'moment'

const { setDashboardBannerStatus } = useDashboard()

const showPopup = ref(false)

const getText = computed(() => {
  return moment.utc().isAfter(moment?.utc('2024-04-22'))
      ? 'has discontinued'
      : 'is discontinuing'
})

const closePopup = () => {
  showPopup.value = false
}

const handleLearnMore = () => {
  window.open(
      'https://docs.contentstudio.io/article/1025-publishing-to-facebook-group-via-mobile-app-notification-method',
      '_blank'
  )
}
</script>

<template>
  <div class="px-4 max-w-[125rem] w-full">
    <div
        class="z-10 bg-no-repeat bg-right-bottom flex h-10 rounded-lg py-2 w-full pl-8 pr-6 items-center mt-4 bg-gray-cs-800 relative"
    >
      <div class="flex flex-grow my-auto gap-4 items-center justify-center">
        <span class="relative flex h-3 w-3 items-center justify-center">
          <span
              class="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-primary-cs-200 opacity-75"
          ></span>
          <span
              class="relative inline-flex rounded-full h-3 w-3 bg-primary-cs-600"
          ></span>
        </span>
        <p class="text-white leading-none">
          Due to the national holiday on August 14th, our support team will be unavailable. We appreciate your understanding!
        </p
        >
      </div>
      <i
          class="fas fa-times text-white text-xl font-light cursor-pointer ml-auto"
          @click="showPopup = !showPopup"
      />
      <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
      >
        <CstPopup
            v-if="showPopup"
            v-click-away="closePopup"
            parent-class="top-[110%] right-0 w-[27.875rem] h-[7.25rem] cursor-default"
            confirm-text="Yes, Hide"
            @close-popup="closePopup"
            @confirm-popup="setDashboardBannerStatus"
        >
          <template v-slot:popup-text>
            <p class="text-secondary-cs font-bold mx-6 mt-5 mb-4"
            >Are you sure you want to hide this banner?</p
            >
          </template>
        </CstPopup>
      </transition>
    </div>
  </div>
</template>
