import http from '@common/lib/http-common'
import { contentCategoriesTypes } from '@src/modules/setting/store/states/mutation-types'
import {
  deleteAllCategorySlotsURL,
  deleteCategoryURL,
  deleteGlobalCategoryURL,
  deleteSlotCategoryURL,
  fetchCategoriesSlotsURL,
  fetchCategoriesURL,
  nextAvailableSlotCategoryURL,
  shuffleCategoryPostsURL,
  storeCategorySlotURL,
  storeCategoryURL,
  storeGlobalCategorySlotsURL,
  storeGlobalCategoryURL,
  updateCategorySlotURL,
} from '@src/modules/setting/config/api-utils'

const getDefaultContentCategoryAvailableSlot = () => {
  return {
    slot_time: {},
    available: false,
    scheduled: false,
  }
}

const getDefaultContentCategorySlots = () => {
  return {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  }
}
const getDefaultContentCategoryAdd = () => {
  return {
    name: '',
    color: 'color_1',
    _id: null,
    category_state: 'local',
    is_hashtags: false,
    facebook: [],
    twitter: [],
    linkedin: [],
    pinterest: [],
    gmb: [],
    instagram: [],
    youtube: [],
    tiktok: [],
  }
}
const getDefaultContentLoaders = () => {
  return {
    retrieve_categories: false,
    retrieve_slots: false,
    store: false,
    store_slots: false,
  }
}

const getDefaultSlotAdd = () => {
  return {
    minute: 0,
    hour: 9,
    period: 'AM',
    weekdays: [],
    category_id: null,
    slots_state: 'local',
    _id: null,
  }
}

export default {
  state: {
    add: getDefaultContentCategoryAdd(),
    loaders: getDefaultContentLoaders(),
    add_slot: getDefaultSlotAdd(),
    categories_list: [],
    slots: getDefaultContentCategorySlots(),
    next_slot: getDefaultContentCategoryAvailableSlot(),
  },
  getters: {
    getContentCategoryAdd: (state) => state.add,
    getContentCategorySlotAdd: (state) => state.add_slot,
    getContentCategoryList: (state) => state.categories_list,
    getContentCategorySlots: (state) => state.slots,
    getContentCategoryLoaders: (state) => state.loaders,
    getContentCategoryAvailableSlot: (state) => state.next_slot,
  },
  actions: {
    async storeCategory({ commit, getters, dispatch }) {
      commit(contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_LOADER, true)

      const category = getters.getContentCategoryAdd
      const res = await http
        .post(storeCategoryURL, {
          ...category,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            // update case
            if ('_id' in category && category._id && category._id.length > 3) {
              const findIndex = getters.getContentCategoryList.findIndex(
                (item) => item._id === category._id
              )
              getters.getContentCategoryList.splice(findIndex, 1, res.data.data)

              // update the category name and other meta data for the existing slots. no service call is being performed.

              const slots = getters.getContentCategorySlots
              console.log('content category slots', Object.entries(slots))
              Object.entries(slots).forEach((slotItem, index) => {
                if (slotItem[1].length > 0) {
                  const item = slotItem[1].find(
                    (slotItem) => slotItem.category_id === category._id
                  )
                  console.log(item)

                  if (item) item.category = res.data.data
                }
                console.log('slot iteration', slotItem, index)
              })
            } else {
              // new item case
              commit(
                contentCategoriesTypes.ADD_TO_CONTENT_CATEGORIES_LIST,
                res.data.data
              )
            }
          } else {
            if (res.data.message) {
              dispatch('toastNotification', {
                message: res.data.message,
                type: 'error',
              })
            }
          }

          return res.data
        })
        .catch(() => {
          return null
        })
      commit(contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_LOADER, false)

      return res
    },
    async storeGlobalCategory({ commit, getters, dispatch }) {
      commit(contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_LOADER, true)

      const category = getters.getContentCategoryAdd
      const res = await http
        .post(storeGlobalCategoryURL, {
          ...category,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            return res
          }
        })
        .catch((err) => {
          return err
        })
      if (res && res.data.status) {
        await dispatch('fetchContentCategories')
        dispatch('fetchContentCategoriesSlots')
        // for the update case, get all the categories slots as the name has been changed.
        if (res.data.state === 'updated') {
          dispatch('toastNotfication', {
            message: `${res.data.count} content categories updated.`,
          })
        }
        if (res.data.state === 'created') {
          dispatch('toastNotfication', {
            message: `${res.data.count} content categories created.`,
          })
        }
      }
      commit(contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_LOADER, false)
      return res
    },
    async deleteGlobalCategory({ commit, getters, dispatch }, payload) {
      const res = await http
        .post(deleteGlobalCategoryURL, payload)
        .then((res) => {
          if (res.data.status) {
            return res
          }
        })
        .catch((err) => {
          return err
        })
      if (res && res.data.status) {
        await dispatch('fetchContentCategories')
        await dispatch('fetchContentCategoriesSlots')
        dispatch('toastNotification', {
          message: 'Your content categories have been removed successfully!',
        })
      }
    },
    async fetchContentCategories({ commit, getters, dispatch }) {
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_RETRIEVE_LOADER,
        true
      )
      if (getters.getWorkspaces.activeWorkspace !== false) {
        await http
          .post(fetchCategoriesURL, {
            workspace_id: getters.getActiveWorkspace._id,
          })
          .then((res) => {
            if (res.data.status) {
              commit(
                contentCategoriesTypes.SET_CONTENT_CATEGORIES_LIST,
                res.data.data
              )
            } else {
              if (res.data.message) {
                dispatch('toastNotification', {
                  message: res.data.message,
                  type: 'error',
                })
              }
            }
            return res
          })
          .catch(() => {
            return null
          })
      }
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_RETRIEVE_LOADER,
        false
      )
    },
    async deleteCategory({ commit, getters, dispatch }, categoryId) {
      const res = await http
        .post(deleteCategoryURL, { category_id: categoryId })
        .then((res) => {
          if (res.data.status) {
            const findIndex = getters.getContentCategoryList.findIndex(
              (item) => item._id === categoryId
            )
            getters.getContentCategoryList.splice(findIndex, 1)

            // remove all associated slots

            const slots = getters.getContentCategorySlots
            console.log('content category slots', Object.entries(slots))
            Object.entries(slots).forEach((item, index) => {
              if (item[1].length > 0) {
                const changed = item[1].filter(
                  (slotItem) => slotItem.category_id !== categoryId
                )
                getters.getContentCategorySlots[item[0]] = changed
              }

              console.log('slot iteration', item, index)
            })
          } else {
            const message = res.data.message
              ? res.data.message
              : 'An error occurred while removing your content category, please try again.'
            dispatch('toastNotification', { message, type: 'error' })
          }
          return res.data.status
        })
        .catch(() => {
          return null
        })

      return res
    },
    async shuffleCategory({ commit, getters, dispatch }, categoryId) {
      await http
        .post(shuffleCategoryPostsURL, {
          category_id: categoryId,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            dispatch('toastNotification', { message: res.data.message })
          }
        })
        .catch(() => {})
    },

    async storeCategorySlot({ commit, getters, dispatch }) {
      const payload = getters.getContentCategorySlotAdd
      payload.hour = parseInt(payload.hour)
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER,
        true
      )

      const res = await http
        .post(storeCategorySlotURL, {
          ...payload,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          console.log(res.data)
          if (res.data.status) {
            if (res.data.data) {
              const category = getters.getContentCategoryList.find(
                (item) => item._id === payload.category_id
              )
              Object.entries(res.data.data).forEach((item) => {
                // we have slots stored at first index

                if (item[1].length > 0) {
                  item[1].forEach((element) => {
                    // zero index i,e monday , tuesday etc.
                    commit(
                      contentCategoriesTypes.ADD_TO_CONTENT_CATEGORIES_SLOTS,
                      {
                        day: item[0],
                        value: element,
                      }
                    )
                    category.slots_count += 1
                  })
                }
              })
            }
          }
          return res.data.status
        })
        .catch(() => {
          return null
        })
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER,
        false
      )
      return res
    },
    async storeGlobalCategorySlot({ commit, getters, dispatch }) {
      const payload = getters.getContentCategorySlotAdd
      payload.hour = parseInt(payload.hour)
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER,
        true
      )

      const res = await http
        .post(storeGlobalCategorySlotsURL, {
          ...payload,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          console.log(res.data)
          return res.data.status
        })
        .catch(() => {
          return null
        })
      if (res) {
        await dispatch('fetchContentCategories')
        await dispatch('fetchContentCategoriesSlots')
      }

      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER,
        false
      )
      return res
    },

    async updateCategorySlot({ commit, getters, dispatch }) {
      const payload = getters.getContentCategorySlotAdd
      payload.hour = parseInt(payload.hour)
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER,
        true
      )

      const res = await http
        .post(updateCategorySlotURL, {
          ...payload,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          console.log(res.data)
          if (res.data.status) {
            if (res.data.data) {
              // remove the existing slot first.
              console.log(getters.getContentCategorySlots)

              Object.entries(getters.getContentCategorySlots).forEach(
                (item, index) => {
                  // item[0] -> monday, tuesday
                  // item[1]] -> slots list (array)
                  console.log('[content category] ', item, index)

                  if (item[1].length) {
                    // find the slot index item by slot id, and remove the item.
                    const slotIndex = item[1].findIndex(
                      (x) => x._id === payload._id
                    )
                    console.log('[content category slot] ', slotIndex)
                    if (slotIndex >= 0) {
                      getters.getContentCategorySlots[item[0]].splice(
                        slotIndex,
                        1
                      )
                    }
                  }
                }
              )

              // push the item to the new date

              getters.getContentCategorySlots[res.data.data.day].push(
                res.data.data
              )
            }
          }
          return res.data.status
        })
        .catch(() => {
          return null
        })
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER,
        false
      )
      return res
    },

    async fetchContentCategoriesSlots({ commit, getters, dispatch }) {
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_RETRIEVE_SLOTS_LOADER,
        true
      )
      await http
        .post(fetchCategoriesSlotsURL, {
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            console.log('slots > ', getters.getContentCategorySlots)
            commit(
              contentCategoriesTypes.SET_CONTENT_CATEGORIES_SLOTS,
              Object.assign(getDefaultContentCategorySlots(), res.data.data)
            )
            console.log('after slots > ', getters.getContentCategorySlots)
          } else {
            if (res.data.message) {
              dispatch('toastNotification', {
                message: res.data.message,
                type: 'error',
              })
            }
          }

          return res
        })
        .catch(() => {
          return null
        })
      commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORIES_RETRIEVE_SLOTS_LOADER,
        false
      )
    },
    async deleteCategorySlot(
      { commit, getters, dispatch },
      { slotId, weekday, index, categoryId }
    ) {
      return await http
        .post(deleteSlotCategoryURL, { slot_id: slotId })
        .then((res) => {
          if (res.data.status) {
            getters.getContentCategorySlots[weekday].splice(
              getters.getContentCategorySlots[weekday].findIndex(
                (item) => item._id === slotId
              ),
              1
            )
            const category = getters.getContentCategoryList.find(
              (item) => item._id === categoryId
            )
            category.slots_count -= 1
          } else {
            const message = res.data.message
              ? res.data.message
              : 'An error occurred while removing your content category, please try again.'
            dispatch('toastNotification', { message, type: 'error' })
          }
          return res.data.status
        })
        .catch(() => {
          return null
        })
    },

    async deleteAllCategorySlots({ commit, getters, dispatch }, categoryId) {
      const res = await http
        .post(deleteAllCategorySlotsURL, {
          category_id: categoryId,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            const category = getters.getContentCategoryList.find(
              (item) => item._id === categoryId
            )
            if (category) {
              category.slots_count = 0
            }
          }
          return res.data.status
        })
        .catch(() => {
          return null
        })
      if (res) {
        dispatch('toastNotification', {
          message: 'Your slots have been removed from the category.',
        })
        await dispatch('fetchContentCategoriesSlots')
      }

      return res
    },
    async nextAvailableSlot({ commit, getters, dispatch }, categoryId) {
      const res = await http
        .post(nextAvailableSlotCategoryURL, {
          category_id: categoryId,
          workspace_id: getters.getActiveWorkspace._id,
          plan_id: getters.getPublishSelection.plan_id,
        })
        .then((res) => {
          if (res.data.status) {
            commit(contentCategoriesTypes.SET_CONTENT_CATEGORY_AVAILABLE_SLOT, {
              data: res.data.data,
              status: res.data.status,
              scheduled: res.data.scheduled,
            })
          } else {
            commit(contentCategoriesTypes.SET_CONTENT_CATEGORY_AVAILABLE_SLOT, {
              data: {},
              status: null,
              scheduled: false,
            })
          }
          return res
        })
        .catch(() => {})
      return res
    },
  },

  mutations: {
    [contentCategoriesTypes.SET_CONTENT_CATEGORIES_LIST](state, value) {
      state.categories_list = value
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORIES_SLOTS](state, value) {
      state.slots = value
    },
    [contentCategoriesTypes.RESET_CONTENT_CATEGORY_ADD](state) {
      state.add = getDefaultContentCategoryAdd()
    },
    [contentCategoriesTypes.RESET_CONTENT_CATEGORY_ADD_SLOT](state) {
      state.add_slot = getDefaultSlotAdd()
    },
    [contentCategoriesTypes.SELECT_CATEGORY](state, value) {
      state.add_slot = {
        minute: value.minutes || 0,
        hour: value.hour === 0 ? 12 : value.hour || 9,
        period: value.period || 'AM',
        weekdays: [value.day] || [],
        category_id: null,
        slots_state: 'local',
        _id: null,
      }
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_SLOT](state, value) {
      value = JSON.parse(JSON.stringify(value))
      delete value.category
      state.add_slot = value
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_VALUE](state, category) {
      state.add = JSON.parse(JSON.stringify(category))
    },
    [contentCategoriesTypes.ADD_TO_CONTENT_CATEGORIES_LIST](state, value) {
      state.categories_list.push(value)
    },
    [contentCategoriesTypes.ADD_TO_CONTENT_CATEGORIES_SLOTS](state, payload) {
      state.slots[payload.day].push(payload.value)
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORIES_RETRIEVE_LOADER](
      state,
      value
    ) {
      state.loaders.retrieve_categories = value
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_LOADER](state, value) {
      state.loaders.store = value
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER](
      state,
      value
    ) {
      state.loaders.store_slots = value
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORIES_RETRIEVE_SLOTS_LOADER](
      state,
      value
    ) {
      state.loaders.retrieve_slots = value
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORY_AVAILABLE_SLOT](
      state,
      payload
    ) {
      state.next_slot.slot_time = payload.data
      state.next_slot.available = payload.status
      state.next_slot.scheduled = payload.scheduled
    },
    [contentCategoriesTypes.RESET_CONTENT_CATEGORY_AVAILABLE_SLOT](state) {
      state.next_slot = getDefaultContentCategoryAvailableSlot()
    },
    [contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_CATEGORY_VALUE](
      state,
      value
    ) {
      state.add_slot.category_id = value
    },
  },
}
