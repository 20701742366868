<template>
  <div
    class="bg-[#F5F7FB] px-6 py-[1.4rem] flex items-center h-[60px]"
  >
    <div class="flex items-center w-full">
      <img
        class="w-[1.781rem] mr-4"
        src="@assets/img/first-comment.webp"
        alt="calendar"
      />
      <div class="flex w-full justify-between items-center">
        <p class="font-medium text-base text-[#26282C] w-max">Schedule</p>
        <div class="flex items-center justify-center">
          <span
              class="ml-2.5 text-xs text-[#706C6C] truncate max-w-[228px] mr-[2.5]"
          >{{ getters.getActiveWorkspace.timezone }} </span
          >
          <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="bg-[#b3b2b2] max-w-[500px] rounded-lg border border-[#F4F6FA] custom-popover"
              popper-inner-class="bg-[#ffffff] text-left p-3 pr-5 rounded-lg"
              :delay="{ hide: 300 }"
          >
            <i
                class="fa fa-globe fa-lg w-10 h-10 flex justify-center items-center"
                popper-class="text-white bg-gray"
            ></i>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div class="bg-[#ffffff] text-left p-3 pr-5 rounded-lg">
                <p class="text-sm "
                ><b>Timezone: </b>
                  {{ getWorkspaces.activeWorkspace.timezone }}</p
                >
                <p class="text-sm mt-2"
                ><b>Current Time: </b>
                  <clock
                      time-format="MMMM DD, YYYY hh:mm A"
                      :timezone="getWorkspaces.activeWorkspace.timezone"
                  ></clock>
                </p>
              </div>
            </template>
          </v-menu>
          <hr class="w-px h-5 bg-[#D2D5DF] mr-2.5 my-0"/>
        </div>
      </div>
    </div>
    <div>
          <span
              class="h-8 w-8 cursor-pointer flex justify-center items-center bg-gray-300 hover:bg-gray-500 text-black-400 rounded-lg p-3.5 text-lg border-0"
              @click="$bvModal.hide('post-scheduler-modal')"
          >
            <i class="fal fa-times !leading-[0]"></i>
          </span>
    </div>
  </div>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */

// constants
import { useStore } from '@state/base'

const { getters } = useStore()
</script>
