<template>
  <input
    v-if="type !== 'date'"
    v-model="content"
    :type="type"
    :class="classObject"
    :placeholder="placeholder"
    class="rounded-lg color-border shadow-xs font-normal text-gray-900 focus:outline-none bg-cs-light-gray"
    :maxlength="maxlength"
    @focus="(e) => $emit('focus', e)"
    @keyup.enter="(val) => $emit('onPressEnter', val)"
    @keydown="(e) => $emit('keydown', e)"
    @blur="(e) => $emit('blur', e)"
  />
  <DatePicker
      v-else
      v-model="content"
      class="bg-cs-light-gray cst-date-range-picker rounded-lg border color-border shadow-xs !w-full"
      style="min-width: 12rem !important"
      :class="classObject"
      range-separator=" - "
      input-class="bg-transparent border-0 font-normal text-gray-900 hover:bg-gray-50 focus:outline-none cursor-pointer"
      :format="dateOptions.format"
      :shortcuts="dateOptions.shortcuts"
      :disabled-date="dateOptions.disabledDate"
      type="date"
      range
      :placeholder="placeholder"
      :popup-class="[datePickerPopupClasses, 'pop-up-date-picker']"
      :confirm="true"
      confirm-text="Apply"
      @clear="() => $emit('clear-date')"
  >
  </DatePicker>
</template>

<script>
import DatePicker from 'vue2-datepicker'

export default {
  name: 'CstInputFields',
  components: { DatePicker },
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    dateOptions: {
      type: Object,
      default: () => {
        return {
          format: 'YYYY-MM-DD',
          disabledDate: () => {},
          shortcuts: [],
        }
      },
    },
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      },
    },
    maxlength: {
      type: [String, Number],
      default: 524288,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    datePickerPopupClasses: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['clear-date'],
  data() {
    return {
      content: this.value,
    }
  },
  computed: {
    classObject: function () {
      return {
        'px-2 py-2.5 text-sm': this.size === 'small',
        'px-3 py-2.5 text-sm': this.size === 'default',
        'px-3 py-3 text-base': this.size === 'large',
      }
    },
  },
  watch: {
    content(value) {
      this.$emit('value', value)
      this.$emit('change', value)
      this.$emit('input', value)
    },
    value(value) {
      this.content = value
    },
  },
  methods: {}
}
</script>

<style lang="scss">
.pop-up-date-picker {
  .mx-calendar-header {
    /* Select direct buttons inside .mx-calendar-header that are NOT inside .mx-calendar-header-label */
    button:not(.mx-calendar-header-label button) {
      /* Apply styles to direct buttons except the ones inside .mx-calendar-header-label */
      display: none;
    }
  }
}

.cst-date-range-picker input {
  width: 80%;
  overflow: hidden;
}

.mx-datepicker-sidebar {
  width: 130px !important;
}

.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 130px !important;
}
</style>