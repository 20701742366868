<template>
  <div class="discovery_analytic_component pt-0">
    <!--.....unlock insight feature.......-->
    <div class="component_inner">
      <!-- render the analytics from here, send the payload from here.-->
      <template v-if="loaders.retrieve">
        <beat-loader class="pt-5" :color="'#436aff'"></beat-loader>
      </template>
      <template v-else>
        <div class="topics-insights-container">
          <div class="topics-insights-container__tabs">
            <a
              href="javascript:;"
              :class="{
                active:
                  !$route.query.section || $route.query.section === 'overview',
              }"
              @click.prevent="
                $router.replace({
                  query: { ...$route.query, section: 'overview' },
                })
              "
            >
              Overview
            </a>
            <a
              href="javascript:;"
              :class="{ active: $route.query.section === 'top_domains' }"
              @click.prevent="
                $router.replace({
                  query: { ...$route.query, section: 'top_domains' },
                })
              "
            >
              Top Domains
            </a>
            <a
              href="javascript:;"
              :class="{ active: $route.query.section === 'top_authors' }"
              @click.prevent="
                $router.replace({
                  query: { ...$route.query, section: 'top_authors' },
                })
              "
            >
              Top Authors
            </a>
          </div>

          <template
            v-if="!$route.query.section || $route.query.section === 'overview'"
          >
            <div class="topics-insights-container__statistics">
              <h2>Summary</h2>
              <TotalStatisticsEngagement
                :articles_analyzed="response.hits.total"
                :total_engagement="response.aggregations.total_stats.value"
                :average_engagement="response.aggregations.average_stats.value"
                :average_facebook_engagement="
                  response.aggregations.average_facebook.value
                "
                :average_twitter_engagement="
                  response.aggregations.average_twitter.value
                "
                :average_reddit_engagement="
                  response.aggregations.average_reddit.value
                "
                :average_pinterest_engagement="
                  response.aggregations.average_pins.value
                "
              ></TotalStatisticsEngagement>
            </div>

            <TotalArticlesPublishedOverTime
              :articles_published_posts_count="
                response.articles_published_posts_count
              "
              :articles_published_engagement="
                response.articles_published_engagement
              "
            ></TotalArticlesPublishedOverTime>

            <!--                <two-insights-charts-slot>-->
            <!--                    <total-articles-published-with-their-sentiments-->
            <!--                            :articles_sentiments="response.articles_sentiments"></total-articles-published-with-their-sentiments>-->
            <!--                    <total-share-of-sentiment-->
            <!--                            :articles_sentiments_with_count="response.articles_sentiments_with_count"></total-share-of-sentiment>-->
            <!--                </two-insights-charts-slot>-->

            <TwoInsightsChartsSlot :block_size="'engagement_rate_block_2_2'">
              <TotalAverageEngagementByNetwork
                :articles_engagement_with_count="
                  response.articles_engagement_with_count
                "
              ></TotalAverageEngagementByNetwork>
              <TotalArticlesEngagementByNetwork
                :articles_engagement_with_count="
                  response.articles_engagement_with_count
                "
              ></TotalArticlesEngagementByNetwork>
            </TwoInsightsChartsSlot>

            <TopArticlesContentType
              :articles_categories_list="response.articles_categories_list"
            ></TopArticlesContentType>

            <TwoInsightsChartsSlot :block_size="'engagement_rate_block_2_2'">
              <TotalArticlesWordCloud
                :articles_wordcloud_list="response.articles_wordcloud_list"
              ></TotalArticlesWordCloud>
              <TotalArticlesPopularWordCount
                :articles_popular_reading_wordcount_list="
                  response.articles_popular_reading_wordcount_list
                "
              ></TotalArticlesPopularWordCount>
            </TwoInsightsChartsSlot>

            <!--              -->
            <!--              -->
            <!--                <two-insights-charts-slot :block_size="'engagement_rate_block_2_2'">-->
            <!--&lt;!&ndash;                    <top-articles-popular-sentiments&ndash;&gt;-->
            <!--&lt;!&ndash;                            :articles_sentiments_with_count="response.articles_sentiments_with_count"></top-articles-popular-sentiments>&ndash;&gt;-->
            <!--                </two-insights-charts-slot>-->
            <div class="ds_card_container engagement_follower_block mt-0">
              <TopArticlesPopularReadingLevels
                :articles_popular_reading_levels_list="
                  response.articles_popular_reading_levels_list
                "
              ></TopArticlesPopularReadingLevels>
            </div>

            <!--                <top-articles-popular-reading-levels-->
            <!--                    :articles_popular_reading_levels_list="response.articles_popular_reading_levels_list"></top-articles-popular-reading-levels>-->
            <TopArticlesPopularDays
              :articles_popular_days="response.articles_popular_days"
            ></TopArticlesPopularDays>

            <TopArticlesDomainsOnNetworks
              :articles_popular_domains="response.articles_popular_domains"
            ></TopArticlesDomainsOnNetworks>
          </template>

          <template v-else-if="$route.query.section === 'top_domains'">
            <TopWebsitesTable
              :articles_popular_domains="response.articles_popular_domains"
            ></TopWebsitesTable>
          </template>
          <template v-else-if="$route.query.section === 'top_authors'">
            <TopAuthorsTable
              :articles_popular_authors="response.articles_popular_authors"
            ></TopAuthorsTable>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { EventBus } from '@common/lib/event-bus'
import TotalStatisticsEngagement from '../../insights/components/TotalStatisticsEngagement'
import TopWebsitesTable from '../../insights/components/TopWebsitesTable'
import TopAuthorsTable from '../../insights/components/TopAuthorsTable'
import TotalShareOfSentiment from '../../insights/components/TotalShareOfSentiment'
import TopArticlesPopularSentiments from '../../insights/components/TopArticlesPopularSentiments'
import TotalArticlesPublishedOverTime from '../../insights/components/TotalArticlesPublishedOverTime'
import TotalAverageEngagementByNetwork from '../../insights/components/TotalAverageEngagementByNetwork'
import TotalArticlesEngagementByNetwork from '../../insights/components/TotalArticlesEngagementByNetwork'
import TotalArticlesWordCloud from '../../insights/components/TotalArticlesWordCloud'
import TotalArticlesPopularWordCount from '../../insights/components/TotalArticlesPopularWordCount'
import TopArticlesPopularReadingLevels from '../../insights/components/TopArticlesPopularReadingLevels'
import TopArticlesPopularDays from '../../insights/components/TopArticlesPopularDays'
import TopArticlesContentType from '../../insights/components/TopArticlesContentType'
import TopArticlesDomainsOnNetworks from '../../insights/components/TopArticlesDomainsOnNetworks'
import TotalArticlesPublishedWithTheirSentiments from '../../insights/components/TotalArticlesPublishedWithTheirSentiments'
import TwoInsightsChartsSlot from '../../insights/slots/TwoInsightsChartsSlot'
import insightsResponseMixin from '../../../mixins/insights/insights-response-mixin'

const getDefaultInsightsFilters = () => {
  return {
    from_date: moment.utc().subtract(90, 'day').format('YYYY-MM-DD'),
    to_date: moment.utc().format('YYYY-MM-DD'),
    query: '',
    topic: null,
    search_type: 'query_insights'
  }
}
export default {
  components: {
    TotalStatisticsEngagement,
    TotalArticlesPublishedOverTime,
    TwoInsightsChartsSlot,
    TotalArticlesPublishedWithTheirSentiments,
    TotalShareOfSentiment,
    TotalAverageEngagementByNetwork,
    TotalArticlesEngagementByNetwork,
    TopArticlesContentType,
    TotalArticlesWordCloud,
    TotalArticlesPopularWordCount,
    TopArticlesPopularSentiments,
    TopArticlesPopularReadingLevels,
    TopArticlesPopularDays,
    TopArticlesDomainsOnNetworks,
    TopWebsitesTable,
    TopAuthorsTable
  },
  mixins: [insightsResponseMixin],
  data () {
    return {
      filters: getDefaultInsightsFilters(),
      loaders: {
        retrieve: true
      },
      response: {
        articles_published_engagement: [],
        articles_published_posts_count: [],
        articles_sentiments: {
          positive: [],
          negative: [],
          neutral: []
        },
        articles_sentiments_with_count: {
          percentage: {},
          engagement: {},
          docs: {}
        },
        articles_engagement_with_count: {
          sum: {},
          average: {}
        }
      }
    }
  },
  async created () {
    // checking if the user has the access to the insights section. Lifetime users only
    if (!this.canAccessInsights) {
      return this.$router.push({
        name: 'discover_insights_web_review_required'
      })
    }

    // the user has access to the insights

    await this.webInsightsFilters()
    this.webInsights()
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getDiscoverCuratedTopic',
      'getActiveWorkspace'
    ]),
    // checking if the user can access the insights, if not, they will be redirected towards the review page where
    // they will need to submit the review.
    canAccessInsights () {
      if (this.isOnLifetimePlan()) return this.getProfile.insights_unlocked
      return true
    }
  },
  watch: {
    async '$route.query.q' (value) {
      await this.webInsightsFilters()
      this.webInsights()
    },
    async '$route.query.from_date' (value) {
      await this.webInsightsFilters()
      this.webInsights()
    },
    async '$route.query.to_date' (value) {
      await this.webInsightsFilters()
      this.webInsights()
    },
    'getActiveWorkspace._id' (value) {
      this.$router.push({ name: 'discover_search' })
    }
  },
  methods: {
    async webInsightsFilters () {
      const query = this.$route.query
      if (query.q) {
        this.filters.topic = {}
        this.filters.search_type = 'query_insights'
        this.filters.query = query.q
        this.filters = Object.assign(this.filters, this.$route.query)
      }
      if (query.tag) {
        await this.$store.dispatch('fetchCuratedTopicByTag', {
          tag: query.tag
        })
        this.filters.topic = this.getDiscoverCuratedTopic
        this.filters.search_type = 'curated_topic_insights'
        this.filters = Object.assign(this.filters, this.$route.query)
      }
      return true
    },
    async webInsights () {
      this.loaders.retrieve = true
      EventBus.$emit('reset-related-topics-suggestions')

      const resInsights = this.$store.dispatch(
        'fetchDiscoverSearchInsights',
        this.filters
      )
      await resInsights
        .then((res) => {
          if (res.data.status) {
            this.response = res.data.insights
            this.response = {
              ...this.response,
              ...this.renderInsightsResponse(res)
            }
            console.log(this.response)
          }
        })
        .catch(() => {})
        .finally(() => {})
      this.loaders.retrieve = false
      if (this.$route.query.q) {
        EventBus.$emit('related-topics-suggestions')
      }
    }
  }
}
</script>
