<template>
  <div v-if="isLoading">
    <SkeletonBox class="!w-full" :style="{ height: chartHeight }"></SkeletonBox>
  </div>
  <div
    v-else-if="isAllDataFetched"
    class="analytics-no-data-found h-5/6 relative right-0 left-0 z-10 bg-white"
  >
    <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
    <p>Data is being fetched.</p>
  </div>
  <div
    v-else-if="!data.length"
    class="analytics-no-data-found h-5/6 relative right-0 left-0 z-10 bg-white"
  >
    <img
      src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
      alt=""
    />
    <p>No Engagement Data Found.</p>
  </div>
  <div v-else class="flex w-full h-full">
    <div class="flex-1">
      <div
        ref="chartRef"
        :style="{ height: isModal ? '85vh' : chartHeight }"
      ></div>
    </div>
  </div>
</template>

<script>
// libraries
import { computed, watch, defineComponent, onMounted } from 'vue'

// components
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

// composable
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'

export default defineComponent({
  name: 'PostEngagementOverTime',
  components: {
    SkeletonBox,
  },
  props: {
    chartHeight: {
      type: [Number, String],
      default: '400px',
    },
    xAxisLabel: {
      type: String,
      default: 'Engagement Rate',
    },
    yAxisLabel: {
      type: String,
      default: 'Followers',
    },
    yAxisSecondLabel: {
      type: String,
      default: 'Engagement',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    chartTypes: {
      type: Array,
      default: () => ['bar'],
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    isAllDataFetched: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    platformName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    // mounted
    onMounted(() => {
      if (props.isModal && chartRef.value) {
        setup(chartOptions.value)
      }
    })

    /**
     * @description creates and sorts(w.r.t. date) the data for series
     * @returns {*}
     */
    const getBarChartData = () => {
      if (!props.data) return []
      return {
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        data: props.data?.map((item) => [
          item.date,
          item.total_posts,
          item.total_engagements,
        ]),
        cursor: 'auto',
      }
    }

    /**
     * tooltip for pulse graph
     */
    const tooltipFormatter = (params) => {
      const { data } = params[0]
      const postsCount = parseInt(data[1])
      const engagementCount = parseInt(data[2])
      const date = new Date(data[0])
      const month = date.toLocaleString('default', { month: 'short' })
      const year = date.getFullYear()

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <span class="text-[#000D21] font-weight-300">${month} ${date.getDate()}, ${year}</span>
          </div>
            <div class="flex items-end justify-between mt-1.5">
              <span class="text-[979CA0] text-sm mr-1.5">Total Posts: </span>
              <span class="text-[#69788E] text-sm font-bold">${postsCount}</span>
            </div>
                      <div class="flex items-end justify-between mt-1.5">
              <span class="text-[979CA0] text-sm mr-1.5">Total Engagement: </span>
              <span class="text-[#69788E] text-sm font-bold">${engagementCount.toLocaleString()}</span>
            </div>
        </div>
        `
    }

    const chartOptions = computed(() => ({
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          crossStyle: {
            color: '#595c5f',
            width: 1,
          },
        },
        formatter: tooltipFormatter,
      },
      legend: props.legend,
      dataZoom: [
        {
          type: 'inside',
        },
        {
          type: 'slider',
        },
      ],
      grid: {
        left: 50,
        top: 30,
        right: 50,
        bottom: 70,
        show: true,
        width: 'auto',
        borderColor: '#E9EFF6',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisPointer: {
          type: 'shadow',
          label: {
            show: false,
          },
        },
        nameGap: 50,
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          margin: 10,
          formatter: (value) => {
            const date = new Date(value)
            const month = date.toLocaleString('default', { month: 'short' })
            const year = date.getFullYear()
            return `${month} ${date.getDate()}, ${year}`
          },
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      },
      yAxis: [
        {
          type: 'value',
          sort: 'ascending',
          name: `{a|} {b|${props.yAxisLabel}}`,
          nameTextStyle: {
            rich: {
              a: {
                backgroundColor: '#8ABAF3',
                width: 11,
                height: 11,
                borderRadius: 2,
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: 14,
                fontSize: 12,
              },
              b: {
                color: '#979CA0',
                fontSize: 12,
                padding: [0, 0, 0, 6],
              },
            },
          },
          position: 'left',
          nameLocation: 'center',
          nameGap: 30,
          min: 'dataMin',
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => value?.toFixed(0),
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            interval: 'int',
            lineStyle: {
              color: '#E9EFF6',
            },
          },
          axisLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
        },
        {
          type: 'value',
          name: `{a|} {b|${props.yAxisSecondLabel}}`,
          nameTextStyle: {
            rich: {
              a: {
                backgroundColor: '#8ED6AF',
                width: 11,
                height: 11,
                borderRadius: 2,
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: 14,
                fontSize: 12,
              },
              b: {
                color: '#979CA0',
                fontSize: 12,
                padding: [0, 0, 0, 6],
              },
            },
          },
          position: 'right',
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => {
              return value?.toLocaleString('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              })
            },
          },
          nameLocation: 'center',
          nameGap: 45,
          splitLine: {
            show: false,
          },
          axisPointer: {
            label: {
              color: 'white',
              backgroundColor: '#8ABAF3',
              precision: '2',
            },
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#333',
              type: 'dashed',
              width: 5,
            },
          },
        },
      ],
      series: [
        {
          name: props.yAxisLabel,
          type: 'bar',
          color: '#8ABAF3',
          colorBy: 'series',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          barCategoryGap: '70%',
          barMaxWidth: 30,
          yAxisIndex: 0,
          encode: {
            x: 0,
            y: 1,
          },
          ...getBarChartData(),
        },
        {
          name: props.yAxisSecondLabel,
          type: 'bar',
          color: '#8ED6AF',
          yAxisIndex: 1,
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          barCategoryGap: '70%',
          barMaxWidth: 30,
          encode: {
            x: 0,
            y: 2,
          },
          ...getBarChartData(),
        },
      ],
    }))

    // setting up the eChart
    const { setup, chartRef } = useEcharts(chartOptions.value, props.chartTypes)

    watch(
      () => chartRef.value,
      () => {
        setup(chartOptions.value)
      }
    )

    return {
      chartRef,
      chartOptions,
    }
  },
})
</script>
