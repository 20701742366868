<template>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <div v-if="plotData.length === 0" class="py-5">
        <SkeletonBox height="250px" width="300px" type="chart" radius="4px"/>
    </div>
    <template v-else>
      <template v-if="state">
        <highcharts
                v-if="plotData.length !== 0"
                class="mt-4 w-100"
                :options="dataOptions"
        >
        </highcharts>

          <div class="h-chart-header mt-4">
              <div
                      class="d-flex flex-column align-items-center justify-content-center"
              >
                  <h2 class="mb-3">The best time to post</h2>

                  <span
                          v-for="(bestTime, index) in recommendedBestTime"
                          :key="index"
                          class="mb-2"
                          @click="
                $emit(
                  'setRecommendedTime',
                  formatBestDate(bestTime.date, bestTime.hour),true
                )
              "
            >
              {{ formatBestDate(bestTime.date, bestTime.hour, true) }}
              <div class="mt-1">
                <i v-for="val in 5 - index" :key="val" class="fas fa-star"></i>
              </div>
            </span>
          </div>
        </div>
      </template>

      <div v-else class="analytics-no-data-found mb-5">
        <img
          src="../../../../../src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p> Not enough historical data available to calculate the metric!</p>
      </div>
    </template>
  </div>
</template>

<script>
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import moment from 'moment'
import isEmpty from 'is-empty'
import highcharts from "@ui/Highcharts";
export default {
    name: 'TimeRecommendationVue',
    components: {
        highcharts,
        SkeletonBox
    },
    props: {
        plotTitle: {
            type: String,
            default: ''
        },
        plotData: {
            type: Array,
            default: () => []
        },
        plotDates: {
            type: Array,
            default: () => []
        },
        state: {
            type: Boolean,
            default: false
        },
        recommendedBestTime: {
            type: Array,
            default: () => []
        },
        userTimezone: {
            type: String,
            default: ''
        },
    },
  data () {
    return {
      plotHours: [
        '12:00 AM',
        '01:00 AM',
        '02:00 AM',
        '03:00 AM',
        '04:00 AM',
        '05:00 AM',
        '06:00 AM',
        '07:00 AM',
        '08:00 AM',
        '09:00 AM',
        '10:00 AM',
        '11:00 AM',
        '12:00 PM',
        '01:00 PM',
        '02:00 PM',
        '03:00 PM',
        '04:00 PM',
        '05:00 PM',
        '06:00 PM',
        '07:00 PM',
        '08:00 PM',
        '09:00 PM',
        '10:00 PM',
        '11:00 PM'
      ]
    }
  },

  computed: {
    dataOptions: {
      get: function () {
        const self = this
        return {
          title: {
            text: ''
          },
          chart: {
            type: 'heatmap',
            marginBottom: 80,
            plotBorderWidth: 0,
            height: '350px'
          },
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 12,
            verticalAlign: 'top',
            x: 10,
            y: 0,
            symbolHeight: 280
          },
          credits: {
            enabled: false
          },
          yAxis: {
            gridLineWidth: 0,

            categories: self.plotDates.map((date) =>
              moment(date, 'DD-MMM-YYYY').format('ddd - DD')
            ),
            title: null,
            reversed: true,
            labels: {
              formatter: function () {
                if (this.isFirst) {
                  return `<span  style="font-weight: 600; color: #0095f3">Today - ${this.value
                    .toString()
                    .substr(-2)}</span>`
                }
                return `<span>${this.value}</span>`
              }
            }
          },

          xAxis: {
            tickLength: 0,
            categories: self.plotHours,
            labels: {
              formatter: function () {
                let val = this.value.toString().substring(0, 2)

                if (val !== '10') {
                  val = val.replace('0', '')
                }

                if (
                  moment().format('h') === val &&
                  moment().format('A') === this.value.toString().substr(-2)
                ) {
                  if (val === '12') {
                    const label = `<div ><span  style="font-weight: 600; color: #0095f3" >${val}</span></div>`

                    return this.isFirst ? label + ' a' : label + ' p'
                  }

                  return `<div><span style="font-weight: 600; color: #0095f3">${val}</span></div>`
                }

                if (val === '12') {
                  const label = `<div ><span >${val}</span></div>`

                  return this.isFirst ? label + ' a' : label + ' p'
                }

                return `<div ><span >${val}</span></div>`
              }
            }
          },

          colorAxis: {
            min: 0,
            minColor: '#faf7f7',
            maxColor: '#0095f3'
          },
          series: [
            {
              name: self.plotTitle,
              borderWidth: 0,

              borderColor: 'rgba(169,169,169,0.35)',
              data: self.plotData,
              dataLabels: {
                enabled: false,
                color: '#000000'
              }
            }
          ],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500
                },
                chartOptions: {
                  yAxis: {
                    labels: {
                      formatter: function () {
                        return this.value.charAt(0)
                      }
                    }
                  }
                }
              }
            ]
          },
          plotOptions: {
            series: {
              pointPadding: 1,
              cursor: 'pointer',
              events: {
                click: function (e) {
                  const timeNow = moment().tz(self.userTimezone)
                  if (
                    timeNow >
                    moment(
                      self.plotDates[parseInt(e.point.y)] +
                        ' ' +
                        self.plotHours[parseInt(e.point.x)],
                      'DD-MMM-YYYY hh:mm a'
                    )
                  ) {
                    self.alertMessage(
                      "Can't schedule posts. \nSelected timestamp is less than current timestamp",
                      'error'
                    )
                  } else {
                    self.$emit(
                      'setRecommendedTime',
                      self.plotDates[parseInt(e.point.y)] +
                        ' ' +
                        self.plotHours[parseInt(e.point.x)],true
                    )
                    console.log(
                      self.plotDates[parseInt(e.point.y)] +
                        ' ' +
                        self.plotHours[parseInt(e.point.x)] +
                        ' ssssssssssss'
                    )
                  }
                  console.log(
                    (timeNow >
                      self.plotDates[parseInt(e.point.y)] +
                        ' ' +
                        self.plotHours[parseInt(e.point.x)]) +
                      ' ssssssssssss'
                  )
                  console.log(timeNow + ' ssssssssssss')
                }
              }
            }
          },
          tooltip: {
            formatter: function () {
              return (
                '<span><b>Best Time To Post</b></span> <br><br>' +
                '<table>' +
                '<tr>' +
                '<td><b>Recommended Time: </b></td>' +
                '<td>' +
                self.plotDates[this.point.y] +
                ' ' +
                self.plotHours[this.point.x] +
                '</td>' +
                '</tr><br>' +
                '<tr>' +
                '<td><b>Potential Engagement Score: </b></td>' +
                '<td>' +
                this.point.value.toFixed(2) +
                '</td>' +
                '</tr><br>' +
                '</table>'
              )
            }
          }
        }
      }
    }
  },
  methods: {
    formatBestDate (date, time, formatted = false) {
      if (!(isEmpty(date) && isEmpty(time))) {
        if (time.toString().length === 1) {
          time = `0${time}`
        }
        return !formatted
          ? moment(`${date} ${time}`).format('DD-MMM-YYYY hh:mm A')
          : moment(`${date} ${time}`).format('llll')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.h-chart-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.3rem 1rem;
  margin-bottom: 2rem;
  border: 1px solid #dee2e6;
  //width: 100%;
  min-width: 270px;
  text-align: center;

  h2 {
    margin-bottom: 0.6rem;
    font-size: 1.05rem;
    font-weight: 500;
  }

  span {
    color: #0095f3;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    padding: 5px 10px;
    width: 100%;

    transition: all ease 0.2s;

    &:hover {
      background-color: #8080800f;
    }

    &:first-of-type {
      color: #1ccb7a;
      font-size: 1.1rem;
    }

    i {
      color: #ffd43b;
    }
  }
}
</style>
