<template>
  <div class="flex flex-nowrap flex-row w-full f-table-row group">
    <div
      v-tooltip.top="'Post Details'"
      class="f-table-cell !w-[400px] px-6 py-3 sticky left-0 bg-white z-10 cursor-pointer"
      data-role="post"
      @click="$emit('preview-plan', plan)"
    >
      <div class="flex items-center">
        <div class="relative">
          <div class="cursor-pointer flex-shrink-0 relative group">

            <template
              v-if="
                structuredPlan.media && (structuredPlan.mediaType === 'image' || structuredPlan.mediaType === 'carousel')
              "
            >
            <div
                v-if="plan?.linkedin_options?.document_added"
                class="hidden group-hover:flex absolute rounded w-32 h-24 top-0 z-10 items-center justify-start text-[18px] bg-[#202324] text-white !bg-opacity-50"
                @click.stop="openPDF"
                >
                <div
                    v-tooltip="{
                      content: 'View PDF in new tab',
                      placement: 'auto',
                    }"
                    class="text-white border rounded-md p-2 flex items-center justify-center bg-[#0000007a] cursor-pointer absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                  >
                  <i :class="'fas fa-file-pdf'"></i>
                </div>
              </div>
              <img
                class="rounded object-cover mr-3.5 w-32 h-24"
                :src="structuredPlan.media[0]"
                alt="media"
                @click.stop="$emit('onLightbox', 'image', structuredPlan.media)"
                @error="onErrorHandle($event)"
              />
            </template>

            <template
              v-else-if="
                structuredPlan.media && structuredPlan.mediaType === 'video'
              "
            >
              <img
                class="rounded object-cover mr-3.5 w-32 h-24"
                :src="structuredPlan.media.thumbnail"
                alt="media"
                @click="$emit('onLightbox', 'video', structuredPlan.media.link)"
                @error="onErrorHandle($event)"
              />
            </template>
          </div>
          <span
            v-show="structuredPlan.mediaType"
            class="absolute bottom-1 right-4 p-1 bg-black-100 opacity-70 text-white rounded-md h-7 text-center text-capitalize"
          >
            {{ structuredPlan.mediaType }}
          </span>
        </div>
        <div class="font-normal select-text w-full line-clamp-4">
          <div
            v-if="structuredPlan.message"
            v-html="structuredPlan.message"
          ></div>
          <div v-else>
            <img
              :src="require('@src/assets/img/no_data_images/no_caption.svg')"
              alt="No caption found!"
              class="object-cover w-full"
              draggable="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="f-table-cell f-table-cell-center-y px-6 py-3"
      data-role="selected-platforms"
    >
      <template v-if="structuredPlan.selectedAccounts.length">
        <template
          v-for="(account, index) in structuredPlan.selectedAccounts.slice(
            0,
            2,
          )"
          :key="index"
        >
          <span
            v-tooltip="account.name"
            class="relative inline-block mr-1.5"
          >
            <img
              :src="account.image"
              alt="DP"
              class="rounded-full object-cover border pointer-events-none w-9 h-9"
              onerror="this.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
            />

            <CstSocialIcon
              :icon="account.platform"
              class="rounded-full w-4 h-4 text-white absolute -right-0.5 -bottom-0.5 flex justify-center items-center text-lg"
            />
          </span>
        </template>
        <b-dropdown
          v-if="structuredPlan.selectedAccounts.length > 2"
          variant="primary"
          toggle-class="bg-white rounded-full border-0 p-0"
          :menu-class="{
            'h-96 overflow-y-auto': structuredPlan.selectedAccounts.length > 10,
          }"
          no-caret
        >
          <template v-slot:button-content>
            <span
              class="inline-flex justify-center items-center w-9 h-9 bg-gray-400 rounded-full text-xs"
            >
              +{{ structuredPlan.selectedAccounts.length - 2 }}
            </span>
          </template>
          <template
            v-for="(account, index) in structuredPlan.selectedAccounts.slice(3)"
            :key="index"
          >
            <b-dropdown-item>
              <div>
                <span class="relative inline-block mr-1.5">
                  <img
                    :src="account.image"
                    alt="DP"
                    class="rounded-full object-cover border pointer-events-none w-9 h-9"
                    data-fallback="0"
                    onerror="this.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                  />
                  <CstSocialIcon
                    :icon="account.platform"
                    class="rounded-full w-4 h-4 text-white absolute -right-0.5 -bottom-0.5 flex justify-center items-center text-lg"
                  />
                </span>
                <span class="text-sm">
                  {{ account.truncateName }}
                </span>
              </div>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
      <div v-else>—</div>
    </div>

    <div
        class="f-table-cell f-table-cell-center-y px-6 py-3"
        data-role="status"
    >
      <div class="flex flex-col space-y-2">
        <div v-tooltip="'See Publishing Status'" class="flex justify-start items-center cursor-pointer" @click="$emit('preview-plan', plan)">
          <span
              class="font-semibold capitalize"
              :class="structuredPlan.statusColorClass"
          >
            {{ structuredPlan.status }}
          </span>
          <div class="ml-2">
            <svg width="1rem" height="1rem" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16" cy="9" r="6" fill="#F2F4F6"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0H11.0913C10.1527 0.529856 9.30821 1.20698 8.58927 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0ZM1 5.14286H6.65664C6.41267 5.78072 6.23214 6.44996 6.12277 7.14286H1C0.447715 7.14286 0 6.69514 0 6.14286C0 5.59057 0.447715 5.14286 1 5.14286ZM1 10.2857H6.12275C6.23211 10.9786 6.41263 11.6478 6.65659 12.2857H1C0.447715 12.2857 0 11.838 0 11.2857C0 10.7334 0.447715 10.2857 1 10.2857ZM1 15.4286H8.58914C9.30805 16.2216 10.1525 16.8987 11.0911 17.4286H1C0.447715 17.4286 0 16.9809 0 16.4286C0 15.8763 0.447715 15.4286 1 15.4286ZM16.4358 3.49561C13.5475 3.49561 11.2061 5.83704 11.2061 8.72535C11.2061 11.6137 13.5475 13.9551 16.4358 13.9551C19.3241 13.9551 21.6655 11.6137 21.6655 8.72535C21.6655 5.83704 19.3241 3.49561 16.4358 3.49561ZM9.20605 8.72535C9.20605 4.73247 12.4429 1.49561 16.4358 1.49561C20.4287 1.49561 23.6655 4.73247 23.6655 8.72535C23.6655 10.4698 23.0477 12.0699 22.019 13.3189L24.602 15.9019C24.9925 16.2924 24.9925 16.9256 24.602 17.3161C24.2115 17.7066 23.5783 17.7066 23.1878 17.3161L20.5457 14.6741C19.3788 15.4819 17.9625 15.9551 16.4358 15.9551C12.4429 15.9551 9.20605 12.7182 9.20605 8.72535Z" fill-opacity="0.7" fill="#000000"/>
            </svg>
          </div>
        </div>
        <div class="text-xs"> {{ structuredPlan.executionTime }}</div>
      </div>
    </div>

    <div
      class="f-table-cell f-table-cell-center-y px-6 py-3"
      data-role="post-type"
    >
      <div class="bg-blue-100 bg-opacity-20 rounded p-2 px-4 inline-block">
        {{ structuredPlan.planType }}
      </div>
    </div>

    <div
        class="f-table-cell f-table-cell-center-y px-6 py-3"
        data-role="created-by"
    >
      <p class="text-sm"> {{ structuredPlan.createdBy }} </p>
    </div>

    <div
        class="f-table-cell f-table-cell-center-y px-6 py-3"
        data-role="updated-at"
    >
      <p class="text-sm"> {{ structuredPlan.createdAt }} </p>
    </div>

    <div
      class="f-table-cell f-table-cell-center-y px-6 py-3"
      data-role="updated-at"
    >
      <p class="text-sm"> {{ structuredPlan.updatedAt }} </p>
    </div>

    <div
      class="f-table-cell f-table-cell-center-y pr-6 py-3 justify-end sticky right-0 !w-[120px] flex-1"
      data-role="actions"
    >
      <div class="hidden group-hover:block">
        <div class="flex space-x-1.5 bg-white p-2 border rounded-md">
          <span
            v-if="
              structuredPlan.status === 'Scheduled' ||
              structuredPlan.status === 'Draft' ||
              structuredPlan.status === 'In Review' ||
              structuredPlan.status === 'Missed Review'
            "
            v-tooltip="'Approve'"
            class="flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-8 h-8"
            @click="handleAction('approve')"
          >
            <span class="flex items-center justify-center">
              <img
                alt="approval_publish"
                src="@assets/img/icons/planner/approve.svg"
              />
            </span>
          </span>

          <span
            v-if="
              structuredPlan.status === 'Scheduled' ||
              structuredPlan.status === 'Draft' ||
              structuredPlan.status === 'In Review' ||
              structuredPlan.status === 'Missed Review'
            "
            v-tooltip="'Reject'"
            class="flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-8 h-8"
            @click="handleAction('reject')"
          >
            <span class="flex items-center justify-center">
              <img
                alt="approval_publish"
                src="@assets/img/icons/planner/more_actions_reject_post.svg"
              />
            </span>
          </span>

          <span
            v-tooltip="'Add Comment'"
            class="flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-8 h-8"
            @click="handleAction('comment')"
          >
            <span class="flex items-center justify-center">
              <img
                alt="approval_publish"
                src="@assets/img/icons/planner/more_actions_comments.svg"
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import CstSocialIcon from '@ui/SocialIcon/CstSocialIcon'
import { memoizeCallback, parseDescriptionHtml } from '@common/lib/helper'
import { EventBus } from '@common/lib/event-bus'
import usePlannerHelper from "@src/modules/planner_v2/composables/usePlannerHelper";

const parseDescriptionHtmlMemoized = memoizeCallback(parseDescriptionHtml)
// Date format options - 'DD MMM YY, hh:mm A'
const DATE_FORMAT = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

export default defineComponent({
  name: 'DataRow',
  components: {
    CstSocialIcon,
  },
  props: {
    plan: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['preview-plan', 'onLightbox'],

  setup(props) {
    const row = ref(props.plan)

    const {renderClass} = usePlannerHelper()

    // Structuring the plan data for the table row
    const structuredPlan = computed(() => {
      const item = row.value

      const data = {
        id: item._id,
      }

      // Post Type
      if (
        item.type === 'Composer Social' ||
        item.type === 'Social Content' ||
        item.type === 'Evergreen Automation' ||
        ((item.type === 'Automation Article' ||
          item.type === 'Automation Video') &&
          'account_selection' in item)
      ) {
        data.planType = 'Social Post'
      } else if (
        item.type === 'Composer Article' ||
        ((item.type === 'Automation Article' ||
          item.type === 'Automation Video') &&
          'blog_selection' in item)
      ) {
        data.planType = 'Blog Post'
      } else {
        data.planType = item.type
      }

      if (data.planType === 'Blog Post') {
        data.message = item.post.title ?? ''

        if (item?.post?.image?.link) {
          data.media = [item.post.image.link]
          data.mediaType = 'image'
        }
      } else {
        // if the common box is checked
        if (item.common_box_status) {
          const sharingDetails = item.common_sharing_details

          // Plan message
          data.message =
            parseDescriptionHtmlMemoized(
              sharingDetails?.message ? sharingDetails.message : ''
            ) ?? ''

          // Plan media
          if (sharingDetails.image.length > 0) {
            data.media = sharingDetails.image
            data.mediaType = 'image'
          } else if (sharingDetails.video.link) {
            data.media = sharingDetails.video
            data.mediaType = 'video'
          } else {
            data.media = null
          }
        } else {
          // loop through the accounts selection object and pick the first one for the details
          for (const account in item.account_selection) {
            if (item.account_selection[account].length > 0) {
              const sharingDetails = item[`${account}_sharing_details`]
              // Plan message
              data.message =
                parseDescriptionHtmlMemoized(sharingDetails.message) ?? ''

              // Plan media
              if (
                sharingDetails.image &&
                typeof sharingDetails.image === 'string'
              ) {
                data.media = [sharingDetails.image]
                data.mediaType = 'image'
              } else if (sharingDetails.image.length > 0) {
                data.media = sharingDetails.image
                data.mediaType = 'image'
              } else if (sharingDetails.video.link) {
                data.media = sharingDetails.video
                data.mediaType = 'video'
              }

              break
            }
          }
        }
        if(item.linkedin_options?.is_carousel){
          if(item.linkedin_options?.document_added){
            data.media = [item.linkedin_options.document?.thumbnail]
          }
          data.mediaType = 'carousel'
        }

        if(item.carousel_options?.is_carousel_post && item.carousel_options?.cards[0]?.picture){
          data.media = [item.carousel_options.cards[0].picture]
          data.mediaType = 'carousel'
        }
      }

      // Post Created By
      data.createdBy = item.added_by

      // Selected Accounts, also structuring the selected accounts for the table row
      data.selectedAccounts = []

      if (data.planType === 'Blog Post' && item.blog_details) {
        data.selectedAccounts.push({
          image: item.blog_details.platform_logo,
          name: item.blog_details.platform_name,
          truncateName:
            item.blog_details.platform_name.length > 15
              ? `${item.blog_details.platform_name.substring(0, 15)}...`
              : item.blog_details.platform_name,
          platform: item.blog_details.platform_type,
        })
      } else {
        for (const account in item.account_selection) {
          if (row.value.account_selection[account].length > 0) {
            const accountDetails = row.value.account_selection[account].map(
              (detail) => {
                let image = detail.image
                let name = detail.platform_name

                if (!image) {
                  if (account === 'gmb') {
                    image =
                      'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
                  } else if (
                    account === 'youtube' ||
                    account === 'tumblr' ||
                    account === 'tiktok' ||
                    account === 'threads'
                  ) {
                    image = detail.platform_logo
                  } else {
                    image =
                      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  }
                }

                if (!name) {
                  name = detail.name
                }

                return {
                  ...detail,
                  image,
                  name,
                  truncateName:
                    name.length > 15 ? `${name.substring(0, 15)}...` : name,
                  platform: account,
                }
              }
            )
            data.selectedAccounts.push(...accountDetails)
          }
        }
      }

      // Post Last Updated At (Converted to 'DD MMM YY, hh:mm A' format)
      data.updatedAt = new Date(item.updated_at).toLocaleString(
        'en-US',
        DATE_FORMAT
      )

      // Post Created At (Converted to 'DD MMM YY, hh:mm A' format)
      data.createdAt = new Date(item.created_at).toLocaleString(
        'en-US',
        DATE_FORMAT
      )

      // Post Execution Time (Converted to 'DD MMM YY, hh:mm A' format)
      data.executionTime = new Date(item.execution_time.date).toLocaleString(
        'en-US',
        DATE_FORMAT
      )

      // Post Status
      data.render = renderClass(item)
      data.status = data.render?.tooltip

      if(item.partially_failed) {
        data.statusColorClass = 'text-status-partially_failed'
        return data
      }

      // Post Status color
      data.statusColorClass = 'text-status-draft'
      switch (item.post_state) {
        case 'published':
          data.statusColorClass = 'text-status-published'
          break
        case 'draft':
          data.statusColorClass = 'text-status-draft'
          break
        case 'scheduled':
          data.statusColorClass = 'text-status-scheduled'
          break
        case 'partially_failed':
          data.statusColorClass = 'text-status-partially_failed'
          break
        case 'failed':
          data.statusColorClass = 'text-status-failed'
          break
        case 'rejected':
          data.statusColorClass = 'text-status-rejected'
          break
        case 'reviewed':
        case 'review':
          data.statusColorClass = 'text-status-under_review'
          break
        case 'missedReview':
          data.statusColorClass = 'text-status-missed_review'
          break
        case 'queued':
        case 'processing':
          data.statusColorClass = 'text-status-in_progress'
          break
      }

      return data
    })

    // Checkbox checked state
    const checked = computed(() => {
      return props.selected
    })

    /**
     * On Plan Image load error
     */
    const onErrorHandle = (event) => {
      event.target.src = require('@src/assets/img/no_data_images/no_media_found.svg')
    }

    /**
     * Handle the action
     */
    const handleAction = (action) => {
      console.log(action)
      EventBus.$emit('external-actions::show', {
        type: action,
        plans: [row.value._id],
      })
    }

    /**
     * Open PDF in new tab
     */
    const openPDF = () => {
      window.open(row.value?.linkedin_options?.document?.link, '_blank')
    }

    return {
      structuredPlan,
      onErrorHandle,
      handleAction,
      checked,
      openPDF,
    }
  },
})
</script>

<style lang="scss" scoped>
.f-table-cell {
  @apply w-[245px] flex-shrink-0;

  @media (max-width: 1280px) {
    @apply w-[200px];
  }
}

.f-table-cell-center-y {
  @apply flex items-center;
}

.f-table-row:not(:last-child) > .f-table-cell {
  border-bottom: 1px solid #ebebeb;
}

.f-table-row:hover > .f-table-cell {
  background-color: #f1faff !important;
}
</style>
