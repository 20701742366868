<template>
  <div class="h-100 d-flex flex-column">
    <InboxConversationHead
      :active-inbox-detail="activeInboxDetail"
      :reset-view="resetView"
    ></InboxConversationHead>
    <SavedReplyModal
      :active-inbox-detail="activeInboxDetail"
      :apply-saved-reply="applySavedReply"
    ></SavedReplyModal>

    <div
      v-if="activeMessages"
      id="chat_conversation_block"
      class="conversation_messages"
    >
      <div v-if="timestamp === null" class="sep_line">
        <strong class="text">This is the start of this conversation</strong>
      </div>
      <div v-else-if="activeMessages.length <= 0" class="sep_line">
        <strong class="text">No messages found</strong>
      </div>
      <div v-else class="loader">
        <InfiniteLoading
          v-if="isScrollActive"
          spinner="spiral"
          direction="top"
          :distance="scrollDistance()"
          @infinite="infiniteHandler"
        ></InfiniteLoading>
        <clip-loader
          class="d-inline-block align-middle ml-1"
          color="#989eb5"
          :size="'20px'"
        />
      </div>

      <template v-for="(messages, index) in activeMessages" :key="index">
        <div class="sep_line">
          <span class="text">{{ index }}</span>
        </div>

        <template
          v-for="(message, message_index) in messages"
        >
          <MessageStatus
            v-if="
              message.event_type === 'assigned' ||
              message.event_type === 'marked_done' ||
              message.event_type === 'archived'
            "
            :key="message.uuid"
            :message="message"
          >
          </MessageStatus>

          <MessageSending
            v-else-if="message.sending"
            :key="message.uuid"
            :message="message"
            :message-loader="messageLoader"
            :temp="temp"
            :index="index"
            :message-index="message_index"
            :append-in-active-messages="appendInActiveMessages"
            :remove-message="removeMessage"
            :emit-event-for-note="emitEventForNote"
          >
          </MessageSending>

          <MessageDialogue
            v-else
            :key="message.uuid"
            :message="message"
            :temp="temp"
            :index="index"
            :message-index="message_index"
            :update-bookmark-status="updateBookmarkStatus"
          >
          </MessageDialogue>
        </template>
      </template>

      <div v-if="typingUsers.length > 0" class="message m_out">
        <div class="sep_line conversation_notification">
          <div class="text">
            <div class="user_block d-flex align-items-center">
              <div class="user_content">
                <p class="name"></p>
                <p class="name">
                  <span v-for="(typingUser, index) in typingUsers" :key="index"
                    ><strong>{{ typingUser }}</strong>
                    <span
                      v-if="
                        typingUsers.length === 1 ||
                        typingUsers.length - 1 === index
                      "
                    ></span>
                    <span v-else-if="typingUsers.length - 2 === index">
                      and
                    </span>
                    <span v-else-if="typingUsers.length > 1">, </span>
                  </span>
                  is typing...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat_tool" :class="{ _note_active: sendAs === 'Note' }">
      <div class="chat_tool_inner">
        <div class="c_bottom">
          <template v-if="sendAs !== 'Note'">
            <textarea
              id="common_sharing_message"
              ref="common_sharing_message_inbox"
              v-model="messageToSend"
              name=""
              :placeholder="getPlaceholder()"
              :disabled="messageLoader"
              cols="30"
              rows="10"
              :maxlength="
                activeInboxDetail.platform === 'instagram' ? '1000' : ''
              "
              @keydown.enter.exact.prevent="send($event)"
              @input="typingStatusChanged"
            >
            </textarea>
          </template>
          <template v-else>
            <AtTa
              ref="at"
              :members="teamMembers"
              name-key="name"
              @insert="mentionMember"
            >
              <template
                v-slot:item="s"
              >
                <img
                  :src="
                    s.item.image
                      ? s.item.image
                      : commonMethods.getDefaultImage()
                  "
                  height="20"
                  width="20"
                 alt=""/>
                <span v-text="s.item.name"> </span>
              </template>
              <textarea
                id="common_sharing_message"
                ref="common_sharing_note_message_inbox"
                v-model="messageToSend"
                name=""
                :placeholder="'Add a note to this conversation...'"
                :disabled="messageLoader"
                cols="30"
                rows="10"
                @keydown.enter.exact.prevent="send($event)"
                @change="typingStatusChanged"
              >
              </textarea>
            </AtTa>
          </template>
        </div>
        <div class="c_top d-flex align-items-center">
          <div class="top_left">
            <div class="attachment_btn">
              <Emoji
                  dropdown-position="top_side"
                  type="Common"
              />
            </div>
            <div v-if="sendAs !== 'Note'" class="attachment_btn !mr-0">
              <label for="attachment_file">
                <i
                  v-tooltip.bottom="'Attach a file'"
                  class="far fa-paperclip"
                />
              </label>

              <input
                id="attachment_file"
                ref="fileInput"
                type="file"
                data-index=""
                :accept="
                  activeInboxDetail.platform === 'instagram'
                    ? 'image/x-png,image/jpeg'
                    : '*'
                "
                @change="attachMedia($event)"
              />

              <span
                v-if="attachment !== null"
                v-tooltip.top="attachment.name"
                class="attachment_file"
              >
                <i
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    attachment-type
                    fal
                    fa-file
                  "
                />
                {{ commonMethods.limitText(attachment.name, 15) }}
                <i
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    cross_btn
                    fal
                    fa-times
                  "
                  @click="removeMedia"
                />
              </span>
            </div>
            <i
              v-if="sendAs !== 'Note'"
              v-tooltip.bottom="'Saved Replies'"
              class="icon-Save attachment_btn m-3"
              style="cursor: pointer"
              @click="$bvModal.show('savedReplyModal')"
            />
          </div>
          <div class="top_right ml-auto d-flex align-items-center">
            <div
              v-if="
                (messageToSend !== '' || attachment !== null) &&
                messageLoader === false
              "
              class="
                d-flex
                align-items-center
                justify-content-left
                failed_option
              "
              style="margin-bottom: 0px; margin-right: 0.625rem"
              @click=";(messageToSend = ''), (attachment = null)"
            >
              <span class="link">Clear </span>
            </div>
            <div
              class="
                btn_dropdown btn-group
                default_style_dropdown
                inbox_message_dropdown
                radio_dropdown
              "
            >
              <button
                type="button"
                class="btn_click btn"
                :disabled="
                  messageToSend === '' &&
                  attachment === null &&
                  messageLoader === false
                "
                @click="send(null)"
              >
                {{ sendAs === 'Message' ? 'Send ' : 'Add ' }} {{ sendAs }}
                <clip-loader
                  v-if="messageLoader"
                  class="d-inline-block align-middle ml-1"
                  color="#ffffff"
                  :size="'14px'"
                />
              </button>
              <button
                type="button"
                class="btn_arrow btn dropdown-toggle dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <i class="dropdown_arrow icon_last cs-angle-down"> </i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li
                    v-for="option in sendingOptions"
                    :key="option"
                    class="list_item_li"
                  >
                    <div class="radio_input_image d-block">
                      <input
                        :id="option"
                        v-model="sendAs"
                        :value="option"
                        type="radio"
                        name="sendMessageType"
                      />
                      <label
                        :for="option"
                        class="radio_right with_checked_icon d-block"
                      >
                        <div class="user_block d-flex align-items-center">
                          <div class="user_content"
                            ><p class="name">{{ option }}</p></div
                          >
                        </div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import * as _ from 'underscore'
import InfiniteLoading from 'vue-infinite-loading'
import _debounce from 'lodash.debounce'
import { pusherAuthSocketInbox } from '@common/lib/pusher'
import Vue from 'vue'

import { EventBus } from '@common/lib/event-bus'

import { socket } from '@src/modules/inbox/store/constants'
import Emoji from '@src/modules/publish/components/posting/social/emoji/Emoji'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import MessageSending from '@src/modules/inbox/components/reusable/conversation/MessageSending'
import MessageDialogue from '@src/modules/inbox/components/reusable/conversation/MessageDialogue'
import MessageStatus from '@src/modules/inbox/components/reusable/conversation/MessageStatus'
import InboxConversationHead from '@src/modules/inbox/components/InboxConversationHead'
import SavedReplyModal from '@src/modules/inbox/components/savereplies/SavedReplyModal'

const moment = require('moment')

export default {
  components: {
    Emoji,
    InboxConversationHead,
    AtTa,
    InfiniteLoading,
    MessageStatus,
    MessageDialogue,
    MessageSending,
    SavedReplyModal,
  },
  props: { activeInboxDetail: { type: Object, default: () => {} },
    resetView: {
      type: Function,
      default: () => {},
    }},
  data() {
    return {
      temp: this,
      sendAs: 'Message',
      sendingOptions: ['Message', 'Note'],
      attachment: null,
      activeMessages: [],
      messageToSend: '',
      commonMethods,
      teamMembers: [],
      timestamp: Math.floor(Date.now() / 1000),
      isScrollActive: false,
      firstTime: true,
      activeUser: null,
      typingUsers: [],
      messageLoader: false,
      mentionedUsers: [],
      conversation_channel: '',
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspace',
      'getProfile',
      'getWorkspaceInboxTeamMembers',
      'getActiveWorkspaceMembersDetails',
    ]),
  },
  watch: {
    activeInboxDetail: async function (newVal, oldVal) {
      // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (
        oldVal.element_details.element_id !== newVal.element_details.element_id
      ) {
        this.removeSocketListners(oldVal)
        this.bindPusherConversations()

        this.timestamp = Math.floor(Date.now() / 1000)
        this.activeMessages = []
        this.messageToSend = ''
        this.attachment = null
        this.firstTime = true
        this.isScrollActive = false
        console.log('called from active')
        await this.getActiveMessages()
      }
    },
    messageToSend: async function (newVal, oldVal) {
      if (newVal !== '') {
        this.emitTypingUserData(true)
      }
    },
  },
  created() {
      console.log("CREATED::Conversation.vue")
  },
  async beforeMount() {
    await this.getActiveMessages()
    this.createMentionUsers(this.getWorkspaceInboxTeamMembers)
    commonMethods.setActiveUser(this)
  },
  beforeUnmount() {
    this.removeSocketListners(this.activeInboxDetail)
    if (this.conversation_channel) {
      const channelForNewMessage =
        'channel-message-' +
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id
      const channelForTyping =
        'channel-typing-' +
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id

      this.conversation_channel.unbind(channelForNewMessage)
      this.conversation_channel.unbind(channelForTyping)
      this.conversation_channel.unsubscribe(
        'presence-conversations-' + this.getActiveWorkspace._id
      )
    }

    EventBus.$off('update-conversation-textarea')
  },
  mounted() {
    const pusher = pusherAuthSocketInbox(
      this.getProfile._id,
      this.getProfile.full_name,
      this.getProfile.email
    )
    this.conversation_channel = pusher.subscribe(
      'presence-conversations-' + this.getActiveWorkspace._id
    )
    this.bindPusherConversations()

    EventBus.$on('update-conversation-textarea', (data) => {
      this.messageToSend = data
    })
  },
  methods: {
    ...mapActions(['fetchMessages', 'reply', 'addNoteToInboxDetails']),
    bindPusherConversations() {
      console.log('binding for conversations ... ')

      const channelForNewMessage =
        'channel-message-' +
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id
      const channelForTyping =
        'channel-typing-' +
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id

      const temp = this
      this.conversation_channel.bind(
        channelForNewMessage,
        async function (data) {
          console.log('recieving on --> ', channelForNewMessage)
          console.log('message data --> ', data)
          const sleep = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms))
          await sleep(1000)
          const latestDate = Object.keys(temp.activeMessages)[
            Object.keys(temp.activeMessages).length - 1
          ]
          let messageExists = false
          const message = data.message

          const tempMsg = temp?.activeMessages?.[latestDate]?.filter(
            (element) =>
              message?.message_id?.toString() === element?.message_id?.toString()
          ) ?? [];
          if (tempMsg.length > 0) {
            if ('message_id' in tempMsg[0]) {
              messageExists = true
            }
          }

          if (
            message.element_id ===
              temp.activeInboxDetail.element_details.element_id &&
            message.workspace_id === temp.activeInboxDetail.workspace_id &&
            !messageExists
          ) {
            const currentDate = moment(message.created_time)
              .tz('UTC')
              .format('Do MMMM, YYYY')
            temp.appendInActiveMessages(currentDate, message)
            if (data.event === 'new_message') {
              temp.moveScrollBottom(true)
            }
          }
        }
      )

      this.conversation_channel.bind(channelForTyping, (data) => {
        console.log('receiving on --> ', channelForTyping)
        if (
          data.element_id ===
            this.activeInboxDetail.element_details.element_id &&
          data.workspace_id === this.activeInboxDetail.workspace_id
        ) {
          if (this.activeUser.user_id !== data.user.user_id) {
            if (data.typing) {
              const index = this.typingUsers.findIndex(
                (x) => x === data.user.user_name
              )
              if (index < 0) {
                this.typingUsers.push(data.user.user_name)
              }
            } else {
              this.typingUsers = _.without(
                this.typingUsers,
                data.user.user_name
              )
            }
          }
        }
      })
    },
    async infiniteHandler($state) {
      console.log('here now ..')
      this.isScrollActive = false
      await this.getActiveMessages().then((result) => {
        console.debug(result, 'result')
        if (result) {
          if (result.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        }
      })
    },
    scrollDistance() {
      const el = document.getElementById('chat_conversation_block')

      return el.scrollHeight / 15
    },
    scrollHelper(el) {
      if (el != null) {
        el.scrollTop = this.firstTime ? el.scrollHeight : el.scrollHeight / 10
        this.isScrollActive = true
        this.firstTime = false
      }
    },
    moveScrollBottom(moveToBottom = false) {
      console.debug('Method:moveScrollBottom')
      const el = document.getElementById('chat_conversation_block')
      console.debug(el, 'element')

      if (moveToBottom) {
        setTimeout(() => {
          el.scrollTop = el.scrollHeight
        }, 10)
      } else {
        setTimeout(this.scrollHelper(el), 10)
      }
    },
    createMentionUsers(teamMembers) {
      for (const item in teamMembers) {
        if (teamMembers[item]?.user && teamMembers[item]?.user_id) {
          this.teamMembers.push({
            id: teamMembers[item].user_id,
            name: teamMembers[item].user.full_name,
            image: teamMembers[item].user.image,
            email: teamMembers[item].user.email,
          })
        }
      }
    },

    mentionMember(userItem) {
      const index = this.mentionedUsers.findIndex((x) => x.id === userItem.id)
      if (index < 0) {
        this.mentionedUsers.push(userItem)
      }
    },

    getPlaceholder() {
      if (commonMethods.isEmpty(this.activeInboxDetail.inbox_details)) {
        if (
          commonMethods.isEmpty(this.activeInboxDetail.inbox_details.posted_by)
        ) {
          return (
            'Reply to ' +
            this.activeInboxDetail.inbox_details.posted_by.name +
            ' here...'
          )
        }
      } else {
        return 'Type your message here...'
      }
    },

    async updateBookmarkStatus(resp, index, messageIndex) {
      this.$set(this.activeMessages[index], messageIndex, resp)
      EventBus.$emit('is-bookmarked-event', resp)
    },

    async getActiveMessages() {
      if (this.timestamp != null) {
        console.log('previous timestamp -------> ', this.timestamp)
        const messageResponse = await this.fetchMessages({
          element_id: this.activeInboxDetail.element_details.element_id,
          workspace_id: this.activeInboxDetail.workspace_id,
          timestamp: this.timestamp,
        })

        if (messageResponse.isValid) {
          await this.addNewMessages(await messageResponse.messages)
          this.timestamp = await messageResponse.next

          console.log('updated timestamp -------> ', this.timestamp)
          console.log('new messages ', this.activeMessages)
        } else {
          this.activeMessages = []
        }
        this.moveScrollBottom()
      }
    },

    addNewMessages(newMessages) {
      for (const [currentDate, messages] of Object.entries(newMessages)) {
        messages.forEach((message) => {
          if (!(currentDate in this.activeMessages)) {
            this.activeMessages = { [currentDate]: [], ...this.activeMessages }
          }
          const index = this.activeMessages[currentDate].findIndex(
            (x) => x.uuid === message.uuid
          )
          if (index < 0) {
            this.activeMessages[currentDate].unshift(message)
          }
        })
      }
      console.log('active messages ==> ', this.activeMessages)
    },

    appendInActiveMessages(currentDate, message) {
      if (this.activeMessages[currentDate] == null) {
        if (!(currentDate in this.activeMessages)) {
          this.activeMessages[currentDate] = []
        }
        this.activeMessages[currentDate].push(message)
      } else {
        const index = this.activeMessages[currentDate].findIndex(
          (x) => x.message_id === message.message_id
        )
        if (index < 0) {
          this.activeMessages[currentDate].push(message)
        } else {
          this.activeMessages[currentDate].push(message)
        }
      }
    },

    getMessageType() {
      if (this.attachment !== null) {
        const mediaTypes = ['image', 'video', 'file', 'audio']
        if (this.attachment.type.indexOf('text') >= 0) {
          return 'file'
        }
        for (const type of mediaTypes) {
          if (this.attachment.type.indexOf(type) >= 0) {
            return type
          }
        }
      } else if (this.messageToSend !== '') {
        return 'text'
      }

      return null
    },

    getAttachmentType() {
      if (this.attachment !== null) {
        const mediaTypes = ['image', 'video', 'file', 'audio']
        if (this.attachment.type.indexOf('text') >= 0) {
          return 'file'
        }
        for (const type of mediaTypes) {
          if (this.attachment.type.indexOf(type) >= 0) {
            return type
          }
        }
      }
      return null
    },

    removeMedia() {
      this.attachment = null
      if (this.sendAs !== 'Note') {
        this.$refs.fileInput.value = null
      }
    },

    attachMedia(event) {
      if (event.target.files.length > 0) {
        const extension = event.target.files[0].name.split('.')
        if (
          this.activeInboxDetail.platform === 'instagram' &&
          _.contains(
            ['pdf', 'docx', 'xlsx', 'txt', 'csv'],
            extension[extension.length - 1]
          )
        ) {
          const name = event.target.files[0].name.split('.')
          this.alertMessage(
            'Instagram inbox accept media files only. </br> ".' +
              name[name.length - 1] +
              '" was found in file.',
            'error'
          )
        } else {
          this.attachment = event.target.files[0]
        }
      } else {
        this.removeMedia()
      }
    },

    async sendMessage() {
      if (!this.messageToSend && !this.attachment) {
        this.alertMessage(
          'Message and attachment both cannot be empty.',
          'error'
        )
        this.messageLoader = false
        return
      }

      const hasMessage = this.messageToSend && this.messageToSend !== ''
      const hasAttachment = this.attachment != null

      if (
        this.activeInboxDetail.platform === 'facebook' ||
        this.activeInboxDetail.platform === 'instagram'
      ) {
        // for fb only
        // need to make api call twice incase both text and attachment exist
        if (hasMessage) {
          const payload = this.getPayloadForTextMessage()
          const messageToAppend = {
            message: this.messageToSend,
            sticker: null,
            attachment: null,
            uuid:
              this.activeInboxDetail.platform_id +
              '_' +
              Math.round(Math.random() * 100000),
            event_type: 'message',
            sending: true,
            has_failed: false,
            payload,
          }

          await this.makeApiCall(payload, messageToAppend)
        }

        if (hasAttachment) {
          const payload = this.getPayloadForAttachmentMessage()

          if (payload) {
            const messageToAppend = {
              message: null,
              sticker: null,
              attachment: this.attachment,
              uuid:
                this.activeInboxDetail.platform_id +
                '_' +
                Math.round(Math.random() * 100000),
              event_type: 'message',
              sending: true,
              has_failed: false,
              payload,
            }

            await this.makeApiCall(payload, messageToAppend)
          }
        }

        this.resetMessage()
        EventBus.$emit('refresh-inbox-detail')
      } else if (this.activeInboxDetail.platform === 'twitter') {
        const payload = this.getPayloadForCombined()
        const messageToAppend = {
          message: this.messageToSend,
          sticker: null,
          attachment: this.attachment,
          uuid:
            this.activeInboxDetail.platform_id +
            '_' +
            Math.round(Math.random() * 100000),
          event_type: 'message',
          sending: true,
          has_failed: false,
          payload,
        }
        this.makeApiCall(payload, messageToAppend)
        this.resetMessage()
        EventBus.$emit('refresh-inbox-detail')
      } else {
        this.alertMessage(
          'Something went wrong, please contact support.',
          'error'
        )
        this.messageLoader = false
      }
    },

    getPayloadForTextMessage() {
      const payload = new FormData()
      payload.append('platform', this.activeInboxDetail.platform)
      payload.append('platform_id', this.activeInboxDetail.platform_id)
      payload.append(
        'recipient_id',
        this.activeInboxDetail.element_details.recipient_id
      )
      payload.append('workspace_id', this.activeInboxDetail.workspace_id)
      payload.append('reply_for', 'conversation')
      payload.append(
        'element_id',
        this.activeInboxDetail.element_details.element_id
      )
      payload.append('type', 'text')
      payload.append('message', this.messageToSend)
      payload.append('attachment_file', null)
      payload.append('replied_by', this.activeUser.user_id)

      return payload
    },

    getPayloadForAttachmentMessage() {
      const attachmentType = this.getAttachmentType()

      if (attachmentType === null) {
        return null
      }

      const payload = new FormData()

      payload.append('platform', this.activeInboxDetail.platform)
      payload.append('platform_id', this.activeInboxDetail.platform_id)
      payload.append(
        'recipient_id',
        this.activeInboxDetail.element_details.recipient_id
      )
      payload.append('workspace_id', this.activeInboxDetail.workspace_id)
      payload.append('reply_for', 'conversation')
      payload.append(
        'element_id',
        this.activeInboxDetail.element_details.element_id
      )
      payload.append('type', attachmentType)
      payload.append('message', '')
      payload.append('attachment_file', this.attachment)
      payload.append('replied_by', this.activeUser.user_id)

      return payload
    },

    getPayloadForCombined() {
      const messageType = this.getMessageType()

      const payload = new FormData()
      payload.append('platform', this.activeInboxDetail.platform)
      payload.append('platform_id', this.activeInboxDetail.platform_id)
      payload.append(
        'recipient_id',
        this.activeInboxDetail.element_details.recipient_id
      )
      payload.append('workspace_id', this.activeInboxDetail.workspace_id)
      payload.append('reply_for', 'conversation')
      payload.append(
        'element_id',
        this.activeInboxDetail.element_details.element_id
      )
      payload.append('type', messageType)
      payload.append('message', this.messageToSend)
      payload.append('attachment_file', this.attachment)
      payload.append('replied_by', this.activeUser.user_id)
      return payload
    },

    async makeApiCall(payload, messageToAppend) {
      const messageAppend = JSON.parse(JSON.stringify(messageToAppend))

      const currentDate = moment().format('Do MMMM, YYYY')
      this.appendInActiveMessages(currentDate, messageAppend)

      this.moveScrollBottom(true)

      const response = await this.reply(payload)
      if (response.isValid) {
        this.removeMessage(currentDate, messageAppend.uuid)
        this.appendInActiveMessages(currentDate, response.response)
        this.moveScrollBottom(true)
      } else {
        this.removeMessage(currentDate, messageToAppend.uuid)
        messageAppend.has_failed = true
        messageAppend.error_message = response.response.error || null
        if (
          messageAppend.error_message ===
          '(#10) This message is sent outside of allowed window.'
        ) {
          messageAppend.error_message =
            "Can't reply to a message more than 7 days old!"
        }
        this.appendInActiveMessages(currentDate, messageAppend)
      }
    },

    async sendNote() {
      const mentioned = []
      const temp = this
      _.forEach(this.mentionedUsers, function (e, k) {
        if (temp.messageToSend.indexOf('@' + e.name) >= 0) {
          mentioned.push(e.id)
        }
      })

      const payload = {
        platform: this.activeInboxDetail.platform,
        platform_id: this.activeInboxDetail.platform_id,
        message: this.messageToSend,
        recipient_id: this.activeInboxDetail.element_details.recipient_id,
        element_id: this.activeInboxDetail.element_details.element_id,
        workspace_id: this.activeInboxDetail.workspace_id,
        mentioned_users: mentioned,
      }

      const messageToAppend = {
        message: this.messageToSend,
        uuid:
          this.activeInboxDetail.platform_id +
          '_' +
          Math.round(Math.random() * 10),
        event_type: 'note',
        sending: true,
        has_failed: false,
        payload,
      }

      const currentDate = moment().format('Do MMMM, YYYY')
      this.appendInActiveMessages(currentDate, messageToAppend)

      this.moveScrollBottom(true)
      this.resetMessage()

      const response = await this.addNoteToInboxDetails(payload)
      if (response.isValid) {
        this.removeMessage(currentDate, messageToAppend.uuid)
        this.appendInActiveMessages(currentDate, response.note)
        this.emitEventForNote(response.note)
        this.moveScrollBottom(true)
      } else {
        messageToAppend.has_failed = true
        this.appendInActiveMessages(currentDate, messageToAppend)
      }
    },

    removeMessage(currentDate, uuid) {
      if (this.activeMessages[currentDate]?.length) {
        const index = this.activeMessages[currentDate]?.findIndex(
          (x) => x.uuid === uuid
        )
        this.activeMessages[currentDate].splice(index, 1)
      }
    },

    emitEventForNote(note) {
      EventBus.$emit('is-bookmarked-event', note)
    },

    async send(e = null) {
      if (this.messageLoader === false) {
        this.messageLoader = true

        const canSend = e === null ? true : e.keyCode === 13 && !e.shiftKey
        if (canSend) {
          if (e !== null) {
            e.preventDefault()
          }
          console.log(this.sendAs, 'this.sendAs', this.messageToSend)
          if (this.sendAs === 'Message') {
            this.sendMessage()
          } else {
            if (this.messageToSend === '') {
              this.alertMessage('Note cannot be empty.', 'error')
              this.messageLoader = false
              return
            }
            await this.sendNote()
          }
        }
      }
    },

    resetMessage() {
      this.messageToSend = ''
      this.messageLoader = false
      this.messageLoader = false
      if (this.sendAs === 'Note') {
        this.$refs.at.closePanel()
      }

      this.removeMedia()
    },

    goToPost(postId) {
      this.$emit('go-to', {
        element_id: postId,
      })
    },

    emitTypingUserData(status) {
      socket.emit('channel-typing', {
        workspace_id: this.activeInboxDetail.workspace_id,
        element_id: this.activeInboxDetail.element_details.element_id,
        user: this.activeUser,
        typing: status,
      })
    },

    typingStatusChanged: _debounce(function (ev) {
      console.log('stopped typing')
      this.emitTypingUserData(false)
    }, 1000),

    removeSocketListners(value) {
      console.log('removing socket for conversations ... ')
      const tail = value.element_details.element_id + '-' + value.workspace_id

      const channelForNewMessage = 'channel-message-' + tail
      const channelForTyping = 'channel-typing-' + tail

      this.conversation_channel.unbind(channelForNewMessage)
      this.conversation_channel.unbind(channelForTyping)
    },
    applySavedReply(applyText) {
      this.messageToSend = applyText
    },
  },
}
</script>

<style lang="less" scoped>
textarea:disabled {
  background: white;
}
</style>
