<script setup>
import OverviewSection from '@src/modules/analytics/views/youtube/components/OverviewSection'
import PostsSection from '@src/modules/analytics/views/youtube/components/PostsSection'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import CstAlert from '@ui/Alert/CstAlert'
import TabsComponent from '@src/modules/analytics/views/common/TabsComponent'
import AnalyticsLoading from '@src/modules/analytics/components/common/AnalyticsLoading'
import { EventBus } from '@common/lib/event-bus'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { onBeforeUnmount, ref, watch, onMounted, onBeforeMount } from 'vue'
import { useStore } from '@state/base'
import { youtube } from '@src/modules/integration/store/states/mutation-types'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'

const { getters, dispatch } = useStore()

const youtubeAccounts = ref([])
const selectedAccount = ref(null)
const dateFilter = ref('')
const previousDate = ref('')
const fetchingData = ref(false)
const analyticsChannel = ref('')
const mainComponentRef = ref(null)
const { postsLimit, isReconnectRequired } = useYoutubeAnalytics()

watch(selectedAccount, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    setTimeout(() => {
      mainComponentRef.value?.scrollIntoView({ behavior: 'smooth' })
    }, 0)
    removeSocketListeners(oldVal)
    bindSocketListeners()
  }
})

onBeforeMount(() => {
  EventBus.$on('workspace-changed', () => {
    console.debug('workspace-changed')
    resetState()

    const pusher = pusherAuthSocketAnalytics()
    analyticsChannel.value = pusher.subscribe(
      'analytics-channel-' + getters.getActiveWorkspace._id
    )
    if (selectedAccount.value?.platform_identifier) {
      bindSocketListeners()
    }
  })
})

onMounted(() => {
  const pusher = pusherAuthSocketAnalytics()
  analyticsChannel.value = pusher.subscribe(
    'analytics-channel-' + getters.getActiveWorkspace._id
  )
  if (selectedAccount.value?.platform_identifier) {
    bindSocketListeners()
  }
  EventBus.$emit('set-top-posts-limit', postsLimit.value)
})

onBeforeUnmount(() => {
  removeSocketListeners(selectedAccount.value?.platform_identifier)
  if (analyticsChannel.value) {
    analyticsChannel.value.unbind(
      'syncing-' +
        getters.getActiveWorkspace._id +
        '-' +
        selectedAccount.value?.platform_identifier
    )
    analyticsChannel.value.unsubscribe(
      'analytics-channel-' + getters.getActiveWorkspace._id
    )
  }
})

const resetState = () => {
  fetchingData.value = false
  youtubeAccounts.value = []
  selectedAccount.value = []
  dateFilter.value = ''
  previousDate.value = ''
  analyticsChannel.value = ''
}

const getAccounts = (account) => {
  selectedAccount.value = account
}

const getDate = (date) => {
  dateFilter.value = date
}

const getPreviousDate = (date) => {
  previousDate.value = date
}

const removeSocketListeners = (value) => {
  const event = 'syncing-' + getters.getActiveWorkspace._id + '-' + value
  analyticsChannel.value.unbind(event)
}

const bindSocketListeners = () => {
  const event =
    'syncing-' +
    getters.getActiveWorkspace._id +
    '-' +
    selectedAccount.value?.platform_identifier
  console.log(event)
  analyticsChannel.value.bind(event, (data) => {
    console.log('message data --> ', data)
    setYoutubeState(data.state, data.account, data.last_analytics_updated_at)
  })
}

const setYoutubeState = (state, checkFor, lastAnalyticsUpdatedAt) => {
  const updatedItems = []
  for (const item in getters.getYoutubeAccounts) {
    const update = getters.getYoutubeAccounts[item]
    if (update?.platform_identifier === checkFor) {
      update.state = state
      // eslint-disable-next-line camelcase
      update.last_analytics_updated_at = lastAnalyticsUpdatedAt
    }
    updatedItems.push(update)
  }
  dispatch('setPlatforms', {
    items: updatedItems,
    type: 'youtube',
    all_item_setter: youtube.SET_ALL_ACCOUNTS,
    item_setter: youtube.SET_ACCOUNTS,
  })
}
</script>

<template>
  <div ref="mainComponentRef" class="analytics-main-container">
    <AnalyticsFilterBar
      :get-accounts="getAccounts"
      :get-date="getDate"
      :get-previous-date="getPreviousDate"
      :style="{
        // eslint-disable-next-line vue/camelcase
        cursor: fetchingData ? 'progress' : '',
        pointerEvents: fetchingData ? 'none' : '',
      }"
      :updating="fetchingData"
      type="youtube"
    />
    <div
      v-if="getters.getYoutubeAccounts?.items?.length <= 0 && !fetchingData"
      class="analytics-main-errors"
    >
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p v-if="getters.getFetchSocialStatus">
        Retrieving Data! Please Wait...
      </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social', params: { id: 'youtube' } }"
          >Connect Youtube Account
        </router-link>
      </p>
    </div>

    <div
      v-else-if="getters?.getYoutubeAccounts?.items?.length > 0"
      class="analytics-content-wrapper"
    >
      <template v-if="selectedAccount?.state === 'Added'">
        <AnalyticsLoading :name="selectedAccount.name" />
      </template>
      <template v-else>
        <TabsComponent
          v-if="!getters.getFetchSocialStatus"
          :tabs="['#overview', '#posts']"
          type="youtube"
        >

          <template v-if="selectedAccount && isReconnectRequired" v-slot:alert>
            <CstAlert type="warn" class="text-left mb-5">
              To view your updated data, you must reconnect your YouTube account.
              <router-link
                class=" text-yellow-600 font-semibold hover:text-yellow-700"
                :to="{ name: 'social', params: { id: 'youtube' } }"
                >Reconnect Now
              </router-link>
            </CstAlert>
          </template>
          <!-- overview tab -->
          <template v-if="selectedAccount" v-slot:overview>
            <OverviewSection />
          </template>

          <!-- posts tab -->
          <template v-slot:posts>
            <PostsSection />
          </template>
        </TabsComponent>
      </template>
    </div>

    <div v-else class="analytics-main-errors">
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p> Fetching Accounts! Please Wait... </p>
    </div>
  </div>
</template>
