<script setup>
import {computed, defineProps} from 'vue'
import useTwitterAnalytics from '@modules/analytics/views/twitter/composables/useTwitterAnalytics'

const {creditsUsedCount, twitterJobTooltip, twitterPostCountTooltip} = useTwitterAnalytics()


const toolTip = {
  youtube: [
    `Data may be up to 3 days old due to API limitations.`,
    `Data is displayed according to your workspace's time zone.`,
    `Data processing runs every 24 hours for timely updates.`,
    `Private video data is not available.`,
  ],
  pinterest: [
    `Data may be up to 2 days old due to API limitations.`,
    `Data is displayed according to your workspace's time zone.`,
    `Data processing runs every 24 hours for timely updates.`,
  ],
  get twitter() {
    return [
      twitterJobTooltip(),
      twitterPostCountTooltip(),
      `Data is displayed according to your workspace's time zone.`,
      `X (Twitter) Analytics credits used: ${creditsUsedCount.value}`,
    ]
  },
  tiktok: [
    `Data is displayed according to your workspace's time zone.`,
    `Data processing runs every 24 hours for timely updates.`,
    `Private posts data is not available.`,
  ],
  'label-and-campaign':[
    `Data is shown in your workspace's time zone.`,
    'Data updates every 24 hours.',
    'Data for private posts is not available.',
    'LinkedIn impressions data is not available.',
    'TikTok data is available only for public videos approved by TikTok.',
    'Pinterest data may be up to 2 days old due to API limits.',
  ],
  default: [`Data is displayed according to your workspace's time zone.`],
}
const learnMoreLinks = {
  'campaign-and-label': 'https://docs.contentstudio.io/article/1031-label-campaign-performance-report',
  youtube: 'https://docs.contentstudio.io/article/1019-youtube-analytics',
  tiktok: 'https://docs.contentstudio.io/article/1016-overview-of-tiktok-analytics-and-how-it-works',
  pinterest: 'https://docs.contentstudio.io/article/1022-pinterest-analytics',
  instagram: 'https://docs.contentstudio.io/article/1037-instagram-analytics',
}

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: 'default',
  },
  enablePeek: {
    type: Boolean,
    default: false,
  },
})

const getCustomTooltip = computed(() => {
  return toolTip[props.type] ?? toolTip.default
})
const isLearnMoreAvailable = computed(() => {
  return !!learnMoreLinks[props.type]
})

const openDocs = () => {
  window.open(learnMoreLinks[props.type], '_blank')
}
</script>

<template>
  <v-menu
      :popper-triggers="['hover']"
      placement="bottom-end"
      popper-class="first-comment__info-popover"
      :delay="300"
  >
    <div class="flex items center gap-2 text-secondary-cs-700 px-2">
      <span v-if="type === 'twitter'" class="text-sm !leading-6">
          Auto Sync Schedule:
        </span>
      <i v-else class="far fa-info-circle p-0 text-base"></i>
      <span
v-if="enablePeek" class="text-sm !leading-6 hidden md:block"
            :class="{ '!text-[#5dc5ec] font-bold': type === 'twitter' }"
      >{{
          type === 'twitter' ? getCustomTooltip[0]?.replace('Data syncs ', '') : getCustomTooltip[0]
        }}</span>
      <i
          v-if="enablePeek"
          class="far fa-chevron-down p-0 text-xs !leading-6 hidden md:block"
      ></i>

    </div>

    <template v-slot:popper>
      <div>
        <p
            v-for="(tooltip, index) in getCustomTooltip"
            :key="index + '_tooltip_item'"
            class="text-sm text-gray-900 mb-1"

        >
          {{ index + 1 }}. {{ tooltip }}
        </p>
      </div>
      <div v-if="isLearnMoreAvailable" class="flex mt-1 items-center justify-end">
        <a
            class="text-sm font-medium text-[#2961D2] cursor-pointer"
            @click="openDocs"
        >
          Learn More
        </a>
      </div>
    </template>
  </v-menu>
</template>
