export const composer = {
  SET_COMPOSER_POST_TYPE: 'SET_COMPOSER_POST_TYPE',
  SET_CURATION_FAVORITE_NAME: 'SET_CURATION_FAVORITE_NAME',
  SET_ASSISTANT_CONTENT_TYPE: 'SET_ASSISTANT_CONTENT_TYPE',
  SET_EDIT_SEO_PREVIEW: 'SET_EDIT_SEO_PREVIEW',
  SET_SEO_KEYWORD: 'SET_SEO_KEYWORD',
  SET_SEO_URL: 'SET_SEO_URL',
  RESET_COMPOSER_BLOG: 'RESET_COMPOSER_BLOG',
  SET_MOVE_CAMPAIGN_ID: 'SET_MOVE_CAMPAIGN_ID',
  SET_MOVE_CAMPAIGN_PLAN_ID: 'SET_MOVE_CAMPAIGN_PLAN_ID',
  SET_MOVE_CAMPAIGN_LOADER: 'SET_MOVE_CAMPAIGN_LOADER',
  SET_MOVE_CAMPAIGN_DEFAULT: 'SET_MOVE_CAMPAIGN_DEFAULT',
  SET_FETCH_PUBLICATION_PAGINATION_LOADER:
    'SET_FETCH_PUBLICATION_PAGINATION_LOADER',
  SET_FETCH_PUBLICATION_CONTENT_LOADER: 'SET_FETCH_PUBLICATION_CONTENT_LOADER',
  SET_PUBLICATIONS_SEARCH: 'SET_PUBLICATIONS_SEARCH',
  SET_PUBLICATIONS_MEMBERS: 'SET_PUBLICATIONS_MEMBERS',
  SET_PUBLICATIONS_LABELS: 'SET_PUBLICATIONS_LABELS',
  SET_PUBLICATIONS_PAGE: 'SET_PUBLICATIONS_PAGE',
  SET_PUBLICATIONS: 'SET_PUBLICATIONS',
  SET_PUBLICATIONS_DEFAULT: 'SET_PUBLICATIONS_DEFAULT',
  SET_PUBLICATIONS_FOLDER: 'SET_PUBLICATIONS_FOLDER',
  SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER:
    'SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER',
  SET_FETCH_PUBLICATION_FOLDERS_LOADER: 'SET_FETCH_PUBLICATION_FOLDERS_LOADER',
  SET_PUBLICATION_FOLDER_STATUS: 'SET_PUBLICATION_FOLDER_STATUS',
  SET_PUBLICATION_LAST_UPDATED: 'SET_PUBLICATION_LAST_UPDATED',
  SET_PUBLICATION_FOLDERS_TOTAL: 'SET_PUBLICATION_FOLDERS_TOTAL',
  SET_PUBLICATIONS_STATUS: 'SET_PUBLICATIONS_STATUS',
  SET_CHECK_ALL_STATUSES: 'SET_CHECK_ALL_STATUSES',
  SET_CHECK_ALL_CATEGORIES: 'SET_CHECK_ALL_CATEGORIES',
  SET_CHECK_ALL_MEMBERS: 'SET_CHECK_ALL_MEMBERS',
  SET_PUBLICATIONS_CATEGORY: 'SET_PUBLICATIONS_CATEGORY',
  SET_PUBLICATION_FOLDERS: 'SET_PUBLICATION_FOLDERS',
  SET_PUBLICATIONS_BULK_IDS: 'SET_PUBLICATIONS_BULK_IDS',
  SET_PUBLICATIONS_SELECT_ALL: 'SET_PUBLICATIONS_SELECT_ALL',
  SET_PUBLICATIONS_TYPE: 'SET_PUBLICATIONS_TYPE',
  SET_PUBLICATIONS_EDIT_NAME : 'SET_PUBLICATIONS_EDIT_NAME',
  SET_PUBLICATIONS_EDIT_COLOR : 'SET_PUBLICATIONS_EDIT_COLOR',
  SET_PUBLICATIONS_EDIT_ID: 'SET_PUBLICATIONS_EDIT_ID',
  SET_MOVE_CAMPAIGN_CREATE_STATUS: 'SET_MOVE_CAMPAIGN_CREATE_STATUS',
  SET_BLOG_AUTO_SAVE_INTERVAL: 'SET_BLOG_AUTO_SAVE_INTERVAL',
  SET_COMPOSER_VERSION_HISTORY: 'SET_COMPOSER_VERSION_HISTORY',
  SET_VERSION_HISTORY_LOADER: 'SET_VERSION_HISTORY_LOADER',
  SET_VERSION_HISTORY_ACTIVE_INDEX: 'SET_VERSION_HISTORY_ACTIVE_INDEX',
  SET_EDITOR_TYPING_INTERVAL: 'SET_EDITOR_TYPING_INTERVAL',
  SET_EDITOR_TYPING_STATUS: 'SET_EDITOR_TYPING_STATUS',
  SET_TYPING_TEXT_INTERVAL: 'SET_TYPING_TEXT_INTERVAL',
  SET_COMPOSER_ACTIVE_PLAN_MEMBERS: 'SET_COMPOSER_ACTIVE_PLAN_MEMBERS',
  SET_SOCKET_ID: 'SET_SOCKET_ID',
  SET_PLAN_CONTROL: 'SET_PLAN_CONTROL',
  SET_PLAN_CONTROL_REQUEST_DATA: 'SET_PLAN_CONTROL_REQUEST_DATA',
  SET_PLAN_KEY: 'SET_PLAN_KEY'
}
