<script setup>
import { inject, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import CstButton from '@ui/Button/CstButton'
import SaveSocialAccounts from '@src/modules/integration/components/dialogs/SaveSocialAccounts'
import AccountsNotAllowedModal from '@src/modules/integration/components/modals/AccountsNotAllowedModal.vue'
import VideoIntro from '@/src/modules/account/views/onboarding/VideoIntro.vue'
import SocialConnect from '@/src/modules/account/views/onboarding/SocialConnect.vue'
import useSocialAccountModal from '@/src/modules/account/composables/useSocialAccountsModal'
import useWorkspace from '@/src/modules/common/composables/useWorkspace'
import { useStore } from '@/src/store/base'

// declarations
const store = useStore()
const route = useRoute()
const steps = store.getters.getActiveWorkspace?.onboarding_steps ?? {}
const root = inject('root')
const { $bvModal } = root
const { onboardingStepsCompleted } = useWorkspace()
const {
  hasConnectedAccounts,
  fetching,
  buttonText,
  OPTIONS,
  selectedOption,
  processReconnectResponse,
} = useSocialAccountModal()

// methods
const handleButtonClick = async () => {
  if (selectedOption.value === OPTIONS.SOCIAL_CONNECT) {
    onboardingStepsCompleted('accounts_connection_modal_closed')
    $bvModal.hide('get-started-modal')
    return 0
  }

  if (selectedOption.value === OPTIONS?.VIDEO) {
    if (steps?.watch_video?.status) {
      selectedOption.value = OPTIONS.SOCIAL_CONNECT
      return
    }
    if (!steps.watch_video) {
      steps.watch_video = {}
    }
    steps.watch_video.status = true
    fetching.value = true
    await onboardingStepsCompleted('watch_video')
    fetching.value = false
  }
}

onMounted(async () => {
  const response = await store.dispatch('checkConnectorState', {
    hash: route.hash,
  })

  await processReconnectResponse(response)
})
</script>

<template>
  <AccountsNotAllowedModal />
  <SaveSocialAccounts />

  <b-modal
    id="get-started-modal"
    body-class="!p-0"
    class="!w-[80rem]"
    dialog-class="xl:!max-w-[80rem]"
    size="xl"
    centered
    no-close-on-backdrop
    hide-header
    hide-footer
    no-close-on-esc
  >
    <div class="bg-gray-50 lg:!bg-transparent lg:h-[41rem]">
      <!-- Body -->
      <VideoIntro
        v-if="selectedOption === OPTIONS.VIDEO"
        video-id="uAuiDkRGYhI"
      />

      <SocialConnect v-else />
    </div>

    <!-- Footer -->
    <div class="flex justify-center align-items-center h-[5rem]">
      <CstButton
        size="default"
        :variant="hasConnectedAccounts ? 'primary' : 'outlined'"
        :text="fetching ? 'Please wait...' : buttonText"
        class="
          h-[2.2rem]
          flex
          align-items-center
          !rounded-lg
          !border-primary-cs
        "
        :disabled="fetching"
        @click="handleButtonClick"
      />
    </div>
  </b-modal>
</template>

<style>
#get-started-modal___BV_modal_outer_ {
  z-index: 1051 !important;
}

#save-social-accounts___BV_modal_outer_ {
  z-index: 1052 !important;
}
#add-twitter-account-modal___BV_modal_outer_ {
  z-index: 1053 !important;
}

#accounts_not_allowed_modal___BV_modal_outer_ {
  z-index: 1053 !important;
}

#removeIntegration___BV_modal_outer_ {
  z-index: 1053 !important;
}
</style>

<style lang="scss" scoped>
.modal_body {
  padding: 0 !important;
}
</style>
