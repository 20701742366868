<template>
  <div
    class="flex items-center h-12 rounded-md border text-gray-900 text-sm focus-within:outline-blue w-full py-2.5 px-3"
    :class="{
      'bg-gray-100 select-none cursor-not-allowed ': disabled,
    }"
  >
    <div
      v-if="showIconLeft"
      class="flex items-center pl-1 pr-4 pointer-events-none"
    >
      <slot name="icon"></slot>
    </div>

    <div
      class="relative flex-1 appearance-none label-floating"
      :class="{
        'pointer-events-none': disabled,
      }"
    >
      <input
        :id="id"
        v-model="model"
        :type="type"
        :name="name"
        class="w-full border-0 py-2 bg-transparent leading-normal rounded cursor-text transition-all ease-in-out duration-300"
        :placeholder="label"
        :maxlength="maxlength"
        :readonly="readonly"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @keydown.enter="$emit('enter')"
        @keydown="$emit('keydown', $event)"
      />
      <label
        class="absolute block text-gray-700 top-0 left-0 w-full pt-2 pb-1 m-0 leading-normal cursor-text transition-all ease-in-out duration-300"
        :for="id"
      >
        {{ label }}
      </label>
    </div>

    <div
      v-if="showIconRight"
      class="flex items-center pr-1 pl-4"
      :class="{
        'pointer-events-none': disabled,
      }"
    >
      <slot name="icon-right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CstInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array],
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    showIconLeft: {
      type: Boolean,
      default: false,
    },
    showIconRight: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [Number, String],
      default: 524288,
    },
    readonly: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: [Boolean, String, Number],
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
  },
  emits: ['enter', 'keydown', 'input'],
  computed: {
    name() {
      return this.label.toLowerCase()
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
.label-floating {
  input::placeholder {
    @apply opacity-0;
  }

  input:not(:placeholder-shown) {
    @apply pt-6;
  }

  input:not(:placeholder-shown) ~ label {
    @apply text-gray-900 text-xs;
  }

  input:autofill {
    @apply text-blue-400;
    box-shadow: 0 0 0 1000px #fff0 inset !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #fff0 inset !important;
  }
}
</style>
